<template>
  <h2 class="content-block">
    <i class="dx-icon nav-icon fa-light fa-users"></i> 
    {{ title }}
  </h2>

  <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block">

						<div class="dx-card no-paddings dx-card-has-minheight">

      <DxDataGrid
        :data-source="customDataSource"
        :focused-row-index="-1"
        :show-borders="false"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :remote-operations="false"
      >
      
      <DxPaging :page-size="50" />
      <DxPager :show-page-size-selector="true" :show-info="true" />
      <DxFilterRow :visible="true" />

      <DxColumn 
        data-field="Avatar" 
        caption=""
        placeholder=""
        alignment='left' 
        :allow-sorting="false"
        :allow-editing="false"
        :allow-filtering="false"
        cell-template="avatarTemplate"
        :width="60" 
        :hiding-priority="20" 
      />
      <DxColumn 
        data-field="id" 
        caption="Id"
        data-type="number"
        alignment='left' 
        :width="90" 
        :hiding-priority="19" 
      />
      <DxColumn
        data-field="Title"
        :caption="currentUser.Translation.vueappCustomerPrefix"
        :placeholder="currentUser.Translation.vueappCustomerPrefix"
        alignment='left' 
        :hiding-priority="18" 
      />
      <DxColumn 
        data-field="FirstName"
        :caption="currentUser.Translation.vueappCustomerFirstName"
        :placeholder="currentUser.Translation.vueappCustomerFirstName"
        :hiding-priority="17" 
      />
      <DxColumn 
        data-field="LastName"
        :caption="currentUser.Translation.vueappCustomerLastName"
        :placeholder="currentUser.Translation.vueappCustomerLastName"
        :hiding-priority="16" 
      />
      <DxColumn
        data-field="Email"
        :caption="currentUser.Translation.vueappCustomerEmail"
        :placeholder="currentUser.Translation.vueappCustomerEmail"
        :hiding-priority="15" 
      />
      <DxColumn
        data-field="Phone"
        :caption="currentUser.Translation.vueappCustomerPhone"
        :placeholder="currentUser.Translation.vueappCustomerPhone"
        :hiding-priority="14" 
      />
      <DxColumn
        data-field="Street"
        :caption="currentUser.Translation.vueappCustomerStreet"
        :placeholder="currentUser.Translation.vueappCustomerStreet"
        :hiding-priority="13" 
      />
      <DxColumn
        data-field="Zip"
        :caption="currentUser.Translation.vueappCustomerZip"
        :placeholder="currentUser.Translation.vueappCustomerZip"
        :width="90"
        :hiding-priority="12" 
      />
      <DxColumn
        data-field="City"
        :caption="currentUser.Translation.vueappCustomerCity"
        :placeholder="currentUser.Translation.vueappCustomerCity"
        :hiding-priority="11" 
      />
      <DxColumn
        data-field="BirthDate"
        :caption="currentUser.Translation.vueappCustomerBirthDate"
        :placeholder="currentUser.Translation.vueappCustomerBirthDate"
        data-type="date"
        format="dd.MM.yyyy"
        :width="125"
        :hiding-priority="10" 
      />
      <DxColumn
        data-field="Role"
        :caption="currentUser.Translation.vueappCustomerRole"
        :placeholder="currentUser.Translation.vueappCustomerRole"
        :hiding-priority="9" 
      />
      <DxColumn
        data-field="CreationDate"
        data-type="date"
        alignment='left' 
        format="dd.MM.yyyy"
        :caption="currentUser.Translation.vueappGlobalCreationDate"
        :placeholder="currentUser.Translation.vueappGlobalCreationDate"
        :hiding-priority="8"
      />
      <DxColumn
        data-field="ModificationDate"
        data-type="date"
        alignment='left' 
        format="dd.MM.yyyy"
        :caption="currentUser.Translation.vueappGlobalModificationDate"
        :placeholder="currentUser.Translation.vueappGlobalModificationDate"
        :hiding-priority="7"
      />

      <DxColumn
        data-field="Notes"
        alignment='left' 
        :caption="currentUser.Translation.vueappCustomerNotes"
        :placeholder="currentUser.Translation.vueappCustomerNotes"
        :hiding-priority="6"
      />

      <template #avatarTemplate="{ data }">
        <div class="user-image" 
          style="width: 40px; height: 40px; border-radius: 50%; background-repeat: no-repeat; background-size: cover; margin-left: -10px; margin-top: -10px; margin-bottom: -10px;" 
          v-bind:style="{ 'background-image': 'url(' + data.value + ')' }" >
        </div>
      </template>

      </DxDataGrid>

    </div><!-- dx-card -->

  </div><!-- content-block -->

  <div v-else class="content-block">
    Oh no 😢
  </div><!-- content-block -->

</template>

<script>

import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  //DxLookup,
  DxPager,
  DxPaging

} from "devextreme-vue/data-grid";

import { 
  statuses, 
  priorities, 
} from "../constant";

import CustomStore from 'devextreme/data/custom_store';

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

let currentUser;

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;
  } // e.data
}); //auth

function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

const customDataSource = new CustomStore({
  key: 'id',
  load: () => {
      return fetch(apihost+'/de/vue/member/list/')
      .then(handleErrors)
      .then(response => response.text())
      .then(data => {
      //console.log(`response text`, text)
      return JSON.parse(data)
      })
      .catch(() => { throw 'Network error' });
  },
});

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    //DxLookup,
    DxPager,
    DxPaging
  },
  data() {
    return {
      apihost,
      
      customDataSource,
      statuses,
      priorities,
      title: 'Custom Store',
      currentUser,
    }
  }
}
</script>