<template>
    <svg class="dynamic-logo" viewBox="0 0 306.6 112.5" fill="none" xmlns="http://www.w3.org/2000/svg">

        <path class="st1" d="M47.3,88.2c0.7,0.3,1.5,0.6,2.2,0.9c-1.8-0.8-3.7-1.6-5.5-2.4C45.2,87.2,46.2,87.7,47.3,88.2z M50,89.4
        c1.3,0.6,2.6,1.1,4,1.6c-1.5-0.6-3-1.2-4.4-1.8C49.7,89.2,49.9,89.3,50,89.4z M305.4,49.8c-1.1-3.7-3.2-6.6-5.1-9
        c-14.8-19-44.9-26.6-69.6-33c-26.2-6.7-55.4-6.9-82.8-4.7C114.4,5.4,81.4,10.4,50.3,23C102.1,3.8,160.6,2.6,214.8,11.6
        c25.8,6.2,75.7,17.8,75.8,44.2c0.1,28.6-43.2,37.2-67.1,43.6C168,110.5,107.8,109.6,54.1,91c32.1,12.2,67.7,16.5,102.1,18.6
        c25.9,2.1,52,0.9,77.3-5.3c23.6-6.2,72.2-18.3,72.6-48.2C306.1,53.7,306,52.3,305.4,49.8z M40.8,85.1c1.1,0.4,2.4,1.4,3.2,1.5
        C43,86.1,41.9,85.6,40.8,85.1z"/>
        <path class="st0" d="M180,110.4C-62.9,101.7-17.6-0.9,194.6,2.4C149-3.1,11.5-0.3,0,54.9c3.5,56.5,151.6,61.4,194.3,55.2
        c0,0,0-0.1,0.1-0.1C189.7,110.4,184.7,110.4,180,110.4z"/>
        <path class="st1" d="M126.7,61.2c2.7,0,5.4,0,8.2,0c1.8-10.2,3.6-20.4,5.4-30.7c0,0,0,0,0-0.1c-2.4,0-4.8,0-7.2,0
        c-1.1,6.7-2.3,13.5-3.4,20.2c-2.8-6.7-5.6-13.4-8.4-20.2c-2.7,0-5.5,0-8.2-0.1c-1.8,10.2-3.6,20.4-5.4,30.5c0,0.1,0,0.1,0,0.2
        c2.4,0,4.8,0,7.1,0c1.2-6.8,2.3-13.6,3.5-20.3C121,47.8,123.8,54.5,126.7,61.2z"/>
        <path class="st0" d="M243.3,36.8c3.1-1.2,7.8-0.4,8.9,2.1c0.5,0.2,1.1-0.6,1.6-1c1.3-1.1,2.6-2.3,3.9-3.4c0-0.1,0.1-0.1,0.1-0.2
        c-2.3-3.2-6.6-4.7-12.2-4.6c-0.7,0.1-1.4,0.2-2.1,0.2c-1.8,0.4-3.7,0.9-5.1,1.7c-4.6,2.7-7.9,6.5-9.4,12.4c-0.3,1.1-0.2,2.2-0.4,3.5
        c-0.5,3,0.5,6.2,1.6,8c1.6,2.7,4,4.7,7.2,5.8c6.8,2.1,14.6-0.5,17.6-4.4c-1.3-1.6-2.6-3.2-4-4.7c0,0-0.1,0-0.1-0.1
        c-1.8,2.5-6.5,4.4-10.4,2.7c-2.3-1-3.6-3.1-4.1-5.9C235.5,43.3,240,38.1,243.3,36.8z"/>
        <path class="st0" d="M78.7,37.5c-4.7-7.9-12.4-7-24.5-6.9c-1.8,10.2-3.6,20.4-5.4,30.7c0,0,0,0,0,0.1c3.4,0,6.8,0,10.2,0
        c2,0,4,0.2,5.8-0.2c6.4-1.2,11.3-4.3,13.7-9.6c0.7-1.6,1.2-3.2,1.5-5.2C80.6,43,79.9,39.5,78.7,37.5z M72.5,45.5
        c-0.9,5.1-3.4,8.2-8.3,9.3c-2,0.4-4.8,0.2-7.1,0.2c1.1-6,2.1-12.1,3.2-18.1c0.3-0.3,4.2-0.2,4.9-0.1c4.1,0.7,6.3,2,7.2,5.8
        C72.4,43.3,72.6,44.5,72.5,45.5z"/>
        <path class="st0" d="M93.7,61.2c0.8-4.3,1.5-8.7,2.3-13c4.9-5.8,9.7-11.5,14.6-17.3c0.1-0.1,0.2-0.2,0.4-0.4c-2.9,0-5.9,0-8.8-0.1
        c-2.9,3.8-5.7,7.6-8.6,11.4c-0.1,0-0.1,0.1-0.2,0.1c-1.4-3.8-2.9-7.7-4.3-11.5c-2.7,0-5.4,0-8.2,0c0,0,0,0,0,0.1
        c2.6,5.9,5.2,11.7,7.9,17.6c-0.8,4.4-1.5,8.7-2.3,13.1C88.9,61.2,91.3,61.2,93.7,61.2z"/>
        <path class="st0" d="M164.2,30.7c-0.4-0.3-6-0.1-7.1-0.1c-6.5,10.2-13,20.4-19.4,30.5c0,0.1,0,0.1-0.1,0.2c2.8,0,5.6,0,8.3,0
        c1.2-2,2.4-4,3.6-6c0.4-0.4,2.3-0.1,3.1-0.1c2.9,0,5.8,0,8.7,0c0.3,2,0.7,4.1,1,6.1c2.6,0,5.1,0,7.7,0
        C168.1,51,166.1,40.8,164.2,30.7z M152.7,49.3c2.1-3.6,4.2-7.2,6.3-10.7c0.5,3.6,1.1,7.2,1.6,10.7C158,49.3,155.3,49.3,152.7,49.3z"
        />
        <path class="st0" d="M192,50.5c0,0-0.1,0-0.1,0.1c-1.2-6.6-2.4-13.2-3.6-19.8c-0.4-0.3-2-0.1-2.6-0.1c-2.5,0-5,0-7.5,0
        c-1.8,10.2-3.6,20.4-5.4,30.5c0,0.1,0,0.1,0,0.2c2.3,0,4.6,0,6.8,0c1.2-7.3,2.4-14.6,3.7-21.8c0,0,0-0.1,0.1-0.1
        c1.4,7.3,2.7,14.6,4.1,22c1.7,0,3.4,0,5.2,0c4-7.3,7.9-14.6,11.9-21.8c0,0,0-0.1,0.1-0.1c-1.3,7.3-2.6,14.6-4,22c2.4,0,4.8,0,7.1,0
        c1.8-10.2,3.6-20.5,5.4-30.7c-3.6,0-7.1,0-10.7,0C199,37.1,195.5,43.8,192,50.5z"/>
        <path class="st0" d="M214.1,61.2c2.4,0,4.9,0,7.3,0c1.8-10.2,3.6-20.5,5.4-30.7c-2.4,0-4.9,0-7.3,0C217.7,40.8,215.9,51,214.1,61.2z
        "/>
        <path class="st1" d="M97.1,67c-0.4,0.3-0.9,0.3-1.2,0.7c-0.6,0.8-0.4,2.1,0.3,2.6c1.1,0.8,2.6-0.1,2.9-1c0.1-0.4,0-0.9,0-1.3
        C98.6,67.4,98.4,67,97.1,67z M164.6,70.7c1.6-0.7,3.6,0,4.2,1c0.7-0.1,1.8-1.7,2.5-2.1c-0.1-0.6-0.7-0.9-1.1-1.1
        c-1.2-0.8-2.6-1.1-4.7-1.1c-0.2,0.1-0.6,0-0.9,0.1c-0.8,0.2-1.7,0.4-2.3,0.8c-2.2,1.4-3.5,3.1-4.2,6c-0.2,0.8-0.3,2.2-0.1,3.1
        c0.1,0.6,0.3,1.2,0.5,1.7c0.8,1.5,2.1,2.5,3.9,3c2.9,0.9,6.6-0.6,7.6-2.2c0,0,0,0,0.1-0.1c-0.7-0.6-1.1-1.6-1.9-2.2
        c0,0,0,0-0.1-0.1c-0.7,1.1-2.9,2.1-4.7,1.3c-1.1-0.5-1.7-1.4-2-2.7c-0.1-0.6,0.1-1.5,0.2-1.9C162.2,72.6,163,71.2,164.6,70.7z
        M87.9,70.6c1.9,0,3.8,0,5.6,0c0.2-1,0.3-1.9,0.5-2.9c-3,0-6,0-8.9,0c-0.8,4.7-1.6,9.5-2.5,14.2c0.6,0,2.9,0.2,3.3-0.1
        c0.3-0.4,0.2-1.2,0.3-1.7c0.3-1,0.6-2.6,0.7-3.7c0,0,0,0,0.1-0.1c1.8,0,3.5,0,5.3,0c0-0.5,0.3-2.4,0.5-2.6v-0.1
        c-1.8,0-3.5,0-5.3,0C87.5,72.6,87.9,71.7,87.9,70.6z M105.2,69.2c-1.1,0-2.1,0-3.2,0c-0.1,0.9-0.4,1.7-0.4,2.6c0,0,0,0-0.1,0.1
        c-0.5,0-1,0-1.6,0c-0.1,0.8-0.3,1.6-0.4,2.5c0.5,0,1.1,0,1.6,0c-0.3,1.5-0.6,3-0.8,4.4c-0.2,0.8,0,1.9,0.4,2.4s1.2,0.7,1.9,0.9
        c0.2,0,0.5,0,0.7,0c0.4,0.1,1.5,0,1.8-0.2h0.1c0.1-0.5,0.1-1.6,0.3-2c0-0.1,0-0.3,0-0.4c-0.7,0.2-1.3,0.2-1.8-0.2
        c-0.3-1.6,0.5-3.3,0.7-4.9c0.4,0,1.8,0.1,2-0.1c0.4-0.5,0.1-1.8,0.5-2.3c0,0,0,0,0-0.1c-0.7,0-1.4,0-2.1,0
        C104.9,71.1,105,70.2,105.2,69.2z M200.9,69.2c-1.1,0-2.1,0-3.2,0c-0.1,0.9-0.3,1.7-0.4,2.6c0,0,0,0-0.1,0.1c-0.5,0-1,0-1.6,0
        c-0.1,0.8-0.3,1.6-0.4,2.5c0.5,0,1.1,0,1.6,0c-0.3,1.5-0.5,3-0.8,4.4c-0.2,0.7-0.1,1.8,0.2,2.2c0.4,0.6,1.2,0.9,2,1.1
        c0.2,0,0.5,0,0.7,0c0.4,0.1,1.5,0,1.8-0.2h0.1c0.1-0.4,0.1-1.6,0.3-1.9c0-0.2,0-0.3,0-0.5c-0.4,0.1-1.2,0.3-1.5,0
        c-0.4-0.2-0.5-0.8-0.4-1.3c0.3-1.2,0.6-2.5,0.7-3.8c0.4,0,1.8,0.1,2-0.1c0.4-0.6,0.1-1.7,0.5-2.3v-0.1c-0.7,0-1.4,0-2.1,0
        C200.6,71.1,200.7,70.2,200.9,69.2z M128.6,72.5c-0.4-0.3-0.9-0.6-1.4-0.7c-0.6-0.2-1.6-0.3-2.3-0.1c-0.4,0.1-0.7,0-1,0.1
        c-1.9,0.6-3.3,1.9-4,3.8c-1,2.8,0.3,5.4,2.3,6.2c2.3,1,6,0.1,7-1.5c0,0,0,0,0.1-0.1c-0.7-0.5-1.3-1.2-2.1-1.6
        c-1,1.6-4.2,1.4-4.6-0.7c0-0.1,0,0,0.1-0.1c2.4,0,4.8,0,7.1,0c0.1-0.5,0.2-1,0.2-1.4C130.3,74.9,129.3,73.1,128.6,72.5z M127,75.8
        c-1.4,0-2.8,0-4.2,0c0.2-0.9,0.9-1.5,1.6-1.8c0.2-0.1,0.4,0,0.5-0.1c0.9,0,1.6,0.1,1.9,0.7c0.1,0.2,0.1,0.4,0.2,0.6
        C127.1,75.4,127.1,75.7,127,75.8z M134.8,74.9c0.2-1.5,2.9-1,3.2,0c0.7-0.3,1.4-1.2,2.1-1.7c-0.4-1.2-2.7-1.7-4.4-1.6
        c-0.5,0.1-1,0.2-1.4,0.3c-1.3,0.5-3.2,2.2-2.3,4.3c0.4,0.9,1.6,1.4,2.6,1.7c0.4,0.1,0.9,0.2,1.2,0.4c0.2,0.2,0.4,0.7,0.2,1
        c-0.3,0.8-2.2,0.5-2.8,0.1c-0.2-0.2-0.5-0.5-0.7-0.7c-0.7,0.2-1.4,1.4-2.1,1.8c1.5,2.2,6.6,2.4,8.2,0.1c0.4-0.5,0.9-1.4,0.6-2.5
        C138.5,75.7,136.1,76.4,134.8,74.9z M145.2,71.6c-0.3,0.2-0.7,0.1-1,0.2c-0.6,0.2-1.2,0.5-1.6,0.8c-0.4,0.3-0.6,0.7-0.8,1.1
        c-0.1,0.2-0.3,0.6-0.4,0.9c-0.1,0.3,0,0.9,0.1,1.1c0.3,1.3,1.3,1.8,2.5,2.2c0.5,0.2,1.1,0.2,1.5,0.5c0.1,0.1,0.2,0.3,0.2,0.4
        c0,0.2,0,0.3-0.1,0.4c-0.4,1.2-3.2,0.3-3.5-0.5c-0.7,0.1-1.5,1.4-2.1,1.7c0.1,0.5,0.7,0.7,1,1c1.9,1.3,5,1.1,6.7-0.2
        c0.3-0.2,0.5-0.7,0.7-1.1c0.1-0.3,0.2-0.6,0.3-1s0-0.9-0.1-1.1c-0.6-2-2.6-1.9-4.1-2.8c-0.1-0.3-0.1-0.5,0.1-0.7
        c0.4-0.5,1.7-0.6,2.3-0.2c0.3,0.2,0.5,0.5,0.8,0.7c0.1,0.1,0.4-0.2,0.5-0.3c0.5-0.3,1.1-0.9,1.5-1.3c0,0,0,0,0.1,0
        C149.3,72.1,147,71.6,145.2,71.6z M179,71.9c-0.6-0.2-1.6-0.5-2.5-0.3c-0.5,0.1-1.1,0.2-1.6,0.4c-1.5,0.6-2.7,1.7-3.3,3.2
        c-0.2,0.5-0.3,1-0.4,1.5c-0.6,2.4,1,4.5,2.4,5.1c0.5,0.2,0.9,0.2,1.6,0.4c0.7,0.2,1.6,0.2,2.3,0c0.9-0.2,1.8-0.6,2.5-1.1
        c0.3-0.2,0.5-0.5,0.8-0.7v-0.1c-0.4-0.2-1.7-1.2-2-1.6c-0.4,0.2-0.7,0.6-1.1,0.8c-1.2,0.5-3.1,0.2-3.4-0.9
        c-0.1-0.2-0.1-0.4-0.1-0.7c2.4,0,4.8,0,7.1,0C182,74.8,181.3,72.8,179,71.9z M174.5,75.8c0.2-0.9,1-1.6,1.8-1.9
        c1.5-0.5,2.5,0.5,2.5,1.9C177.3,75.8,175.9,75.8,174.5,75.8z M211.9,72.6c-0.4-0.3-1-0.6-1.5-0.8c-0.6-0.2-1.6-0.3-2.3-0.1
        c-0.5,0.1-0.8,0-1.3,0.2c-1.8,0.6-3,1.9-3.7,3.5c-0.1,0.5-0.2,1-0.4,1.6c-0.1,0.6,0,1.4,0.1,1.8c0.6,2.2,1.6,2.8,3.8,3.4
        c0.5,0.1,1.4,0.2,2,0.1c1.1-0.2,2-0.5,2.8-1.1c0.3-0.2,0.5-0.6,0.8-0.8v-0.1c-0.7-0.4-1.2-1.1-2-1.5c0,0,0,0-0.1-0.1
        c-0.6,0.9-2.6,1.5-3.7,0.7c-0.5-0.3-0.5-0.7-0.8-1.3c0-0.2,0-0.2,0.1-0.3c2.4,0,4.8,0,7.1,0c0.1-0.5,0.2-1,0.2-1.5
        C213.5,74.9,212.6,73.1,211.9,72.6z M210.3,75.8c-1.4,0-2.8,0-4.2,0c0.2-1.2,1.8-2.4,3.3-1.8c0.6,0.2,0.6,0.7,1,1.1
        C210.3,75.4,210.3,75.7,210.3,75.8z M114.4,71.7c-0.5,0-1,0.1-1.3,0.3c-0.6,0.3-0.9,0.8-1.4,1.2c0,0,0,0,0,0.1c0,0,0,0-0.1,0
        c0-0.4,0.1-0.9,0.1-1.3c-1,0-2.1,0-3.1,0c0,1.2-0.3,2.3-0.5,3.4c-0.3,1.3-0.4,2.6-0.7,3.8c-0.2,0.8-0.1,2.2-0.5,2.8v0.1
        c1.1,0,2.2,0,3.3,0c0-0.7,0.2-1.4,0.4-2c0.2-0.9,0.3-1.8,0.5-2.7c0.2-0.9,0.2-1.6,0.7-2.2c0.3-0.4,1.5-1,2.2-0.5
        c0.6,0.2,0.7,1.3,0.5,2c-0.4,1.7-0.8,3.6-1,5.3c0.6,0,2.9,0.2,3.2-0.1c0.3-0.5,0.3-2.1,0.5-2.8C118.1,75.8,119,71.6,114.4,71.7z
        M190.5,71.7c-0.5,0-0.9,0-1.3,0.2c-0.7,0.3-1.2,0.8-1.7,1.3c0,0,0,0,0,0.1c0,0,0,0-0.1,0c0-0.4,0.1-0.9,0.1-1.3c-1,0-2.1,0-3.1,0
        c0,1-0.2,1.9-0.4,2.7c-0.4,1.4-0.4,2.8-0.7,4.1c-0.2,0.8-0.2,2.5-0.5,3.1V82c1.1,0,2.2,0,3.3,0c0-1.1,0.3-2.3,0.6-3.3
        c0.3-1.4,0.2-2.5,1-3.5c0.3-0.4,1.4-1.2,2.2-0.6c0.5,0.2,0.7,1,0.5,1.7c-0.4,1.8-0.8,3.8-1,5.6c0.6,0,2.9,0.2,3.2-0.1
        c0.3-0.5,0.4-2.3,0.5-3.1C193.9,75.7,194.7,71.7,190.5,71.7z M219,73.2L219,73.2c0,0.1,0,0.1-0.1,0.1c0.1-0.5,0.1-0.9,0.2-1.4
        c-1,0-2.1,0-3.1,0c0,1.4-0.3,2.7-0.7,4c-0.3,1.3-0.3,2.5-0.6,3.7c-0.2,0.7-0.4,1.5-0.4,2.3c1.1,0,2.2,0,3.2,0
        c0.1-1.1,0.3-2.3,0.6-3.3c0.2-0.9,0.2-1.8,0.5-2.6c0.2-0.4,0.4-0.6,0.7-0.9s1.6-1,2.3-0.5c0.1,0,0.2,0,0.2,0
        c0.1-0.6,0.3-2.4,0.5-2.8v-0.1C220.4,71.4,220,72.4,219,73.2z M93.5,82c1.1,0,2.2,0,3.2,0c0.1-1.1,0.3-2.2,0.6-3.2
        c0.3-1.4,0.4-2.7,0.7-4c0.2-0.8,0.1-2.1,0.5-2.7V72c-1.1,0-2.2,0-3.3,0C94.7,75.2,94.1,78.6,93.5,82z M150.3,78c1.7,0,3.4,0,5.1,0
        c0.1-0.9,0.3-1.7,0.4-2.6c-1.7,0-3.4,0-5.1,0C150.6,76.2,150.4,77.1,150.3,78z"/>

    </svg>
</template>

