<template>
  <dx-scroll-view height="100%" width="100%" class="with-footer -single-card">
     <div class="dx-card content" style="background: transparent;">
      <!-- <div class="header">
        <div class="title">{{title}}</div>
        <div class="description">{{description}}</div>
      </div> -->
      <slot />
    </div>
  </dx-scroll-view>
</template>

<script>
import DxScrollView from "devextreme-vue/scroll-view";

import { useRoute } from 'vue-router';
import { watch, ref } from 'vue';

export default {
  components: {
    DxScrollView
  },
  setup() {
    const route = useRoute();

    const title = ref(route.meta.title);
    const description = ref("");

    watch(() => route.path,
     () => {
        title.value = route.meta.title;
        description.value = route.meta.description;
     }
    )
    return {
      title,
      description
    }
  }
};
</script>

<style lang="scss">

</style>
