<template>

  <h2 class="app-title">
    <!-- <i class="nav-icon fa-light fa-weight-scale"></i>  -->
    {{ title }}
  </h2>

  <!-- <div style="height: 3000px; border: 1px solid red;">
  </div> -->
  
</template>

<script>
import auth from "../auth";

let currentUser;

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;
  } // e.data
}); //auth

export default {
  mounted() {

  },
  data() {
    return {
      title: 'Messwerte',
      currentUser,
    };
  },
  methods: {

  },
  unmounted() {

  },
};
</script>

<style lang="scss">
</style>
