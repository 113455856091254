<template>

<div class="-grid-container content-block">

      <div class="grid-x grid-margin-x grid-margin-y ">
        <div class="large-12 cell">
          <h1>Welcome to My Sport App</h1>
        </div>
      </div>



      <div class="grid-x grid-margin-x grid-margin-y ">
        <div class="large-12 cell">
          <div class="callout">
            <h3>We&rsquo;re stoked you want to try! </h3>
            <p>To get going, this file (index.html) includes some basic styles you can modify, play around with, or totally destroy to get going.</p>
            <p>Once you've exhausted the fun in this document, you should check out:</p>
            <div class="grid-x grid-margin-x grid-margin-y ">
              <div class="large-4 medium-4 medium-push-2 cell">
                <p><a target="_blank" href="https://get.foundation/sites/docs/">Foundation Documentation</a><br />Everything you need to know about using the framework.</p>
              </div>
              <div class="large-4 medium-4 medium-push-2 cell">
                <p><a target="_blank" href="https://github.com/foundation/foundation-emails/discussions">Foundation Forum</a><br />Join the Foundation community to ask a question or show off your knowlege.</p>
              </div>
            </div>
            <div class="grid-x grid-margin-x grid-margin-y ">
              <div class="large-4 medium-4 medium-push-2 cell">
                <p><a href="http://github.com/foundation/foundation-sites">Foundation on Github</a><br />Latest code, issue reports, feature requests and more.</p>
              </div>
              <div class="large-4 medium-4 medium-pull-2 cell">
                <p><a target="_blank" href="https://twitter.com/FoundationCSS">@FoundationCSS</a><br />Ping us on Twitter if you have questions. When you build something with this we'd love to see it (and send you a totally boss sticker).</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grid-x grid-margin-x grid-margin-y ">
        <div class="large-8 medium-8 cell">
          <h5>Here&rsquo;s your basic grid:</h5>
          <!-- Grid Example -->

          <div class="grid-x grid-margin-x grid-margin-y ">
            <div class="large-12 cell">
              <div class="dx-card has-paddings">
                <p><strong>This is a twelve cell section in a grid-x.</strong> Each of these includes a div.dx-card element so you can see where the cell are - it's not required at all for the grid.</p>
              </div>
            </div>
          </div>
          <div class="grid-x grid-margin-x grid-margin-y ">
            <div class="large-6 medium-6 cell">
              <div class="dx-card has-paddings">
                <p>Six cell</p>
              </div>
            </div>
            <div class="large-6 medium-6 cell">
              <div class="dx-card has-paddings">
                <p>Six cell</p>
              </div>
            </div>
          </div>
          <div class="grid-x grid-margin-x grid-margin-y ">
            <div class="large-4 medium-4 small-4 cell">
              <div class="dx-card has-paddings">
                <p>Four cell</p>
              </div>
            </div>
            <div class="large-4 medium-4 small-4 cell">
              <div class="dx-card has-paddings">
                <p>Four cell</p>
              </div>
            </div>
            <div class="large-4 medium-4 small-4 cell">
              <div class="dx-card has-paddings">
                <p>Four cell</p>
              </div>
            </div>
          </div>

          <hr />

          <h5>Headers:</h5>


          <h1>h1. This is a very large header.</h1>
          <h2>h2. This is a large header.</h2>
          <h3>h3. This is a medium header.</h3>
          <h4>h4. This is a moderate header.</h4>
          <h5>h5. This is a small header.</h5>
          <h6>h6. This is a tiny header.</h6>

        </div>

        <div class="large-4 medium-4 cell">
          <h5>Try one of these buttons:</h5>
          <p><a href="#" class="button">Simple Button</a><br/>
            <a href="#" class="success button">Success Btn</a><br/>
            <a href="#" class="alert button">Alert Btn</a><br/>
            <a href="#" class="secondary button">Secondary Btn</a></p>
          <div class="callout">
            <h5>So many components, girl!</h5>
            <p>A whole kitchen sink of goodies comes with Foundation. Check out the docs to see them all, along with details on making them your own.</p>
            <a target="_blank" href="https://get.foundation/sites/docs/" class="small button">Go to Foundation Docs</a>
          </div>
        </div>
      </div>
    </div>

</template>

<style lang="scss">

</style>
