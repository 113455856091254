<template>
<svg version="1.1" id="Logo-Quantum" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 740.1 59.6" style="enable-background:new 0 0 740.1 59.6;" xml:space="preserve">
<path class="st0" d="M501.8,2.5c-3.2-0.1-6.4-0.3-9.6,0.1c-1.2,0.1-2.4,1.5-3.4,2.6c-1.2,1.2-10.6,14.4-16.4,22.3v-0.2
c-2,2.3-4.7,5.8-6.2,5.3c-0.1,0-0.2-0.1-0.2-0.1c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0,0,0c-0.2-0.2-0.5-0.5-0.8-0.8
C460,25,447,6.7,445.6,5.2c-1-1-2.2-2.5-3.4-2.6c-3.1-0.4-6.4-0.2-9.6-0.1c-1,0-1.5,0.7-1.1,1.6c0.7,1.4,1.5,2.8,2.5,4
c1,1.2,1.7,2.4,1.6,4.1c0,14.2,0,28.5,0,42.7c0,1.7,0.8,2.5,2.5,2.5c1.5,0,4.9,0,6.4,0c1.9,0,3-1,3-3c-0.1-9.7-0.2-14.5-0.3-24.2
c0-1.1,0.1-2.1,1.2-2.4c1-0.3,1.8,0.3,2.5,1.2c1.4,1.8,7.4,10.6,13.4,18l0.8,1c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0,0,0
c0.2,0.3,0.7,1,1.2,1.4c0.1,0.1,0.2,0.2,0.2,0.3c0,0,0,0,0-0.1l0,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.2,0.1
c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0.1,0,0.1c0,0,0.1-0.1,0.1-0.1c0.4-0.2,0.7-0.5,1.1-0.9c0,0,0,0,0,0
c0.1-0.1,0.2-0.3,0.4-0.4c1.5-1.7,3.2-4.2,3.2-4.2v-0.2c5.2-6.7,9.9-13.5,11.1-15.1c0.7-0.9,1.4-1.5,2.5-1.2
c1.1,0.3,1.2,1.3,1.2,2.4c-0.1,9.7-0.2,14.5-0.3,24.2c0,1.9,1.1,3,3,3c1.5,0,4.9,0,6.4,0c1.7,0,2.5-0.8,2.5-2.5
c0-14.2,0-28.5,0-42.7c0-1.6,0.7-2.9,1.7-4.1c1-1.2,1.8-2.6,2.5-4C503.3,3.3,502.8,2.6,501.8,2.5"/>
<path class="st0" d="M190.7,39.3c-10.2,0.8-14.6,1.4-19.9,3c-1,0.3-2,0.6-3,0.8c-1,0.2-1.7-0.4-1.2-1.4c1.3-2.8,2.6-5.6,4-8.4
c2.1-4.2,4.2-8.4,6.4-12.5c0.4-0.8,1.1-1.5,1.9-1.9c0.3-0.1,1.3,0.9,1.7,1.6C182.8,24.4,190.5,39,190.7,39.3 M209.4,54.9
c-1.4-2.6-2.8-5.1-4.2-7.7c-2.6-4.9-5.1-9.9-7.7-14.8c-3.1-5.8-6.2-11.6-9.4-17.4c-2.1-4-4.2-8-6.4-12c-0.7-1.3-1.9-2.2-3.5-2.1
c-1.5,0.1-2.9,0.2-4.4,0.3c-1,0.1-2.2,0.5-3.3,0.7c0.3,1,0.8,2.2,1.4,3c1.3,1.8,1.2,3.5,0.3,5.4c-1.9,3.5-3.7,7.1-5.6,10.6
c-3.8,7.2-7.6,14.5-11.4,21.7c-2.2,4.3-4.5,8.6-6.8,12.9c-0.6,1.1,0,2,1.2,2.1c1,0,2,0,3,0c2.5,0.1,4.9,0.2,6.6-2
c3.6-3.5,19-7.9,33.6-8.6c1.2-0.1,2.6,2.4,3.3,4.1c0.5,1.2,1.3,2.4,1.8,3.6c0.8,2.3,2.3,3.3,4.7,2.9c0.6-0.1,1.2,0,1.8,0
c1,0,2.1,0,3.1,0C210,57.5,210.4,56.9,209.4,54.9"/>
<path class="st0" d="M280.4,29.8v-0.6c0-8,0-16,0-24c0-1.8-0.8-2.7-2.5-2.7c-1.9,0-3.8,0-5.7,0c-1.8,0-2.2,0.5-2.2,2.3
c0,9.8,0,19.6,0,29.4c0,1,0,2,0,3c0,1.8-1,2.5-2.6,1.6c-0.7-0.4-1.3-0.9-1.8-1.5c-2.2-2.5-4.4-5.2-6.6-7.7
c-2.1-2.4-4.2-4.7-6.2-7.1c-3.4-4-6.9-8-10.3-12c-1.5-1.8-2.9-3.6-4.5-5.3c-1-1-2.2-2.5-3.4-2.6c-3.1-0.4-6.4-0.2-9.6-0.1
c-1,0-1.5,0.7-1.1,1.6c0.7,1.4,1.5,2.8,2.5,4c1,1.2,1.7,2.4,1.6,4.1c0,14.2,0,28.5,0,42.7c0,1.7,0.8,2.5,2.5,2.5
c1.5,0,3.1,0,4.6,0c1.9,0,3-1,3-3c-0.1-9.7-0.2-19.5-0.3-29.2c0-1.1,0.1-2.1,1.2-2.4c1-0.3,1.8,0.3,2.5,1.2
c2.2,2.9,21.4,25.5,24.1,28.6c1.5,1.7,2.9,3.8,4.8,4.6c1.9,0.8,4.4,0.2,6.7,0.2c2.2,0,3.2-1.7,3.3-3.3
C280.4,46,280.4,37.9,280.4,29.8"/>
<path class="st0" d="M345.1,13.3c0.8,0,1.4-0.6,1.4-1.4V4.2c0-0.8-0.6-1.4-1.4-1.4h-48.4c-0.8,0-1.4,0.6-1.4,1.4v7.7
c0,0.8,0.6,1.4,1.4,1.4h17.1c0.8,0,1.4,0.6,1.4,1.4v40.8c0,0.8,0.6,1.4,1.4,1.4h7.1c0.8,0,1.4-0.6,1.4-1.4V14.7
c0-0.8,0.6-1.4,1.4-1.4H345.1L345.1,13.3z"/>
<path class="st0" d="M54.4,29.5c0,4.2-1.7,8.1-4.5,11.2l0,0l-9.1-5.8c-0.7-0.4-1.5-0.2-2,0.4l-4.4,6.9c-0.4,0.7-0.2,1.5,0.4,2
l4.5,2.9c-2.3,0.7-4.8,1-7.3,1c-12.3,0-22.3-8.4-22.3-18.7s10-18.7,22.3-18.7S54.4,19.2,54.4,29.5 M64.7,29.8
C64.7,13.3,50.2,0,32.3,0S0,13.3,0,29.8s14.5,29.8,32.3,29.8c6.8,0,13.1-2,18.3-5.2l6.6,4.2c0.7,0.4,1.5,0.2,2-0.4l4.4-6.9
c0.4-0.7,0.2-1.5-0.4-2L59,46.6C62.6,41.8,64.7,36,64.7,29.8"/>
<path class="st0" d="M136.1,21.8c0-5.7,0-11.3,0-17c0-1.7-0.5-2.2-2.2-2.2c-2,0-3.9,0-5.9,0c-2,0-2.8,0.7-2.8,2.7
c0,11,0.1,22,0,33c0,3.5-1.9,6.2-4.8,8c-2.9,1.9-6.4,2-9.8,2.2c-2.5,0.2-5-0.2-7.5-0.6c-2.2-0.4-4.4-0.8-6.2-2.4
c-2.6-2.4-3.5-5.6-3.5-8.9C93.5,26,93.6,15.5,93.6,4.9c0-2-0.4-2.4-2.5-2.4c-2.1,0-4.1,0-6.1,0c-1.2,0-2.2,0.9-2.2,2.1
c0.3,10.5,0.1,32.6,0.1,33.1c0,12.1,11.9,21.9,26.6,21.9S135.9,49.9,136,38c0.1-0.1,0.1-0.3,0.1-0.4
C136.2,32.3,136.2,27,136.1,21.8"/>
<path class="st0" d="M410.8,21.8c0-5.7,0-11.3,0-17c0-1.7-0.5-2.2-2.2-2.2c-2,0-3.9,0-5.9,0c-2,0-2.8,0.7-2.8,2.7
c0,11,0.1,22,0,33c0,3.5-1.9,6.2-4.8,8c-3,1.9-6.4,2-9.8,2.2c-2.5,0.2-5-0.2-7.5-0.6c-2.2-0.4-4.4-0.8-6.2-2.4
c-2.6-2.4-3.5-5.6-3.5-8.9c-0.2-10.5-0.1-21.1-0.1-31.6c0-2-0.4-2.4-2.5-2.4c-2,0-4.1,0-6.1,0c-1.2,0-2.2,0.9-2.2,2.1
c0.3,10.5,0.1,32.6,0.1,33.1c0,12.1,11.9,21.9,26.6,21.9s26.4-9.7,26.6-21.6c0.1-0.1,0.1-0.3,0.1-0.4
C410.9,32.3,410.9,27,410.8,21.8"/>
<path class="st1" d="M738.8,2.5c-3.2-0.1-6.4-0.3-9.6,0.1c-1.2,0.1-2.4,1.5-3.4,2.6c-1.2,1.2-10.6,14.4-16.4,22.3v-0.2
c-2,2.3-4.7,5.8-6.2,5.3c-0.1,0-0.2-0.1-0.2-0.1c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0,0,0c-0.2-0.2-0.5-0.5-0.8-0.8
c-4.7-6.3-17.8-24.6-19.2-26c-1-1-2.2-2.5-3.4-2.6c-3.2-0.4-6.4-0.2-9.6-0.1c-1,0-1.5,0.7-1.1,1.6c0.7,1.4,1.5,2.8,2.5,4
c1,1.2,1.7,2.4,1.7,4.1c0,14.2,0,28.5,0,42.7c0,1.7,0.8,2.5,2.5,2.5c1.5,0,4.9,0,6.4,0c1.9,0,3-1,3-3c-0.1-9.7-0.2-14.5-0.3-24.2
c0-1.1,0.1-2.1,1.2-2.4c1-0.3,1.8,0.3,2.5,1.2c1.3,1.8,7.4,10.6,13.4,18l0.8,1c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0,0,0
c0.2,0.3,0.7,1,1.2,1.4c0.1,0.1,0.2,0.2,0.2,0.3c0,0,0,0,0-0.1l0,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.2,0.1
c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0.1,0,0.1c0,0,0.1-0.1,0.1-0.1c0.4-0.2,0.7-0.5,1.1-0.9c0,0,0,0,0,0
c0.1-0.1,0.2-0.3,0.4-0.4c1.5-1.7,3.2-4.2,3.2-4.2v-0.2c5.2-6.7,9.9-13.5,11.1-15.1c0.7-0.9,1.4-1.5,2.5-1.2
c1.1,0.3,1.2,1.3,1.2,2.4c-0.1,9.7-0.2,14.5-0.3,24.2c0,1.9,1.1,3,3,3c1.5,0,4.9,0,6.4,0c1.7,0,2.5-0.8,2.5-2.5
c0-14.2,0-28.5,0-42.7c0-1.6,0.7-2.9,1.7-4.1c1-1.2,1.8-2.6,2.5-4C740.4,3.3,739.8,2.6,738.8,2.5"/>
<path class="st1" d="M588.9,35.4L588.9,35.4l-0.1-5.6c0-1.6-1.3-2.8-2.8-2.8h-24.8c-1.6,0-2.8,1.3-2.8,2.8v3
c0,1.6,1.3,2.8,2.8,2.8h11.6c2.6,0.1,3,1.2,3,1.9c0,0.8-3.5,5-4.6,6.1c-3.5,2.1-7.7,3.4-12.3,3.4c-11.5,0-20.8-7.9-20.8-17.5
S547.4,12,558.9,12c6.1,0,11.6,2.2,15.4,5.7l8-6.9C576.4,4.5,567.7,0.5,558,0.5c-17.5,0-31.6,12.9-31.6,28.9s14.2,28.9,31.6,28.9
c15.1,0,27.7-9.7,30.9-22.6h0v-0.1C588.9,35.5,588.9,35.5,588.9,35.4"/>
<path class="st1" d="M612.6,3.6c-0.5-0.6-1.4-1.2-2.2-1.2h-10.3c-0.8,0-1.1,0.5-0.6,1.2L620.9,35c0.4,0.6,0.8,1.8,0.8,2.6V56
c0,0.8,0.6,1.4,1.4,1.4h8.2c0.8,0,1.4-0.6,1.4-1.4l-0.1-18.4c0-0.8,0.3-1.9,0.8-2.6l21.3-31.4c0.4-0.6,0.2-1.2-0.6-1.2h-9.8
c-0.8,0-1.8,0.5-2.3,1.1l-14.3,19.5c-0.5,0.6-1.2,0.6-1.7,0L612.6,3.6L612.6,3.6z"/>
</svg>
</template>

