<template>

  <h2 class="content-block">
    <i class="dx-icon nav-icon fa-light fa-file-measurement"></i> 
    {{ title }}
  </h2>
  
    <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block">
  
      <div class="dx-card no-paddings dx-card-has-minheight">
  
        <DxDataGrid
          id="gridContainer"
          :data-source="customDataSource"
  
          :focused-row-enabled="false"
          :column-auto-width="true"
          :column-hiding-enabled="true"
          
          :allow-column-reordering="true"
          :show-borders="false"
  
          @editing-start="logEvent('EditingStart')"
          @init-new-row="logEvent('InitNewRow')"
          @row-inserting="logEvent('RowInserting')"
          @row-inserted="logEvent('RowInserted')"
          @row-updating="logEvent('RowUpdating')"
          @row-updated="logEvent('RowUpdated')"
          @row-removing="logEvent('RowRemoving')"
          @row-removed="logEvent('RowRemoved')"
          @saving="logEvent('Saving')"
          @saved="logEvent('Saved')"
          @edit-canceling="logEvent('EditCanceling')"
          @edit-canceled="logEvent('EditCanceled')"
        >
  
        <DxPaging :page-size="50" />
        <DxPager :show-page-size-selector="true" :show-info="true" />
        <DxFilterRow :visible="true" />
  
        <DxEditing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          mode="row"
        />
  
        <!-- <DxColumn 
          alignment='left' 
          data-field="id" 
          caption="Id"
          placeholder="Id"
          data-type="number"
          :width="90" 
          :allow-editing="false"
          :hiding-priority="0" 
        /> -->
  
        <!-- <DxColumn 
          alignment='left' 
          data-field="Customerid" 
          caption="Customer Id"
          placeholder="Customer Id"
          data-type="number"
          :width="90" 
          :allow-editing="false"
          :hiding-priority="11" 
        /> -->

        <DxColumn
          alignment='left' 
          data-field="Subject"
          :caption="currentUser.Translation.vueappTaskSubject"
          :placeholder="currentUser.Translation.vueappTaskSubject"
          cell-template="subjectTemplate"
          :hiding-priority="99"
        />
        <template #subjectTemplate="{ data: content }">
          <div class="grid-x align-middle ck-itemrow">
            <div class="cell">
              <p class="ck-overflow-ellipsis strong">
                {{ content.data.Subject }}
              </p>
              <p class="ck-overflow-ellipsis small">
              </p>
            </div>
          </div>
        </template>

  
        <!-- <DxColumn
          alignment='left' 
          data-field="Status"
          editor-type="dxSelectBox"
          :caption="currentUser.Translation.vueappTaskStatus"
          :placeholder="currentUser.Translation.vueappTaskStatus"
          :editor-options="statusEditorOptions"
          :validation-rules="validationRules.status"
          :hiding-priority="9"
        /> -->
  
        <!-- <DxColumn
          alignment='left' 
          data-field="Priority"
          editor-type="dxSelectBox"
          :caption="currentUser.Translation.vueappTaskPriority"
          :placeholder="currentUser.Translation.vueappTaskPriority"
          :editor-options="priorityEditorOptions"
          :validation-rules="validationRules.priority"
          :hiding-priority="8"
        /> -->
  
        <!-- <DxColumn
          alignment='left' 
          data-field="Description"
          :caption="currentUser.Translation.vueappTaskDescription"
          :placeholder="currentUser.Translation.vueappTaskDescription"
          :hiding-priority="7"
        /> -->
  
        <!-- <DxColumn
          alignment='left' 
          data-field="CreationDate"
          data-type="date"
          format="dd.MM.yyyy"
          :caption="currentUser.Translation.vueappGlobalCreationDate"
          :placeholder="currentUser.Translation.vueappGlobalCreationDate"
          :allow-editing="false"
          :hiding-priority="6"
        /> -->
  
        <!-- <DxColumn
          alignment='left' 
          data-field="ModificationDate"
          data-type="date"
          format="dd.MM.yyyy"
          :caption="currentUser.Translation.vueappGlobalModificationDate"
          :placeholder="currentUser.Translation.vueappGlobalModificationDate"
          :allow-editing="false"
          :hiding-priority="5"
        /> -->
  
        
        <DxMasterDetail
          :enabled="true"
          template="masterDetailTemplate"
        />
        <template #masterDetailTemplate="{ data: content }">
          <DetailTemplate
            :template-data="content"
          />
        </template>
  
      </DxDataGrid>
  
    </div><!-- dx-card -->
    
    </div><!-- content-block -->
  
    <div v-else class="content-block">
      Oh no 😢
    </div><!-- content-block -->
  
    <div class="content-block hide">
      <div id="events">
  
        <div class="grid-x grid-margin-x"> 
          <div class="auto cell"> 
            <div class="caption">
              Fired events:
            </div>
            <ul class="menu vertical">
            <li
              v-for="(event, index) in events"
              :key="index"
            >{{ event }}</li>
            </ul>
          </div>
          <div class="shrink cell"> 
            <DxButton
              id="clear"
              text="Clear"
              @click="clearEvents()"
            />
          </div>
        </div><!-- grid-x -->
  
      </div><!-- events -->
    </div><!-- content-block -->
  
  </template>
  
  <script>
  
  import {
    DxDataGrid, 
    DxFilterRow, 
    DxColumn, 
    DxEditing, 
    DxPager, 
    DxPaging, 
    DxButton,
    DxMasterDetail,
    //DxSelection,
    // DxScrolling,

    //DxLookup, 
  } from 'devextreme-vue/data-grid';
  //import { employees } from '../datamembers.js';
  
  import CustomStore from 'devextreme/data/custom_store';
  import DetailTemplate from '../parts/measurement-item-detail.vue';

  import auth from "../auth";

  import { 
    apihost, 
  } from "../api";
  //console.log(apihost);
    
  const colCountByScreen = {
    xs: 1,
    sm: 1,
    md: 2,
    lg: 3
  }

  let currentUser;
  
  auth.getUser().then((e) => {
    if(e.data){
      // console.log(e.data);
      // console.log(e.data.Constant);
      // console.log(e.data.Constant.roles);
      // console.log(e.data.Constant.measurementcategory);
      
      currentUser = e.data;
    } // e.data
  }); //auth
  
  function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
  }

  const customDataSource = new CustomStore({
    key: 'id',
    load: () => {
      return fetch(apihost+'/de/vue/measurement/list/')
      .then(handleErrors)
      .then(response => response.text())
      .then(data => {
      //console.log(`response text`, text)
      return JSON.parse(data)
      })
      .catch(() => { throw 'Network error' });
    },
    insert: (values) => {
      //console.log(values);
      const key = '0';
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/measurement/getmeasurement/'+key+'/insert'+objString, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    remove: (key) => {
      //console.log(key);
      return fetch(apihost+'/de/vue/measurement/getmeasurement/'+key+'/remove', {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    update: (key, values) => {
      // console.log(key);
      // console.log(values);
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/measurement/getmeasurement/'+key+'/save'+objString, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    }
  
  });
  

  export default {
  
    mounted() {

      this.timestamp = this.printTimestamp();
  
    },
  
    components: {
      DxDataGrid,
      DxMasterDetail,
      DxFilterRow,
      DxColumn,
      DxEditing,
      DxPager,
      DxPaging,
      DxButton,
      DetailTemplate,
      // DxForm,
      // DxGroupItem,
      // DxSimpleItem,
      // DxButtonItem,
      // DxLabel,
      // DxFileUploader,
      // DxDropDownBox,
      // DxSelection,
      // DxScrolling,
      //DxTextBox,
      //DxRequiredRule,
      //DxCompareRule,
      //DxPatternRule,
      //DxRangeRule,
      //DxEmailRule,
      //DxStringLengthRule,
      //DxAutocomplete,
      //DxAsyncRule,
      //DxLookup,


    },
    data() {
      return {
        apihost,

        title: currentUser.Translation.vueappNavMemberMeasurements,
        events: [],
        formInstance: null,
        // statuses,
        // priorities,
        // measurementtypes,
        // measurementcategory,
        currentUser,
        customDataSource,
        colCountByScreen,

        // //Option 1
        // option1DataSource,
        // option1Columns,
        // option1BoxValue,
        // //changeOption1,
        // //readyOption1,

        // //Option 2
        // option2DataSource,
        // option2Columns,
        // option2BoxValue,
        // //changeOption2,
        // //readyOption2,
  
        calendarOptions: { 
          displayFormat: "dd.MM.yyyy"
        },
        buttonOptions: {
          text: currentUser.Translation.vueappMeasurementFormSubmit,
          type: 'success',
          useSubmitBehavior: true,
        },
        dateBoxOptions: {
          invalidDateExercise:
            'The date must have the following format: MM/dd/yyyy',
        },
        checkBoxOptions: {
          text: 'I agree to the Terms and Conditions',
          value: false,
        },
        phoneEditorOptions: {
          mask: '+1 (X00) 000-0000',
          maskRules: {
            X: /[02-9]/,
          },
          maskInvalidExercise: 'The phone must have a correct USA phone format',
        },
  
        statusPattern: /^[^0-9]+$/,
        priorityPattern: /^[^0-9]+$/,
        measurementPattern: /^[^0-9]+$/,
  
        // phonePattern: /^[02-9]\d{9}$/,
        // maxDate: new Date().setFullYear(new Date().getFullYear() - 21),
        
        validationRules: {
  
          // status: [
          //   { type: 'required', measurement: currentUser.Translation.vueappTaskStatusExercise },
          // ],
          // priority: [
          //   { type: 'required', measurement: currentUser.Translation.vueappTaskPriorityExercise },
          // ],
        },
        //statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
        //priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },
        // statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
        // priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },
      };
    },
    methods: {

      validateForm(e) {
        e.component.validate();
      },
      
      logEvent(eventName) {
        //console.log(this.events);
        this.events.unshift(eventName);
      },
      clearEvents() {
        this.events = [];
      },
  
      printTimestamp: function () {
          return Date.now();
      },
  
    },
  };
  
  
</script>

<style>

</style>
    