<template>

  <h2 class="content-block">
    <i class="dx-icon nav-icon fa-light fa-users"></i> 
    {{ title }}
  </h2>

  <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block no-padding">

    <div class="dx-card no-paddings dx-card-has-minheight">

      <DxDataGrid
        id="gridContainer"
        :data-source="customDataSource"

        :focused-row-enabled="false"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        
        :allow-column-reordering="true"
        :show-borders="false"

        @editing-start="logEvent('EditingStart')"
        @init-new-row="logEvent('InitNewRow')"
        @row-inserting="logEvent('RowInserting')"
        @row-inserted="logEvent('RowInserted')"
        @row-updating="logEvent('RowUpdating')"
        @row-updated="logEvent('RowUpdated')"
        @row-removing="logEvent('RowRemoving')"
        @row-removed="logEvent('RowRemoved')"
        @saving="logEvent('Saving')"
        @saved="logEvent('Saved')"
        @edit-canceling="logEvent('EditCanceling')"
        @edit-canceled="logEvent('EditCanceled')"
      >

      <DxPaging :page-size="50" />
      <DxPager :show-page-size-selector="true" :show-info="true" />
      <DxFilterRow :visible="true" />

      <DxEditing
        :allow-updating="true"
        :allow-deleting="true"
        :allow-adding="true"
        mode="row"
      />

      <!-- <DxColumn 
        alignment='left' 
        data-field="id" 
        caption="Id"
        placeholder="Id"
        data-type="number"
        :allow-editing="false"
        :width="90" 
        :hiding-priority="19" 
      /> -->

      <DxColumn 
        alignment='left' 
        data-field="Avatar" 
        caption=""
        placeholder=""
        :allow-sorting="false"
        :allow-editing="false"
        :allow-filtering="false"
        cell-template="avatarTemplate"
        :width="80" 
        :hiding-priority="20" 
      />

      <template #avatarTemplate="{ data }">
        <div class="grid-x align-middle ck-itemrow">
            <div class="cell">
              <div class="user-thumb" v-if="data.value"
                v-bind:style="{ 'background-image': 'url(' + data.value + '?v=' + timestamp + ')' }" >
              </div>
          </div>
        </div>
      </template>

      
      <DxColumn
        alignment='left' 
        data-field="Title"
        :caption="currentUser.Translation.vueappCustomerPrefix"
        :placeholder="currentUser.Translation.vueappCustomerPrefix"
        :editor-options="genderEditorOptions"
        :validation-rules="validationRules.gender"
        editor-type="dxSelectBox"
        :hiding-priority="17" 
      />
      <DxColumn 
        alignment='left' 
        data-field="FirstName"
        :caption="currentUser.Translation.vueappCustomerFirstName"
        :placeholder="currentUser.Translation.vueappCustomerFirstName"
        :validation-rules="validationRules.firstname"
        :hiding-priority="16" 
      />
      <DxColumn 
        alignment='left' 
        data-field="LastName"
        :caption="currentUser.Translation.vueappCustomerLastName"
        :placeholder="currentUser.Translation.vueappCustomerLastName"
        :validation-rules="validationRules.lastname"
        :hiding-priority="15" 
      />
      <DxColumn
        alignment='left' 
        data-field="Email"
        :caption="currentUser.Translation.vueappCustomerEmail"
        :placeholder="currentUser.Translation.vueappCustomerEmail"
        :validation-rules="validationRules.email"
        :hiding-priority="14" 
      />
      <!-- <DxColumn
        alignment='left' 
        data-field="Phone"
        :caption="currentUser.Translation.vueappCustomerPhone"
        :placeholder="currentUser.Translation.vueappCustomerPhone"
        :hiding-priority="13" 
      /> -->
      <!-- <DxColumn
        data-field="Street"
        alignment='left' 
        :caption="currentUser.Translation.vueappCustomerStreet"
        :placeholder="currentUser.Translation.vueappCustomerStreet"
        :hiding-priority="12" 
      /> -->
      <!-- <DxColumn
        alignment='left' 
        data-field="Zip"
        :caption="currentUser.Translation.vueappCustomerZip"
        :placeholder="currentUser.Translation.vueappCustomerZip"
        :width="90" 
        :hiding-priority="11" 
      /> -->
      <!-- <DxColumn
        alignment='left' 
        data-field="City"
        :caption="currentUser.Translation.vueappCustomerCity"
        :placeholder="currentUser.Translation.vueappCustomerCity"
        :hiding-priority="10" 
      /> -->
      <!-- <DxColumn 
        alignment='left' 
        data-field="Setpassword" 
        :caption="currentUser.Translation.vueappCustomerPassword"
        placeholder="&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;"
        :hiding-priority="8" 
      /> -->
      <!-- <DxColumn
        alignment='left' 
        data-field="Notes"
        :caption="currentUser.Translation.vueappCustomerNotes"
        :placeholder="currentUser.Translation.vueappCustomerNotes"
        :hiding-priority="7"
      /> -->

      <DxColumn
        alignment='left' 
        data-field="Role"
        :caption="currentUser.Translation.vueappCustomerRole"
        :placeholder="currentUser.Translation.vueappCustomerRole"
        :editor-options="roleEditorOptions"
        :validation-rules="validationRules.role"
        editor-type="dxSelectBox"
        :hiding-priority="18" 
      />
      <DxColumn
        alignment='left' 
        data-field="BirthDate"
        data-type="date"
        format="dd.MM.yyyy"
        :caption="currentUser.Translation.vueappCustomerBirthDate"
        :placeholder="currentUser.Translation.vueappCustomerBirthDate"
        :width="125"
        :hiding-priority="9" 
      />

      <DxMasterDetail
        :enabled="true"
        template="taskInfo"
      />

      <template #taskInfo="{ data: content }">

        <DxTabPanel
          :swipe-enabled="false"
        >

          <DxItem 
            :title="currentUser.Translation.vueappNavMemberProfile" 
            icon="fa-light fa-user"
            badge=""
          >
          <template #default>
            <CustomerProfile 
              :customerData="content.data"
            />
          </template>
          </DxItem>

          <DxItem 
          :title="currentUser.Translation.vueappNavMemberHealthcheck" 
          icon="fa-light fa-heart-pulse" 
          badge=""
          >
          <template #default>
            <CustomerHealthcheck 
              :customerData="content.data"
            />
          </template>
          </DxItem>

          <DxItem 
          :title="currentUser.Translation.vueappNavMemberMeasurements" 
          icon="fa-light fa-weight-scale" 
          badge=""
          >
          <template #default>
            <CustomerMeasurement 
              :customerData="content.data"
            />
          </template>
          </DxItem>

          <DxItem 
          :title="currentUser.Translation.vueappNavMemberTasks" 
          icon="fa-light fa-list-check" 
          badge=""
          >
          <template #default>
            <CustomerTasklist 
              :customerData="content.data"
            />
          </template>
          </DxItem>

          <DxItem 
          :title="currentUser.Translation.vueappNavMemberMessages" 
          icon="fa-light fa-messages" 
          badge=""
          >
          <template #default>
            <CustomerMessage 
              :customerData="content.data"
            />
          </template>
          </DxItem>

          <DxItem 
          :title="currentUser.Translation.vueappNavMemberCourses" 
          icon="fa-light fa-dumbbell" 
          badge=""
          >
          <template #default>
            <CustomerTraining
              :customerData="content.data"
            />
          </template>
          </DxItem>

          <DxItem 
          :title="currentUser.Translation.vueappNavMemberExercises" 
          icon="fa-light fa-dumbbell" 
          badge=""
          >
          <template #default>
            <CustomerExercises
              :customerData="content.data"
            />
          </template>
          </DxItem>

          
          <DxItem 
          :title="currentUser.Translation.vueappNavMemberContracts" 
          icon="fa-light fa-file-contract"
          badge=""
          >
          <template #default>
            <CustomerContract 
              :customerData="content.data"
            />
          </template>
          </DxItem>

          <DxItem 
          :title="currentUser.Translation.vueappNavMemberInvoices" 
          icon="fa-light fa-file-invoice"
          badge=""
          >
          <template #default>
            <CustomerInvoice 
              :customerData="content.data"
            />
          </template>
          </DxItem>

        </DxTabPanel>


      </template>

      
    </DxDataGrid>
    
  </div><!-- dx-card -->

  </div><!-- content-block -->

  <div v-else class="content-block">
    Oh no 😢
  </div><!-- content-block -->

  <div class="content-block hide">
    <div id="events">

      <div class="grid-x grid-margin-x"> 
        <div class="auto cell"> 
          <div class="caption">
            Fired events:
          </div>
          <ul class="menu vertical">
          <li
            v-for="(event, index) in events"
            :key="index"
          >{{ event }}</li>
          </ul>
        </div>
        <div class="shrink cell"> 
          <DxButton
            id="clear"
            text="Clear"
            @click="clearEvents()"
          />
        </div>
      </div><!-- grid-x -->

    </div><!-- events -->
  </div><!-- content-block -->

  <div class="content-block hide">
    <p style="margin: 15px 5px; font-size: 11px;"><a href="https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/RowEditingAndEditingEvents/Vue/Light/" target="_blank" style="color: grey;"><i class="fa-solid fa-arrow-up-right-from-square"></i> https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/RowEditingAndEditingEvents/Vue/Light/</a></p>
  </div><!-- content-block -->

</template>

<script>

  import {
    DxDataGrid, 
    DxFilterRow, 
    DxColumn, 
    DxEditing, 
    DxPager, 
    DxPaging, 
    DxButton,
    DxMasterDetail,
    //DxLookup, 
  } from 'devextreme-vue/data-grid';

  import CustomStore from 'devextreme/data/custom_store';
  import DxTabPanel, { DxItem } from "devextreme-vue/tab-panel";

  //PARTS
  import CustomerProfile from "../parts/customer-profile";
  import CustomerTasklist from "../parts/customer-tasklist";
  import CustomerHealthcheck from "../parts/customer-healthcheck";
  import CustomerMeasurement from "../parts/customer-measurement";
  import CustomerTraining from "../parts/customer-course";
  import CustomerExercises from "../parts/customer-exercise";
  import CustomerInvoice from "../parts/customer-invoice";
  import CustomerContract from "../parts/customer-contract";
  import CustomerMessage from "../parts/customer-message";
  import auth from "../auth";

  import { 
    apihost, 
  } from "../api";
  //console.log(apihost);

  let currentUser;
  let timestamp;

  auth.getUser().then((e) => {
    if(e.data){
      //console.log(e.data);
      currentUser = e.data;
    } // e.data
  }); //auth

  function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
  }
  
  const customDataSource = new CustomStore({
    key: 'id',
    load: () => {
      const objString = '?case=customer';
      return fetch(apihost+'/de/vue/member/list/'+objString)
      .then(handleErrors)
      .then(response => response.text())
      .then(data => {
      //console.log(`response text`, text)
      return JSON.parse(data)
      })
      .catch(() => { throw 'Network error' });
    },
    insert: (values) => {
      //console.log(values);
      const key = '0';
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/member/getmember/'+key+'/insert'+objString, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    remove: (key) => {
      //console.log(key);
      return fetch(apihost+'/de/vue/member/getmember/'+key+'/remove', {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    update: (key, values) => {
      // console.log(key);
      // console.log(values);
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/member/getmember/'+key+'/save'+objString, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    }

  });

export default {
  mounted() {
    this.timestamp = Date.now();

  },

  components: {
    DxDataGrid,
    DxMasterDetail,
    DxFilterRow,
    DxColumn,
    DxEditing,
    DxPager,
    DxPaging,
    DxButton,
    DxTabPanel,
    DxItem,
    //DxLookup,

    //PARTS
    CustomerProfile,
    CustomerTasklist,
    CustomerHealthcheck,
    CustomerMeasurement,
    CustomerTraining,
    CustomerExercises,
    CustomerInvoice,
    CustomerContract,
    CustomerMessage,

  },

  data() {

    return {
      apihost,
      
      title: currentUser.Translation.vueappNavMembers,
      events: [],
      currentUser,
      customDataSource,
      timestamp,


      validationRules: {
        gender: [
          { type: 'required', message: currentUser.Translation.vueappCustomerPrefixMessage },
        ],
        firstname: [
          { type: 'required', message: currentUser.Translation.vueappCustomerFirstNameMessage },
        ],
        lastname: [
          { type: 'required', message: currentUser.Translation.vueappCustomerLastNameMessage },
        ],
        email: [
          { type: 'required', message: currentUser.Translation.vueappCustomerEmailMessage },
        ],
        role: [
          { type: 'required', message: currentUser.Translation.vueappCustomerRoleMessage },
        ],
        country: [
          { type: 'required', message: currentUser.Translation.vueappCustomerCountryMessage },
        ],
        language: [
          { type: 'required', message: currentUser.Translation.vueappCustomerLanguageMessage },
        ],
      },
      genderEditorOptions: { items: currentUser.Constant.genders, searchEnabled: true },
      roleEditorOptions: { items: currentUser.Constant.roles, searchEnabled: true },
      countryEditorOptions: { items: currentUser.Constant.countries, searchEnabled: true },
      languageEditorOptions: { items: currentUser.Constant.languages, searchEnabled: true },
    };
  },
  methods: {
    validateForm(e) {
      e.component.validate();
    },
    
    logEvent(eventName) {
      //console.log(this.events);
      this.events.unshift(eventName);
    },
    clearEvents() {
      this.events = [];
    },
  },
};

</script>
<style>
</style>
