<template>  
  <div class="grid-x -grid-margin-x align-middle" style="height: auto; border-bottom: 1px solid #e6e6e6; padding: 6px 10px 6px 0;">
    <div class="shrink cell">
      <div class="user-image" 
        style="width: 40px; height: 40px; border-radius: 50%; background-repeat: no-repeat; background-size: cover; margin: 0 10px;" 
        v-bind:style="{ 'background-image': 'url(' + employee.Avatar + ')' }" >
      </div>
    </div>
    <div class="auto cell">
      <p>{{ employee.FirstName }} {{ employee.LastName }}</p>
    </div>
    <div class="shrink cell">
      <DxButton
      :on-click="showEmployeeInfo"
      class="button-info"
      text="Details"
      />
    </div>
  </div>
  </template>
  <script>
  import { DxButton } from 'devextreme-vue/button';
  
  export default {
    components: {
      DxButton,
    },
  
    props: {
      employee: {
        type: Object,
        required: true,
        default: () => ({}),
      },
      showInfo: {
        type: Function,
        required: true,
        default: () => {},
      },
    },
  
    methods: {
      showEmployeeInfo() {
        this.showInfo(this.employee);
      },
    },
  };
  </script>
  <style>

  </style>
