import axios from "axios";

import { 
  apihost, 
} from "./api";

function bin2hex (s) {
  let i
  let l
  let o = ''
  let n
  s += ''
  for (i = 0, l = s.length; i < l; i++) {
    n = s.charCodeAt(i)
      .toString(16)
    o += n.length < 2 ? '0' + n : n
  }
  return o
}

function reloadPage() {
		window.location.hash = '#';
		window.location.reload();
}

//global scope
let defaultUser;
let store;

//get userstore
if (localStorage.getItem('userstore')) {
  try {
    store = JSON.parse(localStorage.getItem('userstore'));
  } catch(e) {
			//error
  }
} //console.log(store);

//set defaultUser
defaultUser = store;

export default {
  _user: defaultUser,
  
  loggedIn() {
    return !!this._user;
  },

  async logIn(email, password) {

    try {

						let setOk = false;
						let setMessage = '';

      const hex = bin2hex(password);
      //console.log(email, password, hex);
      //console.log(apihost);

      // Send request
      const { data } = await axios.get(apihost+"/de/vue/member/login/"+email+"/"+hex);
      //console.log(data.id);
      //console.log(data);
      
      
      if(data.id){

						const currentUser = {
							id: data.id,
							Rfid: data.Rfid,
							Email: data.Email,
							Avatar: data.Avatar,
							Language: data.Language,
							Role: data.Role,
							Navigation: data.Navigation,
							Translation: data.Translation,
							Location: data.Location,
							Constant: data.Constant,
						};
      //console.log(currentUser);
						
						//save userstore
						const parsed = JSON.stringify(currentUser);
						localStorage.setItem('userstore', parsed);
						//sessionStorage.setItem('session', parsed);

      //set this._user
      this._user = currentUser;
            
							setOk = true;
							//remove Class #root .islogin
							var element = document.getElementById("root");
							element.classList.remove("islogin");
							//Reload after Login
							setTimeout(reloadPage, 0);

						} else {
							setMessage = 'Authentication failed';
						} //data.id
						

						//console.log(setOk);

      return {
        isOk: setOk,
        message: setMessage,
        data: this._user,
      };
    }
    catch {
      return {
        isOk: false,
        message: "Authentication failed",
      };
    }

  },

  async logOut() {
    this._user = null;
    //localStorage.removeItem('userstore');
    localStorage.setItem('userstore', '');
    //sessionStorage.setItem('session', false);
    //Toggle Class root
				var element = document.getElementById("root");
				element.classList.add("islogin");
				//Reload after Logout
				setTimeout(reloadPage, 0);

  },

  async getUser() {
    try {
      // Send request

      return {
        isOk: true,
        data: this._user
      };
    }
    catch {
      return {
        isOk: false
      };
    }
  },

  async resetPassword(email) {
    try {

      // Send request
      const { data } = await axios.get(apihost+"/de/vue/member/resetpass/"+email);
      console.log(data);
      console.log(email);

      return {
        isOk: data.isOk,
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to reset password"
      };
    }
  },

  async changePassword(password, recoveryCode) {
    try {
      // Send request
      //console.log(password, recoveryCode);
      
      const { data } = await axios.get(apihost+"/de/vue/member/changepass/"+recoveryCode+"/"+password);
      console.log(data);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to change password"
      }
    }
  },

  async createAccount(email, password, gender, firstname, lastname, street, zip, city, phone, location, birthdate ) {
    try {
      //console.log(email, password, gender, firstname, lastname, street, zip, city, phone, location );
      
      // Send request
      const { data } = await axios.get(apihost+"/de/vue/member/register/"+email+"/"+password+"?gender="+gender+"&firstname="+firstname+"&lastname="+lastname+"&street="+street+"&zip="+zip+"&city="+city+"&phone="+phone+"&location="+location+"&birthdate="+birthdate );
      console.log(data);


      return {
        isOk: data.isOk,
        message: data.message
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  }
};
