<template>
<svg version="1.1" id="ManFront" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 450 950" style="enable-background:new 0 0 450 950;" xml:space="preserve">
<g id="Hand_MF">
<path class="st0" d="M351.7,461c0,0-0.9,3.1-3,5.6s-4.9,5.8-6.4,11c-1.4,5.2-2.8,16.1-2.7,21.3c0.1,5.2-0.8,17.1,3,18.2
s7.6-4.4,8.3-7.2c0.7-2.8,2-16.5,4.7-17.2s6.9,5.4,9.9,14.3c3,8.9,2,9.9,2,9.9l-12,20.6c0,0-1.6,2.7-0.6,6.5s1.8,4.8,1.8,4.8
s1.7,7.8,3.2,9l3.8-3.5c0,0,0.6,6.2,2.7,7.8s2.1,1.6,2.1,1.6s10.2-22.9,10.9-29.2c0.7-6.4,4.4-60.7,4.1-72.6s-0.1-31.3,0.6-39
s2.3-22.7,2.3-22.7s-7.2,22.7-14.8,34.3s-18.4,25.3-18.4,25.3L351.7,461z"/>
<path class="st0" d="M98.6,461c0,0,0.9,3.1,3,5.6s4.9,5.8,6.4,11c1.4,5.2,2.8,16.1,2.7,21.3c-0.1,5.2,0.8,17.1-3,18.2
s-7.6-4.4-8.3-7.2c-0.7-2.8-2-16.5-4.7-17.2s-6.9,5.4-9.9,14.3c-3,8.9-2,9.9-2,9.9l12,20.6c0,0,1.6,2.7,0.6,6.5s-1.8,4.8-1.8,4.8
s-1.7,7.8-3.2,9l-3.8-3.5c0,0-0.6,6.2-2.7,7.8s-2.1,1.6-2.1,1.6s-10.2-22.9-10.9-29.2c-0.7-6.4-4.4-60.7-4.1-72.6s0.1-31.3-0.6-39
c-0.8-7.7-2.3-22.8-2.3-22.8s7.2,22.7,14.8,34.3c7.6,11.6,18.4,25.3,18.4,25.3L98.6,461z"/>
</g>
<g id="Waden_MF">
<path class="st0" d="M192.6,745.5c-3.3,11.1-7.5,19.9-10.9,27c-2,4.3-3.8,7.9-5,11.3c-0.5,1.3-1.1,3.3-1.8,5.9
c-11-5.3-15.1-15.8-16.3-27v-0.1c0.2-2.2,0.3-4.4,0.5-6.7c0.5-8.4,0.7-16.9,3.3-25c2-6.1,5-11.8,8.6-17c3.6-5.3,8.2-9.8,11.9-15
c2.2-3,4.3-6.1,6.2-9.4C191,698.8,197,730.7,192.6,745.5z"/>
<path class="st0" d="M174.6,790.8c-1.4,5.2-3.2,12.1-4.8,19.4c-11.4-9.3-12-27.1-11.6-41.6C160.4,778,165.3,786.4,174.6,790.8z"/>
<path class="st0" d="M169.5,811.5c-3.3,14.6-6,30.2-5.1,35.2c0.1,0.7,0.3,1.5,0.4,2.2c-7.3-8.1-7.3-20.6-7.4-31.7
c0-1.6,0-3.2,0-4.7c-0.2-10-0.3-19.4-0.1-28.7C158.3,794.6,161.3,805.1,169.5,811.5L169.5,811.5z"/>
<path class="st0" d="M144.6,778.1c-0.1,21.3,3.3,47.3-9.4,65.8c0.1-1.1,0.3-2.2,0.3-3.5c0.7-10-2.8-34.6-6.3-58.4
c-3-20.9-5.8-40.6-5.7-49.3c0.3-15.3,9.5-38.5,12.7-46.2c1.8,5.7,5.6,13.3,8.8,19.3C144.9,729.9,144.7,754,144.6,778.1z"/>
<path class="st0" d="M257.8,745.5c3.3,11.1,7.5,19.9,10.9,27c2,4.3,3.8,7.9,5,11.3c0.5,1.3,1.1,3.3,1.8,5.9
c11-5.3,15.1-15.8,16.3-27v-0.1c-0.2-2.2-0.3-4.4-0.5-6.7c-0.5-8.4-0.7-16.9-3.3-25c-2-6.1-5-11.8-8.6-17c-3.6-5.3-8.2-9.8-11.9-15
c-2.2-3-4.3-6.1-6.2-9.4C259.4,698.8,253.4,730.7,257.8,745.5z"/>
<path class="st0" d="M275.9,790.8c1.4,5.2,3.2,12.1,4.8,19.4c11.4-9.3,12-27.1,11.6-41.6C290.1,778,285.1,786.4,275.9,790.8z"/>
<path class="st0" d="M281,811.5c3.3,14.6,6,30.2,5.1,35.2c-0.1,0.7-0.3,1.5-0.4,2.2c7.3-8.1,7.3-20.6,7.4-31.7c0-1.6,0-3.2,0-4.7
c0.2-10,0.3-19.4,0.1-28.7C292.2,794.6,289.1,805.1,281,811.5L281,811.5z"/>
<path class="st0" d="M305.8,778.1c0.1,21.3-3.3,47.3,9.4,65.8c-0.1-1.1-0.3-2.2-0.3-3.5c-0.7-10,2.8-34.6,6.3-58.4
c3-20.9,5.8-40.6,5.7-49.3c-0.3-15.3-9.5-38.5-12.7-46.2c-1.8,5.7-5.6,13.3-8.8,19.3C305.6,729.9,305.7,754,305.8,778.1z"/>
</g>
<g id="Fuss_MF">
<path class="st0" d="M96,928.4c0,0-0.2-4.4,2.3-6.6c2.4-2.2,5.9-11.7,6.4-13c0.4-1.3,10.8-18.5,14.1-23.8
c3.3-5.3,7.5-10.8,8.1-13.2c0.7-2.4-0.4-8.8,0.9-11s11.9-7,21.6-8.5s19.4,5.8,19.7,9.3c0.2,3.5-2.7,6.9-3.1,8.9
c-0.4,2-0.9,3.1,1.3,7.9c2.2,4.8,5.7,13.2,2,19.4s-10.4,13.8-10.4,13.8s-0.6,10.6-3,15.5c-2.4,4.8-8.8,10.1-8.8,10.1
s-4.2,12.7-16.5,11.7c-5.3-0.4-7-6.4-7-6.4s-6,5.8-9.5,2.6c-2.1-1.9-2-4.9-2-4.9s-3.5,1.6-5.1-0.5s-1.6-4.2-1.6-4.2s-2.1,1-3.9-0.7
c-1.7-1.7-1.8-3.4-1.8-3.4S97.4,931.5,96,928.4z"/>
<path class="st0" d="M354.5,928.4c0,0,0.2-4.4-2.3-6.6c-2.4-2.2-5.9-11.7-6.4-13c-0.4-1.3-10.8-18.5-14.1-23.8
c-3.3-5.3-7.5-10.8-8.1-13.2c-0.7-2.4,0.4-8.8-0.9-11s-11.9-7-21.6-8.5s-19.4,5.8-19.7,9.3c-0.2,3.5,2.7,6.9,3.1,8.9
s0.9,3.1-1.3,7.9s-5.7,13.2-2,19.4s10.4,13.8,10.4,13.8s0.6,10.6,3,15.5c2.4,4.8,8.8,10.1,8.8,10.1s4.2,12.7,16.5,11.7
c5.3-0.4,7-6.4,7-6.4s6,5.8,9.5,2.6c2.1-1.9,2-4.9,2-4.9s3.5,1.6,5.1-0.5s1.6-4.2,1.6-4.2s2.1,1,3.9-0.7c1.7-1.7,1.8-3.4,1.8-3.4
S353.1,931.5,354.5,928.4z"/>
</g>
<g id="Obere_Bauchmuskeln_MF">
<path class="st0" d="M212.1,313.3c-6,1.5-11.4,4.2-17,6.7c-15.1,6.9-11.3-14.2-7.6-21.6c4.2-8.6,13.8-14.2,22.7-16.7
c10.6-3,9.3,10.3,9.5,15.3C220.1,303.7,221.3,311.3,212.1,313.3z"/>
<path class="st0" d="M219.3,264.9c-1.1,5.8-5.8,8.9-10.5,11.7c-4.9,2.9-22.4,14.4-23.7,2.6c-1.1-10.2,1.9-17.3,11.2-21.9
c5.7-2.8,14.8-8.1,21.1-7.1C221.8,252.8,220,261.1,219.3,264.9z"/>
<path class="st0" d="M238.3,313.3c6,1.5,11.4,4.2,17,6.7c15.1,6.9,11.3-14.2,7.6-21.6c-4.2-8.6-13.8-14.2-22.7-16.7
c-10.6-3-9.3,10.3-9.5,15.3C230.4,303.7,229.2,311.3,238.3,313.3z"/>
<path class="st0" d="M231.1,264.9c1.1,5.8,5.8,8.9,10.5,11.7c4.9,2.9,22.4,14.4,23.7,2.6c1.1-10.2-1.9-17.3-11.2-21.9
c-5.7-2.8-14.8-8.1-21.1-7.1C228.7,252.8,230.4,261.1,231.1,264.9z"/>
</g>
<g id="Untere_Bauchmuskeln_MF">
<path class="st0" d="M220.1,406.5c-0.2,7.6-0.4,15.3-0.7,22.9c-0.4,12.3-14.6-0.3-17.7-3.8c-8.8-13-11.2-30.1-14.1-45.3
c-2.4-12.5,1.7-22.6,14.4-26.5c9-2.7,18.6-3,19.3,8.7C222.2,377.1,220.6,392.1,220.1,406.5z"/>
<path class="st0" d="M215.5,344.4c-6.9,2.2-13.7,4.5-20.5,6.8c-8.5,2.9-10.3-4.1-10.3-9.4c-0.1-5.1,2.6-9.8,5.7-13.6
c5.3-6.4,14.3-8.4,22.1-8.9C221.7,318.6,224.4,341.2,215.5,344.4z"/>
<path class="st0" d="M230.3,406.5c0.2,7.6,0.4,15.3,0.7,22.9c0.4,12.3,14.6-0.3,17.7-3.8c8.8-13,11.2-30.1,14.1-45.3
c2.4-12.5-1.7-22.6-14.4-26.5c-9-2.7-18.6-3-19.3,8.7C228.2,377.1,229.8,392.1,230.3,406.5z"/>
<path class="st0" d="M234.9,344.4c6.9,2.2,13.7,4.5,20.5,6.8c8.5,2.9,10.3-4.1,10.3-9.4c0.1-5.1-2.6-9.8-5.7-13.6
c-5.3-6.4-14.3-8.4-22.1-8.9C228.8,318.6,226,341.2,234.9,344.4z"/>
</g>
<g id="Seitlicher_Bauch_MF">
<path class="st0" d="M180.5,275.3c0,2.5-1,4.9-1.9,7.2c-0.3,0.9-0.3,1.9-1.4,1.7c-0.9-0.1-1.8-1.1-2.4-1.7
c-0.9-0.9-1.8-1.8-2.8-2.6c-1.5-1.3-3.1-2.6-4.4-4.1c-3.6-4-6.6-8.4-9.3-13c3.7-0.4,7.4-1.3,10.9-2.5l0,0
C175.2,263.4,180.4,268.6,180.5,275.3z"/>
<path class="st0" d="M167.6,277.6c-6.2-0.9-11.8-3.5-16.5-7.7l0,0c0.1-2.2,0.4-4.4,0.8-6.7c1.7,0,3.4-0.1,5.1-0.2
c2.2,3.9,4.8,7.7,7.6,11.2C165.7,275.4,166.6,276.5,167.6,277.6z"/>
<path class="st0" d="M178.1,303.8c-1.2,8-6.7-2-8.8-5.2c-4.1-6.1-6.7-13.7-7.6-21.2c2.3,0.8,4.7,1.3,7.2,1.6
c0.5,0.6,1.1,1.1,1.7,1.7c1,1,2.1,2,3.2,3c0.9,0.9,1.9,2.5,3.3,2.8l0,0C181,291,178.8,298.6,178.1,303.8z"/>
<path class="st0" d="M164.1,291c-4.5-1.4-8.5-4-11.7-7.5c-1-4-1.4-8-1.3-12.1c2.9,2.4,6,4.3,9.4,5.6
C161,281.8,162.2,286.5,164.1,291z"/>
<path class="st0" d="M176.8,324.7c-2.1,1.1-3.9-1-5.4-2.3c-1.2-1.1-2.2-2.3-3.1-3.7c-5.2-7.1-6.1-18.6-5.9-27.1
c0.7,0.3,1.4,0.5,2.2,0.7c2.9,6.5,7.1,12.5,12.3,17c0.3,3.1,0.7,6.1,1,9.2C178.2,320.4,178.8,323.7,176.8,324.7z"/>
<path class="st0" d="M159.3,302.7L159.3,302.7c-0.8-2.4-1.9-5.4-3.1-8.5c0,0,0,0,0-0.1s0-0.1,0-0.2c-0.1-0.1-0.1-0.2-0.2-0.4v-0.1
c-0.3-0.8-0.6-1.5-0.9-2.3c-0.6-1.7-1.3-3.4-1.9-5.1c2.4,2.2,5.1,4,8.1,5.3v0.1c-0.1,4.6,0.1,9.6,1,14.4
C161.2,304.8,160.3,303.8,159.3,302.7z"/>
<path class="st0" d="M178.4,343.6c-3.3,1.5-5.3-1.3-7-3.6c-5.5-7.2-9.1-15.5-10.7-24.3l0,0c0-4.5-0.2-7.8-0.4-9.1
c-0.1-0.4-0.2-0.8-0.3-1.2c0.8,0.8,1.7,1.7,2.6,2.4c1.6,6.9,4.7,13.3,10.3,17.3c1.9,1.4,3.3,1.8,4.3,1.5c0.3,0.8,0.5,1.7,0.6,1.8
c0.4,1.2,0.7,2.4,0.9,3.6c0.5,2.4,0.7,4.9,1,7.3C180.1,340.9,180.2,342.9,178.4,343.6z"/>
<path class="st0" d="M179.1,358.7c0.2,1.6-0.8,4.2-2.8,3.9c-1-0.1-2.2-0.6-3-1.2c-5.2-3.2-9.5-7.8-12.7-13l0,0
c0.1-9.1,0.3-19.2,0.3-27.2c1.1,4,2.7,7.9,4.7,11.7c2.1,4,6.5,12.4,11.4,13.8c0.8,0.2,1.4,0.2,1.9,0c0.2,2.3,0.3,4.6,0.3,6.9
C179.2,355.3,179.1,357,179.1,358.7z"/>
<path class="st0" d="M269.9,275.3c0,2.5,1,4.9,1.9,7.2c0.3,0.9,0.3,1.9,1.4,1.7c0.9-0.1,1.8-1.1,2.4-1.7c0.9-0.9,1.8-1.8,2.8-2.6
c1.5-1.3,3.1-2.6,4.4-4.1c3.6-4,6.6-8.4,9.3-13c-3.7-0.4-7.4-1.3-10.9-2.5l0,0C275.2,263.4,270,268.6,269.9,275.3z"/>
<path class="st0" d="M282.8,277.6c6.2-0.9,11.8-3.5,16.5-7.7l0,0c-0.1-2.2-0.4-4.4-0.8-6.7c-1.7,0-3.4-0.1-5.1-0.2
c-2.2,3.9-4.8,7.7-7.6,11.2C284.8,275.4,283.8,276.5,282.8,277.6z"/>
<path class="st0" d="M272.4,303.8c1.2,8,6.7-2,8.8-5.2c4.1-6.1,6.7-13.7,7.6-21.2c-2.3,0.8-4.7,1.3-7.2,1.6
c-0.5,0.6-1.1,1.1-1.7,1.7c-1,1-2.1,2-3.2,3c-0.9,0.9-1.9,2.5-3.3,2.8l0,0C269.4,291,271.6,298.6,272.4,303.8z"/>
<path class="st0" d="M286.3,291c4.5-1.4,8.5-4,11.7-7.5c1-4,1.4-8,1.3-12.1c-2.9,2.4-6,4.3-9.4,5.6
C289.5,281.8,288.2,286.5,286.3,291z"/>
<path class="st0" d="M273.6,324.7c2.1,1.1,3.9-1,5.4-2.3c1.2-1.1,2.2-2.3,3.1-3.7c5.2-7.1,6.1-18.6,5.9-27.1
c-0.7,0.3-1.4,0.5-2.2,0.7c-2.9,6.5-7.1,12.5-12.3,17c-0.3,3.1-0.7,6.1-1,9.2C272.3,320.4,271.6,323.7,273.6,324.7z"/>
<path class="st0" d="M291.1,302.7L291.1,302.7c0.8-2.4,1.9-5.4,3.1-8.5c0,0,0,0,0-0.1s0-0.1,0-0.2c0.1-0.1,0.1-0.2,0.2-0.4v-0.1
c0.3-0.8,0.6-1.5,0.9-2.3c0.6-1.7,1.3-3.4,1.9-5.1c-2.4,2.2-5.1,4-8.1,5.3v0.1c0.1,4.6-0.1,9.6-1,14.4
C289.2,304.8,290.2,303.8,291.1,302.7z"/>
<path class="st0" d="M272,343.6c3.3,1.5,5.3-1.3,7-3.6c5.5-7.2,9.1-15.5,10.7-24.3l0,0c0-4.5,0.2-7.8,0.4-9.1
c0.1-0.4,0.2-0.8,0.3-1.2c-0.8,0.8-1.7,1.7-2.6,2.4c-1.6,6.9-4.7,13.3-10.3,17.3c-1.9,1.4-3.3,1.8-4.3,1.5
c-0.3,0.8-0.5,1.7-0.6,1.8c-0.4,1.2-0.7,2.4-0.9,3.6c-0.5,2.4-0.7,4.9-1,7.3C270.4,340.9,270.2,342.9,272,343.6z"/>
<path class="st0" d="M271.4,358.7c-0.2,1.6,0.8,4.2,2.8,3.9c1-0.1,2.2-0.6,3-1.2c5.2-3.2,9.5-7.8,12.7-13l0,0
c-0.1-9.1-0.3-19.2-0.3-27.2c-1.1,4-2.7,7.9-4.7,11.7c-2.1,4-6.5,12.4-11.4,13.8c-0.8,0.2-1.4,0.2-1.9,0c-0.2,2.3-0.3,4.6-0.3,6.9
C271.2,355.3,271.3,357,271.4,358.7z"/>
<path class="st0" d="M291.9,359.4c0,0-9.3,2.7-15.9,9.2c-6.6,6.5-9.7,11.8-10.2,19.8c-0.5,8,6.7,16.8,8.5,18.6s3.8,4.5,7.2,2.7
c3.4-1.8,17.6-13.7,17.6-13.7s3.2-9.8,2-17.2C299.8,371.3,291.9,359.4,291.9,359.4z"/>
<path class="st0" d="M158.5,359.4c0,0,9.3,2.7,15.9,9.2c6.6,6.5,9.7,11.8,10.2,19.8c0.5,8-6.7,16.8-8.5,18.6
c-1.8,1.8-3.8,4.5-7.2,2.7c-3.4-1.8-17.6-13.7-17.6-13.7s-3.2-9.8-2-17.2S158.5,359.4,158.5,359.4z"/>
</g>
<g id="Bizeps_MF">
<path class="st0" d="M140.8,232.2c-0.8,19.4-4.3,38.3-10.5,56.7l0,0c-2.5,4.5-4.5,10.9-5.9,16.4v0.1c-2.2,5.5-4.6,10.9-7.5,16
c-4.4,7.5-11,13.9-17.6,19.8c-11.2-29.3-16-65.8-2.6-94.3c15-4.5,27.1-14.6,34.3-28.6C133.9,223,137.3,227.7,140.8,232.2z"/>
<path class="st0" d="M98.2,342c-0.1,0.1-0.1,0.1-0.2,0.2c-7.7-13.4-17.3-26-16-42c0-0.2,0-0.4,0-0.6s0-0.4,0.1-0.6
c0-0.1,0-0.1,0-0.1l0,0c0.3-4.8,1.5-25.6,3.6-35.4c1.9-9.3,5.3-18.3,6.3-21c1.1,1.4,2.2,2.9,3.2,4.3l0.1,0.1
C82,275.9,86.9,312.5,98.2,342z"/>
<path class="st0" d="M309.6,232.2c0.8,19.4,4.3,38.3,10.5,56.7l0,0c2.5,4.5,4.5,10.9,5.9,16.4v0.1c2.2,5.5,4.6,10.9,7.5,16
c4.4,7.5,11,13.9,17.6,19.8c11.2-29.3,16-65.8,2.6-94.3c-15-4.5-27.1-14.6-34.3-28.6C316.5,223,313.2,227.7,309.6,232.2z"/>
<path class="st0" d="M352.2,342c0.1,0.1,0.1,0.1,0.2,0.2c7.7-13.4,17.3-26,16-42c0-0.2,0-0.4,0-0.6s0-0.4-0.1-0.6
c0-0.1,0-0.1,0-0.1l0,0c-0.3-4.8-1.5-25.6-3.6-35.4c-1.9-9.3-5.3-18.3-6.3-21c-1.1,1.4-2.2,2.9-3.2,4.3l-0.1,0.1
C368.5,275.9,363.5,312.5,352.2,342z"/>
</g>
<g id="Unterarm_MF">
<path class="st0" d="M101.3,418.9L101.3,418.9c-2.6,7.3-4.6,13.7-5.1,18.4c-0.1,0.8-0.2,1.5-0.3,2.3c-1.3-11.6-4-23-8.1-34.2
c-6.6-18.4-16.9-35.1-19.1-54.7v-16.2c0-12.9,8.7-27.3,11.9-32.2c-0.5,17.9,12.2,32,20.1,47.5C111.7,371.2,107,396.2,101.3,418.9z"
/>
<path class="st0" d="M117.9,367.2c-0.4,8.4-6,23.2-11.4,37.5c-0.6,1.6-1.2,3.2-1.8,4.8c3.3-15.7,5.1-31.5,1.7-47.6
c-0.4-1.8-0.9-3.6-1.5-5.3c3.3-4,7.1-7.6,11.3-10.7C116.8,348,118.4,354.5,117.9,367.2z"/>
<path class="st0" d="M95.7,457.4C80.8,438.5,68.1,418,64,394.1c0-0.1,0-0.1,0-0.2c-0.4-3.6-0.7-7.2-0.9-11c-1-16.9,3-26.8,4.7-30.2
c2,14.3,7.9,27.4,13.7,40.6c7.7,17.4,12.5,35.1,13.8,53.7c-0.1,3,0,5.5,0.4,7.8l0,0l0,0C95.7,455.6,95.7,456.5,95.7,457.4z"/>
<path class="st0" d="M349.1,418.9L349.1,418.9c2.6,7.3,4.6,13.7,5.1,18.4c0.1,0.8,0.2,1.5,0.3,2.3c1.3-11.6,4-23,8.1-34.2
c6.6-18.4,16.9-35.1,19.1-54.7v-16.2c0-12.9-8.7-27.3-11.9-32.2c0.5,17.9-12.2,32-20.1,47.5C338.7,371.2,343.4,396.2,349.1,418.9z"
/>
<path class="st0" d="M332.5,367.2c0.4,8.4,6,23.2,11.4,37.5c0.6,1.6,1.2,3.2,1.8,4.8c-3.3-15.7-5.1-31.5-1.7-47.6
c0.4-1.8,0.9-3.6,1.5-5.3c-3.3-4-7.1-7.6-11.3-10.7C333.6,348,332,354.5,332.5,367.2z"/>
<path class="st0" d="M354.7,457.4c14.9-18.9,27.7-39.4,31.7-63.3c0-0.1,0-0.1,0-0.2c0.4-3.6,0.7-7.2,0.9-11c1-16.9-3-26.8-4.7-30.2
c-2,14.3-7.9,27.4-13.7,40.6c-7.7,17.4-12.5,35.1-13.8,53.7c0.1,3,0,5.5-0.4,7.8l0,0l0,0C354.7,455.6,354.7,456.5,354.7,457.4z"/>
</g>
<g id="Aeusseres_Gesaess_MF">
<path class="st0" d="M156.8,440.1c-1.9,13.4-4.1,32.5-16.8,39.4c0.1-0.5,0.2-0.9,0.3-1.4c3.3-13.2,6-23.6,5.5-30.6
c-0.9-13.5,3.1-31,5.7-40.8C159.4,415.3,158.4,428.9,156.8,440.1z"/>
<path class="st0" d="M293.7,440.1c1.9,13.4,4.1,32.5,16.8,39.4c-0.1-0.5-0.2-0.9-0.3-1.4c-3.3-13.2-6-23.6-5.5-30.6
c0.9-13.6-3.1-31.1-5.7-40.9C291.1,415.3,292,428.9,293.7,440.1z"/>
</g>
<g id="Beinstrecker_MF">
<path class="st0" d="M210.2,570c-0.6,3.6-1.2,7.2-1.6,10.7c-0.6,5.2-1.3,11-1.9,16.9c-1.7-22.3-5-44.2-14.1-65.2
c-15.1-34.7-29.7-68.5-30.4-107c0-2.5,0.1-4.8,0.6-7.2c2.1-10.5,10,4.9,10.2,5.4c2.4,4.5,4.9,7.7,5.5,13c0.3,2.5,0.5,4.8,1.1,7.3
c3.6,17.1,6.8,33.7,13,50.2c2.8,7.6,6.6,14.7,9.3,22.3c3.1,8.5,4.4,17.9,5.8,26.8C209.1,551.9,209.9,560.9,210.2,570z"/>
<path class="st0" d="M206,604.9c-0.5,5.6-1,11.3-1.4,16.7c-0.9,10.9-1.8,21.4-2.7,28.8c-12.1,9.5-27.1-3.4-33.2-14.1
c-6.5-11.5-5.9-25.3-3.1-37.7c5.2-22.8,19.9-42.1,26.4-64.5c4,9.4,7.5,19,9.4,29.1C204.1,577,205.3,591,206,604.9z"/>
<path class="st0" d="M154.9,615.8c-0.2,4.9-1.6,9.5-3.4,14c-2.8,6.7-10.3,12.3-16.6,10.5c-3.3-27.3-7.9-71.5-5.7-100.5
c1.1-15.2,3.7-29.2,6.4-41.6c-1.5,17.4-0.6,35,3,52.3c3.2,15.1,9.9,29,14.1,43.8C154.5,601.3,155.2,608.5,154.9,615.8z"/>
<path class="st0" d="M169.7,415c-0.9-1.4-3.6-2.5-5.1-1.4c-7.6,5.4-3.1,23.9-2.3,31c2.4,20.2,8.7,39.8,16.2,58.6
c3.9,9.9,8.6,19.4,12.8,29.1c-7,25.5-27.7,50.6-29.1,77.6l0,0c-1.8,1.2-4.2,1.3-6,0.3c-0.2-4.5-0.8-8.9-1.6-12.9
c-4-19.7-13.6-37.5-16.9-57.6c-2.7-16.8-2.5-33.9-0.1-50.7c0.6-2.8,1.3-5.4,2-8l0,0c14.4-6.8,16-26.3,18.4-40.7
c1.9-11.8,2.5-26.3-6-35.1c1-3.7,1.8-6,1.8-6l0.1-0.4l0,0c5.2,4.4,10.5,8.8,16.2,12.6c0.3,0.2,0.5,0.3,0.8,0.4L169.7,415z"/>
<path class="st0" d="M240.2,570c0.6,3.6,1.2,7.2,1.6,10.7c0.6,5.2,1.3,11,1.9,16.9c1.7-22.3,5-44.2,14.1-65.2
c15.1-34.7,29.7-68.5,30.4-107c0-2.5-0.1-4.8-0.6-7.2c-2.1-10.5-10,4.9-10.2,5.4c-2.4,4.5-4.9,7.7-5.5,13c-0.3,2.5-0.5,4.8-1.1,7.3
c-3.6,17.1-6.8,33.7-13,50.2c-2.8,7.6-6.6,14.7-9.3,22.3c-3.1,8.5-4.4,17.9-5.8,26.8C241.3,551.9,240.5,560.9,240.2,570z"/>
<path class="st0" d="M244.4,604.9c0.5,5.6,1,11.3,1.4,16.7c0.9,10.9,1.8,21.4,2.7,28.8c12.1,9.5,27.1-3.4,33.2-14.1
c6.5-11.5,5.9-25.3,3.1-37.7c-5.2-22.8-19.9-42.1-26.4-64.5c-4,9.4-7.5,19-9.4,29.1C246.3,577,245.1,591,244.4,604.9z"/>
<path class="st0" d="M295.5,615.8c0.2,4.9,1.6,9.5,3.4,14c2.8,6.7,10.3,12.3,16.6,10.5c3.3-27.3,7.9-71.5,5.7-100.5
c-1.1-15.2-3.7-29.2-6.4-41.6c1.5,17.4,0.6,35-3,52.3c-3.2,15.1-9.9,29-14.1,43.8C295.9,601.3,295.3,608.5,295.5,615.8z"/>
<path class="st0" d="M280.7,415c0.9-1.4,3.6-2.5,5.1-1.4c7.6,5.4,3.1,23.9,2.3,31c-2.4,20.2-8.7,39.8-16.2,58.6
c-3.9,9.9-8.6,19.4-12.8,29.1c7,25.5,27.7,50.6,29.1,77.6l0,0c1.8,1.2,4.2,1.3,6,0.3c0.2-4.5,0.8-8.9,1.6-12.9
c4-19.7,13.6-37.5,16.9-57.6c2.7-16.8,2.5-33.9,0.1-50.7c-0.6-2.8-1.3-5.4-2-8l0,0c-14.4-6.8-16-26.3-18.4-40.7
c-1.9-11.8-2.5-26.3,6-35.1c-1-3.7-1.8-6-1.8-6l-0.1-0.4l0,0c-5.2,4.4-10.5,8.8-16.2,12.6c-0.3,0.2-0.5,0.3-0.8,0.4L280.7,415z"/>
</g>
<g id="Innerer_Oberschenkelmuskel_MF">
<path class="st0" d="M279.7,411.8c-4,5.8-7.8,13.2-12.3,22.4c-3.9,7.8-8.3,16.6-13.9,26.2c-1.6,2.7-2.9,5.8-4.3,9
c-3.9,9.2-8.3,19.6-20.5,21.3c-0.6,3.5-1.9,12.9-0.7,22.1c0.6,4.7,2.5,12.8,4.7,22.1c2.2,9.2,4.7,19.8,6.6,30.4
c0.4-7.5,1.2-15,2.3-22.5c1.5-10.1,3.5-20.3,7-30c3.6-9.8,8.4-19.1,11.7-29c5.8-17.3,7.4-35,11.3-52.5c1.2-5.5,6-11.5,9.1-16.1
L279.7,411.8z"/>
<path class="st0" d="M170.7,411.8c4,5.8,7.8,13.2,12.3,22.4c3.9,7.8,8.3,16.6,13.9,26.2c1.6,2.7,2.9,5.8,4.3,9
c3.9,9.2,8.3,19.6,20.5,21.3c0.6,3.5,1.9,12.9,0.7,22.1c-0.6,4.7-2.5,12.8-4.7,22.1c-2.2,9.2-4.7,19.8-6.6,30.4
c-0.4-7.5-1.2-15-2.3-22.5c-1.5-10.1-3.5-20.3-7-30c-3.6-9.8-8.4-19.1-11.7-29c-5.8-17.3-7.4-35-11.3-52.5
c-1.2-5.5-6-11.5-9.1-16.1L170.7,411.8z"/>
</g>
<g id="Schulter_MF">
<path class="st0" d="M152.1,178.5c-1.2,4.1-3.6,7.9-6.5,11.3c-2,1.9-3.9,3.9-5.6,6l0,0c-0.1,0.1-0.2,0.2-0.4,0.3
c-0.1,0.1-0.2,0.3-0.2,0.4c-4.3,5.5-7.3,11.9-8.6,19.1l0,0c-3.5,7.4-8.3,13.7-14.4,19.2c-3.6,3.2-7.8,5.7-12.1,7.8
c-6.1,3.1-8.5,2.8-12.2-1.9c-0.4-1.3-3.1-9-4.5-18.6c-1.6-10.4,5.6-36.3,9.9-47.3c2.6-6.7,8.1-9.2,12.9-11.4
c1.7-0.8,3.2-1.5,4.6-2.3c3.7,7.3,15.7,4.6,22.2,4.2C146.1,164.9,155.4,167.2,152.1,178.5z"/>
<path class="st0" d="M298.3,178.5c1.2,4.1,3.6,7.9,6.5,11.3c2,1.9,3.9,3.9,5.6,6l0,0c0.1,0.1,0.2,0.2,0.4,0.3
c0.1,0.1,0.2,0.3,0.2,0.4c4.3,5.5,7.3,11.9,8.6,19.1l0,0c3.5,7.4,8.3,13.7,14.4,19.2c3.6,3.2,7.8,5.7,12.1,7.8
c6.1,3.1,8.5,2.8,12.2-1.9c0.4-1.3,3.1-9,4.5-18.6c1.6-10.4-5.6-36.3-9.9-47.3c-2.6-6.7-8.1-9.2-12.9-11.4
c-1.7-0.8-3.2-1.5-4.6-2.3c-3.7,7.3-15.7,4.6-22.2,4.2C304.3,164.9,295,167.2,298.3,178.5z"/>
</g>
<g id="Halsmuskel_MF">
<path class="st0" d="M217.7,177c-2.8-7.2-13.5-34.8-16.9-43.2c-3.9-9.5-6-29.6-6-29.7v-0.3c0.2-1.6,0.3-3.1,0.4-4.3
c1.5,1.6,3.7,3.7,6.1,5.7l0,0c3.9,13.6,7.8,23,10.9,30.6c2.1,5.2,4,9.7,5.3,14.2C220,159.1,218.4,172.3,217.7,177z"/>
<path class="st0" d="M232.7,177c2.8-7.2,13.5-34.8,16.9-43.2c3.9-9.5,6-29.6,6-29.7v-0.3c-0.2-1.6-0.3-3.1-0.4-4.3
c-1.5,1.6-3.7,3.7-6.1,5.7l0,0c-3.9,13.6-7.8,23-10.9,30.6c-2.1,5.2-4,9.7-5.3,14.2C230.4,159.1,232,172.3,232.7,177z"/>
</g>
<g id="Nacken_MF">
<path class="st0" d="M178.2,148c5.8-9.1,9.7-20.2,17.9-27.5l0,0c-0.9-4.9-1.6-9.7-2-12.8c-0.6,3.1-1.5,6.1-2.9,7.9
c-3.3,4.1-15.4,9.9-27.2,15.5c-6.7,3.2-13,6.2-17.4,8.8c-8,4.6-11.8,8-14.2,10.3c-1,0.9-1.9,1.7-2.8,2.3
C146.4,155.1,166.5,166.5,178.2,148z"/>
<path class="st0" d="M203.4,143.2L203.4,143.2c-1.5-3.8-2.7-6.9-3.6-8.9c-1.3-3.2-2.5-7.7-3.4-12.2c-10.6,9.5-12.6,28.4-25.6,35.1
c-2.2,1.1-4.3,1.8-6.5,2.3c3.3,1.4,7.1,2.9,11.2,4.2C188.5,163.2,199.2,155.4,203.4,143.2z"/>
<path class="st0" d="M178.7,164.6c11.4,3.3,24.1,4.6,32.3-2c-2.3-5.8-4.8-12.2-6.9-17.7C199.8,155.8,190.4,163.2,178.7,164.6z"/>
<path class="st0" d="M318,150.2c-2.5-2.3-6.2-5.6-14.2-10.3c-4.4-2.6-10.7-5.6-17.4-8.8c-11.7-5.6-23.8-11.4-27.2-15.5
c-1.4-1.8-2.3-4.8-2.9-7.9c-0.4,3.1-1.1,7.9-2,12.8l0,0c8.2,7.3,12.1,18.4,17.9,27.5c11.8,18.4,31.9,7.1,48.6,4.4
C319.9,151.9,319.1,151.1,318,150.2z"/>
<path class="st0" d="M254,122c-0.9,4.5-2,9-3.4,12.2c-0.8,2-2.1,5.2-3.6,8.9l0,0c4.2,12.2,14.9,20,27.9,20.4
c4.1-1.3,7.9-2.8,11.2-4.2c-2.2-0.4-4.3-1.1-6.5-2.3C266.6,150.4,264.6,131.5,254,122z"/>
<path class="st0" d="M246.4,144.9c-2.2,5.5-4.7,11.9-6.9,17.7c8.1,6.6,20.9,5.3,32.3,2C260.1,163.2,250.6,155.8,246.4,144.9z"/>
</g>
<g id="Brust_MF">
<path class="st0" d="M221.6,212.1c-1.1,10.5-2.4,23-10.2,30.9c-10.1,10.1-32.4,16.2-45.7,10.5c-14.5-6.2-25.9-23.1-33.9-36.1
c1.2-8.1,4.4-14.9,9-20.6c1.4-1.4,3.3-3.3,5.2-5.5l0,0c10.7-9.8,25.8-15.5,40-18.2c14.2-2.8,26.9-0.6,35,11.9
C225.1,191.2,222.3,205.1,221.6,212.1z"/>
<path class="st0" d="M228.8,212.1c1.1,10.5,2.4,23,10.2,30.9c10.1,10.1,32.4,16.2,45.7,10.5c14.5-6.2,25.9-23.1,33.9-36.1
c-1.2-8.1-4.4-14.9-9-20.6c-1.4-1.4-3.3-3.3-5.2-5.5l0,0c-10.7-9.8-25.8-15.5-40-18.2c-14.2-2.8-26.9-0.6-35,11.9
C225.3,191.2,228.1,205.1,228.8,212.1z"/>
</g>
<g id="Kontur">
<g>
<g>
<g>
<g>
<path class="st1" d="M226.5,118.3c-0.2-0.6-0.2-1.2-0.1-1.7c-2.1,0-5.6-0.3-7.9-1.1c-3.4-1.2-21.2-15.1-23.4-19.1
s-1.6-14-1.5-14.1l0.3-4.7l-2.2,4.2c0,0.1-0.7,1.3-2,1.1c-1.9-0.2-5-5.8-6.9-9.1c-0.9-1.7-1.7-3.1-2.4-4.1
c-2-2.8-1.9-9.9,0.1-16.4c0.3-1,0.8-1.6,1.3-1.7c1.4-0.3,3.9,2,5.3,3.7l2,2.5l-0.2-3.2c0-0.3-2.1-29,8.1-41.1
C205.7,2.9,226.2,2,226.4,2l-0.1-2c-0.9,0-21.6,0.9-30.9,12.1c-8.8,10.6-8.9,32.2-8.7,39.8c-1.4-1.3-3.4-2.7-5.3-2.4
c-1.3,0.2-2.3,1.3-2.9,3.1c-1.9,6.1-2.5,14.4,0.1,18.1c0.6,0.9,1.4,2.4,2.3,3.9c3.1,5.5,5.6,9.8,8.4,10.1c0.8,0.1,1.5,0,2-0.3
c-0.1,3.4,0.1,9.5,1.9,12.7c2.4,4.5,20.6,18.6,24.5,20.1C220.4,118.2,224.3,118.4,226.5,118.3
C226.5,118.5,226.5,118.4,226.5,118.3z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M80.7,565.1l-0.3-0.8c-0.4-1-9-23.7-10-28.4c-1.1-5.1-5.3-57-4.3-84.6c0.6-18.2-1.1-32.2-2.7-45.7
c-0.9-7.6-1.8-14.8-2.3-22.6c-1.2-20.4,4.5-30.8,5.6-32.6v-16c0-15.6,11.7-32.6,13.3-34.9c0.2-3,1.5-25.7,3.7-36.4
c2.1-10.1,5.8-19.8,6.6-21.7c-0.6-1.7-3.1-9.4-4.6-18.8c-1.7-10.8,5.6-37.2,10-48.4c2.9-7.4,9-10.2,13.9-12.5
c2.6-1.2,4.9-2.3,6.2-3.8c3.3-3.8,6.6-4.9,9.5-5.8c0.8-0.2,1.5-0.5,2.2-0.8c1.1-0.4,2.1-1.3,3.6-2.7c2.5-2.3,6.4-5.8,14.6-10.5
c4.5-2.6,10.8-5.6,17.5-8.9c11-5.3,23.4-11.2,26.5-15c3.3-4.1,3.6-17.4,3.6-17.5h2c0,0.6-0.3,14.1-4,18.8
c-3.3,4.1-15.4,9.9-27.2,15.5c-6.7,3.2-13,6.2-17.4,8.8c-8,4.6-11.7,8-14.2,10.3c-1.6,1.4-2.7,2.5-4.2,3.1
c-0.7,0.3-1.5,0.6-2.3,0.8c-2.8,0.9-5.6,1.8-8.6,5.2c-1.6,1.8-4.2,3-6.9,4.3c-4.8,2.2-10.2,4.7-12.9,11.4
c-4.3,11-11.5,36.9-9.9,47.3c1.6,10.4,4.6,18.7,4.6,18.8l0.1,0.4l-0.1,0.4c0,0.1-4.3,10.7-6.6,21.8
c-2.3,11.1-3.6,36.1-3.6,36.4v0.3l-0.2,0.2c-0.1,0.2-13.1,18.2-13.1,34V351l-0.2,0.3c-0.1,0.1-6.8,10.3-5.5,31.6
c0.5,7.7,1.3,14.9,2.3,22.5c1.7,13.6,3.4,27.7,2.7,46c-1,28.7,3.4,80,4.3,84.1c0.9,4,7.9,22.6,9.6,27.1c1.2-0.7,3.4-2.5,3.4-7
c0-6.8-4.2-21.5-4.3-21.7l-0.1-0.2v-0.2c0-0.1,1.9-13.1,0.7-18.5l-0.1-0.3l0.1-0.2c1-3.5,6.2-20.9,11-22.7
c0.8-0.3,1.6-0.2,2.3,0.2c2.9,1.9,3.5,6,4.1,10.4c0.5,3.4,0.9,7,2.6,9.6c2.4,3.8,4.2,4.6,5,4.7c0.5,0.1,1-0.1,1.4-0.4
c1.2-1.1,1.9-11,1.8-19.5l-0.1-2.6c-0.5-9.8-0.9-16.8-5.9-23c-7.8-9.7-9.9-15.6-7.7-33.8c0.9-7.6,5.5-19.6,10.3-32.4
c5.4-14.3,11-29.2,11.4-37.5c0.6-15.5-1.9-21.8-1.9-21.8l-0.2-0.6l0.4-0.5c0,0,4.1-4.5,5.1-10.4c1-6.1,0.7-17.3,0.7-17.4
c0.2-1,3.4-19.8,9.1-28.8c5.8-9.3,14.1-29.1,14.1-31.1l1.3,2.4c0,2.7-8.2,21-13.8,29.8c-5.5,8.7-8.8,27.7-8.8,27.9
c0,0.3,0.3,11.3-0.7,17.6c-0.9,5.4-4,9.7-5.2,11c0.6,1.9,2.4,8.6,1.9,22.1c-0.4,8.7-6,23.7-11.5,38.2
c-4.8,12.7-9.3,24.7-10.2,32c-2.2,17.6-0.1,22.9,7.2,32.3c5.4,7,5.9,15.5,6.4,24.1l0.2,2.7c0,4.4,0,18.8-2.5,21.1
c-0.8,0.8-1.9,1.1-3,1c-2.1-0.3-4.3-2.2-6.5-5.6c-1.9-2.9-2.4-6.7-2.9-10.4c-0.5-3.9-1-7.6-3.2-9c-0.1-0.1-0.3-0.1-0.5,0
c-2.9,1.1-7.5,13.5-9.7,21.2c1.2,5.6-0.4,17-0.7,18.8c0.5,1.9,4.3,15.3,4.3,22c0,7.7-5.7,9.3-5.7,9.3L80.7,565.1z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M92.5,542.7c-3.4-5.2-7.7-13.2-9.2-16.2c0,0,0,0,0,0.1v0.2c0,0.9-0.3,1.8-0.8,2.6c2,3.7,5.5,10.1,8.3,14.5
c3,4.6,0.3,10.4-1,12.7l-3.4-3.9c0,1,0,2.1,0.1,3.1l3.6,4.1l0.7-1C91,558.4,97.1,549.7,92.5,542.7z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M93.5,551.7c0.9-1.4,5.9-9.3,1.6-15.8c-4.2-6.4-11.2-19.7-11.3-19.9l-0.9,0.5c0,0.5,0,1-0.1,1.5
c0,0.1,0,0.2-0.1,0.2c1.9,3.5,7.2,13.4,10.7,18.8c2.2,3.4,1.4,7.4,0.2,10.2c0.3,0.8,0.3,1.8,0.1,2.8
C93.7,550.6,93.6,551.1,93.5,551.7z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M160.4,306.6c-0.6-3.2-2.9-9.2-5.3-15.5c-1.7-4.3-3.4-8.8-4.7-12.9c-2.5-7.4-5-31-6.1-42.1
c-0.8-0.8-1.6-1.7-2.3-2.6c0.9,9.3,3.6,36.9,6.4,45.3c1.4,4.1,3.1,8.6,4.7,12.9c2.4,6.2,4.6,12.1,5.2,15.2
c0.8,4.3,0.2,36.3,0,46.9c0,2.2-0.1,3.7-0.1,4.1c-0.1,0.5-1.2,2.2-2.1,3.8c-3.3,5.2-8.8,13.9-7.8,22.6
c1.2,9.6,2.6,13.6,3.2,14.8c-1.1,3.5-9.1,29.7-7.9,48.7c0.4,6.6-2.2,16.9-5.5,30c-4.2,16.8-9.5,37.8-11.3,61.9
c-2.6,35.7,4.9,94.5,7.7,116.6c0.6,4.7,1,7.9,1.1,8.8c0.1,2.3-0.1,3.9-0.3,5.8c-0.3,2.5-0.7,5.4-0.3,10.8
c0.7,9.4,15,33.4,15.5,34.2c0,0-12.8-25.4-13.5-34.3c-0.4-5.2,0-8,0.3-10.4c0.3-1.9,0.5-3.7,0.3-6.2c-0.1-1-0.5-4.2-1.1-8.9
c-2.8-22.1-10.3-80.6-7.7-116.2c1.8-24,7-44.8,11.2-61.6c3.3-13.2,6-23.7,5.5-30.6c-1.3-19.8,7.8-48,7.9-48.3l0.1-0.4l-0.2-0.4
c0,0-1.8-3.4-3.1-14.5c-1-7.9,4.3-16.2,7.5-21.2c1.7-2.7,2.4-3.9,2.4-4.8c0-0.4,0-1.9,0.1-4.1
C160.8,335.5,161.2,310.7,160.4,306.6z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M99.3,932.7c-1.2-0.6-2.8-1.4-3.7-3c-0.6-1.1-0.7-2.5-0.4-3.9c0.5-2,1.6-3.5,2.6-4.8
c0.4-0.5,0.8-1,1.2-1.6c1-1.5,1.6-3,2.3-4.7l0.3-0.7c5.9-13.9,14.7-27.2,22.4-38.9l0.2-0.2c0,0,2.6-2.3,2.3-9.8
c-0.2-3.8,1-5.7,2.3-7.8c1.8-2.9,4-6.4,4.7-16.9c0.7-9.8-2.8-34.3-6.2-58c-3-20.9-5.9-40.7-5.7-49.6
c0.3-18.9,13.4-48.3,14-49.5l1.8,0.8c-0.1,0.3-13.5,30.3-13.8,48.8c-0.2,8.8,2.7,28.5,5.7,49.3c3.4,23.8,7,48.4,6.3,58.4
c-0.8,11-3.2,14.9-5,17.8c-1.3,2.1-2.1,3.5-2,6.7c0.3,7.8-2.2,10.7-2.9,11.3c-7.7,11.6-16.3,24.7-22.1,38.4l-0.3,0.7
c-0.7,1.7-1.4,3.4-2.5,5c-0.4,0.6-0.8,1.2-1.2,1.7c-1,1.3-1.9,2.5-2.3,4.1c-0.2,0.9-0.2,1.8,0.2,2.5c0.5,0.9,1.6,1.5,2.8,2.2
L99.3,932.7z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M190.5,686.6l-1.9,0.4c0.1,0.4,9.3,41.2,4.1,58.4c-3.3,11.1-7.5,19.9-10.9,27c-2,4.3-3.8,7.9-5,11.3
c-3.3,9-14.1,53.8-12.3,63c0.6,3.4,1.5,6.3,2.2,8.9c1,3.7,1.9,6.6,1.2,8.2c-0.3,0.7-0.7,1.5-1.1,2.4c-1.9,3.9-3.1,6.7-2.3,8.3
l0.3,0.6c4.7,9,6.3,15.7,4.8,20c-1.9,5.2-7.6,11.9-10.7,15.2c0.1,0.4,0.2,0.8,0.1,1.3c-0.1,0.6-0.1,1.1-0.2,1.7
c2.1-2.2,10.3-10.8,12.7-17.5c1.7-4.8,0.1-12.1-4.9-21.6l-0.3-0.6c-0.4-0.9,1.4-4.5,2.3-6.5c0.4-0.9,0.9-1.8,1.2-2.5
c1-2.2,0.1-5.3-1.1-9.5c-0.7-2.5-1.5-5.4-2.2-8.7c-1.6-8.6,9.1-53.2,12.3-61.9c1.2-3.3,2.9-7,4.9-11.2c3.4-7.1,7.6-16,11-27.2
C199.9,728.3,190.9,688.3,190.5,686.6z"/>
</g>
</g>
<g>
<path class="st1" d="M111.9,940.3L111.9,940.3c0-0.2,0-0.3,0-0.4c-1,0.3-3,0.6-4.6-0.9s-0.5-4.2,0.3-5.8
c1.6-3.5,4.1-6.5,5.9-9.8c0.2-0.4,3.2-6.1,2.9-6.2c-0.3-0.1-2.5,2.7-2.7,3c-1.5,1.6-2.4,3.5-3.5,5.4c-0.6,1-1.5,1.8-2.2,2.8
c-1,1.4-1.8,3-2.5,4.6c-1,2.4-1.7,5.5,0.4,7.5c1.4,1.3,2.9,1.7,4.3,1.7c0.7,0,1.4-0.1,2-0.3C112,941.3,111.9,940.8,111.9,940.3z
"/>
</g>
<g>
<g>
<path class="st1" d="M158.3,904.6c0,0,0.6,15.3-3.5,21.9c-4.1,6.7-8,9.3-8,9.3l-0.2,0.2l-0.1,0.3c-0.1,0.1-5.2,11.7-13.7,11.7
c-5.3,0-6.5-2.3-8.1-5.2l-0.2-0.3c-0.4-0.7,1.1-3.7,1.3-4.3c2.3-6,5.6-10.5,8.3-16.2c0,0-3.6,4.6-5.6,7.2
c-1.8,2.4-3.3,5.1-4.4,7.8c-0.4,1.1-0.8,2.2-1.2,3.3c-0.1,0.3-0.2,1.6-0.4,1.8c-1.3,1.1-4.3,3.2-6.6,2.6
c-2.6-0.7-2.8-2.3-2.9-4.9c0-1,2.8-6.2,5.3-10.4h-0.1c2.1-2.8,3.7-5.9,4.8-9.2c-2.9,3.5-5.8,7-8.1,10.9
c-0.7,1.1-1.3,2.3-1.9,3.5c-0.8,1.5-2.2,3.6-2.1,5.3c0.1,2.5,0.3,5.6,4.3,6.7c2.9,0.8,6.2-1.2,7.8-2.4c1.5,2.8,3.4,5.8,9.6,5.8
c9.2,0,14.6-10.8,15.4-12.6c1-0.7,4.5-3.6,8.4-9.8C161,920.4,158.5,905.3,158.3,904.6z"/>
</g>
<g>
<path class="st1" d="M111.7,915c-0.6,0-5.1,3.7-6.8,6.3c-0.7,1-1.3,2-2.1,2.9c-2.2,2.6-4.8,6.5-3.1,10c1,2.2,2.9,2.8,4.4,2.8
c0.6,0,1.1-0.1,1.4-0.2l-0.6-1.9c-0.1,0-2.4,0.7-3.5-1.5c-1-2,0.1-4.1,1.2-5.7C105.7,923.5,108.6,919.2,111.7,915z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M203,468.6c-1.3-3.1-2.7-6.4-4.3-9.2c-5.5-9.6-9.9-18.4-13.8-26.1c-4.7-9.4-8.5-17-12.7-23
c-0.5,0.2-1.1,0.3-1.9,0.4c-0.1,0-0.2,0-0.3,0c4.3,5.9,8.3,13.8,13.1,23.5c3.9,7.8,8.3,16.6,13.9,26.2c1.6,2.7,2.9,5.8,4.2,9
c4.3,10.1,9.2,21.5,24,21.5v-2C211.6,488.9,207.4,479,203,468.6z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M155,693.2c-0.1,0-0.3,0-0.4,0c-4.9-0.8-14.1-16.7-14.1-18.9h1c0,0,10,16.4,13.4,17
c3.6,0.5,29.5-18.6,29.5-18.6C180.1,677.4,160.7,693.2,155,693.2z"/>
</g>
</g>
<g>
<path class="st1" d="M223.4,488.8l-2,0.4c0,0.1,2.4,12,1,23.5c-0.6,4.8-2.5,12.8-4.7,22.1c-3.2,13.7-7.2,30.7-9.1,45.9
c-1.6,12.2-2.9,27.5-4,41c-1.2,14.6-2.4,28.3-3.6,35.2c-1.5,8.3-5.4,16.4-9.2,23.9c-2.3,4.4-4.5,9.5-7.8,13.3
c-8.9,10.4-18.4,20.5-22.8,33.7c-3,9.1-3.6,18.5-4.2,28c-0.2,3.2-0.4,6.2-0.6,9.1c-1.4,15.5-1.3,30.8-1,47.6c0,1.5,0,3.1,0,4.7
c0.1,13.2,0.1,28.2,11.7,36.5l0.2-0.3c-0.2-0.7-0.3-1.4-0.5-2.1c0-0.2,0-0.3-0.1-0.4c-9.3-8-9.4-21.6-9.4-33.7
c0-1.6,0-3.2,0-4.7c-0.3-16.8-0.5-32,1-47.4c0.3-2.9,0.4-5.9,0.6-9.1c0.5-8.4,0.7-16.9,3.3-25c2-6.1,5-11.8,8.7-17
c3.6-5.3,8.2-9.8,11.9-15c6.3-8.6,11.5-18.2,15.6-28.1c1.8-4.4,3.6-9,4.4-13.7c1.3-7,2.4-20.1,3.7-35.4
c1.1-13.5,2.4-28.7,4-40.9c1.9-15,5.9-32,9.1-45.7c2.2-9.3,4.1-17.4,4.7-22.3C225.9,501.1,223.5,489.3,223.4,488.8z"/>
</g>
</g>
<g>
<path class="st1" d="M218,180.7l-0.6-1.6c-0.1-0.4-13.7-35.3-17.6-44.8c-4-9.6-6.1-29.8-6.1-30l-0.3-8.6l8.4,8.3v0.1
c4,14.1,8,23.8,11.2,31.6c2.1,5.2,4,9.7,5.3,14.3c3.3,11.3,0.1,28.4,0,29.1L218,180.7z M194.5,98.1l0.2,6.1
c0,0.2,2.1,20.3,6,29.7c3.4,8.3,14.2,36,17,43.2c0.7-4.7,2.4-17.9-0.3-26.9c-1.3-4.5-3.2-9-5.3-14.2c-3.2-7.8-7.2-17.4-11.2-31.6
L194.5,98.1z"/>
</g>
<g>
<g>
<path class="st1" d="M151,396.6c6.2,5.1,12.3,10.5,18.9,14.9c4.7,3.1,11.2-10.7,12.8-13.6c4.9-8.5,1.1-19.7-4.5-27
c-4.6-5.9-12.8-9.1-19.4-12c-0.7-0.3-1.3,0.8-0.6,1.1c12.8,5.5,29,15.5,25.4,32.8c-1,4.7-5,9.7-7.9,13.2c-4,4.9-7.8,2.7-12-0.7
c-4-3.2-7.9-6.4-11.8-9.6C151.3,395.2,150.4,396.1,151,396.6L151,396.6z"/>
</g>
</g>
<g>
<path class="st1" d="M221.3,183.1c-6.5-10-15.9-12.8-27.5-12.3c-26.8,1.1-59.2,17.4-63.2,46.5c-0.1,0.1,0,0.3,0.1,0.5
c4.4,7.2,9.5,14,15,20.3c6.2,7.2,16,18,26.4,18.4c14.8,0.6,31.1-3.7,41.8-14.4c7.2-7.2,7.9-20.6,8.9-30.1
C223.8,203.8,226.2,190.6,221.3,183.1z M221.6,212.1c-1.1,10.5-2.4,23-10.2,30.9c-10.1,10.1-32.4,16.2-45.7,10.5
c-14.5-6.2-25.9-23.1-33.9-36.1c3.9-26.8,30.3-39.7,54.3-44.4c14.2-2.8,26.9-0.6,35,11.9C225.1,191.2,222.3,205.1,221.6,212.1z"
/>
</g>
<g>
<g>
<path class="st1" d="M114.6,160.3c3.1,8.5,15.9,5.6,22.7,5.1c8.9-0.6,18.2,1.7,14.9,13c-2,6.8-7.5,12.9-12.5,17.7
c-0.6,0.5,0.3,1.4,0.8,0.8c5.7-5.4,18.9-19.7,12.1-28.4c-8.2-10.4-32.3,3.8-36.9-8.6C115.5,159.3,114.3,159.6,114.6,160.3
L114.6,160.3z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M90.2,240.3c1.6,2.2,3.3,4.4,4.9,6.5c0.2,0.2,0.4,0.4,0.7,0.3c16-4.6,28.9-15.6,36.1-30.9
c0.3-0.7-0.7-1.3-1-0.6c-3.5,7.5-8.3,13.8-14.4,19.2c-3.6,3.2-7.8,5.7-12.1,7.8c-6.6,3.3-8.9,2.7-13.2-3
C90.8,239.1,89.8,239.7,90.2,240.3L90.2,240.3z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M196.6,120.2L196.6,120.2c-8.5,7.3-12.5,18.7-18.4,27.9c-12.2,19.1-33.5,6.1-50.6,4.1
c-0.8-0.1-3.2,1.2-2.5,1.3l2-0.1c14.3,1.6,29.7,10.9,43.7,3.7c13.1-6.8,15-25.9,25.8-35.3C196.6,121.2,196.6,120.7,196.6,120.2z
"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M211.3,162.3c-12.4,10.5-35.9,2.1-48.7-3.7c-0.7-0.3-1.3,0.7-0.6,1c13.1,5.9,36.8,14.3,49.8,3.9
C211.6,163.1,211.4,162.7,211.3,162.3z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M203.6,142.5c-4.1,12.7-15,20.8-28.3,21.1c-0.1,0-0.2,0-0.3,0.1c0.8,0.3,1.7,0.6,2.5,1
c12.4-1.1,22.4-8.7,26.8-20.3C204,143.9,203.8,143.2,203.6,142.5z"/>
</g>
</g>
<g>
<path class="st1" d="M220.8,354c-7.1-9.9-27.5,1.1-32.8,8.3c-5.8,8-1.1,21.2,0.7,29.7c2.5,11.9,5.1,24.1,12,34.3c0,0,0,0,0.1,0.1
c0,0,0,0,0,0.1c4.3,5,10.6,9.3,17.3,10.4c4.5,0.8,2.8-13.3,2.8-15c0.4-14,0.8-28,1.2-42C222.3,373.4,225.1,359.9,220.8,354z
M220.2,406.6c-0.2,7.6-0.5,15.3-0.7,22.9c-0.4,12.3-14.6-0.3-17.7-3.8c-8.8-13-11.2-30.1-14.1-45.3
c-2.4-12.5,1.7-22.6,14.4-26.5c9-2.7,18.6-3,19.3,8.7C222.2,377.1,220.6,392.1,220.2,406.6z"/>
</g>
<g>
<path class="st1" d="M220.2,321.8c-5-8.7-21.1-1.8-27.2,2.3c-6.8,4.6-12.5,16.1-8.4,24.1c1.9,3.7,4.2,4.9,8.3,4.6
c3-0.2,6-1.9,8.8-2.8c4.6-1.6,9.4-3,14.1-4.5c2.6-0.9,5.9-2.3,6.2-5.6C222.4,335.2,222.7,326.1,220.2,321.8z M215.5,344.4
c-6.9,2.2-13.7,4.5-20.5,6.8c-8.5,2.9-10.3-4-10.3-9.4c-0.1-5.1,2.6-9.8,5.7-13.6c5.3-6.4,14.3-8.4,22.1-8.9
C221.7,318.6,224.5,341.2,215.5,344.4z"/>
</g>
<g>
<path class="st1" d="M220.9,297c-0.2-3.7-0.3-7.3-0.8-10.9c-2-12.7-20.4-1.5-25.3,2.1c-9.3,7-13.6,19.3-11.3,30.6
c0.5,2.3,2.5,5.2,5.3,4.6c8.3-1.7,15.4-6.9,23.6-9c4.3-1,6.9-2,8.6-6.2C222.3,305.3,221.1,300.2,220.9,297z M212.1,313.3
c-6,1.5-11.4,4.2-17,6.7c-15.1,6.9-11.3-14.2-7.6-21.6c4.2-8.6,13.8-14.1,22.7-16.7c10.6-3,9.2,10.3,9.5,15.2
C220.1,303.7,221.3,311.3,212.1,313.3z"/>
</g>
<g>
<g>
<path class="st1" d="M181.7,273.3c-1-6.2-6.2-11.1-11.8-13.9c-0.6,0.2-1.1,0.4-1.7,0.5c6.3,2.9,12.3,8.4,12.3,15.5
c0,2.5-1,4.9-1.9,7.2c-0.3,0.9-0.3,1.9-1.4,1.7c-0.9-0.1-1.8-1.1-2.4-1.7c-0.9-0.9-1.8-1.8-2.8-2.6c-1.5-1.3-3.1-2.6-4.4-4.1
c-3.7-4.1-6.8-8.6-9.5-13.4c-0.4,0.1-0.8,0.1-1.3,0.2c2.3,4,4.9,7.9,7.8,11.5c1.8,2.3,3.8,4.4,5.8,6.5c1,1,2.1,2,3.2,3
c1,0.9,2,2.6,3.4,2.8c1.8,0.2,2.1-1.8,2.6-3C181,280.5,182.2,276.5,181.7,273.3z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M157.2,249c-0.3-0.2-0.7-0.5-1-0.7c-8.1,14.6-8.5,31.3-1,46.3c0.3,0.7,1.4,0.1,1-0.6
C149,279.4,149.4,263.1,157.2,249z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M176.7,256.2c-0.3,0-0.6,0-0.9-0.1c-7.4,4-15.4,6-24,5.9c-0.1,0.4-0.1,0.8-0.3,1.2
c9.6,0.1,18.6-2.3,26.7-7.1C177.7,256.2,177.2,256.2,176.7,256.2z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M168.6,277.8c-6.6-0.8-12.5-3.4-17.5-7.8c-0.1-0.1-0.2-0.1-0.3-0.1v0.1c0,0.4-0.1,0.8-0.2,1.2
c5.4,4.6,11.7,7.3,18.8,8c0.1,0,0.3,0,0.3-0.1C169.4,278.7,169,278.3,168.6,277.8z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M176.1,284.1c-0.6-0.4-1.2,0.6-0.6,1.1c6,4.1,3.4,13,2.5,18.6c-1.2,8-6.7-2-8.8-5.2
c-4.3-6.4-6.9-14.3-7.7-22.2c-0.4,0-0.8-0.1-1.2-0.2c1.2,12.7,7.5,25.3,16.9,33.5c0.3,0.3,0.9,0.1,1-0.3
C179.7,301.9,183.8,289.4,176.1,284.1z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M164.4,291.1c-5.4-1.6-10.2-4.9-13.7-9.6c0.3,1,0.6,1.9,0.9,2.9c3.7,4,8.4,6.8,13.7,8.1
C164.9,292.1,164.6,291.6,164.4,291.1z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M161.3,291.4c-0.3,11.7,1.6,26.5,11.7,33.7c10.4,7.4,5.3-13.8,4.9-17.9c-0.1-0.8-1.3-0.8-1.2,0
c0.4,3.8,0.9,7.6,1.3,11.4c0.2,1.7,0.8,5.1-1.1,6.1c-2.1,1.2-3.9-1-5.4-2.3c-1.2-1.1-2.2-2.3-3.1-3.7
c-5.2-7.2-6.1-18.9-5.9-27.4C162.5,290.6,161.3,290.6,161.3,291.4z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M163,306.7c0-0.1,0-0.1,0-0.2c-2.1-1.9-4-3.9-5.6-6.2c0.1,0.4,0.2,0.8,0.2,0.9c0.2,0.5,0.3,1,0.5,1.5
c0.1,0.3,0.2,0.6,0.3,0.8c1.5,1.8,3.2,3.5,5.1,5C163.3,307.9,163.1,307.3,163,306.7z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M176.5,325.2c0.3,0,1.2,2.9,1.3,3.2c0.4,1.2,0.7,2.4,0.9,3.6c0.5,2.4,0.7,4.9,1.1,7.3
c0.2,1.6,0.4,3.6-1.4,4.3c-3.3,1.5-5.3-1.3-7-3.6c-6.9-9-10.9-19.9-11.4-31.2c0-0.8-1.2-0.8-1.2,0c0.4,8.6,2.7,16.5,6.7,24.2
c2.1,4,6.5,12.4,11.4,13.8c3.7,1,4.3-2.7,4.3-5.4c0-6.3-1.2-11.3-3.5-16.4C177.4,325.1,177,325.2,176.5,325.2z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M178.6,344.6c0.3,3,0.5,6,0.6,9.1c0,1.7-0.1,3.4-0.2,5.1c0.2,1.6-0.8,4.2-2.8,3.9c-1-0.1-2.2-0.7-3-1.2
c-5.8-3.5-10.4-8.8-13.7-14.6c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.3-0.2,0.6-0.2,0.9c2.2,3.6,4.8,6.9,7.9,9.8c2.3,2.1,7,6.7,10.3,6.7
c2.6,0,2.6-2.5,2.8-4.5c0.5-5.5,0.3-11-0.3-16.4C179.4,344.2,179,344.4,178.6,344.6z"/>
</g>
</g>
<g>
<path class="st1" d="M218.2,249.2c-1.3-0.6-3.1-0.4-4.4-0.1c-2.3,0.4-4.5,1.2-6.7,2.2c-0.6,0.3-1.1,0.5-1.7,0.8
c-4.9,2.3-10.9,3.9-15.1,7.4c-4.3,3.6-6,7.5-6.6,13c-0.3,2.8-0.9,11.9,1.8,14.2c3.1,2.7,6.9-0.4,10-1.9
c6.5-3.1,15.1-6.1,20.2-11.2c4.1-4,6.4-10.9,6.2-16.6c-0.1-2.6-1-6-3.2-7.4C218.5,249.4,218.3,249.3,218.2,249.2z M208.8,276.6
c-4.9,2.9-22.4,14.3-23.7,2.6c-1.1-10.1,1.9-17.3,11.2-21.9c5.7-2.8,14.8-8.1,21.1-7.1c4.3,2.5,2.6,10.9,1.9,14.7
C218.2,270.7,213.5,273.8,208.8,276.6z"/>
</g>
<g>
<g>
<path class="st1" d="M207.2,604.6c-1.5-24.7-4.5-49.1-14.6-72.3c-15.1-34.7-29.7-68.5-30.4-107c0-2.5,0.1-4.8,0.6-7.2
c2.1-10.5,10,4.9,10.2,5.4c2.4,4.5,4.9,7.7,5.5,13c0.3,2.5,0.5,4.8,1.1,7.3c3.7,17.1,6.8,33.7,13,50.2
c2.8,7.6,6.6,14.7,9.3,22.3c3.1,8.5,4.4,17.9,5.8,26.8c1.8,10.9,2.6,21.8,2.7,32.8c0.5-1.6,0.9-3.1,1-4.7c0-0.1,0-0.2,0-0.4
c-0.3-9.4-1.1-18.7-2.5-28.1c-1.5-10.1-3.5-20.3-7-30c-3.5-9.8-8.3-19.1-11.7-29c-5.8-17.2-7.4-35-11.4-52.5
c-1.2-5.5-6-11.5-9.1-16.1c-0.9-1.3-3.6-2.5-5.1-1.4c-7.6,5.4-3.1,23.9-2.3,31c2.4,20.2,8.7,39.8,16.2,58.6
c7.9,19.9,18.9,38.7,23,60c2.9,15.2,4.1,30.6,4.8,46C206.5,607.7,206.9,606.2,207.2,604.6z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M203.1,649.4c-12.3,11.4-28.1-2.1-34.4-13.1c-6.5-11.5-5.9-25.3-3.1-37.8c5.2-22.8,20-42.1,26.4-64.6
c-0.3-0.6-0.5-1.3-0.7-1.9c-7.9,29.2-33.7,57.7-28.6,89.3c1.7,10.4,6.1,21.7,15.2,28c7.7,5.3,17.6,7.9,25.2,1.7c0,0,0,0,0-0.1
C203.1,650.4,203.1,649.9,203.1,649.4z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M154.5,597.4c-4-19.7-13.6-37.6-16.9-57.6c-3.2-19.7-2.3-39.9,1.3-59.5c-0.6,1.7-1.2,3.3-2,5
c-3.3,21.6-3.1,43.6,1.4,65.2c3.2,15.1,9.9,29,14.1,43.8c2,7,2.7,14.3,2.4,21.5c-0.2,4.9-1.5,9.5-3.4,14
c-3,7.1-11.3,13-17.9,10.1c0,0.5-0.1,0.9-0.1,1.4c5.2,2.1,11.2-0.5,15.9-5.6C158.1,625.7,156.9,609.3,154.5,597.4z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M151.5,404.9c-0.2,0.4-0.4,0.7-0.6,1.1c8.5,8.5,7.5,22.6,5.9,34.1c-2,13.8-4.3,33.8-18.2,40.1
c-0.1,0.5-0.3,1-0.5,1.5c15.8-6.2,17.4-26.4,19.8-41.3C159.9,428.4,160.5,413.7,151.5,404.9z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M163.3,608.9c-2.1,2.4-5.5,2.6-7.8,1c0,0.4,0,0.9-0.1,1.3c2.4,1.3,5.4,1.3,7.7-0.4
C163.2,610.2,163.3,609.5,163.3,608.9z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M158.2,757.8c-0.1,1.3-0.3,2.6-0.6,3.9c-0.1,0.4-0.2,0.8-0.4,1.1c1.4,12.1,6.5,23.4,18.5,28.5
c0.1-0.4,0.2-0.8,0.4-1.1C162.6,784.4,158.7,771.2,158.2,757.8z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M170.7,810.9c-12.5-9.3-13-28.1-12.4-43.1c0-0.8-1.2-0.8-1.2,0c-0.5,15.6,0.2,34.9,13.3,44.4
C170.4,811.8,170.6,811.3,170.7,810.9z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M146.2,707.7c-0.1-0.1-0.2-0.3-0.3-0.4c-0.4-0.6-0.7-1.2-0.9-1.8c-0.1,24.2-0.3,48.4-0.4,72.7
c-0.1,21.9,3.5,48.6-10.4,67.2c-0.5,0.6,0.6,1.2,1,0.6c12.7-17,10.4-39.9,10.5-59.9C145.9,759.9,146,733.8,146.2,707.7z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M80.8,299c-2.2,19.4,11.5,34.2,19.9,50.6c12.3,24.2,4.9,52.6-1.5,77.2c-0.2,0.7,1,1.1,1.2,0.3
c5.6-21.5,10.6-43.1,5.9-65.2c-4.7-22.5-27-38.8-24.3-63C82.2,298.2,80.9,298.3,80.8,299L80.8,299z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M67.3,348c1.2,16.1,7.8,30.6,14.3,45.2c9.3,21,14.4,42.5,14.2,65.5c0,0.8,1.2,0.8,1.2,0
c0.1-18.4-2.9-36.1-9.1-53.3c-7-19.3-17.9-36.6-19.4-57.4C68.4,347.2,67.2,347.2,67.3,348L67.3,348z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M118,343.3c-5.3,3.6-9.9,7.8-14,12.6c0.2,0.4,0.4,0.8,0.6,1.2c4-4.9,8.7-9.2,14-12.8
C119.2,343.9,118.6,342.9,118,343.3z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M98.6,461c-17.8-21.9-33.2-46-35.6-75c-0.1-0.8-1.3-0.8-1.2,0c2.6,31.6,20.5,57.4,40.3,81
C100.7,465.1,99.6,463.1,98.6,461z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M140.8,231.5c-0.8,21.2-4.9,41.8-12.1,61.8c-3.4,9.5-6.8,19.3-12,28c-4.8,8.1-12,14.9-19.1,21.2
c0.3,0.3,0.5,0.6,0.7,0.9c6.3-5.8,12.2-11.8,17.5-18.6c6.2-8.1,9.1-18.3,12.7-27.7c7.9-20.7,12.4-42.2,13.4-64.4
C141.6,232.3,141.2,231.9,140.8,231.5z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M99.5,342c-11.6-29.9-16.6-67.5-2.2-96.5c-0.4-0.1-0.8-0.1-1.2-0.3c-14.4,29.5-9.3,67.4,2.4,97.6
C98.8,342.4,99.1,342.2,99.5,342z"/>
</g>
</g>
</g>
<g>
<g>
<g>
<g>
<g>
<path class="st1" d="M223.9,118.3c0.2-0.6,0.2-1.2,0.1-1.7c2.1,0,5.6-0.3,7.9-1.1c3.4-1.2,21.2-15.1,23.4-19.1
c2.2-4,1.6-14,1.5-14.1l-0.3-4.7l2.2,4.2c0,0.1,0.7,1.3,2,1.1c1.9-0.2,5-5.8,6.9-9.1c0.9-1.7,1.7-3.1,2.4-4.1
c2-2.8,1.9-9.9-0.1-16.4c-0.3-1-0.8-1.6-1.3-1.7c-1.4-0.3-3.9,2-5.3,3.7l-2,2.5l0.2-3.2c0-0.3,2.1-29-8.1-41.1
C244.7,2.9,224.2,2,224,2l0.1-2c0.9,0,21.6,0.9,30.9,12.1c8.8,10.6,8.9,32.2,8.7,39.8c1.4-1.3,3.4-2.7,5.3-2.4
c1.3,0.2,2.3,1.3,2.9,3.1c1.9,6.1,2.5,14.4-0.1,18.1c-0.6,0.9-1.4,2.4-2.3,3.9c-3.1,5.5-5.6,9.8-8.4,10.1
c-0.8,0.1-1.5,0-2-0.3c0.1,3.4-0.1,9.5-1.9,12.7c-2.4,4.5-20.6,18.6-24.5,20.1C230,118.2,226.1,118.4,223.9,118.3
C223.9,118.5,223.9,118.4,223.9,118.3z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M369.7,565.1l0.3-0.8c0.4-1,9-23.7,10-28.4c1.1-5.1,5.3-57,4.3-84.6c-0.6-18.2,1.1-32.2,2.7-45.7
c0.9-7.6,1.8-14.8,2.3-22.6c1.2-20.4-4.5-30.8-5.6-32.6v-16c0-15.6-11.7-32.6-13.3-34.9c-0.2-3-1.5-25.7-3.7-36.4
c-2.1-10.1-5.8-19.8-6.6-21.7c0.6-1.7,3.1-9.4,4.6-18.8c1.7-10.8-5.6-37.2-10-48.4c-2.9-7.4-9-10.2-13.9-12.5
c-2.6-1.2-4.9-2.3-6.2-3.8c-3.3-3.8-6.6-4.9-9.5-5.8c-0.8-0.2-1.5-0.5-2.2-0.8c-1.1-0.4-2.1-1.3-3.6-2.7
c-2.5-2.3-6.4-5.8-14.6-10.5c-4.5-2.6-10.8-5.6-17.5-8.9c-11-5.3-23.4-11.2-26.5-15c-3.3-4.1-3.6-17.4-3.6-17.5h-2
c0,0.6,0.3,14.1,4,18.8c3.3,4.1,15.4,9.9,27.2,15.5c6.7,3.2,13,6.2,17.4,8.8c8,4.6,11.7,8,14.2,10.3c1.6,1.4,2.7,2.5,4.2,3.1
c0.7,0.3,1.5,0.6,2.3,0.8c2.8,0.9,5.6,1.8,8.6,5.2c1.6,1.8,4.2,3,6.9,4.3c4.8,2.2,10.2,4.7,12.9,11.4
c4.3,11,11.5,36.9,9.9,47.3c-1.6,10.4-4.6,18.7-4.6,18.8l-0.1,0.4l0.1,0.4c0,0.1,4.3,10.7,6.6,21.8
c2.3,11.1,3.6,36.1,3.6,36.4v0.3l0.2,0.2c0.1,0.2,13.1,18.2,13.1,34V351l0.2,0.3c0.1,0.1,6.8,10.3,5.5,31.6
c-0.5,7.7-1.3,14.9-2.3,22.5c-1.7,13.6-3.4,27.7-2.7,46c1,28.7-3.4,80-4.3,84.1c-0.9,4-7.9,22.6-9.6,27.1
c-1.2-0.7-3.4-2.5-3.4-7c0-6.8,4.2-21.5,4.3-21.7l0.1-0.2v-0.2c0-0.1-1.9-13.1-0.7-18.5l0.1-0.3l-0.1-0.2
c-1-3.5-6.2-20.9-11-22.7c-0.8-0.3-1.6-0.2-2.3,0.2c-2.9,1.9-3.5,6-4.1,10.4c-0.5,3.4-0.9,7-2.6,9.6c-2.4,3.8-4.2,4.6-5,4.7
c-0.5,0.1-1-0.1-1.4-0.4c-1.2-1.1-1.9-11-1.8-19.5l0.1-2.6c0.5-9.8,0.9-16.8,5.9-23c7.8-9.7,9.9-15.6,7.7-33.8
c-0.9-7.6-5.5-19.6-10.3-32.4c-5.4-14.3-11-29.2-11.4-37.5c-0.6-15.5,1.9-21.8,1.9-21.8l0.2-0.6l-0.4-0.5
c0,0-4.1-4.5-5.1-10.4c-1-6.1-0.7-17.3-0.7-17.4c-0.2-1-3.4-19.8-9.1-28.8c-5.8-9.3-14.1-29.1-14.1-31.1l-1.3,2.1
c0,2.7,8.2,21,13.8,29.8c5.5,8.7,8.8,27.7,8.8,27.9c0,0.3-0.3,11.3,0.7,17.6c0.9,5.4,4,9.7,5.2,11c-0.6,1.9-2.4,8.6-1.9,22.1
c0.4,8.7,6,23.7,11.5,38.2c4.8,12.7,9.3,24.7,10.2,32c2.2,17.6,0.3,23-7.2,32.3c-5.4,6.7-5.8,14-6.4,24.1l-0.2,2.7
c0,4.4,0,18.8,2.5,21.1c0.8,0.8,1.9,1.1,3,1c2.1-0.3,4.3-2.2,6.5-5.6c1.9-2.9,2.4-6.7,2.9-10.4c0.5-3.9,1-7.6,3.2-9
c0.1-0.1,0.3-0.1,0.5,0c2.9,1.1,7.5,13.5,9.7,21.2c-1.2,5.6,0.4,17,0.7,18.8c-0.5,1.9-4.3,15.3-4.3,22c0,7.7,5.7,9.3,5.7,9.3
H369.7z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M357.9,542.7c3.4-5.2,7.7-13.2,9.2-16.2c0,0,0,0,0,0.1v0.2c0,0.9,0.3,1.8,0.8,2.6
c-2,3.7-5.5,10.1-8.3,14.5c-3,4.6-0.3,10.4,1,12.7l3.4-3.9c0,1,0,2.1-0.1,3.1l-3.6,4.1l-0.7-1
C359.4,558.4,353.3,549.7,357.9,542.7z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M356.9,551.7c-0.9-1.4-5.9-9.3-1.6-15.8c4.2-6.4,11.2-19.7,11.3-19.9l0.9,0.5c0,0.5,0,1,0.1,1.5
c0,0.1,0,0.2,0.1,0.2c-1.9,3.5-7.2,13.4-10.7,18.8c-2.2,3.4-1.4,7.4-0.2,10.2c-0.3,0.9-0.2,1.9-0.1,2.9
C356.7,550.6,356.8,551.1,356.9,551.7z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M290,306.6c0.6-3.2,2.9-9.2,5.3-15.5c1.7-4.3,3.4-8.8,4.7-12.9c2.5-7.4,5-31,6.1-42.1
c0.8-0.8,1.6-1.7,2.3-2.6c-0.9,9.3-3.6,36.9-6.4,45.3c-1.4,4.1-3.1,8.6-4.7,12.9c-2.4,6.2-4.6,12.1-5.2,15.2
c-0.8,4.3-0.2,36.3,0,46.9c0,2.2,0.1,3.7,0.1,4.1c0.1,0.5,1.2,2.2,2.1,3.8c3.3,5.2,8.8,13.9,7.8,22.6
c-1.2,9.6-2.6,13.6-3.2,14.8c1.1,3.5,9.1,29.7,7.9,48.7c-0.4,6.6,2.2,16.9,5.5,30c4.2,16.8,9.5,37.8,11.3,61.9
c2.6,35.7-4.9,94.5-7.7,116.6c-0.6,4.7-1,7.9-1.1,8.8c-0.1,2.3,0.1,3.9,0.3,5.8c0.3,2.5,0.7,5.4,0.3,10.8
c-0.7,9.4-15,33.4-15.5,34.2c0,0,12.8-25.4,13.5-34.3c0.4-5.2,0-8-0.3-10.4c-0.3-1.9-0.5-3.7-0.3-6.2c0.1-1,0.5-4.2,1.1-8.9
c2.8-22.1,10.3-80.6,7.7-116.2c-1.8-24-7-44.8-11.2-61.6c-3.3-13.2-6-23.7-5.5-30.6c1.3-19.8-7.8-48-7.9-48.3l-0.1-0.4
l0.2-0.4c0,0,1.8-3.4,3.1-14.5c1-7.9-4.3-16.2-7.5-21.2c-1.7-2.7-2.4-3.9-2.4-4.8c0-0.4,0-1.9-0.1-4.1
C289.6,335.5,289.2,310.7,290,306.6z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M351,932.7c1.2-0.6,2.8-1.4,3.7-3c0.6-1.1,0.7-2.5,0.4-3.9c-0.5-2-1.6-3.5-2.6-4.8
c-0.4-0.5-0.8-1-1.2-1.6c-1-1.5-1.6-3-2.3-4.7l-0.3-0.7c-5.9-13.9-14.7-27.2-22.4-38.9l-0.2-0.2c0,0-2.6-2.3-2.3-9.8
c0.2-3.8-1-5.7-2.3-7.8c-1.8-2.9-4-6.4-4.7-16.9c-0.7-9.8,2.8-34.3,6.2-58c3-20.9,5.9-40.7,5.7-49.6
c-0.3-18.9-13.4-48.3-14-49.5l-1.8,0.8c0.1,0.3,13.5,30.3,13.8,48.8c0.2,8.8-2.7,28.5-5.7,49.3c-3.4,23.8-7,48.4-6.3,58.4
c0.8,11,3.2,14.9,5,17.8c1.3,2.1,2.1,3.5,2,6.7c-0.3,7.8,2.2,10.7,2.9,11.3c7.7,11.6,16.3,24.7,22.1,38.4l0.3,0.7
c0.7,1.7,1.4,3.4,2.5,5c0.4,0.6,0.8,1.2,1.2,1.7c1,1.3,1.9,2.5,2.3,4.1c0.2,0.9,0.2,1.8-0.2,2.5c-0.5,0.9-1.6,1.5-2.8,2.2
L351,932.7z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M259.9,686.6l1.9,0.4c-0.1,0.4-9.3,41.2-4.1,58.4c3.3,11.1,7.5,19.9,10.9,27c2,4.3,3.8,7.9,5,11.3
c3.3,9,14.1,53.8,12.3,63c-0.6,3.4-1.5,6.3-2.2,8.9c-1,3.7-1.9,6.6-1.2,8.2c0.3,0.7,0.7,1.5,1.1,2.4c1.9,3.9,3.1,6.7,2.3,8.3
l-0.3,0.6c-4.7,9-6.3,15.7-4.8,20c1.9,5.2,7.6,11.9,10.7,15.2c-0.1,0.4-0.2,0.8-0.1,1.3c0.1,0.6,0.1,1.1,0.2,1.7
c-2.1-2.2-10.3-10.8-12.7-17.5c-1.7-4.8-0.1-12.1,4.9-21.6l0.3-0.6c0.4-0.9-1.4-4.5-2.3-6.5c-0.4-0.9-0.9-1.8-1.2-2.5
c-1-2.2-0.1-5.3,1.1-9.5c0.7-2.5,1.5-5.4,2.2-8.7c1.6-8.6-9.1-53.2-12.3-61.9c-1.2-3.3-2.9-7-4.9-11.2
c-3.4-7.1-7.6-16-11-27.2C250.5,728.3,259.5,688.3,259.9,686.6z"/>
</g>
</g>
<g>
<path class="st1" d="M338.5,940.3L338.5,940.3c0-0.2,0-0.3,0-0.4c1,0.3,3,0.6,4.6-0.9s0.5-4.2-0.3-5.8
c-1.6-3.5-4.1-6.5-5.9-9.8c-0.2-0.4-3.2-6.1-2.9-6.2c0.3-0.1,2.5,2.7,2.7,3c1.5,1.6,2.4,3.5,3.5,5.4c0.6,1,1.5,1.8,2.2,2.8
c1,1.4,1.8,3,2.5,4.6c1,2.4,1.7,5.5-0.4,7.5c-1.4,1.3-2.9,1.7-4.3,1.7c-0.7,0-1.4-0.1-2-0.3
C338.3,941.3,338.5,940.8,338.5,940.3z"/>
</g>
<g>
<g>
<path class="st1" d="M292,904.6c0,0-0.6,15.3,3.5,21.9c4.1,6.7,8,9.3,8,9.3l0.2,0.2l0.1,0.3c0.1,0.1,5.2,11.7,13.7,11.7
c5.3,0,6.5-2.3,8.1-5.2l0.2-0.3c0.4-0.7-1.1-3.7-1.3-4.3c-2.3-6-5.6-10.5-8.3-16.2c0,0,3.6,4.6,5.6,7.2
c1.8,2.4,3.3,5.1,4.4,7.8c0.4,1.1,0.8,2.2,1.2,3.3c0.1,0.3,0.2,1.6,0.4,1.8c1.3,1.1,4.3,3.2,6.6,2.6c2.6-0.7,2.8-2.3,2.9-4.9
c0-1-2.8-6.2-5.3-10.4h0.1c-2.1-2.8-3.7-5.9-4.8-9.2c2.9,3.5,5.8,7,8.1,10.9c0.7,1.1,1.3,2.3,1.9,3.5c0.8,1.5,2.2,3.6,2.1,5.3
c-0.1,2.5-0.3,5.6-4.3,6.7c-2.9,0.8-6.2-1.2-7.8-2.4c-1.5,2.8-3.4,5.8-9.6,5.8c-9.2,0-14.6-10.8-15.4-12.6
c-1-0.7-4.5-3.6-8.4-9.8C289.4,920.4,291.9,905.3,292,904.6z"/>
</g>
<g>
<path class="st1" d="M338.7,915c0.6,0,5.1,3.7,6.8,6.3c0.7,1,1.3,2,2.1,2.9c2.2,2.6,4.8,6.5,3.1,10c-1,2.2-2.9,2.8-4.4,2.8
c-0.6,0-1.1-0.1-1.4-0.2l0.6-1.9c0.1,0,2.4,0.7,3.5-1.5c1-2-0.1-4.1-1.2-5.7C344.6,923.5,341.8,919.2,338.7,915z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M247.4,468.6c1.3-3.1,2.7-6.4,4.3-9.2c5.5-9.6,9.9-18.4,13.8-26.1c4.7-9.4,8.5-17,12.7-23
c0.5,0.2,1.1,0.3,1.9,0.4c0.1,0,0.2,0,0.3,0c-4.3,5.9-8.3,13.8-13.1,23.5c-3.9,7.8-8.3,16.6-13.9,26.2c-1.6,2.7-2.9,5.8-4.2,9
c-4.3,10.1-9.2,21.5-24,21.5v-2C238.7,488.9,242.9,479,247.4,468.6z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M295.4,693.2c0.1,0,0.3,0,0.4,0c4.9-0.8,14.1-16.7,14.1-18.9h-1c0,0-10,16.4-13.4,17
c-3.6,0.5-29.5-18.6-29.5-18.6C270.3,677.4,289.7,693.2,295.4,693.2z"/>
</g>
</g>
<g>
<path class="st1" d="M227,488.8l2,0.4c0,0.1-2.4,12-1,23.5c0.6,4.8,2.5,12.8,4.7,22.1c3.2,13.7,7.2,30.7,9.1,45.9
c1.6,12.2,2.9,27.5,4,41c1.2,14.6,2.4,28.3,3.6,35.2c1.5,8.3,5.4,16.4,9.2,23.9c2.3,4.4,4.5,9.5,7.8,13.3
c8.9,10.4,18.4,20.5,22.8,33.7c3,9.1,3.6,18.5,4.2,28c0.2,3.2,0.4,6.2,0.6,9.1c1.4,15.5,1.3,30.8,1,47.6c0,1.5,0,3.1,0,4.7
c-0.1,13.2-0.1,28.2-11.7,36.5l-0.2-0.3c0.2-0.7,0.3-1.4,0.5-2.1c0-0.2,0-0.3,0.1-0.4c9.3-8,9.4-21.6,9.4-33.7
c0-1.6,0-3.2,0-4.7c0.3-16.8,0.5-32-1-47.4c-0.3-2.9-0.4-5.9-0.6-9.1c-0.5-8.4-0.7-16.9-3.3-25c-2-6.1-5-11.8-8.7-17
c-3.6-5.3-8.2-9.8-11.9-15c-6.3-8.6-11.5-18.2-15.6-28.1c-1.8-4.4-3.6-9-4.4-13.7c-1.3-7-2.4-20.1-3.7-35.4
c-1.1-13.5-2.4-28.7-4-40.9c-1.9-15-5.9-32-9.1-45.7c-2.2-9.3-4.1-17.4-4.7-22.3C224.5,501.1,226.9,489.3,227,488.8z"/>
</g>
</g>
<g>
<path class="st1" d="M232.3,180.7L232,179c-0.1-0.7-3.3-17.9,0-29.1c1.3-4.5,3.2-9,5.3-14.3c3.2-7.8,7.2-17.4,11.2-31.6v-0.1
l8.4-8.3l-0.3,8.6c0,0.2-2.2,20.5-6.1,30.1c-3.9,9.5-17.5,44.5-17.6,44.8L232.3,180.7z M249.5,104.4c-4,14.1-8,23.8-11.2,31.6
c-2.1,5.2-4,9.7-5.3,14.2c-2.6,9-1,22.1-0.3,26.9c2.8-7.2,13.5-34.8,17-43.2c3.9-9.5,6-29.6,6-29.8l0.2-6L249.5,104.4z"/>
</g>
<g>
<g>
<path class="st1" d="M299.4,396.6c-6.2,5.1-12.3,10.5-18.9,14.9c-4.7,3.1-11.2-10.7-12.8-13.6c-4.9-8.5-1.1-19.7,4.5-27
c4.6-5.9,12.8-9.1,19.4-12c0.7-0.3,1.3,0.8,0.6,1.1c-12.8,5.5-29,15.5-25.4,32.8c1,4.7,5,9.7,7.9,13.2c4,4.9,7.8,2.7,12-0.7
c4-3.2,7.9-6.4,11.8-9.6C299.1,395.2,300,396.1,299.4,396.6L299.4,396.6z"/>
</g>
</g>
<g>
<path class="st1" d="M227.6,212.1c1.1,9.5,1.7,22.9,8.9,30.1c10.6,10.7,27,14.9,41.8,14.4c10.4-0.4,20.3-11.2,26.4-18.4
c5.5-6.4,10.6-13.2,15-20.3c0.1-0.2,0.1-0.3,0.1-0.5c-4.1-29.1-36.5-45.4-63.2-46.5c-11.6-0.5-21,2.3-27.5,12.3
C224.2,190.6,226.6,203.8,227.6,212.1z M229.4,184.8c8.1-12.5,20.8-14.7,35-11.9c24,4.7,50.4,17.6,54.3,44.4
c-8,13.1-19.4,29.9-33.9,36.1c-13.3,5.7-35.6-0.4-45.7-10.5c-7.8-7.8-9.2-20.3-10.2-30.9C228.1,205.1,225.2,191.2,229.4,184.8z"
/>
</g>
<g>
<g>
<path class="st1" d="M335.8,160.3c-3.1,8.5-15.9,5.6-22.7,5.1c-8.9-0.6-18.2,1.7-14.9,13c2,6.8,7.5,12.9,12.5,17.7
c0.6,0.5-0.3,1.4-0.8,0.8c-5.7-5.4-18.9-19.7-12.1-28.4c8.2-10.4,32.3,3.8,36.9-8.6C334.9,159.3,336.1,159.6,335.8,160.3
L335.8,160.3z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M360.1,240.3c-1.6,2.2-3.3,4.4-4.9,6.5c-0.2,0.2-0.4,0.4-0.7,0.3c-16-4.6-28.9-15.6-36.1-30.9
c-0.3-0.7,0.7-1.3,1-0.6c3.5,7.5,8.3,13.8,14.4,19.2c3.6,3.2,7.8,5.7,12.1,7.8c6.6,3.3,8.9,2.7,13.2-3
C359.6,239.1,360.6,239.7,360.1,240.3L360.1,240.3z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M253.8,120.2L253.8,120.2c8.5,7.3,12.5,18.7,18.4,27.9c12.2,19.1,33.5,6.1,50.6,4.1
c0.8-0.1,3.2,1.2,2.5,1.3l-2-0.1c-14.3,1.6-29.7,10.9-43.7,3.7c-13.1-6.8-15-25.9-25.8-35.3
C253.7,121.2,253.8,120.7,253.8,120.2z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M239.1,162.3c12.4,10.5,35.9,2.1,48.7-3.7c0.7-0.3,1.3,0.7,0.6,1c-13.1,5.9-36.8,14.3-49.8,3.9
C238.8,163.1,238.9,162.7,239.1,162.3z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M246.8,142.5c4.1,12.7,15,20.8,28.3,21.1c0.1,0,0.2,0,0.3,0.1c-0.8,0.3-1.7,0.6-2.5,1
c-12.4-1.1-22.4-8.7-26.8-20.3C246.4,143.9,246.6,143.2,246.8,142.5z"/>
</g>
</g>
<g>
<path class="st1" d="M228.2,379.8c0.4,14,0.8,28,1.2,42c0,1.6-1.7,15.7,2.8,15c6.7-1.1,12.9-5.4,17.3-10.4c0,0,0,0,0-0.1
c0,0,0,0,0.1-0.1c6.9-10.2,9.5-22.5,12-34.3c1.8-8.5,6.6-21.7,0.7-29.7c-5.3-7.2-25.7-18.2-32.8-8.3
C225.3,359.9,228,373.4,228.2,379.8z M229,362.6c0.7-11.7,10.3-11.4,19.3-8.7c12.8,3.9,16.8,14,14.4,26.5
c-2.9,15.2-5.3,32.3-14.1,45.3c-3.1,3.6-17.4,16.2-17.7,3.8c-0.2-7.6-0.5-15.3-0.7-22.9C229.8,392.1,228.2,377.1,229,362.6z"/>
</g>
<g>
<path class="st1" d="M228.4,339.9c0.2,3.3,3.5,4.6,6.2,5.6c4.7,1.5,9.5,2.9,14.1,4.5c2.8,1,5.8,2.6,8.8,2.8
c4.1,0.3,6.4-0.9,8.3-4.6c4.1-8-1.6-19.5-8.4-24.1c-6.1-4.1-22.2-11-27.2-2.3C227.7,326.1,228,335.2,228.4,339.9z M237.9,319.2
c7.8,0.5,16.8,2.5,22.1,8.9c3.1,3.8,5.8,8.5,5.7,13.6c-0.1,5.4-1.8,12.3-10.3,9.4c-6.8-2.3-13.6-4.6-20.5-6.8
C225.9,341.2,228.7,318.6,237.9,319.2z"/>
</g>
<g>
<path class="st1" d="M229.4,308.3c1.7,4.2,4.3,5.2,8.6,6.2c8.2,2.1,15.4,7.3,23.6,9c2.8,0.6,4.8-2.3,5.3-4.6
c2.3-11.3-1.9-23.6-11.3-30.6c-4.8-3.6-23.2-14.7-25.3-2.1c-0.6,3.6-0.7,7.3-0.8,10.9C229.3,300.2,228.1,305.3,229.4,308.3z
M230.7,297c0.3-5-1.1-18.2,9.5-15.2c8.9,2.5,18.5,8.1,22.7,16.7c3.7,7.4,7.5,28.5-7.6,21.6c-5.6-2.6-11-5.2-17-6.7
C229.1,311.3,230.3,303.7,230.7,297z"/>
</g>
<g>
<g>
<path class="st1" d="M268.7,273.3c1-6.2,6.2-11.1,11.8-13.9c0.6,0.2,1.1,0.4,1.7,0.5c-6.3,2.9-12.3,8.4-12.3,15.5
c0,2.5,1,4.9,1.9,7.2c0.3,0.9,0.3,1.9,1.4,1.7c0.9-0.1,1.8-1.1,2.4-1.7c0.9-0.9,1.8-1.8,2.8-2.6c1.5-1.3,3.1-2.6,4.4-4.1
c3.7-4.1,6.8-8.6,9.5-13.4c0.4,0.1,0.8,0.1,1.3,0.2c-2.3,4-4.9,7.9-7.8,11.5c-1.8,2.3-3.8,4.4-5.8,6.5c-1,1-2.1,2-3.2,3
c-1,0.9-2,2.6-3.4,2.8c-1.8,0.2-2.1-1.8-2.6-3C269.4,280.5,268.2,276.5,268.7,273.3z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M293.2,249c0.3-0.2,0.7-0.5,1-0.7c8.1,14.6,8.5,31.3,1,46.3c-0.3,0.7-1.4,0.1-1-0.6
C301.4,279.4,301,263.1,293.2,249z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M273.7,256.2c0.3,0,0.6,0,0.9-0.1c7.4,4,15.4,6,24,5.9c0.1,0.4,0.1,0.8,0.3,1.2
c-9.6,0.1-18.6-2.3-26.7-7.1C272.7,256.2,273.2,256.2,273.7,256.2z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M281.7,277.8c6.6-0.8,12.5-3.4,17.5-7.8c0.1-0.1,0.2-0.1,0.3-0.1v0.1c0,0.4,0.1,0.8,0.2,1.2
c-5.4,4.6-11.7,7.3-18.8,8c-0.1,0-0.3,0-0.3-0.1C281,278.7,281.4,278.3,281.7,277.8z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M274.3,284.1c0.6-0.4,1.2,0.6,0.6,1.1c-6,4.1-3.4,13-2.5,18.6c1.2,8,6.7-2,8.8-5.2
c4.3-6.4,6.9-14.3,7.7-22.2c0.4,0,0.8-0.1,1.2-0.2c-1.2,12.7-7.5,25.3-16.9,33.5c-0.3,0.3-0.9,0.1-1-0.3
C270.6,301.9,266.6,289.4,274.3,284.1z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M286,291.1c5.4-1.6,10.2-4.9,13.7-9.6c-0.3,1-0.6,1.9-0.9,2.9c-3.7,4-8.4,6.8-13.7,8.1
C285.5,292.1,285.7,291.6,286,291.1z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M289.1,291.4c0.3,11.7-1.6,26.5-11.7,33.7c-10.4,7.4-5.3-13.8-4.9-17.9c0.1-0.8,1.3-0.8,1.2,0
c-0.4,3.8-0.9,7.6-1.3,11.4c-0.2,1.7-0.8,5.1,1.1,6.1c2.1,1.2,3.9-1,5.4-2.3c1.2-1.1,2.2-2.3,3.1-3.7
c5.2-7.2,6.1-18.9,5.9-27.4C287.9,290.6,289.1,290.6,289.1,291.4z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M287.4,306.7c0-0.1,0-0.1,0-0.2c2.1-1.9,4-3.9,5.6-6.2c-0.1,0.4-0.2,0.8-0.2,0.9c-0.2,0.5-0.3,1-0.5,1.5
c-0.1,0.3-0.2,0.6-0.3,0.8c-1.5,1.8-3.2,3.5-5.1,5C287.1,307.9,287.2,307.3,287.4,306.7z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M273.8,325.2c-0.3,0-1.2,2.9-1.3,3.2c-0.4,1.2-0.7,2.4-0.9,3.6c-0.5,2.4-0.7,4.9-1.1,7.3
c-0.2,1.6-0.4,3.6,1.4,4.3c3.3,1.5,5.3-1.3,7-3.6c6.9-9,10.9-19.9,11.4-31.2c0-0.8,1.2-0.8,1.2,0c-0.4,8.6-2.7,16.5-6.7,24.2
c-2.1,4-6.5,12.4-11.4,13.8c-3.7,1-4.3-2.7-4.3-5.4c0-6.3,1.2-11.3,3.5-16.4C273,325.1,273.4,325.2,273.8,325.2z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M271.7,344.6c-0.3,3-0.5,6-0.6,9.1c0,1.7,0.1,3.4,0.2,5.1c-0.2,1.6,0.8,4.2,2.8,3.9c1-0.1,2.2-0.7,3-1.2
c5.8-3.5,10.4-8.8,13.7-14.6c0,0.2,0.1,0.5,0.2,0.7c0.1,0.3,0.2,0.6,0.2,0.9c-2.2,3.6-4.8,6.9-7.9,9.8c-2.3,2.1-7,6.7-10.3,6.7
c-2.6,0-2.6-2.5-2.8-4.5c-0.5-5.5-0.3-11,0.3-16.4C271,344.2,271.3,344.4,271.7,344.6z"/>
</g>
</g>
<g>
<path class="st1" d="M231.7,249.5c-2.3,1.5-3.2,4.9-3.2,7.4c-0.2,5.6,2.2,12.6,6.2,16.6c5.2,5.1,13.8,8.2,20.2,11.2
c3.1,1.5,6.9,4.5,10,1.9c2.7-2.2,2-11.4,1.8-14.2c-0.5-5.5-2.2-9.4-6.6-13c-4.2-3.5-10.2-5.1-15.1-7.4c-0.6-0.3-1.1-0.5-1.7-0.8
c-2.1-1-4.3-1.9-6.7-2.2c-1.4-0.2-3.1-0.4-4.4,0.1C232,249.3,231.9,249.4,231.7,249.5z M231.1,264.9c-0.7-3.8-2.5-12.2,1.9-14.7
c6.3-1.1,15.4,4.3,21.1,7.1c9.4,4.6,12.4,11.8,11.2,21.9c-1.3,11.7-18.8,0.3-23.7-2.6C236.9,273.8,232.2,270.7,231.1,264.9z"/>
</g>
<g>
<g>
<path class="st1" d="M243.2,604.6c1.5-24.7,4.5-49.1,14.6-72.3c15.1-34.7,29.7-68.5,30.4-107c0-2.5-0.1-4.8-0.6-7.2
c-2.1-10.5-10,4.9-10.2,5.4c-2.4,4.5-4.9,7.7-5.5,13c-0.3,2.5-0.5,4.8-1.1,7.3c-3.7,17.1-6.8,33.7-13,50.2
c-2.8,7.6-6.6,14.7-9.3,22.3c-3.1,8.5-4.4,17.9-5.8,26.8c-1.8,10.9-2.6,21.8-2.7,32.8c-0.5-1.6-0.9-3.1-1-4.7
c0-0.1,0-0.2,0-0.4c0.3-9.4,1.1-18.7,2.5-28.1c1.5-10.1,3.5-20.3,7-30c3.5-9.8,8.3-19.1,11.7-29c5.8-17.2,7.4-35,11.4-52.5
c1.2-5.5,6-11.5,9.1-16.1c0.9-1.3,3.6-2.5,5.1-1.4c7.6,5.4,3.1,23.9,2.3,31c-2.4,20.2-8.7,39.8-16.2,58.6
c-7.9,19.9-18.9,38.7-23,60c-2.9,15.2-4.1,30.6-4.8,46C243.8,607.7,243.5,606.2,243.2,604.6z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M247.3,649.4c12.3,11.4,28.1-2.1,34.4-13.1c6.5-11.5,5.9-25.3,3.1-37.8c-5.2-22.8-20-42.1-26.4-64.6
c0.3-0.6,0.5-1.3,0.7-1.9c7.9,29.2,33.7,57.7,28.6,89.3c-1.7,10.4-6.1,21.7-15.2,28c-7.7,5.3-17.6,7.9-25.2,1.7c0,0,0,0,0-0.1
C247.3,650.4,247.3,649.9,247.3,649.4z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M295.8,597.4c4-19.7,13.6-37.6,16.9-57.6c3.2-19.7,2.3-39.9-1.3-59.5c0.6,1.7,1.2,3.3,2,5
c3.3,21.6,3.1,43.6-1.4,65.2c-3.2,15.1-9.9,29-14.1,43.8c-2,7-2.7,14.3-2.3,21.5c0.2,4.9,1.5,9.5,3.4,14
c3,7.1,11.3,13,17.9,10.1c0,0.5,0.1,0.9,0.1,1.4c-5.2,2.1-11.2-0.5-15.9-5.6C292.3,625.7,293.5,609.3,295.8,597.4z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M298.9,404.9c0.2,0.4,0.4,0.7,0.6,1.1c-8.5,8.5-7.5,22.6-5.8,34.1c2,13.8,4.3,33.8,18.2,40.1
c0.1,0.5,0.3,1,0.5,1.5c-15.8-6.2-17.4-26.4-19.8-41.3C290.5,428.4,289.9,413.7,298.9,404.9z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M287,608.9c2.1,2.4,5.5,2.6,7.8,1c0,0.4,0,0.9,0.1,1.3c-2.4,1.3-5.4,1.3-7.7-0.4
C287.2,610.2,287.1,609.5,287,608.9z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M292.2,757.8c0.1,1.3,0.3,2.6,0.6,3.9c0.1,0.4,0.2,0.8,0.4,1.1c-1.4,12.1-6.5,23.4-18.5,28.5
c-0.1-0.4-0.2-0.8-0.4-1.1C287.8,784.4,291.6,771.2,292.2,757.8z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M279.7,810.9c12.5-9.3,13-28.1,12.4-43.1c0-0.8,1.2-0.8,1.2,0c0.5,15.6-0.2,34.9-13.3,44.4
C279.9,811.8,279.8,811.3,279.7,810.9z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M304.2,707.7c0.1-0.1,0.2-0.3,0.3-0.4c0.4-0.6,0.7-1.2,0.9-1.8c0.1,24.2,0.3,48.4,0.4,72.7
c0.1,21.9-3.5,48.6,10.4,67.2c0.5,0.6-0.6,1.2-1,0.6c-12.7-17-10.4-39.9-10.5-59.9C304.5,759.9,304.3,733.8,304.2,707.7z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M369.6,299c2.2,19.4-11.5,34.2-19.9,50.6c-12.3,24.2-4.9,52.6,1.5,77.2c0.2,0.7-1,1.1-1.2,0.3
c-5.6-21.5-10.6-43.1-5.9-65.2c4.7-22.5,27-38.8,24.3-63C368.2,298.2,369.5,298.3,369.6,299L369.6,299z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M383.1,348c-1.2,16.1-7.8,30.6-14.3,45.2c-9.3,21-14.4,42.5-14.2,65.5c0,0.8-1.2,0.8-1.2,0
c-0.1-18.4,2.9-36.1,9.1-53.3c6.9-19.2,17.8-36.5,19.4-57.3C382,347.2,383.2,347.2,383.1,348L383.1,348z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M332.4,343.3c5.3,3.6,9.9,7.8,14,12.6c-0.2,0.4-0.4,0.8-0.6,1.2c-4-4.9-8.7-9.2-14-12.8
C331.2,343.9,331.7,342.9,332.4,343.3z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M351.7,461c17.8-21.9,33.2-45.9,35.6-74.9c0.1-0.8,1.3-0.8,1.2,0c-2.6,31.6-20.5,57.4-40.3,81
C349.6,465.1,350.8,463.1,351.7,461z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M309.5,231.5c0.8,21.2,4.9,41.8,12.1,61.8c3.4,9.5,6.8,19.3,12,28c4.8,8.1,12,14.9,19.1,21.2
c-0.3,0.3-0.5,0.6-0.7,0.9c-6.3-5.8-12.2-11.8-17.5-18.6c-6.2-8.1-9.1-18.3-12.7-27.7c-7.9-20.7-12.4-42.2-13.4-64.4
C308.8,232.3,309.2,231.9,309.5,231.5z"/>
</g>
</g>
<g>
<g>
<path class="st1" d="M350.9,342c11.6-29.9,16.6-67.5,2.2-96.5c0.4-0.1,0.8-0.1,1.2-0.3c14.4,29.5,9.3,67.4-2.4,97.6
C351.6,342.4,351.3,342.2,350.9,342z"/>
</g>
</g>
</g>
</g>
</g>
</svg>
</template>
