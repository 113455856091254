<template>
	<div class="-userblock" style="text-align: left;">
	
		<template v-if="templateModel.appointmentData.checkdata.length > 0">
			<template v-for="data in templateModel.appointmentData.checkdata" :key="data.id">
				<div class="grid-x align-middle" style="margin-bottom: 5px;">
					<div class="shrink cell">
						<div class="user-thumb" style="margin-right: 5px;"
						v-bind:style="{ 'background-image': 'url(' + data.avatar + '?v=' + timestamp + ')' }" >
						</div>
					</div>
					<div class="auto cell">
						<p class="ck-overflow-ellipsis" style="margin: 0;">{{ data.firstname }} {{ data.lastname }}</p>
					</div>
				</div><!-- grid-x -->
			</template>
		</template>
		
	</div>
</template>

<script>


let timestamp;


export default {

	name: 'appointment-tooltip-template',
	
	props: {
	templateModel: Object,
	},

	mounted() {

	//timestamp
	this.timestamp = this.printTimestamp();

	},


  data() {
    return {
      timestamp,
    };
  },

  methods: {
	
    printTimestamp: function () {
      return Date.now();
    },


  },


}

</script>
