<template>

  <div v-if="currentUser.Role == 'Customer'" class="-content-block -hide">
  
    <h2 class="app-title hide">
      <!-- <i class="nav-icon fa-light fa-dumbbell"></i>  -->
      {{ title }}
    </h2>
  
    <div class="tabs-content tabs-content-customer" :data-tabs-content="'tabsDashboard'+currentUser.id">
  
      <div class="tabs-panel" :class="tab1" :id="'panelDashboard_1'+currentUser.id">
        <!-- <p>subtitle panel1</p> -->
        <h2 class="app-title">
          <i class="fa-light fa-cart-shopping"></i>
          Shop
        </h2>
        
        <div class="dx-card hide">
          <div class="user-base-wrap has-padding">
            <!-- <p>Shop Dashboard</p> -->
          </div><!-- /user-base-wrap --> 
        </div><!-- /dx-card --> 

      </div><!-- /tabs-panel -->     

      <div class="tabs-panel" :class="tab2" :id="'panelDashboard_2'+currentUser.id">
        <!-- <p>subtitle panel2</p> -->
        <h2 class="app-title">
          <i class="fa-light fa-ticket"></i>
          Abo's
        </h2>
        <div class="dx-card">
          <div class="user-base-wrap has-padding">
            <!-- <p>Abo's</p> -->
          </div><!-- /user-base-wrap --> 
        </div><!-- /dx-card --> 
      </div><!-- /tabs-panel --> 

      <div class="tabs-panel" :class="tab3" :id="'panelDashboard_3'+currentUser.id">
        <!-- <p>subtitle panel3</p> -->
        <h2 class="app-title">
          <i class="fa-light fa-tag"></i>
          Produkte
        </h2>
        <div class="dx-card">
          <div class="user-base-wrap has-padding">
            <!-- <p>Produkte</p> -->
          </div><!-- /user-base-wrap --> 
        </div><!-- /dx-card --> 
      </div><!-- /tabs-panel --> 

      <div class="tabs-panel" :class="tab4" :id="'panelDashboard_4'+currentUser.id">
        <!-- <p>subtitle panel4</p> -->
        <h2 class="app-title">
          <i class="fa-light fa-badge-percent"></i>
          Aktionen
        </h2>
        <div class="dx-card">
          <div class="user-base-wrap has-padding">
            <!-- <p>Aktionen</p> -->
          </div><!-- /user-base-wrap --> 
        </div><!-- /dx-card --> 
      </div><!-- /tabs-panel --> 

      <div class="tabs-panel" :class="tab5" :id="'panelDashboard_5'+currentUser.id">
        <!-- <p>subtitle panel4</p> -->
        <h2 class="app-title">
          <i class="fa-light fa-cart-shopping-fast"></i>
          Bestellungen
        </h2>
        <div class="dx-card">
          <div class="user-base-wrap has-padding">
            <!-- <p>Bestellungen</p> -->
          </div><!-- /user-base-wrap --> 
        </div><!-- /dx-card --> 
      </div><!-- /tabs-panel --> 

    </div><!-- /tabs-content --> 
  
    <ul :id="'tabsDashboard'+currentUser.id" class="tabs grid-x grid-margin-x small-up-2" data-tabs style="border: none; background: none; margin: 0 -15px;">
      <li class="cell tabs-title hide" :class="tab1">
        <a :href="'#panelDashboard_1'+currentUser.id" aria-selected="true" style="padding: 0;">
          <!-- panelDashboard_1 start -->
          <div class="dx-card dashboard-item">
            <!-- alert-icon -->
            <AppAlertDashboard
              :count="countAlertShop"
            />
            <!-- /alert-icon -->
            <div class="text-center">
              <i class="dashboard-icon fa-light fa-cart-shopping"></i>
            </div>
            <div class="text-center">
              <p class="dashboard-text">Shop</p>
            </div>
          </div>
        </a>
      </li>
      <li class="cell tabs-title" :class="tab2">
        <a :href="'#panelDashboard_2'+currentUser.id" aria-selected="true" style="padding: 0;">
          <!-- panelDashboard_2 adress -->
          <div class="dx-card dashboard-item">
            <!-- alert-icon -->
            <AppAlertDashboard
              :count="countAlertShopAbo"
            />
            <!-- /alert-icon -->
            <div class="text-center">
              <i class="dashboard-icon fa-light fa-ticket"></i>
            </div>
            <div class="text-center">
              <p class="dashboard-text">Abo's</p>
            </div>
          </div>
        </a>
      </li>
      <li class="cell tabs-title" :class="tab3">
        <a :href="'#panelDashboard_3'+currentUser.id" aria-selected="true" style="padding: 0;">
          <!-- panelDashboard_3 foto -->
          <div class="dx-card dashboard-item">
            <!-- alert-icon -->
            <AppAlertDashboard
              :count="countAlertShopProduct"
            />
            <!-- /alert-icon -->
            <div class="text-center">
              <i class="dashboard-icon fa-light fa-tag"></i>
            </div>
            <div class="text-center">
              <p class="dashboard-text">Produkte</p>
            </div>
          </div>
        </a>
      </li>
      <li class="cell tabs-title" :class="tab4">
        <a :href="'#panelDashboard_4'+currentUser.id" aria-selected="true" style="padding: 0;">
          <!-- panelDashboard_4 gesundheit -->
          <div class="dx-card dashboard-item">
            <!-- alert-icon -->
            <AppAlertDashboard
              :count="countAlertShopOffer"
            />
            <!-- /alert-icon -->
            <div class="text-center">
              <i class="dashboard-icon fa-light fa-badge-percent"></i>
            </div>
            <div class="text-center">
              <p class="dashboard-text">Aktionen</p>
            </div>
          </div>
        </a>
      </li>
      <li class="cell tabs-title" :class="tab5">
        <a :href="'#panelDashboard_5'+currentUser.id" aria-selected="true" style="padding: 0;">
          <!-- panelDashboard_5 messungen -->
          <div class="dx-card dashboard-item">
            <!-- alert-icon -->
            <AppAlertDashboard
              :count="countAlertShopOrder"
            />
            <!-- /alert-icon -->
            <div class="text-center">
              <i class="dashboard-icon fa-light fa-cart-shopping-fast"></i>
            </div>
            <div class="text-center">
              <p class="dashboard-text">Bestellungen</p>
            </div>
          </div>
        </a>
      </li>

    </ul>
  
  </div>
</template>

<script>

  import AppAlertDashboard from "../appdetail/app-alert-dashboard";
  
  import $ from 'jquery';
  import Foundation from 'foundation-sites';
  
  import auth from "../auth";
  import { 
    apihost, 
  } from "../api";

  let currentUser;
  
  let tab1 = 'is-active';
  let tab2 = '';
  let tab3 = '';
  let tab4 = '';
  let tab5 = '';

  let countAlertShop = 0;
  let countAlertShopAbo = 0;
  let countAlertShopProduct = 0;
  let countAlertShopOffer = 0;
  let countAlertShopOrder = 0;


  auth.getUser().then((e) => {
    if(e.data){
      //console.log(e.data);
      currentUser = e.data;

    } // e.data
  }); //auth
  
  export default {
    async mounted() {

      await this.fetchAlert();
  
      let url = window.location.href;
      let pos = url.indexOf("?");
      let tab = '';

      if(pos > 0){
        tab = url.substring(pos+1, 100);
      }

      if(tab == 'abo'){
        this.tab1 = '';
        this.tab2 = 'is-active';
      }

      if(tab == 'product'){
        this.tab1 = '';
        this.tab3 = 'is-active';
      }

      if(tab == 'offer'){
        this.tab1 = '';
        this.tab4 = 'is-active';
      }

      if(tab == 'order'){
        this.tab1 = '';
        this.tab5 = 'is-active';
      }

      this.tabsDashboard = new Foundation.Tabs($('#tabsDashboard'+this.currentUser.id), {
        matchHeight: false
      });
  
    },

    components: {

      AppAlertDashboard,

    },

    data() {
      return {
        apihost,
        title: 'Shop',
        currentUser,

        tab1,
        tab2,
        tab3,
        tab4,
        tab5,

        countAlertShop,
        countAlertShopAbo,
        countAlertShopProduct,
        countAlertShopOffer,
        countAlertShopOrder,

      };
    },
    methods: {

      async fetchAlert() {

        fetch(apihost+'/'+currentUser.Language+'/de/vue/member/memberalert/'+currentUser.id)
        .then(response => response.text())
        .then(result => {
          const data = JSON.parse(result);
          console.log(data);

          this.countAlertShop = data.countAlertShop;
          this.countAlertShopAbo = data.countAlertShopAbo;
          this.countAlertShopProduct = data.countAlertShopProduct;
          this.countAlertShopOffer = data.countAlertShopOffer;
          this.countAlertShopOrder = data.countAlertShopOrder;

        })
        .catch(() => { throw 'Network error' });

      },
  

    },
    unmounted() {
  
    },
  };
  </script>
  
  <style lang="scss">
  </style>
  