export default [
  {
    text: "Dashboard",
    path: "/home",
    icon: "nav-icon fa-light fa-grid-horizontal"
  },
  {
    text: 'Mitglieder',
    path: '/members-page',
    icon: 'nav-icon fa-light fa-users'
  },
  {
    text: 'Verträge',
    path: '/contracts-page',
    icon: 'nav-icon fa-light fa-file-contract'
  },
  {
    text: 'Kurse',
    path: '/checkin-page',
    icon: 'nav-icon fa-light fa-dumbbell'
  },
  {
    text: 'Termine',
    path: '/calendar-page',
    icon: 'nav-icon fa-light fa-calendar'
  },
  {
    text: 'Nachrichten',
    path: '/message-page',
    icon: 'nav-icon fa-light fa-messages'
  },
  {
    text: 'Videos/Übungen',
    path: '/video-page',
    icon: 'nav-icon fa-light fa-circle-play'
  },
  {
    text: 'Trainingspläne',
    path: '/training-page',
    icon: 'nav-icon fa-light fa-file-user'
  },
  {
    text: 'To-Do',
    path: '/task-page',
    icon: 'nav-icon fa-light fa-list-check'
  },
  {
    text: 'Statistiken',
    path: '/analytic-page',
    icon: 'nav-icon fa-light fa-chart-line'
  },
  {
    text: 'Shop',
    path: '/shop-page',
    icon: 'nav-icon fa-light fa-tag'
  },
  {
    text: 'Einstellungen',
    path: '/setting-page',
    icon: 'nav-icon fa-light fa-gear'
  },
/*
  {
    text: "Examples",
    icon: "nav-icon fa-light fa-folder-open",
    items: [
      {
        text: "Profile",
        path: "/profile",
								icon: 'nav-icon fa-regular fa-grip-dots'
      },
      {
        text: "Tasks",
        path: "/tasks",
								icon: 'nav-icon fa-regular fa-grip-dots'
      },

    ]
  },
*/
  {
    text: "Beispiele",
    icon: "nav-icon fa-light fa-puzzle-piece",
    items: [
      {
        text: "Task Store",
        path: "/dx-taskstore",
								icon: 'nav-icon fa-regular fa-grip-dots'
      },
      {
        text: "Mitglieder Store",
        path: "/dx-memberstore",
								icon: 'nav-icon fa-regular fa-grip-dots'
      },
      {
        text: "Mitglieder Popup",
        path: "/dx-memberpopup",
								icon: 'nav-icon fa-regular fa-grip-dots'
      },
      {
        text: "Mitglieder Accordion",
        path: "/dx-memberaccordion",
								icon: 'nav-icon fa-regular fa-grip-dots'
      },
      {
        text: "Mitglieder Liste",
        path: "/dx-memberlist",
								icon: 'nav-icon fa-regular fa-grip-dots'
      },
    ]
  }, 
  
  {
    text: "DevExtreme",
    icon: "nav-icon fa-light fa-puzzle-piece",
    items: [
      {
        text: "Popup Demo",
        path: "/dx-popupdemo",
								icon: 'nav-icon fa-regular fa-grip-dots'
      },
      {
        text: "Accordion Demo",
        path: "/dx-accordiondemo",
								icon: 'nav-icon fa-regular fa-grip-dots'
      },
      {
        text: "Test Store",
        path: "/dx-teststore",
								icon: 'nav-icon fa-regular fa-grip-dots'
      },
      {
        text: "Test Form",
        path: "/dx-testform",
								icon: 'nav-icon fa-regular fa-grip-dots'
      },
       {
        text: "Json Read-Only",
        path: "/dx-jsonreadonly",
								icon: 'nav-icon fa-regular fa-grip-dots'
      },
       {
        text: "Json Store",
        path: "/dx-jsonstore",
								icon: 'nav-icon fa-regular fa-grip-dots'
      },
      {
        text: "Custom Store",
        path: "/dx-customstore",
								icon: 'nav-icon fa-regular fa-grip-dots'
      },


    ]
  },
  
/*
  {
    text: "Foundation",
    icon: "nav-icon fa-light fa-puzzle-piece",
    items: [
      {
        text: "Grid",
        path: "/grid",
								icon: 'nav-icon fa-regular fa-grip-dots'
      },
      {
        text: "Typography",
        path: "/typography",
								icon: 'nav-icon fa-regular fa-grip-dots'
      },
      {
        text: "Buttons",
        path: "/button",
								icon: 'nav-icon fa-regular fa-grip-dots'
      },
      {
       text: "Accordion",
       path: "/accordion",
							icon: 'nav-icon fa-regular fa-grip-dots'
     },
      {
       text: "Tabs",
       path: "/tabs",
							icon: 'nav-icon fa-regular fa-grip-dots'
     },
      {
       text: "Reveal",
       path: "/reveal",
							icon: 'nav-icon fa-regular fa-grip-dots'
     },
     {
      text: "Tooltip",
      path: "/tooltip",
						icon: 'nav-icon fa-regular fa-grip-dots'
    },
     {
      text: "Dropdown Menu",
      path: "/dropdownmenu",
						icon: 'nav-icon fa-regular fa-grip-dots'
    },
     {
      text: "Drilldown Menu",
      path: "/drilldownmenu",
						icon: 'nav-icon fa-regular fa-grip-dots'
    },
     {
      text: "Accordion Menu",
      path: "/accordionmenu",
						icon: 'nav-icon fa-regular fa-grip-dots'
    },
   ]
  },   
*/

/*
  {
    text: 'About',
    path: '/about',
    icon: 'nav-icon fa-regular fa-grip-dots'
  },	
*/

]; //export default
