<template>
  <dx-scroll-view height="100%" width="100%" class="with-footer single-card">

    <div class="grid-x -align-middle" style="min-height: 100vh;">
      <div class="cell">

      <div class="customer-app" style="padding: 0;">

        <!-- HEADER -->
        <div class="customer-app-header">

          <div class="customer-app-content">
            <div class="grid-x align-middle" style="height: 50px;">
              <div class="shrink cell">
                <div class="app-logo logo-msa">

                  <div v-if="stakeholder == 'msa'" class="app-logo logo-msa">
                  <LogoMsa />
                  </div>
                  <div v-if="stakeholder == 'tgym'" class="app-logo logo-tgym">
                    <LogoTgym />
                  </div>
                  <div v-if="stakeholder == 'dynamic'" class="app-logo logo-dynamic">
                    <LogoDynamic />
                  </div>
                  <div v-if="stakeholder == 'lasuite'" class="app-logo logo-lasuite">
                    <LogoLasuite />
                  </div>
                  <div v-if="stakeholder == 'x30'" class="app-logo logo-x30">
                    <LogoX30 />
                  </div>
                  <div v-if="stakeholder == 'profitfitness'" class="app-logo logo-profitfitness">
                    <LogoProfitfitness />
                  </div>
                  <div v-if="stakeholder == 'physioplus'" class="app-logo logo-physioplus">
                    <LogoPhysioplus />
                  </div>
                  <div v-if="stakeholder == 'goldsgym'" class="app-logo logo-goldsgym">
                    <LogoGoldsgym />
                  </div>
                  <div v-if="stakeholder == 'fitx'" class="app-logo logo-fitx">
                    <LogoFitx />
                  </div>
                  <div v-if="stakeholder == 'kuoni'" class="app-logo logo-kuoni">
                    <LogoKuoni />
                  </div>
                  <div v-if="stakeholder == 'chilihealth'" class="app-logo logo-chilihealth">
                    <LogoChilihealth />
                  </div>
                  <div v-if="stakeholder == 'fitnessunited'" class="app-logo logo-fitnessunited">
                    <LogoFitnessunited />
                  </div>
                  <div v-if="stakeholder == 'neogate'" class="app-logo logo-neogate">
                    <LogoNeogate />
                  </div>
                  <div v-if="stakeholder == 'christoppark'" class="app-logo logo-christoppark">
                    <LogoChristoppark />
                  </div>
                  <div v-if="stakeholder == '4trainingfitness'" class="app-logo logo-4trainingfitness">
                    <Logo4trainingfitness />
                  </div>            
                  <div v-if="stakeholder == 'besttraining'" class="app-logo logo-besttraining">
                    <LogoBesttraining />
                  </div>
                  <div v-if="stakeholder == 'quantumgym'" class="app-logo logo-quantumgym">
                    <LogoQuantumgym />
                  </div>
                  <div v-if="stakeholder == 'gym365'" class="app-logo logo-gym365">
                    <LogoGym365 />
                  </div>
                  <div v-if="stakeholder == 'trainiq'" class="app-logo logo-trainiq">
                    <LogoTrainiq />
                  </div>
                  <div v-if="stakeholder == 'lfit'" class="app-logo logo-lfit">
                    <LogoLfit />
                  </div>

                </div>
              </div>
            </div>
          </div>




          <!-- header -->
        </div>
        <!-- /HEADER -->

        <!-- CONTENT -->
        <div class="customer-app-content">
          <div class="-app-content-slot">
            <slot />
          </div>
        </div><!-- customer-app-content -->
        <!-- /CONTENT -->

        <!-- FOOTER -->
        <div class="customer-app-footer">
          <div class="customer-app-content">
            <!-- footer -->
            <ul class="customer-app-footer-nav">
              <li>
                <a href="#shop">
                  <i class="footernav-icon fa-light fa-right-to-bracket"></i>
                  <p>Home</p>
                </a>
              </li>
              <li>
                <a href="#shopproduct">
                  <i class="footernav-icon fa-light fa-right-to-bracket"></i>
                  <p>Produkte</p>
                </a>
              </li>
              <li>
                <a href="#shopcart">
                  <i class="footernav-icon fa-light fa-right-to-bracket"></i>
                  <p>Warenkorb</p>
                </a>
              </li>
              <li>
                <a href="#shopcheckout">
                  <i class="footernav-icon fa-light fa-right-to-bracket"></i>
                  <p>Checkout</p>
                </a>
              </li>
              <li>
                <a href="#shoppurchase">
                  <i class="footernav-icon fa-light fa-right-to-bracket"></i>
                  <p>Einkäufe</p>
                </a>
              </li>
            </ul>

          </div><!-- customer-app-content -->
        </div><!-- customer-app-footer -->
        <!-- /FOOTER -->

      </div><!-- customer-app -->

      </div>
    </div>

  </dx-scroll-view>
</template>

<script>
import DxScrollView from "devextreme-vue/scroll-view";

import { useRoute } from 'vue-router';
import { watch, ref } from 'vue';

import LogoMsa from '../svg/logo-msa-login.vue';
import LogoTgym from '../svg/logo-tgym.vue';
import LogoDynamic from '../svg/logo-dynamic.vue';
import LogoLasuite from '../svg/logo-lasuite.vue';
import LogoX30 from '../svg/logo-x30.vue';
import LogoProfitfitness from '../svg/logo-profitfitness.vue';
import LogoPhysioplus from '../svg/logo-physioplus.vue';
import LogoGoldsgym from '../svg/logo-goldsgym.vue';
import LogoFitx from '../svg/logo-fitx.vue';
import LogoKuoni from '../svg/logo-kuoni.vue';
import LogoChilihealth from '../svg/logo-chilihealth.vue';
import LogoFitnessunited from '../svg/logo-fitnessunited.vue';
import LogoNeogate from '../svg/logo-neogate.vue';
import LogoChristoppark from '../svg/logo-christoppark.vue';
import Logo4trainingfitness from '../svg/logo-4trainingfitness.vue';
import LogoBesttraining from '../svg/logo-besttraining.vue';
import LogoQuantumgym from '../svg/logo-quantumgym.vue';
import LogoGym365 from '../svg/logo-gym365.vue';
import LogoTrainiq from '../svg/logo-trainiq.vue';
import LogoLfit from '../svg/logo-lfit.vue';

import { 
  apihost, 
} from "../api";


export default {
  components: {
    DxScrollView,

    LogoMsa,
    LogoTgym,
    LogoDynamic,
    LogoLasuite,
    LogoX30,
    LogoProfitfitness,
    LogoPhysioplus,
    LogoGoldsgym,
    LogoFitx,
    LogoKuoni,
    LogoChilihealth,
    LogoFitnessunited,
    LogoNeogate,
    LogoChristoppark,
    Logo4trainingfitness,
    LogoBesttraining,
    LogoQuantumgym,
    LogoGym365,
    LogoTrainiq,
    LogoLfit,

  },
  setup() {

    //stakeholder
    const pos = apihost.indexOf("api")+3;
    const result = apihost.substring(pos, 100);
    const posend = result.indexOf(".");
    const stakeholder = result.substring(0, posend);

    const route = useRoute();

    const title = ref(route.meta.title);
    const description = ref("");

    watch(() => route.path,
     () => {
        title.value = route.meta.title;
        description.value = route.meta.description;
     }
    )
    return {
      apihost,
      stakeholder,
      title,
      description
    }
  }
};
</script>

<style lang="scss">

</style>
