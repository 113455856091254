<template>

  <div class="hide" style="font-size: 10px;">
    currentUser: {{ currentUser }}
  </div>
    
</template>

<script>
import auth from "../auth";
import { locale, loadMessages } from "devextreme/localization";


let currentUser;

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;
    //Set Local for Dexextreme Components
    //locale(currentUser.Language);
    //console.log(currentUser);
  } // e.data
}); //auth


export default {
  mounted() {

    if (typeof currentUser == 'object') {
      
      //redirect to Studio App
      if(currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'){
        const currentUrl = window.location.href;
        const redirecturl = currentUrl.replace("/#/home", "/#/sap-dashboard");
        //console.log(redirecturl);
        window.location.href = redirecturl;
      }

      //redirect to Member App
      if(currentUser.Role == 'Customer'){
        const currentUrl = window.location.href;
        const redirecturl = currentUrl.replace("/#/home", "/#/dashboard");
        //const redirecturl = currentUrl.replace("/#/home", "/#/myprofile");
        //console.log(redirecturl);
        window.location.href = redirecturl;
      }

      //Set Local for Dexextreme Components
      locale(currentUser.Language);
      
    } else {
      //reload this page => waiting for localStorage

    }

  },
  data() {
    return {
      title: 'Home',
      currentUser,

      locale,
      loadMessages,

    };
  },
  methods: {

  },

  components: {

  },

  unmounted() {

  },

};
</script>
