<template>

  <div class="hide" style="font-size: 10px;">

    <p>currentUser.Location: {{ currentUser.Location }}</p>
    <p>GetLocations: {{ this.templateData.data.GetLocations }}</p>
    <p>OptionLocation: {{ this.templateData.data.OptionLocation }}</p>
    <p>OptionLocationstring: {{ this.templateData.data.OptionLocationstring }}</p>
  
    <p>Picture: {{ this.Picture }}</p>
    <p>Picthumb: {{ this.Picthumb }}</p>
    <p>Video: {{ this.Video }}</p>

    <p>Repetition: {{this.repetition}}</p>
    <p>repetitioncount: {{this.repetitioncount}}</p>

    <p>Option1: {{this.Option1}}</p>
    <p>option1list: {{this.option1list}}</p>
    <p>option1array: {{this.option1array}}</p>
    <p>option1count: {{this.option1count}}</p>

    <p>Option2: {{this.Option2}}</p>
    <p>option2list: {{this.option2list}}</p>
    <p>option2array: {{this.option2array}}</p>
    <p>option2count: {{this.option2count}}</p>

  </div>

  <div class="hide" style="font-size: 10px;">
    <p>id: {{ this.templateData.data.id }}</p>

    <p>Picture: {{ this.templateData.data.Picture }}</p>
    <p>Picthumb: {{ this.templateData.data.Picthumb }}</p>
    <p>Video: {{ this.templateData.data.Video }}</p>
    <p>Data: {{this.templateData.data}}</p>

    <p>Repetition: {{this.templateData.data.Repetition}}</p>
    <p>Settingtype: {{this.templateData.data.Settingtype}}</p>

    <p>Option1: {{this.templateData.data.Option1}}</p>
    <p>Option1list: {{this.templateData.data.Option1list}}</p>
    <p>Option1string: {{this.templateData.data.Option1string}}</p>
    <p>option1array: {{this.templateData.data.Option1array}}</p>

    <p>Option2: {{this.templateData.data.Option2}}</p>
    <p>Option2list: {{this.templateData.data.Option2list}}</p>
    <p>Option2string: {{this.templateData.data.Option2string}}</p>
    <p>Option2array: {{this.templateData.data.Option2array}}</p>


  </div>

  <form 
    style="padding: 0;"
    :id="this.templateData.data.id"
    @submit="handleSubmit"
  >

  <div class="-hide">

    <DxForm 
      :id="this.templateData.data.id"
      :form-data="this.templateData.data"
      :read-only="false"
      @initialized="saveFormInstance"
      :show-colon-after-label="true"
      :show-validation-summary="true"
      validation-group="currentUserData"
    >
    <DxSimpleItem 
      data-field="id"
      help-text=""
      css-class="hide"
    >
    </DxSimpleItem>

    <DxSimpleItem 
      data-field="mastercontent"
      help-text=""
      template="mastercontent"
    >
    <DxLabel 
      :text="'mastercontent'"
      :visible="false"
      />
    </DxSimpleItem>

      <template #mastercontent>

        <div :id="'musclegroupContainer'+this.templateData.data.id" class="master-content" style="position: relative;">
        
        <!-- SUBMIT BUTTON  -->
        <div class="grid-x grid-margin-x align-middle title-area-content">
          <div class="auto cell">
            <h5>Übung bearbeiten</h5>
          </div>
          <div class="shrink cell">
            <button :id="'submitform'+this.templateData.data.id" class="cx-button">{{currentUser.Translation.vueappExerciseFormSubmit}}</button>
          </div>
        </div>
        <!-- /SUBMIT BUTTON  -->

          <div class="grid-x grid-margin-x medium-up-2 xlarge-up-3 xxlarge-up-4 master-grid">

            <div class="cell cell-margin-y large-3">

              <div class="dx-card no-paddings">

                <label class="ck-label hide">Betreff</label>

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>{{ currentUser.Translation.vueappTaskSubject }}</p>
                  </div>
                </div>


                <ul :id="'tabsLang'+this.templateData.data.id" class="tabs hide" data-tabs>
                  <li class="tabs-title" :class="currentUser.Language == 'de'  ? 'is-active' : ''"><a :href="'#panelSubject_de'+this.templateData.data.id" aria-selected="true"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/de.svg'"><span class="hide">de</span></a></li>
                  <li class="tabs-title" :class="currentUser.Language == 'en'  ? 'is-active' : ''"><a :href="'#panelSubject_en'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/gb.svg'"><span class="hide">en</span></a></li>
                  <li class="tabs-title" :class="currentUser.Language == 'fr'  ? 'is-active' : ''"><a :href="'#panelSubject_fr'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/fr.svg'"><span class="hide">fr</span></a></li>
                  <li class="tabs-title" :class="currentUser.Language == 'es'  ? 'is-active' : ''"><a :href="'#panelSubject_es'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/es.svg'"><span class="hide">es</span></a></li>
                  <li class="tabs-title" :class="currentUser.Language == 'it'  ? 'is-active' : ''"><a :href="'#panelSubject_it'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/it.svg'"><span class="hide">it</span></a></li>
                </ul>

                <div class="tabs-content" :data-tabs-content="'tabsLang'+this.templateData.data.id">

                  <div class="tabs-panel" :class="currentUser.Language == 'de'  ? 'is-active' : ''" :id="'panelSubject_de'+this.templateData.data.id">
                    <DxTextBox
                      :id="'Subject_de'+this.templateData.data.id"
                      :value="this.templateData.data.Subject_de"
                      :input-attr="{ 'aria-label': 'Subject_de' }"
                    />
                  </div>
                  <div class="tabs-panel" :class="currentUser.Language == 'en'  ? 'is-active' : ''" :id="'panelSubject_en'+this.templateData.data.id">
                    <DxTextBox
                      :id="'Subject_en'+this.templateData.data.id"
                      :value="this.templateData.data.Subject_en"
                      :input-attr="{ 'aria-label': 'Subject_en' }"
                    />
                  </div>
                  <div class="tabs-panel" :class="currentUser.Language == 'fr'  ? 'is-active' : ''" :id="'panelSubject_fr'+this.templateData.data.id">
                    <DxTextBox
                      :id="'Subject_fr'+this.templateData.data.id"
                      :value="this.templateData.data.Subject_fr"
                      :input-attr="{ 'aria-label': 'Subject_fr' }"
                    />
                  </div>
                  <div class="tabs-panel" :class="currentUser.Language == 'es'  ? 'is-active' : ''" :id="'panelSubject_es'+this.templateData.data.id">
                    <DxTextBox
                      :id="'Subject_es'+this.templateData.data.id"
                      :value="this.templateData.data.Subject_es"
                      :input-attr="{ 'aria-label': 'Subject_es' }"
                    />
                  </div>
                  <div class="tabs-panel" :class="currentUser.Language == 'it'  ? 'is-active' : ''" :id="'panelSubject_it'+this.templateData.data.id">
                    <DxTextBox
                      :id="'Subject_it'+this.templateData.data.id"
                      :value="this.templateData.data.Subject_it"
                      :input-attr="{ 'aria-label': 'Subject_it' }"
                    />
                  </div>

                </div><!-- /tabs-content-->


                <!-- settingtype hide-->
                <div class="hide">

                  <label class="ck-label">Einstellung</label>

                  <div class="ck-input-container" style="background-color: #f3f3f3; padding: 0 10px;">

                    <div class="radio-container" v-for="settingentity in settingEntities" :key="settingentity.id" style="margin: 15px 15px 15px 0;">
                      <div class="radio-item">
                        <input type="radio" 
                        :name="'settingtype[]'+this.templateData.data.id" 
                        :value="settingentity.value" 
                        :id="settingentity.id+'_'+this.templateData.data.id" 
                        :checked="this.templateData.data.Settingtype.includes(settingentity.value)"
                        required
                        >
                        <label :for="settingentity.id+'_'+this.templateData.data.id">{{ settingentity.text }}</label>
                        <span class="checkmark"></span>
                    </div>
                  </div>

                  </div>

                </div>
                <!-- /settingtype hide-->

                <!-- description-->
                <label class="ck-label hide">Beschreibung</label>

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>{{ currentUser.Translation.vueappTaskDescription }}</p>
                  </div>
                </div>
                <DxTextBox
                  :id="'Description'+this.templateData.data.id"
                  :value="this.templateData.data.Description"
                  :input-attr="{ 'aria-label': 'Description' }"
                />
                <!-- /description-->

                <!-- location-->
                <label class="ck-label hide">Standorte</label>

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>Standorte</p>
                  </div>
                </div>


                <div class="padding-wrap">

                  <div v-for="location in currentUser.Location" :key="location.id">

                    <div class="checkbox-container">
                      <input 
                        :name="'checkboxeslocation'+this.templateData.data.id"
                        :id="location.locationid+this.templateData.data.id"
                        type="checkbox" 
                        :value="location.locationid"
                        :checked="this.templateData.data.OptionLocation.includes(location.locationid.toString())"
                        v-model="location.checked"
                      >
                      <!-- <input 
                        :name="'checkboxesLocation'+this.templateData.data.id"
                        :id="location.locationid+this.templateData.data.id"
                        type="checkbox" 
                        :value="location.locationid"
                        :checked="this.templateData.data.Option1.includes(location.locationid)"
                        v-model="location.checked"
                        @change="checkboxes1($event)"
                      > -->
                      <label :for="location.locationid+this.templateData.data.id">{{location.locationname}}</label>
                      <span class="checkmark"></span>
                    </div>

                  </div><!-- /v-for-->

                </div><!-- /padding-wrap-->

              <!-- /description-->

              </div><!-- dx-card-->

              </div>
              <!-- /cell Betreff-->

              <!-- cell Video-->
              <div class="cell cell-margin-y">

                <!-- <div class="ck-overflow-container"> -->
                <div class="dx-card no-paddings">

                <label class="ck-label hide">Video</label>

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>Video</p>
                  </div>
                </div>

                <ul :id="'tabsVideo'+this.templateData.data.id" class="tabs hide" data-tabs>
                  <li class="tabs-title -is-active"><a :href="'#panelVideo_1'+this.templateData.data.id" aria-selected="true"><i class="tabnav-icon no-margin fa-light fa-circle-play"></i><span class="hide">Video</span></a></li>
                  <li class="tabs-title is-active"><a :href="'#panelVideo_2'+this.templateData.data.id"><i class="tabnav-icon no-margin fa-light fa-cloud-arrow-up"></i><span class="hide">Upload</span></a></li>
                </ul>

                <div class="tabs-content" :data-tabs-content="'tabsVideo'+this.templateData.data.id">
                  <div class="tabs-panel -is-active" :id="'panelVideo_1'+this.templateData.data.id">

                    <div v-if="this.Video" class="responsive-embed widescreen" style="padding-bottom: 56.25%; margin: 0; background-color: #efefef;">
                      <video width="1920" height="1080" :poster="this.Picthumb" controls muted preload="none">
                        <source :src="this.Video" type="video/mp4">
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <div v-else>
                      <div class="responsive-embed widescreen" style="padding-bottom: 56.25%; margin: 0; background-color: #efefef;">
                        <img style="width: 100%;" :src="this.Picthumb">
                      </div>
                    </div>

                  </div>
                  <!-- /tabs-panel -->
                  
                  <div class="tabs-panel is-active" :id="'panelVideo_2'+this.templateData.data.id">

                    <div class="padding-wrap">

                      <!-- upload Video -->
                      <div v-if="this.Video" class="responsive-embed widescreen" style="padding-bottom: 56.25%; margin: 0; background-color: #efefef;">
                        <video width="1920" height="1080" :poster="this.Picthumb" controls muted preload="none">
                          <source :src="this.Video" type="video/mp4">
                          Your browser does not support the video tag.
                        </video>
                      </div>
                      <div v-else style="font-size: 12px;">
                        <span style="color: #ec5f67;">
                          <i class="fa-light fa-circle-exclamation"></i>
                          Video mp4, mov (40 MB)
                        </span>
                      </div>

                      <DxFileUploader
                        id="uploadvideo"
                        name="upfile"
                        class="uploadbutton"
                        :upload-url="apihost+'/uploadexercise.php?id=' + this.templateData.data.id"
                        :accept="accept"
                        :multiple="false"
                        :upload-mode="uploadMode"
                        :allowed-file-extensions="['.mp4', '.mov']"
                        :min-file-size="1024"
                        :max-file-size="40960*1024"
                        :on-files-uploaded="onFilesUploaded"
                        label-text=""
                        :select-button-text="currentUser.Translation.vueappExerciseUploadVideoButton"
                        @value-changed="e => files = e.value"
                      />

                      
                      <!-- upload Video -->
                      <div class="hide">

                        <div style="height: 16px;">
                        </div>

                        <!-- upload Picture -->
                        <div v-if="this.Picthumb">
                          <div class="responsive-embed widescreen" style="padding-bottom: 56.25%; margin: 0; background-color: #efefef;">
                            <img style="width: 100%;" :src="this.Picthumb">
                          </div>
                        </div>
                        <div v-else style="font-size: 12px;">
                          <span style="color: #ec5f67;">
                            <i class="fa-light fa-circle-exclamation"></i>
                            Bild jpg, png (5 MB)
                          </span>
                        </div>

                        <DxFileUploader
                          id="uploadpicture"
                          name="upfile"
                          class="uploadbutton"
                          :upload-url="apihost+'/uploadexercise.php?id=' + this.templateData.data.id"
                          :accept="accept"
                          :multiple="false"
                          :upload-mode="uploadMode"
                          :allowed-file-extensions="['.jpg', '.jpeg', '.png']"
                          :min-file-size="1024"
                          :max-file-size="10240*1024"
                          :on-files-uploaded="onFilesUploaded"
                          label-text=""
                          :select-button-text="currentUser.Translation.vueappExerciseUploadPicButton"
                          @value-changed="e => files = e.value"
                        />

                        <!-- /upload Picture -->
                      </div>
                      <!-- /hide upload Picture -->

                    </div><!-- /padding-wrap -->

                  </div><!-- /tabs-panel -->

                </div><!-- /tabs-content -->

              </div><!-- dx-card -->

            </div>
            <!-- /cell Video -->

            
            <!-- cell Muskelgruppen-->
            <div class="cell cell-margin-y">

              <div class="dx-card no-paddings">

              <label class="ck-label hide">Muskelgruppen</label>

              <div class="dx-card-label grid-x align-middle">
                <div class="auto cell">
                  <p>{{ currentUser.Translation.vueappNavMemberExerciseoption1 }}</p>
                </div>
              </div>

              <ul :id="'tabsMusclegroup'+this.templateData.data.id" class="tabs hide" data-tabs>
                <li class="tabs-title -is-active"><a :href="'#panelMusclegroup_1'+this.templateData.data.id" aria-selected="true"><i class="tabnav-icon no-margin fa-solid fa-circle-check"></i><span class="hide">checked</span></a></li>
                <li class="tabs-title"><a :href="'#panelMusclegroup_2'+this.templateData.data.id"><i class="tabnav-icon no-margin fa-light fa-square-check"></i><span class="hide">check</span></a></li>
                <li class="tabs-title is-active"><a :href="'#panelMusclegroup_3'+this.templateData.data.id"><i style="font-size: 20px; line-height: 14px;" class="tabnav-icon no-margin fa-light fa-light fa-person"></i><span class="hide">man</span></a></li>
                <li class="tabs-title"><a :href="'#panelMusclegroup_4'+this.templateData.data.id"><i style="font-size: 20px; line-height: 14px;" class="tabnav-icon no-margin fa-light fa-light fa-person-dress"></i><span class="hide">woman</span></a></li>
              </ul>

              <div class="tabs-content musclegroup-container" :data-tabs-content="'tabsMusclegroup'+this.templateData.data.id" style="position: relative;">

                <div class="grid-x align-middle -hide" style="position: absolute; top: 10px; left: 0; width: 100%; height: 30px; text-align: center; z-index: 1;">
                  <div class="cell">
                    <span :id="'musclegroupindicator'+this.templateData.data.id" class="musclegroup-indicator">indicator</span>
                  </div>
                </div>

                <div class="tabs-panel -is-active" :id="'panelMusclegroup_1'+this.templateData.data.id">
                  
                  <div class="padding-wrap">

                    <div v-if="option1count > 0">
                      <div v-for="item in option1array" :key="item.id">
                        <p><i class="tabnav-icon fa-solid fa-circle-check"></i><span style="font-size: 14px;">{{ item.Subject }}</span></p>
                      </div>
                    </div>
                    <div v-else>
                      <p>keine Muskelgruppen</p>
                    </div>

                  </div><!-- padding-wrap-->

                </div>
                <div class="tabs-panel" :id="'panelMusclegroup_2'+this.templateData.data.id">
                  <div id="checkboxContainer">

                    <div class="padding-wrap">

                      <div v-for="option1 in option1list" :key="option1.id">

                        <div class="checkbox-container">
                          <input 
                          :name="'checkboxes1'+this.templateData.data.id"
                          :id="option1.Itemkey+this.templateData.data.id"
                          type="checkbox" 
                          :value="option1.id"
                          :checked="this.templateData.data.Option1.includes(option1.id)"
                          v-model="option1.checked"
                          @change="checkboxes1($event)"

                          >
                          <label :for="option1.Itemkey+this.templateData.data.id">{{option1.Subject}}</label>
                          <span class="checkmark"></span>
                        </div>
                        
                      </div>

                    </div><!-- padding-wrap-->
                  </div><!-- checkboxContainer -->

                </div> 
                
                <div class="tabs-panel is-active" :id="'panelMusclegroup_3'+this.templateData.data.id">

                  <div class="padding-wrap">

                    <div class="grid-x">
                      <div class="auto cell">
                        <ManFront />
                      </div>
                      <div class="auto cell">
                        <ManBack />
                      </div>
                    </div>

                  </div><!-- padding-wrap-->
                </div><!-- tabs-panel-->

                <div class="tabs-panel" :id="'panelMusclegroup_4'+this.templateData.data.id">
                  <div class="padding-wrap">

                    <div class="grid-x">
                      <div class="auto cell">
                        <WomanFront />
                      </div>
                      <div class="auto cell">
                        <WomanBack />
                      </div>
                    </div>
                    
                  </div><!-- padding-wrap-->
                </div><!-- tabs-panel-->

              </div><!-- tabs-content-->

              </div><!-- dx-card-->

              </div>
              <!-- /cell Muskelgruppen-->

            <!-- cell Hilfsmittel-->
            <div class="cell cell-margin-y">
              <div class="dx-card no-paddings">
              
              <label class="ck-label hide">Hilfsmittel</label>

              <div class="dx-card-label grid-x align-middle">
                <div class="auto cell">
                  <p>{{ currentUser.Translation.vueappNavMemberExerciseoption2 }}</p>
                </div>
              </div>

              <ul :id="'tabsTool'+this.templateData.data.id" class="tabs hide" data-tabs>
                <li class="tabs-title -is-active"><a :href="'#panelTool_1'+this.templateData.data.id" aria-selected="true"><i class="tabnav-icon no-margin fa-solid fa-circle-check"></i><span class="hide">checked</span></a></li>
                <li class="tabs-title is-active"><a :href="'#panelTool_2'+this.templateData.data.id"><i class="tabnav-icon no-margin fa-light fa-square-check"></i><span class="hide">check</span></a></li>
              </ul>

              <div class="tabs-content" :data-tabs-content="'tabsTool'+this.templateData.data.id">

                <div class="tabs-panel -is-active" :id="'panelTool_1'+this.templateData.data.id">

                  <div class="padding-wrap">

                    <div v-if="option2count > 0">
                      <div v-for="item in option2array" :key="item.id">
                        <p><i class="tabnav-icon fa-solid fa-circle-check"></i><span style="font-size: 14px;">{{ item.Subject }}</span></p>
                      </div>
                    </div>
                    <div v-else>
                      <p>keine Hilfsmittel</p>
                    </div>

                  </div><!-- padding-wrap-->

                </div>
                <div class="tabs-panel is-active" :id="'panelTool_2'+this.templateData.data.id">

                  <div class="padding-wrap">

                    <div v-for="option2 in option2list" :key="option2.id">

                      <div class="checkbox-container">
                        <input 
                        :name="'checkboxes2'+this.templateData.data.id"
                        :id="option2.Itemkey+this.templateData.data.id"
                        type="checkbox" 
                        :value="option2.id"
                        :checked="this.templateData.data.Option2.includes(option2.id)"
                        v-model="option2.checked"
                        @change="checkboxes2($event)"

                        >
                        <label :for="option2.Itemkey+this.templateData.data.id">{{option2.Subject}}</label>
                        <span class="checkmark"></span>
                      </div>
                      
                    </div>

                  </div><!-- padding-wrap-->

                </div><!-- dx-card-->

                </div><!-- tabs-panel-->
              </div><!-- tabs-content-->


            </div>
            <!-- /cell Hilfsmittel-->

            <!-- cell Wiederholungen hide-->
            <div class="cell cell-margin-y hide">
              <label class="ck-label">Wdhl.</label>

              <ul :id="'tabsRepetition'+this.templateData.data.id" class="tabs" data-tabs>
                <li class="tabs-title is-active"><a :href="'#panelRepetition_1'+this.templateData.data.id" aria-selected="true"><i class="tabnav-icon no-margin fa-solid fa-circle-check"></i><span class="hide">checked</span></a></li>
                <li class="tabs-title"><a :href="'#panelRepetition_2'+this.templateData.data.id"><i class="tabnav-icon no-margin fa-light fa-ballot-check"></i><span class="hide">check</span></a></li>
              </ul>

              <div class="tabs-content" :data-tabs-content="'tabsRepetition'+this.templateData.data.id">

                <div class="tabs-panel is-active" :id="'panelRepetition_1'+this.templateData.data.id">


                  <div v-if="repetitioncount > 0">
                    <div class="ck-overflow-container">

                      <div class="grid-x align-middle" style="margin-bottom: 5px;">
                        <div class="shrink cell" style="width: 15px;">
                          <p><small>#</small></p>
                        </div>
                        <div class="auto cell">
                          <p>
                            <i class="fa-light fa-repeat"></i>&nbsp;
                            <small>Wdhl. (x)</small>
                          </p>
                        </div>
                        <div class="auto cell -hide">
                          <p>
                            <i class="fa-light fa-stopwatch"></i>&nbsp;
                            <small>Zeit (sek)</small>
                          </p>
                        </div>
                        <div class="auto cell">
                          <p>
                            <i class="fa-light fa-weight-hanging"></i>&nbsp;
                            <small>Gewicht (kg)</small>
                          </p>
                        </div>
                      </div>

                      <div class="" v-for="item in repetition" :key="item.id">
                        <div class="grid-x align-middle" v-if="item.rep || item.time">
                          <div class="shrink cell" style="width: 15px;">
                            <p>{{ item.id }}</p>
                          </div>
                          <div class="auto cell" style="margin-right: 1px;">
                            <p>{{ item.rep }}</p>
                          </div>
                          <div class="auto cell" style="margin-right: 1px;">
                            <p>{{ item.time }}</p>
                          </div>
                          <div class="auto cell" style="margin-right: 1px;">
                            <p>{{ item.weight }}</p>
                          </div>
                        </div>
                      </div>
                      </div><!-- ck-overflow-container -->

                  </div>
                  <div v-else>
                    <p>keine Wiederholungen</p>
                  </div>


                </div><!-- tabs-panel-->

                <div class="tabs-panel" :id="'panelRepetition_2'+this.templateData.data.id">

                  <div class="ck-overflow-container">

                    <div class="grid-x align-middle" style="margin-bottom: 5px;">
                      <div class="shrink cell" style="width: 15px;">
                        <p><small>#</small></p>
                      </div>
                      <div class="auto cell">
                        <p><small>Anzahl (x)</small></p>
                      </div>
                      <div class="auto cell -hide">
                        <p><small>Zeit (min)</small></p>
                      </div>
                      <div class="auto cell">
                        <p><small>Gewicht (kg)</small></p>
                      </div>
                    </div>

                    <div class="grid-x align-middle" v-for="item in repetition" :key="item.id">
                      <div class="shrink cell" style="width: 15px;">
                        <p><small>{{ item.id }}</small></p>
                      </div>
                      <div class="auto cell" style="margin-right: 1px;">
                        <input :name="'repetitionrep[]'+this.templateData.data.id" :value="item.rep" type="text" style="width: 100%; background: transparent; border: 1px solid #d3d3d3; margin: 1px;">
                      </div>
                      <div class="auto cell" style="margin-right: 1px;">
                        <input :name="'repetitiontime[]'+this.templateData.data.id" :value="item.time" type="text" style="width: 100%; background: transparent; ; border: 1px solid #d3d3d3; margin: 1px;">
                      </div>
                      <div class="auto cell" style="margin-right: 1px;">
                        <input :name="'repetitionweight[]'+this.templateData.data.id" :value="item.weight" type="text" style="width: 100%; background: transparent; ; border: 1px solid #d3d3d3; margin: 1px;">
                      </div>
                    </div>

                  </div><!-- ck-overflow-container -->

                </div><!-- tabs-panel-->

              </div><!-- tabs-content -->

            </div>
            <!-- /cell Wiederholungen-->

          </div><!-- grid-x grid-margin-x medium-up-2 large-up-3 xlarge-up-4 -->

        </div><!-- master-form -->

      </template><!-- mastercontent -->
    
      <DxButtonItem
        horizontal-alignment="left"
        :button-options="buttonOptions"
        css-class="hide"
      />

    </DxForm>

  </div><!-- hide -->

  </form>

</template>
<script>

import { DxFileUploader } from 'devextreme-vue/file-uploader';
import DxTextBox from 'devextreme-vue/text-box';

import DxForm, {
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  //DxGroupItem,
  //DxRequiredRule,
  //DxCompareRule,
  //DxRangeRule,
  //DxStringLengthRule,
  //DxPatternRule,
  //DxEmailRule,
  //DxAsyncRule,
} from 'devextreme-vue/form';

//import { DxRadioGroup } from 'devextreme-vue/radio-group';


import notify from 'devextreme/ui/notify';
import { ref } from 'vue';

import $ from 'jquery';
import Foundation from 'foundation-sites';

import ManFront from '../svg/man_front.vue';
import ManBack from '../svg/man_back.vue';
import WomanFront from '../svg/woman_front.vue';
import WomanBack from '../svg/woman_back.vue';

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 3,
  lg: 4
}

let Picture;
let Picthumb;
let Video;

let Option1;
let option1list;
let option1array;
let option1count;
const optionselected1 = ref(null);

let Option2;
let option2list;
let option2array;
let option2count;
const optionselected2 = ref(null);

let repetitioncount;
let repetition;

let currentUser;

auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
  } // e.data
}); //auth

function handleErrors(response) {
   if (!response.ok) {
       throw Error(response.statusText);
   }
   return response;
 }

export default {
 name: 'exerciseitem-template',

 props: {
  templateData: Object,
 },

 components: {
  DxFileUploader,
  DxForm,
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  DxTextBox,

  //DxGroupItem,
  //DxRadioGroup,

  ManFront,
  ManBack,
  WomanFront,
  WomanBack,

 },

 mounted() {

  //foundation
  this.tabsLang = new Foundation.Tabs($('#tabsLang'+this.templateData.data.id), {
    matchHeight: false
  })
  this.tabsMusclegroup = new Foundation.Tabs($('#tabsMusclegroup'+this.templateData.data.id), {
    matchHeight: false
  })
  this.tabsTool = new Foundation.Tabs($('#tabsTool'+this.templateData.data.id), {
    matchHeight: false
  })
  this.tabsRepetition = new Foundation.Tabs($('#tabsRepetition'+this.templateData.data.id), {
    matchHeight: false
  })
  this.tabsVideo = new Foundation.Tabs($('#tabsVideo'+this.templateData.data.id), {
    matchHeight: false
  })

  //set repetitions
  this.repetitioncount = 0;

  //set checkboxes Musclegroup
  this.optionselected1 = [];
  this.option1list = [];
  this.option1array = [];
  this.option1count = 0;

  //set checkboxes Tool
  this.optionselected2 = [];
  this.option2list = [];
  this.option2array = [];
  this.option2count = 0;

  if(this.templateData.data.Option1.length > 1){
    this.optionselected1 = JSON.parse(this.templateData.data.Option1);
    this.option1list = JSON.parse(this.templateData.data.Option1);
  }

  this.optionselected2 = [];
  if(this.templateData.data.Option2.length > 1){
    this.optionselected2 = JSON.parse(this.templateData.data.Option2);
    this.option2list = JSON.parse(this.templateData.data.Option2);
  }

  this.Picture = this.templateData.data.Picture;
  this.Picthumb = this.templateData.data.Picthumb;
  this.Video = this.templateData.data.Video;

  //get checkboxvalues

  //get option1array and count
  this.Option1 = this.templateData.data.Option1;
  this.option1list = this.templateData.data.Option1list;
  this.option1array = this.templateData.data.Option1array;
  this.option1count = this.templateData.data.Option1array.length;

  //get option2array and count
  this.Option2 = this.templateData.data.Option2;
  this.option2list = this.templateData.data.Option2list;
  this.option2array = this.templateData.data.Option2array;
  this.option2count = this.templateData.data.Option2array.length;

  //get repetion array and count
  this.repetitioncount = 1;
  this.repetition = this.templateData.data.Repetition;

  //SVG CHECKED (on load)
  //console.log(musclegroupArray);
  const musclegroup = document.getElementById("musclegroupContainer"+this.templateData.data.id);
  const svgnodes = musclegroup.getElementsByTagName("g");
  //const musclegroupChecked = this.templateData.data.Option1string;
  const musclegroupArray = this.templateData.data.Option1string.split(", ");

  //remove all checked  
  for (let i = 0; i < svgnodes.length; i++) {
    if(svgnodes[i].id && svgnodes[i].id != 'Kontur'){
      let text = svgnodes[i].id;
      let svg = document.getElementById("musclegroupContainer"+this.templateData.data.id).querySelector('#'+text);
      if(svg){
        svg.classList.remove("checked");
      }
    }
  }  

  //add checked for selected
  for (let i = 0; i < musclegroupArray.length; i++) {
    let text = musclegroupArray[i].replace(' ','_').replace('Ä','Ae').replace('ä','ae').replace('Ü','Ue').replace('ü','ue').replace('Ö','Oe').replace('ö','oe');

    let svgmanfront = document.getElementById("musclegroupContainer"+this.templateData.data.id).querySelector('#'+text+'_MF');
    let svgmanback = document.getElementById("musclegroupContainer"+this.templateData.data.id).querySelector('#'+text+'_MB');
    let svgwomanfront = document.getElementById("musclegroupContainer"+this.templateData.data.id).querySelector('#'+text+'_WF');
    let svgwomanback = document.getElementById("musclegroupContainer"+this.templateData.data.id).querySelector('#'+text+'_WB');

    if(svgmanfront){
    svgmanfront.classList.add("checked");
    }
    if(svgmanback){
    svgmanback.classList.add("checked");
    }
    if(svgwomanfront){
    svgwomanfront.classList.add("checked");
    }
    if(svgwomanback){
    svgwomanback.classList.add("checked");
    }

  }

  //SVG HOVER AND CLICK

  for (let i = 0; i < svgnodes.length; i++) {
    
    //hover
    if(svgnodes[i].id && svgnodes[i].id != 'Kontur'){
      svgnodes[i].addEventListener('mouseover', () => {
      let text = svgnodes[i].id.replace('_',' ').replace('_','').replace('MF','').replace('MB','').replace('WF','').replace('WB','').replace('Ae','Ä').replace('ae','ä').replace('Ue','Ü').replace('ue','ü').replace('Oe','Ö').replace('oe','ö');
      document.getElementById("musclegroupindicator"+this.templateData.data.id).innerHTML = text;
      document.getElementById("musclegroupindicator"+this.templateData.data.id).style.opacity = "1";
      });
      svgnodes[i].addEventListener('mouseout', () => {
      document.getElementById("musclegroupindicator"+this.templateData.data.id).innerHTML = "";
      document.getElementById("musclegroupindicator"+this.templateData.data.id).style.opacity = "0";
      });
    }

    //click
    svgnodes[i].addEventListener('click', () => {
    let text = svgnodes[i].id.replace('_MF','').replace('_MB','').replace('_WF','').replace('_WB','');
    let thischeckbox = document.getElementById(text+this.templateData.data.id);

    if(thischeckbox){

    let svgmanfront = document.getElementById("musclegroupContainer"+this.templateData.data.id).querySelector('#'+text+'_MF');
    let svgmanback = document.getElementById("musclegroupContainer"+this.templateData.data.id).querySelector('#'+text+'_MB');
    let svgwomanfront = document.getElementById("musclegroupContainer"+this.templateData.data.id).querySelector('#'+text+'_WF');
    let svgwomanback = document.getElementById("musclegroupContainer"+this.templateData.data.id).querySelector('#'+text+'_WB');

    if(thischeckbox.checked){

      //uncheck checkbox
      thischeckbox.checked = false;

      //Remove option1array
      const index = this.option1array.findIndex(key => key.id === thischeckbox.value);
      this.option1array.splice(index,1);
      this.option1count = this.option1array.length;

      //Remove checked svg
      if(svgmanfront){
      svgmanfront.classList.remove("checked");
      }
      if(svgmanback){
      svgmanback.classList.remove("checked");
      }
      if(svgwomanfront){
      svgwomanfront.classList.remove("checked");
      }
      if(svgwomanback){
      svgwomanback.classList.remove("checked");
      }

    } else {

      //check checkbox
      thischeckbox.checked = true;

      //add option1array
      const item = this.option1list.find(item => item.id === parseInt(thischeckbox.value));
      const obj = { id: thischeckbox.id ,Subject: item.Subject };
      this.option1array.push(obj);
      this.option1count = this.option1array.length;

      //Add checked svg
      if(svgmanfront){
      svgmanfront.classList.add("checked");
      }
      if(svgmanback){
      svgmanback.classList.add("checked");
      }
      if(svgwomanfront){
      svgwomanfront.classList.add("checked");
      }
      if(svgwomanback){
      svgwomanback.classList.add("checked");
      }

    } //thischeckbox.checked

    } //thischeckbox

    });

  } // for svgnodes


},

 data() {
   return {
    apihost,
    
    currentUser,
    colCountByScreen,

    Picture,
    Picthumb,
    Video,

    //checkboxes option1
    Option1,
    optionselected1,
    option1list,
    option1array,
    option1count,

    //checkboxes option2
    Option2,
    optionselected2,
    option2list,
    option2array,
    option2count,

    //repetitions
    repetition,
    repetitioncount,


    //save Button
    buttonOptions: {
      text: currentUser.Translation.vueappExerciseFormSubmit,
      type: 'success',
      useSubmitBehavior: true,
    },

    //uploader
    formRefName: 'tree-view',
    multiple: false,
    accept: '*',
    uploadMode: 'instantly',
    fileTypesSource: [
      { name: 'All types', value: '*' },
      { name: 'Images', value: 'image/*' },
      { name: 'Videos', value: 'video/*' },
    ],
    files: [],
    //end uploader    
    
    
    settingSource: ['Wiederholungen', 'Zeit'],

    settingEntities: [
      { id: 0, value: 'repeat', text: 'Wdhl.' },
      { id: 1, value: 'time', text: 'Zeit' },
    ],

   };

 },
 methods: {

  checkboxes1: function(e) {
    //console.log(e);
		//console.log(e.target.checked);
    const text = e.target.id.replace(this.templateData.data.id,'')

    let svgmanfront = document.getElementById("musclegroupContainer"+this.templateData.data.id).querySelector('#'+text+'_MF');
    let svgmanback = document.getElementById("musclegroupContainer"+this.templateData.data.id).querySelector('#'+text+'_MB');
    let svgwomanfront = document.getElementById("musclegroupContainer"+this.templateData.data.id).querySelector('#'+text+'_WF');
    let svgwomanback = document.getElementById("musclegroupContainer"+this.templateData.data.id).querySelector('#'+text+'_WB');


    //console.log(this.e.target.id);

    // console.log(svgmanfront);
		// console.log(svgmanback);
		// console.log(svgwomanfront);
		// console.log(svgwomanback);
    
    if(e.target.checked){

      //add option1array
      const item = this.option1list.find(item => item.id === parseInt(e.target.value));
      const obj = { id: e.target.value ,Subject: item.Subject };
      this.option1array.push(obj);

      // console.log(e.target.id);
      // console.log(e.target.value);
      // console.log('add');
      // console.log(item.Subject);
      // console.log(obj);
      // console.log(this.option1array);

      if(svgmanfront){
      svgmanfront.classList.add("checked");
      }
      if(svgmanback){
      svgmanback.classList.add("checked");
      }
      if(svgwomanfront){
      svgwomanfront.classList.add("checked");
      }
      if(svgwomanback){
      svgwomanback.classList.add("checked");
      }
      } else {

      //Remove option1array
      const index = this.option1array.findIndex(key => key.id === e.target.value);
      this.option1array.splice(index,1);

      // console.log(e.target.id);
      // console.log(e.target.value);
      // console.log('remove');
      // console.log(index); // 0
      // console.log(this.option1array);

      if(svgmanfront){
      svgmanfront.classList.remove("checked");
      }
      if(svgmanback){
      svgmanback.classList.remove("checked");
      }
      if(svgwomanfront){
      svgwomanfront.classList.remove("checked");
      }
      if(svgwomanback){
      svgwomanback.classList.remove("checked");
      }
    } //e.target.checked

  },

  checkboxes2: function(e) {
    //console.log(e);
		//console.log(e.target.checked);
    //const text = e.target.id.replace(this.templateData.data.id,'')
    //console.log(text);

    
    if(e.target.checked){

      //add option2array
      const item = this.option2list.find(item => item.id === parseInt(e.target.value));
      const obj = { id: e.target.value ,Subject: item.Subject };
      this.option2array.push(obj);

      // console.log(e.target.id);
      // console.log(e.target.value);
      // console.log('add');
      // console.log(item.Subject);
      // console.log(obj);
      // console.log(this.option2array);

    } else {

      //Remove option2array
      const index = this.option1array.findIndex(key => key.id === e.target.value);
      this.option2array.splice(index,1);

      // console.log(e.target.id);
      // console.log(e.target.value);
      // console.log('remove');
      // console.log(index); // 0
      // console.log(this.option2array);


    } //e.target.checked

  },

  printTimestamp: function () {
    return Date.now();
  },

  onFilesUploaded() {

    const id = this.formInstance.getEditor('id').option('value'); 

    let objString = '?id=' + id;
    //console.log(objString);

    fetch(apihost+'/de/vue/exercise/getexercise/'+id+'/view'+objString)
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
      const data = JSON.parse(result);
      //console.log(data);

      //update Frontend
      this.Picture = data.Picture;
      this.Picthumb = data.Picthumb;
      this.Video = data.Video;

    })
    .catch(() => { throw 'Network error' });
  
    notify({
      message: currentUser.Translation.vueappExerciseFormSubmitSuccess,
      position: {
        my: 'center top',
        at: 'center top',
      },
    }, 'success', 3000);
    

    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);

  },

  saveFormInstance(e) {
    this.formInstance = e.component;
  },

  handleSubmit(e) {
    e.preventDefault();

    const id = this.formInstance.getEditor('id').option('value'); 
    // const Status = this.formInstance.getEditor('Status').option('value');  
    // const Priority = this.formInstance.getEditor('Priority').option('value');  

    const Subject_de = document.getElementById("Subject_de"+id).getElementsByTagName("input")[0].value;
    const Subject_en = document.getElementById("Subject_en"+id).getElementsByTagName("input")[0].value;
    const Subject_fr = document.getElementById("Subject_fr"+id).getElementsByTagName("input")[0].value;
    const Subject_es = document.getElementById("Subject_es"+id).getElementsByTagName("input")[0].value;
    const Subject_it = document.getElementById("Subject_it"+id).getElementsByTagName("input")[0].value;

    const Description = document.getElementById("Description"+id).getElementsByTagName("input")[0].value;

    //Settingtype
    const radiosettingtype = document.getElementsByName("settingtype[]"+id);
    const selectedSettingtype = Array.prototype.slice.call(radiosettingtype).filter(ch => ch.checked==true);
    const Settingtype = selectedSettingtype[0].value;
    //console.log(Settingtype);


    //Repetions
    const repetitionsRep = document.getElementsByName("repetitionrep[]"+id);
    const repetitionsTime = document.getElementsByName("repetitiontime[]"+id);
    const repetitionWeight = document.getElementsByName("repetitionweight[]"+id);

    let repetitionselectedRep = [];
    let repetitionselectedTime = [];
    let repetitionselectedWeight = [];

    for (let i = 0; i < repetitionsRep.length; i++) {
      repetitionselectedRep.push(repetitionsRep[i].value);
      repetitionselectedTime.push(repetitionsTime[i].value);
      repetitionselectedWeight.push(repetitionWeight[i].value);
    }
    // console.log(repetitionselectedRep);
    // console.log(repetitionselectedTime);
    // console.log(repetitionselectedWeight);
    const Settingrep = encodeURIComponent(JSON.stringify(repetitionselectedRep));
    const Settingtime = encodeURIComponent(JSON.stringify(repetitionselectedTime));
    const Settingweight = encodeURIComponent(JSON.stringify(repetitionselectedWeight));
    // console.log(Settingrep);
    // console.log(Settingtime);
    // console.log(Settingweight);

    //Checkboxes Location
    const checkboxeslocation = document.getElementsByName("checkboxeslocation"+id);
    //console.log(checkboxeslocation)

    const selectedCheckboxeslocation = Array.prototype.slice.call(checkboxeslocation).filter(ch => ch.checked==true);
    let optionselectedlocation = [];
    for (let i1 = 0; i1 < selectedCheckboxeslocation.length; i1++) {
      optionselectedlocation.push(selectedCheckboxeslocation[i1].value);
    }
    const OptionLocation = encodeURIComponent(JSON.stringify(optionselectedlocation));
    //console.log(OptionLocation)


    //Checkboxes 1
    const checkboxes1 = document.getElementsByName("checkboxes1"+id);
    const selectedCheckboxes1 = Array.prototype.slice.call(checkboxes1).filter(ch => ch.checked==true);
    let optionselected1 = [];
    for (let i1 = 0; i1 < selectedCheckboxes1.length; i1++) {
      optionselected1.push(selectedCheckboxes1[i1].value);
    }
    const Option1 = encodeURIComponent(JSON.stringify(optionselected1));

    //Checkboxes 2
    const checkboxes2 = document.getElementsByName("checkboxes2"+id);
    const selectedCheckboxes2 = Array.prototype.slice.call(checkboxes2).filter(ch => ch.checked==true);
    let optionselected2 = [];
    for (let i2 = 0; i2 < selectedCheckboxes2.length; i2++) {
      optionselected2.push(selectedCheckboxes2[i2].value);
    }
    const Option2 = encodeURIComponent(JSON.stringify(optionselected2));


    let objString = '?id=' + id;

    if(Subject_de){
      objString = objString + '&Subject_de=' + Subject_de;
    } else {
      objString = objString + '&Subject_de=';
    }
    if(Subject_en){
      objString = objString + '&Subject_en=' + Subject_en;
    } else {
      objString = objString + '&Subject_en=';
    }
    if(Subject_fr){
      objString = objString + '&Subject_fr=' + Subject_fr;
    } else {
      objString = objString + '&Subject_fr=';
    }
    if(Subject_es){
      objString = objString + '&Subject_es=' + Subject_es;
    } else {
      objString = objString + '&Subject_es=';
    }
    if(Subject_it){
      objString = objString + '&Subject_it=' + Subject_it;
    } else {
      objString = objString + '&Subject_it=';
    }
    if(Description){
      objString = objString + '&Description=' + Description;
    } else {
      objString = objString + '&Description=';
    }
    if(OptionLocation){
      objString = objString + '&OptionLocation=' + OptionLocation;
    } else {
      objString = objString + '&OptionLocation=';
    }
    if(optionselected1){
      objString = objString + '&Option1=' + Option1;
    } else {
      objString = objString + '&Option1=';
    }
    if(optionselected2){
      objString = objString + '&Option2=' + Option2;
    } else {
      objString = objString + '&Option2=';
    }
    if(Settingtype){
      objString = objString + '&Settingtype=' + Settingtype;
    } else {
      objString = objString + '&Settingtype=';
    }
    if(Settingrep){
      objString = objString + '&Settingrep=' + Settingrep;
    } else {
      objString = objString + '&Settingrep=';
    }
    if(Settingtime){
      objString = objString + '&Settingtime=' + Settingtime;
    } else {
      objString = objString + '&Settingtime=';
    }
    if(Settingweight){
      objString = objString + '&Settingweight=' + Settingweight;
    } else {
      objString = objString + '&Settingweight=';
    }

    //console.log(objString);

    fetch(apihost+'/de/vue/exercise/getexercise/'+id+'/save'+objString)
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
      const data = JSON.parse(result);
      //console.log(data);

      //update Frontend
      this.Picture = data.Picture;
      this.Picthumb = data.Picthumb;
      this.Video = data.Video;

    })
    .catch(() => { throw 'Network error' });
  
    notify({
      message: currentUser.Translation.vueappExerciseFormSubmitSuccess,
      position: {
        my: 'center top',
        at: 'center top',
      },
    }, 'success', 3000);

  },    

  },

};

</script>

<style>

</style>
