<template>
  <svg class="mysportapp-logo" viewBox="0 0 717.4 136.6" fill="none" xmlns="http://www.w3.org/2000/svg">
  
    <path class="st0" d="M124.8,46.6c7.9,0,14.3-6.4,14.3-14.3S132.7,18,124.8,18s-14.3,6.4-14.3,14.3S116.9,46.6,124.8,46.6"></path>
    <path class="st0" d="M126.4,115.6l-10.8-24.7l20.5-9.4L126.4,115.6z M149.3,66.1c-3.1-2.6-7.3-3.2-11-1.5l-28.5,13.1L79.7,8.9
    C77.3,3.5,72.2,0.1,66.4,0c-5.9-0.1-11.1,3.1-13.6,8.4L1.5,115.6c-2.7,5.6-1.6,12,2.8,16.4c4.4,4.4,10.8,5.5,16.3,2.9l70-32.9
    l-6.1-13.1l-69.9,32.9l51.2-107c0.1-0.1,0.2-0.2,0.3-0.2c0.2,0,0.2,0.1,0.3,0.2l50.4,115.1c1.8,4.1,5.8,6.7,10.2,6.7
    c0.3,0,0.5,0,0.8,0c4.8-0.3,8.7-3.5,10-8.1l14.6-51.6C153.6,72.8,152.4,68.7,149.3,66.1"></path>
    <polygon class="st1" points="268.6,63 268.6,115.7 255.8,115.7 255.8,84 243.8,115.7 233.5,115.7 221.7,83.8 221.7,115.7 
    209,115.7 209,63 224,63 238.8,99.4 253.4,63"></polygon>
    <polygon class="st1" points="320.5,73.7 294.3,135.5 280.5,135.5 290.1,114.2 273,73.7 287.4,73.7 297.2,99.9 306.7,73.7 	"></polygon>
    <path class="st1" d="M348.7,113.7c-3-1.2-5.5-3-7.3-5.5c-1.8-2.4-2.8-5.5-2.8-8.9h13.6c0.2,2,0.8,3.4,2,4.5c1.2,1,2.6,1.6,4.5,1.6
    s3.4-0.4,4.5-1.2c1-0.8,1.6-2,1.6-3.6c0-1.2-0.4-2.4-1.2-3.2c-0.8-0.8-2-1.6-3.2-2c-1.2-0.6-3-1.2-5.5-1.8c-3.4-1-6.1-2-8.3-3.2
    c-2.2-1-4.1-2.6-5.5-4.7c-1.6-2-2.2-4.7-2.2-8.1c0-4.9,1.8-8.7,5.3-11.6c3.6-2.8,8.1-4.3,13.8-4.3c5.9,0,10.5,1.4,14,4.3
    c3.6,2.8,5.5,6.7,5.7,11.6h-13.8c0-1.6-0.8-3-1.8-4.1c-1.2-1-2.6-1.4-4.5-1.4c-1.6,0-2.8,0.4-3.9,1.2c-1,0.8-1.4,2-1.4,3.6
    c0,1.6,0.8,3,2.4,4.1c1.6,1,4.1,2,7.5,3c3.4,1.2,6.1,2.2,8.3,3.2c2,1,4.1,2.6,5.5,4.7c1.6,2,2.2,4.7,2.2,7.7s-0.8,5.7-2.2,8.1
    c-1.6,2.4-3.6,4.5-6.7,5.9c-3,1.4-6.3,2.2-10.3,2.2C355.4,115.5,351.9,114.9,348.7,113.7"></path>
    <path class="st1" d="M414.8,86.5c-1.8-1.8-4.1-2.8-6.5-2.8c-2.6,0-4.7,1-6.5,2.8s-2.6,4.5-2.6,7.5c0,3.2,0.8,5.7,2.6,7.5
    c1.8,1.8,3.9,2.8,6.5,2.8c2.6,0,4.7-1,6.5-2.8s2.6-4.5,2.6-7.7S416.4,88.3,414.8,86.5 M404.2,74.3c2.2-1.2,4.9-1.8,7.7-1.8
    c3.4,0,6.5,0.8,9.3,2.6c2.8,1.8,5.1,4.3,6.7,7.5s2.4,7.1,2.4,11.4c0,4.3-0.8,8.1-2.4,11.4s-3.9,5.9-6.7,7.5
    c-2.8,1.8-5.9,2.6-9.3,2.6c-2.8,0-5.5-0.6-7.7-1.8c-2.2-1.2-4.1-2.8-5.3-4.7v25.7h-12.8V73.1h12.8V79
    C400.4,77.2,402,75.6,404.2,74.3"></path>
    <path class="st1" d="M462.6,101.9c1.8-1.8,2.6-4.5,2.6-7.7c0-3.4-0.8-5.9-2.4-7.7c-1.6-1.8-3.6-2.6-6.3-2.6c-2.4,0-4.7,0.8-6.3,2.6
    s-2.4,4.5-2.4,7.7c0,3.4,0.8,5.9,2.4,7.7s3.6,2.6,6.1,2.6C458.8,104.6,460.8,103.7,462.6,101.9 M445.2,113.1
    c-3.2-1.8-5.9-4.3-7.7-7.5c-1.8-3.2-2.8-7.1-2.8-11.4c0-4.3,1-8.1,2.8-11.4c1.8-3.2,4.5-5.9,7.9-7.5c3.2-1.8,7.1-2.6,11.2-2.6
    c4.1,0,7.9,0.8,11.2,2.6s5.9,4.3,7.7,7.5c1.8,3.2,2.8,7.1,2.8,11.4c0,4.3-1,8.1-2.8,11.4c-2,3.2-4.5,5.9-7.9,7.5
    c-3.2,1.8-7.1,2.6-11.2,2.6C452.1,115.7,448.4,114.7,445.2,113.1"></path>
    <path class="st1" d="M503.4,74.7c2.2-1.4,4.7-2,7.5-2v13.6h-3.4c-3.2,0-5.7,0.6-7.3,2c-1.6,1.4-2.4,3.9-2.4,7.3v19.5h-12.8v-42
    h12.8V80C499.3,77.8,501.1,76,503.4,74.7"></path>
    <path class="st1" d="M540.5,104.1v10.9H534c-4.7,0-8.3-1.2-10.9-3.4c-2.6-2.2-3.9-6.1-3.9-11.2V83.9h-5.1V73.1h5.1V63h12.8v10.1
    h8.3v10.7h-8.3v16.8c0,1.2,0.2,2.2,0.8,2.6c0.6,0.6,1.6,0.8,3,0.8L540.5,104.1L540.5,104.1z"></path>
    <path class="st1" d="M596.3,95.8l-6.5-19.3l-6.5,19.3H596.3z M599.6,105.8h-19.7l-3.2,9.3h-13.4l19.1-52.7h14.8l19.1,52.7h-13.6
    L599.6,105.8z"></path>
    <path class="st1" d="M650.7,86.5c-1.8-1.8-4.1-2.8-6.5-2.8c-2.6,0-4.7,1-6.5,2.8c-1.8,1.8-2.6,4.5-2.6,7.5c0,3.2,0.8,5.7,2.6,7.5
    c1.8,1.8,3.9,2.8,6.5,2.8s4.7-1,6.5-2.8c1.8-1.8,2.6-4.5,2.6-7.7C653.3,91,652.5,88.3,650.7,86.5 M640.1,74.3
    c2.2-1.2,4.9-1.8,7.7-1.8c3.4,0,6.5,0.8,9.3,2.6s5.1,4.3,6.7,7.5s2.4,7.1,2.4,11.4s-0.8,8.1-2.4,11.4s-3.9,5.9-6.7,7.5
    c-2.8,1.8-5.9,2.6-9.3,2.6c-2.8,0-5.5-0.6-7.7-1.8s-4.1-2.8-5.3-4.7v25.7h-12.8V72.9h12.8v5.9C636.3,77.2,637.9,75.5,640.1,74.3"></path>
    <path class="st1" d="M701.6,86.5c-1.8-1.8-4.1-2.8-6.5-2.8c-2.6,0-4.7,1-6.5,2.8c-1.8,1.8-2.6,4.5-2.6,7.5c0,3.2,0.8,5.7,2.6,7.5
    c1.8,1.8,3.9,2.8,6.5,2.8c2.6,0,4.7-1,6.5-2.8c1.8-1.8,2.6-4.5,2.6-7.7C704.2,91,703.4,88.3,701.6,86.5 M691.2,74.3
    c2.2-1.2,4.9-1.8,7.7-1.8c3.4,0,6.5,0.8,9.3,2.6s5.1,4.3,6.7,7.5s2.4,7.1,2.4,11.4s-0.8,8.1-2.4,11.4s-3.9,5.9-6.7,7.5
    c-2.8,1.8-5.9,2.6-9.3,2.6c-2.8,0-5.5-0.6-7.7-1.8s-4.1-2.8-5.3-4.7v25.7h-12.8V72.9h12.8v5.9C687.2,77.2,689,75.5,691.2,74.3"></path>

  </svg>
</template>