<template>
    <svg class="lasuite-logo" viewBox="0 0 1328.1 307.9" fill="none" xmlns="http://www.w3.org/2000/svg">

        <path class="st0" d="M441.5,234.7v14.8h24v8.4h-24v22.3h-9.1v-54.1h36l-0.1,8.5L441.5,234.7z"/>
        <path class="st0" d="M510.8,226.2h9.1v54.1h-9.1L510.8,226.2z"/>
        <path class="st0" d="M587,234.6v45.7h-9.1v-45.7h-16.4v-8.4h41.9v8.4L587,234.6z"/>
        <path class="st0" d="M685.5,226.2h9.1v54.1h-9.9l-30.6-39.5v39.5H645v-54h9.1l31.4,40.4V226.2z"/>
        <path class="st0" d="M779.3,226.2v8.6h-28.9v14.3h25.9v8.1h-25.9v14.5h29.8v8.6h-38.9v-54h38V226.2z"/>
        <path class="st0" d="M840,233.3c-2.3-0.1-4.6,0.5-6.5,1.6c-1.7,1.1-2.7,2.9-2.5,4.9c-0.1,2,0.9,3.9,2.5,5
        c1.7,1.2,5.3,2.4,10.9,3.7c5.5,1.3,9.7,3.2,12.5,5.7c2.8,2.4,4.2,6,4.2,10.7c0.1,4.5-1.9,8.7-5.3,11.5c-3.5,2.9-8.2,4.4-14,4.4
        c-8.3,0.1-16.4-3.1-22.5-8.7l5.7-6.9c5.5,4.8,11.1,7.1,17,7.1c2.5,0.1,4.9-0.5,7-1.9c1.6-1.1,2.6-3,2.6-5
        c0.1-1.9-0.8-3.8-2.4-4.9c-1.6-1.2-4.4-2.2-8.4-3.2c-3.1-0.7-6.1-1.6-9.1-2.6c-2-0.8-3.8-1.8-5.5-3.1c-3.2-2.4-4.8-6.1-4.8-11.1
        s1.8-8.9,5.5-11.6c3.9-2.8,8.7-4.2,13.5-4c3.5,0,7,0.6,10.3,1.7c3.2,1,6.2,2.6,8.8,4.8l-4.9,6.9c-1.9-1.6-4.1-2.8-6.5-3.6
        C845.4,233.8,842.7,233.4,840,233.3z"/>
        <path class="st0" d="M920.4,233.3c-2.3-0.1-4.6,0.5-6.5,1.6c-1.7,1.1-2.7,2.9-2.6,4.9c-0.1,2,0.9,3.9,2.6,5
        c1.7,1.2,5.3,2.4,10.9,3.7c5.5,1.3,9.7,3.2,12.5,5.6s4.2,6,4.2,10.7c0.1,4.5-1.9,8.7-5.3,11.5c-3.6,2.9-8.2,4.4-14,4.4
        c-8.3,0.1-16.4-3.1-22.5-8.7l5.7-6.9c5.5,4.8,11.1,7.1,17,7.1c2.5,0.1,4.9-0.5,7-1.9c1.6-1.1,2.6-3,2.6-5
        c0.1-1.9-0.8-3.8-2.4-4.9c-1.6-1.2-4.4-2.2-8.4-3.2c-3.1-0.7-6.1-1.6-9-2.6c-2-0.7-3.8-1.8-5.5-3.1c-3.2-2.4-4.8-6.1-4.8-11.1
        s1.8-8.9,5.5-11.6c3.9-2.8,8.7-4.2,13.5-4c3.5,0,7,0.6,10.3,1.7c3.2,1,6.2,2.7,8.8,4.8l-4.9,6.9c-1.9-1.6-4.1-2.8-6.5-3.6
        C925.9,233.8,923.2,233.3,920.4,233.3z"/>
        <path class="st0" d="M1014.5,272c2.8,0.1,5.6-0.5,8.1-1.6c2.6-1.3,5-3,7.1-5l5.9,6c-5.7,6.3-12.7,9.5-20.9,9.5s-15-2.6-20.3-7.9
        c-5.4-5.3-8.1-11.9-8.1-19.9c-0.2-7.6,2.8-14.9,8.2-20.1c5.5-5.3,12.4-8,20.8-8c8-0.1,15.7,3.2,21,9.3l-5.8,6.3
        c-2.1-2.2-4.6-3.9-7.3-5.2c-2.6-1-5.3-1.5-8.1-1.5c-5.1-0.1-10.1,1.8-13.8,5.3s-5.7,8.4-5.6,13.5c-0.1,5.2,1.9,10.2,5.5,13.8
        C1004.8,270,1009.5,272,1014.5,272z"/>
        <path class="st0" d="M1078.4,280.3v-54h9.1v45.4h24.7v8.7L1078.4,280.3z"/>
        <path class="st0" d="M1164.7,267.9c5.3,5.6,14,5.8,19.6,0.5c0.2-0.2,0.4-0.4,0.5-0.5c2.5-2.9,3.7-6.8,3.7-11.8v-29.9h9.1v30.3
        c0,7.8-2.1,13.8-6.4,18s-9.8,6.3-16.5,6.3s-12.2-2.1-16.5-6.3c-4.3-4.2-6.4-10.2-6.4-18v-30.3h9.1v29.9
        C1161,261.1,1162.2,265,1164.7,267.9z"/>
        <path class="st0" d="M1267.3,280.3h-23.7v-54h21c3.2-0.1,6.4,0.4,9.5,1.3c2.3,0.6,4.3,1.9,5.9,3.6c2.4,2.6,3.8,5.9,3.8,9.4
        c0,4.2-1.3,7.4-4,9.4c-0.6,0.5-1.3,1-2,1.4c-0.4,0.2-1,0.5-2,0.9c3.1,0.6,5.9,2.2,8,4.5c2,2.4,3.1,5.4,3,8.5
        c0,3.7-1.4,7.3-3.9,10C1279.9,278.6,1274.7,280.3,1267.3,280.3z M1252.8,248.6h11.6c6.6,0,9.9-2.2,9.9-6.7
        c0-2.6-0.8-4.5-2.4-5.6s-4.1-1.7-7.4-1.7h-11.7V248.6z M1252.8,271.8h14.3c3.3,0,5.9-0.5,7.7-1.6s2.7-3,2.7-6
        c0-4.8-3.8-7.2-11.5-7.2h-13.1L1252.8,271.8L1252.8,271.8z"/>
        <path class="st0" d="M743.1,91.1L743.1,91.1C720,85.5,701.8,81,701.8,63.8c0-15.6,14.4-25,38.5-25c15,0.2,29.6,4.6,42.1,12.7
        l4.5,2.9l9.3-22l-3-2.1c-13.7-9.5-33.4-15.2-52.9-15.2c-47.4,0-64.3,25.3-64.3,49c0,35.8,31.3,43.5,59,50.2
        c22.9,5.6,41,10,41,27.1c0,15.4-14.3,24.3-39.2,24.3c-19.2,0-39-7.2-50.5-18.5L682,143l-11,21.7l2.2,2.3
        c13.2,13.7,38.1,22.5,63.6,22.5c47.9,0,65-25.2,65-48.8C801.8,105.5,770.6,97.9,743.1,91.1z"/>
        <path class="st0" d="M1026.6,6.9c-8.9,0.2-16,7.6-15.9,16.5s7.6,16,16.5,15.9c8.9-0.2,16-7.6,15.9-16.5l0,0
        C1043.1,14.1,1035.7,6.9,1026.6,6.9z"/>
        <rect x="1013.8" y="57.1" class="st0" width="25.4" height="131"/>
        <path class="st0" d="M939.9,129.6c0,19.1-15.5,34.6-34.6,34.6s-34.6-15.5-34.6-34.6V57.1h-25.4v72.5c0,33.2,26.9,60.1,60.1,60.1
        s60.1-26.9,60.1-60.1V57.1h-25.4v72.5H939.9z"/>
        <path class="st0" d="M1326.9,135.3c0.8-4.2,1.2-8.5,1.2-12.8c0.1-37.1-30-67.3-67.1-67.3s-67.3,30-67.3,67.1s30,67.3,67.1,67.3
        c25.2,0,48.3-14,59.9-36.5h-31.5c-16.9,15.6-43.2,14.5-58.8-2.3c-4.1-4.5-7.2-9.8-9.1-15.6L1326.9,135.3L1326.9,135.3z
        M1261,80.9c18.1,0,34.1,11.7,39.7,28.9h-79.3C1226.9,92.6,1242.9,80.9,1261,80.9z"/>
        <path class="st0" d="M598.6,70.1c-29-23.1-71.2-18.3-94.3,10.7c-23.1,29-18.3,71.2,10.7,94.3c24.5,19.5,59.1,19.5,83.6,0v13H624
        v-131h-25.4V70.1z M556.9,164.2c-23,0-41.7-18.7-41.7-41.7s18.7-41.7,41.7-41.7s41.7,18.7,41.7,41.7l0,0
        C598.6,145.6,579.9,164.2,556.9,164.2z"/>
        <path class="st0" d="M422,137.5V6.9h-25.4v130.6c0,28.8,23.4,52.1,52.1,52.1h11.2v-25.5h-11.2C434,164.2,422.1,152.3,422,137.5z
        "/>
        <path class="st0" d="M1114.9,30.4h-25.4v107.2c0,28.8,23.4,52.1,52.1,52.1h11.2v-25.5h-11.2c-14.7,0-26.7-12-26.7-26.7v-55h37.9
        V57.1H1115L1114.9,30.4L1114.9,30.4z"/>
        <path class="st1" d="M209.5,148.9l-24-13.4l11.3-6.6c3.3-1.9,4.4-6.1,2.5-9.4c-0.6-1-1.5-1.9-2.5-2.5l-29.3-17
        c-2.9-1.7-6.6-1.7-9.5,0l-29.8,17.2c-3.3,1.9-4.4,6.1-2.5,9.4c0.6,1,1.5,1.9,2.5,2.5l102.3,59.2l33.5-19.6
        c21-12.3,33.8-34.8,33.8-59.1V97.2L209.5,148.9z"/>
        <path class="st1" d="M93.8,187.9l0.4,0.1l20.6-12.3l24,13.9L128,196c-3.3,1.9-4.3,6.2-2.4,9.4c0.6,1,1.5,1.9,2.5,2.4l29.5,17.1
        c2.9,1.7,6.6,1.7,9.5,0l29.5-17.1c3.3-1.9,4.4-6.1,2.5-9.4c-0.6-1-1.5-1.9-2.5-2.5l-170-98.7v12.3c0,24.4,12.9,46.9,34,59.2
        L93.7,188h0.1V187.9z"/>
        <path class="st1" d="M162.1,256.3L60.3,197v0.2c0,31.8,17,61.3,44.5,77.2l12.6,7.5l44.5,25.9l44.6-25.7l13.1-7.8
        c27.7-15.9,44.8-45.4,44.8-77.4l0,0L162.1,256.3z"/>
        <path class="st1" d="M94.1,107.6l0.4,0.1l44.4-25.6L0,0v12.5c0,24.3,12.9,46.7,33.8,59L94.1,107.6L94.1,107.6z"/>
        <path class="st1" d="M291.5,71.6C312.3,59.2,325,36.8,325,12.7V0L186.1,82.3l44.6,25.7L291.5,71.6z"/>

    </svg>
</template>

