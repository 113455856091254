<template>
  <div class="grid-x align-middle">
  <div class="auto cell">
  </div>
  <div class="shrink cell">
  <div style="padding: 20px;">
  <div id="medium-indicator" class="dx-loadindicator dx-widget" style="width: 40px; height: 40px;">
  <div class="dx-loadindicator-wrapper">
  <div class="dx-loadindicator-content">
  <div class="dx-loadindicator-icon">
  <div class="dx-loadindicator-segment dx-loadindicator-segment2">
  <div class="dx-loadindicator-segment-inner">
  </div>
  </div>
  <div class="dx-loadindicator-segment dx-loadindicator-segment1">
  <div class="dx-loadindicator-segment-inner">
  </div>
  </div>
  <div class="dx-loadindicator-segment dx-loadindicator-segment0">
  <div class="dx-loadindicator-segment-inner">
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  <div class="auto cell">
  </div>
  </div>
</template>
