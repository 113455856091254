<template>

  <!-- mainhide -->
  <div class="hide" style="font-size: 10px; padding: 10px; overflow: hidden;">

    <!-- {{ this.templateData.data }} -->

    <!-- subhide -->
    <div class="-hide" style="color: orange;">

      <p>id: {{ this.id }}</p>
      <p>CustomerId: {{ this.templateData.data.CustomerId }}</p>
      <p>Gender: {{ this.templateData.data.Gender }}</p>
      <p>Subject: {{ this.templateData.data.Subject }}</p>
      <p>ExercisesPlanned: {{ this.templateData.data.ExercisesPlanned }}</p>

    </div>
    <!-- /subhide -->

    &nbsp;

    <!-- subhide -->
    <div class="-hide" style="color: green;">

      <p>id: {{ id }}</p>
      <p>CustomerId: {{ CustomerId }}</p>
      <p>Gender: {{ Gender }}</p>
      <p>Subject: {{ Subject }}</p>
      <p>ExercisesPlanned: {{ ExercisesPlanned }}</p>

    </div>
    <!-- /subhide -->

  </div>
  <!-- /mainhide -->


  <!-- MASTER REVEAL -->
  <div id="revealDialogTraining" class="reveal" data-reveal style="padding: 0; border: none;">
    <div class="content">
      <div :id="'revealHtml'+id" class="reveal-html">
        <h2 class="no-margin-top">{{ id }} Awesome. I Have It.</h2>
      </div>
      <button class="close-button" data-close aria-label="Close modal" type="button">
        <span aria-hidden="true">&times;</span>
      </button>
    </div><!-- content -->
  </div><!-- revealDialogTraining -->
  <!-- //MASTER REVEAL -->

  <div v-for="exercise in ExercisesPlanned" :key="exercise.id">
    <!-- {{ exercise }} -->

    <!-- title -->
    <a @click="openReveal(exercise.id)" style="color: #000000;">

      <div class="grid-x align-middle" style="border-bottom: 1px solid #dedede;">
        <div class="shrink cell" style="margin: 0 10px 0 0; position: relative;">
          <!-- video-thumb -->
            <div class="video-thumb"
              v-bind:style="{ 'background-image': 'url(' + exercise.image + '?v=' + timestamp + ')' }" >
            </div>
          <!-- /video-thumb -->

          <!-- <div class="grid-x align-middle" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0;">
            <div class="cell text-center">
              <span style="display: inline-block; height: 30px; width: 30px; border-radius: 50%; background: #ffffff; opacity: 0.4;"></span>
            </div>
          </div>
          <div class="grid-x align-middle" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0;">
            <div class="cell text-center" style="font-size: 30px; line-height: 30px; opacity: 0.4;">
              <i class="fa-solid fa-circle-play"></i>
            </div>
          </div> -->

        </div>
        <div class="auto cell">
          <p class="ck-overflow-ellipsis small strong">{{ exercise.text }}</p>
          <!-- <p class="ck-overflow-ellipsis small hide">Brust, Nacken, Schulter, Trizeps <span>|</span> Boxhandschuhe</p> -->
        </div>

        <div class="shrink cell -hide">
          <p style="font-size: 20px; margin-right: 10px; color: #7f7f7f;">
            <span style="display: block; font-size: 10px; line-height: 20px; color: grey;">Video</span>
          </p>
        </div>

        <div class="shrink cell -hide">
          <p style="font-size: 20px; margin-right: 10px; color: #7f7f7f;">
            <i class="fa-light fa-circle-play"></i>
          </p>
        </div>

      </div>

    </a>

    <!-- revealContent -->
    <div :id="'revealContent'+id+exercise.id" class="hide">

      <div class="grid-x align-middle" style="border-bottom: 1px solid #dedede;">
        <div class="shrink cell">
          <div class="video-thumb" style="margin-right: 15px;"
            v-bind:style="{ 'background-image': 'url(' + exercise.image + '?v=' + timestamp + ')' }" >
          </div>
        </div>

        <div class="auto cell">
          <p class="ck-overflow-ellipsis small strong">
            {{ exercise.text }}
          </p>
        </div>
      </div>
      
      <div class="responsive-embed widescreen" style="margin: 0;">
        <video width="1920" height="1080" :poster="exercise.poster" controls="controls" muted="muted" loop="loop" preload="none">
          <source :src="exercise.video" type="video/mp4"> 
          Your browser does not support the video tag. 
        </video>
      </div>

    </div>
    <!-- /revealContent -->

    <!-- detail legend -->

    <div class="grid-x align-middle" style="height: 34px; border-bottom: 1px solid #dedede;">

      <div class="auto cell grid-x">

        <div class="shrink cell" style="width: 20px; margin-right: 5px;">
          <p class="ck-overflow-ellipsis small text-center" style="font-size: 10px; color: grey;">
            #
          </p>
        </div>

        <div class="shrink cell" :class="exercise.Type == 'time'  ? 'hide' : ''" style="width: 50px; margin-right: 5px;">
          <p class="ck-overflow-ellipsis small" style="font-size: 10px; color: grey;">
            <i class="fa-light fa-repeat" :class="exercise.Type == 'time'  ? 'hide' : ''"></i>
            <i class="fa-light fa-stopwatch" :class="exercise.Type == 'repeat'  ? 'hide' : ''"></i>
            Wdhl.
          </p>
        </div>

        <div class="shrink cell" :class="exercise.Type == 'repeat'  ? 'hide' : ''" style="width: 50px; margin-right: 5px;">
          <p class="ck-overflow-ellipsis small" style="font-size: 10px; color: grey;">
            <i class="fa-light fa-repeat" :class="exercise.Type == 'time'  ? 'hide' : ''"></i>
            <i class="fa-light fa-stopwatch" :class="exercise.Type == 'repeat'  ? 'hide' : ''"></i>
            Sek.
          </p>
        </div>
        <div class="shrink cell" style="width: 50px; margin-right: 5px;">
          <p class="ck-overflow-ellipsis small" style="font-size: 10px; color: grey;">
            <i class="fa-light fa-weight-hanging"></i>
            kg
          </p>
        </div>

      </div>

      <div class="shrink cell" style="margin-right: 10px;">
        <!-- {{ exercise.Type }} -->
      </div>

    </div>


    <!-- detail list -->

    <div v-for="repetition in exercise.Repetition" :key="repetition.id">

      <div :id="'row'+id+exercise.id+repetition.id" class="grid-x align-middle" :class="repetition.rep+repetition.time == '' ? 'hide' : ''" style="height: 34px; border-bottom: 1px solid #dedede;">
        <div class="auto cell grid-x">

        <div class="shrink cell" style="width: 20px; border: 0px solid #dedede; margin-right: 5px;">
          <p class="ck-overflow-ellipsis small text-center" style="line-height: 24px;">
            {{ repetition.id }}
          </p>
        </div>

        <div class="shrink cell" :class="exercise.Type == 'time'  ? 'hide' : ''" style="width: 50px; border: 0px solid #dedede; margin-right: 5px;">
          <p class="ck-overflow-ellipsis small">
            <!-- {{ repetition.rep }} -->
            <input @change="changeField('savebutton'+id+exercise.id)" :name="'repetitionrep'+id+exercise.id+'[]'" :value="repetition.rep" type="number" autocomplete="off" spellcheck="false" class="app-input-small">
          </p>
        </div>

        <div class="shrink cell" :class="exercise.Type == 'repeat'  ? 'hide' : ''" style="width: 50px; border: 0px solid #dedede; margin-right: 5px;">
          <p class="ck-overflow-ellipsis small">
            <!-- {{ repetition.time }} -->
            <input @change="changeField('savebutton'+id+exercise.id)" :name="'repetitiontime'+id+exercise.id+'[]'" :value="repetition.time" type="number" autocomplete="off" spellcheck="false" class="app-input-small">
          </p>
        </div>

        <div class="shrink cell" style="width: 50px; border: 0px solid #dedede; margin-right: 5px;">
          <p class="ck-overflow-ellipsis small">
            <!-- {{ repetition.weight }} -->
            <input @change="changeField('savebutton'+id+exercise.id)" :name="'repetitionweight'+id+exercise.id+'[]'" :value="repetition.weight" type="number" autocomplete="off" spellcheck="false" class="app-input-small">
          </p>
        </div>

        <div class="shrink cell -hide" style="padding: 2px 10px 0 0; font-size: 18px;">
          <a @click="saverepetition" class="fa-light fa-floppy-disk" :id="'savebutton'+id+exercise.id"></a>
        </div>

      </div>


      <a class="grid-x align-middle" @click="openReveal(exercise.id+'-'+repetition.id)">
        <div class="shrink cell">
          <p style="font-size: 20px; margin-right: 10px; color: #7f7f7f;">
            <span style="display: block; font-size: 10px; line-height: 20px; color: grey;">trainieren</span>
          </p>
        </div>
        <div class="shrink cell">
          <p style="font-size: 20px; margin-right: 10px;">
            <i class="fa-light fa-person-running-fast"></i>
          </p>
        </div>
      </a>

    </div>

    <!-- revealContent -->
    <div :id="'revealContent'+id+exercise.id+'-'+repetition.id" class="hide">

      <div class="grid-x align-middle" style="border-bottom: 1px solid #dedede;">
        <div class="shrink cell">
          <div class="video-thumb" style="margin-right: 15px;"
            v-bind:style="{ 'background-image': 'url(' + exercise.image + '?v=' + timestamp + ')' }" >
          </div>
        </div>

        <div class="auto cell">
          <p class="ck-overflow-ellipsis small strong">
            {{ exercise.text }}
          </p>
        </div>
      </div>

      <div class="reveal-exercise" 
        :data-customerid="CustomerId" 
        :data-planid="id" 
        :data-exerciseid="exercise.id" 

        :data-type="exercise.Type" 
        :data-rep="repetition.rep" 
        :data-time="repetition.time" 
        :data-weight="repetition.weight" 
        style="padding: 10px;"
      >

        <div>
          <p class="ck-overflow-ellipsis small -strong">
            <!-- Level {{ repetition.id }} |  -->
            <span :class="exercise.Type == 'time'  ? 'hide' : ''">
            <i class="fa-light fa-repeat"></i>
            &nbsp;
            {{ repetition.rep }} Wiederholungen
            </span>
            <span :class="exercise.Type == 'repeat'  ? 'hide' : ''">
            <i class="fa-light fa-stopwatch"></i>
            &nbsp;
            {{ repetition.time }} Sek.
            </span>
          </p>
        </div>

        <div :class="!repetition.weight ? 'hide' : ''">
          <p class="ck-overflow-ellipsis small -strong">
            <i class="fa-light fa-weight-hanging"></i>
            &nbsp;
            {{ repetition.weight }} kg
          </p>
        </div>

        <div class="-customer-wrapper" style="min-height: 300px; border: 0px solid red;">

          <div class="customer-info -hide" style="border: 0px solid aqua;">
            <!-- info -->
             <div class="info-wrapper hide">

              <div :class="!exercise.Option2string ? 'hide' : ''">
                <p class="ck-overflow-ellipsis small">
                  Hilfsmittel:<br />
                  <span style="color: red;">{{ exercise.Option2string }}</span>
                </p>
              </div>

              <div :class="!exercise.Option1string ? 'hide' : ''">
                <p class="ck-overflow-ellipsis small">
                  Muskelgruppen:<br />
                  <span style="color: green;">{{ exercise.Option1string }}</span>
                </p>
              </div>

            </div>
            <!-- end info -->


            <div style="margin-top: 10px;">
            <!--button start -->
              <button 
                :id="'customerstart'+id+exercise.id+'-'+repetition.id" 
                :data-type="exercise.Type" 
                :data-rep="repetition.rep" 
                :data-time="repetition.time" 
                :data-weight="repetition.weight" 
                :class="exercise.Type == 'repeat'  ? 'hide' : ''"
                class="-hide customer-start cx-button -tiny" 
                >
                <i class="fa-light fa-stopwatch"></i> Start 
              </button>
            </div>
            <!-- end button start -->
          </div>

          <div class="customer-progress hide" :id="'customerprogress'+id+exercise.id+'-'+repetition.id">

            <div class="grid-x">
              <div class="auto cell">
              </div>

              <div class="shrink cell">

                <svg width="100px" height="100px" viewBox="0 0 42 42" class="donut">
                    <circle class="c3" :id="'c3'+id+exercise.id+'-'+repetition.id" cx="21" cy="21" r="16" stroke-dasharray="100 0" stroke-dashoffset="100"></circle>
                    <circle class="c1" :id="'c1'+id+exercise.id+'-'+repetition.id" cx="21" cy="21" r="16" stroke-dasharray="100 0" stroke-dashoffset="100"></circle>
                    <circle class="c2" :id="'c2'+id+exercise.id+'-'+repetition.id" cx="21" cy="21" r="16" stroke-dasharray="0 100" stroke-dashoffset="25"></circle>
                    <g class="chart-text">
                        <text class="text" :id="'counterText'+id+exercise.id+'-'+repetition.id" x="50%" y="50%" dominant-baseline="middle" text-anchor="middle">{{repetition.time}}</text>
                    </g>
                </svg>

              </div>
                

              <div class="auto cell">
              </div>

            </div>
            

          </div><!-- customer-progress -->

          <div class="customer-result -hide" :class="exercise.Type == 'time'  ? 'hide' : ''" style="border: 0px solid purple;">

            <div style="margin-top: 10px;">
              <button 
                data-close
                :id="'customersave'+id+exercise.id+'-'+repetition.id" 
                :data-type="exercise.Type" 
                :data-rep="repetition.rep" 
                :data-time="repetition.time" 
                :data-weight="repetition.weight" 
                class="-hide customer-save cx-button -tiny"
                >
                <i class="fa-light fa-floppy-disk"></i> Sichern 
              </button>
            </div>

            <!-- <div class="exercise-done-list" style="border: 1px solid red;">
              <p>show results here</p>
            </div> -->

          </div><!-- customer-result -->

        </div><!-- customer-wrapper -->

    </div>
    <!-- reveal-exercise -->


    </div>
    <!-- /revealContent -->

    </div>
    <!-- v-for -->


    <!-- ADD BUTTON -->
    <div class="grid-x align-middle -hide" style="height: 35px; border-bottom: 1px solid #dedede;">
      <div class="auto cell">
        <p class="ck-overflow-ellipsis small" style="font-size: 10px; padding: 0 5px; color: grey;"> 
          <!-- neue Wiederholung  -->
        </p>
      </div>

      <div class="shrink cell -hide" style="padding: 2px 10px 0 0; font-size: 18px;">
        <span style="display: block; font-size: 10px; line-height: 20px; color: grey;">neu…</span>
      </div>

      <div class="shrink cell -hide" style="padding: 2px 10px 0 0; font-size: 20px;">
        <a @click="addrepetition" class="fa-light fa-circle-plus" :id="'addrepetition'+id+exercise.id" :data-exerciseid="exercise.id"></a>
      </div>
    </div>
    <!-- ADD BUTTON -->
     
    <!-- SAVE BUTTON -->
    <div><input :id="'customerid'+id+exercise.id" :value="this.CustomerId" type="hidden" autocomplete="off" spellcheck="false" class="cx-input-small"></div>
    <div><input :id="'planid'+id+exercise.id" :value="id" type="hidden" autocomplete="off" spellcheck="false" class="cx-input-small"></div>
    <div><input :id="'exerciseid'+id+exercise.id" :value="exercise.id" type="hidden" autocomplete="off" spellcheck="false" class="cx-input-small"></div>
    <div><input :id="'repetitiontype'+id+exercise.id" :value="exercise.Type" type="hidden" autocomplete="off" spellcheck="false" class="cx-input-small"></div>
    <div class="grid-x align-middle hide" style="height: 35px; border-bottom: 1px solid #dedede;">
      <div class="auto cell">
        &nbsp;
      </div>
      <div class="shrink cell" style="padding: 2px 10px 0 0; font-size: 20px;">
        <a @click="saverepetition" class="fa-light fa-floppy-disk" :id="'savebutton'+id+exercise.id"></a>
      </div>
    </div>
    <!-- END SAVE BUTTON -->

  </div>
  <!-- v-for -->

</template>

<script>

import notify from 'devextreme/ui/notify';

import $ from 'jquery';
import Foundation from 'foundation-sites';

import { 
    apihost, 
  } from "../api";

let id;
let CustomerId;
let Gender;
let Subject;
let ExercisesPlanned;
let timestamp;

function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

export default {
  name: 'trainingitem-template',

  props: {
    templateData: Object,
  },

  mounted() {

    this.id = this.templateData.data.id;
    this.CustomerId = this.templateData.data.CustomerId;
    this.Gender = this.templateData.data.Gender;
    this.Subject = this.templateData.data.Subject;
    this.ExercisesPlanned = this.templateData.data.ExercisesPlanned;

    this.reveal = new Foundation.Reveal($('#revealDialogTraining'), {
      // These options can be declarative using the data attributes
      animationIn: 'scale-in-up',
    });

    this.timestamp = this.printTimestamp();
    

  },

  data() {
    return {

      apihost,
      id,
      CustomerId,
      Gender,
      Subject,
      ExercisesPlanned,
      timestamp,
    };
  },

  methods: {

    // Added the below openReveal method for two reasons:
    // 1) There was a bug preventing the reveal from working once
    // you navigated away and back to the reveal component.
    // 2) Most dialogs will need to be opened using code.
    openReveal(id) {
      //console.log(id);
      //console.log(this.id);

      const revealhtml = document.getElementById("revealHtml"+this.id);
      const revealcontent = document.getElementById('revealContent'+this.id+id);
      const revealhtmlcontent = revealcontent.innerHTML;

      //Set Content before open
      revealhtml.innerHTML = revealhtmlcontent;

      //Call Method playrepetition
      const revealdialog = document.getElementById('revealDialogTraining');
      const revealexercise = revealdialog.getElementsByClassName("reveal-exercise")[0];
      if(revealexercise){
        this.playrepetition(this.id+id);
      }

      //Open Reveal
      //console.log(this.reveal);
      this.reveal.open();

    },

    changeField(id) {

      const savebutton = document.getElementById(id);
      savebutton.click();

    },

    addrepetition(e) {

      if (e) {

        const exerciseid = e.target.dataset.exerciseid;
        const targetid = e.target.id;
        const targetrow = targetid.replace('addrepetition', 'row');
        //console.log(exerciseid);
        //console.log(targetid);
        //console.log(targetrow);

        //FIND repetitionid
        let repetitionid = 0;

        for (var i = 0; i < this.ExercisesPlanned.length; i++) {
          if(this.ExercisesPlanned[i].id == exerciseid){
            const thisRepetition = this.ExercisesPlanned[i].Repetition;
            var obj = thisRepetition;
            var repetitions = Object.keys(obj).map((key) => [key, obj[key]]);

            for (var x = 0; x < repetitions.length; x++) {
              if(repetitions[x][1].rep || repetitions[x][1].time){
                repetitionid = repetitionid+1;
              }
            }

          }
        }
        repetitionid = repetitionid+1;
        //console.log(repetitionid);
        //END FIND repetitionid

        //const row = document.getElementById('row307281659'+i);

        if(repetitionid < 11){
          const row = document.getElementById(targetrow+repetitionid);
          //console.log(row);
          row.classList.remove("hide");

        }
        
      }

    },

    saverepetition(e) {
      // `e` is the native DOM event
      if (e) {

        const customerid = document.getElementById(e.target.id.replace('savebutton','customerid')).value;
        const planid = document.getElementById(e.target.id.replace('savebutton','planid')).value;
        const exerciseid = document.getElementById(e.target.id.replace('savebutton','exerciseid')).value;
        const repetitiontype = document.getElementById(e.target.id.replace('savebutton','repetitiontype')).value;

        const repetitionRep = document.getElementsByName("repetitionrep"+planid+exerciseid+"[]");
        const repetitionTime = document.getElementsByName("repetitiontime"+planid+exerciseid+"[]");
        const repetitionWeight = document.getElementsByName("repetitionweight"+planid+exerciseid+"[]");
        // console.log(repetitionRep);
        // console.log(repetitionTime);
        // console.log(repetitionWeight);

        let arrayExerciseitems = [];
    
        let repetitionselectedRep = [];
        let repetitionselectedTime = [];
        let repetitionselectedWeight = [];
    
        for (let i = 0; i < repetitionRep.length; i++) {
          repetitionselectedRep.push(repetitionRep[i].value);
        }
        for (let i = 0; i < repetitionTime.length; i++) {
          repetitionselectedTime.push(repetitionTime[i].value);
        }
        for (let i = 0; i < repetitionWeight.length; i++) {
          repetitionselectedWeight.push(repetitionWeight[i].value);
        }
        const Settingrep = JSON.stringify(repetitionselectedRep);
        const Settingtime = JSON.stringify(repetitionselectedTime);
        const Settingweight = JSON.stringify(repetitionselectedWeight);
        // console.log(Settingrep);
        // console.log(Settingtime);
        // console.log(Settingweight);
    
        //ARRAY PUSH
        arrayExerciseitems.push({
          'type': repetitiontype,        
          'settingrep': Settingrep,        
          'settingtime': Settingtime,        
          'settingweight': Settingweight,        
        });
        //console.log(arrayExerciseitems);

    
        //console.log(arrayExerciseitems);
        const Exerciseitems = encodeURIComponent(JSON.stringify(arrayExerciseitems));
        //console.log(Exerciseitems);        
    
        let objString = '?exerciseid=' + exerciseid;
    
        if(planid){
          objString = objString + '&planid=' + planid;
        } else {
          objString = objString + '&planid=';
        }
        if(customerid){
          objString = objString + '&customerid=' + customerid;
        } else {
          objString = objString + '&customerid=';
        }
        if(repetitiontype){
          objString = objString + '&repetitiontype=' + repetitiontype;
        } else {
          objString = objString + '&repetitiontype=';
        }
        if(Exerciseitems){
          objString = objString + '&Exerciseitems=' + Exerciseitems;
        } else {
          objString = objString + '&Exerciseitems=';
        }
        //console.log(objString);

        fetch(apihost+'/de/vue/exerciseplan/getexerciseplan/'+planid+'/savecustomer'+objString)
          .then(handleErrors)
          .then(response => response.text())
          .then(result => {
          const data = JSON.parse(result);
          //console.log(data);
          this.ExercisesPlanned = data;
        })
        .catch(() => { throw 'Network error' });
    
        notify({
          message: 'Wiederholung wurde gesichert',
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 1000);
    
    
      } // if (e)

    },

    playrepetition() {

      //console.log(id);

      const revealdialog = document.getElementById('revealDialogTraining');
      const revealexercise = revealdialog.getElementsByClassName("reveal-exercise")[0];
      const buttonstart = revealexercise.getElementsByClassName("customer-start")[0];
      const buttonsave = revealexercise.getElementsByClassName("customer-save")[0];

      const info = revealexercise.getElementsByClassName("customer-info")[0];
      const progress = revealexercise.getElementsByClassName("customer-progress")[0];
      const result = revealexercise.getElementsByClassName("customer-result")[0];

      // console.log(info);
      // console.log(progress);
      // console.log(result);

      //console.log(revealdialog);
      //console.log(revealexercise);
      //console.log(buttonstart);
      //console.log(buttonsave);

      buttonstart.onclick = function() {
        //console.log(this);
        // console.log(this.id);
        //console.log("button was clicked");

        this.classList.add('hide');
        info.classList.add('hide');
        progress.classList.remove('hide');

        //result.classList.add('hide');

        const revealdialog = document.getElementById('revealDialogTraining');
        const revealexercise = revealdialog.getElementsByClassName("reveal-exercise")[0];
        //const time = revealexercise.getAttribute('data-time');

        let time = 0;
        if(revealexercise.getAttribute('data-time')){
          time = revealexercise.getAttribute('data-time');
        }
        const getc1 = revealdialog.getElementsByClassName("c1")[0];
        const getc2 = revealdialog.getElementsByClassName("c2")[0];
        const getcountertext = revealdialog.getElementsByClassName("text")[0];


        var duration = parseInt(time); 

        var timeout = setTimeout(function () {
        //var time = duration;
        var i = 1;
        var k = ((i/duration) * 100);
        var l = 100 - k;
        i++;

        getc1.style.strokeDasharray = [l,k];
        getc2.style.strokeDasharray = [k,l];
        getc1.style.strokeDashoffset = l;


        //duration = '';

        getcountertext.innerHTML = duration;

        var interval = setInterval(function() {
            if (i > time) {
                clearInterval(interval);
                clearTimeout(timeout);
                return;
            }
            k = ((i/duration) * 100);
            l = 100 - k;

            //console.log(k, l);
            //console.log(parseInt(k), parseInt(l));

            getc1.style.strokeDasharray = [l,k];
            getc2.style.strokeDasharray = [k,l];
            getc1.style.strokeDashoffset = l;
            

            getcountertext.innerHTML = parseInt((duration +1)-i);
            
            if(l == 0){
              getcountertext.innerHTML = 0;
              buttonsave.classList.remove('hide');
              progress.classList.add('hide');
              result.classList.remove('hide');
            }
            
          i++;
          }, 1000);

        },0);
            
      } //buttonstart.onclick


      buttonsave.onclick = function() {
        //console.log(this)

        const revealdialog = document.getElementById('revealDialogTraining');
        const revealexercise = revealdialog.getElementsByClassName("reveal-exercise")[0];

        //console.log('do save exercises in Class Customer')

        let date = Date.now();
        let customerid = '';
        let planid = '';
        let exerciseid = '';
        let type = '';
        let time = '';
        let rep = '';
        let weight = '';
        
        
        if(revealexercise.getAttribute('data-customerid')){
          customerid = revealexercise.getAttribute('data-customerid');
        }
        if(revealexercise.getAttribute('data-planid')){
          planid = revealexercise.getAttribute('data-planid');
        }
        if(revealexercise.getAttribute('data-exerciseid')){
          exerciseid = revealexercise.getAttribute('data-exerciseid');
        }
        if(revealexercise.getAttribute('data-type')){
          type = revealexercise.getAttribute('data-type');
        }
        if(revealexercise.getAttribute('data-time')){
          time = revealexercise.getAttribute('data-time');
        }
        if(revealexercise.getAttribute('data-rep')){
          rep = revealexercise.getAttribute('data-rep');
        }
        if(revealexercise.getAttribute('data-weight')){
          weight = revealexercise.getAttribute('data-weight');
        }

        let objString = '?date=' + date;

        if(planid){
          objString = objString + '&planid=' + planid;
        } else {
          objString = objString + '&planid=';
        }
    
        if(exerciseid){
          objString = objString + '&exerciseid=' + exerciseid;
        } else {
          objString = objString + '&exerciseid=';
        }

        if(type){
          objString = objString + '&type=' + type;
        } else {
          objString = objString + '&type=';
        }
    
        if(time){
          objString = objString + '&time=' + time;
        } else {
          objString = objString + '&time=';
        }
    
        if(rep){
          objString = objString + '&rep=' + rep;
        } else {
          objString = objString + '&rep=';
        }
    
        if(weight){
          objString = objString + '&weight=' + weight;
        } else {
          objString = objString + '&weight=';
        }
    
        //console.log(objString);

        fetch(apihost+'/de/vue/member/getmember/'+customerid+'/exercisedone'+objString)
          .then(handleErrors)
          .then(response => response.text())
          .then(result => {
          const data = JSON.parse(result);
          //console.log(data.Id);
          //console.log(data.Exercisedone);
          this.customerExercises = data.Exercisedone;
        })
        .catch(() => { throw 'Network error' });

        notify({
          message: 'Training gesichert',
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);
        
      } //buttonsave.onclick


    //playrepetition
    },

    printTimestamp: function () {
      return Date.now();
    },

  },
  unmounted() {
    //this.reveal.destroy();
  },

};
</script>
