<template>

<div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block">


  <div class="grid-x grid-margin-x align-middle" style="margin-bottom: 15px;">
    <div class="shrink cell">
      <h2>
        <i class="dx-icon nav-icon fa-light fa-calendar-day"></i> 
        {{ title }}
      </h2>
    </div><!-- shrink cell -->

    <div class="auto cell">
      <p class="hide">man in the middle</p>
    </div><!-- auto cell -->

    <div class="shrink cell" style="width: 200px;">
      <DxSelectBox
        :items="typeData"
        displayExpr="text"
        valueExpr="id"
        :input-attr="{ 'aria-label': 'Typ' }"
        :show-clear-button="true"
        placeholder="Typ"
        id="filterType"
        @value-changed="onTypeChanged"

      />
    </div><!-- shrink cell -->

    <div class="shrink cell" style="width: 200px;">
      <DxSelectBox
        :items="locationData"
        displayExpr="locationname"
        valueExpr="locationid"
        :input-attr="{ 'aria-label': 'Standort' }"
        :show-clear-button="true"
        placeholder="Standort"
        id="filterLocation"
        @value-changed="onLocationChanged"
      />
    </div><!-- shrink cell -->



  </div><!-- grid-x -->

  
  <div class="dx-card no-paddings dx-card-has-minheight">

    <DxScheduler
      time-zone="Europe/Berlin"
      current-view="week"
      :data-source="SchedulerItems"
      :current-date="currentDate"
      :views="views"
      :start-day-hour="6"
      :end-day-hour="24"
      :cell-duration="60"
      :show-all-day-panel="false"
      :first-day-of-week="1"
      :on-appointment-added="showAddedToast"
      :on-appointment-updated="showUpdatedToast"
      :on-appointment-deleted="showDeletedToast"

      @appointment-updated="onAppointmentUpdated"
      @appointment-form-opening="onAppointmentFormOpening"

      appointment-template="AppointmentTemplateSlot"
      appointment-tooltip-template="AppointmentTooltipTemplateSlot"
    >

    <template #AppointmentTemplateSlot="{ data }">
      <AppointmentTemplate
        :template-model="data"
      />
    </template>


    <template #AppointmentTooltipTemplateSlot="{ data }">
      <AppointmentTooltipTemplate
        :template-model="data"
      />
    </template>

  </DxScheduler>

  </div><!-- dx-card -->

  </div><!-- content-block -->

  <div v-else class="content-block">
    Oh no 😢
  </div><!-- content-block -->

</template>
  
<script>
  
  //import { ref } from 'vue';
  import DxScheduler from 'devextreme-vue/scheduler';
  import DxSelectBox from 'devextreme-vue/select-box';

  import AppointmentTemplate from '../parts/appointment-template.vue';
  import AppointmentTooltipTemplate from '../parts/appointment-tooltip-template.vue';


  import notify from 'devextreme/ui/notify';
  import auth from "../auth";
  import { 
    apihost, 
  } from "../api";
  //console.log(apihost);

  let currentUser;
  let SchedulerItems;
  let typeData;
  let locationData;
  let courseData;
  let trainerData;

  //const views = ['day','week','workWeek','month'];
  const views = ['day','week','month'];

  const currentDate = new Date();

  function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
  }

  auth.getUser().then((e) => {
    if(e.data){
      currentUser = e.data;
      //locale(currentUser.Language);
      //console.log(currentUser);
    } // e.data
  }); //auth

  export default {
  
    mounted() {

      //redirect to Member App
      if(currentUser.Role == 'Customer'){
        const currentUrl = window.location.href;
        const redirecturl = currentUrl.replace("/#/sap-scheduler", "/#/dashboard");
        window.location.href = redirecturl;
      }

      //Menu Type
      fetch(apihost+'/'+currentUser.Language+'/vue/scheduler/getschedulertype/id/action')
        .then(handleErrors)
        .then(response => response.text())
        .then(result => {
        const data = JSON.parse(result);
        //console.log(data);
        this.typeData = data;
        typeData = data;
      })
      .catch(() => { throw 'Network error' });


      //Menu Location
      this.locationData = currentUser.Location;
      locationData = currentUser.Location;

      //Menu Course
      fetch(apihost+'/'+currentUser.Language+'/vue/course/list/')
        .then(handleErrors)
        .then(response => response.text())
        .then(result => {
        const data = JSON.parse(result);
        //console.log(data);
        this.courseData = data;
        courseData = data;
      })
      .catch(() => { throw 'Network error' });

      //Menu Trainer
      fetch(apihost+'/'+currentUser.Language+'/vue/member/list/?case=employee&status=active')
        .then(handleErrors)
        .then(response => response.text())
        .then(result => {
        const data = JSON.parse(result);
        //console.log(data);
        this.trainerData = data;
        trainerData = data;
      })
      .catch(() => { throw 'Network error' });

      //Scheduler
      fetch(apihost+'/'+currentUser.Language+'/vue/scheduler/getscheduler/id/action')
        .then(handleErrors)
        .then(response => response.text())
        .then(result => {
        const data = JSON.parse(result);
        //console.log(data);
        this.SchedulerItems = data;
      })
      .catch(() => { throw 'Network error' });

      //timestamp
      this.timestamp = this.printTimestamp();
  
    },
  
    components: {
      DxScheduler,
      DxSelectBox,
      AppointmentTemplate,
      AppointmentTooltipTemplate,
      
    },
    data() {
      return {
        apihost,
        views,
        //title: currentUser.Translation.vueappNavMemberExercises,
        title: 'Terminplan',
        currentUser,
        SchedulerItems,
        currentDate,
        typeData,
        locationData,
      };
    },
    methods: {

      onTypeChanged(e) {
        //console.log(`The type has changed to: "${e.value}"`);

        const filterType = e.value;
        const filterLocation = document.getElementById("filterLocation").getElementsByTagName("input")[0].value;
        //console.log(filterType);
        //console.log(filterLocation);

        let objString = '?' + 'text=filter';

        if(filterType){
          objString = objString + '&filtertype=' + filterType;
        } else {
          objString = objString + '&filtertype=';
        }
        if(filterLocation){
          objString = objString + '&filterlocation=' + filterLocation;
        } else {
          objString = objString + '&filterlocation=';
        }

        //console.log(objString);


        //Scheduler
        fetch(apihost+'/'+currentUser.Language+'/vue/scheduler/getscheduler/id/action'+objString)
          .then(handleErrors)
          .then(response => response.text())
          .then(result => {
          const data = JSON.parse(result);
          //console.log(data);
          this.SchedulerItems = data;
        })
        .catch(() => { throw 'Network error' });

      },

      onLocationChanged(e) {
        //console.log(`The location has changed to: "${e.value}"`);

        const filterType = document.getElementById("filterType").getElementsByTagName("input")[0].value;
        const filterLocation = e.value;
        //console.log(filterType);
        //console.log(filterLocation);

        let objString = '?' + 'text=filter';

        if(filterType){
          objString = objString + '&filtertype=' + filterType;
        } else {
          objString = objString + '&filtertype=';
        }
        if(filterLocation){
          objString = objString + '&filterlocation=' + filterLocation;
        } else {
          objString = objString + '&filterlocation=';
        }

        //console.log(objString);

        //Scheduler
        fetch(apihost+'/'+currentUser.Language+'/vue/scheduler/getscheduler/id/action'+objString)
          .then(handleErrors)
          .then(response => response.text())
          .then(result => {
          const data = JSON.parse(result);
          console.log(data);
          this.SchedulerItems = data;
        })
        .catch(() => { throw 'Network error' });
        
      },

      showToast(event, data) {

        const filterType = document.getElementById("filterType").getElementsByTagName("input")[0].value;
        const filterLocation = document.getElementById("filterLocation").getElementsByTagName("input")[0].value;

        const startDate = new Date(data.startDate).toISOString();
        const endDate = new Date(data.endDate).toISOString();

        // console.log(startDate);
        // console.log(endDate);

        let objString = '?' + 'text=' + data.text;

        //Filter
        if(filterType){
          objString = objString + '&filtertype=' + filterType;
        } else {
          objString = objString + '&filtertype=';
        }
        if(filterLocation){
          objString = objString + '&filterlocation=' + filterLocation;
        } else {
          objString = objString + '&filterlocation=';
        }

        //Data
        if(data.typeid){
          objString = objString + '&typeid=' + data.typeid;
        } else {
          objString = objString + '&typeid=';
        }

        if(data.courseid){
          objString = objString + '&courseid=' + data.courseid;
        } else {
          objString = objString + '&courseid=';
        }

        if(data.locationid){
          objString = objString + '&locationid=' + data.locationid;
        } else {
          objString = objString + '&locationid=';
        }

        if(data.trainerid){
          objString = objString + '&trainerid=' + data.trainerid;
        } else {
          objString = objString + '&trainerid=';
        }

        if(data.startDate){
          objString = objString + '&startDate=' + startDate;
        } else {
          objString = objString + '&startDate=';
        }

        if(data.endDate){
          objString = objString + '&endDate=' + endDate;
        } else {
          objString = objString + '&endDate=';
        }

        if(data.recurrenceRule){
          objString = objString + '&recurrenceRule=' + data.recurrenceRule;
        } else {
          objString = objString + '&recurrenceRule=';
        }

        if(data.eventType){
          objString = objString + '&eventType=' + data.eventType;
        } else {
          objString = objString + '&eventType=';
        }

        if(data.locationId){
          objString = objString + '&locationId=' + data.locationId;
        } else {
          objString = objString + '&locationId=';
        }
        if(data.description){
          objString = objString + '&description=' + data.description;
        } else {
          objString = objString + '&description=';
        }

        //console.log(objString);

        fetch(apihost+'/'+currentUser.Language+'/vue/scheduler/getscheduler/'+data.id+'/'+event+objString)
          .then(handleErrors)
          .then(response => response.text())
          .then(result => {
          const data = JSON.parse(result);
          //console.log(data);
          this.SchedulerItems = data;

        })
        .catch(() => { throw 'Network error' });

        notify({
          message: data.text + ' gesichert',
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);


      },
        

      // onAppointmentUpdating(e) {
      //   console.log(e);
      //   //console.log(e.oldData);
      //   //console.log(e.newData);
      // },

      onAppointmentUpdated(e) {
        // Handler of the "appointmentUpdated" event
        //console.log(e);
        //console.log(e.appointmentData);
        //console.log(e.appointmentData.id);
        this.showToast('Updated', e.appointmentData);

      },

      onAppointmentFormOpening(e) {

        const form = e.form;

        form.option('items', [

        //Typ
        {
          colSpan: 1,
          label: {
            text: 'Typ',
          },
          name: 'typeid',
          dataField: 'typeid',
          editorType: 'dxSelectBox',
          editorOptions: {
            items: typeData,
            displayExpr: 'text',
            valueExpr: 'id',
            readOnly: false,
          },
        },     
        
        //Standort
        {
          colSpan: 1,
          label: {
            text: 'Standort',
          },
          name: 'locationid',
          dataField: 'locationid',
          editorType: 'dxSelectBox',
          editorOptions: {
            items: locationData,
            displayExpr: 'locationname',
            valueExpr: 'locationid',
            readOnly: false,
          },
        },   

        //Kurs
        {
          colSpan: 1,
          label: {
            text: 'Kurs',
          },
          name: 'courseid',
          dataField: 'courseid',
          editorType: 'dxSelectBox',
          editorOptions: {
            items: courseData,
            displayExpr: 'Subject',
            valueExpr: 'id',
            readOnly: false,
          },
        },     
        
        //Trainer
        {
          colSpan: 1,
          label: {
            text: 'Trainer',
          },
          name: 'trainerid',
          dataField: 'trainerid',
          editorType: 'dxSelectBox',
          editorOptions: {
            items: trainerData,
            displayExpr: 'FirstName',
            valueExpr: 'id',
            readOnly: false,
          },
        },     
             
        //Start
        {
          colSpan: 1,
          label: {
            text: 'Start',
          },
          name: 'startDate',
          dataField: 'startDate',
          editorType: 'dxDateBox',
          editorOptions: {
            type: 'datetime',
            readOnly: false,
          },
        },     

        //Ende
        {
          colSpan: 1,
          label: {
            text: 'Ende',
          },
          name: 'endDate',
          dataField: 'endDate',
          editorType: 'dxDateBox',
          editorOptions: {
            type: 'datetime',
            readOnly: false,
          },
        },     

        //Text
        {
          colSpan: 2,
          label: {
            text: 'Text',
          },
          name: 'text',
          dataField: 'text',
          editorType: 'dxTextBox',
          editorOptions: {
            readOnly: false,
          },
        }, 

        //Beschreibung
        {
          colSpan: 2,
          label: {
            text: 'Beschreibung',
          },
          name: 'description',
          dataField: 'description',
          editorType: 'dxTextBox',
          editorOptions: {
            readOnly: false,
          },
        }, 
        
      
      ]);


      },

      showAddedToast(e) {
        //showToast('Added', e.appointmentData.text, 'success');
        //console.log(e);
        //console.log(e.appointmentData);
        this.showToast('Added', e.appointmentData);
      },

      showUpdatedToast(e) {
        //showToast('Updated', e.appointmentData.text, 'info');
        //console.log(e);
        //console.log(e.appointmentData);
        this.showToast('Updated', e.appointmentData);
      },

      showDeletedToast(e) {
        //showToast('Deleted', e.appointmentData.text, 'warning');
        //console.log(e);
        //console.log(e.appointmentData);
        this.showToast('Deleted', e.appointmentData);
      },


      onShiftChanged(e) {
        console.log(e);
      },


      customizeDateNavigatorText(e) {
          const formatOptions = { 
              year: 'numeric', 
              month: 'numeric', 
              day: 'numeric' 
          };
          const formattedStartDate = e.startDate.toLocaleString('en', formatOptions);
          const formattedEndDate = e.endDate.toLocaleString('en', formatOptions);
          if(this.currentView === 'day' | 'timelineDay') 
              return formattedStartDate;
          if(this.currentView === 'month' ) 
              return e.startDate.toLocaleString('en', { year: 'numeric', month: 'numeric' });
          return formattedStartDate + ' - ' + formattedEndDate;
      },

      printTimestamp: function () {
          return Date.now();
      },
  
    },
  };
  
</script>

<style>

</style>
