<template>
<svg version="1.1" id="FitX" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 504.9 213.8" style="enable-background:new 0 0 504.9 213.8;" xml:space="preserve">
<path class="st0" d="M65.1,0c0,0.4-0.1,0.8-0.1,1.3c0,5.1,0,10.2,0,15.2c0,1-0.3,1.3-1.3,1.3c-7.9,0-15.7,0-23.6,0
c-2.3,0-4.4,0.7-5.6,2.9C34,21.8,34,23,33.9,24.1c-0.1,0.6-0.1,1.3-0.2,1.9c-0.1,1.2,0.1,1.3,1.3,1.3c9.5,0,19,0,28.5,0
c1.5,0,1.5,0,1.5,1.6c0,4.9,0,9.8,0,14.7c0,1.4,0,1.4-1.5,1.4c-9.4,0-18.9,0-28.3,0c-1.4,0-1.4,0-1.4,1.4c0,17.9,0,35.8,0,53.7
c0,1.3,0,1.4-1.4,1.4c-5.7,0-11.4,0-17.1,0c-1,0-1.3-0.3-1.2-1.3c0-18,0-36,0.1-54.1c0-1-0.3-1.2-1.2-1.2c-3.9,0-7.8,0-11.7,0
c-1,0-1.3-0.3-1.3-1.3c0.3-4.8,0.1-9.7,0.1-14.5c0-0.2,0-0.5,0-0.7c-0.1-0.9,0.3-1.2,1.2-1.2c3.4,0.1,6.8,0,10.2,0
c2.6,0,2.6,0,2.7-2.6c0.2-5.2,1.3-10.1,4.2-14.5c3.3-5,8-8,13.8-9.3c0.8-0.2,1.6-0.1,2.4-0.2c0.6,0,1-0.2,1.2-0.8
C45.6,0,55.4,0,65.1,0z"/>
<path class="st0" d="M92.7,0c-0.2,1.1,0.1,2.2,0.1,3.3c0,5.4,0,10.7,0,16.1c0,1.4-0.1,1.5-1.5,1.5c-5.6,0-11.2,0-16.8,0
c-1.5,0-1.5,0-1.5-1.6c0-6.2,0-12.4,0-18.5c0-0.2-0.1-0.5-0.1-0.7C79.6,0,86.2,0,92.7,0z"/>
<path class="st0" d="M156.2,27.4c6.9,0,13.8,0,20.6,0c1,0,1.4,0.3,1.3,1.3c0,5,0,10.1,0,15.1c0,1.1-0.3,1.3-1.4,1.3
c-13.7,0-27.4,0-41.2,0c-1,0-1.4,0.2-1.4,1.3c0.1,9.7,0,19.4,0,29.1c0,2.3,0.5,4.6,1.9,6.5c1.6,2.1,4.1,2.5,6.5,2.7
c4.1,0.4,8.1,0.2,12.2,0c1.4-0.1,2.8-0.4,4.1-0.9c1.5-0.6,2.3-1.8,2.8-3.3c0.3-1.2,0.5-2.4,0.5-3.6c0-4.2,0-8.4,0-12.6
c0-0.8,0.2-1.1,1.1-1.1c5.2,0,10.3,0,15.5,0c0.9,0,1.2,0.3,1.2,1.2c0,4,0.1,8-0.1,12c-0.1,3.7-0.3,7.5-1.3,11.2
c-1.6,5.5-5,9.5-10.2,11.9c-3.6,1.6-7.4,2.3-11.2,2.8c-4.5,0.6-9.1,0.6-13.6,0.4c-5.6-0.2-11.1-1-16.3-3.3
c-7.1-3.3-11-8.9-12.3-16.4c-0.4-2.5-0.4-5-0.4-7.5c-0.1-8,0-16.1,0-24.1c0-1.7,0-3.3,0.1-5c0.1-1-0.3-1.2-1.2-1.2
c-4.1,0-8.1,0-12.2,0c-1,0-1.3-0.3-1.3-1.3c0.1-5-0.1-10.1,0.1-15.1c0-1,0.3-1.4,1.3-1.3c4,0.1,8,0,12,0c1.1,0,1.4-0.3,1.3-1.3
c-0.4-4.3-0.2-8.5-0.2-12.8c0-1.4,0.1-1.5,1.4-1.5c5.7,0,11.3,0,17,0c1,0,1.3,0.3,1.3,1.3c0,4.4,0,8.7,0,13.1c0,1,0.3,1.2,1.3,1.2
C142.4,27.4,149.3,27.4,156.2,27.4z"/>
<path class="st0" d="M189.4,27.6c0.4-0.3,0.8-0.1,1.2-0.1c8.2,0,16.3,0,24.5,0c1.2,0,2,0.3,2.8,1.2c6.4,6.5,12.8,13,19.2,19.5
c0.2,0.3,0.5,0.5,0.8,0.8c0.6,0.7,1.1,0.7,1.7,0c6.6-6.7,13.3-13.5,19.9-20.2c0.8-0.8,1.6-1.2,2.7-1.2c8.4,0,16.8,0,25.2,0
c0.2,0,0.4-0.1,0.7,0.3c-1.5,1.5-3.1,3-4.6,4.5c-5.6,5.5-11.3,11-17,16.5c-4.2,4.1-8.4,8.2-12.6,12.3c-1.4,1.4-1.4,1.5,0.1,2.7
c1.4,1.2,2.7,2.5,3.9,3.7c4.2,4.1,8.3,8.3,12.6,12.3c7.1,6.9,14.3,13.8,21.4,20.7c0.3,0.3,0.8,0.5,0.6,0.9c-0.2,0.4-0.7,0.2-1.1,0.2
c-8.6,0-17.2,0-25.8,0c-0.7,0-1.2-0.2-1.7-0.7c-5.7-5.8-11.6-11.3-17-17.4c-2.4-2.7-5.1-5.1-7.6-7.7c-0.5-0.5-0.9-0.7-1.6-0.1
c-6.8,6.9-13.6,13.7-20.3,20.5c-1.5,1.5-2.9,2.9-4.3,4.4c-0.5,0.6-1.1,0.8-1.9,0.8c-8.5,0-17,0-25.5,0c-0.3,0-0.8,0.1-0.9-0.3
c-0.1-0.4,0.2-0.6,0.4-0.8c6.5-6.2,12.8-12.5,19.3-18.8c5.7-5.5,11.4-10.9,17-16.5c0.7-0.7,1.4-1.2,2.1-1.9c1-0.9,1-1.1,0-2
c-4.2-4.1-8.4-8.3-12.6-12.4c-7.2-7-14.3-14.1-21.5-21.1C189.5,27.8,189.5,27.7,189.4,27.6z"/>
<path class="st1" d="M464.3,65.7c-13.1,0-26.2,0-39.3,0c-1,0-1.3,0.3-1.2,1.3c0.1,3.4-0.1,6.9,0.6,10.3c1.4,7.5,6,11.8,13.3,13.4
c5.2,1.2,10.6,1.5,15.9,1.4c6.1-0.1,12.2-0.1,18.3,0c4.2,0,8.4-0.1,12.5-1.2c5.9-1.6,8.4-4.9,9.2-10.9c0.1-0.8,0.4-1,1.1-1
c3,0,6,0,9,0c1,0,1.3,0.3,1.3,1.3c-0.2,9-4.7,15-13.7,17.9c-6.2,2-12.6,2.6-19.1,2.7c-9.2,0.1-18.3,0-27.5,0
c-4.9,0-9.8-0.4-14.6-1.8c-9.1-2.7-14.5-8.8-16.5-18c-0.6-2.9-0.8-5.8-0.8-8.7c0-6.3,0-12.7,0-19c0-4,0.8-7.9,2.4-11.6
c2.9-6.8,8.2-10.6,15-12.7c4.4-1.3,8.9-1.8,13.5-1.8c10.3-0.1,20.7-0.2,31,0.1c4.7,0.2,9.3,1,13.8,2.6c8.1,3,13,8.8,15.1,17.1
c1.1,4.3,1.2,8.6,1.2,13c0,1.5,0,3.1,0,4.6c0,0.9-0.3,1.1-1.2,1.1C490.5,65.7,477.4,65.7,464.3,65.7z M458.7,56.8
C458.7,56.8,458.7,56.8,458.7,56.8c10.7,0,21.4,0,32.1,0c0.7,0,1.4,0,2.1,0c0.3,0,0.7,0,0.7-0.5c-0.1-2.5,0.3-5.1-0.1-7.6
c-0.8-4.9-4.1-8-8.5-9.9c-5.1-2.3-10.6-2.9-16.2-2.9c-7.4-0.1-14.7-0.1-22.1,0c-3.5,0-7,0.3-10.4,1c-5.6,1.1-9.4,4.1-10.9,9.8
c-0.8,2.9-1.1,5.8-1.4,8.7c-0.1,1.4-0.1,1.4,1.2,1.4C436.2,56.8,447.5,56.8,458.7,56.8z"/>
<path class="st1" d="M301.4,76.6c0-15.7,0-31.4,0-47c0-1.5-0.3-1.4,1.4-1.4c2.6,0,5.2,0,7.8,0c0.8,0,1.2,0.2,1.2,1.1
c0,3.3,0,6.5,0,9.8c0,0.2,0,0.5,0,0.7c0,0.3,0.1,0.5,0.4,0.6c0.2,0.1,0.4-0.2,0.5-0.3c0.2-0.2,0.3-0.5,0.4-0.7
c2.5-5.8,7.2-8.9,13.1-10.7c3.8-1.2,7.7-1.4,11.6-1.4c9.4,0,18.7-0.2,28.1,0.2c4.8,0.2,9.4,1.3,13.7,3.3c7,3.3,11,9,12.7,16.4
c0.7,3.1,1.1,6.2,1.1,9.4c0,6.1,0.2,12.2-0.2,18.3c-0.2,3.5-0.8,7-2,10.3c-2.5,6.9-7.4,11.2-14.4,13.5c-5.2,1.7-10.6,2.2-16,2.2
c-9.2,0-18.3,0-27.5,0c-3.7,0-7.2-0.3-10.7-1.5c-3.6-1.2-6.3-3.4-7.9-6.9c-0.3-0.7-0.7-1.5-1.1-2.2c-0.1-0.2-0.2-0.5-0.6-0.4
c-0.2,0.1-0.3,0.3-0.3,0.5c-0.1,0.5-0.1,0.9-0.1,1.4c0,10.6,0,21.3,0,31.9c0,1.6,0,1.6-1.6,1.6c-2.9,0-5.7,0-8.6,0
c-0.9,0-1.2-0.2-1.2-1.1C301.4,108.2,301.4,92.4,301.4,76.6C301.4,76.6,301.4,76.6,301.4,76.6z M348.4,35.9c-4.8,0-9.1,0-13.4,0
c-2.5,0-5,0.2-7.4,0.8c-6,1.4-10.6,4.5-13.1,10.4c-1.3,3.1-1.8,6.3-1.8,9.6c0,5.3,0,10.6,0,15.9c0,1.6,0.2,3.2,0.5,4.8
c1.3,7.1,5.4,11.7,12.5,13.6c3.4,0.9,6.9,1.2,10.4,1.2c7-0.1,14-0.1,21,0c3.5,0,6.9,0,10.3-0.5c4.5-0.7,8.6-2.3,11.5-6.1
c2.3-3,3.1-6.5,3.4-10.2c0.4-4.7,0-9.4,0.1-14.2c0.1-3.8,0.2-7.6-0.5-11.4c-1-5.3-3.6-9.5-8.7-11.7c-2.9-1.2-6-1.8-9.2-2
C358.5,35.7,353.2,36,348.4,35.9z"/>
<path class="st1" d="M167,181.7c-11.7,0-23.4,0-35.2,0c-1.4,0-1.4,0-1.3,1.4c0.1,3-0.2,6,0.3,8.9c1.2,6.8,5.3,10.7,11.9,12.2
c4,0.9,8,1.2,12.1,1.3c6.7,0.1,13.5,0.1,20.2,0c3.2,0,6.5-0.2,9.6-1c5.3-1.4,7.9-4.2,8.5-9.6c0.1-1.1,0.5-1.4,1.6-1.3
c2.5,0.1,5,0.1,7.5,0c1,0,1.2,0.3,1.2,1.3c-0.1,8-4,13.2-12,16c-5.1,1.8-10.5,2.3-15.9,2.5c-8.3,0.4-16.7,0.3-25,0.2
c-4.7-0.1-9.5-0.3-14.1-1.6c-9-2.5-14.1-8.5-15.6-17.6c-0.5-2.7-0.6-5.4-0.6-8.2c0-4.8,0-9.6,0-14.4c0-3.5,0.6-6.9,1.7-10.3
c2.4-7,7.6-11,14.5-12.9c4.8-1.3,9.8-1.6,14.7-1.7c6.5-0.1,13-0.1,19.5,0c5.7,0.1,11.5,0.4,17,2.3c8.8,3,13.6,9.3,15.1,18.3
c0.7,4.4,0.6,8.9,0.6,13.4c0,0.9-0.5,0.8-1,0.8c-6.4,0-12.8,0-19.2,0C177.8,181.7,172.4,181.7,167,181.7z M161.7,173.7
C161.7,173.6,161.7,173.6,161.7,173.7c9.8,0,19.7,0,29.5,0c2,0,2,0,2.1-2c0.2-2.3,0.2-4.6-0.6-6.7c-1.4-3.7-4.2-6-7.7-7.5
c-4.6-1.9-9.5-2.4-14.4-2.4c-6.3,0-12.6,0-18.9,0c-3.4,0-6.7,0.2-10,0.9c-5.4,1.1-8.9,4.1-10.1,9.5c-0.6,2.5-0.9,5-1.1,7.5
c0,0.6,0.2,0.8,0.8,0.8C141.4,173.6,151.5,173.7,161.7,173.7z"/>
<path class="st1" d="M468.3,181.2c-11.5,0-23.1,0-34.6,0c-1.7,0-1.7,0-1.7,1.8c0.1,2.9-0.3,5.9,0.4,8.8c1.3,6.3,5.1,10.3,11.4,11.9
c3.4,0.9,6.9,1.3,10.4,1.3c8,0,16,0,24,0c2.6,0,5.2-0.3,7.7-0.9c5.4-1.3,7.9-4.1,8.7-9.6c0.1-1,0.5-1.3,1.5-1.3c2.4,0.1,4.8,0,7.2,0
c1.6,0,1.7,0,1.6,1.7c-0.4,8.1-4.5,13.2-12.7,15.8c-5.6,1.7-11.4,2.3-17.2,2.3c-8.5,0.1-17,0.1-25.4,0c-4.8,0-9.6-0.6-14.1-2.4
c-7.7-3-11.7-9-13-16.9c-0.5-3-0.7-5.9-0.6-8.9c0.1-5.3-0.2-10.7,0.2-16c0.5-6.2,2.2-11.9,7.1-16.1c3.3-2.9,7.2-4.3,11.4-5.1
c4.1-0.8,8.2-1,12.3-1c8.2,0,16.5-0.2,24.7,0.2c4,0.2,8,0.8,11.8,2.1c7.5,2.5,12.2,7.7,14.2,15.3c0.7,2.7,1.1,5.5,1.2,8.3
c0.1,2.4,0.1,4.8,0.1,7.1c0,2.1,0,1.7-1.7,1.7C491.5,181.3,479.9,181.3,468.3,181.2C468.3,181.3,468.3,181.3,468.3,181.2z
M463.3,173.2c9.2,0,18.4,0,27.7,0c1,0,2,0,3,0c0.4,0,0.7-0.1,0.7-0.6c-0.1-2.2,0.3-4.5-0.2-6.7c-0.8-4.1-3.5-6.8-7.2-8.5
c-4.8-2.3-10-2.8-15.2-2.9c-6.6-0.1-13.1-0.1-19.7,0c-3.3,0-6.5,0.3-9.8,1c-4.9,1.1-8.3,3.8-9.6,8.8c-0.7,2.6-1.1,5.2-1.2,7.9
c0,0.9,0.2,1.1,1.1,1.1C443.1,173.2,453.2,173.2,463.3,173.2z"/>
<path class="st1" d="M363.3,213.7c-5.2-0.1-10.3,0.1-15.5-0.2c-4-0.3-7.9-1-11.6-2.7c-6.8-3.2-10.5-8.7-11.9-15.9
c-0.5-2.5-0.6-4.9-0.6-7.4c0-5.5,0-10.9,0-16.4c0-3.3,0.6-6.4,1.7-9.5c2.4-6.5,7.1-10.4,13.6-12.4c3.9-1.2,7.9-1.7,12-1.9
c8.1-0.4,16.2-0.2,24.4-0.2c4.7,0,9.5,0.5,14,2c8.7,2.9,13.4,9.4,13.4,18.5c0,2.6,0,2.6-2.6,2.6c-1.8,0-3.6,0-5.4,0
c-0.8,0-1.1-0.2-1.1-1c0-1.6-0.2-3.1-0.6-4.6c-1.1-4.5-4-7.2-8.3-8.4c-2.3-0.7-4.7-1-7.2-1.1c-8.7,0-17.4,0-26.1,0
c-2.3,0-4.6,0.3-6.9,0.9c-6.2,1.7-9.4,5.9-10.5,12c-0.5,3-0.6,5.9-0.6,8.9c0.1,4.5-0.2,9,0.3,13.5c0.9,8.4,5.5,13.2,13.8,14.6
c2.5,0.4,5.1,0.6,7.7,0.6c7,0,14,0,20.9,0c3.2,0,6.4-0.4,9.5-1.5c5.5-1.9,8.6-6,8.9-11.8c0-0.6,0.1-1.2,0-1.8c0-0.7,0.2-1,1-1
c2.5,0.1,5,0,7.5,0c0.6,0,0.9,0.2,0.9,0.8c-0.1,3.6-0.3,7.2-1.5,10.6c-2,5.8-6.2,9-11.8,10.7c-3.7,1.2-7.6,1.4-11.5,1.7
C374,213.9,368.7,213.6,363.3,213.7z"/>
<path class="st1" d="M223.3,180.7c0-10.4,0-20.8,0-31.1c0-0.9,0.2-1.1,1.1-1.1c2.4,0.1,4.8,0.1,7.3,0c0.8,0,1.1,0.2,1.1,1
c-0.1,3.1,0,6.3,0,9.4c0,0.4-0.1,0.9,0.4,1.1c0.6,0.1,0.7-0.3,0.9-0.7c2.8-6.8,8.3-9.9,15.2-10.9c3.2-0.5,6.4-0.7,9.7-0.7
c6.2,0,12.3,0,18.5,0c5.1,0,10,0.7,14.7,2.8c7.2,3.3,10.6,9.2,11.1,16.9c0.4,6.4,0.2,12.8,0.2,19.2c0,8.5,0,16.9,0.1,25.4
c0,0.9-0.2,1.2-1.2,1.2c-2.7-0.1-5.4,0-8.1,0c-0.7,0-0.9-0.2-0.9-0.9c0-11.3,0.1-22.6,0.1-33.9c0-3.4,0.2-6.8-0.3-10.2
c-0.7-5.5-3.7-8.9-8.9-10.6c-2.9-0.9-5.8-1.5-8.8-1.6c-8.6-0.4-17.2-0.5-25.8,0.8c-2.9,0.4-5.8,1.2-8.4,2.6
c-4.7,2.4-6.7,6.6-7.4,11.5c-0.2,1.2-0.2,2.3-0.2,3.5c0,12.5,0,25,0,37.4c0,1.4,0,1.4-1.4,1.4c-2.6,0-5.2,0-7.8,0
c-0.9,0-1.1-0.3-1.1-1.1C223.3,201.5,223.3,191.1,223.3,180.7z"/>
<path class="st0" d="M73.1,64.4c0-11.9,0-23.8,0-35.7c0-1.1,0.3-1.3,1.3-1.3c5.6,0,11.3,0,16.9,0c1.5,0,1.5,0,1.5,1.5
c0,23.8,0,47.6,0,71.5c0,1-0.3,1.4-1.3,1.4c-5.7,0-11.5,0-17.2,0c-0.9,0-1.2-0.3-1.2-1.2C73.1,88.4,73.1,76.4,73.1,64.4
C73.1,64.4,73.1,64.4,73.1,64.4z"/>
<path class="st1" d="M9.1,180.2c0-10.4,0-20.8,0-31.1c0-0.8,0.2-1.1,1-1.1c2.4,0,4.8,0.1,7.3,0c0.9,0,1.1,0.3,1.1,1.1
c0,2.6,0,5.1,0,7.7c0,0.2,0,0.4,0,0.6c0,0.3,0.1,0.7,0.4,0.8c0.3,0.1,0.4-0.4,0.5-0.6c3.5-7.1,9.7-10,17.2-10.4
c6.8-0.4,13.7-0.3,20.5,0.2c2.9,0.2,5.7,0.8,8.4,1.8c5.8,2.2,9.2,6.3,10.4,12.3c1,4.8,0.8,9.6,0.9,14.4c0,0.5-0.3,0.6-0.7,0.6
c-2.6,0-5.2,0-7.8,0c-0.7,0-0.7-0.4-0.7-0.9c-0.1-3-0.3-6-0.6-9c-0.2-1.4-0.5-2.7-1-4c-1.5-4.1-4.9-5.9-8.9-6.7
c-2.8-0.6-5.6-0.8-8.5-0.8c-4.3-0.1-8.7-0.1-13,0c-3.1,0.1-6.1,0.8-8.9,2.4c-4.4,2.6-6.7,6.6-7.4,11.6c-0.4,2.9-0.4,5.8-0.4,8.7
c0,10.2,0,20.3,0,30.5c0,1.1,0,2.1,0.1,3.2c0,0.8-0.2,1.1-1,1.1c-2.6,0-5.2,0-7.8,0c-0.9,0-1.2-0.3-1.2-1.2
C9.2,201,9.2,190.6,9.1,180.2C9.1,180.2,9.1,180.2,9.1,180.2z"/>
<path class="st1" d="M102.9,180.4c0,10.4,0,20.7,0,31.1c0,0.9-0.3,1.1-1.2,1.1c-2.5-0.1-5.1-0.1-7.6-0.1c-1.4,0-1.4,0-1.4-1.4
c0-9.6,0-19.1,0-28.7c0-11.2,0-22.3-0.1-33.5c0-0.7,0.2-1,1-1c2.8,0,5.6,0,8.3,0c0.8,0,1,0.3,1,1
C102.7,159.5,102.9,169.9,102.9,180.4z"/>
</svg>
</template>
