<template>
  <div class="content-block">

<h2 class="main-title">{{ msg }}</h2>

<div class="dx-card has-paddings" style="overflow: visible;">

  <ul id="drilldown" class="vertical menu" data-drilldown>
      <li>
        <a>Item 1</a>
        <ul class="vertical menu">
          <li><a>Item 1A</a></li>
          <li><a>Item 1B</a></li>
          <li><a>Item 1C</a></li>
          <!-- ... -->
        </ul>
      </li>
      <li><a>Item 2</a></li>
    </ul>

  </div><!-- dx-card -->

<div style="height: 10px;"></div>
<p style="margin: 15px 5px; font-size: 11px;"><a href="https://get.foundation/sites/docs/drilldown-menu.html" target="_blank" style="color: grey;"><i class="fa-solid fa-arrow-up-right-from-square"></i> https://get.foundation/sites/docs/drilldown-menu.html</a></p>

</div><!-- content-block -->

</template>

<script>
 
import $ from 'jquery';
import Foundation from 'foundation-sites';

export default {
  name: 'drilldownmenu-example',
  mounted() {
    this.drilldownMenu = new Foundation.Drilldown($('#drilldown'), {
    // These options can be declarative using the data attributes
    animationDuration: 500
  })
  },
  data() {
    return {
      msg: 'Drilldown Menu',
    };
  },
  methods: {

  },
  unmounted() {

  },
};
</script>

<style lang="scss">
</style>
