<template>
  <h2 class="content-block">
    <i class="dx-icon nav-icon fa-light fa-dumbbell"></i> 
    {{ title }}
  </h2>
  <div class="content-block">
    <div class="dx-card _responsive-paddings has-paddings">
      <p>Put your content here</p>
    </div>
  </div>
</template>

<script>
import auth from "../auth";

let currentUser;

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;
  } // e.data
}); //auth

export default {
  mounted() {

  },
  data() {
    return {
      title: currentUser.Translation.vueappNavMemberCourses,
    };
  },
  methods: {

  },
  unmounted() {

  },
};
</script>

<style lang="scss">
</style>
