<template>
<svg version="1.1" id="chilihealth-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 399.1 96" style="enable-background:new 0 0 399.1 96;" xml:space="preserve">
<path class="st0" d="M79.6,44.8c2.2,0,4.4,0,6.6,0c1,0,1.3-0.2,1.3-1.2c0-2.8,0-5.5,0-8.3c0-0.9-0.4-1.2-1.3-1.2
c-1.9-0.1-1.9-0.1-1.9-2.1c0.1-3,0.1-3,3.1-3c3.6,0,7.1,0,10.7,0c1,0,1.4,0.3,1.3,1.3c0,0.4,0,0.9,0,1.4c0,2,0,2-1.9,2.4
c-0.9,0.2-1.4,0.7-1.4,1.6c0,1.4,0,2.9,0,4.3c0,7.1,0,14.1,0,21.2c0,1.1,0.4,1.4,1.4,1.4c1.8,0,1.8,0,1.8,1.8c0,3.3,0,3.3-3.3,3.3
c-3.6,0-7.2,0-10.8,0c-0.8,0-1.1-0.2-1.1-1c0.1-0.9,0.1-1.7,0-2.6c-0.1-1,0.3-1.4,1.2-1.4c2,0,2.2-0.7,2.2-2.6c0-2.6,0-5.2,0-7.8
c0-0.8-0.1-1.3-1.2-1.3c-4.4,0.1-8.9,0.1-13.3,0.1c-1,0-1.3,0.3-1.2,1.2c0.1,3,0.1,6,0,9c0,1,0.4,1.3,1.3,1.3c1.9,0,1.9,0.1,1.9,2
c0,3.1,0,3.1-3.1,3.1c-3.7,0-7.3,0-11,0c-0.9,0-1.2-0.3-1.2-1.1c0-0.4,0-0.8,0-1.2c0-0.7-0.3-1.6,0.1-2.2c0.4-0.7,1.4-0.4,2-0.5
c1.1-0.3,1.3-1,1.3-2c0-6.7,0-13.4,0-20c0-1.7,0-3.4,0-5.1c0-0.9-0.3-1.2-1.2-1.2c-2.4,0-2,0-2-2c0-1-0.5-2.4,0.2-2.9
c0.6-0.5,1.9-0.2,2.9-0.2c3.6,0,7.2,0,10.8,0c1.1,0,1.4,0.3,1.3,1.3c0,0.3,0,0.6,0,0.9c0,0.8,0.2,1.7-0.1,2.3
c-0.4,0.8-1.4,0.4-2.1,0.6c-0.8,0.2-1.2,0.6-1.2,1.5c0,2.8,0,5.5,0,8.3c0,0.7,0.2,1,1,1C75,44.7,77.3,44.8,79.6,44.8z"/>
<path class="st0" d="M48.4,61c0,1.3,0,2.6,0,3.8c0,0.7-0.2,1.1-0.8,1.4c-7,2.5-14,2.9-21,0.1c-4.7-1.9-7.5-5.5-8.8-10.3
c-1.4-5.2-1.4-10.4,0.1-15.6c2.2-7.2,7.4-10.9,14.6-11.9c4.8-0.7,9.4-0.1,14,1.4c1.1,0.4,1.6,0.9,1.5,2.1c-0.1,2.3,0,4.7,0,7
c0,0.8-0.2,1.2-1.1,1.2c-1.4,0-2.8,0.1-4.2,0.3c-1,0.1-1.2-0.3-1.2-1.2c0.1-0.9,0-1.7,0-2.6c0-0.9-0.4-1.5-1.3-1.7
c-2-0.4-4.1-0.5-6.1-0.2c-4.2,0.7-6.7,3.3-7.8,7.2c-1.1,4.1-1,8.2,0.2,12.2c1.4,4.5,4.4,6.9,9.1,7.2c1.5,0.1,3,0,4.5-0.2
c1.1-0.2,1.8-0.8,1.7-2c-0.1-0.9,0-1.9,0-2.8c-0.1-0.8,0.3-1,1-0.9c1.5,0.2,3.1,0.3,4.6,0.3c0.9,0,0.9,0.5,0.9,1.2
C48.4,58.3,48.4,59.7,48.4,61C48.4,61,48.4,61,48.4,61z"/>
<path class="st0" d="M150.4,67.4c-4.4,0-8.8,0-13.2,0c-0.9,0-1.3-0.3-1.2-1.2c0-0.5,0-1,0-1.5c0-2.1,0-2.1,2.1-2.5
c1-0.2,1.3-0.7,1.3-1.7c0-2.5,0-4.9,0-7.4c0-5.9,0-11.9,0-17.8c0-1-0.2-1.5-1.4-1.5c-1.9,0-1.9,0-1.9-1.9c0-1-0.5-2.3,0.2-3
c0.7-0.6,2-0.2,3-0.2c3.8,0,7.6,0,11.3,0c0.8,0,1.1,0.3,1.1,1.1c0,0.4,0,0.7,0,1.1c0,0.8,0.2,1.6-0.1,2.3c-0.4,0.8-1.4,0.3-2,0.4
c-1,0.3-1.6,0.8-1.6,1.9c0,8.3,0,16.6,0,25c0,0.8,0.2,1.1,1.1,1.1c2.5,0,5,0,7.5,0c1.6,0,2-0.5,2-2c0-0.8,0-1.6,0-2.3
c-0.1-0.8,0.2-1.1,1-1.1c1.5,0.1,3.1,0.2,4.6,0.3c0.7,0,0.9,0.3,0.8,0.9c0,3,0,6,0,9c0,0.9-0.6,0.8-1.2,0.8
C159.4,67.4,154.9,67.4,150.4,67.4z"/>
<path class="st0" d="M179.3,28.9c2.2,0,4.4,0,6.6,0c0.8,0,1.1,0.2,1.1,1c-0.1,1,0,2,0,3c0,0.6-0.3,0.8-0.9,0.9
c-2.4,0.2-2.6,0.4-2.6,2.7c0,8.2,0,16.3,0,24.5c0,1.2,0.6,1.3,1.5,1.3c1.8,0,1.8,0.1,1.8,1.8c0,3.3,0,3.3-3.3,3.3
c-3.6,0-7.1,0-10.7,0c-1,0-1.3-0.4-1.3-1.3c0-0.9,0-1.7-0.1-2.6c0-0.7,0.2-1,1-1.1c2.4-0.3,2.5-0.4,2.5-2.8c0-8,0-16.1,0-24.1
c0-1.2-0.3-1.6-1.5-1.6c-1.8,0.1-1.8,0-1.8-1.8c0-1.1-0.5-2.4,0.2-3.1c0.7-0.7,2-0.2,3.1-0.2C176.4,28.9,177.8,28.9,179.3,28.9z"/>
<path class="st0" d="M117.8,28.9c2.3,0,4.6,0,6.9,0c0.7,0,0.9,0.1,0.9,0.9c-0.1,1-0.1,2,0,3c0,0.7-0.2,1-1,1
c-2.2,0.1-2.4,0.4-2.4,2.5c0,8.2,0,16.4,0,24.6c0,1.1,0.3,1.4,1.4,1.4c1.9,0,1.9,0,1.9,1.8c0,3.3,0,3.3-3.3,3.3c-3.6,0-7.1,0-10.7,0
c-1.1,0-1.4-0.3-1.3-1.3c0-0.3,0-0.7,0-1c0-2.3,0-2.3,2.3-2.8c0.6-0.1,1-0.5,1.1-1.1c0.1-0.5,0.1-1,0.1-1.5c0-8.1,0-16.2,0-24.4
c0-1.2-0.4-1.5-1.5-1.5c-1.8,0-1.8,0-1.8-1.8c0-0.1,0-0.2,0-0.4c0-2.9,0-2.9,3-2.9C114.7,28.9,116.3,28.9,117.8,28.9z"/>
<path class="st1" d="M398.8,21.5c-1.9-11.3-10.7-18.9-22.1-20.9c-0.2,0-0.4-0.1-0.8-0.1c0.5-0.2-2.2-0.4-1.8-0.5H26.5l-0.1,0.1
c-0.2,0.1-2,0.1-3.6,0.4c-0.5,0.1-2.5,0.5-3.7,0.9C8.4,4.8,1.9,12,0.2,23.3C0.1,24.2,0,25.2,0,26.1C0,40.4,0,54.7,0,69
c0,13.5,9.6,24.6,23.1,26.6c1.6,0.2,3.3,0.3,5,0.3c114.6,0,229.1,0,343.7,0c9.5,0,17.1-3.9,22.6-11.6c2.2-3.1,3.5-6.6,4.1-10.4
c0.1-0.7,0.2-1.5,0.5-2.2c0-16.1,0.1-31.3,0.1-47.4C398.6,24,398.9,22,398.8,21.5z M196.7,89.9c-28.4,0-56.9,0-85.3,0
c-27.9,0-55.8,0-83.7,0c-6.8,0-13.5-2.9-17.6-8.3c-2.6-3.3-3.9-7.1-3.9-11.3c0-14.8-0.1-29.7,0-44.5c0-9.5,7.6-17.9,17.1-19.4
c1.4-0.2,2.8-0.2,4.2-0.2c56.1,0,112.3,0,168.4,0c0.6,0,1.2,0,1.7,0c1.6,0,1.6,0,1.6,1.6c0,26.6,0,53.1,0,79.7
C199.3,88.8,198.1,89.9,196.7,89.9z"/>
<path class="st2" d="M238.1,28.3C238.1,28.3,238.1,28.3,238.1,28.3c0-3.8,0-7.6,0-11.4c0-0.9-0.3-1.3-1.2-1.2
c-1.2,0.1-2.3,0.1-3.5,0c-0.9-0.1-1.1,0.2-1.1,1.1c0,2.6,0,5.1,0,7.7c0,0.8-0.3,1.1-1.1,1.1c-2.6,0-5.2-0.1-7.8,0
c-0.9,0-1.2-0.2-1.2-1.2c0.1-2.5,0-5,0-7.5c0-0.9-0.3-1.2-1.2-1.1c-1.2,0.1-2.4,0-3.6,0c-0.7,0-1,0.2-1,1c0,7.5,0,15.1,0,22.6
c0,0.9,0.3,1.2,1.2,1.1c1.2,0,2.4,0,3.6,0c0.8,0,1.1-0.2,1-1.1c-0.1-2.6,0-5.2-0.1-7.8c0-1,0.3-1.3,1.3-1.3c2.6,0.1,5.1,0,7.7,0
c0.9,0,1.1,0.3,1.1,1.1c-0.1,2.6,0,5.3-0.1,7.9c0,0.9,0.3,1.2,1.1,1.2c1.2-0.1,2.4,0,3.6,0c0.9,0,1.1-0.3,1.1-1.2
C238.1,35.6,238.1,32,238.1,28.3z"/>
<path class="st2" d="M346.5,28.1C346.5,28.1,346.5,28.1,346.5,28.1c0,3.8,0,7.5,0,11.3c0,0.8,0.2,1.1,1.1,1.1c1.2,0,2.4-0.1,3.6,0
c1,0,1.2-0.3,1.2-1.2c-0.1-2.5,0-4.9-0.1-7.4c0-1.2,0.2-1.6,1.5-1.5c2.3,0.1,4.7,0,7,0c1.6,0,1.7,0,1.7,1.6c0,2.5,0,4.9-0.1,7.4
c0,0.8,0.2,1.1,1,1.1c1.2,0,2.4-0.1,3.6,0c0.9,0,1.2-0.4,1.2-1.2c0-7.5,0-14.9,0-22.4c0-0.9-0.4-1.1-1.2-1.1c-1.1,0.1-2.2,0.1-3.4,0
c-1-0.1-1.3,0.3-1.3,1.2c0.1,2.5,0,4.9,0,7.4c0,0.8-0.2,1.2-1.1,1.2c-2.7,0-5.3,0-8,0c-0.8,0-1.1-0.3-1.1-1.1c0-2.5,0-5,0-7.5
c0-0.9-0.3-1.3-1.2-1.2c-1.1,0.1-2.2,0.1-3.4,0c-1-0.1-1.3,0.3-1.3,1.2C346.5,20.7,346.5,24.4,346.5,28.1z"/>
<path class="st2" d="M296.6,63.4c0.4,0.3,0.5,0.4,0.6,0.5c2.7,4.2,5.4,8.3,7.9,12.5c1,1.6,2.1,2.4,4,2.2c1.1-0.1,1.5-0.3,1.5-1.5
c-0.1-6.5,0-13,0-19.5c0-3.8,0-3.8-3.8-3.7c-0.6,0-0.9,0.2-0.9,0.8c0,3.7,0,7.5,0,11.2c0,0.8,0.3,1.6,0.1,2.4c-0.6-0.2-0.8-0.7-1-1
c-2.3-3.5-4.6-7.1-6.8-10.6c-1.8-2.8-1.8-2.8-5.2-2.8c-0.9,0-1.1,0.3-1.1,1.2c0,6.7,0,13.4,0,20.2c0,3.3,0,3.3,3.4,3.3
c1.1,0,1.4-0.3,1.3-1.4c-0.1-3.3,0-6.6,0-9.9C296.6,66.1,296.6,64.9,296.6,63.4z"/>
<path class="st2" d="M330.1,63.5c0.6,0.2,0.7,0.6,0.9,0.9c2.6,4,5.3,7.9,7.7,12c1.1,1.8,2.3,2.4,4.3,2.2c0.9-0.1,1.2-0.3,1.2-1.2
c0-6.8,0-13.6,0-20.4c0-2.9,0-2.9-2.9-3c-0.1,0-0.3,0-0.4,0c-1.1-0.3-1.3,0.3-1.3,1.2c0,4,0,8.1,0,12.1c0,0.4,0.2,0.9-0.3,1.3
c-2.5-3.9-5.2-7.7-7.4-11.7c-1.3-2.3-2.8-3.3-5.4-3c-0.7,0.1-0.9,0.3-0.9,0.9c0,0.7,0,1.4,0,2.1c0,6.8,0,13.6,0,20.4
c0,1.1,0.3,1.5,1.4,1.3c0.7-0.1,1.5-0.1,2.2,0c0.8,0.1,1-0.2,1-1c0-3.3,0-6.6,0-9.9C330.1,66.3,330.1,64.9,330.1,63.5z"/>
<path class="st2" d="M247.6,28C247.6,28,247.6,28,247.6,28c0,3.7,0,7.4-0.1,11.1c0,1.1,0.4,1.3,1.4,1.3c4.1,0,8.1,0,12.2,0
c3.5,0,3.4,0,3.5-3.4c0-1-0.3-1.4-1.4-1.4c-2.9,0.1-5.7,0-8.6,0.1c-0.9,0-1.4-0.3-1.3-1.3c0.1-1.1,0-2.2,0-3.3
c0-0.8,0.3-1.1,1.1-1.1c1.7,0.1,3.3,0.1,5,0.1c3.4,0,3.3,0,3.4-3.3c0-1.1-0.3-1.5-1.4-1.4c-2.3,0.1-4.7,0-7,0
c-0.8,0-1.2-0.2-1.1-1.1c0-0.9,0.1-1.9,0-2.8c-0.1-0.9,0.3-1.3,1.2-1.2c2.3,0.1,4.6,0,6.9,0c2.9,0,2.9,0,2.9-2.8c0-0.2,0-0.5,0-0.7
c0.1-1-0.3-1.1-1.2-1.1c-4.7,0.1-9.4,0-14.1,0c-1.1,0-1.4,0.2-1.4,1.3C247.6,20.7,247.6,24.3,247.6,28z"/>
<path class="st2" d="M368.1,69.8C368,69.8,368,69.8,368.1,69.8c0-1.5-0.1-3.1,0-4.6c0-0.5-0.1-0.9-0.7-0.9c-2.5,0-5.1,0-7.6,0
c-0.4,0-0.7,0-0.7,0.5c0,1.3-0.5,2.9,0.2,4c0.5,0.8,1.9,0.2,3,0.3c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0.1,0.5,0.3c0.5,0.7,0,3.4-0.8,3.9
c-3.1,1.8-6.9,0.6-8.5-2.5c-0.9-1.6-1.1-3.3-1-5.1c0.2-2.8,1.2-5.2,3.8-6.5c2.5-1.3,4.7-0.3,6.8,1.3c0,0,0.1,0.1,0.1,0.1
c0.5,0.5,0.9,0.4,1.3-0.2c0.5-0.8,1-1.6,1.6-2.2c0.8-0.9,0.5-1.4-0.3-2.1c-4.4-3.7-11.3-3.1-15.1,1.4c-3.8,4.6-4.2,12.3-0.8,17.2
c2.1,2.9,4.9,4.4,8.5,4.5c3,0.1,5.6-1.1,7.9-2.8c0.8-0.6,1.2-1.4,1.2-2.4C368,72.4,368.1,71.1,368.1,69.8z"/>
<path class="st2" d="M329.3,15.7C329.3,15.8,329.3,15.8,329.3,15.7c-1.1,0.1-2.2,0.1-3.4,0.1c-1.1,0-2.2,0-3.4,0
c-1.1,0.1-2.7-0.6-3.3,0.2c-0.7,0.8-0.1,2.3-0.2,3.5c-0.1,0.8,0.3,1,1,1c1.5,0,3.1,0,4.6,0c1.7,0,1.7,0,1.7,1.7c0,5.7,0,11.5,0,17.2
c0,0.9,0.3,1.2,1.2,1.1c1.1,0,2.2-0.1,3.2,0c1.1,0.1,1.4-0.4,1.4-1.4c-0.1-5.8,0-11.6-0.1-17.4c0-0.9,0.2-1.2,1.2-1.2
c1.8,0.1,3.7,0,5.5,0c0.7,0,1.1-0.3,1-1c0-0.9,0-1.8,0-2.7c0-0.7-0.3-1-1.1-1C335.6,15.8,332.5,15.7,329.3,15.7z"/>
<path class="st2" d="M301.1,28C301.1,28,301.1,28,301.1,28c0,3.7,0,7.3,0,11c0,1.1,0.3,1.5,1.5,1.5c3.9-0.1,7.9,0,11.8,0
c3.6,0,3.6,0,3.6-3.6c0-0.9-0.3-1.2-1.1-1.2c-2.8,0-5.7,0-8.5,0c-1.1,0-1.4-0.2-1.4-1.4c0.1-5.8,0-11.5,0-17.3c0-1-0.3-1.4-1.4-1.3
c-1.1,0.1-2.3,0.1-3.4,0c-1-0.1-1.1,0.3-1.1,1.1C301.1,20.6,301.1,24.3,301.1,28z"/>
<path class="st2" d="M227.1,68.9C227.1,68.9,227.1,68.9,227.1,68.9c0-2.9,0-5.8,0-8.7c0-1,0.3-1.3,1.3-1.2c1.3,0.1,2.7,0,4,0
c0.8,0,1.1-0.3,1.1-1.1c0-1-0.1-2.1,0-3.1c0-0.6-0.2-0.9-0.9-0.9c-5.2,0-10.4,0-15.6,0c-0.6,0-0.9,0.2-0.9,0.8c0,1.1,0,2.1,0,3.2
c0,0.8,0.3,1,1,1c1.3,0,2.7,0.1,4,0c1.2-0.1,1.4,0.4,1.4,1.5c-0.1,4.9-0.1,9.8,0,14.7c0,1.1-0.5,2.5,0.2,3.2
c0.7,0.7,2.1,0.1,3.3,0.2c1,0.1,1.2-0.3,1.2-1.2C227.1,74.5,227.1,71.7,227.1,68.9z"/>
<path class="st2" d="M282.1,66.3c0,3,0,5.9,0,8.9c0,1.1-0.5,2.5,0.2,3.2c0.7,0.7,2.1,0.1,3.2,0.2c1,0.1,1.3-0.4,1.3-1.3
c0-6.3,0-12.6,0-18.9c0-1.2,0-2.3,0-3.5c0-0.7-0.3-1-1.1-1c-0.8,0.1-1.7,0.1-2.5,0c-1-0.1-1.2,0.3-1.2,1.2
C282.2,58.8,282.1,62.5,282.1,66.3z"/>
<path class="st2" d="M315.7,66.2C315.7,66.2,315.7,66.2,315.7,66.2c0,3.7,0,7.5,0,11.2c0,0.9,0.2,1.2,1.1,1.1c0.9-0.1,1.7,0,2.6,0
c0.7,0,1-0.2,1-0.9c0-7.6,0-15.2,0-22.7c0-0.8-0.3-1-1.1-1c-0.8,0-1.7,0.1-2.5,0c-0.9-0.1-1.1,0.2-1.1,1.1
C315.8,58.7,315.7,62.5,315.7,66.2z"/>
<path class="st2" d="M294.9,39.2c-2.6-6.8-5.2-13.5-7.7-20.3c-1.2-3.1-1.1-3.1-4.5-3.1c-0.5,0-1.1,0.1-1.6,0
c-1.1-0.2-1.7,0.2-2.1,1.3c-2.7,7.5-5.4,14.9-8.2,22.4c-0.2,0.7-0.1,0.9,0.6,0.9c1.3,0,2.7,0,4,0c0.7,0,0.9-0.4,1.1-1
c0.5-1.4,1-2.7,1.4-4.1c0.2-0.7,0.6-0.9,1.3-0.9c2.3,0,4.6,0,6.9,0c0.7,0,1.1,0.2,1.3,1c0.4,1.4,1,2.7,1.4,4.1c0.2,0.7,0.6,1,1.4,1
c1.3,0,2.6-0.1,3.9,0C295.1,40.5,295.3,40.2,294.9,39.2z M284.5,30.5c-0.7,0-1.3,0-2,0c-3.2,0-3.2,0-2.2-3c0.6-1.9,1.3-3.7,1.9-5.6
c0.1-0.3,0-0.7,0.5-0.7c0.3,0,0.3,0.4,0.4,0.7c0.8,2.4,1.6,4.8,2.4,7.2C285.9,30.4,285.8,30.5,284.5,30.5z"/>
<path class="st2" d="M278.4,77.3c-2.5-6.9-4.9-13.7-7.5-20.5c-1.4-3.7-0.3-2.9-4.3-3c-0.9,0-1.2,0.3-1.5,1.1
c-2.6,7.2-5.2,14.4-7.8,21.6c-0.8,2.2-0.8,2.2,1.5,2.2c0.5,0,0.9,0,1.4,0c0.8,0.1,1.3-0.2,1.5-1c0.4-1.2,0.8-2.4,1.2-3.6
c0.2-0.6,0.5-0.9,1.1-0.8c1.5,0,3.1,0,4.6,0c3.3,0,3.3,0,4.4,3.2c0.8,2.3,0.8,2.3,3.1,2.3c0.4,0,0.8,0,1.2,0
C278.9,78.6,278.9,78.6,278.4,77.3z M269.1,68.2c-0.5,0-1,0-1.5,0c-2.7-0.1-2.7-0.1-1.8-2.6c0.5-1.3,0.9-2.6,1.3-4
c0.1-0.3,0-0.7,0.5-0.7c0.4,0,0.4,0.4,0.5,0.7c0.6,1.8,1.1,3.6,1.8,5.4C270.4,68,270.1,68.3,269.1,68.2z"/>
<path class="st2" d="M254.3,77.4c-1.3-2.2-2.6-4.4-3.9-6.6c-0.3-0.5-0.6-0.8,0.1-1.2c3.3-2,4-5,3.8-8.5c-0.2-4.1-3.1-6.9-7.2-7
c-3,0-6,0.1-9-0.1c-1.1,0-1.3,0.3-1.3,1.2c0,3.7,0,7.3,0,11c0,0,0,0,0,0c0,3,0,5.9,0,8.9c0,3.6,0,3.6,3.6,3.5c0.9,0,1.2-0.3,1.2-1.1
c-0.1-1.6,0-3.1,0-4.7c0-2.5-0.1-2,2.1-2.1c1.1,0,1.7,0.3,2.1,1.3c0.5,1.1,1.4,2.1,1.8,3.3c1,3,3.1,3.9,6.1,3.4
C255,78.4,255,78.5,254.3,77.4z M246.7,65.9c-1.4,0.1-2.8,0-4.2,0c-0.6,0-0.9-0.2-0.9-0.9c0-0.9,0-1.8,0-2.7c0-0.9,0-1.7,0-2.6
c0-0.6,0.2-0.9,0.9-0.9c1.4,0.1,2.9,0,4.4,0.2c1.8,0.2,2.9,1.5,2.8,3.4C249.6,64.4,248.5,65.8,246.7,65.9z"/>
</svg>
</template>

