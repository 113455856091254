<template>

  <h2 class="content-block">
    <i class="dx-icon nav-icon fa-light fa-calendar-day"></i> 
    {{ title }}
  </h2>

  <div class="hide" style="font-size: 10px;">
    {{ this.SchedulerItems }}
  </div>
  
    <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block">
  
      <div class="dx-card no-paddings dx-card-has-minheight">

        <!-- <DxScheduler
          time-zone="America/Los_Angeles"
          :data-source="appointments"
          :current-date="currentDate"
          :views="views"
          current-view="workWeek"
          :start-day-hour="0"
          :end-day-hour="8"
          :offset="currentOffset"
          :cell-duration="60"
          :show-all-day-panel="false"

          appointment-template="AppointmentTemplate"
          appointment-tooltip-template="AppointmentTooltipTemplate"
          @appointment-updating="onAppointmentUpdating"

        /> -->

        <DxScheduler
          time-zone="Europe/Berlin"
          :data-source="SchedulerItems"
          :current-date="currentDate"
          :views="views"
          current-view="week"
          :start-day-hour="6"
          :end-day-hour="24"
          :cell-duration="60"
          :show-all-day-panel="false"
          :first-day-of-week="1"

          :on-appointment-added="showAddedToast"
          :on-appointment-updated="showUpdatedToast"
          :on-appointment-deleted="showDeletedToast"

          @appointment-updated="onAppointmentUpdated"

        >

        <template #AppointmentTemplate="{ data }">

          <div class="-hide" style="font-size: 10px;">
            <p>id: {{ data.appointmentData.id }}</p>
            <p>text: {{ data.appointmentData.text }}</p>
            <p>startDate: {{ data.appointmentData.startDate }}</p>
            <p>endDate: {{ data.appointmentData.endDate }}</p>
          </div>

        </template>

        <template #AppointmentTooltipTemplate="{ data }">

          <div class="-hide" style="font-size: 10px;">
            <p>id: {{ data.appointmentData.id }}</p>
            <p>text: {{ data.appointmentData.text }}</p>
            <p>startDate: {{ data.appointmentData.startDate }}</p>
            <p>endDate: {{ data.appointmentData.endDate }}</p>
          </div>

        </template>

      </DxScheduler>


      </div>

    </div>
  
  
  </template>
  
  <script>
  
  //import { ref } from 'vue';
  import DxScheduler from 'devextreme-vue/scheduler';
  import notify from 'devextreme/ui/notify';
  import auth from "../auth";
  import { 
    apihost, 
  } from "../api";
  //console.log(apihost);
    
  let currentUser;

  let SchedulerItems;

  const views = ['day','week','workWeek','month'];
  const currentDate = new Date();

  function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
  }


  auth.getUser().then((e) => {
    if(e.data){
      currentUser = e.data;
    } // e.data
  }); //auth

  export default {
  
    mounted() {

      fetch(apihost+'/'+currentUser.Language+'/vue/scheduler/getscheduler/id/action')
        .then(handleErrors)
        .then(response => response.text())
        .then(result => {
        const data = JSON.parse(result);
        //console.log(data);
        this.SchedulerItems = data;
      })
      .catch(() => { throw 'Network error' });


      this.timestamp = this.printTimestamp();
  
    },
  
    components: {
      DxScheduler,

    },
    data() {
      return {
        apihost,
        //title: currentUser.Translation.vueappNavMemberExercises,
        title: 'Terminplan',
        currentUser,
        SchedulerItems,
        currentDate,
        views,

      };
    },
    methods: {

      showToast(event, data) {

        let objString = '?' + 'text=' + data.text;

        if(data.startDate){
          objString = objString + '&startDate=' + data.startDate;
        } else {
          objString = objString + '&startDate=';
        }

        if(data.endDate){
          objString = objString + '&endDate=' + data.endDate;
        } else {
          objString = objString + '&endDate=';
        }

        if(data.recurrenceRule){
          objString = objString + '&recurrenceRule=' + data.recurrenceRule;
        } else {
          objString = objString + '&recurrenceRule=';
        }

        if(data.eventType){
          objString = objString + '&eventType=' + data.eventType;
        } else {
          objString = objString + '&eventType=';
        }

        if(data.locationId){
          objString = objString + '&locationId=' + data.locationId;
        } else {
          objString = objString + '&locationId=';
        }

        //console.log(objString);

        fetch(apihost+'/'+currentUser.Language+'/vue/scheduler/getscheduler/'+data.id+'/'+event+objString)
          .then(handleErrors)
          .then(response => response.text())
          .then(result => {
          const data = JSON.parse(result);
          console.log(data);
          this.SchedulerItems = data;

        })
        .catch(() => { throw 'Network error' });

        notify({
          message: data.text,
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);


      },

      onAppointmentUpdating(e) {
        console.log(e);
        //console.log(e.oldData);
        //console.log(e.newData);
      },

      onAppointmentUpdated(e) {
        // Handler of the "appointmentUpdated" event
        //console.log(e);
        //console.log(e.appointmentData);
        //console.log(e.appointmentData.id);
        this.showToast('Updated', e.appointmentData);

      },

      showAddedToast(e) {
        //showToast('Added', e.appointmentData.text, 'success');
        //console.log(e);
        //console.log(e.appointmentData);
        this.showToast('Added', e.appointmentData);
      },

      showUpdatedToast(e) {
        //showToast('Updated', e.appointmentData.text, 'info');
        //console.log(e);
        //console.log(e.appointmentData);
        this.showToast('Updated', e.appointmentData);
      },

      showDeletedToast(e) {
        //showToast('Deleted', e.appointmentData.text, 'warning');
        console.log(e);
        console.log(e.appointmentData);
        this.showToast('Deleted', e.appointmentData);
      },


      onShiftChanged(e) {
        console.log(e);
      },

      printTimestamp: function () {
          return Date.now();
      },
  
    },
  };
  
  
</script>

<style>

</style>
    