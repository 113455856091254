<template>
	<svg version="1.1" id="logo-msa" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 281.4 136.5" style="enable-background:new 0 0 281.4 136.5;" xml:space="preserve">
	<polygon class="st0" points="212,22.6 212,48.6 205.7,48.6 205.7,33 199.8,48.6 194.7,48.6 188.9,32.9 188.9,48.6 182.6,48.6 
	182.6,22.6 190,22.6 197.3,40.6 204.5,22.6 "/>
	<polygon class="st0" points="237.6,27.9 224.7,58.4 217.9,58.4 222.6,47.9 214.2,27.9 221.3,27.9 226.1,40.8 230.8,27.9 "/>
	<path class="st0" d="M186.8,84.9c-1.5-0.6-2.7-1.5-3.6-2.7c-0.9-1.2-1.4-2.7-1.4-4.4h6.7c0.1,1,0.4,1.7,1,2.2
	c0.6,0.5,1.3,0.8,2.2,0.8c0.9,0,1.7-0.2,2.2-0.6c0.5-0.4,0.8-1,0.8-1.8c0-0.6-0.2-1.2-0.6-1.6c-0.4-0.4-1-0.8-1.6-1
	c-0.6-0.3-1.5-0.6-2.7-0.9c-1.7-0.5-3-1-4.1-1.6c-1.1-0.5-2-1.3-2.7-2.3c-0.8-1-1.1-2.3-1.1-4c0-2.4,0.9-4.3,2.6-5.7
	c1.8-1.4,4-2.1,6.8-2.1c2.9,0,5.2,0.7,6.9,2.1c1.8,1.4,2.7,3.3,2.8,5.7h-6.8c0-0.8-0.4-1.5-0.9-2c-0.6-0.5-1.3-0.7-2.2-0.7
	c-0.8,0-1.4,0.2-1.9,0.6c-0.5,0.4-0.7,1-0.7,1.8c0,0.8,0.4,1.5,1.2,2c0.8,0.5,2,1,3.7,1.5c1.7,0.6,3,1.1,4.1,1.6
	c1,0.5,2,1.3,2.7,2.3c0.8,1,1.1,2.3,1.1,3.8c0,1.5-0.4,2.8-1.1,4c-0.8,1.2-1.8,2.2-3.3,2.9s-3.1,1.1-5.1,1.1
	C190.1,85.8,188.4,85.5,186.8,84.9"/>
	<path class="st0" d="M219.4,71.5c-0.9-0.9-2-1.4-3.2-1.4c-1.3,0-2.3,0.5-3.2,1.4c-0.9,0.9-1.3,2.2-1.3,3.7c0,1.6,0.4,2.8,1.3,3.7
	c0.9,0.9,1.9,1.4,3.2,1.4c1.3,0,2.3-0.5,3.2-1.4c0.9-0.9,1.3-2.2,1.3-3.8S220.2,72.4,219.4,71.5 M214.2,65.5
	c1.1-0.6,2.4-0.9,3.8-0.9c1.7,0,3.2,0.4,4.6,1.3c1.4,0.9,2.5,2.1,3.3,3.7s1.2,3.5,1.2,5.6c0,2.1-0.4,4-1.2,5.6
	c-0.8,1.6-1.9,2.9-3.3,3.7c-1.4,0.9-2.9,1.3-4.6,1.3c-1.4,0-2.7-0.3-3.8-0.9c-1.1-0.6-2-1.4-2.6-2.3v12.7h-6.3V64.9h6.3v2.9
	C212.3,66.9,213.1,66.1,214.2,65.5"/>
	<path class="st0" d="M243,79.1c0.9-0.9,1.3-2.2,1.3-3.8c0-1.7-0.4-2.9-1.2-3.8c-0.8-0.9-1.8-1.3-3.1-1.3c-1.2,0-2.3,0.4-3.1,1.3
	c-0.8,0.9-1.2,2.2-1.2,3.8c0,1.7,0.4,2.9,1.2,3.8c0.8,0.9,1.8,1.3,3,1.3C241.1,80.4,242.1,80,243,79.1 M234.4,84.6
	c-1.6-0.9-2.9-2.1-3.8-3.7c-0.9-1.6-1.4-3.5-1.4-5.6c0-2.1,0.5-4,1.4-5.6c0.9-1.6,2.2-2.9,3.9-3.7c1.6-0.9,3.5-1.3,5.5-1.3
	s3.9,0.4,5.5,1.3c1.6,0.9,2.9,2.1,3.8,3.7c0.9,1.6,1.4,3.5,1.4,5.6c0,2.1-0.5,4-1.4,5.6c-1,1.6-2.2,2.9-3.9,3.7
	c-1.6,0.9-3.5,1.3-5.5,1.3C237.8,85.9,236,85.4,234.4,84.6"/>
	<path class="st0" d="M263.1,65.7c1.1-0.7,2.3-1,3.7-1v6.7h-1.7c-1.6,0-2.8,0.3-3.6,1c-0.8,0.7-1.2,1.9-1.2,3.6v9.6H254V64.9h6.3
	v3.4C261.1,67.2,262,66.3,263.1,65.7"/>
	<path class="st0" d="M281.4,80.2v5.4h-3.2c-2.3,0-4.1-0.6-5.4-1.7c-1.3-1.1-1.9-3-1.9-5.5v-8.2h-2.5v-5.3h2.5v-5h6.3v5h4.1v5.3
	h-4.1v8.3c0,0.6,0.1,1.1,0.4,1.3c0.3,0.3,0.8,0.4,1.5,0.4H281.4z"/>
	<path class="st0" d="M197.1,113.1l-3.2-9.5l-3.2,9.5H197.1z M198.7,118H189l-1.6,4.6h-6.6l9.4-26h7.3l9.4,26h-6.7L198.7,118z"/>
	<path class="st0" d="M223.9,108.5c-0.9-0.9-2-1.4-3.2-1.4c-1.3,0-2.3,0.5-3.2,1.4c-0.9,0.9-1.3,2.2-1.3,3.7c0,1.6,0.4,2.8,1.3,3.7
	c0.9,0.9,1.9,1.4,3.2,1.4c1.3,0,2.3-0.5,3.2-1.4c0.9-0.9,1.3-2.2,1.3-3.8C225.2,110.7,224.8,109.4,223.9,108.5 M218.7,102.5
	c1.1-0.6,2.4-0.9,3.8-0.9c1.7,0,3.2,0.4,4.6,1.3c1.4,0.9,2.5,2.1,3.3,3.7s1.2,3.5,1.2,5.6c0,2.1-0.4,4-1.2,5.6
	c-0.8,1.6-1.9,2.9-3.3,3.7c-1.4,0.9-2.9,1.3-4.6,1.3c-1.4,0-2.7-0.3-3.8-0.9c-1.1-0.6-2-1.4-2.6-2.3v12.7h-6.3v-30.5h6.3v2.9
	C216.8,103.9,217.6,103.1,218.7,102.5"/>
	<path class="st0" d="M249,108.5c-0.9-0.9-2-1.4-3.2-1.4c-1.3,0-2.3,0.5-3.2,1.4c-0.9,0.9-1.3,2.2-1.3,3.7c0,1.6,0.4,2.8,1.3,3.7
	c0.9,0.9,1.9,1.4,3.2,1.4c1.3,0,2.3-0.5,3.2-1.4c0.9-0.9,1.3-2.2,1.3-3.8C250.3,110.7,249.9,109.4,249,108.5 M243.9,102.5
	c1.1-0.6,2.4-0.9,3.8-0.9c1.7,0,3.2,0.4,4.6,1.3c1.4,0.9,2.5,2.1,3.3,3.7c0.8,1.6,1.2,3.5,1.2,5.6c0,2.1-0.4,4-1.2,5.6
	c-0.8,1.6-1.9,2.9-3.3,3.7c-1.4,0.9-2.9,1.3-4.6,1.3c-1.4,0-2.7-0.3-3.8-0.9c-1.1-0.6-2-1.4-2.6-2.3v12.7H235v-30.5h6.3v2.9
	C241.9,103.9,242.8,103.1,243.9,102.5"/>
	<path class="st1" d="M124.7,46.6c7.9,0,14.3-6.4,14.3-14.3S132.6,18,124.7,18s-14.3,6.4-14.3,14.3S116.8,46.6,124.7,46.6"/>
	<path class="st1" d="M126.3,115.6l-10.8-24.7l20.5-9.4L126.3,115.6z M149.2,66.1c-3.1-2.6-7.3-3.2-11-1.5l-28.5,13.1L79.6,8.9
	C77.2,3.5,72.1,0.1,66.3,0c-5.9-0.1-11.1,3.1-13.6,8.4L1.4,115.6c-2.7,5.6-1.6,12,2.8,16.4c4.4,4.4,10.8,5.5,16.3,2.9l70-32.9
	l-6.1-13.1l-69.9,32.9l51.2-107c0.1-0.1,0.2-0.2,0.3-0.2c0.2,0,0.2,0.1,0.3,0.2l50.4,115.1c1.8,4.1,5.8,6.7,10.2,6.7
	c0.3,0,0.5,0,0.8,0c4.8-0.3,8.7-3.5,10-8.1l14.6-51.6C153.5,72.8,152.3,68.7,149.2,66.1"/>
	</svg>
</template>