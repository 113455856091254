<template>
  <div class="content-block">

    <h2 class="main-title">XY-Grid</h2>

    <div class="grid-x grid-margin-x grid-margin-y">
      <div class="large-4 medium-12 small-12 cell">
        <div class="dx-card has-paddings">
          <p>large-4 medium-12 small-12</p>
        </div>
      </div>
      <div class="large-4 medium-6 small-12 cell">
        <div class="dx-card has-paddings">
          <p>large-4 medium-6 small-12</p>
        </div>
      </div>
      <div class="large-4 medium-6 small-12 cell">
        <div class="dx-card has-paddings">
          <p>large-4 medium-6 small-12</p>
        </div>
      </div>
    </div>

    <div class="grid-x grid-margin-x grid-margin-y">
      <div class="large-8 medium-12 small-12 cell">
        <div class="dx-card has-paddings">
          <p>large-8 medium-12 small-12</p>
        </div>
      </div>
      <div class="large-4 medium-12 small-12 cell">
        <div class="dx-card has-paddings">
          <p>large-4 medium-12 small-12</p>
        </div>
      </div>
    </div>

    <div class="grid-x grid-margin-x grid-margin-y">
      <div class="large-4 medium-12 small-12 cell">
        <div class="dx-card has-paddings">
          <p>large-4 medium-12 small-12</p>
        </div>
      </div>
      <div class="large-8 medium-12 small-12 cell">
        <div class="dx-card has-paddings">
          <p>large-8 medium-12 small-12</p>
        </div>
      </div>
    </div>

  </div><!-- content-block -->

  <div style="height: 50px;"></div>

  <div class="content-block">

    <h2 class="main-title">Block-Grid</h2>

    <div class="grid-x grid-margin-x grid-margin-y large-up-3 medium-up-2 small-up-1">

      <div class="cell">
        <div class="dx-card has-paddings">
          <p>large-up-3 medium-up-2 small-up-1</p>
        </div>
      </div>

      <div class="cell">
        <div class="dx-card has-paddings">
          <p>large-up-3 medium-up-2 small-up-1</p>
        </div>
      </div>

      <div class="cell">
        <div class="dx-card has-paddings">
          <p>large-up-3 medium-up-2 small-up-1</p>
        </div>
      </div>

      <div class="cell">
        <div class="dx-card has-paddings">
          <p>large-up-3 medium-up-2 small-up-1</p>
        </div>
      </div>

      <div class="cell">
        <div class="dx-card has-paddings">
          <p>large-up-3 medium-up-2 small-up-1</p>
        </div>
      </div>

      <div class="cell">
        <div class="dx-card has-paddings">
          <p>large-up-3 medium-up-2 small-up-1</p>
        </div>
      </div>

    </div><!-- grid-x -->

  </div><!-- content-block -->

  <div style="height: 50px;"></div>

  <div class="content-block">
    <p style="margin: 15px 5px; font-size: 11px;"><a href="https://get.foundation/sites/docs/xy-grid.html" target="_blank" style="color: grey;"><i class="fa-solid fa-arrow-up-right-from-square"></i> https://get.foundation/sites/docs/xy-grid.html</a></p>
  </div><!-- content-block -->

</template>


<style lang="scss">
</style>
