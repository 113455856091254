<template>

  <div class="hide" style="font-size: 10px; margin-bottom: 16px;">
    <p>id: {{ this.templateData.data.id }}</p>
    <p>ExercisesDoing: {{ this.templateData.data.ExercisesDoing }}</p>
    <p>ExercisesPlanned: {{ this.templateData.data.ExercisesPlanned }}</p>
    <p>MembersDoing: {{ this.templateData.data.MembersDoing }}</p>
    <p>MembersPlanned: {{ this.templateData.data.MembersPlanned }}</p>
    
    <p>data: {{ this.templateData.data }}</p>
  </div>

  <form 
    style="padding: 0;"
    :id="this.templateData.data.id"
    @submit="handleSubmit"
  >

    <DxForm 
      :id="this.templateData.data.id"
      :form-data="this.templateData.data"
      :read-only="false"
      @initialized="saveFormInstance"
      :show-colon-after-label="true"
      :show-validation-summary="true"
      validation-group="currentUserData"
    >

      <DxSimpleItem 
        data-field="id"
        help-text=""
        css-class="hide"
      >
      </DxSimpleItem>


      <DxSimpleItem 
        data-field="mastercontent"
        help-text=""
        template="mastercontent"
      >
      <DxLabel 
        :text="'mastercontent'"
        :visible="false"
      />
      </DxSimpleItem>

      <template #mastercontent>

        <div class="master-content">

        <!-- SUBMIT BUTTON  -->
        <div class="grid-x grid-margin-x align-middle title-area-content">
          <div class="auto cell">
            <h5>Trainingsgruppe bearbeiten</h5>
          </div>
          <div class="shrink cell">
            <button :id="'submitform'+this.templateData.data.id" class="cx-button">{{currentUser.Translation.vueappExercisegroupFormSubmit}}</button>
          </div>
        </div>
        <!-- /SUBMIT BUTTON  -->

          <div class="grid-x grid-margin-x  master-grid">

            <!-- cell Betreff-->
            <div class="cell cell-margin-y xlarge-3">

              <div class="dx-card no-paddings">
                
              <label class="ck-label hide">Betreff</label>

              <div class="dx-card-label grid-x align-middle">
                <div class="auto cell">
                  <p>{{ currentUser.Translation.vueappTaskSubject }}</p>
                </div>
              </div>

              <ul :id="'tabsLang'+this.templateData.data.id" class="tabs hide" data-tabs>
                <li class="tabs-title" :class="currentUser.Language == 'de'  ? 'is-active' : ''"><a :href="'#panelSubject_de'+this.templateData.data.id" aria-selected="true"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/de.svg'"><span class="hide">de</span></a></li>
                <li class="tabs-title" :class="currentUser.Language == 'en'  ? 'is-active' : ''"><a :href="'#panelSubject_en'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/gb.svg'"><span class="hide">en</span></a></li>
                <li class="tabs-title" :class="currentUser.Language == 'fr'  ? 'is-active' : ''"><a :href="'#panelSubject_fr'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/fr.svg'"><span class="hide">fr</span></a></li>
                <li class="tabs-title" :class="currentUser.Language == 'es'  ? 'is-active' : ''"><a :href="'#panelSubject_es'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/es.svg'"><span class="hide">es</span></a></li>
                <li class="tabs-title" :class="currentUser.Language == 'it'  ? 'is-active' : ''"><a :href="'#panelSubject_it'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/it.svg'"><span class="hide">it</span></a></li>
              </ul>


              <div class="tabs-content" :data-tabs-content="'tabsLang'+this.templateData.data.id">

                <div class="tabs-panel" :class="currentUser.Language == 'de'  ? 'is-active' : ''" :id="'panelSubject_de'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_de'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_de"
                    :input-attr="{ 'aria-label': 'Subject_de' }"
                  />
                </div>
                <div class="tabs-panel" :class="currentUser.Language == 'en'  ? 'is-active' : ''" :id="'panelSubject_en'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_en'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_en"
                    :input-attr="{ 'aria-label': 'Subject_en' }"
                  />
                </div>
                <div class="tabs-panel" :class="currentUser.Language == 'fr'  ? 'is-active' : ''" :id="'panelSubject_fr'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_fr'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_fr"
                    :input-attr="{ 'aria-label': 'Subject_fr' }"
                  />
                </div>
                <div class="tabs-panel" :class="currentUser.Language == 'es'  ? 'is-active' : ''" :id="'panelSubject_es'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_es'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_es"
                    :input-attr="{ 'aria-label': 'Subject_es' }"
                  />
                </div>
                <div class="tabs-panel" :class="currentUser.Language == 'it'  ? 'is-active' : ''" :id="'panelSubject_it'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_it'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_it"
                    :input-attr="{ 'aria-label': 'Subject_it' }"
                  />
                </div>

              </div><!-- /tabs-content-->

                <!-- description-->
                <label class="ck-label hide">Beschreibung</label>

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>{{ currentUser.Translation.vueappTaskDescription }}</p>
                  </div>
                </div>
                <DxTextBox
                  :id="'Description'+this.templateData.data.id"
                  :value="this.templateData.data.Description"
                  :input-attr="{ 'aria-label': 'Description' }"
                />
                <!-- /description-->

              </div><!-- dx-card-->

            </div>
            <!-- /cell Betreff-->

            <!-- cell Exercises-->
            <div class="cell cell-margin-y xlarge-9">

              <div class="grid-x grid-margin-x">

                <!-- cell doingExercises-->

                <div class="auto cell">

                  <!-- <div class="ck-list-container auto cell"> -->
                  <div class="dx-card no-paddings" style="overflow-y: scroll; height: 400px;">

                    <label class="ck-label hide">{{ doingExercises.length }} verfügbare Übungen</label>

                    <div class="dx-card-label grid-x align-middle">
                      <div class="auto cell">
                        <p>{{ doingExercises.length }} verfügbare Übungen</p>
                      </div>
                    </div>
                          
                    <DxList
                      :data-source="doingExercises"
                      key-expr="id"
                      :search-enabled="true"
                      :search-expr="['text','text_en','Option1string','Option2string']"
                      item-template="doing-exercises">
                    >

                      <DxSearchEditorOptions
                        placeholder="Übungen suchen..."
                      />

                      <template #doing-exercises="{ data }">
                        <div>
                              
                          <div class="grid-x align-middle ck-itemrow">
                            <div class="shrink cell">
                              <div class="video-thumb" v-if="data.image"
                                style="margin: 0 10px 0 0;"
                                v-bind:style="{ 'background-image': 'url(' + data.image + '?v=' + timestamp + ')' }" >
                              </div>
                            </div>
                            <div class="auto cell grid-x">

                              <div class="shrink cell">
                                <div class="hide">
                                  <i class="fa-light fa-square-dashed-circle-plus"></i>&nbsp;
                                </div>
                              </div>

                              <div class="auto cell">
                                <p class="ck-overflow-ellipsis strong">
                                  {{ data.text }}
                                </p>
                                <p class="ck-overflow-ellipsis small">
                                {{ data.Option1string }} <span v-if="data.Option2string">|</span> {{ data.Option2string }}
                                </p>
                              </div>

                            </div>
                          </div>

                        </div>
                      </template>

                      <DxItemDragging
                        data="doingExercises"
                        :allow-reordering="true"
                        :on-drag-start="onDragStartExercises"
                        :on-add="onAddExercises"
                        :on-remove="onRemoveExercises"
                        :on-reorder="onReorderExercises"
                        group="tasks"
                      />
                    </DxList>

                    </div>



                  </div>
                  <!-- /cell doingExercises-->

                <!-- cell plannedExercises-->

                <div class="auto cell">


                  <!-- <div class="ck-list-container auto cell"> -->
                  <div class="dx-card no-paddings" style="overflow-y: scroll; height: 400px;">

                    <label class="ck-label hide">{{ plannedExercises.length }} zugewiesene Übungen</label>

                    <div class="dx-card-label grid-x align-middle">
                      <div class="auto cell">
                        <p>{{ plannedExercises.length }} zugewiesene Übungen</p>
                      </div>
                    </div>

                    <DxList
                      :data-source="plannedExercises"
                      key-expr="id"
                      :search-enabled="true"
                      :search-expr="['text','text_en','Option1string','Option2string']"
                      item-template="planned-exercises">
                    >

                    <DxSearchEditorOptions
                      placeholder="Übungen suchen..."
                    />

                    <template #planned-exercises="{ data }">
                      <div>

                        <div class="grid-x align-middle ck-itemrow">
                          <div class="shrink cell">
                            <div class="video-thumb" v-if="data.image"
                              style="margin: 0 10px 0 0;"
                              v-bind:style="{ 'background-image': 'url(' + data.image + '?v=' + timestamp + ')' }" >
                            </div>
                          </div>
                          <div class="auto cell grid-x">

                            <div class="shrink cell">
                              <div class="hide">
                                <i class="fa-light fa-square-dashed-circle-plus"></i>&nbsp;
                              </div>
                            </div>

                            <div class="auto cell">
                              <p class="ck-overflow-ellipsis strong">
                                {{ data.text }}
                              </p>
                              <p class="ck-overflow-ellipsis small">
                              {{ data.Option1string }} <span v-if="data.Option2string">|</span> {{ data.Option2string }}
                              </p>
                            </div>

                          </div>
                        </div>

                      </div>
                    </template>

                    <DxItemDragging
                      data="plannedExercises"
                      :allow-reordering="true"
                      :on-drag-start="onDragStartExercises"
                      :on-add="onAddExercisessave"
                      :on-remove="onRemoveExercisessave"
                      :on-reorder="onReorderExercisessave"
                      group="tasks"
                    />
                  </DxList>

                  </div>


                </div>
                <!-- /cell plannedExercises-->

              </div>
               <!-- /grid-x-->


            </div>
            <!-- /cell Exercises-->


          </div><!-- grid-x grid-margin-x  -->

        </div><!-- master-form -->

      </template><!-- mastercontent -->

      <DxButtonItem
        horizontal-alignment="left"
        :button-options="buttonOptions"
        css-class="hide"
      />

    </DxForm>

  </form>


</template>
<script>

import DxForm, {
  //DxGroupItem,
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  // DxRequiredRule,
  // DxPatternRule,
  //DxCompareRule,
  //DxRangeRule,
  //DxStringLengthRule,
  //DxEmailRule,
  //DxAsyncRule,
} from 'devextreme-vue/form';

import DxList, { DxItemDragging, DxSearchEditorOptions } from 'devextreme-vue/list';
import notify from 'devextreme/ui/notify';
import DxTextBox from 'devextreme-vue/text-box';

import $ from 'jquery';
import Foundation from 'foundation-sites';

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);


let doingExercises = [];
let plannedExercises = [];

let doingMembers = [];
let plannedMembers = [];

let saveExercisesGroup = [];
let saveMembersGroup = [];


//let doingExercises;

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 3,
  lg: 4
}

let currentUser;

auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
  } // e.data
}); //auth

function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

export default {
 name: 'exercisegroup-template',

 props: {
  templateData: Object,
 },

 components: {
  DxList,
  DxItemDragging,
  DxSearchEditorOptions,
  DxForm,
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  DxTextBox,
    //DxGroupItem,
 },

 mounted() {

  //foundation
  this.tabsLang = new Foundation.Tabs($('#tabsLang'+this.templateData.data.id), {
    matchHeight: false
  })
  
  this.doingExercises = this.templateData.data.ExercisesDoing;
  this.plannedExercises = this.templateData.data.ExercisesPlanned;

  this.doingMembers = this.templateData.data.MembersDoing;
  this.plannedMembers = this.templateData.data.MembersPlanned;

  this.timestamp = this.printTimestamp();

 },

 data() {
   return {
    apihost, 

    currentUser,
    colCountByScreen,

    doingExercises,
    plannedExercises,

    doingMembers,
    plannedMembers,
  
    saveExercisesGroup,
    saveMembersGroup,

    calendarOptions: { 
      displayFormat: "dd.MM.yyyy"
    },
    buttonOptions: {
      text: currentUser.Translation.vueappExercisegroupFormSubmit,
      type: 'success',
      useSubmitBehavior: true,
    },
    dateBoxOptions: {
      invalidDateMessage:
        'The date must have the following format: MM/dd/yyyy',
    },
    checkBoxOptions: {
      text: 'I agree to the Terms and Conditions',
      value: false,
    },
    phoneEditorOptions: {
      mask: '+1 (X00) 000-0000',
      maskRules: {
        X: /[02-9]/,
      },
      maskInvalidMessage: 'The phone must have a correct USA phone format',
    },

    statusPattern: /^[^0-9]+$/,
    priorityPattern: /^[^0-9]+$/,
    exercisegroupPattern: /^[^0-9]+$/,

    // phonePattern: /^[02-9]\d{9}$/,
    // maxDate: new Date().setFullYear(new Date().getFullYear() - 21),
    
    validationRules: {

      status: [
        { type: 'required', message: currentUser.Translation.vueappTaskStatusMessage },
      ],
      priority: [
        { type: 'required', message: currentUser.Translation.vueappTaskPriorityMessage },
      ],
    },
    statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
    priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },

   };

 },
 methods: {

  onDragStartExercises(e) {
    //e.itemData = this[e.fromData][e.fromIndex];
    let items = e.component.getDataSource().items();
    e.itemData = items[e.fromIndex];
  },

  onAddExercisessave(e) {
    // const data = [...this[e.toData]];
    // data.splice(e.toIndex, 0, e.itemData);
    // this[e.toData] = data;
    const data = [...this[e.toData]];
    data.splice(e.toIndex, 0, e.itemData);
    this[e.toData] = data;
    e.component.getDataSource().reload();
    this.saveExercisesGroup = data;
  },
  onRemoveExercisessave(e) {
    // const data = [...this[e.fromData]];
    // data.splice(e.fromIndex, 1);
    // this[e.fromData] = data;
    const data = [...this[e.fromData]];
    const itemIndex = data.findIndex((i) => i.id === e.itemData.id);
    data.splice(itemIndex, 1);
    this[e.fromData] = data;
    e.component.getDataSource().reload();
    this.saveExercisesGroup = data;
  },
  onReorderExercisessave(e) {
    console.log('onReorderExercisessave');
    this.onRemoveExercisessave(e);
    this.onAddExercisessave(e);
  },

  onAddExercises(e) {
    // const data = [...this[e.toData]];
    // data.splice(e.toIndex, 0, e.itemData);
    // this[e.toData] = data;
    const data = [...this[e.toData]];
    data.splice(e.toIndex, 0, e.itemData);
    this[e.toData] = data;
    e.component.getDataSource().reload();
  },
  onRemoveExercises(e) {
    // const data = [...this[e.fromData]];
    // data.splice(e.fromIndex, 1);
    // this[e.fromData] = data;
    const data = [...this[e.fromData]];
    const itemIndex = data.findIndex((i) => i.id === e.itemData.id);
    data.splice(itemIndex, 1);
    this[e.fromData] = data;
    e.component.getDataSource().reload();
  },
  onReorderExercises(e) {
    this.onRemoveExercises(e);
    this.onAddExercises(e);
  },
  
  onDragStartMembers(e) {
    // e.itemData = this[e.fromData][e.fromIndex];
    let items = e.component.getDataSource().items();
    e.itemData = items[e.fromIndex];
  },
  onAddMemberssave(e) {
    // const data = [...this[e.toData]];
    // data.splice(e.toIndex, 0, e.itemData);
    // this[e.toData] = data;
    const data = [...this[e.toData]];
    data.splice(e.toIndex, 0, e.itemData);
    this[e.toData] = data;
    e.component.getDataSource().reload();
    this.saveMembersGroup = data;
  },
  onRemoveMemberssave(e) {
    // const data = [...this[e.fromData]];
    // data.splice(e.fromIndex, 1);
    // this[e.fromData] = data;
    const data = [...this[e.fromData]];
    const itemIndex = data.findIndex((i) => i.id === e.itemData.id);
    data.splice(itemIndex, 1);
    this[e.fromData] = data;
    e.component.getDataSource().reload();
    this.saveMembersGroup = data;
  },
  onReorderMemberssave(e) {
    console.log('onReorderMemberssave');
    this.onRemoveMemberssave(e);
    this.onAddMemberssave(e);
  },

  onAddMembers(e) {
    // const data = [...this[e.toData]];
    // data.splice(e.toIndex, 0, e.itemData);
    // this[e.toData] = data;
    const data = [...this[e.toData]];
    data.splice(e.toIndex, 0, e.itemData);
    this[e.toData] = data;
    e.component.getDataSource().reload();
},
  onRemoveMembers(e) {
    // const data = [...this[e.fromData]];
    // data.splice(e.fromIndex, 1);
    // this[e.fromData] = data;
    const data = [...this[e.fromData]];
    const itemIndex = data.findIndex((i) => i.id === e.itemData.id);
    data.splice(itemIndex, 1);
    this[e.fromData] = data;
    e.component.getDataSource().reload();
},
  onReorderMembers(e) {
    this.onRemoveMembers(e);
    this.onAddMembers(e);
  },
 
  printTimestamp: function () {
    return Date.now();
  },

  saveFormInstance(e) {
    this.formInstance = e.component;
  },

  handleSubmit(e) {
    e.preventDefault();

    const id = this.formInstance.getEditor('id').option('value'); 
    // const Status = this.formInstance.getEditor('Status').option('value');  
    // const Priority = this.formInstance.getEditor('Priority').option('value');  

    const Subject_de = document.getElementById("Subject_de"+id).getElementsByTagName("input")[0].value;
    const Subject_en = document.getElementById("Subject_en"+id).getElementsByTagName("input")[0].value;
    const Subject_fr = document.getElementById("Subject_fr"+id).getElementsByTagName("input")[0].value;
    const Subject_es = document.getElementById("Subject_es"+id).getElementsByTagName("input")[0].value;
    const Subject_it = document.getElementById("Subject_it"+id).getElementsByTagName("input")[0].value;

    const Description = document.getElementById("Description"+id).getElementsByTagName("input")[0].value;

    let ToSaveExercises;
    if(this.saveExercisesGroup.length > 0){
      ToSaveExercises = this.saveExercisesGroup;
    } else {
      ToSaveExercises = this.templateData.data.ExercisesPlanned;
    }
    let arrayExerciseitems = [];
    for (let i = 0; i < ToSaveExercises.length; i++) {
      arrayExerciseitems.push({
        'id': ToSaveExercises[i].id,
        'type': '',        
      });
    }
    const Exerciseitems = encodeURIComponent(JSON.stringify(arrayExerciseitems));

    let ToSaveMembers;
    if(this.saveMembersGroup.length > 0){
      ToSaveMembers = this.saveMembersGroup;
    } else {
      ToSaveMembers = this.templateData.data.MembersPlanned;
    }
    let arrayMemberitems = [];
    for (let i = 0; i < ToSaveMembers.length; i++) {
      arrayMemberitems.push({
        'id': ToSaveMembers[i].id,
        'type': '',        
      });
    }
    const Memberitems = encodeURIComponent(JSON.stringify(arrayMemberitems));

    let objString = '?id=' + id;

    if(Subject_de){
      objString = objString + '&Subject_de=' + Subject_de;
    } else {
      objString = objString + '&Subject_de=';
    }
    if(Subject_en){
      objString = objString + '&Subject_en=' + Subject_en;
    } else {
      objString = objString + '&Subject_en=';
    }
    if(Subject_fr){
      objString = objString + '&Subject_fr=' + Subject_fr;
    } else {
      objString = objString + '&Subject_fr=';
    }
    if(Subject_es){
      objString = objString + '&Subject_es=' + Subject_es;
    } else {
      objString = objString + '&Subject_es=';
    }
    if(Subject_it){
      objString = objString + '&Subject_it=' + Subject_it;
    } else {
      objString = objString + '&Subject_it=';
    }
    if(Description){
      objString = objString + '&Description=' + Description;
    } else {
      objString = objString + '&Description=';
    }
    if(Exerciseitems){
      objString = objString + '&Exerciseitems=' + Exerciseitems;
    } else {
      objString = objString + '&Exerciseitems=';
    }
    if(Memberitems){
      objString = objString + '&Memberitems=' + Memberitems;
    } else {
      objString = objString + '&Memberitems=';
    }

    // if(Status){
    //   objString = objString + '&Status=' + Status;
    // } else {
    //   objString = objString + '&Status=';
    // }
    // if(Priority){
    //   objString = objString + '&Priority=' + Priority;
    // } else {
    //   objString = objString + '&Priority=';
    // }

    //console.log(objString);

    fetch(apihost+'/de/vue/exercisegroup/getexercisegroup/'+id+'/save'+objString)
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
      const data = JSON.parse(result);
      //console.log(data);
      this.fetchUser = data;
    })
    .catch(() => { throw 'Network error' });
  
    notify({
      message: currentUser.Translation.vueappExercisegroupFormSubmitSuccess,
      position: {
        my: 'center top',
        at: 'center top',
      },
    }, 'success', 3000);

  },    

},

};

</script>

<style>

</style>
