<template>
<svg version="1.1" id="logo-4trainingfitness" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 185.3 89.8" style="enable-background:new 0 0 185.3 89.8;" xml:space="preserve">
<path class="st0" d="M42.2,70.6c0,5.9,0,11.7,0,17.6c0,0.6,0,1-0.8,0.8c-3.2-0.7-6.5-1.6-9.5-2.9c-3.3-1.4-6.3-3.3-9.3-5.2
c-0.8-0.5-1.1-1.2-1.1-2.1c0-8.5,0.1-17.1,0.1-25.6c0-1.2-0.1-1.3-1.3-1.3c-4.1,0-8.2,0-12.2,0c-0.6,0-1-0.1-1-0.8
C7.2,46.9,7,42.9,7,38.8c0-0.9,0.3-1.1,1.2-1.1c4.1,0,8.2,0,12.2,0c0.8,0,1-0.3,1-1c0-4-0.2-8.1,0.4-12.1c0.4-2.8,1.2-5.4,3.1-7.7
c1.5-1.8,3.7-2.7,5.9-3.4c3.8-1.1,7.8-1.5,11.7-1.5c5.8,0,11.5-0.1,17.3,0.1c0.9,0,1.2,0.3,1.2,1.2c0,3.8,0,7.7,0,11.5
c0,0.8-0.4,1.1-1.1,1.1c-3.3,0-6.6,0-9.9,0c-1.3,0-2.6,0-3.8,0.4c-2.6,0.7-4.1,2.6-4.1,5.3c0,1.7,0,3.4,0,5c0,0.8,0.2,1.1,1.1,1.1
c5.2,0,10.4,0,15.6,0c2.8,0,2.3,0,2.3,2.3c-0.1,3.6-0.1,7.2,0,10.8c0,0.8-0.1,1.1-1,1.1c-5.5,0-11,0-16.5,0c-1.5,0-1.4-0.2-1.4,1.4
C42.2,59,42.2,64.8,42.2,70.6C42.2,70.6,42.2,70.6,42.2,70.6z"/>
<path class="st1" d="M51.4,7.7c-4,0-8,0-12.1,0c-3.7,0.1-7.2,0.7-10.8,1.7c-6.2,1.9-9.4,6.2-10.9,12.2c-0.9,3.5-0.7,7-0.8,10.5
c0,0.8-0.3,1.1-1.1,1.1c-4.3,0-8.7,0.1-13,0.1c-1.4,0-1.4,0-1.4,1.4c-0.3,6.1-0.1,12.3-0.1,18.4c0,0.3,0,0.7,0,1
c0.1,1.7,0.4,2,2.1,2.1c3.4,0.2,6.8-0.2,10.3-0.1c0.8,0,1.6,0,2.4,0c0.6,0,0.8,0.2,0.8,0.8c0,3.9,0,7.9,0,11.8
c0,1.6,0.1,3.3,0.1,4.9c0,0.4,0.1,0.9-0.4,1c-0.5,0.1-0.9-0.1-1.2-0.4c-0.6-0.7-1.2-1.5-1.7-2.2c-2.1-3-4.1-6.1-5.7-9.4
c-0.6-1.4-1.3-2.8-1.8-4.2c-0.2-0.6-0.6-0.7-1.1-0.7c-1.2,0-2.5,0-3.7,0c-1.1,0-1.4-0.3-1.4-1.4c0-1.5,0-2.9,0-4.4
c0.1-6,0.2-12-0.1-18.1c-0.1-2.4-0.1-2.4,2.2-2.4c1.1,0,2.1,0,3.2,0c0.4,0,0.7-0.1,0.9-0.5c0.4-1.1,0.9-2.2,1.3-3.4
c0.6-1.9,1.8-3.6,2.7-5.3c1.7-3.3,4-6.1,6.6-8.7c2.9-3,6.2-5.5,9.8-7.7c4.2-2.7,8.9-4.2,13.7-5.1c5.3-1.1,10.6-1.3,16-0.4
c4.7,0.8,9.2,2.1,13.4,4.1c1.4,0.7,2.6,1.6,3.9,2.4c1.6,1,3.1,2,4.5,3.2c2.7,2.3,5.2,4.7,7.2,7.6c1.4,2,2.9,4,4,6.2
c1.5,2.7,2.5,5.6,3.2,8.6c0.4,1.4,0.7,2.9,1,4.3c0.5,1.9,0.6,3.9,0.5,5.8c0,1.8,0,3.7,0,5.5c0,1.4-0.1,2.8-0.6,4.1
c-0.2,0.5-0.3,1.1-0.4,1.6C92.8,55.9,92.8,56,91,56c-8.2,0.1-16.4,0-24.6,0c-0.5,0-0.8-0.1-0.8-0.7c0-4.7,0-9.3,0-14
c0-2.4,0-4.8,0-7.2c0-0.7-0.2-0.9-0.9-0.9c-5.6,0-11.2,0-16.8,0c-0.4,0-1,0.4-1.2-0.5c-0.2-1.1,0-1.5,0.9-1.8
c1.5-0.5,3.1-0.6,4.7-0.6c4.2,0.1,8.4,0.3,12.6,0.2c0.6,0,0.7-0.1,0.7-0.7c0-7-0.1-14,0-21c0-0.9-0.3-1.1-1.1-1.1
C60.1,7.7,55.7,7.7,51.4,7.7C51.4,7.7,51.4,7.7,51.4,7.7z"/>
<path class="st0" d="M147.2,85c1.9,0,3.9,0,5.8,0c1.3,0,2.1-0.6,2.2-1.8c0.1-0.9-0.6-1.9-1.8-2.2c-0.4-0.1-0.9-0.1-1.3-0.1
c-2.4,0-4.7,0.1-7.1,0c-3.5-0.2-5.5-1.8-5.6-5.4c0-1.2-0.1-2.5,0.4-3.7c0.6-1.6,1.7-2.8,3.4-3.3c1.4-0.4,2.8-0.7,4.2-0.7
c3.7,0,7.4,0,11.1,0c0.9,0,1.2,0.4,1.2,1.2c0,0.9-0.2,1.9,0,2.8c0.1,0.4-0.1,0.7-0.6,0.7c-0.5,0-0.9,0.1-1.4,0.1
c-3.5,0.1-7-0.1-10.6,0.2c-0.8,0.1-1.5,0.4-1.8,1.2c-0.2,0.7-0.1,1.3,0.4,1.8c0.5,0.5,1.3,0.6,2,0.6c2.2-0.1,4.5-0.1,6.7,0.2
c1,0.1,1.9,0.2,2.8,0.7c2.9,1.5,4.2,4.8,3.1,7.9c-1.2,3.4-2.7,4.5-6.3,4.6c-4.5,0.2-9,0.1-13.5,0.1c-0.7,0-0.9-0.2-0.9-0.9
c0-1,0-1.9,0-2.9c0-0.7,0.3-1,1-1C143,85.1,145.1,85.1,147.2,85C147.2,85,147.2,85,147.2,85z"/>
<path class="st0" d="M171.6,85c2,0,4,0,6,0c1.6,0,2.3-0.6,2.2-2.3c0-0.7-0.3-1.1-0.9-1.4c-0.8-0.4-1.7-0.5-2.6-0.6
c-2.5,0-5.1,0.2-7.6-0.2c-1.4-0.2-2.8-0.6-3.5-1.9c-0.2-0.3-0.4-0.6-0.4-0.9c-0.3-1.9-0.5-3.7-0.1-5.6c0.4-1.7,1.5-2.8,2.9-3.5
c1.3-0.7,2.8-0.9,4.3-0.9c3.6,0,7.2,0,10.7,0c0.2,0,0.4,0,0.6,0c1.3,0.1,1.4,0.2,1.2,1.6c-0.1,0.7,0,1.4,0.1,2
c0.2,0.9-0.2,1.1-1,1.1c-3.1,0-6.1,0-9.2,0c-0.8,0-1.7,0-2.5,0.2c-1.4,0.3-1.9,1.1-1.5,2.5c0.2,0.6,0.7,0.8,1.2,0.9
c0.8,0.1,1.7,0.1,2.5,0.2c2.2,0,4.4,0.1,6.5,0.4c3,0.5,5,2.7,5,5.8c0,1.9-0.2,3.7-1.5,5.2c-1.1,1.4-2.6,1.9-4.3,2
c-4.7,0.3-9.4,0.2-14.1,0.1c-0.8,0-1-0.3-1-1c0-0.9,0-1.9,0-2.8c0-0.7,0.2-0.9,0.8-0.9C167.4,85,169.5,85,171.6,85
C171.6,85,171.6,85,171.6,85z"/>
<path class="st0" d="M129.6,76.6c1.9,0,3.7,0,5.6,0c0.6,0,0.9,0.1,0.9,0.7c0,1.1,0,2.2,0,3.3c0,0.5-0.3,0.6-0.7,0.6
c-3.5,0-7,0-10.6-0.1c-1.8,0-1.8,0-1.5,1.7c0.3,1.3,1.3,2.2,2.7,2.2c2.9,0,5.8,0,8.7,0c1.4,0,1.4,0,1.3,1.4c0,0.8,0,1.7,0,2.5
c0,0.5-0.1,0.8-0.7,0.8c-3.9-0.1-7.8,0.4-11.6-0.5c-4.5-1-6.8-4.5-7.1-8.6c-0.1-1.5-0.1-3.1,0.1-4.6c0.7-3.7,2.1-6.1,6.2-7.5
c2.5-0.8,5.1-0.8,7.8-0.8c1.4,0,2.8,0,4.2,0c0.7,0,1.1,0.2,1.1,0.9c0,1.1,0,2.2,0,3.3c0,0.6-0.5,0.6-0.9,0.6c-2.5,0-5,0-7.6,0
c-0.7,0-1.4,0.1-2,0.2c-1.8,0.4-2.5,1.4-2.5,3.3c0,0.6,0.3,0.6,0.7,0.6C125.7,76.6,127.6,76.6,129.6,76.6
C129.6,76.6,129.6,76.6,129.6,76.6z"/>
<path class="st0" d="M92.7,77.4c0-2.9,0-5.9,0-8.8c0-0.5,0.1-0.9,0.7-0.9c4.1,0,8.2-0.3,12.3,0.1c1.2,0.1,2.3,0.4,3.3,0.9
c2.3,1,3.4,2.9,3.5,5.4c0.2,4.2,0.1,8.3,0.1,12.5c0,0.7,0,1.4,0.1,2c0.1,0.7-0.2,1.1-0.9,1c-1.3,0-2.6,0-3.9,0
c-0.8,0-1.1-0.3-1.1-1.1c0-3.3,0-6.6,0-10c0-1.1-0.1-2.1-0.1-3.2c0.2-2-0.9-2.6-3-2.9c-1.6-0.2-3.2,0-4.8,0c-0.4,0-0.4,0.2-0.5,0.4
c-0.2,0.6-0.2,1.3-0.2,1.9c0,4.5,0,9.1,0,13.6c0,0.9-0.2,1.2-1.1,1.1c-1.1-0.1-2.2-0.1-3.4,0c-0.9,0.1-1.2-0.2-1.2-1.1
C92.6,84.9,92.7,81.2,92.7,77.4z"/>
<path class="st0" d="M56.4,81.2c0,2.6,0,5.1,0,7.7c0,0.6-0.2,0.9-0.8,0.9c-1.4,0-2.8,0-4.2-0.1c-0.7-0.1-1-0.3-1-1
c0-3.4,0-6.9,0-10.3c0-1.7-0.1-3.3-0.2-5c0-0.7-0.4-1-1-1c-0.7,0-1.3-0.1-2,0c-0.8,0.1-1-0.3-1.1-1c-0.1-1.1,0.2-2.1,0.1-3.1
c0-0.5,0.4-0.4,0.7-0.4c0.8,0,1.6,0.1,2.3,0.1c0.7,0,1-0.2,1-1c0-0.8,0.1-1.5,0.1-2.3c0-2.6,1.3-4,4-4.4c2.3-0.3,4.5-0.1,6.8-0.2
c0.5,0,0.7,0.2,0.7,0.7c0,1,0,2,0,3c0,0.6-0.4,0.7-0.8,0.7c-1,0-2-0.2-3.1,0.1c-1.2,0.3-2.1,2.1-1.4,3.1c0.2,0.3,0.5,0.3,0.8,0.3
c1.2,0,2.3,0,3.5,0c0.8,0,1.1,0.3,1,1.1c0,0.9,0,1.8,0,2.7c0,0.6-0.2,0.9-0.8,0.9c-1.2,0-2.4,0-3.5,0c-0.7,0-1,0.2-1,0.9
C56.4,75.9,56.4,78.6,56.4,81.2C56.4,81.2,56.4,81.2,56.4,81.2z"/>
<path class="st0" d="M77.3,78.6c0-1.7,0-3.4,0-5.1c0-0.9-0.3-1.3-1.2-1.2c-0.7,0.1-1.3,0-2,0c-0.5,0-0.8-0.2-0.8-0.7c0-1,0-2-0.1-3
c0-0.5,0.2-0.8,0.8-0.8c0.8,0,1.6,0,2.3,0c0.7,0,1-0.3,1-1c0-2,0-3.9,0-5.9c0-0.7,0.2-1,1-1c1.4,0,2.7,0,4.1,0
c0.6,0,0.9,0.2,0.8,0.9c0,2,0,4,0,6c0,0.8,0.3,1,1,1c1.3,0,2.6,0,3.9,0c0.8,0,1.1,0.4,1.1,1.1c-0.1,0.9-0.1,1.8-0.1,2.7
c0,0.6-0.2,0.8-0.8,0.8c-1.3,0-2.7,0-4-0.1c-0.8,0-1.1,0.2-1.1,1c0,2.9,0,5.8,0,8.7c0,0.4,0.1,0.9,0.2,1.3c0.3,1.1,0.9,1.8,2.1,1.9
c0.9,0.1,1.7,0.2,2.6,0.2c0.7,0,1,0.3,1,0.9c0,1,0.1,1.9,0.1,2.9c0,0.4-0.2,0.6-0.6,0.6c-1.9-0.1-3.9,0.2-5.8-0.1
c-3.5-0.6-5.5-2.9-5.5-6.4C77.4,81.7,77.4,80.1,77.3,78.6C77.4,78.6,77.4,78.6,77.3,78.6z"/>
<path class="st0" d="M70.5,78.8c-0.1,3.3-0.2,6.5,0.1,9.8c0.1,0.9-0.3,1.2-1.2,1.2c-1.4,0-2.7-0.1-4.1-0.1c-0.6,0-0.8-0.3-0.8-0.8
c0-0.9,0.1-1.8,0.1-2.7c0-3-0.1-6-0.1-8.9c0-2.5-0.1-5.1-0.1-7.6c0-1.8,0.2-2,2-1.9c1.1,0.1,2.1,0.1,3.2,0c0.7,0,1,0.3,1,1
C70.5,72.1,70.5,75.5,70.5,78.8z"/>
<path class="st0" d="M67.6,59.9c0.7,0,1.4,0,2.1,0c0.6,0,0.9,0.2,0.8,0.8c-0.1,1.2-0.1,2.3,0,3.5c0,0.7-0.2,1-0.9,1
c-1.3,0-2.7,0-4,0c-0.7,0-1.1-0.3-1.1-1c0-1.3-0.1-2.5-0.1-3.8c0-0.5,0.4-0.5,0.7-0.5C66,59.9,66.8,59.9,67.6,59.9
C67.6,59.9,67.6,59.9,67.6,59.9z"/>
</svg>
</template>

