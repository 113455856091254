<template>
  <div class="content-block">

  <h2 class="main-title">{{ msg }}</h2>

  <div class="dx-card has-paddings" style="overflow: visible;">

    <h1>h1. This is a very large header.</h1>
    <h2>h2. This is a large header.</h2>
    <h3>h3. This is a medium header.</h3>
    <h4>h4. This is a moderate header.</h4>
    <h5>h5. This is a small header.</h5>
    <h6>h6. This is a tiny header.</h6>

  </div><!-- dx-card -->


</div><!-- content-block -->

<div style="height: 30px;"></div>
<div class="content-block">
<h2 class="main-title">Paragraphs</h2>

<div class="dx-card has-paddings" style="overflow: visible;">

  <p>This is a paragraph. Paragraphs are preset with a font size, line height and spacing to match the overall vertical rhythm. To show what a paragraph looks like this needs a little more content so, did you know that there are storms occurring on Jupiter that are larger than the Earth? Pretty cool. Wrap strong around type to <strong>make it bold!</strong>. You can also use em to <em>italicize your words</em>.</p>

</div><!-- dx-card -->


</div><!-- content-block -->

<div style="height: 30px;"></div>
<div class="content-block">

<h2 class="main-title">Blockquotes <small>blockquote</small></h2>

<blockquote>
  Those people who think they know everything are a great annoyance to those of us who do.
  <cite>Isaac Asimov</cite>
</blockquote>

</div><!-- content-block -->

<div style="height: 30px;"></div>
<div class="content-block">

<h2 class="main-title">Unordered Lists <small>ul => li</small></h2>

<div class="dx-card has-paddings" style="overflow: visible;">

  <ul>
  <li>List item with a much longer description or more content.</li>
  <li>List item</li>
  <li>List item
    <ul>
      <li>Nested list item</li>
      <li>Nested list item</li>
      <li>Nested list item</li>
    </ul>
  </li>
  <li>List item</li>
  <li>List item</li>
  <li>List item</li>
</ul>
</div><!-- dx-card -->

</div><!-- content-block -->

<div style="height: 30px;"></div>
<div class="content-block">

<h2 class="main-title">Ordered Lists <small>ol => li</small></h2>

<div class="dx-card has-paddings" style="overflow: visible;">

  <ol>
  <li>Cheese (essential)</li>
  <li>Pepperoni</li>
  <li>Bacon
    <ol>
      <li>Normal bacon</li>
      <li>Canadian bacon</li>
    </ol>
  </li>
  <li>Sausage</li>
  <li>Onions</li>
  <li>Mushrooms</li>
</ol>

</div><!-- dx-card -->

</div><!-- content-block -->

<div style="height: 30px;"></div>
<div class="content-block">

<h2 class="main-title">Definition Lists <small>dt => dd</small></h2>

<div class="dx-card has-paddings" style="overflow: visible;">

<dl>
  <dt>Time</dt>
  <dd>The indefinite continued progress of existence and events in the past, present, and future regarded as a whole.</dd>
  <dt>Space</dt>
  <dd>A continuous area or expanse that is free, available, or unoccupied.</dd>
  <dd>The dimensions of height, depth, and width within which all things exist and move.</dd>
</dl>

</div><!-- dx-card -->

</div><!-- content-block -->


<div style="height: 30px;"></div>
<div class="content-block">

<h2 class="main-title">Code <small>code => code-inline/code-block</small></h2>

<p class="code-inline">I am not code, but I am displayed as if.</p>

<div style="height: 20px;"></div>

<code class="code-block">{
    "What I am": "I am a big chunk of code. I can have very long lines, I will not break and show a scrollbar instead.",
    ...
}</code>

</div><!-- content-block -->

<div style="height: 30px;"></div>
<div class="content-block">

  <div style="height: 10px;"></div>
  <p style="margin: 15px 5px; font-size: 11px;"><a href="https://get.foundation/sites/docs/typography-base.html" target="_blank" style="color: grey;"><i class="fa-solid fa-arrow-up-right-from-square"></i> https://get.foundation/sites/docs/typography-base.html</a></p>

</div><!-- content-block -->

</template>

<script>
 

export default {
  name: 'typography-example',
  mounted() {

  },
  data() {
    return {
      msg: 'Typography',
    };
  },
  methods: {

  },
  unmounted() {

  },
};
</script>

<style lang="scss">
</style>
