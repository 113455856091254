<template>

  <h2 class="content-block">
      <i class="dx-icon nav-icon fa-light fa-ticket"></i> 
      {{ title }}
    </h2>
  
    <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block">
  
      <div class="dx-card no-paddings dx-card-has-minheight">
  
        <DxDataGrid
          id="gridContainer"
          :data-source="customDataSource"
  
          :focused-row-enabled="false"
          :column-auto-width="true"
          :column-hiding-enabled="true"
          
          :allow-column-reordering="true"
          :show-borders="false"
  
          @editing-start="logEvent('EditingStart')"
          @init-new-row="logEvent('InitNewRow')"
          @row-inserting="logEvent('RowInserting')"
          @row-inserted="logEvent('RowInserted')"
          @row-updating="logEvent('RowUpdating')"
          @row-updated="logEvent('RowUpdated')"
          @row-removing="logEvent('RowRemoving')"
          @row-removed="logEvent('RowRemoved')"
          @saving="logEvent('Saving')"
          @saved="logEvent('Saved')"
          @edit-canceling="logEvent('EditCanceling')"
          @edit-canceled="logEvent('EditCanceled')"
        >
  
        <DxPaging :page-size="50" />
        <DxPager :show-page-size-selector="true" :show-info="true" />
        <DxFilterRow :visible="true" />
  
        <DxEditing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          mode="row"
        />
  
        <!-- <DxColumn 
          alignment='left' 
          data-field="id" 
          caption="Id"
          placeholder="Id"
          data-type="number"
          :width="90" 
          :allow-editing="false"
          :hiding-priority="0" 
        /> -->
  
        <!-- <DxColumn 
          alignment='left' 
          data-field="Customerid" 
          caption="Customer Id"
          placeholder="Customer Id"
          data-type="number"
          :width="90" 
          :allow-editing="false"
          :hiding-priority="11" 
        /> -->
  
        <DxColumn 
          alignment='left' 
          data-field="Avatar" 
          caption=""
          placeholder=""
          :allow-sorting="false"
          :allow-editing="false"
          :allow-filtering="false"
          cell-template="picTemplate"
          :width="80" 
          :hiding-priority="22" 
        />

        <template #picTemplate="{ data: content }">
          <div class="grid-x align-middle ck-itemrow">
            <div class="cell">
              <div class="image-thumb" v-if="content.data.Picthumb"
                v-bind:style="{ 'background-image': 'url(' + content.data.Picthumb + '?v=' + timestamp + ')' }" >
              </div>
            </div>
          </div>
        </template>
  
        <DxColumn
          alignment='left' 
          data-field="Subject"
          :caption="currentUser.Translation.vueappTaskSubject"
          :placeholder="currentUser.Translation.vueappTaskSubject"
          cell-template="subjectTemplate"
          :hiding-priority="99"
        />
        <template #subjectTemplate="{ data: content }">
          <div class="grid-x align-middle ck-itemrow">
            <div class="cell">
              <p class="ck-overflow-ellipsis strong">
                {{ content.data.Subject }}
              </p>
              <p class="ck-overflow-ellipsis small">
              </p>
            </div>
          </div>
        </template>
  
        <!-- <DxColumn
          alignment='left' 
          data-field="Description"
          :caption="currentUser.Translation.vueappTaskDescription"
          :placeholder="currentUser.Translation.vueappTaskDescription"
          :hiding-priority="7"
        /> -->

        <!-- <DxColumn
          alignment='left' 
          data-field="Status"
          editor-type="dxSelectBox"
          :caption="currentUser.Translation.vueappTaskStatus"
          :placeholder="currentUser.Translation.vueappTaskStatus"
          :editor-options="statusEditorOptions"
          :validation-rules="validationRules.status"
          :hiding-priority="9"
        /> -->
  
        <!-- <DxColumn
          alignment='left' 
          data-field="Priority"
          editor-type="dxSelectBox"
          :caption="currentUser.Translation.vueappTaskPriority"
          :placeholder="currentUser.Translation.vueappTaskPriority"
          :editor-options="priorityEditorOptions"
          :validation-rules="validationRules.priority"
          :hiding-priority="8"
        /> -->
  
        <!-- <DxColumn
          alignment='left' 
          data-field="Description"
          :caption="currentUser.Translation.vueappTaskDescription"
          :placeholder="currentUser.Translation.vueappTaskDescription"
          :hiding-priority="7"
        /> -->
  
        <!-- <DxColumn
          alignment='left' 
          data-field="CreationDate"
          data-type="date"
          format="dd.MM.yyyy"
          :caption="currentUser.Translation.vueappGlobalCreationDate"
          :placeholder="currentUser.Translation.vueappGlobalCreationDate"
          :allow-editing="false"
          :hiding-priority="6"
        /> -->
  
        <!-- <DxColumn
          alignment='left' 
          data-field="ModificationDate"
          data-type="date"
          format="dd.MM.yyyy"
          :caption="currentUser.Translation.vueappGlobalModificationDate"
          :placeholder="currentUser.Translation.vueappGlobalModificationDate"
          :allow-editing="false"
          :hiding-priority="5"
        /> -->
  
        <DxMasterDetail
          :enabled="true"
          template="shopInfo"
        />
  
  
        <template #shopInfo="{ data: content }">
  
          <div class="grid-x -grid-margin-x">
            <div class="shrink cell">
              <div class="form-avatar">
                <div v-if="content.data.Picthumb !=''">
                  <img :src="content.data.Picthumb + '?v=' + timestamp" />
                </div>
                <div v-else>
                  Oh no 😢
                </div>
              </div>
            </div>
            <div class="auto cell">
  
              <DxFileUploader
                name="upfile"
                class="uploadbutton"
                :upload-url="apihost+'/uploadshop.php?id=' + content.data.id"
                :accept="accept"
                :multiple="false"
                :upload-mode="uploadMode"
                :allowed-file-extensions="['.jpg', '.jpeg', '.png']"
                :min-file-size="1024"
                :max-file-size="10240*1024"
                :on-files-uploaded="onFilesUploaded"
                label-text=""
                :select-button-text="currentUser.Translation.vueappShopUploadPicButton"
                @value-changed="e => files = e.value"
              />
  
            </div>
          </div>
  
          <form 
            style="padding: 0;"
            :id="content.data.id"
            @submit="handleSubmit"
          >
  
            <DxForm 
              :id="content.data.id"
              :form-data="content.data"
              :read-only="false"
              @initialized="saveFormInstance"
              :show-colon-after-label="true"
              :show-validation-summary="true"
              validation-group="currentUserData"
            >
  
              <DxSimpleItem 
                data-field="id"
                help-text=""
                css-class="hide"
              >
              </DxSimpleItem>
  
  
              <DxSimpleItem
                data-field="Subject"
                help-text=""
              >
                <DxLabel :text="currentUser.Translation.vueappTaskSubject"/>
              </DxSimpleItem>
  
  
  
              <DxSimpleItem 
                data-field="Description"
                help-text=""
              >
                <DxLabel :text="currentUser.Translation.vueappTaskDescription"/>
              </DxSimpleItem>
  
              <DxButtonItem
                horizontal-alignment="left"
                :button-options="buttonOptions"
              />
  
            </DxForm>
  
          </form>
  
        </template>
  
      </DxDataGrid>
  
    </div><!-- dx-card -->
    
    </div><!-- content-block -->
  
    <div v-else class="content-block">
      Oh no 😢
    </div><!-- content-block -->
  
    <div class="content-block hide">
      <div id="events">
  
        <div class="grid-x grid-margin-x"> 
          <div class="auto cell"> 
            <div class="caption">
              Fired events:
            </div>
            <ul class="menu vertical">
            <li
              v-for="(event, index) in events"
              :key="index"
            >{{ event }}</li>
            </ul>
          </div>
          <div class="shrink cell"> 
            <DxButton
              id="clear"
              text="Clear"
              @click="clearEvents()"
            />
          </div>
        </div><!-- grid-x -->
  
      </div><!-- events -->
    </div><!-- content-block -->
  
  </template>
  
  <script>

  import { DxFileUploader } from 'devextreme-vue/file-uploader';
  
  import {
    DxDataGrid, 
    DxFilterRow, 
    DxColumn, 
    DxEditing, 
    DxPager, 
    DxPaging, 
    DxButton,
    DxMasterDetail,
    //DxLookup, 
  } from 'devextreme-vue/data-grid';
  //import { employees } from '../datamembers.js';
  
  import CustomStore from 'devextreme/data/custom_store';
  import notify from 'devextreme/ui/notify';
  
  import DxForm, {
    DxSimpleItem,
    DxButtonItem,
    DxLabel,
    //DxGroupItem,
    //DxRequiredRule,
    //DxCompareRule,
    //DxRangeRule,
    //DxStringLengthRule,
    //DxPatternRule,
    //DxEmailRule,
    //DxAsyncRule,
  } from 'devextreme-vue/form';
  
  import auth from "../auth";

  import { 
    apihost, 
  } from "../api";
  //console.log(apihost);
  
  const colCountByScreen = {
    xs: 1,
    sm: 1,
    md: 3,
    lg: 4
  }
  
  let currentUser;
  
  auth.getUser().then((e) => {
    if(e.data){
      //console.log(e.data);
      currentUser = e.data;
    } // e.data
  }); //auth
  
  function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
  }
  
  const customDataSource = new CustomStore({
    key: 'id',
    load: () => {
      return fetch(apihost+'/de/vue/shop/list/')
      .then(handleErrors)
      .then(response => response.text())
      .then(data => {
      //console.log(`response text`, text)
      return JSON.parse(data)
      })
      .catch(() => { throw 'Network error' });
    },
    insert: (values) => {
      //console.log(values);
      const key = '0';
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/shop/getshop/'+key+'/insert'+objString, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    remove: (key) => {
      //console.log(key);
      return fetch(apihost+'/de/vue/shop/getshop/'+key+'/remove', {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    update: (key, values) => {
      // console.log(key);
      // console.log(values);
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/shop/getshop/'+key+'/save'+objString, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    }
  
  });
  
  export default {
  
    mounted() {
  
      //REFRESH https://stackoverflow.com/questions/44094629/i-have-page-refreshing-on-vuejs-single-page-app
      //this.$router.push('/shoput');
  
      // auth.getUser().then((e) => {
      //   if(e.data){
      //     // console.log(e.data);
      //     // console.log(e.data.id);
      //     // console.log(e.data.Language);
  
      //     //DATA
      //     setTimeout(() => {
      //       fetch(apihost+'/de/vue/member/getmember/'+e.data.id+'/view')
      //       .then(response => response.text())
      //       .then(result => {
      //       const data = JSON.parse(result);
      //       //console.log(data.Avatar);
      //       this.fetchUser = data;
      //       })
      //       .catch(() => { throw 'Network error' });
          
      //     }, 0);
  
      //   } // e.data
        
      // }); //auth
  
      this.timestamp = this.printTimestamp();
  
    },
  
    components: {
      DxFileUploader,
      DxDataGrid,
      DxMasterDetail,
      DxFilterRow,
      DxColumn,
      DxEditing,
      DxPager,
      DxPaging,
      DxButton,
      DxForm,
      DxSimpleItem,
      DxButtonItem,
      DxLabel,
      //DxGroupItem,
      //DxRequiredRule,
      //DxCompareRule,
      //DxPatternRule,
      //DxRangeRule,
      //DxEmailRule,
      //DxStringLengthRule,
      //DxAutocomplete,
      //DxAsyncRule,
  
  
      //DxLookup,
    },
    data() {
      return {
        apihost,
        
        title: currentUser.Translation.vueappNavMemberShopitem,
        events: [],
        formInstance: null,
        currentUser,
        customDataSource,
        colCountByScreen,
  
        //uploader
        formRefName: 'tree-view',
        multiple: false,
        accept: '*',
        uploadMode: 'instantly',
        //uploadUrl: apihost+'/uploadshop.php?id=' + currentUser.id,
        fileTypesSource: [
          { name: 'All types', value: '*' },
          { name: 'Images', value: 'image/*' },
          { name: 'Videos', value: 'video/*' },
        ],
        files: [],
        //end uploader        
        
  
        calendarOptions: { 
          displayFormat: "dd.MM.yyyy"
        },
        buttonOptions: {
          text: currentUser.Translation.vueappShopFormSubmit,
          type: 'success',
          useSubmitBehavior: true,
        },
        dateBoxOptions: {
          invalidDateShop:
            'The date must have the following format: MM/dd/yyyy',
        },
        checkBoxOptions: {
          text: 'I agree to the Terms and Conditions',
          value: false,
        },
        phoneEditorOptions: {
          mask: '+1 (X00) 000-0000',
          maskRules: {
            X: /[02-9]/,
          },
          maskInvalidShop: 'The phone must have a correct USA phone format',
        },
  
        statusPattern: /^[^0-9]+$/,
        priorityPattern: /^[^0-9]+$/,
        shopPattern: /^[^0-9]+$/,
  
        // phonePattern: /^[02-9]\d{9}$/,
        // maxDate: new Date().setFullYear(new Date().getFullYear() - 21),
        
        validationRules: {
  
          status: [
            { type: 'required', shop: currentUser.Translation.vueappTaskStatusShop },
          ],
          priority: [
            { type: 'required', shop: currentUser.Translation.vueappTaskPriorityShop },
          ],
        },
        statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
        priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },
  
      };
    },
    methods: {
      validateForm(e) {
        e.component.validate();
      },
      
      logEvent(eventName) {
        //console.log(this.events);
        this.events.unshift(eventName);
      },
      clearEvents() {
        this.events = [];
      },
  
      printTimestamp: function () {
        return Date.now();
      },

      onFilesUploaded() {
        //console.log(e);
        //console.log('finished');
        //this.$router.push('/');

        setTimeout(() => {
          window.location.reload();
        }, 2000);

      },
  
      saveFormInstance(e) {
          this.formInstance = e.component;
        },
        changePasswordMode(name) {
          const editor = this.formInstance.getEditor(name);
          editor.option(
            'mode',
            editor.option('mode') === 'text' ? 'password' : 'text',
          );
        },
        passwordComparison() {
          return this.fetchUser.Password;
        },
        checkComparison() {
          return true;
        },
  
        handleSubmit(e) {
          e.preventDefault();
  
          const id = this.formInstance.getEditor('id').option('value');  
          const Subject = this.formInstance.getEditor('Subject').option('value');  
          const Description = this.formInstance.getEditor('Description').option('value');  
          // const Status = this.formInstance.getEditor('Status').option('value');  
          // const Priority = this.formInstance.getEditor('Priority').option('value');  
  
          let objString = '?id=' + id;
  
          if(Subject){
            objString = objString + '&Subject=' + Subject;
          } else {
            objString = objString + '&Subject=';
          }
          if(Description){
            objString = objString + '&Description=' + Description;
          } else {
            objString = objString + '&Description=';
          }
          // if(Status){
          //   objString = objString + '&Status=' + Status;
          // } else {
          //   objString = objString + '&Status=';
          // }
          // if(Priority){
          //   objString = objString + '&Priority=' + Priority;
          // } else {
          //   objString = objString + '&Priority=';
          // }
  
          //console.log(objString);
  
          fetch(apihost+'/de/vue/shop/getshop/'+id+'/save'+objString)
            .then(handleErrors)
            .then(response => response.text())
            .then(result => {
            const data = JSON.parse(result);
            //console.log(data);
            this.fetchUser = data;
          })
          .catch(() => { throw 'Network error' });
        
          notify({
            message: currentUser.Translation.vueappShopFormSubmitSuccess,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'success', 3000);
    
        },    
    },
  };
  
  </script>
  
  <style>
  .uploadbutton .dx-button {
    padding: 10px 0;
    margin: 10px 0;
  }
  </style>
    