<template>

  <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="-content-block">
    
    <div class="-dx-card -no-paddings" style="position: relative;">

      <!-- allow-adding="false" then this -->
      <div class="" style="position: absolute; top: 15px; right: 15px; z-index: 1;">
        <button @click="createOption2" class="cx-button tiny"><i class="fa-light fa-ballot-check"></i> Hilfsmittel erstellen</button>
      </div>

      <DxDataGrid
        ref="myDataGrid"
        id="gridContainer"
        :data-source="customDataSource"

        :focused-row-enabled="false"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        
        :allow-column-reordering="true"
        :show-borders="false"

        @editing-start="logEvent('EditingStart')"
        @init-new-row="logEvent('InitNewRow')"
        @row-inserting="logEvent('RowInserting')"
        @row-inserted="logEvent('RowInserted')"
        @row-updating="logEvent('RowUpdating')"
        @row-updated="logEvent('RowUpdated')"
        @row-removing="logEvent('RowRemoving')"
        @row-removed="logEvent('RowRemoved')"
        @saving="logEvent('Saving')"
        @saved="logEvent('Saved')"
        @edit-canceling="logEvent('EditCanceling')"
        @edit-canceled="logEvent('EditCanceled')"
      >

      <DxPaging :page-size="500" />
      <DxPager :show-page-size-selector="false" :show-info="true" />
      <DxFilterRow :visible="true" />

      <DxEditing
        :allow-updating="false"
        :allow-deleting="true"
        :allow-adding="true"
        mode="row"
      />

      <DxColumn
        alignment='left' 
        data-field="Subject"
        :caption="currentUser.Translation.vueappTaskSubject"
        :placeholder="currentUser.Translation.vueappTaskSubject"
        cell-template="subjectTemplate"
        :hiding-priority="99"
      />
      <template #subjectTemplate="{ data: content }">

        <div class="grid-x align-middle">

          <div class="shrink cell">
            <div style="width: 0; height: 50px;">
            </div>

          </div><!-- /shrink cell main -->

          <div class="auto cell">
            <div class="grid-x align-middle">

              <div class="shrink cell">
                <!-- row 1 -->
                <div class="fixedwidth-listitem">
                  <p class="ck-overflow-ellipsis strong small">
                    <span v-if="content.data.Subject">
                      {{ content.data.Subject }}
                    </span>
                    <span v-else class="alert-color">
                      <i class="fa-light fa-triangle-exclamation"></i> Bezeichnung
                    </span>
                  </p>
                </div><!-- /fixedwidth-listitem -->
              </div><!-- /shrink cell -->

              <div class="shrink cell hide">
                <!-- row 2 -->
                <div class="fixedwidth-listitem">
                  <p class="ck-overflow-ellipsis -strong small">
                    <span v-if="content.data.Itemkey">
                      {{ content.data.Itemkey }}
                    </span>
                    <span v-else class="alert-color">
                      <i class="fa-light fa-triangle-exclamation"></i> Key
                    </span>
                  </p>
                </div><!-- /fixedwidth-listitem -->
              </div><!-- /shrink cell -->


            </div><!-- /grid-x sub-->

          </div><!-- /auto cell main -->
        </div><!-- /grid-x main -->

      </template>

      <DxMasterDetail
        :enabled="true"
        template="masterDetailTemplate"
      />
      <template #masterDetailTemplate="{ data: content }">
        <DetailTemplate
          :template-data="content"
        />
      </template>

    </DxDataGrid>

    </div><!-- dx-card -->
    
    </div><!-- content-block -->
  
    <div v-else class="content-block">
      Oh no 😢
    </div><!-- content-block -->
  
    <div class="content-block hide">
      <div id="events">
  
        <div class="grid-x grid-margin-x"> 
          <div class="auto cell"> 
            <div class="caption">
              Fired events:
            </div>
            <ul class="menu vertical">
            <li
              v-for="(event, index) in events"
              :key="index"
            >{{ event }}</li>
            </ul>
          </div>
          <div class="shrink cell"> 
            <DxButton
              id="clear"
              text="Clear"
              @click="clearEvents()"
            />
          </div>
        </div><!-- grid-x -->
  
      </div><!-- events -->
    </div><!-- content-block -->
  
  </template>
  
  <script>
  
  import {
    DxDataGrid, 
    DxFilterRow, 
    DxColumn, 
    DxEditing, 
    DxPager, 
    DxPaging, 
    DxButton,
    DxMasterDetail,
    //DxSelection,
    // DxScrolling,

    //DxLookup, 
  } from 'devextreme-vue/data-grid';
  
  import CustomStore from 'devextreme/data/custom_store';
  import DetailTemplate from '../parts/exerciseoption2-detail-master.vue';

  import auth from "../auth";

  import { 
    apihost, 
  } from "../api";
  //console.log(apihost);

  const colCountByScreen = {
    xs: 1,
    sm: 1,
    md: 2,
    lg: 3
  }

  let currentUser;
  
  auth.getUser().then((e) => {
    if(e.data){
      currentUser = e.data;
      // console.log(currentUser.id);
      // console.log(currentUser.Constant.languages);
      // console.log(currentUser.Email);
      // console.log(currentUser.Language);
    } // e.data
  }); //auth
  
  function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
  }

  const customDataSource = new CustomStore({
    key: 'id',
    load: () => {
      return fetch(apihost+'/'+currentUser.Language+'/vue/exerciseoption2/list/')
      .then(handleErrors)
      .then(response => response.text())
      .then(data => {
      //console.log(`response text`, text)
      return JSON.parse(data)
      })
      .catch(() => { throw 'Network error' });
    },
    insert: (values) => {
      //console.log(values);
      const key = '0';
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/exerciseoption2/getexerciseoption2/'+key+'/insert'+objString, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    remove: (key) => {
      //console.log(key);
      return fetch(apihost+'/de/vue/exerciseoption2/getexerciseoption2/'+key+'/remove', {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    update: (key, values) => {
      // console.log(key);
      // console.log(values);
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/exerciseoption2/getexerciseoption2/'+key+'/save'+objString, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    }
  
  });
  

  export default {
  
    mounted() {

      this.timestamp = this.printTimestamp();
  
    },
  
    components: {
      DxDataGrid,
      DxMasterDetail,
      DxFilterRow,
      DxColumn,
      DxEditing,
      DxPager,
      DxPaging,
      DxButton,
      DetailTemplate,
      // DxForm,
      // DxGroupItem,
      // DxSimpleItem,
      // DxButtonItem,
      // DxLabel,
      // DxFileUploader,
      // DxDropDownBox,
      // DxSelection,
      // DxScrolling,
      //DxTextBox,
      //DxRequiredRule,
      //DxCompareRule,
      //DxPatternRule,
      //DxRangeRule,
      //DxEmailRule,
      //DxStringLengthRule,
      //DxAutocomplete,
      //DxAsyncRule,
      //DxLookup,


    },
    data() {
      return {
        apihost,

        title: currentUser.Translation.vueappNavMemberExerciseoption2,
        events: [],
        formInstance: null,
        // statuses,
        // priorities,
        // exerciseoption2types,
        // exerciseoption2category,
        currentUser,
        customDataSource,
        colCountByScreen,

        // //Option 1
        // option1DataSource,
        // option1Columns,
        // option1BoxValue,
        // //changeOption1,
        // //readyOption1,

        // //Option 2
        // option2DataSource,
        // option2Columns,
        // option2BoxValue,
        // //changeOption2,
        // //readyOption2,
  
        calendarOptions: { 
          displayFormat: "dd.MM.yyyy"
        },
        buttonOptions: {
          text: currentUser.Translation.vueappExerciseoption2FormSubmit,
          type: 'success',
          useSubmitBehavior: true,
        },
        dateBoxOptions: {
          invalidDateExerciseoption2:
            'The date must have the following format: MM/dd/yyyy',
        },
        checkBoxOptions: {
          text: 'I agree to the Terms and Conditions',
          value: false,
        },
        phoneEditorOptions: {
          mask: '+1 (X00) 000-0000',
          maskRules: {
            X: /[02-9]/,
          },
          maskInvalidExerciseoption2: 'The phone must have a correct USA phone format',
        },
  
        statusPattern: /^[^0-9]+$/,
        priorityPattern: /^[^0-9]+$/,
        exerciseoption2Pattern: /^[^0-9]+$/,
  
        // phonePattern: /^[02-9]\d{9}$/,
        // maxDate: new Date().setFullYear(new Date().getFullYear() - 21),
        
        validationRules: {
  
          // status: [
          //   { type: 'required', exerciseoption2: currentUser.Translation.vueappTaskStatusExerciseoption2 },
          // ],
          // priority: [
          //   { type: 'required', exerciseoption2: currentUser.Translation.vueappTaskPriorityExerciseoption2 },
          // ],
        },
        //statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
        //priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },
        // statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
        // priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },
      };
    },
    methods: {

      validateForm(e) {
        e.component.validate();
      },
      
      logEvent(eventName) {
        //console.log(this.events);
        this.events.unshift(eventName);
      },
      clearEvents() {
        this.events = [];
      },
  
      printTimestamp: function () {
        return Date.now();
      },
  
      createOption2() {
        this.$refs['myDataGrid'].instance.addRow();
      },

    },
  };
  
  
</script>

<style>

</style>
    