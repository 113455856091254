<template>

<div style="padding: 16px 16px 16px 16px;">
  <p><i class="dx-icon -nav-icon tabnav-icon fa-light fa-messages"></i>{{ currentUser.Translation.vueappNavMemberMessages }}</p>
  <!-- {{ customerData.id }} -->
  <!-- {{ customerData }} -->
  <!-- {{ customerData.Tasklist }} -->
</div>

<div class="dx-card -no-paddings">

<DxDataGrid
  id="gridContainer"
  :data-source="customDataSource"
  :focused-row-enabled="false"
  :column-auto-width="true"
  :column-hiding-enabled="true"
  :allow-column-reordering="true"
  :show-borders="false"
>

<DxPaging :page-size="50" />
<DxPager :show-page-size-selector="true" :show-info="true" />
<DxFilterRow :visible="true" />

<DxEditing
  :allow-updating="true"
  :allow-deleting="true"
  :allow-adding="true"
  mode="row"
/>

<!-- <DxColumn 
  alignment='left' 
  data-field="id" 
  caption="Id"
  placeholder="Id"
  data-type="number"
  :width="90" 
  :allow-editing="false"
  :hiding-priority="12" 
/>

<DxColumn 
  alignment='left' 
  data-field="Customerid" 
  caption="Customer Id"
  placeholder="Customer Id"
  data-type="number"
  :width="90" 
  :allow-editing="false"
  :hiding-priority="11" 
/> -->

<DxColumn
  alignment='left' 
  data-field="Subject"
  :caption="currentUser.Translation.vueappTaskSubject"
  :placeholder="currentUser.Translation.vueappTaskSubject"
  :hiding-priority="10"
/>

<DxColumn
  alignment='left' 
  data-field="Status"
  editor-type="dxSelectBox"
  :caption="currentUser.Translation.vueappTaskStatus"
  :placeholder="currentUser.Translation.vueappTaskStatus"
  :editor-options="statusEditorOptions"
  :validation-rules="validationRules.status"
  :hiding-priority="9"

/>
<DxColumn
  alignment='left' 
  data-field="Priority"
  editor-type="dxSelectBox"
  :caption="currentUser.Translation.vueappTaskPriority"
  :placeholder="currentUser.Translation.vueappTaskPriority"
  :editor-options="priorityEditorOptions"
  :validation-rules="validationRules.priority"
  :hiding-priority="8"
/>

<DxColumn
  alignment='left' 
  data-field="Description"
  :caption="currentUser.Translation.vueappTaskDescription"
  :placeholder="currentUser.Translation.vueappTaskDescription"
  :hiding-priority="7"
/>

<DxColumn
  alignment='left' 
  data-field="CreationDate"
  data-type="date"
  format="dd.MM.yyyy"
  :caption="currentUser.Translation.vueappGlobalCreationDate"
  :placeholder="currentUser.Translation.vueappGlobalCreationDate"
  :allow-editing="false"
  :hiding-priority="6"
/>

<DxColumn
  alignment='left' 
  data-field="ModificationDate"
  data-type="date"
  format="dd.MM.yyyy"
  :caption="currentUser.Translation.vueappGlobalModificationDate"
  :placeholder="currentUser.Translation.vueappGlobalModificationDate"
  :allow-editing="false"
  :hiding-priority="5"
/>

</DxDataGrid>

</div><!-- dx-card -->

</template>

<script>
 
 let currentUser;
 let customerId;

import {
  DxDataGrid, 
  DxFilterRow, 
  DxColumn, 
  DxEditing, 
  DxPager, 
  DxPaging, 
  //DxButton,
  
  //DxLookup, 
} from 'devextreme-vue/data-grid';

import CustomStore from 'devextreme/data/custom_store';

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;
  } // e.data
}); //auth

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
  }

  
  const customDataSource = new CustomStore({
    key: 'id',
    load: () => {
      return fetch(apihost+'/de/vue/message/list/?Customerid='+customerId)
      .then(handleErrors)
      .then(response => response.text())
      .then(data => {
      //console.log(`response text`, text)
      return JSON.parse(data)
      })
      .catch(() => { throw 'Network error' });
    },
    insert: (values) => {
      //console.log(values);
      const key = '0';
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/message/getmessage/'+key+'/insert'+objString+'&Customerid='+customerId, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    remove: (key) => {
      //console.log(key);
      return fetch(apihost+'/de/vue/message/getmessage/'+key+'/remove', {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    update: (key, values) => {
      // console.log(key);
      // console.log(values);
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/message/getmessage/'+key+'/save'+objString, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    }

  });

 // console.log(customDataSource)


export default {
  name: 'customer-tasklist',

  props: {
    customerData: Object,
  },

  components: {
    DxDataGrid,
    DxFilterRow,
    DxColumn,
    DxEditing,
    DxPager,
    DxPaging,
    //DxButton,
    //DxLookup,
  },

  mounted() {
    // console.log(this.customerData.id);
    // customerId = '1189';
    if(this.customerData){
      customerId = this.customerData.id;
    }
  },

  data() {
    return {
      apihost,

      msg: 'Tasks (part)',
      // statuses,
      // priorities,
      currentUser,
      customDataSource,

      validationRules: {
        status: [
          { type: 'required', message: 'Status is required.' },
        ],
        priority: [
          { type: 'required', message: 'Priority is required.' },
        ],
      },
      statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
      priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },

    };
  },
  methods: {

  },
  unmounted() {

  },
};
</script>

<style>

</style>
