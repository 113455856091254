<template>
  <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block">

    <div class="grid-x grid-margin-x">
      <div class="large-auto cell">
        <h2><i class="dx-icon nav-icon fa-light fa-right-to-bracket"></i> {{ title }}</h2>
      </div>
    </div><!-- grid-x -->

    <div class="grid-x grid-margin-x">
      <div class="cell large-3" v-for="device in devices" :key="device.id">
        <div class="dx-card" style="text-align:center;padding:10px">
          <p>
            <span style="font-weight:bold;">Gerät: </span><span>{{ device.DeviceName }}</span>
          </p>
          <p>
            <span style="font-weight:bold;">Ort: </span><span>{{ device.LocationName }}</span>
          </p>
          <p>
            <span style="font-weight:bold;">Seriennummer: </span><span>{{ device.SerialNumber }}</span>
          </p>
          <p>
            <span v-if="SerialConnected.get(device.SerialNumber)" style="font-weight:bold; color:green">Online</span>
            <span v-else style="font-weight:bold; color:red">Offline</span>
          </p>
          <button class="cx-button medium" @click="() => { sendOpenCommand(device.SerialNumber); }">Öffnen</button>
        </div>
      </div>
    </div>

    <div class="dx-card checkin-list">
      <DxList
        :data-source="checkinDataSource"
      >
        <template #item="{ data }">
          <div>

          <div class="grid-x align-middle">

          <div class="shrink cell">

            <!-- avatar -->
            <div class="user-thumb" style="margin: 2px 10px 2px 5px;"
              v-bind:style="{ 'background-image': 'url(' + data.Avatar + '?v=' + timestamp + ')' }" >
            </div>
            <!-- /avatar -->

          </div><!-- /shrink cell main -->

          <div class="auto cell">

            <div class="grid-x align-middle">

              <div class="shrink cell">
                <!-- row 1 -->
                <div class="fixedwidth-listitem">
                  <p class="ck-overflow-ellipsis strong small">
                    <span>
                      {{ data.FirstName }} {{ data.LastName }}
                    </span>
                  </p>
                  <p class="ck-overflow-ellipsis -strong small">
                    <span>{{ epochToDateTime(data.Timestamp) }}</span>
                  </p>

                </div><!-- /fixedwidth-listitem -->
              </div><!-- /shrink cell -->

              <div class="shrink cell">
                <!-- row 2 -->
                <div class="fixedwidth-listitem">

                  <p class="ck-overflow-ellipsis strong small">
                    <span v-if='data.GrantAccess === "true"' class="success-color">
                    <i class="fa-light fa-right-to-bracket"></i> Check{{ data.Status }}
                    </span>
                    <span v-else class="alert-color">
                    <i class='fa-solid fa-octagon-xmark'></i> Verweigert
                    </span>
                  </p>

                  <p class="ck-overflow-ellipsis small">
                    <span v-if='data.GrantAccess === "true"' class="success-color">
                    <i class="fa-light fa-circle-check"></i> Access Granted
                    </span>
                    <span v-else class="alert-color">
                     <i class="fa-light fa-triangle-exclamation"></i> {{ data.Status }}
                    </span>
                  </p>

                </div><!-- /fixedwidth-listitem -->
              </div><!-- /shrink cell -->

              <div class="shrink cell">
                <!-- row 3 -->
                <div class="fixedwidth-listitem">

                  <p class="ck-overflow-ellipsis small">
                    RFID: {{ data.Rfid }}
                  </p>

                  <p class="ck-overflow-ellipsis small">
                    DeviceId: {{ data.DeviceId }}
                  </p>

                </div><!-- /fixedwidth-listitem -->
              </div><!-- /shrink cell -->

              <div class="shrink cell">
                <!-- row 4 -->
                <div class="fixedwidth-listitem">

                  <p class="ck-overflow-ellipsis small">
                    LocationId: {{ data.LocationId }}
                  </p>

                  <p class="ck-overflow-ellipsis small">
                    Id: {{ data.id }}
                  </p>

                </div><!-- /fixedwidth-listitem -->
              </div><!-- /shrink cell -->

            </div><!-- /grid-x sub-->

          </div><!-- /auto cell main -->
          </div><!-- /grid-x main -->

        </div>
        </template>

      </DxList>
    </div><!-- dx-card checkin-list -->

  </div><!-- content-block -->

  <div v-else class="content-block">
    Oh no 😢
  </div><!-- content-block -->


</template>

<script>
import auth from "../auth";
import { apihost } from "../api";

import DxList from "devextreme-vue/list";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";

import { ref } from 'vue';
import notify from 'devextreme/ui/notify';

let currentUser;
let devices = ref([]);
let SerialConnected = ref(new Map());

const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';
let checkinDataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load: (opts) => {
      let params = "?";
      
      [
        'take',
        'skip',
      ].forEach(i => {
          if(i in opts && isNotEmpty(opts[i])){
            params += `${i}=${JSON.stringify(opts[i])}&`;
          }
        });
      params = params.slice(0, -1);

      return fetch(`${apihost}/vue/websocket/list/${params}`)
        .then(r => r.json())
        .then(o => {
          return {
            data: o.data,
            totalCount: o.totalCount,
            summary: []
          };
        })
        .catch(() => { throw 'Network Error' });
    }
  }),
  paginate: true,
  pageSize: 25
});

auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
  } // e.data
}); //auth

export default {
  mounted() {

    //redirect to Member App
    if(currentUser.Role == 'Customer'){
      const currentUrl = window.location.href;
      const redirecturl = currentUrl.replace("/#/sap-membercheckin", "/#/dashboard");
      window.location.href = redirecturl;
    }

    fetch(`${apihost}/vue/device/list/`)
      .then(r => r.json())
      .then(o => {
        for(const device of o){
          const deviceIds = JSON.parse(device.DeviceId);  
          for(const deviceId of deviceIds) {
            devices.value.push({
              DeviceName: device.DeviceName,
              LocationName: device.LocationName,
              SerialNumber: deviceId,
            });

            SerialConnected.value.set(deviceId, false);
          }
        }
        this.getConnectionState();
      })
      .catch((e) => { console.log(e); throw 'Network error'; });

  },
  data() {
    return {
      title: currentUser.Translation.vueappNavMemberCheckin,
      currentUser,
      checkinDataSource,
      devices,
      SerialConnected,
    };
  },
  methods: {
    epochToDateTime(timestamp) {
      const date = new Date(timestamp * 1000);
      const dateString = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
      const timeString = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      return `${timeString} ${dateString}`;
    },

    sendOpenCommand(serialnumber) {
      const host = window.location.hostname.split('.')[0];
      const clientSocket = new WebSocket(`wss://node.mysportapp.ch/wss/${host}/ctl`);
      const commandObject = {
        cmd: "open",
        target: String(serialnumber),
      };

      clientSocket.addEventListener("close", (e) => {
        if(e.code == 1000) {
          notify("Befehl erfolgreich gesendet.", "success", 3000);
        } else {
          notify("Befehl konnte nicht erfolgreich gesendet werden.", "error", 3000);
        }
      });
      clientSocket.addEventListener("open", () => {
        clientSocket.send(JSON.stringify(commandObject));
      });
    },

    getConnectionState() {
      let result = Array(SerialConnected.value.size, false);
      const host = window.location.hostname.split('.')[0]; 
      const clientSocket = new WebSocket(`wss://node.mysportapp.ch/wss/${host}/ctl`);
      const commandObject = {
        cmd: "checkSerial",
        targets: devices.value.map((d) => d.SerialNumber) 
      };
      clientSocket.addEventListener("open", () => {
        clientSocket.send(JSON.stringify(commandObject));
      });
      clientSocket.addEventListener("message", (e) => {
        try{
          const replyObj = JSON.parse(e.data);
          result = replyObj.result; 
        } catch (e) { console.log(e); }
      });
      clientSocket.addEventListener("close", (e) => {
        if(e.code == 1000){
          devices.value.forEach((device, i) => {
             SerialConnected.value.set(device.SerialNumber, result[i]);
          });
        }
      });
    },

  },
  components: {
    DxList,
  },
  unmounted() {
    devices.value = [];  
  },
};

</script>
