<template>
<svg version="1.1" id="logoPhysioplus" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 532 216" style="enable-background:new 0 0 532 216;" xml:space="preserve">
<path class="st0" d="M0,36.6c3.3-0.7,6.6-1.6,10-2.1c4.9-0.8,9.9-0.9,14.9-0.5c4.4,0.3,8.6,1.4,12.6,3.2c7.6,3.5,12,9.5,13.9,17.5
c1.1,4.6,1.1,9.2,0.8,13.9c-0.4,6.2-2.7,11.7-6.2,16.8c-2.4,3.5-5.9,5.7-9.5,7.6c-7,3.7-14.5,4.6-22.2,3.8
c-1.3-0.1-2.6-0.3-3.8-0.6c-1.1-0.3-1.3,0.2-1.2,1c0,0.7,0,1.5,0,2.2c0,8.8,0,17.7,0.1,26.5c0,2.7,0.1,2.8-2.7,2.7
c-2.2-0.1-4.4,0.1-6.6-0.2C0,97.8,0,67.2,0,36.6z M9.2,65.6C9.2,65.6,9.2,65.6,9.2,65.6c0,4.6,0,9.3,0,13.9c0,2.6,0,5.1,0,7.7
c0,0.8,0.1,1.5,1.2,1.7c4.8,0.7,9.5,1,14.4,0.3C33.5,88,40.4,82,42.6,73.4c0.6-2.3,0.9-4.6,1-7c0.3-4.1,0-8.2-1.6-12.1
c-3-7.6-9.1-11.6-17-12.9c-4.6-0.7-9.2-0.4-13.7,0.7c-2.1,0.5-2.2,0.5-2.2,2.7C9.2,51.7,9.2,58.6,9.2,65.6z"/>
<path class="st1" d="M74.1,0c0,1.6,0,3.1,0,4.7c0,10,0,20.1,0,30.1c0,1.5,0,1.5,1.4,1c7.7-2.6,15.6-3.2,23.6-1.3
c6.1,1.4,10.5,4.9,12.8,10.7c0.9,2.1,1.2,4.4,1.3,6.7c0.2,4.3,0.2,8.6,0.2,12.9c0,10.1,0,20.2,0,30.2c0,1-0.2,1.5-1.4,1.4
c-2.3-0.1-4.6-0.1-6.9,0c-1,0-1.2-0.3-1.2-1.2c0-12.4,0-24.7,0-37.1c0-2.2-0.1-4.5-0.4-6.7c-0.7-5.6-4.6-9.1-10.2-10
c-6.5-1.1-12.6,0.2-18.6,2.8c-0.6,0.3-0.8,0.7-0.7,1.3c0,1.1,0,2.2,0,3.2c0,15.4,0,30.9,0,46.3c0,1.1-0.2,1.5-1.4,1.4
c-2.2-0.1-4.3-0.1-6.5,0c-1.3,0.1-1.6-0.3-1.6-1.6c0.1-6,0-11.9,0-17.9c0-23.5,0-47.1,0-70.6c0-2.2,0.1-4.3,0.2-6.5
C68,0,71,0,74.1,0z"/>
<path class="st1" d="M345.1,171.7c0-14.3,0-28.5,0-42.8c0-1.2,0.4-1.6,1.5-1.9c7.5-1.7,15-2.8,22.7-2.3c6.7,0.4,13.1,1.9,18.5,6.1
c6,4.6,8.9,11,9.6,18.4c0.6,5.5,0.4,11-1.3,16.2c-3.4,10.6-10.8,16.9-21.6,19.4c-5.5,1.3-11.1,1.2-16.7,0.3
c-1.1-0.2-1.4-0.1-1.4,1.2c0.1,8.8,0,17.6,0,26.4c0,0.4,0,0.7,0,1.1c0.1,2.1,0.1,2.1-2,2.1c-2.7,0-5.3-0.1-8,0
c-1.1,0-1.4-0.4-1.4-1.4C345.1,200.3,345.1,186,345.1,171.7C345.1,171.7,345.1,171.7,345.1,171.7z M356.5,155.4
C356.5,155.4,356.5,155.4,356.5,155.4c0,6.5,0,13-0.1,19.5c0,0.7,0.4,0.9,0.9,0.9c4.2,0.7,8.5,1,12.8,0.3
c7.5-1.4,12.7-5.4,15.1-12.8c1.4-4.5,1.5-9.1,0.8-13.7c-0.9-6.3-3.9-11.3-10.1-13.9c-6.1-2.6-12.3-2.3-18.6-0.9
c-0.8,0.2-0.8,0.7-0.8,1.3C356.5,142.6,356.5,149,356.5,155.4z"/>
<path class="st1" d="M482.8,154.4c0,9.2,0,18.5,0,27.7c0,1-0.4,1.3-1.2,1.4c-8.6,1.6-17.2,2.6-25.9,2.3c-4.4-0.2-8.6-0.8-12.5-2.9
c-4.7-2.5-7.4-6.4-8.3-11.7c-0.5-3-0.7-6-0.7-9c-0.1-11.9,0-23.7-0.1-35.6c0-1.1,0.3-1.3,1.3-1.3c2.9,0.1,5.7,0,8.6,0
c1.6,0,1.6,0,1.6,1.6c0,10.6,0,21.2,0,31.7c0,2.9,0,5.8,0.4,8.7c0.9,5.5,4.1,8.5,9.6,9.1c4.7,0.5,9.4,0.2,14-0.5
c1.1-0.2,1.6-0.5,1.6-1.8c-0.1-15.8,0-31.7-0.1-47.5c0-0.9,0.2-1.2,1.1-1.2c3,0.1,6.1,0.1,9.1,0c1,0,1.3,0.3,1.2,1.3
C482.8,135.9,482.8,145.2,482.8,154.4z"/>
<path class="st0" d="M326.8,70.1c0,11.5,0,23.1,0,34.6c0,1.3,0.5,1.5,1.6,1.5c23.2,0,46.3,0,69.5,0c1.6,0,1.6,0,1.6,1.7
c0,3.1,0,3.1-3.1,3.1c-22.5,0-45.1,0-67.6,0c-2,0-2,0-2,2c0,23,0,46,0,69c0,1.6,0,1.7-1.7,1.6c-3.6,0-3.1,0.4-3.1-3.1
c0-19.4,0-38.9,0-58.3c0-3.3-0.1-6.5,0-9.8c0-1.1-0.4-1.4-1.4-1.4c-4.1,0-8.1,0-12.2,0c-19,0-38.1,0-57.1,0c-1.9,0-1.9,0-1.8-1.8
c0.1-2.9,0.1-3,3.1-3c22.5,0,45.1,0,67.6,0c0.3,0,0.7,0,1,0c0.7,0,1-0.2,1-0.9c0-0.6,0-1.2,0-1.7c0-22.7,0-45.4,0-68
c0-1.9,0-1.9,2-1.9c2.8,0,2.8,0,2.8,2.8C326.8,47.5,326.8,58.8,326.8,70.1z"/>
<path class="st1" d="M310.9,68c0,3.7-0.9,8.7-2.9,13.5c-5.1,12.3-16.1,17.4-28.4,16.7c-5.8-0.3-11.2-2.1-15.8-5.7
c-2.5-2-4.4-4.3-5.9-7.1c-4-7.3-5-15.2-4.3-23.3c0.5-5.5,1.7-10.9,4.6-15.7c4.8-8,12-12,21.1-12.9c5.9-0.6,11.7,0.2,17.1,3
c5,2.6,8.6,6.5,11,11.6C310.1,53.8,311.2,60,310.9,68z M282.3,41c-9.2-0.1-15.8,4.8-18.3,13.7c-2,7-1.9,14-0.4,21
c1,4.8,3.4,8.8,7.5,11.7c2.9,2.1,6.3,2.9,9.8,3.1c9.3,0.5,16.5-3.7,19.3-12.7c2.1-6.7,2.1-13.5,0.9-20.4c-0.7-4-2.2-7.6-4.8-10.6
C292.6,42.5,287.7,41,282.3,41z"/>
<path class="st1" d="M147.8,89c0.5-2,1.1-4,1.9-5.9c6.7-15.6,12.5-31.4,17.1-47.8c0.2-0.7,0.5-1.2,1.3-1.1c2.6,0,5.2,0,7.9,0
c0.9,0,0.8,0.5,0.6,1.1c-1.1,3-2.1,6.1-3.2,9.1c-3.7,10.3-7.1,20.7-11.5,30.7c-4.8,11.1-9.8,22.1-14.8,33.1
c-2.9,6.4-5.6,12.8-8.2,19.3c-0.3,0.9-0.8,1.2-1.7,1.2c-2.2-0.1-4.4,0-6.6,0c-0.9,0-1.3-0.1-0.9-1.2c4-9.7,8.1-19.3,12.4-28.9
c0.3-0.7,0.1-1.3-0.1-1.9c-4.7-10.8-9.2-21.6-13.1-32.7c-3.2-9-6-18.1-8.4-27.3c-0.1-0.4-0.2-0.8-0.4-1.2c-0.5-1.4-0.5-1.5,1-1.5
c2.4,0,4.8,0,7.2,0c0.7,0,1.2,0.1,1.4,0.9c1.9,8,4.3,15.8,6.9,23.6c2.8,8.5,5.9,16.9,9.2,25.2c0.7,1.6,1.1,3.4,1.6,5.1
C147.6,89,147.7,89,147.8,89z"/>
<path class="st1" d="M494.3,177.8c0-1.5,0-2.9,0-4.4c0-0.3-0.2-0.7,0.2-0.9c0.4-0.3,0.7,0.1,1,0.4c2.9,2.3,6.1,3.8,9.6,4.4
c3.5,0.6,7,0.7,10.4-0.6c5.5-2.1,6.8-8.3,2.5-12.3c-1.7-1.6-3.7-2.6-5.8-3.7c-4-2-8-3.9-11.6-6.5c-8.9-6.2-7.7-19.2-0.3-25.1
c4.2-3.3,9-4.6,14.3-4.8c5.2-0.2,10.1,0.9,14.9,3c0.5,0.2,0.6,0.6,0.6,0.9c0,3.1,0.1,6.1,0.1,9.2c0,0.8-0.5,0.4-0.8,0.2
c-3.4-2.2-7-3.7-11-4c-2.6-0.2-5.2,0-7.7,0.9c-3.8,1.5-5.4,4.4-4.6,8.4c0.3,1.5,1.3,2.6,2.5,3.4c2.3,1.7,4.9,2.8,7.4,4.1
c2.9,1.5,5.9,2.8,8.5,4.7c3.7,2.8,6.5,6.4,7.3,11c1.1,5.8-1.5,12.7-6.6,16.3c-3.8,2.6-8,3.9-12.5,4.2c-6.2,0.5-12.1-0.7-17.7-3.2
c-0.6-0.2-0.7-0.6-0.7-1.1C494.3,181,494.3,179.4,494.3,177.8C494.3,177.8,494.3,177.8,494.3,177.8z"/>
<path class="st1" d="M420.2,139.2c0,15,0,29.9,0,44.9c0,0.9-0.2,1.2-1.1,1.1c-3.2,0-6.3,0-9.5,0c-0.7,0-1-0.1-1-0.9
c0-24.7,0-49.4,0-74.1c0-5.4,0-10.8-0.1-16.2c0-0.7,0.2-1.1,1-1.1c3.2,0,6.4,0,9.6,0c1,0,1,0.5,1,1.3
C420.2,109.2,420.2,124.2,420.2,139.2C420.2,139.2,420.2,139.2,420.2,139.2z"/>
<path class="st1" d="M217.6,46.2c-0.9-0.3-1.4-0.9-2-1.3c-5.6-4.1-11.6-5.1-18.1-2.8c-4.7,1.7-6.6,5.3-5.7,10.2
c0.3,1.5,1.2,2.6,2.3,3.6c2.5,2.2,5.6,3.5,8.6,5c3.2,1.7,6.5,3.2,9.4,5.3c5.6,4,8.1,9.4,7.3,16.2c-0.8,7.1-5,11.7-11.6,14.1
c-8.5,3.1-16.8,2.4-24.8-1.9c-0.6-0.3-0.7-0.7-0.7-1.3c0-2.6,0-5.1,0-7.7c0-0.3-0.2-0.7,0.2-0.9c0.4-0.2,0.6,0.2,0.8,0.4
c3.2,2.5,6.6,4.5,10.6,5.1c3.8,0.6,7.5,0.5,11-1.1c6.7-3,6.4-10.8,3.1-14.4c-2.3-2.6-5.3-4-8.3-5.6c-3.4-1.8-6.8-3.4-10-5.5
c-10.6-7-9.4-21,1-27.4c4.1-2.5,8.7-3.2,13.4-3.1c4.5,0.1,8.8,1.2,12.8,3.3c0.4,0.2,0.8,0.4,0.8,1
C217.6,40.3,217.6,43.2,217.6,46.2z"/>
<path class="st1" d="M231.6,65.3c0-10,0-20,0-30c0-0.9,0.3-1.1,1.1-1.1c2.4,0,4.7,0,7.1,0c1,0,1.2,0.3,1.2,1.2c0,3.9,0,7.9,0,11.8
c0,15.9,0,31.9,0,47.8c0,1.2-0.3,1.6-1.5,1.5c-2.1-0.1-4.2-0.1-6.4,0c-1.2,0.1-1.6-0.2-1.6-1.5C231.6,85.1,231.6,75.2,231.6,65.3
C231.6,65.3,231.6,65.3,231.6,65.3z"/>
<path class="st1" d="M241.3,14.8c0,1.5,0,2.9,0,4.4c0,0.7-0.2,1.1-1,1c-2.9,0-5.7,0-8.6,0c-0.8,0-1-0.2-1-1c0.1-3,0.2-6,0-8.9
c-0.1-0.8,0.3-1,0.9-1c2.9,0,5.9,0,8.9,0c0.8,0,0.8,0.5,0.8,1.1C241.3,11.8,241.3,13.3,241.3,14.8
C241.3,14.8,241.3,14.8,241.3,14.8z"/>
</svg>
</template>
