export const doingTasks = [
		{ id: 1552, text: 'Front Kick' },
  { id: 1550, text: 'Gorilla-Lauf seitlich' },
  { id: 7, text: 'Brochure Design Review' },
  { id: 8, text: 'Website Re-Design Plan' },
  { id: 9, text: 'Rollout of New Website and Marketing Brochures' },
  { id: 10, text: 'Create 2018 Sales Report' },
  { id: 11, text: 'Direct vs Online Sales Comparison Report' },
  { id: 12, text: 'Review 2018 Sales Report and Approve 2019 Plans' },
  { id: 13, text: 'Submit Signed NDA' },
  { id: 14, text: 'Update Revenue Projections' },
  { id: 15, text: 'Review Revenue Projections' },
  { id: 16, text: 'Comment on Revenue Projections' },
  { id: 17, text: 'Scan Health Insurance Forms' },
  { id: 18, text: 'Sign Health Insurance Forms' },
  { id: 19, text: 'Follow up with West Coast Stores' },
  { id: 20, text: 'Follow up with East Coast Stores' },
  { id: 21, text: 'Submit Refund Report for 2019 Recall' },
  { id: 22, text: 'Give Final Approval for Refunds' },
  { id: 23, text: 'Prepare Product Recall Report' },
  { id: 24, text: 'Review Product Recall Report by Engineering Team' },
  { id: 25, text: 'Review Training Course for any Omissions' },
  { id: 26, text: 'Review Overtime Report' },
  { id: 27, text: 'Submit Overtime Request Forms' },
  { id: 28, text: 'Overtime Approval Guidelines' },
  { id: 29, text: 'Create Report on Customer Feedback' },
  { id: 30, text: 'Review Customer Feedback Report' }
];

export const plannedTasks = [
		{ id: 5, text: 'New Brochures' },
  { id: 2, text: 'Prepare 2019 Marketing Plan' },
  { id: 3, text: 'Update Personnel Files' },
  { id: 4, text: 'Review Health Insurance Options Under the Affordable Care Act' }
];


export const shifts = [{
  text: 'First shift',
  offset: -120,
}, {
  text: 'Second shift',
  offset: 360,
}, {
  text: 'Third shift',
  offset: 840,
}];

export const appointments = [{
  text: 'Website Re-Design Plan',
  startDate: '2024-03-29T16:00:00.000Z',
  endDate: '2024-03-29T18:00:00.000Z',
  recurrenceRule: '',
  eventType: '',
  locationId: '',
}, {
  text: 'Approve Personal Computer Upgrade Plan',
  startDate: '2024-03-31T01:30:00.000Z',
  endDate: '2024-03-31T03:00:00.000Z',
  recurrenceRule: '',
  eventType: '',
  locationId: '',
}, {
  text: 'Final Budget Review',
  startDate: '2024-03-30T16:30:00.000Z',
  endDate: '2024-03-30T18:05:00.000Z',
  recurrenceRule: '',
  eventType: '',
  locationId: '',
}, {
  text: 'New Brochures',
  startDate: '2024-04-01T23:30:00.000Z',
  endDate: '2024-04-02T02:30:00.000Z',
  recurrenceRule: '',
  eventType: '',
  locationId: '',
}, {
  text: 'Approve New Online Marketing Strategy',
  startDate: '2024-03-31T16:30:00.000Z',
  endDate: '2024-03-31T18:30:00.000Z',
  recurrenceRule: '',
  eventType: '',
  locationId: '',
}, {
  text: 'Prepare 2021 Marketing Plan',
  startDate: '2024-04-01T16:30:00.000Z',
  endDate: '2024-04-01T18:00:00.000Z',
  recurrenceRule: '',
  eventType: '',
  locationId: '',
}, {
  text: 'Brochure Design Review',
  startDate: '2024-04-02T17:30:00.000Z',
  endDate: '2024-04-02T19:00:00.000Z',
  recurrenceRule: '',
  eventType: '',
  locationId: '',
}, {
  text: 'Create Icons for Website',
  startDate: '2024-04-01T18:00:00.000Z',
  endDate: '2024-04-01T19:30:00.000Z',
  recurrenceRule: '',
  eventType: '',
  locationId: '',
}, {
  text: 'Submit New Website Design',
  startDate: '2024-04-02T09:30:00.000Z',
  endDate: '2024-04-02T11:00:00.000Z',
  recurrenceRule: '',
  eventType: '',
  locationId: '',
}, {
  text: 'Launch New Website',
  startDate: '2024-04-01T01:30:00.000Z',
  endDate: '2024-04-01T03:00:00.000Z',
  recurrenceRule: 'FREQ=WEEKLY;BYDAY=WE;INTERVAL=2',
  eventType: '',
  locationId: '',
}, {
  text: 'Install New Router in Dev Room',
  startDate: '2024-03-29T08:00:00.000Z',
  endDate: '2024-03-29T09:00:00.000Z',
  recurrenceRule: '',
  eventType: '',
  locationId: '',
}, {
  text: 'Upgrade Personal Computers',
  startDate: '2024-03-30T01:00:00.000Z',
  endDate: '2024-03-30T03:00:00.000Z',
  recurrenceRule: '',
  eventType: '',
  locationId: '',
}, {
  text: 'Install New Database',
  startDate: '2024-03-31T08:30:00.000Z',
  endDate: '2024-03-31T10:00:00.000Z',
  recurrenceRule: '',
  eventType: '',
  locationId: '',
}, {
  text: 'Update Customer Shipping Profiles',
  startDate: '2024-04-01T09:30:00.000Z',
  endDate: '2024-04-01T11:00:00.000Z',
  recurrenceRule: 'FREQ=WEEKLY;BYDAY=TH',
  eventType: '',
  locationId: '',
}, {
  text: 'Upgrade Server Hardware',
  startDate: '2024-03-30T08:30:00.000Z',
  endDate: '2024-03-30T11:00:00.000Z',
  recurrenceRule: 'FREQ=MONTHLY;BYMONTHDAY=30',
  eventType: '',
  locationId: '',
}];