<template>
<svg version="1.1" id="Logo-Life-Fit" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
y="0px" viewBox="0 0 1145.4 379.8" style="enable-background:new 0 0 1145.4 379.8;" xml:space="preserve">
<path class="st0" d="M152.6,79.6c-11,0-18.7,9.1-19,17.1l0.2,27.3l37.6-0.1V96.1C171.4,88.8,164.9,79.6,152.6,79.6z M253.9,116.9
c4.3-1.2,13.4-1.7,13.4-1.7l1-35.8c-39.8,0.7-48.2,10.9-54.5,19.7c-12.7,17.4-11.5,40.2-11.5,69.8c0,37.2,0,205.4,0,205.4h37
c0,0-0.2-140.4-0.2-184.8h28.3l-0.5-33.4H240c-2-2.5-0.7-11.6-0.7-15.3C239.2,128,243.8,119.6,253.9,116.9L253.9,116.9z M85.1,339.6
c-11.7-1.9-16.5-9.5-18.5-21.1c-0.1-53.8-1.2-221-1.2-221H28.2c0,0,0,164.7,0,195.3c0,22.3,0.4,43.7,8.7,57.6
c10.1,16.9,27.2,24,54.5,24h28.2v-33.8C119.5,340.6,93,340.9,85.1,339.6z"/>
<path class="st1" d="M3.3,271.2"/>
<rect x="133.6" y="156.3" class="st0" width="37.7" height="219.5"/>
<path class="st1" d="M1023.5,78.8c12.3,0,18.8,9.2,18.8,16.5V123l-37.6,0.1l-0.2-27.3C1004.8,87.9,1012.5,78.8,1023.5,78.8
L1023.5,78.8z"/>
<rect x="1004.6" y="156.2" class="st1" width="37.7" height="219.5"/>
<path class="st0" d="M326,290.3c2-0.6,64.7-29.3,67.9-32.8c3.2-3.5,3.6-7.9,3.8-12s0-41.1,0-41.1s-1.3-50.9-57.3-52.7
c-30.3-1-60.3,20.6-60,53.9c0-4.5,0,118,0,118c0,24,14.6,53.9,56.1,54.3s57-25.4,59.6-43.1s1-31.3,1-31.3h-35.7
c0,0,4.4,40.1-18,41.7c-12.3,0.9-25-5.9-26-20.1s-0.8-24.6-0.4-27.5C317.4,294.8,323.9,291,326,290.3L326,290.3z M317.8,204.5
c1.4-7.1,6-16.2,18-18.3c11.6-2,23.1,7.5,23.3,16.6c0,0,2.5,26.4,1.7,29c-1.1,3.5-4,4.9-4,4.9c-4.1,2.2-31.2,16.7-34.1,18.2
c-2.8,1.5-5.4-0.6-5.5-3.9C317.1,242.9,317.2,207.4,317.8,204.5L317.8,204.5z"/>
<path class="st1" d="M980.4,131.5V98.2l-25.8-0.5c-15.2,0.2-33.7,1.5-45.5,10.5c-21,16.1-19.5,38.4-19.5,61.5v205.9H926V255.2
c0-5.1,4.1-5.6,5.6-5.6c6.5,0,42.4,0,42.4,0v-35.3h-44.5c0,0-3.6,1.1-3.6-2.9V155c0,0,0.5-10.6,6.3-16.2s8.4-6.6,18.2-6.6
L980.4,131.5L980.4,131.5z"/>
<path class="st1" d="M1114.1,103.9h-36.3c0,0-0.8,199.9,0,217c0.9,19.7,4.1,55.5,61.6,55.1c3.6,0,4.8-1.5,5.2-4.3
c0,0,0.2-18.4,0-23.5s-2.3-6.4-5.7-6.4s-21.3,1.2-23.8-21.4c-0.8-7.4-0.6-125.9-0.6-125.9c0.2-3.5,3.3-5.1,7.3-4.9h22.3v-33.9
l-25.6,0.2c-3.1-1.2-3.8-2.1-3.8-5.9C1114.6,150,1114.1,103.9,1114.1,103.9z"/>
<circle class="st0" cx="588.6" cy="101.4" r="32"/>
<path class="st0" d="M694.8,61c0,0,10.8,22.6-4.6,42.2S628,137.8,628,137.8s-58.8,19.5-76.6,28.3s-60.4,25.7-66,84.3
c-4,41.3,17.2,84.8,64.5,112.3c0,0-48.7-46.2-33.9-93.8c16.7-53.7,59.8-67.2,86.3-78.1c28.4-11.7,92.8-31.1,105.3-71.2
C717.9,86.6,694.8,61,694.8,61L694.8,61z"/>
<path class="st1" d="M687.5,167.3c0,0-20.5,10.7-38.4,19.2c-31.1,14.7-96,31.2-112.7,67.2c-24,51.6,2.5,126.3,104.9,126.2
c89,0,164.4-63.3,182-148.7C849,106,755.1-1.4,632.9,1.2c-57.2,1.2-110.4,36-122.6,52L522,66.5c0,0,98.5-84.3,211.7,11
c45.7,43,57,102.9,49.2,147.6c-10.1,58.1-44.8,94.5-68.9,110.6c-20,13.1-40.2,23.4-77.9,24.7c-38,1.3-75.2-32.2-71-68.1
c4.5-38.6,37.4-52.7,37.4-52.7s27-17.2,51.6-29.5l-14.5,52.2c0,0,25.2-27.4,34.4-48.4S687.5,167.3,687.5,167.3L687.5,167.3z"/>
<path class="st0" d="M470.2,32.8c0,0,15.2,6.2,41.5,34.1c30.7,32.7,39.5,83,39.5,83c-21.5,8.7-40.9,24.3-49.3,31.9
C499.4,184.1,532.6,103.9,470.2,32.8z"/>
<path class="st1" d="M495,91l-6-15.7c0,0-49.6,53.9-38.2,138.4C450.7,213.7,447.6,144.4,495,91z"/>
</svg>
</template>

