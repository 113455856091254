<template>

  <div class="content-block">
    <div class="dx-card _responsive-paddings has-paddings">
      <p>design-statistic</p>
    </div>
  </div>

</template>

<script>

</script>

<style lang="scss">
</style>
