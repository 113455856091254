<template>
  <div class="content-block">
    <footer class="footer">
      Copyright © {{new Date().getFullYear()}} {{this.$appInfo.title}} GmbH
      <br />All trademarks or registered trademarks are property of their respective owners.
    </footer>
  </div>
</template>

<style lang="scss">

</style>
