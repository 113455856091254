<template>

  <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block no-padding">
    <div class="grid-x grid-margin-x">
      <div class="large-auto cell">
        <h2><i class="dx-icon nav-icon fa-light fa-user-slash"></i> blockierte {{ title }}</h2>
      </div>
      <div class="large-shrink cell">
        <a href="/#/sap-memberactive" class="cx-button tiny" style="margin-right: 10px;"><i class="fa-light fa-users"></i> aktive</a>
        <a href="/#/sap-memberinactive" class="cx-button tiny disabled" style="margin-right: 10px;"><i class="fa-light fa-users"></i> inaktive</a>
        <a href="/#/sap-memberblocked" class="cx-button tiny" style="margin-right: 10px; opacity: 0.3;"><i class="fa-light fa-users-slash"></i> blockiert</a>
        <a href="/#/sap-memberinterested" class="cx-button tiny" style="margin-right: 10px;"><i class="fa-light fa-users-slash"></i> interessiert</a>
        <a href="/#/sap-memberall" class="cx-button tiny" style="margin-right: 10px;"><i class="fa-light fa-users"></i> alle</a>
      </div><!-- shrink cell -->
    </div><!-- grid-x -->
  

    <div class="dx-card -no-paddings" style="position: relative;">

      <!-- allow-adding="false" then this -->
      <div class="hide" style="position: absolute; top: 15px; right: 15px; z-index: 1;">
        <button @click="createMember" class="cx-button tiny"><i class="fa-light fa-user"></i> Kunde erstellen</button>
      </div>

      <DxDataGrid
        ref="myDataGrid"
        id="gridContainer"
        :data-source="customDataSource"

        :focused-row-enabled="false"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        
        :allow-column-reordering="true"
        :show-borders="false"

        @content-ready="getTotalCount"

        @editing-start="logEvent('EditingStart')"
        @init-new-row="logEvent('InitNewRow')"
        @row-inserting="logEvent('RowInserting')"
        @row-inserted="logEvent('RowInserted')"
        @row-updating="logEvent('RowUpdating')"
        @row-updated="logEvent('RowUpdated')"
        @row-removing="logEvent('RowRemoving')"
        @row-removed="logEvent('RowRemoved')"
        @saving="logEvent('Saving')"
        @saved="logEvent('Saved')"
        @edit-canceling="logEvent('EditCanceling')"
        @edit-canceled="logEvent('EditCanceled')"
      >

      <DxPaging :page-size="500" />
      <DxPager :show-page-size-selector="false" :show-info="true" />
      <DxFilterRow :visible="true" />

      <DxEditing
        :allow-updating="false"
        :allow-deleting="true"
        :allow-adding="false"
        mode="row"
      />

      <DxColumn 
        alignment='left' 
        data-field="Avatar" 
        :caption="totalCount + ' '"
        placeholder=""
        :allow-sorting="false"
        :allow-editing="false"
        :allow-filtering="false"
        cell-template="avatarTemplate"
        :width="70" 
        :hiding-priority="20" 
      />

      <template #avatarTemplate="{ data }">
        <div class="grid-x align-middle ck-itemrow">
          <div class="cell">
            <div class="user-thumb" v-if="data.value"
              v-bind:style="{ 'background-image': 'url(' + data.value + ')' }" >
            </div>
          </div>
        </div>
      </template>

      <DxColumn
        alignment='left' 
        data-field="Title"
        :caption="currentUser.Translation.vueappCustomerPrefix"
        :placeholder="currentUser.Translation.vueappCustomerPrefix"
        :editor-options="genderEditorOptions"
        :validation-rules="validationRules.gender"
        editor-type="dxSelectBox"
        :width="100" 
        :hiding-priority="10" 
      />
      <DxColumn 
        alignment='left' 
        data-field="FirstName"
        :caption="currentUser.Translation.vueappCustomerFirstName"
        :placeholder="currentUser.Translation.vueappCustomerFirstName"
        :validation-rules="validationRules.firstname"
        :width="150" 
        :hiding-priority="16" 
      />
      <DxColumn 
        alignment='left' 
        data-field="LastName"
        :caption="currentUser.Translation.vueappCustomerLastName"
        :placeholder="currentUser.Translation.vueappCustomerLastName"
        :validation-rules="validationRules.lastname"
        :width="150" 
        :hiding-priority="15" 
      />
      <DxColumn
        alignment='left' 
        data-field="Email"
        :caption="currentUser.Translation.vueappCustomerEmail"
        :placeholder="currentUser.Translation.vueappCustomerEmail"
        :validation-rules="validationRules.email"
        :width="200" 
        :hiding-priority="14" 
      />

      <DxColumn
        alignment='left' 
        data-field="LocationName"
        :caption="'Standort'"
        :placeholder="'Standort'"
        :editor-options="locationEditorOptions"
        :validation-rules="validationRules.gender"
        editor-type="dxSelectBox"
        :width="150" 
        :hiding-priority="13" 
      />

      <DxColumn
        alignment='left' 
        data-field="Search"
        :caption="title"
        :placeholder="'Suchen'"
        cell-template="subjectTemplate"
        :hiding-priority="1"
      />

      <template #subjectTemplate="{ data: content }">

        <div class="grid-x align-middle ck-itemrow dx-template-wrapper">

          <div class="auto cell">
            <p class="ck-overflow-ellipsis small -hide">

              <!-- <span v-if="content.data.LocationId" class="-hide -success-color">
                <i class="fa-light fa-location-crosshairs"></i>
                {{ content.data.LocationName }}
              </span>
              <span v-else class="alert-color">
                Standort: 
                <i class="fa-light fa-triangle-exclamation"></i> 
              </span>
              |
 -->
              <span v-if="content.data.Status == 'active'" class="success-color">
                <i class="fa-light fa-user"></i> {{ content.data.Status }}
              </span>
              <span v-if="content.data.Status == 'inactive'" class="warning-color">
                <i class="fa-light fa-user"></i> {{ content.data.Status }}
              </span>
              <span v-if="content.data.Status == 'blocked'" class="alert-color">
                <i class="fa-light fa-user-slash"></i> {{ content.data.Status }}
              </span>
              <span v-if="content.data.Status == 'register'" class="warning-color">
                <i class="fa-light fa-user-slash"></i> {{ content.data.Status }}
              </span>

              <span v-if="content.data.Age"> | Alter: {{ content.data.Age }}</span>
              <span v-else class="alert-color">
                | Alter: 
                <i class="fa-light fa-triangle-exclamation"></i> 
              </span>
              
            </p>

            <p class="ck-overflow-ellipsis small -hide">
              Kdnr:
              <span v-if="content.data.Kundennummer">
                {{ content.data.Kundennummer }}
              </span>
              <span v-else class="alert-color">
                <i class="fa-light fa-triangle-exclamation"></i> 
              </span>
              | 
              <span class="-hide">
                ID: {{ content.data.id }}
              </span>
              <!-- RFID:
              <span v-if="content.data.Rfid">
                  {{ content.data.Rfid }}
              </span>
              <span v-else class="alert-color">
                <i class="fa-light fa-triangle-exclamation"></i> 
              </span> -->
            </p>

          </div><!-- auto cell -->
          
        </div><!-- grid-x -->

      </template>

      <DxMasterDetail
        :enabled="true"
        template="taskInfo"
      />

      <template #taskInfo="{ data: content }">

        <div class="tabpanel-nopadding">

        <DxTabPanel
          :swipe-enabled="false"
        >

          <DxItem 
            :title="currentUser.Translation.vueappNavMemberProfile" 
            icon="fa-light fa-user"
            badge=""
          >
          <template #default>
            <CustomerProfile 
              :customerData="content.data"
            />
          </template>
          </DxItem>

          <DxItem 
          :title="currentUser.Translation.vueappNavMemberHealthcheck" 
          icon="fa-light fa-heart-pulse" 
          badge=""
          >
          <template #default>
            <CustomerHealthcheck 
              :customerData="content.data"
            />
          </template>
          </DxItem>

          <DxItem 
          :title="currentUser.Translation.vueappNavMemberMeasurements" 
          icon="fa-light fa-weight-scale" 
          badge=""
          >
          <template #default>
            <CustomerMeasurement 
              :customerData="content.data"
            />
          </template>
          </DxItem>

          <DxItem 
          :title="currentUser.Translation.vueappNavMemberTasks" 
          icon="fa-light fa-list-check" 
          badge=""
          >
          <template #default>
            <CustomerTasklist 
              :customerData="content.data"
            />
          </template>
          </DxItem>

          <DxItem 
          :title="currentUser.Translation.vueappNavMemberMessages" 
          icon="fa-light fa-messages" 
          badge=""
          >
          <template #default>
            <CustomerMessage 
              :customerData="content.data"
            />
          </template>
          </DxItem>

          <DxItem 
          :title="currentUser.Translation.vueappNavMemberCourses" 
          icon="fa-light fa-whistle" 
          badge=""
          >
          <template #default>
            <CustomerTraining
              :customerData="content.data"
            />
          </template>
          </DxItem>

          <DxItem 
          :title="currentUser.Translation.vueappNavMemberExercises" 
          icon="fa-light fa-dumbbell" 
          badge=""
          >
          <template #default>
            <CustomerExercises
              :customerData="content.data"
            />
          </template>
          </DxItem>

          
          <DxItem 
          :title="currentUser.Translation.vueappNavMemberContracts" 
          icon="fa-light fa-file-contract"
          badge=""
          >
          <template #default>
            <CustomerContract 
              :customerData="content.data"
            />
          </template>
          </DxItem>

          <DxItem 
          :title="currentUser.Translation.vueappNavMemberInvoices" 
          icon="fa-light fa-file-invoice"
          badge=""
          >
          <template #default>
            <CustomerInvoice 
              :customerData="content.data"
            />
          </template>
          </DxItem>

        </DxTabPanel>

      </div>

      </template>

      
    </DxDataGrid>
    
  </div><!-- dx-card -->

  </div><!-- content-block -->

  <div v-else class="content-block">
    Oh no 😢
  </div><!-- content-block -->

  <div class="content-block hide">
    <div id="events">

      <div class="grid-x grid-margin-x"> 
        <div class="auto cell"> 
          <div class="caption">
            Fired events:
          </div>
          <ul class="menu vertical">
          <li
            v-for="(event, index) in events"
            :key="index"
          >{{ event }}</li>
          </ul>
        </div>
        <div class="shrink cell"> 
          <DxButton
            id="clear"
            text="Clear"
            @click="clearEvents()"
          />
        </div>
      </div><!-- grid-x -->

    </div><!-- events -->
  </div><!-- content-block -->

</template>

<script>

  import {
    DxDataGrid, 
    DxFilterRow, 
    DxColumn, 
    DxEditing, 
    DxPager, 
    DxPaging, 
    DxButton,
    DxMasterDetail,
    //DxLookup, 
  } from 'devextreme-vue/data-grid';

  import CustomStore from 'devextreme/data/custom_store';
  import DxTabPanel, { DxItem } from "devextreme-vue/tab-panel";
  //import notify from 'devextreme/ui/notify';

  import CustomerProfile from "../parts/customer-profile";
  import CustomerTasklist from "../parts/customer-tasklist";
  import CustomerHealthcheck from "../parts/customer-healthcheck";
  import CustomerMeasurement from "../parts/customer-measurement";
  import CustomerTraining from "../parts/customer-course";
  import CustomerExercises from "../parts/customer-exercise";
  import CustomerInvoice from "../parts/customer-invoice";
  import CustomerContract from "../parts/customer-contract";
  import CustomerMessage from "../parts/customer-message";

  import auth from "../auth";

  import { 
    apihost, 
  } from "../api";
  //console.log(apihost);

  let currentUser;
  let totalCount;

  auth.getUser().then((e) => {
    if(e.data){
      //console.log(e.data);
      currentUser = e.data;
    } // e.data
  }); //auth

  function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
  }
  
  const customDataSource = new CustomStore({
    key: 'id',
    load: () => {
      const objString = '?case=customer&status=blocked';
      return fetch(apihost+'/de/vue/member/list/'+objString)
      .then(handleErrors)
      .then(response => response.text())
      .then(data => {
      //console.log(`response text`, text)
      return JSON.parse(data)
      })
      .catch(() => { throw 'Network error' });
    },
    insert: (values) => {
      //console.log(values);
      const key = '0';
      const objString = '?case=customer&Status=active&' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/member/getmember/'+key+'/insert'+objString, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    remove: (key) => {
      //console.log(key);
      return fetch(apihost+'/de/vue/member/getmember/'+key+'/remove', {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    update: (key, values) => {
      // console.log(key);
      // console.log(values);
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/member/getmember/'+key+'/save'+objString, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    }

  });

export default {

  components: {
    DxDataGrid,
    DxMasterDetail,
    DxFilterRow,
    DxColumn,
    DxEditing,
    DxPager,
    DxPaging,
    DxButton,
    DxTabPanel,
    DxItem,
    //DxLookup,

    //PARTS
    CustomerProfile,
    CustomerTasklist,
    CustomerHealthcheck,
    CustomerMeasurement,
    CustomerTraining,
    CustomerExercises,
    CustomerInvoice,
    CustomerContract,
    CustomerMessage,

  },

  mounted() {

    //redirect to Member App
    if(currentUser.Role == 'Customer'){
      const currentUrl = window.location.href;
      const redirecturl = currentUrl.replace("/#/sap-memberblocked", "/#/dashboard");
      window.location.href = redirecturl;
    }

    this.timestamp = this.printTimestamp();
  },

  data() {

    return {
      apihost,
      totalCount,

      title: currentUser.Translation.vueappNavMembersactive,
      events: [],
      currentUser,
      customDataSource,

      validationRules: {
        gender: [
          { type: 'required', message: currentUser.Translation.vueappCustomerPrefixMessage },
        ],
        firstname: [
          { type: 'required', message: currentUser.Translation.vueappCustomerFirstNameMessage },
        ],
        lastname: [
          { type: 'required', message: currentUser.Translation.vueappCustomerLastNameMessage },
        ],
        email: [
          { type: 'required', message: currentUser.Translation.vueappCustomerEmailMessage },
        ],
        role: [
          { type: 'required', message: currentUser.Translation.vueappCustomerRoleMessage },
        ],
        country: [
          { type: 'required', message: currentUser.Translation.vueappCustomerCountryMessage },
        ],
        language: [
          { type: 'required', message: currentUser.Translation.vueappCustomerLanguageMessage },
        ],
      },
      genderEditorOptions: { items: currentUser.Constant.genders, searchEnabled: true },
      roleEditorOptions: { items: currentUser.Constant.roles, searchEnabled: true },
      countryEditorOptions: { items: currentUser.Constant.countries, searchEnabled: true },
      languageEditorOptions: { items: currentUser.Constant.languages, searchEnabled: true },
      locationEditorOptions: { items: currentUser.Constant.locations, searchEnabled: true },

    };
  },
  methods: {

    getTotalCount(e) {
      this.totalCount = e.component.totalCount();
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
    },
    
    validateForm(e) {
      e.component.validate();
    },
    
    logEvent(eventName) {
      //console.log(this.events);
      this.events.unshift(eventName);
    },
    clearEvents() {
      this.events = [];
    },

    printTimestamp: function () {
      return Date.now();
    },

    createMember() {
      this.$refs['myDataGrid'].instance.addRow();
    },

  },
};

</script>
<style>

</style>
