<template>

<div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block">

  <h2>
    <i class="dx-icon nav-icon fa-light fa-grid-horizontal"></i> 
    {{ title }}     
  </h2>

  <div class="dx-card has-paddings">
    <p>Studio Dashboard</p>
  </div>

</div>
<div v-else class="content-block">
    Oh no 😢
</div><!-- content-block -->

</template>

<script>
import auth from "../auth";
import { locale, loadMessages } from "devextreme/localization";


let currentUser;

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;
    //Set Local for Dexextreme Components
    locale(currentUser.Language);
    //console.log(currentUser);
  } // e.data
}); //auth


export default {
  mounted() {

    //redirect to Member App
    if(currentUser.Role == 'Customer'){
      const currentUrl = window.location.href;
      const redirecturl = currentUrl.replace("/#/sap-dashboard", "/#/dashboard");
      window.location.href = redirecturl;
    }

  },
  data() {
    return {
      title: currentUser.Translation.vueappNavMemberDashboard,
      currentUser,

      locale,
      loadMessages,

    };
  },
  methods: {

  },

  components: {

  },

  unmounted() {

  },
};
</script>
