<template>
  <div>

    <!-- TABS -->
    <ul id="tabs" class="tabs hide" data-tabs style="border: none;">
      <li id="healthlink1" class="tabs-title is-active"><a href="#healthpanel1" aria-selected="true">1</a></li>
      <li id="healthlink2" class="tabs-title"><a href="#healthpanel2">2</a></li>
      <li id="healthlink3" class="tabs-title"><a href="#healthpanel3">3</a></li>
      <li id="healthlink4" class="tabs-title"><a href="#healthpanel4">4</a></li>
      <li id="healthlink5" class="tabs-title"><a href="#healthpanel5">5</a></li>
      <li id="healthlink6" class="tabs-title"><a href="#healthpanel6">6</a></li>
      <li id="healthlink7" class="tabs-title"><a href="#healthpanel7">7</a></li>
      <li id="healthlink8" class="tabs-title"><a href="#healthpanel8">8</a></li>
      <li id="healthlink9" class="tabs-title"><a href="#healthpanel9">9</a></li>
    </ul>
    <!-- /TABS -->

    <div class="tabs-content" data-tabs-content="tabs" style="border: none;">

      <div class="tabs-panel is-active" id="healthpanel1">
        <div class="healthpanel">

          <!-- QUESTION -->
          <div class="question">

            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 1">
                <p class="health-type">{{ health.type }}</p>
                <p class="health-question-index">Frage {{ index }}</p>
                <p class="health-question-text">{{ health.question }}</p>
              </template>
            </template><!-- /v-for -->

            <!-- <p>Frage 1</p>
            <p>Hast du Gelenk-, Muskel-, Sehnen- oder Bänder-Beschwerden?</p> -->

          </div>

          <!-- LIST -->
          <template v-for="(health, index) in healthData" :key="health.id">
            <template v-if="index == 1">

              <!-- LEGEND -->
              <div class="grid-x legend align-middle">
                <div class="shrink cell">
                  <p class="hide">Legende</p>
                </div>
                <div class="auto cell">
                  <p>&nbsp;</p>
                </div>
                <div class="shrink cell radio-cell legend-one">
                  <p class="ck-overflow-ellipsis small">keine</p>
                </div>
                <div class="shrink cell radio-cell legend-two">
                  <p class="ck-overflow-ellipsis small">leicht</p>
                </div>
                <div class="shrink cell radio-cell legend-three">
                  <p class="ck-overflow-ellipsis small">mittel</p>
                </div>
                <div class="shrink cell radio-cell legend-four">
                  <p class="ck-overflow-ellipsis small">stark</p>
                </div>
              </div>
              <!-- /LEGEND -->

              <ul class="menu vertical" v-for="(answer, index) in health.answer" :key="answer.id">
                <li class="grid-x align-middle answer-text" :class="{ hide: !answer.text }">

                  <div class="shrink cell">
                    <div class="icon">
                      <img v-if="answer.img" :src="answer.img" :alt="answer.key" />
                    </div>
                  </div>
                  <div class="auto cell">
                    <p>{{ answer.text }}</p>
                    <p class="hide"><small>{{ answer.checked }}</small></p>
                  </div>
                  <div class="shrink cell radio-cell">

                    <div class="radio-container">
                      <div class="radio-item">
                        <input type="radio" :name="'tab1answer'+(index+1)" value="0" :id="'tab1answer'+(index+1)+'_0'" :checked="answer.checked.value === 0">
                        <label :for="'tab1answer'+(index+1)+'_0'"></label>
                        <span class="checkmark"></span>
                      </div>
                    </div>

                  </div>
                  <div class="shrink cell radio-cell">

                    <div class="radio-container">
                      <div class="radio-item">
                        <input type="radio" :name="'tab1answer'+(index+1)" value="1" :id="'tab1answer'+(index+1)+'_1'" :checked="answer.checked.value === 1">
                        <label :for="'tab1answer'+(index+1)+'_1'"></label>
                        <span class="checkmark"></span>
                      </div>
                    </div>
                    
                  </div>
                  <div class="shrink cell radio-cell">
                    <div class="radio-container">
                      <div class="radio-item">
                        <input type="radio" :name="'tab1answer'+(index+1)" value="2" :id="'tab1answer'+(index+1)+'_2'" :checked="answer.checked.value === 2">
                        <label :for="'tab1answer'+(index+1)+'_2'"></label>
                        <span class="checkmark"></span>
                      </div>
                    </div>
                    
                  </div>
                  <div class="shrink cell radio-cell">
                    <div class="radio-container">
                      <div class="radio-item">
                        <input type="radio" :name="'tab1answer'+(index+1)" value="3" :id="'tab1answer'+(index+1)+'_3'" :checked="answer.checked.value === 3">
                        <label :for="'tab1answer'+(index+1)+'_3'"></label>
                        <span class="checkmark"></span>
                      </div>
                    </div>
                    
                  </div>
                </li>

              </ul>

            </template>
          </template><!-- /v-for -->
          <!-- /LIST -->

          <!-- REMARK -->
          <div class="dx-field-item-content dx-field-item-content-location-bottom">
            <div class="dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-filled dx-widget dx-validator dx-visibility-change-handler dx-state-focused">
              <div class="dx-texteditor-container">
                <div class="dx-texteditor-input-container">
                  <template v-for="(health, index) in healthData" :key="health.id">
                  <template v-if="index == 1">
                  <input name="tab1remark" :value="health.remark" autocomplete="off" placeholder="Bemerkung" id="tab1remark" class="dx-texteditor-input" type="text" spellcheck="false" tabindex="0" role="textbox" aria-required="true">
                  </template>
                  </template><!-- /v-for -->
                </div>
              </div>
            </div>
          </div>
          <!-- /REMARK -->
          
          <!-- BUTTONAREA -->
          <div class="button-area grid-x">
            <div class="shrink cell">
              <!-- <button class="cx-button" @click="tabGoto(1)">vorherige Frage</button> -->
            </div>
            <div class="auto cell">
              &nbsp;
            </div>
            <div class="shrink cell">
              <button class="cx-button" @click="tabGoto(2)">zu Frage 2<i class="hide fa-light fa-forward-step"></i></button>
            </div>
          </div>
          <!-- /BUTTONAREA -->

        </div>
        <!-- healthpanel -->

      </div>
      <!-- tabs-panel -->

      
      <div class="tabs-panel" id="healthpanel2">
        <div class="healthpanel">

          <!-- QUESTION -->
          <div class="question">

            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 2">
                <p class="health-type">{{ health.type }}</p>
                <p class="health-question-index">Frage {{ index }}</p>
                <p class="health-question-text">{{ health.question }}</p>
              </template>
            </template><!-- /v-for -->

          </div>
      
          <!-- LIST -->
          <template v-for="(health, index) in healthData" :key="health.id">
            <template v-if="index == 2">

              <!-- LEGEND -->
              <div class="grid-x legend align-middle">
                <div class="shrink cell">
                  <p class="hide">Legende</p>
                </div>
                <div class="auto cell">
                  <p>&nbsp;</p>
                </div>
                <div class="shrink cell radio-cell legend-one">
                  <p class="ck-overflow-ellipsis small">keine</p>
                </div>
                <div class="shrink cell radio-cell legend-two">
                  <p class="ck-overflow-ellipsis small">leicht</p>
                </div>
                <div class="shrink cell radio-cell legend-three">
                  <p class="ck-overflow-ellipsis small">mittel</p>
                </div>
                <div class="shrink cell radio-cell legend-four">
                  <p class="ck-overflow-ellipsis small">stark</p>
                </div>
              </div>
              <!-- /LEGEND -->

              <ul class="menu vertical" v-for="(answer, index) in health.answer" :key="answer.id">
                <li class="grid-x align-middle answer-text" :class="{ hide: !answer.text }">

                  <div class="shrink cell">
                    <div class="icon">
                      <img v-if="answer.img" :src="answer.img" :alt="answer.key" />
                    </div>
                  </div>
                  <div class="auto cell">
                    <p>{{ answer.text }}</p>
                    <p class="hide"><small>{{ answer.checked }}</small></p>
                  </div>
                  <div class="shrink cell radio-cell">

                    <div class="radio-container">
                      <div class="radio-item">
                        <input type="radio" :name="'tab2answer'+(index+1)" value="0" :id="'tab2answer'+(index+1)+'_0'" :checked="answer.checked.value === 0">
                        <label :for="'tab2answer'+(index+1)+'_0'"></label>
                        <span class="checkmark"></span>
                      </div>
                    </div>

                  </div>
                  <div class="shrink cell radio-cell">

                    <div class="radio-container">
                      <div class="radio-item">
                        <input type="radio" :name="'tab2answer'+(index+1)" value="1" :id="'tab2answer'+(index+1)+'_1'" :checked="answer.checked.value === 1">
                        <label :for="'tab2answer'+(index+1)+'_1'"></label>
                        <span class="checkmark"></span>
                      </div>
                    </div>
                    
                  </div>
                  <div class="shrink cell radio-cell">
                    <div class="radio-container">
                      <div class="radio-item">
                        <input type="radio" :name="'tab2answer'+(index+1)" value="2" :id="'tab2answer'+(index+1)+'_2'" :checked="answer.checked.value === 2">
                        <label :for="'tab2answer'+(index+1)+'_2'"></label>
                        <span class="checkmark"></span>
                      </div>
                    </div>
                    
                  </div>
                  <div class="shrink cell radio-cell">
                    <div class="radio-container">
                      <div class="radio-item">
                        <input type="radio" :name="'tab2answer'+(index+1)" value="3" :id="'tab2answer'+(index+1)+'_3'" :checked="answer.checked.value === 3">
                        <label :for="'tab2answer'+(index+1)+'_3'"></label>
                        <span class="checkmark"></span>
                      </div>
                    </div>
                    
                  </div>
                </li>

              </ul>

            </template>
          </template><!-- /v-for -->
          <!-- /LIST -->

          <!-- REMARK -->
          <div class="dx-field-item-content dx-field-item-content-location-bottom">
            <div class="dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-filled dx-widget dx-validator dx-visibility-change-handler dx-state-focused">
              <div class="dx-texteditor-container">
                <div class="dx-texteditor-input-container">
                  <template v-for="(health, index) in healthData" :key="health.id">
                  <template v-if="index == 2">
                  <input name="tab2remark" :value="health.remark" autocomplete="off" placeholder="Bemerkung" id="tab2remark" class="dx-texteditor-input" type="text" spellcheck="false" tabindex="0" role="textbox" aria-required="true">
                  </template>
                  </template><!-- /v-for -->
                </div>
              </div>
            </div>
          </div>
          <!-- /REMARK -->

          <!-- BUTTONAREA -->
          <div class="button-area grid-x">
            <div class="shrink cell">
              <!-- <button class="-hide cx-button" @click="tabGoto(1)"><i class="hide fa-light fa-backward-step"></i> Frage 1</button> -->
            </div>
            <div class="auto cell">
              &nbsp;
            </div>
            <div class="shrink cell">
              <button class="cx-button" @click="tabGoto(3)">zu Frage 3<i class="hide fa-light fa-forward-step"></i></button>
            </div>
          </div>
          <!-- /BUTTONAREA -->

        </div>
        <!-- healthpanel -->

      </div>
      <!-- tabs-panel -->


      <div class="tabs-panel" id="healthpanel3">
        <div class="healthpanel">

          <!-- QUESTION -->
          <div class="question">

            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 3">
                <p class="health-type">{{ health.type }}</p>
                <p class="health-question-index">Frage {{ index }}</p>
                <p class="health-question-text">{{ health.question }}</p>
              </template>
            </template><!-- /v-for -->
            
            <!-- <p>Frage 3</p>
            <p>Hast du Stoffwechselprobleme oder Allergien?</p> -->

          </div>

          <!-- LIST -->
          <template v-for="(health, index) in healthData" :key="health.id">
            <template v-if="index == 3">

              <!-- LEGEND -->
              <div class="grid-x legend align-middle">
                <div class="shrink cell">
                  <p class="hide">Legende</p>
                </div>
                <div class="auto cell">
                  <p>&nbsp;</p>
                </div>
                <div class="shrink cell radio-cell legend-one">
                  <p class="ck-overflow-ellipsis small">keine</p>
                </div>
                <div class="shrink cell radio-cell legend-two">
                  <p class="ck-overflow-ellipsis small">leicht</p>
                </div>
                <div class="shrink cell radio-cell legend-three">
                  <p class="ck-overflow-ellipsis small">mittel</p>
                </div>
                <div class="shrink cell radio-cell legend-four">
                  <p class="ck-overflow-ellipsis small">stark</p>
                </div>
              </div>
              <!-- /LEGEND -->

              <ul class="menu vertical" v-for="(answer, index) in health.answer" :key="answer.id">
                <li class="grid-x align-middle answer-text" :class="{ hide: !answer.text }">

                  <div class="shrink cell">
                    <div class="icon">
                      <img v-if="answer.img" :src="answer.img" :alt="answer.key" />
                    </div>
                  </div>
                  <div class="auto cell">
                    <p>{{ answer.text }}</p>
                    <p class="hide"><small>{{ answer.checked }}</small></p>
                  </div>
                  <div class="shrink cell radio-cell">

                    <div class="radio-container">
                      <div class="radio-item">
                        <input type="radio" :name="'tab3answer'+(index+1)" value="0" :id="'tab3answer'+(index+1)+'_0'" :checked="answer.checked.value === 0">
                        <label :for="'tab3answer'+(index+1)+'_0'"></label>
                        <span class="checkmark"></span>
                      </div>
                    </div>

                  </div>
                  <div class="shrink cell radio-cell">

                    <div class="radio-container">
                      <div class="radio-item">
                        <input type="radio" :name="'tab3answer'+(index+1)" value="1" :id="'tab3answer'+(index+1)+'_1'" :checked="answer.checked.value === 1">
                        <label :for="'tab3answer'+(index+1)+'_1'"></label>
                        <span class="checkmark"></span>
                      </div>
                    </div>
                    
                  </div>
                  <div class="shrink cell radio-cell">
                    <div class="radio-container">
                      <div class="radio-item">
                        <input type="radio" :name="'tab3answer'+(index+1)" value="2" :id="'tab3answer'+(index+1)+'_2'" :checked="answer.checked.value === 2">
                        <label :for="'tab3answer'+(index+1)+'_2'"></label>
                        <span class="checkmark"></span>
                      </div>
                    </div>
                    
                  </div>
                  <div class="shrink cell radio-cell">
                    <div class="radio-container">
                      <div class="radio-item">
                        <input type="radio" :name="'tab3answer'+(index+1)" value="3" :id="'tab3answer'+(index+1)+'_3'" :checked="answer.checked.value === 3">
                        <label :for="'tab3answer'+(index+1)+'_3'"></label>
                        <span class="checkmark"></span>
                      </div>
                    </div>
                    
                  </div>
                </li>

              </ul>

            </template>
          </template><!-- /v-for -->
          <!-- /LIST -->

          <!-- REMARK -->
          <div class="dx-field-item-content dx-field-item-content-location-bottom">
            <div class="dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-filled dx-widget dx-validator dx-visibility-change-handler dx-state-focused">
              <div class="dx-texteditor-container">
                <div class="dx-texteditor-input-container">
                  <template v-for="(health, index) in healthData" :key="health.id">
                  <template v-if="index == 3">
                  <input name="tab3remark" :value="health.remark" autocomplete="off" placeholder="Bemerkung" id="tab3remark" class="dx-texteditor-input" type="text" spellcheck="false" tabindex="0" role="textbox" aria-required="true">
                  </template>
                  </template><!-- /v-for -->
                </div>
              </div>
            </div>
          </div>
          <!-- /REMARK -->

          <!-- BUTTONAREA -->
          <div class="button-area grid-x">
            <div class="shrink cell">
              <!-- <button class="-hide cx-button" @click="tabGoto(2)"><i class="hide fa-light fa-backward-step"></i> Frage 2</button> -->
            </div>
            <div class="auto cell">
              &nbsp;
            </div>
            <div class="shrink cell">
              <button class="cx-button" @click="tabGoto(4)">zu Frage 4<i class="hide fa-light fa-forward-step"></i></button>
            </div>
          </div>
          <!-- /BUTTONAREA -->

        </div>
        <!-- healthpanel -->

      </div>
      <!-- tabs-panel -->

      <div class="tabs-panel" id="healthpanel4">
        <div class="healthpanel">

          <!-- QUESTION -->
          <div class="question">

            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 4">
                <p class="health-type">{{ health.type }}</p>
                <p class="health-question-index">Frage {{ index }}</p>
                <p class="health-question-text">{{ health.question }}</p>
              </template>
            </template><!-- /v-for -->
            
            <!-- <p>Frage 4</p>
            <p>Was ist dein wichtigstes persönliches Trainingsziel?</p> -->

          </div>

          <!-- LEGEND -->
          <div class="grid-x legend align-middle">
            <div class="shrink cell">
            </div>
            <div class="auto cell">
            </div>
          </div>
          <!-- /LEGEND -->

          <!-- LIST -->
          <template v-for="(health, index) in healthData" :key="health.id">
            <template v-if="index == 4">
              <template v-for="(answer, index) in health.answer" :key="answer.id">

                <div class="answer-text grid-x align-middle" :class="{ hide: !answer.text }">
                  <div class="shrink cell">
                    <div class="icon-shim">
                    </div>
                  </div>
                  <div class="auto cell">
                    <p class="hide"><small>{{ answer.checked }}</small></p>

                    <div class="radio-container">
                      <div class="radio-item">
                        <input type="radio" name="tab4answer1" :value="index" :id="'tab4answer1_'+index" :checked="answer.checked.value === index">
                        <label class="label" :for="'tab4answer1_'+index">&nbsp;{{ answer.text }}</label>
                        <span class="checkmark"></span>
                      </div>
                    </div>
                  </div>
                  <div class="shrink cell">
                    &nbsp;
                  </div>
                </div>
              </template>
            </template>
          </template>
          <!-- /LIST -->

          <!-- REMARK -->
          <div class="dx-field-item-content dx-field-item-content-location-bottom">
            <div class="dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-filled dx-widget dx-validator dx-visibility-change-handler dx-state-focused">
              <div class="dx-texteditor-container">
                <div class="dx-texteditor-input-container">
                  <template v-for="(health, index) in healthData" :key="health.id">
                  <template v-if="index == 4">
                  <input name="tab4remark" :value="health.remark" autocomplete="off" placeholder="Bemerkung" id="tab4remark" class="dx-texteditor-input" type="text" spellcheck="false" tabindex="0" role="textbox" aria-required="true">
                  </template>
                  </template><!-- /v-for -->
                </div>
              </div>
            </div>
          </div>
          <!-- /REMARK -->

          <!-- BUTTONAREA -->
          <div class="button-area grid-x">
            <div class="shrink cell">
              <!-- <button class="-hide cx-button" @click="tabGoto(3)"><i class="hide fa-light fa-backward-step"></i> Frage 3</button> -->
            </div>
            <div class="auto cell">
              &nbsp;
            </div>
            <div class="shrink cell">
              <button class="cx-button" @click="tabGoto(5)">zu Frage 5<i class="hide fa-light fa-forward-step"></i></button>
            </div>
          </div>
          <!-- /BUTTONAREA -->

        </div>
        <!-- healthpanel -->

      </div>
      <!-- tabs-panel -->

      <div class="tabs-panel" id="healthpanel5">
        <div class="healthpanel">

          <!-- QUESTION -->
          <div class="question">

            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 5">
                <p class="health-type">{{ health.type }}</p>
                <p class="health-question-index">Frage {{ index }}</p>
                <p class="health-question-text">{{ health.question }}</p>
              </template>
            </template><!-- /v-for -->
            
            <!-- <p>Frage 5</p>
            <p>Wie wichtig ist es dir dein Trainingsziel zu erreichen?</p> -->

          </div>

          <!-- LEGEND -->
          <div class="grid-x legend align-middle">
            <div class="shrink cell">
            </div>
            <div class="auto cell">
            </div>
          </div>
          <!-- /LEGEND -->

          <!-- LIST -->
          <template v-for="(health, index) in healthData" :key="health.id">
            <template v-if="index == 5">
              <template v-for="(answer, index) in health.answer" :key="answer.id">
                <div class="answer-text grid-x align-middle" :class="{ hide: !answer.text }">

                  <div class="shrink cell">
                    <div class="icon-shim">
                    </div>
                  </div>
                  <div class="auto cell">
                    <p class="hide"><small>{{ answer.checked }}</small></p>

                    <div class="radio-container">
                      <div class="radio-item">
                        <input type="radio" name="tab5answer1" :value="index" :id="'tab5answer1_'+index" :checked="answer.checked.value === index">
                        <label class="label" :for="'tab5answer1_'+index">&nbsp;{{ answer.text }}</label>
                        <span class="checkmark"></span>
                      </div>
                    </div>
                  </div>
                  <div class="shrink cell">
                    &nbsp;
                  </div>
                </div>
              </template>
            </template>
          </template>
          <!-- /LIST -->

          <!-- REMARK -->
          <div class="dx-field-item-content dx-field-item-content-location-bottom">
            <div class="dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-filled dx-widget dx-validator dx-visibility-change-handler dx-state-focused">
              <div class="dx-texteditor-container">
                <div class="dx-texteditor-input-container">
                  <template v-for="(health, index) in healthData" :key="health.id">
                  <template v-if="index == 5">
                  <input name="tab5remark" :value="health.remark" autocomplete="off" placeholder="Bemerkung" id="tab5remark" class="dx-texteditor-input" type="text" spellcheck="false" tabindex="0" role="textbox" aria-required="true">
                  </template>
                  </template><!-- /v-for -->
                </div>
              </div>
            </div>
          </div>
          <!-- /REMARK -->
          
          <!-- BUTTONAREA -->
          <div class="button-area grid-x">
            <div class="shrink cell">
              <!-- <button class="-hide cx-button" @click="tabGoto(4)"><i class="hide fa-light fa-backward-step"></i> Frage 4</button> -->
            </div>
            <div class="auto cell">
              &nbsp;
            </div>
            <div class="shrink cell">
              <button class="cx-button" @click="tabGoto(6)">zu Frage 6<i class="hide fa-light fa-forward-step"></i></button>
            </div>
          </div>
          <!-- /BUTTONAREA -->
          
        </div>
        <!-- healthpanel -->

      </div>
      <!-- tabs-panel -->


      <div class="tabs-panel" id="healthpanel6">
        <div class="healthpanel">

          <!-- QUESTION -->
          <div class="question">

            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 6">
                <p class="health-type">{{ health.type }}</p>
                <p class="health-question-index">Frage {{ index }}</p>
                <p class="health-question-text">{{ health.question }}</p>
              </template>
            </template><!-- /v-for -->

            <!-- <p>Frage 6</p>
            <p>Wie regelmässig möchtest du trainieren?</p> -->

          </div>

          <!-- LEGEND -->
          <div class="grid-x legend align-middle">
            <div class="shrink cell">
            </div>
            <div class="auto cell">
            </div>
          </div>
          <!-- /LEGEND -->

          <!-- LIST -->
          <template v-for="(health, index) in healthData" :key="health.id">
            <template v-if="index == 6">
              <template v-for="(answer, index) in health.answer" :key="answer.id">
                <div class="answer-text grid-x align-middle" :class="{ hide: !answer.text }">
                  <div class="shrink cell">
                    <div class="icon-shim">
                    </div>
                  </div>
                  <div class="auto cell">
                    <p class="hide"><small>{{ answer.checked }}</small></p>

                    <div class="radio-container">
                      <div class="radio-item">
                        <input type="radio" name="tab6answer1" :value="index" :id="'tab6answer1_'+index" :checked="answer.checked.value === index">
                        <label class="label" :for="'tab6answer1_'+index">&nbsp;{{ answer.text }}</label>
                        <span class="checkmark"></span>
                      </div>
                    </div>
                  </div>
                  <div class="shrink cell">
                    &nbsp;
                  </div>
                </div>
              </template>
            </template>
          </template>
          <!-- /LIST -->

          <!-- REMARK -->
          <div class="dx-field-item-content dx-field-item-content-location-bottom">
            <div class="dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-filled dx-widget dx-validator dx-visibility-change-handler dx-state-focused">
              <div class="dx-texteditor-container">
                <div class="dx-texteditor-input-container">
                  <template v-for="(health, index) in healthData" :key="health.id">
                  <template v-if="index == 6">
                  <input name="tab6remark" :value="health.remark" autocomplete="off" placeholder="Bemerkung" id="tab6remark" class="dx-texteditor-input" type="text" spellcheck="false" tabindex="0" role="textbox" aria-required="true">
                  </template>
                  </template><!-- /v-for -->
                </div>
              </div>
            </div>
          </div>
          <!-- /REMARK -->

          <!-- BUTTONAREA -->
          <div class="button-area grid-x">
            <div class="shrink cell">
              <!-- <button class="-hide cx-button" @click="tabGoto(5)"><i class="hide fa-light fa-backward-step"></i> Frage 5</button> -->
            </div>
            <div class="auto cell">
              &nbsp;
            </div>
            <div class="shrink cell">
              <button class="cx-button" @click="tabGoto(7)">zu Frage 7<i class="hide fa-light fa-forward-step"></i></button>
            </div>
          </div>
          <!-- /BUTTONAREA -->

        </div>
        <!-- healthpanel -->

      </div>
      <!-- tabs-panel -->


      <div class="tabs-panel" id="healthpanel7">
        <div class="healthpanel">

          <!-- QUESTION -->
          <div class="question">

            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 7">
                <p class="health-type">{{ health.type }}</p>
                <p class="health-question-index">Frage {{ index }}</p>
                <p class="health-question-text">{{ health.question }}</p>
              </template>
            </template><!-- /v-for -->
            
            <!-- <p>Frage 7</p>
            <p>Wann warst du das letzte mal sportlich aktiv?</p> -->

          </div>

          <!-- LEGEND -->
          <div class="grid-x legend align-middle">
            <div class="shrink cell">
            </div>
            <div class="auto cell">
            </div>
          </div>
          <!-- /LEGEND -->

          <!-- LIST -->
          <template v-for="(health, index) in healthData" :key="health.id">
            <template v-if="index == 7">
              <template v-for="(answer, index) in health.answer" :key="answer.id">
                <div class="answer-text grid-x align-middle" :class="{ hide: !answer.text }">
                  <div class="shrink cell">
                    <div class="icon-shim">
                    </div>
                  </div>
                  <div class="auto cell">
                    <p class="hide"><small>{{ answer.checked }}</small></p>

                    <div class="radio-container">
                      <div class="radio-item">
                        <input type="radio" name="tab7answer1" :value="index" :id="'tab7answer1_'+index" :checked="answer.checked.value === index">
                        <label class="label" :for="'tab7answer1_'+index">&nbsp;{{ answer.text }}</label>
                        <span class="checkmark"></span>
                      </div>
                    </div>
                  </div>
                  <div class="shrink cell">
                    &nbsp;
                  </div>
                </div>
              </template>
            </template>
          </template>
          <!-- /LIST -->

          <!-- REMARK -->
          <div class="dx-field-item-content dx-field-item-content-location-bottom">
            <div class="dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-filled dx-widget dx-validator dx-visibility-change-handler dx-state-focused">
              <div class="dx-texteditor-container">
                <div class="dx-texteditor-input-container">
                  <template v-for="(health, index) in healthData" :key="health.id">
                  <template v-if="index == 7">
                  <input name="tab7remark" :value="health.remark" autocomplete="off" placeholder="Bemerkung" id="tab7remark" class="dx-texteditor-input" type="text" spellcheck="false" tabindex="0" role="textbox" aria-required="true">
                  </template>
                  </template><!-- /v-for -->
                </div>
              </div>
            </div>
          </div>
          <!-- /REMARK -->

          <!-- BUTTONAREA -->
          <div class="button-area grid-x">
            <div class="shrink cell">
              <!-- <button class="-hide cx-button" @click="tabGoto(6)"><i class="hide fa-light fa-backward-step"></i> Frage 6</button> -->
            </div>
            <div class="auto cell">
              &nbsp;
            </div>
            <div class="shrink cell">
              <button class="cx-button" @click="tabGoto(8)">zu Frage 8<i class="hide fa-light fa-forward-step"></i></button>
            </div>
          </div>
          <!-- /BUTTONAREA -->
          
        </div>
        <!-- healthpanel -->

      </div>
      <!-- tabs-panel -->

      <div class="tabs-panel" id="healthpanel8">
        <div class="healthpanel">

          <!-- QUESTION -->
          <div class="question">

            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 8">
                <p class="health-type">{{ health.type }}</p>
                <p class="health-question-index">Frage {{ index }}</p>
                <p class="health-question-text">{{ health.question }}</p>
              </template>
            </template><!-- /v-for -->
            
            <!-- <p>Frage 8</p>
            <p>Wie schätzt du dein persönliches Level ein?</p> -->

          </div>

          <!-- LEGEND -->
          <div class="grid-x legend align-middle">
            <div class="shrink cell">
            </div>
            <div class="auto cell">
            </div>
          </div>
          <!-- /LEGEND -->
          
          <!-- LIST -->
          <template v-for="(health, index) in healthData" :key="health.id">
            <template v-if="index == 8">
              <template v-for="(answer, index) in health.answer" :key="answer.id">
                <div class="answer-text grid-x align-middle" :class="{ hide: !answer.text }">
                  <div class="shrink cell">
                    <div class="icon-shim">
                    </div>
                  </div>
                  <div class="auto cell">
                    <p class="hide"><small>{{ answer.checked }}</small></p>
                    <div class="radio-container">
                      <div class="radio-item">
                        <input type="radio" name="tab8answer1" :value="index" :id="'tab8answer1_'+index" :checked="answer.checked.value === index">
                        <label class="label" :for="'tab8answer1_'+index">&nbsp;{{ answer.text }}</label>
                        <span class="checkmark"></span>
                      </div>
                    </div>
                  </div>
                  <div class="shrink cell">
                    &nbsp;
                  </div>
                </div>
              </template>
            </template>
          </template>
          <!-- /LIST -->

          <!-- REMARK -->
          <div class="dx-field-item-content dx-field-item-content-location-bottom">
            <div class="dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-filled dx-widget dx-validator dx-visibility-change-handler dx-state-focused">
              <div class="dx-texteditor-container">
                <div class="dx-texteditor-input-container">
                  <template v-for="(health, index) in healthData" :key="health.id">
                  <template v-if="index == 8">
                  <input name="tab8remark" :value="health.remark" autocomplete="off" placeholder="Bemerkung" id="tab8remark" class="dx-texteditor-input" type="text" spellcheck="false" tabindex="0" role="textbox" aria-required="true">
                  </template>
                  </template><!-- /v-for -->
                </div>
              </div>
            </div>
          </div>
          <!-- /REMARK -->
           
          <!-- BUTTONAREA -->
          <div class="button-area grid-x">
            <div class="shrink cell">
              <!-- <button class="-hide cx-button" @click="tabGoto(7)"><i class="hide fa-light fa-backward-step"></i> Frage 7</button> -->
            </div>
            <div class="auto cell">
              &nbsp;
            </div>
            <div class="shrink cell">
              <button class="cx-button" @click="tabGoto(9)">zu Frage 9<i class="hide fa-light fa-forward-step"></i></button>
            </div>
          </div>
          <!-- /BUTTONAREA -->
          
          
        </div>
        <!-- healthpanel -->

      </div>
      <!-- tabs-panel -->

      <div class="tabs-panel" id="healthpanel9">
        <div class="healthpanel">

          <!-- QUESTION -->
          <div class="question">

            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 9">
                <p class="health-type">{{ health.type }}</p>
                <p class="health-question-index">Frage {{ index }}</p>
                <p class="health-question-text">{{ health.question }}</p>
              </template>
            </template><!-- /v-for -->
            
            <!-- <p>Frage 9</p>
            <p>Wie bist du auf uns aufmerksam geworden?</p> -->

          </div>

          <!-- LEGEND -->
          <div class="grid-x legend align-middle">
            <div class="shrink cell">
            </div>
            <div class="auto cell">
            </div>
          </div>
          <!-- /LEGEND -->

          <!-- LIST -->
          <template v-for="(health, index) in healthData" :key="health.id">
            <template v-if="index == 9">
              <template v-for="(answer, index) in health.answer" :key="answer.id">
                <div class="answer-text grid-x align-middle" :class="{ hide: !answer.text }">
                  <div class="shrink cell">
                    <div class="icon-shim">
                    </div>
                  </div>
                  <div class="auto cell">
                    <p class="hide"><small>{{ answer.checked }}</small></p>
                    <div class="radio-container">
                      <div class="radio-item">
                        <input type="radio" name="tab9answer1" :value="index" :id="'tab9answer1_'+index" :checked="answer.checked.value === index">
                        <label class="label" :for="'tab9answer1_'+index">&nbsp;{{ answer.text }}</label>
                        <span class="checkmark"></span>
                      </div>
                    </div>
                  </div>
                  <div class="shrink cell">
                    &nbsp;
                  </div>
                </div>
              </template>
            </template>
          </template>
          <!-- /LIST -->

          <!-- REMARK -->
          <div class="dx-field-item-content dx-field-item-content-location-bottom">
            <div class="dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-filled dx-widget dx-validator dx-visibility-change-handler dx-state-focused">
              <div class="dx-texteditor-container">
                <div class="dx-texteditor-input-container">
                  <template v-for="(health, index) in healthData" :key="health.id">
                  <template v-if="index == 9">
                  <input name="tab9remark" :value="health.remark" autocomplete="off" placeholder="Bemerkung" id="tab9remark" class="dx-texteditor-input" type="text" spellcheck="false" tabindex="0" role="textbox" aria-required="true">
                  </template>
                  </template><!-- /v-for -->
                </div>
              </div>
            </div>
          </div>
          <!-- /REMARK -->
           
          <!-- BUTTONAREA -->
          <div class="button-area grid-x">
            <div class="shrink cell">
              <button class="-hide cx-button" @click="tabGoto(1)"><i class="hide fa-light fa-backward-step"></i> zurück</button>
            </div>
            <div class="auto cell">
              &nbsp;
            </div>
            <div class="shrink cell">
              <button class="cx-button" @click="save()">sichern <i class="hide fa-light fa-floppy-disk"></i></button>
            </div>
          </div>
          <!-- /BUTTONAREA -->
          
        </div>
        <!-- healthpanel -->

      </div>
      <!-- tabs-panel -->

    </div><!-- tabs-content -->

  </div>
</template>

<script>

import notify from 'devextreme/ui/notify';

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

import $ from 'jquery';
import Foundation from 'foundation-sites';

let customerid;
let healthData;

export default {
  name: 'health-detail',

  props: {
    templateData: Object,
  },

  mounted() {
    this.tabs = new Foundation.Tabs($('#tabs'), {
      // These options can be declarative using the data attributes
      matchHeight: false
    });

    auth.getUser().then((e) => {
      if(e.data){
        // console.log(e.data);
        // console.log(e.data.id);
        // console.log(e.data.Language);
        this.customerid = e.data.id;

        //DATA
        setTimeout(() => {
          fetch(apihost+'/de/vue/health/gethealth/'+e.data.id+'/view')
          .then(response => response.text())
          .then(result => {
          const data = JSON.parse(result);
          //console.log(data);
          this.healthData = data;
          })
          .catch(() => { throw 'Network error' });
        
        }, 0);

      } // e.data
      
    }); //auth

  },

  data() {
    return {
      apihost,
      customerid,
      healthData,
    };
  },

  methods: {
    tabGoto(tab) {
      //console.log(tab);
      const thisbutton = document.getElementById("healthlink"+tab);
      //console.log(thisbutton);
      thisbutton.click();
    },

    save() {
      //console.log('save');
      //console.log(this.customerid);

      //REMARK
      const tab1remark = document.getElementById("tab1remark");
      const tab2remark = document.getElementById("tab2remark");
      const tab3remark = document.getElementById("tab3remark");
      const tab4remark = document.getElementById("tab4remark");
      const tab5remark = document.getElementById("tab5remark");
      const tab6remark = document.getElementById("tab6remark");
      const tab7remark = document.getElementById("tab7remark");
      const tab8remark = document.getElementById("tab8remark");
      const tab9remark = document.getElementById("tab9remark");

      //TAB1

      const tab1answer1_0 = document.getElementById("tab1answer1_0");
      const tab1answer1_1 = document.getElementById("tab1answer1_1");
      const tab1answer1_2 = document.getElementById("tab1answer1_2");
      const tab1answer1_3 = document.getElementById("tab1answer1_3");

      const tab1answer2_0 = document.getElementById("tab1answer2_0");
      const tab1answer2_1 = document.getElementById("tab1answer2_1");
      const tab1answer2_2 = document.getElementById("tab1answer2_2");
      const tab1answer2_3 = document.getElementById("tab1answer2_3");

      const tab1answer3_0 = document.getElementById("tab1answer3_0");
      const tab1answer3_1 = document.getElementById("tab1answer3_1");
      const tab1answer3_2 = document.getElementById("tab1answer3_2");
      const tab1answer3_3 = document.getElementById("tab1answer3_3");

      const tab1answer4_0 = document.getElementById("tab1answer4_0");
      const tab1answer4_1 = document.getElementById("tab1answer4_1");
      const tab1answer4_2 = document.getElementById("tab1answer4_2");
      const tab1answer4_3 = document.getElementById("tab1answer4_3");

      const tab1answer5_0 = document.getElementById("tab1answer5_0");
      const tab1answer5_1 = document.getElementById("tab1answer5_1");
      const tab1answer5_2 = document.getElementById("tab1answer5_2");
      const tab1answer5_3 = document.getElementById("tab1answer5_3");

      const tab1answer6_0 = document.getElementById("tab1answer6_0");
      const tab1answer6_1 = document.getElementById("tab1answer6_1");
      const tab1answer6_2 = document.getElementById("tab1answer6_2");
      const tab1answer6_3 = document.getElementById("tab1answer6_3");

      const tab1answer7_0 = document.getElementById("tab1answer7_0");
      const tab1answer7_1 = document.getElementById("tab1answer7_1");
      const tab1answer7_2 = document.getElementById("tab1answer7_2");
      const tab1answer7_3 = document.getElementById("tab1answer7_3");

      const tab1answer8_0 = document.getElementById("tab1answer8_0");
      const tab1answer8_1 = document.getElementById("tab1answer8_1");
      const tab1answer8_2 = document.getElementById("tab1answer8_2");
      const tab1answer8_3 = document.getElementById("tab1answer8_3");

      const tab1answer9_0 = document.getElementById("tab1answer9_0");
      const tab1answer9_1 = document.getElementById("tab1answer9_1");
      const tab1answer9_2 = document.getElementById("tab1answer9_2");
      const tab1answer9_3 = document.getElementById("tab1answer9_3");

      //TAB2

      const tab2answer1_0 = document.getElementById("tab2answer1_0");
      const tab2answer1_1 = document.getElementById("tab2answer1_1");
      const tab2answer1_2 = document.getElementById("tab2answer1_2");
      const tab2answer1_3 = document.getElementById("tab2answer1_3");

      const tab2answer2_0 = document.getElementById("tab2answer2_0");
      const tab2answer2_1 = document.getElementById("tab2answer2_1");
      const tab2answer2_2 = document.getElementById("tab2answer2_2");
      const tab2answer2_3 = document.getElementById("tab2answer2_3");

      const tab2answer3_0 = document.getElementById("tab2answer3_0");
      const tab2answer3_1 = document.getElementById("tab2answer3_1");
      const tab2answer3_2 = document.getElementById("tab2answer3_2");
      const tab2answer3_3 = document.getElementById("tab2answer3_3");

      const tab2answer4_0 = document.getElementById("tab2answer4_0");
      const tab2answer4_1 = document.getElementById("tab2answer4_1");
      const tab2answer4_2 = document.getElementById("tab2answer4_2");
      const tab2answer4_3 = document.getElementById("tab2answer4_3");

      const tab2answer5_0 = document.getElementById("tab2answer5_0");
      const tab2answer5_1 = document.getElementById("tab2answer5_1");
      const tab2answer5_2 = document.getElementById("tab2answer5_2");
      const tab2answer5_3 = document.getElementById("tab2answer5_3");

      const tab2answer6_0 = document.getElementById("tab2answer6_0");
      const tab2answer6_1 = document.getElementById("tab2answer6_1");
      const tab2answer6_2 = document.getElementById("tab2answer6_2");
      const tab2answer6_3 = document.getElementById("tab2answer6_3");

      const tab2answer7_0 = document.getElementById("tab2answer7_0");
      const tab2answer7_1 = document.getElementById("tab2answer7_1");
      const tab2answer7_2 = document.getElementById("tab2answer7_2");
      const tab2answer7_3 = document.getElementById("tab2answer7_3");

      const tab2answer8_0 = document.getElementById("tab2answer8_0");
      const tab2answer8_1 = document.getElementById("tab2answer8_1");
      const tab2answer8_2 = document.getElementById("tab2answer8_2");
      const tab2answer8_3 = document.getElementById("tab2answer8_3");

      const tab2answer9_0 = document.getElementById("tab2answer9_0");
      const tab2answer9_1 = document.getElementById("tab2answer9_1");
      const tab2answer9_2 = document.getElementById("tab2answer9_2");
      const tab2answer9_3 = document.getElementById("tab2answer9_3");

      //TAB3

      const tab3answer1_0 = document.getElementById("tab3answer1_0");
      const tab3answer1_1 = document.getElementById("tab3answer1_1");
      const tab3answer1_2 = document.getElementById("tab3answer1_2");
      const tab3answer1_3 = document.getElementById("tab3answer1_3");

      const tab3answer2_0 = document.getElementById("tab3answer2_0");
      const tab3answer2_1 = document.getElementById("tab3answer2_1");
      const tab3answer2_2 = document.getElementById("tab3answer2_2");
      const tab3answer2_3 = document.getElementById("tab3answer2_3");

      const tab3answer3_0 = document.getElementById("tab3answer3_0");
      const tab3answer3_1 = document.getElementById("tab3answer3_1");
      const tab3answer3_2 = document.getElementById("tab3answer3_2");
      const tab3answer3_3 = document.getElementById("tab3answer3_3");

      const tab3answer4_0 = document.getElementById("tab3answer4_0");
      const tab3answer4_1 = document.getElementById("tab3answer4_1");
      const tab3answer4_2 = document.getElementById("tab3answer4_2");
      const tab3answer4_3 = document.getElementById("tab3answer4_3");

      const tab3answer5_0 = document.getElementById("tab3answer5_0");
      const tab3answer5_1 = document.getElementById("tab3answer5_1");
      const tab3answer5_2 = document.getElementById("tab3answer5_2");
      const tab3answer5_3 = document.getElementById("tab3answer5_3");

      const tab3answer6_0 = document.getElementById("tab3answer6_0");
      const tab3answer6_1 = document.getElementById("tab3answer6_1");
      const tab3answer6_2 = document.getElementById("tab3answer6_2");
      const tab3answer6_3 = document.getElementById("tab3answer6_3");

      const tab3answer7_0 = document.getElementById("tab3answer7_0");
      const tab3answer7_1 = document.getElementById("tab3answer7_1");
      const tab3answer7_2 = document.getElementById("tab3answer7_2");
      const tab3answer7_3 = document.getElementById("tab3answer7_3");

      const tab3answer8_0 = document.getElementById("tab3answer8_0");
      const tab3answer8_1 = document.getElementById("tab3answer8_1");
      const tab3answer8_2 = document.getElementById("tab3answer8_2");
      const tab3answer8_3 = document.getElementById("tab3answer8_3");

      const tab3answer9_0 = document.getElementById("tab3answer9_0");
      const tab3answer9_1 = document.getElementById("tab3answer9_1");
      const tab3answer9_2 = document.getElementById("tab3answer9_2");
      const tab3answer9_3 = document.getElementById("tab3answer9_3");

      //TAB4
      const tab4answer1_0 = document.getElementById("tab4answer1_0");
      const tab4answer1_1 = document.getElementById("tab4answer1_1");
      const tab4answer1_2 = document.getElementById("tab4answer1_2");
      const tab4answer1_3 = document.getElementById("tab4answer1_3");

      //TAB5
      const tab5answer1_0 = document.getElementById("tab5answer1_0");
      const tab5answer1_1 = document.getElementById("tab5answer1_1");
      const tab5answer1_2 = document.getElementById("tab5answer1_2");
      const tab5answer1_3 = document.getElementById("tab5answer1_3");

      //TAB6
      const tab6answer1_0 = document.getElementById("tab6answer1_0");
      const tab6answer1_1 = document.getElementById("tab6answer1_1");
      const tab6answer1_2 = document.getElementById("tab6answer1_2");
      const tab6answer1_3 = document.getElementById("tab6answer1_3");

      //TAB7
      const tab7answer1_0 = document.getElementById("tab7answer1_0");
      const tab7answer1_1 = document.getElementById("tab7answer1_1");
      const tab7answer1_2 = document.getElementById("tab7answer1_2");
      const tab7answer1_3 = document.getElementById("tab7answer1_3");

      //TAB8
      const tab8answer1_0 = document.getElementById("tab8answer1_0");
      const tab8answer1_1 = document.getElementById("tab8answer1_1");
      const tab8answer1_2 = document.getElementById("tab8answer1_2");
      const tab8answer1_3 = document.getElementById("tab8answer1_3");

      //TAB9
      const tab9answer1_0 = document.getElementById("tab9answer1_0");
      const tab9answer1_1 = document.getElementById("tab9answer1_1");
      const tab9answer1_2 = document.getElementById("tab9answer1_2");
      const tab9answer1_3 = document.getElementById("tab9answer1_3");
      
      let objString = '?fake=fake';

      //TAB1

      //Antworten tab1 | frage 1
      objString = objString + '&tab1remark=' + tab1remark.value;
      objString = objString + '&tab1answer1_0=' + tab1answer1_0.checked;
      objString = objString + '&tab1answer1_1=' + tab1answer1_1.checked;
      objString = objString + '&tab1answer1_2=' + tab1answer1_2.checked;
      objString = objString + '&tab1answer1_3=' + tab1answer1_3.checked;

      //Antworten tab1 | frage 2
      objString = objString + '&tab2remark=' + tab2remark.value;
      objString = objString + '&tab1answer2_0=' + tab1answer2_0.checked;
      objString = objString + '&tab1answer2_1=' + tab1answer2_1.checked;
      objString = objString + '&tab1answer2_2=' + tab1answer2_2.checked;
      objString = objString + '&tab1answer2_3=' + tab1answer2_3.checked;

      //Antworten tab1 | frage 3
      objString = objString + '&tab3remark=' + tab3remark.value;
      objString = objString + '&tab1answer3_0=' + tab1answer3_0.checked;
      objString = objString + '&tab1answer3_1=' + tab1answer3_1.checked;
      objString = objString + '&tab1answer3_2=' + tab1answer3_2.checked;
      objString = objString + '&tab1answer3_3=' + tab1answer3_3.checked;

      //Antworten tab1 | frage 4
      objString = objString + '&tab4remark=' + tab4remark.value;
      objString = objString + '&tab1answer4_0=' + tab1answer4_0.checked;
      objString = objString + '&tab1answer4_1=' + tab1answer4_1.checked;
      objString = objString + '&tab1answer4_2=' + tab1answer4_2.checked;
      objString = objString + '&tab1answer4_3=' + tab1answer4_3.checked;

      //Antworten tab1 | frage 5
      objString = objString + '&tab5remark=' + tab5remark.value;
      objString = objString + '&tab1answer5_0=' + tab1answer5_0.checked;
      objString = objString + '&tab1answer5_1=' + tab1answer5_1.checked;
      objString = objString + '&tab1answer5_2=' + tab1answer5_2.checked;
      objString = objString + '&tab1answer5_3=' + tab1answer5_3.checked;

      //Antworten tab1 | frage 6
      objString = objString + '&tab6remark=' + tab6remark.value;
      objString = objString + '&tab1answer6_0=' + tab1answer6_0.checked;
      objString = objString + '&tab1answer6_1=' + tab1answer6_1.checked;
      objString = objString + '&tab1answer6_2=' + tab1answer6_2.checked;
      objString = objString + '&tab1answer6_3=' + tab1answer6_3.checked;

      //Antworten tab1 | frage 7
      objString = objString + '&tab7remark=' + tab7remark.value;
      objString = objString + '&tab1answer7_0=' + tab1answer7_0.checked;
      objString = objString + '&tab1answer7_1=' + tab1answer7_1.checked;
      objString = objString + '&tab1answer7_2=' + tab1answer7_2.checked;
      objString = objString + '&tab1answer7_3=' + tab1answer7_3.checked;

      //Antworten tab1 | frage 8
      objString = objString + '&tab8remark=' + tab8remark.value;
      objString = objString + '&tab1answer8_0=' + tab1answer8_0.checked;
      objString = objString + '&tab1answer8_1=' + tab1answer8_1.checked;
      objString = objString + '&tab1answer8_2=' + tab1answer8_2.checked;
      objString = objString + '&tab1answer8_3=' + tab1answer8_3.checked;

      //Antworten tab1 | frage 9
      objString = objString + '&tab9remark=' + tab9remark.value;
      objString = objString + '&tab1answer9_0=' + tab1answer9_0.checked;
      objString = objString + '&tab1answer9_1=' + tab1answer9_1.checked;
      objString = objString + '&tab1answer9_2=' + tab1answer9_2.checked;
      objString = objString + '&tab1answer9_3=' + tab1answer9_3.checked;

      //TAB2

      //Antworten tab2 | frage 1
      objString = objString + '&tab2answer1_0=' + tab2answer1_0.checked;
      objString = objString + '&tab2answer1_1=' + tab2answer1_1.checked;
      objString = objString + '&tab2answer1_2=' + tab2answer1_2.checked;
      objString = objString + '&tab2answer1_3=' + tab2answer1_3.checked;

      //Antworten tab2 | frage 2
      objString = objString + '&tab2answer2_0=' + tab2answer2_0.checked;
      objString = objString + '&tab2answer2_1=' + tab2answer2_1.checked;
      objString = objString + '&tab2answer2_2=' + tab2answer2_2.checked;
      objString = objString + '&tab2answer2_3=' + tab2answer2_3.checked;

      //Antworten tab2 | frage 3
      objString = objString + '&tab2answer3_0=' + tab2answer3_0.checked;
      objString = objString + '&tab2answer3_1=' + tab2answer3_1.checked;
      objString = objString + '&tab2answer3_2=' + tab2answer3_2.checked;
      objString = objString + '&tab2answer3_3=' + tab2answer3_3.checked;

      //Antworten tab2 | frage 4
      objString = objString + '&tab2answer4_0=' + tab2answer4_0.checked;
      objString = objString + '&tab2answer4_1=' + tab2answer4_1.checked;
      objString = objString + '&tab2answer4_2=' + tab2answer4_2.checked;
      objString = objString + '&tab2answer4_3=' + tab2answer4_3.checked;

      //Antworten tab2 | frage 5
      objString = objString + '&tab2answer5_0=' + tab2answer5_0.checked;
      objString = objString + '&tab2answer5_1=' + tab2answer5_1.checked;
      objString = objString + '&tab2answer5_2=' + tab2answer5_2.checked;
      objString = objString + '&tab2answer5_3=' + tab2answer5_3.checked;

      //Antworten tab2 | frage 6
      objString = objString + '&tab2answer6_0=' + tab2answer6_0.checked;
      objString = objString + '&tab2answer6_1=' + tab2answer6_1.checked;
      objString = objString + '&tab2answer6_2=' + tab2answer6_2.checked;
      objString = objString + '&tab2answer6_3=' + tab2answer6_3.checked;

      //Antworten tab2 | frage 7
      objString = objString + '&tab2answer7_0=' + tab2answer7_0.checked;
      objString = objString + '&tab2answer7_1=' + tab2answer7_1.checked;
      objString = objString + '&tab2answer7_2=' + tab2answer7_2.checked;
      objString = objString + '&tab2answer7_3=' + tab2answer7_3.checked;

      //Antworten tab2 | frage 8
      objString = objString + '&tab2answer8_0=' + tab2answer8_0.checked;
      objString = objString + '&tab2answer8_1=' + tab2answer8_1.checked;
      objString = objString + '&tab2answer8_2=' + tab2answer8_2.checked;
      objString = objString + '&tab2answer8_3=' + tab2answer8_3.checked;

      //Antworten tab2 | frage 9
      objString = objString + '&tab2answer9_0=' + tab2answer9_0.checked;
      objString = objString + '&tab2answer9_1=' + tab2answer9_1.checked;
      objString = objString + '&tab2answer9_2=' + tab2answer9_2.checked;
      objString = objString + '&tab2answer9_3=' + tab2answer9_3.checked;

      //TAB3

      //Antworten tab3 | frage 1
      objString = objString + '&tab3answer1_0=' + tab3answer1_0.checked;
      objString = objString + '&tab3answer1_1=' + tab3answer1_1.checked;
      objString = objString + '&tab3answer1_2=' + tab3answer1_2.checked;
      objString = objString + '&tab3answer1_3=' + tab3answer1_3.checked;

      //Antworten tab3 | frage 2
      objString = objString + '&tab3answer2_0=' + tab3answer2_0.checked;
      objString = objString + '&tab3answer2_1=' + tab3answer2_1.checked;
      objString = objString + '&tab3answer2_2=' + tab3answer2_2.checked;
      objString = objString + '&tab3answer2_3=' + tab3answer2_3.checked;

      //Antworten tab3 | frage 3
      objString = objString + '&tab3answer3_0=' + tab3answer3_0.checked;
      objString = objString + '&tab3answer3_1=' + tab3answer3_1.checked;
      objString = objString + '&tab3answer3_2=' + tab3answer3_2.checked;
      objString = objString + '&tab3answer3_3=' + tab3answer3_3.checked;

      //Antworten tab3 | frage 4
      objString = objString + '&tab3answer4_0=' + tab3answer4_0.checked;
      objString = objString + '&tab3answer4_1=' + tab3answer4_1.checked;
      objString = objString + '&tab3answer4_2=' + tab3answer4_2.checked;
      objString = objString + '&tab3answer4_3=' + tab3answer4_3.checked;

      //Antworten tab3 | frage 5
      objString = objString + '&tab3answer5_0=' + tab3answer5_0.checked;
      objString = objString + '&tab3answer5_1=' + tab3answer5_1.checked;
      objString = objString + '&tab3answer5_2=' + tab3answer5_2.checked;
      objString = objString + '&tab3answer5_3=' + tab3answer5_3.checked;

      //Antworten tab3 | frage 6
      objString = objString + '&tab3answer6_0=' + tab3answer6_0.checked;
      objString = objString + '&tab3answer6_1=' + tab3answer6_1.checked;
      objString = objString + '&tab3answer6_2=' + tab3answer6_2.checked;
      objString = objString + '&tab3answer6_3=' + tab3answer6_3.checked;

      //Antworten tab3 | frage 7
      objString = objString + '&tab3answer7_0=' + tab3answer7_0.checked;
      objString = objString + '&tab3answer7_1=' + tab3answer7_1.checked;
      objString = objString + '&tab3answer7_2=' + tab3answer7_2.checked;
      objString = objString + '&tab3answer7_3=' + tab3answer7_3.checked;

      //Antworten tab3 | frage 8
      objString = objString + '&tab3answer8_0=' + tab3answer8_0.checked;
      objString = objString + '&tab3answer8_1=' + tab3answer8_1.checked;
      objString = objString + '&tab3answer8_2=' + tab3answer8_2.checked;
      objString = objString + '&tab3answer8_3=' + tab3answer8_3.checked;

      //Antworten tab3 | frage 9
      objString = objString + '&tab3answer9_0=' + tab3answer9_0.checked;
      objString = objString + '&tab3answer9_1=' + tab3answer9_1.checked;
      objString = objString + '&tab3answer9_2=' + tab3answer9_2.checked;
      objString = objString + '&tab3answer9_3=' + tab3answer9_3.checked;

      //TAB4
      //Antworten tab4
      objString = objString + '&tab4answer1_0=' + tab4answer1_0.checked;
      objString = objString + '&tab4answer1_1=' + tab4answer1_1.checked;
      objString = objString + '&tab4answer1_2=' + tab4answer1_2.checked;
      objString = objString + '&tab4answer1_3=' + tab4answer1_3.checked;

      //TAB5
      //Antworten tab5
      objString = objString + '&tab5answer1_0=' + tab5answer1_0.checked;
      objString = objString + '&tab5answer1_1=' + tab5answer1_1.checked;
      objString = objString + '&tab5answer1_2=' + tab5answer1_2.checked;
      objString = objString + '&tab5answer1_3=' + tab5answer1_3.checked;

      //TAB6
      //Antworten tab6
      objString = objString + '&tab6answer1_0=' + tab6answer1_0.checked;
      objString = objString + '&tab6answer1_1=' + tab6answer1_1.checked;
      objString = objString + '&tab6answer1_2=' + tab6answer1_2.checked;
      objString = objString + '&tab6answer1_3=' + tab6answer1_3.checked;

      //TAB7
      //Antworten tab7
      objString = objString + '&tab7answer1_0=' + tab7answer1_0.checked;
      objString = objString + '&tab7answer1_1=' + tab7answer1_1.checked;
      objString = objString + '&tab7answer1_2=' + tab7answer1_2.checked;
      objString = objString + '&tab7answer1_3=' + tab7answer1_3.checked;

      //TAB8
      //Antworten tab8
      objString = objString + '&tab8answer1_0=' + tab8answer1_0.checked;
      objString = objString + '&tab8answer1_1=' + tab8answer1_1.checked;
      objString = objString + '&tab8answer1_2=' + tab8answer1_2.checked;
      objString = objString + '&tab8answer1_3=' + tab8answer1_3.checked;

      //TAB9
      //Antworten tab9
      objString = objString + '&tab9answer1_0=' + tab9answer1_0.checked;
      objString = objString + '&tab9answer1_1=' + tab9answer1_1.checked;
      objString = objString + '&tab9answer1_2=' + tab9answer1_2.checked;
      objString = objString + '&tab9answer1_3=' + tab9answer1_3.checked;

      //console.log(objString);


      //DATA
      setTimeout(() => {
        fetch(apihost+'/de/vue/health/gethealth/'+this.customerid+'/save'+objString)
        .then(response => response.text())
        .then(result => {
        const data = JSON.parse(result);
        //console.log(data);
        this.healthData = data;
        })
        .catch(() => { throw 'Network error' });
      
      }, 0);

      notify({
        message: 'Gesundheitsfragen gesichert',
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'success', 3000);

    },

  },

};


</script>
