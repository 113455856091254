<template>

  <h2 class="content-block">
    <i class="dx-icon nav-icon fa-light fa-comments"></i> 
    {{ title }}
  </h2>

    <div class="chat-container">
      
      <div class="messages" id="messages" style="position: relative;">

        <div class="connection-chat-container hide" style="position: absolute; right: 25px; top: 5px;">
          <div class="connection_ready" v-if="connection_ready">{{nickname}} <i class="fa-light fa-check"></i></div>
        </div>

        <div class="message-chat-container">
          <h1 class="error" v-if="connection_error"> Connection error! </h1>
          <div v-for="(m,idx) in messages" :key="'m-' + idx" style="clear:both">
            <div :class="{ 'msg-from-me' : m.from=='me', 'msg-from-other' : m.from=='other'}">
              <!-- <div class="connection_ready" v-if="connection_ready">{{nickname}} <i class="fa-light fa-check"></i></div> -->
              <div v-if="m.from=='me'">
                <div class="connection_ready" v-if="connection_ready">{{nickname}} <i class="fa-light fa-check"></i></div>
              </div>
              <div v-if="m.from=='other'">
                <!-- <div class="connection_ready" v-if="connection_ready">{{this.receiver}} <i class="fa-light fa-check"></i></div> -->
                <div class="connection_ready" v-if="connection_ready">{{m.email}} <i class="fa-light fa-check"></i></div>
              </div>
              {{m.message}}
            </div>
          </div> 
        </div>
      </div>

      <div class="send-zone">
        <input v-model="new_message" type="text" placeholder="Type a message" @keyup.enter="send_message"/>
      </div>

  </div>
</template>

<script>
import auth from "../auth";

let currentUser;

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;
  } // e.data
}); //auth

export default {

  name: 'HomeView',
  data(){
    return {
      //title: currentUser.Translation.vueappNavMembers,
      //title: "Chat",
      title: "WhatsUp",
      currentUser,
      //TODO: here you must insert your api key from Sockets Bay. Generate it here: https://socketsbay.com/my-account
      sockets_bay_api_key: "97e046fa6fe3d2966e0e55df8122f3fd", 
      connection_ready: false,
      connection_error: false,
      nickname: "",
      websocket: null,
      new_message: "",
      messages: [],
    }
  },
  methods:{
    init_chat() {
      //ask for a nickname
      //if(this.nickname == "") this.nickname = prompt("Enter a nickname:");

      //override ask for a nickname
      //console.log(currentUser.Email);
      this.nickname = currentUser.Email;

      //connect to Sockets Bay
      //var sockets_bay_url = `wss://socketsbay.com/wss/v2/1/${this.sockets_bay_api_key}/`;
      var sockets_bay_url = 'wss://socketsbay.com/wss/v2/10/97e046fa6fe3d2966e0e55df8122f3fd/';
      this.websocket      = new WebSocket(sockets_bay_url);
      //
      this.websocket.onopen    = this.onSocketOpen;
      this.websocket.onmessage = this.onSocketMessage;
      this.websocket.onerror   = this.onSockerError;
    },
    onSocketOpen(evt){
      this.evt = evt;
      this.connection_ready = true;
    },
    onSocketMessage(evt){
      this.evt = evt;
      //we parse the json that we receive
      var received = JSON.parse(evt.data);

      //check if it's our message or from a friend
      this.messages.push( { from: "other", email: received.email, message: received.message } );
      this.receiver = received.email;
      //console.log(this.receiver);

      //scroll to the bottom of the messages div
      const messages_div = document.getElementById('messages');
      const pos = messages_div.scrollHeight;
      messages_div.scrollTo({top: pos, behavior: 'smooth'});
    },

    onSockerError(evt){
      this.evt = evt;
      this.connection_error = true;
    },

    send_message() {
      var to_send = { from: this.nickname, email: this.nickname, message: this.new_message };
      this.websocket.send( JSON.stringify(to_send) );
      this.messages.push( { from: "me", message: this.new_message } );
      this.new_message = "";

      //scroll to the bottom of the messages div
      const messages_div = document.getElementById('messages');
      const pos = messages_div.scrollHeight;
      messages_div.scrollTo({top: pos, behavior: 'smooth'});
    }
  },
  mounted() {
    this.init_chat();
  }
}
</script>

<style lang="scss">
  // body{
  //   background: #111b21;
  // }
  .chat-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 768px;
    min-height: 98vh;
    position: relative;

    .connection_ready{
      font-size: 11px;
      //padding: 3px 10px;
      padding: 3px 0;
      color: white;

      i {
        color: greenyellow;
      }
    }

    .messages::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .messages {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }

    .messages{
      height: 80vh;
      overflow-y: scroll;
      background: url(@/assets/background.jpg) no-repeat center;
      background-size: cover;
      padding-bottom: 100px;
      
      .msg-from-me {
        border-radius: 7.5px;
        max-width: 65%;
        font-size: 16px;
        line-height: 19px;
        color: #e9edef;
        background: fade( #046a62, 90%);
        padding: 5px;
        margin: 20px 20px 5px 0px;
        float: right;
      }
      .msg-from-other {
        border-radius: 7.5px;
        max-width: 65%;
        font-size: 16px;
        line-height: 19px;
        color: #e9edef;
        background: fade( #202c33, 90%);
        padding: 5px;
        margin: 20px 0px 5px 20px;
        float: left;
      }
    }
    .send-zone{
      height: 62px;
      background: #202c33;
      display: flex;

      input[type='text']{
        padding: 9px 12px 11px;
        margin: 5px 10px;
        border: 1px solid #2a3942;
        background: #2a3942;
        border-radius: 8px;
        font-size: 15px;
        flex-grow: 1;
        color: white;
      }

    }
  }
  
</style>
