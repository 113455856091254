<template>
  <!-- mainhide -->
  <div class="hide" style="font-size: 10px; padding: 10px; overflow: hidden;">

    <!-- subhide -->
    <div class="-hide" style="color: orange;">
      <!-- <p>currentUser: {{ this.templateData }}</p> -->
    </div>
    <!-- /subhide -->

    &nbsp;

    <!-- subhide -->
    <div class="-hide" style="color: green;">
      <p>SchedulerItems: {{ this.SchedulerItems }}</p>

    </div>
    <!-- /subhide -->

  </div>
  <!-- /mainhide -->

  <!-- MASTER REVEAL -->
  <div id="revealDialogCourse" class="reveal" data-reveal style="padding: 0; border: none;">
    <div class="content" style="padding: 15px;">
      <div :id="'revealHtml'" class="reveal-html">
        <h2 class="no-margin-top">Awesome. I Have It. Course</h2>
      </div>
      <button class="close-button" data-close aria-label="Close modal" type="button">
        <span aria-hidden="true">&times;</span>
      </button>
    </div><!-- content -->
  </div><!-- revealDialogCourse -->
  <!-- //MASTER REVEAL -->

  <!-- <p><a @click="openReveal()">Click me for a modal</a></p> -->


  <template v-for="item in SchedulerItems" :key="item.id">

    <div class="scheduler-list-item grid-x grid-margin-x -align-middle" :class="{ active: item.ischeckedin }">

      <div class="-auto cell">
        <p class="ck-overflow-ellipsis">
          <i class="fa-light fa-whistle"></i>
          Kurs: {{ item.text }} 
        </p>
        <p class="ck-overflow-ellipsis">
          <i class="fa-light fa-location-dot"></i>
          Standort: {{ item.locationtext }}
        </p>
        <p class="ck-overflow-ellipsis">
          <i class="fa-light fa-calendar-clock"></i>
          Datum: {{ formatDateTs(item.startTs*1000) }}
        </p>
        <p class="ck-overflow-ellipsis">
          <i class="fa-light fa-user"></i>
          Trainer: {{ item.trainertext }}
        </p>
        <p class="ck-overflow-ellipsis">
          <a @click="openReveal(item.id)">
            <i class="fa-light fa-users"></i>
            Teilnehmer: {{  item.checkdata.length }}
          </a>
        </p>


        <!-- <a @click="openReveal(item.id)">{{ item.id }}  Click me for a modal</a> -->

        <!-- revealContent -->
        <div :id="'revealContent'+item.id" class="hide">

          <div class="-userblock" style="">
            <template v-for="data in item.checkdata" :key="data.id">
              <div class="grid-x align-middle" style="margin-bottom: 5px;">
                <div class="shrink cell">
                <div class="user-thumb" style="margin-right: 5px;"
                v-bind:style="{ 'background-image': 'url(' + data.avatar + '?v=' + timestamp + ')' }" >
                </div>
                </div>
                <div class="auto cell">
                  <p class="ck-overflow-ellipsis">{{ data.firstname }} {{ data.lastname }}</p>
                </div>
              </div><!-- grid-x -->
            </template>
          </div> <!-- userblock -->

        </div>
        <!-- /revealContent -->


      </div>

      <div class="-shrink cell">

        <p class="ck-overflow-ellipsis hide">ischeckedin: {{ item.ischeckedin }}</p>
        <p class="ck-overflow-ellipsis hide">checkdata: {{ item.checkdata }}</p>
        
        <div v-if="item.ischeckedin == false">
        <button @click="checkCourse('checkin',item.id,currentUser.id)" class="cx-button tiny"><i class="fa-solid fa-right-to-bracket"></i> Anmelden</button>
        </div>
        <div v-if="item.ischeckedin == true">
        <button @click="checkCourse('checkout',item.id,currentUser.id)" class="cx-button tiny"><i class="fa-solid fa-right-to-bracket"></i> Abmelden</button>
        </div>
      </div>
      <!-- shrink cell -->

    </div>
    <!-- grid-x -->

  </template>
  <!-- template v-for -->

<!-- bottomspace or if no-content -->
 &nbsp;
 <!-- bottomspace or if no-content -->

</template>

<script>

let currentUser;
let SchedulerItems;
let timestamp;

import $ from 'jquery';
import Foundation from 'foundation-sites';

import auth from "../auth";
import { 
  apihost, 
} from "../api";
//console.log(apihost);

function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

auth.getUser().then((e) => {
if(e.data){
  //console.log(e.data);
  currentUser = e.data;
} // e.data
}); //auth

export default {
  name: 'training-data',

  props: {
    templateData: Object,
  },

  mounted() {

    this.reveal = new Foundation.Reveal($('#revealDialogCourse'), {
      // These options can be declarative using the data attributes
      animationIn: 'scale-in-up',
    });

    //timestamp
    this.timestamp = this.printTimestamp();

    //DATA Scheduler
    let objString = '?' + 'customerid='+this.templateData.id;
    objString = objString + '&filtertype=1';
    objString = objString + '&filterstart='+this.timestamp;
    //console.log(objString);


    fetch(apihost+'/'+currentUser.Language+'/vue/scheduler/getscheduler/id/action'+objString)
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
      const data = JSON.parse(result);
      //console.log(data);
      this.SchedulerItems = data;
    })
    .catch(() => { throw 'Network error' });
    
  },

  data() {
    return {
      apihost,
      msg: 'Course (part)',
      currentUser,
      timestamp,
      SchedulerItems,
    };
  },

  methods: {

    // Added the below openReveal method for two reasons:
    // 1) There was a bug preventing the reveal from working once
    // you navigated away and back to the reveal component.
    // 2) Most dialogs will need to be opened using code.

    openReveal(id) {
      const revealhtml = document.getElementById("revealHtml");
      const revealcontent = document.getElementById('revealContent'+id);
      const revealhtmlcontent = revealcontent.innerHTML;

      //Set Content before open
      revealhtml.innerHTML = revealhtmlcontent;

      //Open Reveal
      //console.log(this.reveal);
      this.reveal.open();

    },

    checkCourse(action, itemid, customerid) {
    // console.log(action);
    // console.log(itemid);
    // console.log(customerid);

    //let objString
    let objString = '?' + 'customerid='+customerid;
    objString = objString + '&filtertype=1';
    objString = objString + '&filterstart='+this.timestamp;
    //console.log(objString);

    //Scheduler
    fetch(apihost+'/'+currentUser.Language+'/vue/scheduler/getscheduler/'+itemid+'/'+action+objString)
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
      const data = JSON.parse(result);
      //console.log(data);
      this.SchedulerItems = data;
    })
    .catch(() => { throw 'Network error' });

    },


    formatDateTs(ts) {
      //console.log(ts);
      const timestamp = parseInt(ts); // timestamp
      const date = new Date(timestamp);
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

      // Get Hours part from the timestamp
      var hours = '0' + date.getHours();
      hours = hours.slice(-2);
      //console.log(hours);

      // Get Minutes part from the timestamp
      var minutes = '0' + date.getMinutes();
      minutes = minutes.slice(-2);
      //console.log(minutes);
      
      const formattedDate = new Intl.DateTimeFormat('de-DE', options).format(date) + ', ' + hours+':'+minutes;
      //console.log(formattedDate);

      return formattedDate;
    },
    
    printTimestamp: function () {
      return Date.now();
    },
    

  },

};


</script>
