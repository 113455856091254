<template>

  <h2 class="content-block">
    <i class="dx-icon nav-icon fa-light fa-users"></i> 
    {{ title }}
  </h2>

  <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block">

    <div class="dx-card _responsive-paddings -has-paddings">
      <!-- <p>Put your content here</p> -->
    <DxLoadPanel
      v-model:visible="isLoadPanelVisible"
    />

    <div id="employeeList" class="-grid-x -grid-margin-x -grid-margin-y -xsmall-up-2 -medium-up-3 -large-up-4">
      <EmployeeItem
        v-for="employee in employees"
        :key="employee.ID"
        :employee="employee"
        :show-info="showInfo"
      />
    </div>

    <!-- <DxPopup
      v-model:visible="popupVisible"
      :drag-enabled="true"
      :hide-on-outside-click="true"
      :show-close-button="false"
      :show-title="true"
      :width="300"
      :height="280"
      container="#app"
      title="Information"
    > -->
    <!-- <DxPosition
      at="bottom"
      my="center"
      v-model:of="positionOf"
      collision="fit"
    /> -->

      <DxPopup
        v-model:visible="popupVisible"
        :drag-enabled="true"
        :hide-on-outside-click="true"
        :show-close-button="true"
        :show-title="true"
        container="#app"
        position="center"
        title="Information"
      >

      <DxPosition
      />
      
      <DxToolbarItem
        widget="dxButton"
        toolbar="top"
        locate-in-menu="always"
        :options="moreInfoButtonOptions"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="before"
        :options="emailButtonOptions"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="closeButtonOptions"
      />


      <div class="itemrow">
        <div class="legend">
          Name
        </div>
        <div class="text">
          {{ currentEmployee.Title }} {{ currentEmployee.FirstName }} {{ currentEmployee.LastName }}
        </div>
      </div>

      <div class="itemrow">
        <div class="legend">
          E-Mail
        </div>
        <div class="text">
          {{ currentEmployee.Email }}
        </div>
      </div>

      <div class="itemrow">
        <div class="legend">
          Telefon
        </div>
        <div class="text">
          {{ currentEmployee.Phone }}
        </div>
      </div>

      <div class="itemrow">
        <div class="legend">
          Strasse
        </div>
        <div class="text">
          {{ currentEmployee.Street }}
        </div>
      </div>

      <div class="itemrow">
        <div class="legend">
          Ort
        </div>
        <div class="text">
          {{ currentEmployee.Zip }} {{ currentEmployee.City }}
        </div>
      </div>

      <div class="itemrow">
        <div class="legend">
          Geburtsdatum
        </div>
        <div class="text">
          {{getFormattedDate(currentEmployee.BirthDate)}}
        </div>
      </div>

      <div class="itemrow">
        <div class="legend">
          Notizen
        </div>
        <div class="text">
          {{ currentEmployee.Notes }}
        </div>
      </div>

      <div class="itemrow">
        <div class="legend">
          Erstellt
        </div>
        <div class="text">
          {{getFormattedDate(currentEmployee.CreationDate)}}
        </div>
      </div>

      <div class="itemrow">
        <div class="legend">
          Geändert
        </div>
        <div class="text">
          {{getFormattedDate(currentEmployee.ModificationDate)}}
        </div>
      </div>

      <div class="itemrow">
        <div class="legend">
          Rolle
        </div>
        <div class="text">
          {{ currentEmployee.Role }}
        </div>
      </div>

      <div class="itemrow">
        <div class="legend">
          ID
        </div>
        <div class="text">
          {{ currentEmployee.id }}
        </div>
      </div>

    </DxPopup>

  </div><!-- dx-card -->
  
</div><!-- content-block -->

<div v-else class="content-block">
  Oh no 😢
</div><!-- content-block -->

</template>

<script>
import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup';
import { DxLoadPanel } from 'devextreme-vue/load-panel';

import notify from 'devextreme/ui/notify';
import EmployeeItem from './dx-memberpopupitem';
import moment from 'moment';

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

let currentUser;

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;
  } // e.data
}); //auth

let employees = {};
let isLoadPanelVisible = true;

export default {

  mounted() {

    auth.getUser().then((e) => {
      if(e.data){
        // console.log(e.data);
        // console.log(e.data.id);
        // console.log(e.data.Language);

        //DATA
        fetch(apihost+'/de/vue/member/list/')
        .then(response => response.text())
        .then(result => {
        const data = JSON.parse(result);
        //console.log(data)
        this.employees = data;
        this.isLoadPanelVisible = false;
        })
        .catch(() => { throw 'Network error' });
      }
    });

  },

  components: {
    DxPopup,
    DxPosition,
    DxToolbarItem,
    DxLoadPanel,
    EmployeeItem,
  },

  data() {
    return {
      apihost,

      title: 'Mitglieder Popup',
      currentUser,
      employees,
      currentEmployee: {},
      popupVisible: false,
      positionOf: '',
      isLoadPanelVisible,

      moreInfoButtonOptions: {
        text: 'More info',
        onClick: () => {
          const message = `More info about ${this.currentEmployee.FirstName} ${this.currentEmployee.LastName}`;
          notify({
            message,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'success', 3000);
        },
      },
      emailButtonOptions: {
        icon: 'email',
        text: 'Send',
        onClick: () => {
          const message = `Email is sent to ${this.currentEmployee.FirstName} ${this.currentEmployee.LastName}`;
          notify({
            message,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'success', 3000);
        },
      },
      closeButtonOptions: {
        text: 'Close',
        onClick: () => {
          this.popupVisible = false;
        },
      },
    };
  },

  methods: {
    showInfo(employee) {
      this.currentEmployee = employee;
      this.positionOf = `#image${employee.ID}`;
      this.popupVisible = true;
    },

    getFormattedDate(date) {
      if(date){
        return moment(date).format("DD.MM.YYYY")
      }
    }
    
  },
};
</script>
<style>

</style>

