<template>
<svg version="1.1" id="logo-trainiq" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 207.5 51" style="enable-background:new 0 0 207.5 51;" xml:space="preserve">
<path class="st0" d="M103.6,8.3v35.3l0,0c-4.1,0-7.5-3.3-7.5-7.5V0.9l0,0C100.2,0.9,103.5,4.2,103.6,8.3
C103.6,8.3,103.6,8.3,103.6,8.3z"/>
<path class="st0" d="M134.7,0.1c-6,0-9.9,3.9-14.5,7.4l0,0c-0.4-3.8-3.6-6.7-7.4-6.7v35.3c0,4.1,3.3,7.5,7.5,7.5l0,0V15.5
c5.1-4.3,9.2-8.3,12.7-8.3c4.7,0,6.2,2.6,6.2,7v22c0,4.1,3.3,7.5,7.5,7.5V11.9C146.6,4.2,142.4,0.1,134.7,0.1z"/>
<path class="st0" d="M163.2,8.3v35.3l0,0c-4.1,0-7.5-3.3-7.5-7.5V0.9l0,0C159.9,0.9,163.2,4.2,163.2,8.3
C163.2,8.3,163.2,8.3,163.2,8.3z"/>
<path class="st0" d="M87.3,16.5c0.1-11.2-5.9-16-17.1-16.4c-5.5-0.2-13.8,0-23.1,7c-0.6-3.6-3.7-6.2-7.3-6.2v35.3
c0,4.1,3.3,7.5,7.5,7.5h0V14.1c6.5-7.3,18-8.6,23.7-8.1c7,0.7,9,4,9,9.4V20h-9.1c-8.1,0-14.5,4.3-14.5,12.6
c0,7.9,5.5,11.8,12.8,11.8c3.9,0.1,7.8-1.2,10.9-3.6l1-0.9c1.3,2.3,3.8,3.6,6.4,3.6v-9.1l0,0L87.3,16.5z M63.4,32.2
c0-4.5,2.9-6.8,7.8-6.8h8.6V35c-2.9,2.1-6.5,3.3-10.1,3.4C66.4,38.4,63.4,36.4,63.4,32.2z"/>
<path class="st0" d="M14.7,32.2c0.1,4.2,2.8,6.1,6.1,6.1c3.8,0,7-1.5,10.1-3.6v6.1c-3.1,2.4-7,3.6-10.9,3.6
c-7.2,0-12.7-3.8-12.7-11.8v-0.9L7.2,7H6.1C2.7,7,0,4.3,0,0.9c0,0,0,0,0,0h29.2c3.4,0,6.1,2.7,6.1,6.1c0,0,0,0,0,0.1H14.7v24.2
C14.7,31.5,14.7,32,14.7,32.2z"/>
<path class="st1" d="M193.1,36.8V51l0,0c-4.1,0-7.5-3.3-7.5-7.5v0V29.4l0,0C189.7,29.4,193.1,32.7,193.1,36.8
C193.1,36.8,193.1,36.8,193.1,36.8z"/>
<path class="st0" d="M189.4,0c-9.6,0-18.1,5.7-18.1,16.9v10.6c0,9,5.4,14.4,12.5,16.2v-7.1c-3.2-1.7-5-5-5-9.6v-9.6
c0-6.7,3.9-10.8,10.6-10.8S200,10.7,200,17.4V27c0,4.6-1.8,7.9-5,9.6v7.1c7.1-1.8,12.5-7.2,12.5-16.2V16.9
C207.5,5.7,199,0,189.4,0z"/>
</svg>
</template>

