<template>
  <div class="side-nav-inner-toolbar">
    <dx-drawer
      class="drawer"
      position="before"
      template="menuTemplate"
      v-model:opened="menuOpened"
      :opened-state-mode="drawerOptions.menuMode"
      :reveal-mode="drawerOptions.menuRevealMode"
      :min-size="drawerOptions.minMenuSize"
      :max-size="drawerOptions.maxMenuSize"
      :shading="drawerOptions.shaderEnabled"
      :close-on-outside-click="drawerOptions.closeOnOutsideClick"
    >
      <div class="container">
        <header-toolbar
          :menu-toggle-enabled="headerMenuTogglerEnabled"
          :toggle-menu-func="toggleMenu"
        />
        <dx-scroll-view ref="scrollViewRef" class="layout-body with-footer">
          <slot />
          <slot name="footer" />
        </dx-scroll-view>
      </div>
      <template #menuTemplate>
        <side-nav-menu
          :compact-mode="!menuOpened"
          @click="handleSideBarClick"
        >
          <dx-toolbar id="navigation-header">
            <dx-item v-if="!isXSmall" location="before" css-class="menu-button">
              <template #default>
              <dx-button
                icon="menu"
                styling-mode="text"
                @click="toggleMenu"
              />
              </template>
            </dx-item>
            <dx-item location="before" css-class="header-title dx-toolbar-label">
              <template #default>
                <div class="appname-text">
                  {{ title }} 
                </div>
                <div v-if="stakeholder == 'msa'" class="main-logo logo-msa">
                  <LogoMsa />
                </div>
                <div v-if="stakeholder == 'tgym'" class="main-logo logo-tgym">
                  <LogoTgym />
                </div>
                <div v-if="stakeholder == 'dynamic'" class="main-logo logo-dynamic">
                  <LogoDynamic />
                </div>
                <div v-if="stakeholder == 'lasuite'" class="main-logo logo-lasuite">
                  <LogoLasuite />
                </div>
                <div v-if="stakeholder == 'x30'" class="main-logo logo-x30">
                  <LogoX30 />
                </div>
                <div v-if="stakeholder == 'profitfitness'" class="main-logo logo-profitfitness">
                  <LogoProfitfitness />
                </div>
                <div v-if="stakeholder == 'physioplus'" class="main-logo logo-physioplus">
                  <LogoPhysioplus />
                </div>
                <div v-if="stakeholder == 'goldsgym'" class="main-logo logo-goldsgym">
                  <LogoGoldsgym />
                </div>
                <div v-if="stakeholder == 'fitx'" class="main-logo logo-fitx">
                  <LogoFitx />
                </div>
                <div v-if="stakeholder == 'kuoni'" class="main-logo logo-kuoni">
                  <LogoKuoni />
                </div>
                <div v-if="stakeholder == 'chilihealth'" class="main-logo logo-chilihealth">
                  <LogoChilihealth />
                </div>
                <div v-if="stakeholder == 'fitnessunited'" class="main-logo logo-fitnessunited">
                  <LogoFitnessunited />
                </div>
                <div v-if="stakeholder == 'neogate'" class="main-logo logo-neogate">
                  <LogoNeogate />
                </div>
                <div v-if="stakeholder == 'christoppark'" class="main-logo logo-christoppark">
                  <LogoChristoppark />
                </div>
                <div v-if="stakeholder == '4trainingfitness'" class="main-logo logo-4trainingfitness">
                  <Logo4trainingfitness />
                </div>            
                <div v-if="stakeholder == 'besttraining'" class="main-logo logo-besttraining">
                  <LogoBesttraining />
                </div>
                <div v-if="stakeholder == 'quantumgym'" class="main-logo logo-quantumgym">
                  <LogoQuantumgym />
                </div>
                <div v-if="stakeholder == 'gym365'" class="main-logo logo-gym365">
                  <LogoGym365 />
                </div>
                <div v-if="stakeholder == 'trainiq'" class="main-logo logo-trainiq">
                  <LogoTrainiq />
                </div>
                <div v-if="stakeholder == 'lfit'" class="main-logo logo-lfit">
                  <LogoLfit />
                </div>

              </template>

            </dx-item>
          </dx-toolbar>
        </side-nav-menu>
      </template>
    </dx-drawer>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxDrawer from "devextreme-vue/drawer";
import DxScrollView from "devextreme-vue/scroll-view";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";

import HeaderToolbar from "../components/header-toolbar";
import SideNavMenu from "../components/side-nav-menu";
import menuItems from "../app-navigation";
import { ref, watch, computed } from 'vue';
import { useRoute } from 'vue-router';

import LogoMsa from '../svg/logo-msa.vue';
import LogoTgym from '../svg/logo-tgym.vue';
import LogoDynamic from '../svg/logo-dynamic.vue';
import LogoLasuite from '../svg/logo-lasuite.vue';
import LogoX30 from '../svg/logo-x30.vue';
import LogoProfitfitness from '../svg/logo-profitfitness.vue';
import LogoPhysioplus from '../svg/logo-physioplus.vue';
import LogoGoldsgym from '../svg/logo-goldsgym.vue';
import LogoFitx from '../svg/logo-fitx.vue';
import LogoKuoni from '../svg/logo-kuoni.vue';
import LogoChilihealth from '../svg/logo-chilihealth.vue';
import LogoFitnessunited from '../svg/logo-fitnessunited.vue';
import LogoNeogate from '../svg/logo-neogate.vue';
import LogoChristoppark from '../svg/logo-christoppark.vue';
import Logo4trainingfitness from '../svg/logo-4trainingfitness.vue';
import LogoBesttraining from '../svg/logo-besttraining.vue';
import LogoQuantumgym from '../svg/logo-quantumgym.vue';
import LogoGym365 from '../svg/logo-gym365.vue';
import LogoTrainiq from '../svg/logo-trainiq.vue';
import LogoLfit from '../svg/logo-lfit.vue';

import { 
  apihost, 
} from "../api";


export default {
  props: {
    title: String,
    isXSmall: Boolean,
    isLarge: Boolean
  },
  setup(props) {

    //stakeholder
    const pos = apihost.indexOf("api")+3;
    const result = apihost.substring(pos, 100);
    const posend = result.indexOf(".");
    const stakeholder = result.substring(0, posend);

    const route = useRoute();

    const scrollViewRef = ref(null);
    const menuOpened = ref(props.isLarge);
    const menuTemporaryOpened = ref(false);

    function toggleMenu (e) {
      const pointerEvent = e.event;
      pointerEvent.stopPropagation();
      if (menuOpened.value) {
        menuTemporaryOpened.value = false;
      }

      menuOpened.value = !menuOpened.value;
    }

    function handleSideBarClick () {
      if (menuOpened.value === false) {
        menuTemporaryOpened.value = true;
      }

      menuOpened.value = true;
    }

    const drawerOptions = computed(() => {
      const shaderEnabled = !props.isLarge;

      return {
        menuMode: props.isLarge ? "shrink" : "overlap",
        menuRevealMode: props.isXSmall ? "slide" : "expand",
        minMenuSize: props.isXSmall ? 0 : 60,
        maxMenuSize: props.isXSmall ? 250 : undefined,
        closeOnOutsideClick: shaderEnabled,
        shaderEnabled
      };
    });

    const headerMenuTogglerEnabled = computed(() => {
      return props.isXSmall;
    });

    watch(
      () => props.isLarge,
      () => {
        if (!menuTemporaryOpened.value) {
          menuOpened.value = props.isLarge;
        }
      }
    );

    watch(
      () => route.path,
      () => {
        if (menuTemporaryOpened.value || !props.isLarge) {
          menuOpened.value = false;
          menuTemporaryOpened.value = false;
        }
        scrollViewRef.value.instance.scrollTo(0);
      }
    );

    return {
      apihost,
      stakeholder,
      scrollViewRef,
      menuOpened,
      drawerOptions,
      menuItems,
      headerMenuTogglerEnabled,
      toggleMenu,
      handleSideBarClick
    };
  },
  components: {
    DxButton,
    DxDrawer,
    DxScrollView,
    DxToolbar,
    DxItem,
    HeaderToolbar,
    SideNavMenu,

    LogoMsa,
    LogoTgym,
    LogoDynamic,
    LogoLasuite,
    LogoX30,
    LogoProfitfitness,
    LogoPhysioplus,
    LogoGoldsgym,
    LogoFitx,
    LogoKuoni,
    LogoChilihealth,
    LogoFitnessunited,
    LogoNeogate,
    LogoChristoppark,
    Logo4trainingfitness,
    LogoBesttraining,
    LogoQuantumgym,
    LogoGym365,
    LogoTrainiq,
    LogoLfit,
    
  }
};
</script>

<style lang="scss">

</style>
