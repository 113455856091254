<template>
    <div class="content-block">
  
        <h2 class="main-title">Json Store</h2>
  
          <div class="dx-card no-paddings dx-card-has-minheight">
  
            <DxDataGrid
              :data-source="customDataSource"
              :focused-row-index="-1"
              :show-borders="false"
              :focused-row-enabled="true"
              :column-auto-width="true"
              :column-hiding-enabled="true"
              :remote-operations="false"
            >

            <DxPaging :page-size="50" />
            <DxPager :show-page-size-selector="true" :show-info="true" />
            <DxFilterRow :visible="true" />

            <DxColumn 
              data-field="id" 
              caption="Id"
              data-type="number"
              alignment='left' 
              :width="90" 
              :hiding-priority="1" 
            />
  
            <DxColumn
              data-field="Subject"
              caption="Betreff"
              placeholder="Betreff"
              alignment='left' 
              :hiding-priority="3"
            />
  
            <DxColumn
              data-field="Description"
              alignment='left' 
              caption="Description"
              :hiding-priority="5"
            />
  
            <DxColumn
              data-field="Status"
              alignment='left' 
              caption="Status"
              :hiding-priority="4"
            />
  
            <DxColumn
              data-field="CreationDate"
              caption="Erstellt"
              placeholder="Erstellt"
              data-type="date"
              alignment='left' 
              format="dd.MM.yyyy"
              :hiding-priority="3"
            />
  
            <DxColumn
              data-field="ModificationDate"
              caption="Geändert"
              placeholder="Geändert"
              data-type="date"
              alignment='left' 
              format="dd.MM.yyyy"
              :hiding-priority="2"
            />
  
          </DxDataGrid>
  
        </div><!-- dx-card -->
  
    </div><!-- content-block -->
  
  </template>
  
  
  <script>
  
  import DxDataGrid, {
    DxColumn,
    DxFilterRow,
    //DxLookup,
    DxPager,
    DxPaging
  
  } from "devextreme-vue/data-grid";


  import CustomStore from 'devextreme/data/custom_store';

  import { 
    apihost, 
  } from "../api";
  //console.log(apihost);

  
  function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
  }
  
  const customDataSource = new CustomStore({
    key: 'id',
    //loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
    //loadMode: 'processed',
    //https://stackoverflow.com/questions/73548742/uncaught-in-promise-syntaxerror-unexpected-end-of-json-input-with-cors-http-p
    load: () => {
        return fetch(apihost+'/de/vue/task/list/')
        .then(handleErrors)
        .then(response => response.text())
        .then(data => {
        //console.log(`response text`, text)
        return JSON.parse(data)
        })
        .catch(() => { throw 'Network error' });
    },
    // Needed to process selected value(s) in the SelectBox, Lookup, Autocomplete, and DropDownBox
    // byKey: (key) => {
    //     return fetch(`https://mydomain.com/MyDataService?id=${key}`)
    //         .then(handleErrors);
    // }
  });
  
  //console.log(customDataSource);
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxFilterRow,
      //DxLookup,
      DxPager,
      DxPaging,
      
    },
    data() {
      return {
        apihost,
        customDataSource,
      }
    },
  }
  </script>