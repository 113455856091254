<template>
<svg version="1.1" id="WomanFront" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 450 950" style="enable-background:new 0 0 450 950;" xml:space="preserve">
<g id="Hand_WF">
<path class="st0" d="M378.9,489.4c0,0,5.4,0.6,12-1.1c6.7-1.7,10.2-4,10.2-4s6.4,15.5,8.2,19c1.8,3.5,5.4,12.6,5.5,18.8
c0.1,6.2-1.7,12.8-2.4,18c-0.8,5.2-1.8,12.2-2.9,15.3c-1.1,3.1-2.1,4.9-5.5,8.6c-3.4,3.7-7.4,9.2-9.6,10.4s-3.6,0.8-3.6-1.2
c0-2.1,0-2.1,0-2.1s-0.9-1.1-0.5-3c0.5-1.9,0.4-1.6,0.4-1.6s-2.2,0.6-1.6-2.4c0.6-2.9,3.9-8.4,7.6-13.5c3.8-5.1,5.2-7.1,5.2-7.1
s-0.9-10-2-14.5c-1-4.5-2.4-8.2-4.4-7.3c-2,0.8-3.4,3.4-3.4,7.9s1.5,7.7,1.7,11.8s0.8,6.6-1.9,9.1c-2.6,2.5-3.5,0.5-5.1-5.9
c-1.6-6.4-5.2-17.2-6.3-24.3c-1.1-7.1-1.2-18.8-1-22.2C379.7,494.8,378.9,489.4,378.9,489.4z"/>
<path class="st0" d="M72.5,489.4c0,0-5.4,0.6-12-1.1c-6.7-1.7-10.2-4-10.2-4s-6.4,15.5-8.2,19c-1.8,3.5-5.4,12.6-5.5,18.8
c-0.1,6.2,1.7,12.8,2.4,18c0.8,5.2,1.8,12.2,2.9,15.3c1.1,3.1,2.1,4.9,5.5,8.6c3.4,3.7,7.4,9.2,9.6,10.4c2.2,1.1,3.6,0.8,3.6-1.2
c0-2.1,0-2.1,0-2.1s0.9-1.1,0.5-3c-0.5-1.9-0.4-1.6-0.4-1.6s2.2,0.6,1.6-2.4c-0.6-2.9-3.9-8.4-7.6-13.5s-5.2-7.1-5.2-7.1
s0.9-10,2-14.5c1-4.5,2.4-8.2,4.4-7.3c2,0.8,3.4,3.4,3.4,7.9s-1.5,7.7-1.7,11.8c-0.2,4-0.8,6.6,1.9,9.1c2.6,2.5,3.5,0.5,5.1-5.9
c1.6-6.4,5.2-17.2,6.3-24.3c1.1-7.1,1.2-18.8,1-22.2C71.7,494.8,72.5,489.4,72.5,489.4z"/>
</g>
<g id="Waden_WF">
<path class="st0" d="M180.3,762.8c0.4,1.6,5.1,19.6,10.7,25.5c0.9-5.3,1.8-10.6,2.7-15.6c2.3-12.8,4.4-24.8,4.5-34.3
c0.1-11.4-1-20.7-2-28.9c-0.3-2.2-0.5-4.4-0.7-6.5c-2.6,3.6-8.7,12.9-12,22.8C181.8,731,180.6,745.3,180.3,762.8z"/>
<path class="st0" d="M180.9,770.2c1.5,11.4,5.7,29.1,7.7,37.3c0.6-5.8,1.3-11.6,2.2-17.2C186.6,786.6,183,776.9,180.9,770.2z"/>
<path class="st0" d="M180.4,787.2c0.7,29.2,3.4,53,7.3,67.1c-0.3-12.8-0.1-28.6,0-32.9c0.1-3.1,0.2-6.2,0.4-9.3
c-0.5-2-5.4-21.1-7.9-35.7C180.3,779.9,180.4,783.6,180.4,787.2z"/>
<path class="st0" d="M177,878.8c0.4-1.5-0.7-14.9-1.9-30.3c-1.5-19.1-3.7-45.2-4.4-66.5c-0.4-10.2-0.6-19.6-0.8-27.9
c-0.6-24-1-41.4-4.3-49.5c-2-4.9-5-7.8-8-10.6c-2.7-2.5-5.4-5.2-7.4-9.2c0.3,5.5,0.6,9.7,0.3,11.9c-0.1,1.1-0.7,2.9-1.4,5.4
c-0.9,2.9-2,6.5-3.1,11c0.7,4.7,19.7,126.2,28.2,167.2C175.6,879.9,176.6,879.3,177,878.8z"/>
<path class="st0" d="M170.1,881.4c1-0.2,1.9-0.4,2.7-0.6c-1.6-7.6-3.5-18-5.6-29.9l0.4,30.7C168.4,881.6,169.2,881.5,170.1,881.4z"
/>
<path class="st0" d="M165.5,841.6c-8-46-17.8-108.1-20.4-124.3c-1.7,8.5-2.9,19.3-2.1,32.3c2.3,33.6,7.8,61.1,12.3,83.2
c1.1,5.6,2.2,10.8,3.1,15.9c0.4,2.2,0.9,4.4,1.3,6.5c1.6,7.9,3.1,15.4,2.7,21.3c-0.1,1.8-0.5,3.4-0.8,4.8c0.9,0.2,2.5,0.4,4.4,0.5
L165.5,841.6z"/>
<path class="st0" d="M278.7,880.8c0.8,0.2,1.8,0.4,2.7,0.6c0.8,0.1,1.7,0.2,2.5,0.3l0.4-30.7C282.2,862.8,280.2,873.2,278.7,880.8z
"/>
<path class="st0" d="M285.4,881.6c1.9,0,3.5-0.3,4.4-0.5c-0.4-1.4-0.7-2.9-0.8-4.8c-0.5-5.9,1.1-13.4,2.7-21.3
c0.4-2.1,0.9-4.3,1.3-6.5c0.9-5,2-10.3,3.1-15.8c4.5-22.1,10-49.6,12.3-83.2c0.9-13-0.4-23.8-2.1-32.3
c-2.5,16.2-12.3,78.3-20.4,124.3L285.4,881.6z"/>
<path class="st0" d="M260.7,790.2c0.9,5.7,1.7,11.4,2.2,17.2c2-8.2,6.2-25.9,7.7-37.3C268.4,776.9,264.8,786.6,260.7,790.2z"/>
<path class="st0" d="M271.2,776.4c-2.5,14.6-7.4,33.7-7.9,35.7c0.2,3.1,0.4,6.2,0.4,9.3c0.1,4.3,0.3,20.1,0,32.9
c3.9-14,6.5-37.9,7.3-67.1C271.1,783.6,271.2,779.9,271.2,776.4z"/>
<path class="st0" d="M293.8,694c-2.9,2.8-6,5.7-8,10.6c-3.3,8.1-3.7,25.5-4.3,49.5c-0.2,8.3-0.4,17.7-0.8,27.9
c-0.8,21.3-2.9,47.4-4.4,66.5c-1.2,15.5-2.3,28.8-1.9,30.3c0.4,0.5,1.4,1,2.7,1.5c8.5-41,27.5-162.5,28.2-167.2
c-1.1-4.5-2.2-8.2-3.1-11c-0.8-2.5-1.3-4.3-1.4-5.4c-0.3-2.2,0-6.5,0.3-11.9C299.2,688.8,296.5,691.5,293.8,694z"/>
<path class="st0" d="M268,725.9c-3.3-10-9.4-19.2-12-22.9c-0.2,2.1-0.5,4.2-0.7,6.5c-1,8.2-2.1,17.5-2,28.9
c0.1,9.5,2.2,21.6,4.5,34.3c0.9,5,1.8,10.2,2.7,15.6c5.6-6,10.3-23.9,10.7-25.5C270.8,745.3,269.7,731,268,725.9z"/>
</g>
<g id="Fuss_WF">
<path class="st0" d="M152.7,911.3c0,0,5.1-11.3,6.1-15.5s2.1-14.1,2.1-14.1s5.6,1.7,10.3,0.3s6.5-2.7,6.5-2.7s2.5,5.8,9.5,4
c0,0,1,7.9,2.4,11s2.7,9.6,1.4,12.4c-1.3,2.8-4.1,4.9-4.2,8s-0.9,13.5-2.3,16.5c-1.4,3-3.7,7.3-6.2,10.4c-2.5,3.1-9.1,8.7-13.1,7.2
c-2.5-0.9-2.4-3.7-2.4-3.7s-4,3.2-6.4,2.5c-3.1-0.9-2.7-3-2.7-3s-3,2.7-5.1,1.3s-1.7-3.4-1.7-3.4s-4,0.5-5.1-0.7
c-1.8-1.9-0.6-4.2-0.6-4.2s-3.4-0.4-2.4-4c1-3.5,8-12.8,9.9-15.5C150.6,915.5,152.7,911.3,152.7,911.3z"/>
<path class="st0" d="M298.6,911.3c0,0-5.1-11.3-6.1-15.5s-2.1-14.1-2.1-14.1s-5.6,1.7-10.3,0.3s-6.5-2.7-6.5-2.7s-2.5,5.8-9.5,4
c0,0-1,7.9-2.4,11s-2.7,9.6-1.4,12.4c1.3,2.8,4.1,4.9,4.2,8s0.9,13.5,2.3,16.5s3.7,7.3,6.2,10.4s9.1,8.7,13.1,7.2
c2.5-0.9,2.4-3.7,2.4-3.7s4,3.2,6.4,2.5c3.1-0.9,2.7-3,2.7-3s3,2.7,5.1,1.3s1.7-3.4,1.7-3.4s4,0.5,5.1-0.7c1.8-1.9,0.6-4.2,0.6-4.2
s3.4-0.4,2.4-4c-1-3.5-8-12.8-9.9-15.5C300.7,915.5,298.6,911.3,298.6,911.3z"/>
</g>
<g id="Untere_Bauchmuskeln_WF">
<path class="st0" d="M200.6,383.3c-3.4,5.8,1.3,42.5,12.6,62.2c4,7,8.3,10.6,12.6,10.6s8.5-3.6,12.6-10.6
c11.3-19.7,16-56.5,12.6-62.2c-3.1-5.2-17.9-4.9-23.4-4.7c-0.7,0-1.3,0-1.7,0s-1,0-1.7,0C218.4,378.4,203.6,378.1,200.6,383.3z"/>
<path class="st0" d="M217.5,345.5c-0.8-0.2-2.1-0.3-3.5-0.3c-5.1,0-11.6,1.3-12.8,4.8c-1.9,5.5-0.9,14.5,2,17.5
c2.2,2.3,7.1,5.8,11.6,5.8l0,0c1.1,0,2.2-0.2,3.1-0.7c3.1-1.5,3.8-2.9,3.8-14.3C221.7,347.9,219.5,346,217.5,345.5z"/>
<path class="st0" d="M237.4,345.2c-1.4,0-2.8,0.1-3.5,0.3c-2,0.5-4.2,2.4-4.2,12.9c0,11.4,0.7,12.8,3.8,14.3c0.9,0.5,2,0.7,3.1,0.7
c4.5,0,9.4-3.5,11.6-5.8c2.8-3,3.9-12,2-17.5C249,346.5,242.6,345.2,237.4,345.2z"/>
</g>
<g id="Obere_Bauchmuskeln_WF">
<path class="st0" d="M247.1,310.9c1,0,1.8-0.2,2.3-0.5c2.6-1.6,2-6.2-1.8-15.9c-3.6-9-10.3-13.4-13.9-14.2c-0.2,0-0.4-0.1-0.6-0.1
c-2.3,0-3.3,3.9-3.3,12.7c0,0.6,0,1.2,0,1.7c0,4.5,0,7.1,1.9,9.4C235.1,308,242.7,310.9,247.1,310.9z"/>
<path class="st0" d="M202.1,310.4c0.5,0.3,1.3,0.5,2.3,0.5c4.4,0,11.9-2.9,15.4-6.8c2-2.2,2-4.9,1.9-9.4c0-0.5,0-1.1,0-1.7
c0-8.8-1-12.7-3.3-12.7c-0.2,0-0.4,0-0.6,0.1c-3.6,0.8-10.4,5.2-13.9,14.2C200,304.3,199.5,308.9,202.1,310.4z"/>
<path class="st0" d="M201.3,340.4c0.8,0.6,2.6,0.9,4.9,0.9l0,0c5.1,0,11.5-1.6,13.3-4.3c2.1-3.2,2.3-4.3,2.3-12.6
c0-8.8-1.3-10.5-3.9-10.5c-0.2,0-0.5,0-0.8,0.1c-4,0.5-10.6,1.3-13.6,8.6C200,331.1,199,338.8,201.3,340.4z"/>
<path class="st0" d="M234.4,314.1c-0.3,0-0.6-0.1-0.8-0.1c-2.6,0-3.9,1.6-3.9,10.5c0,8.3,0.2,9.3,2.3,12.6
c1.8,2.7,8.2,4.3,13.3,4.3c2.3,0,4.1-0.3,4.9-0.9c2.2-1.7,1.3-9.3-2.2-17.8C245,315.3,238.4,314.5,234.4,314.1z"/>
</g>
<g id="Seitlicher_Bauch_WF">
<path class="st0" d="M190.6,372.2c1.9-0.8,2.9-3.2,2.9-7.1c0-8.4-5.1-9.9-11.5-11.8c-1.9-0.6-3.9-1.1-6-2
c-5.4-2.2-9.4-5.5-11.6-7.6c0.7,4.3,1.1,8,1,10.7c-0.1,1.2-0.2,2.4-0.4,3.7C170.5,363.2,184.7,374.7,190.6,372.2z"/>
<path class="st0" d="M176.6,349.9c1.9,0.8,3.9,1.4,5.8,1.9c3.2,0.9,6.3,1.8,8.6,3.6c0.7-0.6,2.5-2.9,2.5-9.7
c0-6.5-7.2-13.2-20.9-19.3c-2-0.9-3.6-1.6-5.1-2.1l0,0c-4.6-1.7-6.6-1.8-7.5-1.6c0.2,0.7,0.3,1.4,0.5,2c1.3,5.9,2.6,11.4,3.5,16.3
C164.8,342,169.6,347.1,176.6,349.9z"/>
<path class="st0" d="M166.8,322.5c-0.3-1.8-0.4-3.4-0.3-4.9c-2.6-1.1-4.7-3.2-6.3-6c-0.6,1.2-1.5,3.6-1,7.3
c0.2,0.8,0.3,1.5,0.5,2.3C160.7,321.1,162.7,321.1,166.8,322.5z"/>
<path class="st0" d="M291.2,311.7c-1.6,2.8-3.7,4.9-6.3,6c0.1,1.5,0.1,3.1-0.3,4.9c4.1-1.4,6.1-1.5,7.1-1.2
c0.2-0.8,0.3-1.5,0.5-2.3C292.7,315.3,291.9,312.9,291.2,311.7z"/>
<path class="st0" d="M269.4,353.3c-6.4,1.9-11.5,3.4-11.5,11.8c0,3.9,1,6.3,2.9,7.1c5.9,2.6,20.2-9,25.5-14
c-0.2-1.3-0.4-2.5-0.4-3.7c-0.1-2.7,0.3-6.4,1-10.7c-2.2,2.1-6.2,5.4-11.6,7.6C273.4,352.1,271.4,352.7,269.4,353.3z"/>
<path class="st0" d="M260.2,373.6c-0.6-0.3-1.1-0.6-1.6-1.1v0.1c-0.4,5.5-1.4,18.3-0.9,30.2c0.6,14.4,3.1,22.7,7.3,24
c5.2,1.6,10.3-2.6,16.1-7.6c6.1-5.2,13-11,21.9-11.8c-0.6-1.7-1.2-3.5-1.8-5.2c-3.1-9.3-5.9-16.9-8.1-23
c-3.1-8.5-5.3-14.5-6.4-19.5c-3.6,3.3-16.2,14.2-24,14.2C261.8,374.1,261,373.9,260.2,373.6z"/>
<path class="st0" d="M283.9,324.4L283.9,324.4c-1.5,0.5-3.1,1.2-5.1,2.1c-13.7,6.1-20.9,12.8-20.9,19.3c0,6.8,1.8,9,2.5,9.7
c2.3-1.8,5.4-2.7,8.6-3.6c1.9-0.6,3.9-1.1,5.8-1.9c7-2.8,11.8-7.9,12.7-8.8c0.9-4.8,2.2-10.4,3.5-16.3c0.2-0.7,0.3-1.4,0.5-2.1
C290.5,322.6,288.5,322.7,283.9,324.4z"/>
<path class="st0" d="M186.4,426.9c4.2-1.3,6.8-9.6,7.3-24c0.5-11.9-0.5-24.7-0.9-30.2v-0.1c-0.5,0.4-1,0.8-1.6,1.1
c-0.8,0.3-1.6,0.5-2.5,0.5c-7.8,0-20.3-10.9-24-14.2c-1.1,5-3.3,11-6.4,19.5c-2.2,6.1-5,13.7-8.1,23c-0.6,1.7-1.2,3.4-1.8,5.2
c8.9,0.7,15.8,6.6,21.9,11.8C176.1,424.2,181.1,428.5,186.4,426.9z"/>
<path class="st0" d="M279.9,310.7c1.5,0.5,2.8,1.4,3.7,2.8c0.5,0.8,0.9,1.7,1.2,2.7c3-1.4,5.5-4.6,6.7-8.7c0.9-3.1,1-6.3,0.1-8.2
c-2.6,2.4-6.2,4.1-10.1,4.7C281.8,305.8,281.7,308.7,279.9,310.7z"/>
<path class="st0" d="M265.5,296.1L265.5,296.1c0.3,0.5,0.6,0.9,1,1.4c3.2,3.8,8.4,5.7,13.9,5c4.2-0.5,8-2.3,10.7-5l0.1-0.1
c1.1-1.1,1.9-2.3,2.5-3.6c1.1-2.4,1-4.9-0.2-7c-3,3.6-7.3,6.1-12.2,7.1c-1.7,0.3-3.3,0.5-4.7,0.5c-5.8,0-10-2.4-12.9-5.5
C263.4,291.4,264,293.9,265.5,296.1z"/>
<path class="st0" d="M283,323.1c0.9-3.7,0.6-6.8-0.7-8.8c-0.9-1.4-2.3-2.2-4.2-2.5c-3.4-0.5-12.8,1.4-17.5,5.8
c-1.9,1.8-2.9,3.8-2.7,6c0.4,5.3,1.9,7.7,3.2,8.7c1.1,0.9,2.1,1.1,2.7,1.1c3.5-2.9,8.3-5.6,14.4-8.3
C280,324.3,281.6,323.7,283,323.1z"/>
<path class="st0" d="M279.9,304.1c-0.5,0-1,0.1-1.6,0.1c-5.2,0-9.9-2-13-5.7c-0.3-0.4-0.6-0.7-0.9-1.1c-1.4,0.1-5.7,1.2-6.5,8.6
c-0.7,5.8,1.3,9,2.2,10.2c5.1-4.3,14.2-6.2,17.9-5.8C280.5,308.6,280.2,305.4,279.9,304.1z"/>
<path class="st0" d="M264,287.1c2.6,3.2,6.6,5.9,12.5,5.9c1.4,0,2.9-0.1,4.5-0.5c6.7-1.3,12.1-5.8,14.4-12
c1.5-3.9,1.5-7.9,0.1-10.7c-3.7,7.3-8.5,11.9-13.6,13.1c-2,0.5-4.2,0.7-6.5,0.7c-4.8,0-9.9-1.1-14.7-2.8c0.5,1.9,1.5,4,2.9,5.9
C263.8,286.8,264,286.9,264,287.1z"/>
<path class="st0" d="M174.9,294.4c-1.5,0-3.1-0.2-4.7-0.5c-4.9-1-9.1-3.5-12.2-7.1c-1.2,2.1-1.2,4.6-0.2,7c0.6,1.3,1.4,2.5,2.5,3.6
l0.1,0.1c2.6,2.6,6.5,4.5,10.7,5l0,0l0,0c5.5,0.6,10.7-1.3,13.9-5c0.4-0.4,0.7-0.9,1-1.3l0,0c1.4-2.2,2-4.7,1.7-7.1
C184.9,292.1,180.7,294.4,174.9,294.4z"/>
<path class="st0" d="M173.1,304.2c-0.5,0-1,0-1.6-0.1c-0.3,1.3-0.6,4.5,1.9,6.2c3.8-0.4,12.8,1.5,17.9,5.8
c0.9-1.2,2.8-4.4,2.2-10.2c-0.9-7.4-5.1-8.4-6.5-8.6c-0.3,0.4-0.6,0.8-0.9,1.1C183.1,302.1,178.3,304.2,173.1,304.2z"/>
<path class="st0" d="M156,280.5c2.4,6.2,7.8,10.7,14.4,12c8.4,1.7,13.8-1.4,17-5.4c0.1-0.2,0.2-0.3,0.3-0.4c1.4-1.9,2.4-4,2.9-5.9
c-4.8,1.8-9.8,2.8-14.6,2.8l0,0c-2.3,0-4.5-0.2-6.5-0.7c-5.2-1.2-10-5.8-13.6-13.1C154.5,272.6,154.5,276.6,156,280.5z"/>
<path class="st0" d="M187.6,333.5c0.6,0,1.6-0.1,2.7-1.1c1.3-1.1,2.8-3.5,3.2-8.7c0.1-2.2-0.8-4.2-2.7-6
c-4.7-4.4-14.1-6.3-17.5-5.8c-1.8,0.3-3.2,1.1-4.2,2.5c-1.3,2-1.6,5.1-0.7,8.8c1.4,0.5,3,1.2,4.8,2
C179.3,327.8,184.1,330.6,187.6,333.5z"/>
<path class="st0" d="M170,303.9c-3.9-0.6-7.4-2.3-10.1-4.7c-0.8,1.9-0.8,5.1,0.1,8.2c1.2,4.1,3.7,7.3,6.7,8.7
c0.2-1,0.6-1.9,1.2-2.7c0.9-1.3,2.1-2.2,3.7-2.8C169.7,308.7,169.7,305.8,170,303.9z"/>
</g>
<g id="Bizeps_WF">
<path class="st0" d="M334.6,343.1c2-6.9,1.9-17,1.9-18.4c-1.5-2.9-3.2-6.1-4.9-9.4c-4.6-8.8-9.9-18.8-13.4-26.5
c-5.7-12.6-13.1-32.3-16.4-43.7c0,0.2,0,0.3,0,0.5c-0.3,4.5-1,9.1-2.2,13.4c1.2,3.6,5.4,12.4,5.4,12.5l0,0l0,0
c0.1,0.1,6,14.9,10.9,30.7c2.2,7.3,6.6,18.1,10.8,28.6c2.3,5.8,4.6,11.4,6.3,16.1c0.6-1,1.1-2.3,1.5-3.6
C334.5,343.1,334.6,343.1,334.6,343.1z"/>
<path class="st0" d="M348.3,358c0.1-0.3,0.3-0.6,0.7-0.6c0.3,0,0.7,0.2,0.8,0.5c1.3,3.7,6.8,7.2,9.2,8.6c0.2-9.2,2.6-16.5,3.1-18
c-0.4-1.4-0.5-4.6-0.6-9.5c-0.2-7.2-0.5-18-2.1-30.4c-1.6-11.8-5.4-25.8-8.2-36c-0.5-2-1-3.7-1.4-5.3c-0.5-2-1-3.8-1.3-5.6
c-7.7-1.8-14.1-7.3-20.9-13.2c-7.8-6.7-15.8-13.7-26.6-15.6c0.4,1.8,0.7,3.7,0.8,5.8c1.6,8.3,10.9,34.1,17.8,49.4
c3.5,7.7,8.7,17.7,13.4,26.5c5.2,9.9,9.7,18.5,11.1,22.4c1.8,5.3,2.6,15.7-1,24.5l0,0c-1.4,3.5-3.6,6.7-6.6,9.3
c-0.1,2.7-0.1,5.8,0,9.2C345.4,374.9,348.3,358.2,348.3,358z"/>
<path class="st0" d="M151.8,258.9c-1.2-4.3-1.9-8.9-2.2-13.4c0-0.2,0-0.3,0-0.5c-3.3,11.3-10.7,31.1-16.4,43.7
c-3.5,7.8-8.8,17.7-13.4,26.5c-1.7,3.3-3.4,6.4-4.9,9.4c0,1.4-0.1,11.5,1.9,18.4v0.1c0.4,1.4,0.9,2.6,1.5,3.6
c1.8-4.7,4-10.3,6.3-16.1c4.2-10.5,8.5-21.3,10.8-28.6c4.8-15.8,10.8-30.5,10.9-30.7l0,0l0,0C146.4,271.3,150.6,262.6,151.8,258.9z
"/>
<path class="st0" d="M108.5,361.4L108.5,361.4c-3.6-8.8-2.9-19.2-1-24.5c1.3-3.9,5.8-12.5,11.1-22.4c4.6-8.8,9.8-18.7,13.4-26.5
c7-15.3,16.3-41.1,17.8-49.4c0.1-2.1,0.4-4,0.8-5.8c-10.7,1.9-18.8,8.9-26.6,15.6c-6.8,5.8-13.2,11.4-20.9,13.2
c-0.4,1.8-0.8,3.7-1.3,5.6c-0.4,1.6-0.9,3.4-1.4,5.3c-2.8,10.2-6.6,24.2-8.2,36c-1.7,12.4-2,23.2-2.1,30.4
c-0.1,4.9-0.2,8.1-0.6,9.5c0.5,1.5,2.9,8.8,3.1,18c2.4-1.4,7.9-4.9,9.2-8.6c0.1-0.3,0.4-0.5,0.8-0.5c0.3,0,0.6,0.3,0.7,0.6
c0,0.2,2.9,16.9,11.9,21.9c0.2-3.4,0.1-6.5,0-9.2C112,368.2,109.9,364.9,108.5,361.4z"/>
</g>
<g id="Unterarm_WF">
<path class="st0" d="M366.8,355.1c-1.5-1.8-2.8-3.3-3.7-4.7c-0.9,3-2.6,9.6-2.6,17.3l0,0l0,0c0,2.9,0.7,6.7,2.1,11.4
c0,0.1,0,0.1,0,0.2c2.1,7.2,5.6,16.5,9.9,27.6c8.1,21.1,18.1,47.4,25.4,78.2c0.8-0.4,1.6-0.7,2.2-1.1c-7-22.4-9-43.2-10.6-60
c-0.5-5.4-1-10.5-1.6-15.2c-1.1-6.1-2.4-12.2-3.8-18.1c0,0,0,0,0-0.1c-0.7-3-1.5-6-2.3-8.9C377.9,368,371.2,360.3,366.8,355.1z"/>
<path class="st0" d="M383,488.6c0.8-0.1,1.6-0.1,2.4-0.2c-3.1-9.2-6.2-20.6-8.9-30.7c-3-11.3-5.9-22-8.2-27.2
c-2.7-5.8-5.3-10.8-7.6-15.5c-6.4-12.6-12-23.5-16.4-41.1c-1.9,3.2-4.4,6.1-7.7,7.7c0.3,3.8,0.8,8,1.8,12.7
c1.9,8.7,6.9,20.2,12.8,32.4l0,0c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.2,0.4,0.3,0.6c1.7,3.4,4.3,7.3,7.2,11.9
C366.2,450.8,376.2,466.3,383,488.6z"/>
<path class="st0" d="M377.8,425.4c-2.3-6.3-4.6-12.3-6.8-17.9c-4.2-11.1-7.8-20.3-9.9-27.6c-1.1-1-7.4-6.4-13.8-12.6
c-0.6,1.5-1.2,3.1-2,4.6c4.5,18.3,10.1,29.5,16.7,42.4c2.3,4.4,4.7,9.2,7.2,14.6l0,0c0.1,0.3,0.3,0.6,0.4,0.9
c6.2,13.4,13.3,30.9,20.9,57.7c1-0.2,1.9-0.5,2.7-0.7c-8.5-23.2-10.3-34.4-11.8-44.2C380.6,437.1,379.8,432,377.8,425.4z"/>
<path class="st0" d="M80.4,407.5c-2.1,5.6-4.4,11.6-6.8,17.9l0,0c-1.9,6.6-2.8,11.7-3.6,17.2c-1.6,9.9-3.4,21-11.8,44.2
c0.9,0.3,1.8,0.5,2.7,0.7c7.6-26.9,14.7-44.4,20.9-57.7c0.1-0.3,0.3-0.6,0.4-0.9l0,0c2.6-5.4,5-10.2,7.2-14.6
c6.6-12.9,12.2-24,16.7-42.4c-0.8-1.6-1.5-3.1-2-4.6c-6.5,6.3-12.7,11.7-13.8,12.6C88.2,387.2,84.6,396.4,80.4,407.5z"/>
<path class="st0" d="M51.3,484c0.7,0.4,1.4,0.7,2.2,1.1c7.3-30.8,17.4-57,25.4-78.2c4.3-11.1,7.8-20.4,9.9-27.6c0-0.1,0-0.1,0-0.2
c1.3-4.6,2.1-8.5,2.1-11.4l0,0l0,0c0-7.7-1.7-14.4-2.6-17.3c-0.9,1.4-2.2,2.9-3.7,4.7c-4.4,5.2-11.1,12.9-15.1,26.7
c-2.5,8.6-4.4,17.9-6.1,27.1c-0.6,4.7-1.1,9.8-1.6,15.2C60.3,440.8,58.3,461.7,51.3,484z"/>
<path class="st0" d="M107.2,373.9c-4.4,17.5-10,28.5-16.4,41.1c-2.4,4.7-4.9,9.7-7.6,15.5c-2.3,5.3-5.2,16-8.2,27.2
c-2.7,10.1-5.8,21.5-8.9,30.7c0.8,0.1,1.6,0.2,2.4,0.2c6.8-22.4,16.9-37.8,24.2-49.1c2.9-4.5,5.5-8.5,7.2-11.9
c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.1-0.2,0.2-0.3l0,0c6-12.2,11-23.7,12.8-32.4c1-4.7,1.5-8.9,1.8-12.7
C111.7,380,109.2,377.1,107.2,373.9z"/>
</g>
<g id="Aeusseres_Gesaess_WF">
<path class="st0" d="M146.3,413.6c-8.1,23.3-16.8,49.9-16.4,86.5c5.8-13.6,27.5-65.5,31-78.9C155.3,416.8,150.1,413.7,146.3,413.6z
"/>
<path class="st0" d="M321.6,500.1c0.4-36.6-8.4-63.2-16.4-86.5c-3.8,0.1-9,3.2-14.6,7.6C294.1,434.6,315.9,486.5,321.6,500.1z"/>
</g>
<g id="Beinstrecker_WF">
<path class="st0" d="M167.3,426.8c-1.7-1.6-3.4-3.1-5.1-4.5c-1.3,4.8-4.5,13.3-8.3,23.1c3.2,18.1,14.8,51.4,27,86.7
c9.4,27,19,54.8,25.1,76.4c-0.2-4.5-0.2-9.4,0.2-15.2c0.3-4.6,1.1-9.1,2.2-13.9c-0.8-10.4-7.8-26.3-15.8-44.7
C177.8,501.6,159.7,460.2,167.3,426.8z"/>
<path class="st0" d="M263.4,557.8c4.6,10.8,8.8,32.5,9.9,51.8c0,0.2,0,0.3,0,0.5c1.1,3.7,4.7,13,9,13s11.6-10.5,19.3-40
c7-26.7,12.1-60.7,13.9-93.3c-4.7-11.3-11.4-27.5-16.9-41.5c-3.8,18.7-14.9,50.7-26.6,84.6C269.1,541,266.2,549.5,263.4,557.8z"/>
<path class="st0" d="M179.7,609.6c-1.3,23,1.7,38.2,8.3,41.6c1.6,0.8,3.3,0.9,5.1,0.3c7.3-2.6,13.6-16.1,14.4-17.7
c-0.1-4.8-0.4-9.2-0.8-13.7c-0.1-1.3-0.2-2.7-0.3-4c-4-15.6-10.5-35.4-17.5-56.1C184.5,571,180.7,591.4,179.7,609.6z"/>
<path class="st0" d="M297.6,445.4c-3.8-9.8-7-18.4-8.3-23.1c-1.7,1.4-3.4,2.9-5.1,4.5c7.6,33.4-10.5,74.8-25.1,108.1
c-8,18.4-15,34.2-15.8,44.7c1.1,4.7,1.9,9.3,2.2,13.9c0.4,5.7,0.4,10.7,0.2,15.2c6.1-21.6,15.7-49.4,25.1-76.4
C282.8,496.9,294.4,463.5,297.6,445.4z"/>
<path class="st0" d="M258.3,651.5c1.8,0.6,3.5,0.5,5.1-0.3c6.6-3.4,9.6-18.6,8.3-41.6c-1.1-18.2-4.8-38.6-9.1-49.7
c-7.1,20.7-13.5,40.5-17.5,56.1c-0.1,1.4-0.2,2.7-0.3,4c-0.4,4.5-0.7,8.9-0.8,13.7C244.7,635.4,251,648.9,258.3,651.5z"/>
<path class="st0" d="M149.8,583c7.7,29.5,15.1,40,19.3,40c4.3,0,7.9-9.3,9-13c0-0.2,0-0.3,0-0.5c1.1-19.3,5.3-41,9.9-51.8
c-2.8-8.3-5.8-16.8-8.7-25.1c-11.7-33.8-22.8-65.9-26.6-84.6c-5.5,14.1-12.2,30.2-16.9,41.5C137.7,522.3,142.8,556.3,149.8,583z"/>
<path class="st0" d="M158.5,647.7c1,0.3,1.9,0.1,2.8-0.4c4.6-3,3.3-19.6,2.4-26c-6.8-7.9-12.7-28.1-15.3-37.9
c-6.8-25.9-11.8-58.7-13.8-90.5c-2.5,5.9-4.2,10-4.6,11c0.9,29.5,8.6,65.1,13.8,89c2.4,10.9,4.2,19.5,4.8,24.6
c1,8.4,1.4,14.7,1.6,20.1C150.6,638.6,154.4,646.5,158.5,647.7z"/>
<path class="st0" d="M303.1,583.4c-2.6,9.8-8.5,30-15.3,37.9c-0.9,6.4-2.2,23,2.4,26c0.9,0.6,1.8,0.7,2.8,0.4
c4.1-1.2,7.9-9.1,8.4-10.2c0.1-5.3,0.6-11.7,1.6-20.1c0.6-5,2.5-13.7,4.8-24.6c5.2-23.9,12.9-59.5,13.8-89c-0.4-1-2.2-5.1-4.6-11
C314.9,524.6,309.9,557.5,303.1,583.4z"/>
</g>
<g id="Innerer_Oberschenkelmuskel_WF">
<path class="st0" d="M206,471.4c1.2,5.3,3.4,16.4,4.7,29.8c0.2,1.7,0.3,3.5,0.5,5.4c0,0,0,0,0,0.1c1.2,16.6,0.8,38.1-5.9,55.4
c1.8,5,3.1,9.5,3.9,13.4c0.7-3,1.6-6,2.4-9.2c3.2-11.6,6.8-24.8,8.2-41.3c2.1-23.8,0-38.5-0.4-40.9
C214.7,481.2,210.3,476.6,206,471.4z"/>
<path class="st0" d="M240.2,506.6L240.2,506.6c0.1-1.9,0.3-3.7,0.5-5.4c1.3-13.4,3.5-24.5,4.7-29.8c-4.3,5.2-8.7,9.8-13.4,12.7
c-0.4,2.3-2.5,17-0.4,40.9c1.5,16.6,5.1,29.7,8.2,41.3c0.9,3.2,1.7,6.2,2.4,9.2c0.7-4,2.1-8.5,3.9-13.4
C239.4,544.8,239,523.3,240.2,506.6z"/>
<path class="st0" d="M241.8,506.7c1.3,24.6,9.7,33.7,12.2,36c1.2-2.7,2.4-5.5,3.7-8.4c6.1-13.8,12.7-29,17.9-44.5
c-13.1-9.4-17-28.5-17.9-34.5c-1.4,1.8-2.7,3.6-4,5.4c-2,2.7-4,5.3-6,7.9c-0.4,1.5-3.7,15.2-5.4,32.8
C242.1,503.1,241.9,504.9,241.8,506.7z"/>
<path class="st0" d="M175.9,489.8c5.2,15.4,11.8,30.6,17.9,44.5c1.3,2.9,2.5,5.7,3.7,8.4c2.5-2.2,10.9-11.4,12.2-35.9
c-0.1-1.9-0.3-3.7-0.5-5.4c-1.7-17.5-5-31.2-5.4-32.8c-2-2.6-4-5.3-6-7.9c-1.3-1.8-2.7-3.6-4-5.4
C192.9,461.3,189,480.4,175.9,489.8z"/>
<path class="st0" d="M280,474.8c1.1-4.5,2.1-8.9,2.8-13.3c-0.4-1.6-4.5-15.4-11.5-22c-2.2,2.3-4.2,4.4-6,6.3
c-0.5,0.5-1,1.1-1.5,1.6C264.5,449.8,267.9,465,280,474.8z"/>
<path class="st0" d="M283.4,458c1.4-10.4,1.5-20.6-0.5-30c-3.6,3.4-7.2,7-10.4,10.4C278.1,443.6,281.8,453.1,283.4,458z"/>
<path class="st0" d="M276.1,488.3c1.3-4,2.5-7.9,3.6-11.9c-11.6-9.1-15.8-22.9-16.9-27.6c-1.3,1.5-2.6,3.1-3.8,4.8
C259.3,456.4,262.5,478.1,276.1,488.3z"/>
<path class="st0" d="M241.3,517c-0.3,13.9,0.8,29.6,5.6,42.9c1.8-4.9,4-10.1,6.4-15.7C251.3,542.5,244.2,535.6,241.3,517z"/>
<path class="st0" d="M168.6,461.5c0.7,4.4,1.7,8.8,2.8,13.3c12.1-9.8,15.6-24.9,16-27.4c-0.5-0.5-1-1.1-1.5-1.6
c-1.8-1.9-3.8-4.1-6-6.3C173.1,446,169,459.9,168.6,461.5z"/>
<path class="st0" d="M171.8,476.4c1.1,4,2.3,7.9,3.6,11.9c13.6-10.2,16.8-31.9,17.1-34.7c-1.3-1.6-2.6-3.2-3.8-4.8
C187.6,453.4,183.5,467.3,171.8,476.4z"/>
<path class="st0" d="M168,458c1.6-4.9,5.3-14.4,10.9-19.7c-3.2-3.4-6.8-7-10.4-10.3C166.5,437.4,166.6,447.6,168,458z"/>
<path class="st0" d="M210.2,516.9c-2.9,18.7-10,25.6-12.1,27.2c2.4,5.6,4.6,10.8,6.4,15.7C209.3,546.5,210.5,530.8,210.2,516.9z"/>
</g>
<g id="Schulter_WF">
<path class="st0" d="M247,202.9c0.1,0,0.3,0,0.4,0c12.7,0,43.8,10.7,51.2,23.6c0.8,1.4,1.4,3,2,4.8c11.5,1.8,19.9,9.1,28,16.1
c6.4,5.5,12.5,10.8,19.6,12.7c-0.9-4.7-1.3-9.2-1.8-14.3c-0.2-1.8-0.4-3.7-0.6-5.7c-1.2-11-9.4-40.4-17.1-50.1
c-7.3-9.1-24.5-9.7-33.8-10c-1.6-0.1-3-0.1-4.1-0.2c-21.9,4.6-30.9,12.3-38.8,19.1C250.2,200.2,248.6,201.6,247,202.9z"/>
<path class="st0" d="M150.9,231.3c0.5-1.8,1.2-3.4,2-4.8c7.4-12.9,38.5-23.6,51.2-23.6c0.1,0,0.3,0,0.4,0c-1.6-1.3-3.2-2.6-4.9-4
c-7.9-6.8-16.9-14.5-38.8-19.1c-1.1,0.1-2.5,0.1-4.1,0.2c-9.3,0.3-26.5,0.9-33.8,10c-7.8,9.7-16,39.1-17.1,50.1
c-0.2,2-0.4,3.9-0.6,5.7c-0.5,5.2-0.9,9.6-1.8,14.3c7-1.9,13.1-7.1,19.6-12.7C131.1,240.4,139.4,233.2,150.9,231.3z"/>
</g>
<g id="Halsmuskel_WF">
<path class="st0" d="M201.4,124.8l9.7,8c0,0-0.6,7.2,3.4,16.3s8.6,17.2,11.3,19c0,0,5.6-5.6,9.9-15.9c4.2-10.3,5.1-20,5.1-20
l9.3-7.7c0,0-0.3,11.7-0.3,14s0.2,7.9,0.2,7.9s-5.7,7.4-8.7,11c-2.9,3.6-6.3,8.7-8,15.9s-1.8,13.6-1.8,13.6s-5.6,1.8-11.9,0.2
c0,0,0.7-10.4-3.6-20c-4.2-9.7-8.5-12-10.6-15.4s-4.3-6.2-4-10.3C201.6,137.4,201.4,124.8,201.4,124.8z"/>
</g>
<g id="Nacken_WF">
<path class="st0" d="M212.1,184.8c2.4,0.7,4.7,1.3,6.9,1.7c-0.8-16.1-5.7-23.8-10.2-29.6C208.5,161.6,208,173.9,212.1,184.8z"/>
<path class="st0" d="M242.6,157c-4.6,5.8-9.5,13.5-10.2,29.6c2.2-0.4,4.5-1,6.9-1.7C243.4,173.9,243,161.6,242.6,157z"/>
<path class="st0" d="M200.9,147.5c-2.1,5.7-6.7,13.7-15.3,20.3c-5.1,3.9-9.1,6.6-12.7,8.5c9.2,0.3,17.1,2,24.2,4
C200.7,167.9,201,154.5,200.9,147.5z"/>
<path class="st0" d="M210.3,184.3c-4.4-12.7-3.1-26.7-2.9-29c-0.1-0.2-0.3-0.3-0.4-0.5c-1.8-2.2-3.5-4.2-4.7-6.5
c0.1,7.2-0.3,20.3-3.8,32.4c2.6,0.7,5,1.5,7.4,2.3C207.5,183.4,208.9,183.9,210.3,184.3z"/>
<path class="st0" d="M254.3,180.3c7.1-2,15-3.7,24.2-4c-3.6-1.8-7.5-4.5-12.7-8.5c-8.6-6.6-13.2-14.6-15.3-20.3
C250.4,154.5,250.7,167.9,254.3,180.3z"/>
<path class="st0" d="M241.1,184.3c1.4-0.4,2.8-0.9,4.3-1.3c2.4-0.7,4.9-1.5,7.4-2.3c-3.5-12.1-3.9-25.2-3.8-32.4
c-1.2,2.3-2.9,4.3-4.7,6.5c-0.1,0.2-0.3,0.3-0.4,0.5C244.2,157.6,245.5,171.6,241.1,184.3z"/>
</g>
<g id="Brust_WF">
<path class="st0" d="M297.3,227.3c-3.2-5.5-11.3-11.2-22.9-16.1c-9.8-4.1-20.6-6.8-27-6.8c-1.1,0-2.1,0.1-2.9,0.3
c-10.1,2.4-12.2,16-12.2,27c0,13.5,0.3,21.7,6.8,31.6c5.8,8.9,22.1,18.8,36.3,18.8c2.2,0,4.2-0.2,6.2-0.7
c5.2-1.2,10.1-6.3,13.6-14.5c1.1-2.6,2.1-5.3,2.8-8.1v-0.1C301.3,246.9,301.1,234,297.3,227.3z"/>
<path class="st0" d="M156.2,266.9c3.6,8.2,8.4,13.3,13.6,14.5c1.9,0.4,4,0.7,6.2,0.7c14.2,0,30.5-9.9,36.3-18.8
c6.5-10,6.8-18.1,6.8-31.6c0-11-2.1-24.7-12.2-27c-0.8-0.2-1.7-0.3-2.9-0.3c-6.4,0-17.2,2.7-27,6.8c-11.6,4.8-19.7,10.5-22.9,16.1
c-3.9,6.8-4,19.6-0.8,31.4v0.1C154.1,261.6,155.1,264.4,156.2,266.9z"/>
</g>
<g id="Kontur">
<path class="st1" d="M225.7,139.2c-8.3,0-11.7-2.8-15.9-6.4c-0.9-0.8-1.9-1.6-3-2.4c-13.1-10.1-16.9-20.5-18.9-30.1
c-1.6-0.5-3.1-1.1-4.3-1.8c-3.4-2.1-5.9-7.9-7.2-16.7c-0.4-2.7,0.1-4.8,1.4-6.3c1.8-2.1,4.6-2.4,6.2-2.5c-3.2-30,4.7-41.7,12-48.6
c7.8-7.3,17.7-11,29.6-11c11.9,0,21.8,3.7,29.6,11c7.3,6.9,15.3,18.6,12,48.6c1.6,0,4.4,0.4,6.2,2.5c1.3,1.5,1.8,3.6,1.4,6.3
c-1.3,8.8-3.8,14.6-7.2,16.7c-1.2,0.7-2.6,1.3-4.3,1.8c-2,9.6-5.7,19.9-18.9,30.1c-1.1,0.8-2,1.6-3,2.4
C237.4,136.4,234,139.2,225.7,139.2z M184.3,74.5c-1.1,0-3.8,0.2-5.3,1.9c-1,1.2-1.4,2.9-1,5.1c1.2,8.2,3.5,13.8,6.5,15.6
c1.2,0.7,2.6,1.3,4.3,1.7c0.3,0.1,0.5,0.3,0.6,0.6c2.2,11,6.2,20.2,18.4,29.7c1.1,0.8,2.1,1.7,3,2.5c4.2,3.5,7.2,6.1,15,6.1
s10.8-2.5,15-6.1c0.9-0.8,1.9-1.6,3-2.5c12.2-9.5,16.2-18.7,18.4-29.7c0.1-0.3,0.3-0.5,0.6-0.6c1.7-0.4,3.2-1,4.3-1.7
c3-1.8,5.3-7.4,6.5-15.6c0.3-2.2,0-3.9-1-5.1c-1.5-1.7-4.2-1.9-5.3-1.9c-0.4,0-0.6,0-0.6,0c-0.2,0-0.5-0.1-0.6-0.2
c-0.2-0.2-0.2-0.4-0.2-0.6c3.4-29.9-4.3-41.5-11.5-48.1c-7.5-7-17.1-10.6-28.6-10.6s-21.1,3.6-28.6,10.6
c-7.1,6.7-14.9,18.2-11.5,48.1c0,0.2-0.1,0.5-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2C184.8,74.6,184.6,74.5,184.3,74.5z"/>
<path class="st1" d="M217.9,344c-0.9-0.2-2.3-0.3-3.9-0.3c-2,0-12.3,0.3-14.3,5.8c-1.9,5.5-1.3,15.2,2.3,19.1c2.8,3,8,6.3,12.7,6.3
l0,0c1.4,0,2.7-0.3,3.8-0.8c4.2-2,4.7-5,4.7-15.7C223.3,349.2,221.7,344.9,217.9,344z M217.9,372.7c-0.9,0.5-2,0.7-3.1,0.7l0,0
c-4.5,0-9.4-3.5-11.6-5.8c-2.8-3-3.9-12-2-17.5c1.2-3.5,7.7-4.8,12.8-4.8c1.4,0,2.8,0.1,3.5,0.3c2,0.5,4.2,2.4,4.2,12.9
C221.7,369.8,221.1,371.2,217.9,372.7z"/>
<path class="st1" d="M200.4,341.7c1.4,1.1,4.2,1.2,5.8,1.2l0,0c4.5,0,12.1-1.3,14.5-5s2.6-5.1,2.6-13.4c0-7.8-0.9-12-5.4-12
c-0.3,0-0.6,0-1,0.1c-4,0.5-11.5,1.4-14.9,9.5C199.6,328,196.5,338.8,200.4,341.7z M203.4,322.6c3-7.3,9.6-8.1,13.6-8.6
c0.3,0,0.6-0.1,0.8-0.1c2.6,0,3.9,1.6,3.9,10.5c0,8.3-0.2,9.3-2.3,12.6c-1.8,2.7-8.2,4.3-13.3,4.3l0,0c-2.3,0-4.1-0.3-4.9-0.9
C199,338.8,200,331.1,203.4,322.6z"/>
<path class="st1" d="M201.3,311.7c0.8,0.5,1.8,0.7,3.1,0.7l0,0c4.7,0,12.7-3,16.6-7.3c2.4-2.7,2.4-5.7,2.3-10.4c0-0.5,0-1.1,0-1.7
c0-8.6-0.8-14.2-4.9-14.2c-0.3,0-0.6,0-0.9,0.1c-4,0.9-11.2,5.6-15,15.1C198.8,303.2,197.4,309.3,201.3,311.7z M203.9,294.6
c3.6-9,10.3-13.4,13.9-14.2c0.2,0,0.4-0.1,0.6-0.1c2.3,0,3.3,3.9,3.3,12.7c0,0.6,0,1.2,0,1.7c0,4.5,0,7.1-1.9,9.4
c-3.5,3.9-11,6.8-15.4,6.8c-1,0-1.8-0.2-2.3-0.5C199.5,308.9,200,304.3,203.9,294.6z"/>
<path class="st1" d="M227.4,377c-0.7,0-1.3,0-1.7,0s-1,0-1.7,0c-6.4-0.2-21.3-0.5-24.8,5.5c-2,3.3-1.7,14.1,0.7,27.3
c2.6,14.5,6.9,27.8,11.9,36.4c4.3,7.6,9,11.4,13.9,11.4s9.6-3.8,13.9-11.4c4.9-8.6,9.3-21.9,11.9-36.4c2.4-13.3,2.7-24,0.7-27.3
C248.7,376.5,233.8,376.9,227.4,377z M238.3,445.6c-4,7-8.3,10.6-12.6,10.6s-8.5-3.6-12.6-10.6c-11.3-19.7-16-56.5-12.6-62.2
c3.1-5.2,17.9-4.9,23.4-4.7c0.7,0,1.3,0,1.7,0s1,0,1.7,0c5.6-0.1,20.4-0.5,23.4,4.7C254.3,389.1,249.6,425.8,238.3,445.6z"/>
<path class="st1" d="M237.4,343.7c-1.6,0-3,0.1-3.9,0.3c-3.8,0.9-5.4,5.2-5.4,14.3c0,10.7,0.5,13.6,4.7,15.7
c1.1,0.6,2.4,0.8,3.8,0.8c4.7,0,9.9-3.3,12.7-6.3c3.6-3.8,4.2-13.6,2.3-19.1C249.8,344,239.5,343.7,237.4,343.7z M248.3,367.5
c-2.2,2.3-7.1,5.8-11.6,5.8c-1.1,0-2.2-0.2-3.1-0.7c-3.1-1.5-3.8-2.9-3.8-14.3c0-10.5,2.2-12.4,4.2-12.9c0.8-0.2,2.1-0.3,3.5-0.3
c5.1,0,11.6,1.3,12.8,4.8C252.1,355.5,251.1,364.5,248.3,367.5z"/>
<path class="st1" d="M249.4,322.1c-3.3-8.1-10.8-9-14.9-9.5c-0.3,0-0.7-0.1-1-0.1c-4.5,0-5.4,4.2-5.4,12c0,8.3,0.2,9.7,2.6,13.4
c2.4,3.7,10,5,14.5,5c1.6,0,4.4-0.2,5.8-1.2C254.9,338.8,251.9,328,249.4,322.1z M250.2,340.4c-0.8,0.6-2.6,0.9-4.9,0.9
c-5.1,0-11.5-1.6-13.3-4.3c-2.1-3.2-2.3-4.3-2.3-12.6c0-8.8,1.3-10.5,3.9-10.5c0.2,0,0.5,0,0.8,0.1c4,0.5,10.6,1.3,13.6,8.6
C251.5,331.1,252.4,338.8,250.2,340.4z"/>
<path class="st1" d="M247.1,312.4c1.3,0,2.4-0.2,3.1-0.7c3.9-2.4,2.4-8.6-1.2-17.7c-3.8-9.5-11-14.3-15-15.1
c-0.3-0.1-0.6-0.1-0.9-0.1c-4,0-4.9,5.7-4.9,14.2c0,0.6,0,1.2,0,1.7c0,4.7,0,7.7,2.3,10.4C234.4,309.4,242.3,312.4,247.1,312.4z
M229.7,294.7c0-0.5,0-1.1,0-1.7c0-8.8,1-12.7,3.3-12.7c0.2,0,0.4,0,0.6,0.1c3.6,0.8,10.4,5.2,13.9,14.2c3.8,9.7,4.4,14.3,1.8,15.9
c-0.5,0.3-1.3,0.5-2.3,0.5c-4.4,0-11.9-2.9-15.4-6.8C229.7,301.9,229.7,299.2,229.7,294.7z"/>
<path class="st1" d="M225.2,169.1C225.2,169.2,225.2,169.2,225.2,169.1C225.2,169.2,225.2,169.2,225.2,169.1
C225.2,169.2,225.2,169.2,225.2,169.1c0.1,0.1,0.1,0.1,0.1,0.1h0.1c0,0,0,0,0.1,0h0.1l0,0h0.1h0.1l0,0h0.1c0,0,0,0,0.1,0h0.1
c0,0,0,0,0.1,0c0,0,0,0,0.1,0l0,0l0,0l0,0c5.7-5.5,14.5-22.5,15.3-36.6c0-0.4-0.3-0.8-0.7-0.8s-0.8,0.3-0.8,0.7
c-0.8,13.1-8.9,29.3-14.3,35.1c-5.4-5.8-13.6-22-14.3-35.1c0-0.4-0.4-0.7-0.8-0.7c-0.4,0-0.7,0.4-0.7,0.8
C210.7,146.6,219.5,163.6,225.2,169.1L225.2,169.1z"/>
<rect x="225" y="138.4" class="st1" width="1.5" height="49.5"/>
<path class="st1" d="M190.9,896.7c-0.8-3.2-2.4-8.7-2.9-11.8c-0.1-0.4-0.1-0.8-0.1-1.2c0-1.2,0.7-2.3,1.3-3.3
c0.8-1.3,2.1-2.7,2.3-4.3c0-2.1-0.7-4.1-1.3-6.1l-1.4,0.5c0.5,1.6,1.1,3.2,1.2,4.9c0,0.2,0,0.5,0,0.7c-0.4,1.3-1.3,2.3-2,3.5
s-1.4,2.5-1.5,4c0.1,2.3,0.8,4.4,1.3,6.6c0.6,2.4,2.2,7.8,2.6,10.1c0.8,3.6,0.5,6.9-1.9,9.8c-0.4,0.6-0.9,1.3-1.2,2
c-1.6,3.6-1.8,7.6-2.2,11.4c-0.2,2-0.4,4-0.8,6c-0.6,2.5-1.8,4.7-3.2,6.9c-1.9,2.9-4.1,5.6-6.5,8c-2.3,2.3-5.6,3.9-8.8,3.7
c-1.8-0.2-2.3-1.4-2.4-3c-0.3-2.9,1.6-5.2,3.1-7.5c1.3-2,2.5-3.9,3.8-5.9l-0.5-0.3c-1.2,1.6-3,4-4.2,5.6c-1.8,2.3-4,4.9-3.6,8
c-1.5,1.3-3.6,2.2-5.6,1.7c-1.7-0.4-2.1-2.3-1.6-3.8c0.2-0.7,0.6-1.3,1-1.9c1-1.7,3.5-6.1,4.6-7.9l-0.4-0.2c-0.9,1.2-2.6,3.8-3.4,5
c-0.9,1.5-2.1,3.1-2.9,4.6c-0.2,0.4-0.3,0.9-0.3,1.4l0,0c-0.2,0.3-0.5,0.6-0.8,0.8c-1,0.7-2.3,0.8-3.4,0.5
c-1.7-0.4-1.7-2.4-1.2-3.8c0.6-1.7,1.8-3.1,2.7-4.6c1.1-1.5,2.1-3,3.2-4.5l-0.5-0.4c-1.2,1.4-2.4,2.9-3.5,4.4s-2.4,3-3.1,4.8
c-0.1,0.2-0.2,0.4-0.2,0.7l0,0c-1.4,0.3-3.2,0.2-4.2-0.6c-0.3-0.3-0.6-0.6-0.7-0.9l-0.1-0.1v-0.1c0-0.1-0.1-0.3-0.1-0.4
c0.2-3,4.4-7.7,6.4-10.1l-0.3-0.3c-0.7,0.5-1.3,1.1-1.9,1.7c-1.3,1.3-3.7,3.7-5,6.1c-0.4-0.2-0.8-0.6-1.1-1.2
c-0.7-1.5,0.5-3.5,1.8-5.6c0.1-0.2,0.3-0.5,0.4-0.7c0.5-0.9,1.8-2.8,3.3-4.9c2.6-3.8,5.9-8.5,7.2-11.1c1.9-3.7,7.3-17.1,8.2-23.3
c0.9-5.9,1.8-13.7,1.8-13.8l-1.5-0.2c0,0.1-0.9,7.8-1.8,13.8c-0.9,6.1-6.2,19.2-8.1,22.9c-1.3,2.5-4.5,7.2-7.1,10.9
c-1.5,2.2-2.8,4-3.4,5c-0.1,0.2-0.3,0.4-0.4,0.7c-1.4,2.4-2.9,4.8-1.9,7.1c0.5,1,1.3,1.6,1.9,2c-0.2,0.9-0.3,1.7,0.1,2.4
c1,2.4,3.8,2.9,6.1,2.4c0,1.4,0.7,2.8,2.3,3.3s3.5,0.2,4.8-0.8c0.3,0.9,1,1.7,1.9,2.1c2.3,0.9,4.8,0.2,6.7-1.1
c0.2,0.8,0.6,1.6,1.4,2.2c1.7,1.2,4,0.9,5.8,0.3c3.5-1.2,6.2-3.8,8.6-6.5c1.6-1.8,3-3.7,4.3-5.7c1.4-2.2,2.8-4.7,3.4-7.3
c0.4-2,0.6-4.1,0.8-6.2c0.4-3.7,0.6-7.5,2.1-10.9c0.4-0.7,0.9-1.5,1.4-2.1c0.7-1,1.3-2.1,1.7-3.3
C192.8,903.9,191.8,900.2,190.9,896.7z"/>
<path class="st1" d="M260.5,896.7c0.8-3.2,2.4-8.7,2.9-11.8c0.1-0.4,0.1-0.8,0.1-1.2c0-1.2-0.7-2.3-1.3-3.3
c-0.8-1.3-2.1-2.7-2.3-4.3c0-2.1,0.7-4.1,1.3-6.1l1.4,0.5c-0.5,1.6-1.1,3.2-1.2,4.9c0,0.2,0,0.5,0,0.7c0.4,1.3,1.3,2.3,2,3.5
s1.4,2.5,1.5,4c-0.1,2.3-0.8,4.4-1.3,6.6c-0.6,2.4-2.2,7.8-2.6,10.1c-0.8,3.6-0.5,6.9,1.9,9.8c0.4,0.6,0.9,1.3,1.2,2
c1.6,3.6,1.8,7.6,2.2,11.4c0.2,2,0.4,4,0.8,6c0.6,2.5,1.8,4.7,3.2,6.9c1.9,2.9,4.1,5.6,6.5,8c2.3,2.3,5.6,3.9,8.8,3.7
c1.8-0.2,2.3-1.4,2.4-3c0.3-2.9-1.6-5.2-3.1-7.5c-1.3-2-2.5-3.9-3.8-5.9l0.5-0.3c1.2,1.6,3,4,4.2,5.6c1.8,2.3,4,4.9,3.6,8
c1.5,1.3,3.6,2.2,5.6,1.7c1.7-0.4,2.1-2.3,1.6-3.8c-0.2-0.7-0.6-1.3-1-1.9c-1-1.7-3.5-6.1-4.6-7.9l0.4-0.2c0.9,1.2,2.6,3.8,3.4,5
c0.9,1.5,2.1,3.1,2.9,4.6c0.2,0.4,0.3,0.9,0.3,1.4l0,0c0.2,0.3,0.5,0.6,0.8,0.8c1,0.7,2.3,0.8,3.4,0.5c1.7-0.4,1.7-2.4,1.2-3.8
c-0.6-1.7-1.8-3.1-2.7-4.6c-1.1-1.5-2.1-3-3.2-4.5l0.5-0.4c1.2,1.4,2.4,2.9,3.5,4.4s2.4,3,3.1,4.8c0.1,0.2,0.2,0.4,0.2,0.7l0,0
c1.4,0.3,3.2,0.2,4.2-0.6c0.3-0.3,0.6-0.6,0.7-0.9l0.1-0.1v-0.1c0-0.1,0.1-0.3,0.1-0.4c-0.2-3-4.4-7.7-6.4-10.1l0.3-0.3
c0.7,0.5,1.3,1.1,1.9,1.7c1.3,1.3,3.7,3.7,5,6.1c0.4-0.2,0.8-0.6,1.1-1.2c0.7-1.5-0.5-3.5-1.8-5.6c-0.1-0.2-0.3-0.5-0.4-0.7
c-0.5-0.9-1.8-2.8-3.3-4.9c-2.6-3.8-5.9-8.5-7.2-11.1c-1.9-3.7-7.3-17.1-8.2-23.3c-0.9-5.9-1.8-13.7-1.8-13.8l1.5-0.2
c0,0.1,0.9,7.8,1.8,13.8c0.9,6.1,6.2,19.2,8.1,22.9c1.3,2.5,4.5,7.2,7.1,10.9c1.5,2.2,2.8,4,3.4,5c0.1,0.2,0.3,0.4,0.4,0.7
c1.4,2.4,2.9,4.8,1.9,7.1c-0.5,1-1.3,1.6-1.9,2c0.2,0.9,0.3,1.7-0.1,2.4c-1,2.4-3.8,2.9-6.1,2.4c0,1.4-0.7,2.8-2.3,3.3
c-1.6,0.5-3.5,0.2-4.8-0.8c-0.3,0.9-1,1.7-1.9,2.1c-2.3,0.9-4.8,0.2-6.7-1.1c-0.2,0.8-0.6,1.6-1.4,2.2c-1.7,1.2-4,0.9-5.8,0.3
c-3.5-1.2-6.2-3.8-8.6-6.5c-1.6-1.8-3-3.7-4.3-5.7c-1.4-2.2-2.8-4.7-3.4-7.3c-0.4-2-0.6-4.1-0.8-6.2c-0.4-3.7-0.6-7.5-2.1-10.9
c-0.4-0.7-0.9-1.5-1.4-2.1c-0.7-1-1.3-2.1-1.7-3.3C258.6,903.9,259.6,900.2,260.5,896.7z"/>
<g>
<path class="st1" d="M190.2,104.1c-0.1,4-0.2,2.8-0.2,5.8c-0.1,13.8-0.2,18.4-4,28.7c-2,5.4-6.6,13.1-17.6,19.3
c-6.5,3.7-12.1,5.1-12.3,5.1l-2.4,0.6l1.6-1.8c4.2-4.7,6.2-10.2,7.1-13.7c-1.3,1.7-3.3,4.1-5.7,6.7c-6.8,7.2-17.3,13.1-17.7,13.4
l-3.2,1.8l2.2-2.9c0.1-0.1,9.2-12.2,15.4-26.8c6.1-14.4,10-46.7,9.4-60.1c-0.6-12.5-0.2-31,5.2-47.4c5.3-16.3,14.1-26.4,26.1-30.2
c12.2-3.8,20.3-0.7,25,2.5c5.1,3.6,7.2,8,7.3,8.2L225,14c0,0-2.1-4.3-6.9-7.6c-6.4-4.4-14.3-5.2-23.6-2.3
c-11.5,3.6-20,13.5-25.1,29.2c-4.1,12.7-5.9,29.3-5.1,46.9c0.7,13.6-3.1,45.8-9.5,60.8c-4.4,10.5-10.3,19.6-13.4,24.1
c3.7-2.3,9.8-6.5,14.2-11.2c4.8-5.1,7.6-9.3,7.7-9.4l1.8-2.6l-0.4,3.2c0,0.4-1.1,8.6-6.6,16c5.9-2,21.2-8.3,26.5-22.8
c3.7-10,3.7-14.3,3.9-28.2c0-3,0.1-6.4,0.1-10.4"/>
</g>
<path class="st1" d="M413.5,512.3c-1.6-5.7-4.1-11.2-6.6-16.6c-1.7-3.8-3.5-7.7-5-11.7l0,0l-0.3-0.8c-5.9-18.9-8.2-36.2-9.7-51.7
l-0.3-3.5c-1.1-6.6-1.4-12.2-2.7-19.3c-0.9-6.8-2-12.9-3.8-18.2c-0.7-3.1-1.5-6.1-2.4-9.1c-4-14.1-10.8-22-15.4-27.3
c-2.1-2.4-3.7-4.3-4.3-5.9c-0.4-1-0.5-4.7-0.6-9.3c-0.2-7.2-0.5-18.1-2.2-30.5c-1.6-11.9-5.4-25.9-8.2-36.2c-0.5-1.9-1-3.7-1.4-5.3
c-2.1-7.8-2.6-13.8-3.4-21.4c-0.2-1.8-0.4-3.7-0.6-5.7c-1.2-11.2-9.5-41-17.5-50.9c-7.8-9.7-25.4-10.3-34.9-10.6
c-1.6-0.1-3.1-0.1-4.2-0.2c-2.9-0.2-5.6-0.8-8.4-1.9h-0.1c-4.2-1.7-8.8-4.7-15.3-9.6c-11.1-8.5-15.3-19.4-16-24
c-0.5-3.5-0.1-15.6,0-17.6c8.4-8.5,11.2-17.2,12.7-24.7c1.6-0.5,3.1-1.1,4.3-1.8c3.4-2.1,5.9-7.9,7.2-16.7c0.4-2.7-0.1-4.8-1.4-6.3
c-1.8-2.1-4.6-2.4-6.2-2.5c3.2-30-4.7-41.7-12-48.6c-7.8-7.3-17.7-11-29.6-11c-11.9,0-21.8,3.7-29.6,11c-7.3,6.9-15.3,18.6-12,48.6
c-1.6,0-4.4,0.4-6.2,2.5c-1.3,1.5-1.8,3.6-1.4,6.3c1.3,8.8,3.8,14.6,7.2,16.7c1.2,0.7,2.6,1.3,4.3,1.8c1.5,7.5,4.3,16.2,12.7,24.7
c0.1,2,0.5,14.1,0,17.6c-0.7,4.6-4.9,15.6-16,24c-6.5,5-11.1,7.9-15.3,9.6h-0.1c-2.8,1.1-5.5,1.7-8.4,1.9c-1.1,0.1-2.5,0.1-4.2,0.2
c-9.5,0.3-27.2,0.9-34.9,10.6c-7.9,9.9-16.3,39.7-17.5,50.9c-0.2,2-0.4,3.9-0.6,5.7c-0.7,7.6-1.3,13.6-3.4,21.4
c-0.4,1.6-0.9,3.4-1.4,5.3c-2.8,10.2-6.6,24.3-8.2,36.2c-1.7,12.5-2,23.3-2.2,30.5c-0.1,4.6-0.2,8.2-0.6,9.3
c-0.7,1.6-2.3,3.5-4.3,5.9c-4.5,5.3-11.4,13.2-15.4,27.2c-0.8,3-1.6,6-2.4,9.1c-1.8,5.3-2.9,11.4-3.8,18.2
c-1.3,7.1-0.4,11-1.5,17.6l-0.4,5.3C58,447,55.8,464.2,50,483c-1.5,3.9-3.7,9-5.4,12.7c-2.5,5.4-5,10.9-6.6,16.6
c-3,10.7-2.2,17.9-1.1,22.1c0.6,2.4,1.4,6.6,2.1,10.7c0.7,4.1,1.4,8.4,2.1,10.9c1.2,4.2,4.5,7.5,7.7,10.8c0.6,0.6,1.3,1.3,1.9,1.9
c1,1,1.8,2.1,2.7,3.1c1.9,2.3,3.6,4.4,5.4,4.4c0.4,0,0.8-0.1,1.3-0.4c2-1.2,1.8-2.7,1.2-4.1c0.4-0.2,0.6-0.5,0.7-0.8
c0.4-0.9,0.3-2.1-0.2-3.5c0.6-0.3,1.1-0.8,1.3-1.4c0.5-1.6-0.3-4.3-2.6-8.1c-1.6-2.7-8.8-12.3-10.1-14.1c0,0,0,0,0-0.1
c0.2-1,0.3-1.9,0.4-2.7c0.1-0.9,0.2-1.9,0.3-2.9c0.3-2.9,0.7-6.1,1.2-8.3c0.4-1.9,1.4-6.4,2.8-7.1c0.1-0.1,0.3-0.1,0.7,0.1
c3.2,1.7,3.6,6.9,1.4,15.3c-1,3.8-1.9,9.4,1.7,13.5c0.9,1.1,1.9,1.5,2.8,1.2c2.1-0.6,2.9-4.1,3.6-7.7l0.1-0.4
c0.2-1.1,1-3.7,1.8-6.7c1.2-4,2.6-9,3.6-13.1c1.5-6.4,2.2-15.3,1.9-23.8c-0.4-11.3,3.1-19.5,3.1-19.6c0,0,3.4-10.2,9-21
c3.4-6.7,6.3-12.9,8-16.6c2.6-5.1,5.2-10.2,7.7-15.2c0.1-0.2,0.2-0.5,0.3-0.7c6.1-12.4,11.2-24.1,13.1-33c1.1-4.9,1.6-9.4,1.8-13.3
c0-0.1,0-0.1,0-0.2c0.2-4.1,0.2-7.7,0.1-10.8c0-1.2-0.1-2.3-0.2-3.3c-0.2-3.3-0.3-6.2,0-8.6c0.2-1.5,1-4.1,2.1-7.4
c1.9-5.3,4.7-12.3,7.6-19.6c4.2-10.5,8.5-21.3,10.8-28.7c3.8-12.3,8.3-24,10-28.6c1.4,4.6,4.9,16.5,7.8,31.7
c1.1,5.7,2.3,11.3,3.6,16.7c0.2,0.9,0.4,1.8,0.6,2.7c2.8,12.5,5.2,23.3,5,29.2c-0.1,1.2-0.2,2.5-0.5,3.8c0,0,0,0,0,0.1
c-1,5.1-3.3,11.4-6.6,20.5c-2.2,6.1-5,13.7-8.2,23c-1,3-2,6-3.1,9.1c-0.2,0.5-0.4,1.1-0.6,1.7c-8.2,23.7-17.2,50.7-16.7,88.2
c0.4,30.2,8.5,67.7,13.9,92.4c2.4,10.9,4.2,19.5,4.8,24.4c2.1,18,1.7,26.7,1.2,35.9c-0.2,2.9-0.3,6-0.4,9.4
c-0.1,6.6,0.3,14.1,0.7,20.7c0.4,5.7,0.7,10.7,0.4,12.9c-0.1,1-0.7,2.7-1.4,5.1c-2.7,8.7-7.7,24.9-6.1,47.9
c2.3,33.7,7.8,61.3,12.3,83.4c1.1,5.6,2.2,10.8,3.1,15.8c0.4,2.2,0.9,4.4,1.3,6.5c1.6,7.8,3.1,15.2,2.7,20.9
c-0.3,3.3,0.2,5.2-0.6,7.5v1.1c0.3-0.7,0.6-1.5,0.8-2.3c1,0.2,3.1,0.5,5.5,0.5c0.1,0,0.1,0,0.2,0c1.1,0,2.3-0.1,3.5-0.3
c1.5-0.3,6.7-1.3,7.9-3.3c0.6-1,0.2-7.2-1.7-31.2c-1.5-19.1-3.7-45.2-4.4-66.4c-0.4-10.2-0.6-19.6-0.8-27.9
c-0.6-25.1-1-41.7-4.4-50c-2.1-5.2-5.3-8.2-8.3-11.1c-3.6-3.4-7-6.7-8.8-13.4c-0.3-5.5-0.6-11.4-0.5-16.6c0.1-3.4,0.2-6.4,0.4-9.4
c0.2-4,0.4-8,0.4-12.6c1.7,3,4.6,7.3,7.9,8.2c0.5,0.1,0.9,0.2,1.4,0.2c0.9,0,1.8-0.3,2.6-0.8c5.3-3.5,4-19.4,3.3-25.5
c1.2,1,2.5,1.6,3.7,1.6c4,0,7-5.3,8.8-9.4c-0.4,14,0.8,33,9.4,37.5c1.1,0.6,2.3,0.9,3.6,0.9c0.9,0,1.9-0.2,2.8-0.5
c6.1-2.2,11.4-10.9,13.8-15.6c-0.1,3.3-0.3,6.9-0.8,10.9c-1.5,12.3-4.4,19.3-7.1,25.5c-2,4.7-3.7,8.7-4.4,14c-0.6,4-0.4,8.3,0,13.1
c-1.3,1.7-9.3,12.7-13.2,24.7c-2.5,7.6-3.8,33.6-3.1,61.8c0.4,15.7,2.1,54.1,9,72.5c0.2,4.4,0.4,8,0.8,10.2c0.6,3.5,2.1,3,1.5-0.3
c-1.2-6.8-1.3-30.3-1-48.3c0.3-16.3,3.3-33.3,6-48.3c2.3-12.8,4.4-25,4.5-34.6c0.1-11.5-1-20.8-2-29.1c-1-8.2-1.8-15.3-1-21.4
c0.7-5.1,2.4-9.1,4.3-13.6c2.7-6.3,5.7-13.5,7.2-25.9c0.6-5.4,0.8-10,0.8-14.4c-0.1-4.9-0.4-9.4-0.8-14c-0.1-1.3-0.2-2.7-0.3-4
c-0.5-6.5-0.8-13.5-0.3-22.4c0.5-8.5,2.9-17,5.5-26.8c3.2-11.7,6.8-24.9,8.3-41.6c1.9-21.9,0.3-36.1-0.3-40.3
c1.2,0.4,2.9,0.8,4.6,0.8s3.4-0.5,4.6-0.8c-0.6,4.2-2.2,18.5-0.3,40.3c1.5,16.7,5.1,29.9,8.3,41.6c2.7,9.8,5,18.3,5.5,26.8
c0.6,9,0.2,15.9-0.3,22.4c-0.1,1.4-0.2,2.7-0.3,4c-0.4,4.6-0.7,9.1-0.8,14c-0.1,4.3,0.1,9,0.8,14.4c1.5,12.5,4.5,19.6,7.2,25.9
c1.9,4.6,3.6,8.5,4.3,13.6c0.8,6.2,0,13.2-1,21.4c-1,8.3-2.1,17.6-2,29.1c0.1,9.6,2.2,21.8,4.5,34.6c2.7,15,5.7,32.1,6,48.3
c0.3,18.1,0.2,41.5-1,48.3c0.6,3.1,2,3.5,2.6,0.1c0.4-2.2,0.6-5.8,0.8-10.2c6.9-18.4,8.6-56.8,9-72.5c0.7-28.2-0.6-54.2-3.1-61.8
c-4-12-11.9-22.9-13.2-24.7c0.4-4.7,0.6-9,0-13.1c-0.7-5.3-2.4-9.3-4.4-14c-2.6-6.2-5.6-13.3-7.1-25.5c-0.5-4-0.7-7.5-0.8-10.9
c2.5,4.7,7.7,13.5,13.8,15.6c0.9,0.3,1.9,0.5,2.8,0.5c1.2,0,2.4-0.3,3.6-0.9c8.6-4.4,9.8-23.5,9.4-37.5c1.8,4.1,4.8,9.4,8.8,9.4
c1.3,0,2.5-0.6,3.7-1.6c-0.7,6.1-2,22,3.3,25.5c0.8,0.6,1.7,0.8,2.6,0.8c0.5,0,0.9-0.1,1.4-0.2c3.3-1,6.2-5.2,7.9-8.2
c0,4.7,0.2,8.6,0.4,12.6c0.2,2.9,0.3,6,0.4,9.4c0.1,5.2-0.2,11.1-0.5,16.6c-1.7,6.7-5.1,10-8.8,13.4c-3.1,2.9-6.2,5.9-8.3,11.1
c-3.4,8.4-3.8,25-4.4,50c-0.2,8.3-0.4,17.7-0.8,27.9c-0.8,21.2-2.9,47.4-4.4,66.4c-1.9,24.1-2.4,30.3-1.7,31.2c1.3,2,6.4,3,7.9,3.3
c1.2,0.2,2.4,0.3,3.5,0.3c0.1,0,0.1,0,0.2,0c2.4,0,4.4-0.3,5.5-0.5c0.3,0.8,0.5,1.5,0.8,2.3l0.3,0.2c-0.9-2.3-0.7-5.5-0.9-8.8
c-0.4-5.7,1.1-13.1,2.7-20.9c0.4-2.1,0.9-4.3,1.3-6.5c0.9-5,2-10.3,3.1-15.8c4.5-22.1,10-49.7,12.3-83.4c1.6-23-3.4-39.2-6.1-47.9
c-0.7-2.4-1.3-4.1-1.4-5.1c-0.3-2.2,0-7.2,0.4-12.9c0.4-6.6,0.9-14.2,0.7-20.7c-0.1-3.4-0.2-6.5-0.4-9.4
c-0.5-9.2-0.9-17.8,1.2-35.9c0.6-5,2.5-13.6,4.8-24.4c5.4-24.8,13.5-62.2,13.9-92.4c0.5-38.4-8.9-65.7-17.3-89.8
c-1.1-3.1-2.1-6.1-3.1-9.1c-3.1-9.3-5.9-16.9-8.2-23c-3.4-9.1-5.7-15.4-6.6-20.5c0,0,0,0,0-0.1c-0.3-1.3-0.4-2.6-0.5-3.8
c-0.3-6,2.1-16.8,5-29.2c0.2-0.9,0.4-1.8,0.6-2.7c1.2-5.5,2.5-11.1,3.6-16.7c2.9-15.2,6.4-27.1,7.8-31.7c1.8,4.5,6.3,16.2,10,28.6
c2.3,7.4,6.6,18.2,10.8,28.7c2.5,6.3,5,12.5,6.8,17.4c0.3,0.8,0.6,1.5,0.8,2.2c1.2,3.3,2,5.9,2.1,7.4c0.3,2.4,0.1,5.3,0,8.6
c-0.1,1-0.1,2.1-0.2,3.3c-0.1,3.1-0.2,6.7,0.1,10.8c0,0.1,0,0.1,0,0.2c0.2,4,0.8,8.4,1.8,13.3c1.9,8.9,7,20.6,13.1,33
c0.1,0.2,0.2,0.5,0.3,0.7c3,6,6.1,12.2,9.3,18.3c7,13.7,13.6,26.6,16.9,35.8l0.2,0.4c1,2.9,2.8,9.6,2.5,18
c-0.3,8.5,0.4,17.4,1.9,23.8c1,4.1,2.4,9.1,3.6,13.1c0.9,3,1.6,5.6,1.8,6.7l0.1,0.4c0.7,3.6,1.5,7.1,3.6,7.7
c0.9,0.3,1.9-0.1,2.8-1.2c3.7-4.1,2.7-9.6,1.7-13.5c-2.2-8.4-1.7-13.6,1.4-15.3c0.4-0.2,0.6-0.1,0.7-0.1c1.4,0.6,2.4,5.1,2.8,7.1
c0.5,2.2,0.8,5.5,1.2,8.3c0.1,1,0.2,2,0.3,2.9c0.1,0.7,0.2,1.6,0.4,2.7c0,0,0,0,0,0.1c-1.3,1.7-8.5,11.4-10.1,14.1
c-2.2,3.7-3.1,6.5-2.6,8.1c0.2,0.6,0.7,1.1,1.3,1.4c-0.5,1.4-0.7,2.6-0.2,3.5c0.1,0.3,0.4,0.6,0.7,0.8c-0.6,1.5-0.8,3,1.2,4.1
c0.4,0.2,0.9,0.4,1.3,0.4c1.8,0,3.5-2.1,5.4-4.4c0.8-1,1.7-2.1,2.7-3.1c0.6-0.6,1.2-1.3,1.9-1.9c3.2-3.2,6.5-6.6,7.7-10.8
c0.7-2.4,1.4-6.7,2.1-10.9c0.7-4.1,1.4-8.3,2.1-10.7C415.7,530.3,416.4,523.1,413.5,512.3z M187.7,821.4c-0.1,4.3-0.3,20.1,0,32.9
c-3.9-14-6.5-37.9-7.3-67.1c-0.1-3.6-0.1-7.3-0.2-10.8c2.6,14.6,7.4,33.7,7.9,35.7C187.9,815.2,187.7,818.3,187.7,821.4z
M188.6,807.4c-2.1-8.2-6.2-25.9-7.7-37.2c2.1,6.8,5.8,16.4,9.9,20C189.9,795.9,189.1,801.6,188.6,807.4z M195.4,703.1
c0.2,2.1,0.5,4.2,0.7,6.5c1.1,8.2,2.2,17.5,2.1,28.8c-0.1,9.5-2.2,21.6-4.5,34.3c-0.9,5-1.8,10.2-2.7,15.6
c-5.6-6-10.3-23.9-10.7-25.5c0.3-17.5,1.4-31.8,3.1-36.9C186.7,715.9,192.8,706.7,195.4,703.1z M260.7,790.2
c4.1-3.6,7.7-13.3,9.9-20c-1.5,11.3-5.7,29-7.7,37.2C262.3,801.6,261.6,795.8,260.7,790.2z M271,787.2c-0.7,29.2-3.4,53-7.3,67.1
c0.3-12.9,0.1-28.6,0-32.9c-0.1-3.1-0.2-6.2-0.4-9.3c0.5-2,5.4-21.1,7.9-35.7C271.2,780,271.1,783.6,271,787.2z M255.9,703.1
c2.6,3.6,8.7,12.9,12,22.9c1.7,5.1,2.8,19.4,3.1,36.9c-0.4,1.6-5.1,19.6-10.7,25.5c-0.9-5.3-1.8-10.6-2.7-15.6
c-2.3-12.8-4.4-24.8-4.5-34.3c0-11.4,1.2-20.7,2.1-28.9C255.5,707.4,255.7,705.2,255.9,703.1z M363.2,350.5
c0.8,1.3,2.1,2.8,3.6,4.6c4.4,5.2,11.1,12.9,15.1,26.7c0.8,2.9,1.6,5.9,2.3,8.9c0,0,0,0,0,0.1c1.4,5.9,2.7,12,3.8,18.1
c0.6,4.7,1.1,9.8,1.6,15.2c1.6,16.8,3.6,37.6,10.6,60c-0.7,0.4-1.4,0.7-2.2,1.1c-7.3-30.8-17.4-57-25.4-78.2
c-4.3-11.1-7.8-20.4-9.9-27.6c0-0.1,0-0.1,0-0.2c-1.3-4.6-2.1-8.5-2.1-11.4C360.6,360.1,362.3,353.4,363.2,350.5z M369.7,429.8
c-0.1-0.3-0.3-0.6-0.4-0.9c-2.6-5.4-5-10.2-7.2-14.6c-6.6-12.9-12.2-24-16.7-42.4c0.8-1.6,1.5-3.1,2-4.6
c6.5,6.3,12.7,11.7,13.8,12.6c2.1,7.3,5.7,16.5,9.9,27.6c2.1,5.6,4.4,11.6,6.8,17.9c1.9,6.6,2.8,11.7,3.6,17.2
c1.6,9.9,3.4,21,11.8,44.2c-0.9,0.3-1.8,0.5-2.7,0.7C383,460.6,375.9,443.1,369.7,429.8z M389.1,487.9c-0.7,0.1-1.4,0.2-2.2,0.3
c-3.2-9.2-6.3-20.7-9-30.9c-1.2-4.3-2.3-8.5-3.3-12.2C379.1,456.2,384,470,389.1,487.9z M347.9,365.6c0.7-2.1,1.2-3.9,1.5-5.3
c2.5,4,8,7.1,9.6,7.9c0,2.5,0.6,5.5,1.5,9C357.9,374.9,352.9,370.5,347.9,365.6z M382.9,442.4c-0.3-1.7-0.5-3.3-0.8-5
c5.1,14.6,10.2,30.7,14.4,48.4c-0.6,0.2-1.1,0.4-1.8,0.6C386.3,463.3,384.5,452.2,382.9,442.4z M290.6,179.7
c1.1,0.1,2.5,0.1,4.1,0.2c9.3,0.3,26.6,0.9,33.9,10c7.8,9.7,16,39.1,17.1,50.1c0.2,2,0.4,3.9,0.6,5.7c0.5,5.1,0.9,9.6,1.8,14.3
c-7-1.9-13.1-7.1-19.6-12.7c-8.1-7-16.5-14.2-28-16.1c-0.5-1.8-1.2-3.4-2-4.8c-7.4-12.9-38.5-23.6-51.2-23.6c-0.1,0-0.3,0-0.4,0
c1.6-1.3,3.2-2.6,4.9-4C259.7,192,268.7,184.3,290.6,179.7z M260.6,317.6c4.7-4.4,14.1-6.3,17.5-5.8c1.8,0.3,3.2,1.1,4.2,2.5
c1.3,2,1.6,5.1,0.7,8.8c-1.4,0.6-3,1.2-4.8,2c-6,2.7-10.8,5.5-14.4,8.3c-0.6,0-1.6-0.1-2.7-1.1c-1.3-1.1-2.8-3.5-3.2-8.7
C257.8,321.4,258.7,319.4,260.6,317.6z M260.1,316.1c-0.9-1.2-2.8-4.4-2.2-10.2c0.9-7.4,5.1-8.4,6.5-8.6c0.3,0.4,0.6,0.8,0.9,1.2
c3.1,3.6,7.9,5.7,13,5.7c0.5,0,1,0,1.6-0.1c0.3,1.3,0.6,4.5-1.9,6.2C274.2,309.9,265.2,311.8,260.1,316.1z M283.5,313.4
c-0.9-1.3-2.1-2.2-3.7-2.8c1.9-2,1.9-4.9,1.5-6.8c3.9-0.6,7.4-2.3,10.1-4.7c0.9,2,0.9,5.2,0,8.3c-1.2,4.1-3.7,7.3-6.7,8.7
C284.5,315.1,284.1,314.2,283.5,313.4z M291.2,297.5L291.2,297.5c-2.7,2.7-6.6,4.6-10.8,5.1c-5.5,0.6-10.7-1.3-13.9-5
c-0.4-0.4-0.7-0.9-1-1.3c-1.4-2.2-2-4.7-1.7-7.1c2.8,3.1,7,5.5,12.9,5.5c1.5,0,3.1-0.2,4.7-0.5c4.9-1,9.1-3.5,12.2-7.1
c1.1,1.9,1.1,4.3,0.1,6.8C293.1,295.2,292.3,296.4,291.2,297.5z M281,292.5c-1.6,0.3-3.1,0.5-4.5,0.5c-5.9,0-9.9-2.6-12.5-5.9
c-0.1-0.2-0.2-0.3-0.3-0.4c-1.4-1.9-2.4-4-2.9-5.9c4.8,1.8,9.8,2.8,14.7,2.8c2.3,0,4.5-0.2,6.5-0.7c5.2-1.2,10-5.8,13.6-13.1
c1.4,2.8,1.4,6.8-0.1,10.7C293.1,286.7,287.7,291.2,281,292.5z M295.3,266.9c-3.6,8.2-8.4,13.3-13.6,14.5c-1.9,0.4-4,0.7-6.2,0.7
c-14.2,0-30.5-9.9-36.3-18.8c-6.5-10-6.8-18.1-6.8-31.6c0-11,2.1-24.7,12.2-27c0.8-0.2,1.7-0.3,2.9-0.3c6.4,0,17.2,2.7,27,6.8
c11.6,4.8,19.7,10.5,22.9,16.1c3.7,6.7,3.9,19.6,0.7,31.4v0.1C297.3,261.6,296.4,264.3,295.3,266.9z M250.5,147.5
c2.1,5.7,6.7,13.7,15.3,20.3c5.1,3.9,9.1,6.6,12.7,8.5c-9.1,0.3-17,2-24.2,4C250.7,167.9,250.4,154.5,250.5,147.5z M252.9,180.7
c-2.6,0.7-5,1.5-7.4,2.3c-1.5,0.5-2.9,0.9-4.3,1.3c4.3-12.7,3-26.7,2.8-29c0.1-0.2,0.3-0.3,0.4-0.5c1.8-2.2,3.5-4.2,4.7-6.5
C249,155.5,249.4,168.6,252.9,180.7z M239.3,184.9c-2.4,0.6-4.7,1.2-6.9,1.7c0.8-16.1,5.7-23.8,10.2-29.6
C243,161.6,243.4,173.9,239.3,184.9z M189.4,99.5c-0.1-0.3-0.3-0.5-0.6-0.6c-1.7-0.4-3.2-1-4.3-1.7c-3-1.8-5.3-7.4-6.5-15.6
c-0.4-2.2,0-4,1-5.1c1.5-1.7,4.2-1.9,5.3-1.9c0.4,0,0.6,0,0.6,0c0.2,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.6
c-3.4-29.9,4.3-41.5,11.5-48.1c7.5-7,17.1-10.6,28.6-10.6s21.1,3.6,28.6,10.6c7.1,6.7,14.9,18.2,11.5,48.1c0,0.2,0.1,0.5,0.2,0.6
c0.2,0.2,0.4,0.2,0.6,0.2c0,0,0.2,0,0.6,0c1.1,0,3.8,0.2,5.3,1.9c1,1.2,1.4,2.9,1,5.1c-1.2,8.2-3.5,13.8-6.5,15.6
c-1.2,0.7-2.6,1.3-4.3,1.7c-0.3,0.1-0.5,0.3-0.6,0.6c-2.1,10.7-5.8,17.8-12.6,24.6c-0.1,0.1-0.2,0.3-0.2,0.6c0,1-0.6,14,0,18
c-0.6,4.5-3,7.4-6.1,11.2c-5.1,6.3-11.5,14.1-12.4,33c-1.7,0.3-3.4,0.4-5.1,0.4s-3.4-0.2-5.1-0.4c-0.8-18.9-7.2-26.7-12.4-33
c-3.1-3.8-5.5-6.7-6.1-11.2c0.6-4,0-16.9,0-18c0-0.2-0.1-0.4-0.2-0.6C195.2,117.3,191.6,110.2,189.4,99.5z M202.4,148.3
c1.2,2.3,2.9,4.3,4.7,6.5c0.1,0.2,0.3,0.3,0.4,0.5c-0.3,2.3-1.5,16.3,2.9,29c-1.4-0.4-2.8-0.9-4.3-1.3c-2.4-0.7-4.9-1.5-7.4-2.3
C202.1,168.6,202.5,155.5,202.4,148.3z M208.8,156.9c4.6,5.8,9.5,13.5,10.2,29.6c-2.2-0.4-4.5-1-6.9-1.7
C208,173.8,208.4,161.5,208.8,156.9z M185.6,167.8c8.5-6.6,13.2-14.6,15.3-20.3c0.2,6.9-0.1,20.4-3.8,32.8c-7.1-2-15-3.7-24.2-4
C176.5,174.5,180.4,171.8,185.6,167.8z M105,245.7c0.3-1.8,0.5-3.7,0.7-5.7c1.2-11,9.4-40.4,17.1-50.1c7.3-9.1,24.5-9.7,33.8-10
c1.6-0.1,3-0.1,4.1-0.2c21.9,4.6,30.9,12.3,38.8,19.1c1.6,1.4,3.2,2.8,4.9,4c-0.1,0-0.3,0-0.4,0c-12.7,0-43.8,10.7-51.2,23.6
c-0.8,1.4-1.4,3-2,4.8c-11.5,1.8-19.9,9.1-28,16.1c-6.4,5.5-12.5,10.8-19.6,12.7C104.1,255.3,104.5,250.8,105,245.7z M171.6,310.7
c-1.5,0.5-2.8,1.4-3.7,2.8c-0.5,0.8-0.9,1.7-1.2,2.7c-2.9-1.5-5.4-4.7-6.6-8.8c-0.9-3.1-1-6.3-0.1-8.2c2.6,2.4,6.2,4.1,10.1,4.7
C169.7,305.8,169.8,308.7,171.6,310.7z M169.2,314.3c0.9-1.4,2.3-2.2,4.2-2.5c3.4-0.5,12.8,1.4,17.5,5.8c1.9,1.8,2.9,3.8,2.7,6
c-0.4,5.3-1.9,7.7-3.2,8.7c-1.1,0.9-2.1,1.1-2.7,1.1c-3.6-2.8-8.4-5.6-14.4-8.3c-1.8-0.8-3.4-1.5-4.8-2
C167.6,319.4,167.9,316.3,169.2,314.3z M173.5,310.3c-2.5-1.7-2.2-4.9-1.9-6.2c0.5,0,1,0.1,1.6,0.1c5.2,0,9.9-2,13-5.7
c0.3-0.4,0.6-0.7,0.9-1.1c1.4,0.1,5.7,1.2,6.5,8.6c0.6,5.7-1.3,8.9-2.2,10.1C186.3,311.8,177.2,309.9,173.5,310.3z M186,296.1
c-0.3,0.5-0.6,0.9-1,1.4c-3.2,3.8-8.4,5.7-13.9,5c-4.2-0.5-8-2.3-10.7-5l-0.1-0.1c-1.1-1.1-1.9-2.3-2.5-3.6c-1.1-2.4-1-4.9,0.2-7
c3,3.6,7.3,6.1,12.2,7.1c1.7,0.3,3.3,0.5,4.7,0.5c5.8,0,10-2.4,12.9-5.5C188,291.4,187.4,293.9,186,296.1z M187.8,286.7
c-0.2,0.1-0.3,0.2-0.3,0.4c-3.2,4-8.6,7.1-17,5.4c-6.7-1.3-12.1-5.8-14.4-12c-1.5-3.9-1.5-7.9-0.1-10.7c3.7,7.3,8.5,11.9,13.6,13.1
c2,0.5,4.2,0.7,6.5,0.7c4.8,0,9.9-1.1,14.6-2.8C190.1,282.7,189.2,284.8,187.8,286.7z M176,282.1c-2.2,0-4.2-0.2-6.2-0.7
c-5.2-1.2-10.1-6.3-13.6-14.5c-1.1-2.6-2.1-5.3-2.8-8.1v-0.1c-3.2-11.8-3-24.7,0.8-31.4c3.2-5.5,11.3-11.2,22.9-16.1
c9.8-4.1,20.6-6.8,27-6.8c1.1,0,2.1,0.1,2.9,0.3c10.1,2.4,12.2,16,12.2,27c0,13.5-0.3,21.7-6.8,31.6
C206.5,272.2,190.2,282.1,176,282.1z M62,424c0.5-5.4,1-10.5,1.6-15.2c1.6-9.1,3.5-18.4,6-27c3.9-13.7,10.6-21.5,15.1-26.7
c1.5-1.8,2.8-3.3,3.7-4.7c0.9,3,2.6,9.6,2.6,17.3c0,2.9-0.7,6.8-2.1,11.4c0,0.1,0,0.1,0,0.2c-2.1,7.2-5.6,16.5-9.9,27.6
c-8.1,21.1-18.1,47.4-25.4,78.2c-0.8-0.4-1.6-0.7-2.2-1.1C58.4,461.6,60.4,440.8,62,424z M61,487.5c-1-0.2-1.9-0.5-2.7-0.7
c8.5-23.2,10.3-34.4,11.8-44.2c0.9-5.5,1.7-10.7,3.6-17.2c2.3-6.3,4.6-12.3,6.8-17.9c4.2-11.1,7.8-20.3,9.9-27.6
c1.1-1,7.4-6.4,13.8-12.6c0.6,1.5,1.2,3.1,2,4.6c-4.5,18.3-10.1,29.5-16.7,42.4c-2.3,4.4-4.7,9.1-7.2,14.6
c-0.2,0.3-0.3,0.6-0.4,0.9C75.7,443.2,68.6,460.7,61,487.5z M76.9,445c-1.1,3.8-2.2,7.9-3.3,12.2c-2.7,10.2-5.8,21.7-9,30.9
c-0.8,0-1.5-0.1-2.2-0.3C67.5,469.9,72.3,456.1,76.9,445z M56.7,486.3c-0.6-0.1-1.2-0.4-1.8-0.6c4.2-17.6,9.3-33.8,14.4-48.4
c-0.3,1.7-0.6,3.3-0.8,5C66.9,452.1,65.2,463.2,56.7,486.3z M91,377.2c0.9-3.5,1.4-6.5,1.5-9c1.6-0.8,7-4,9.6-7.8
c0.3,1.3,0.8,3.2,1.5,5.3C98.6,370.6,93.6,375,91,377.2z M60.6,570.1c0,0,0,0.1-0.1,0.1c0,0,0-0.1-0.1-0.1
c-0.2-0.3-0.4-0.7-0.6-1.1c-0.6-1.2-2.3-3.5-4.4-6.2c-2.4-3.2-5.1-6.8-5.7-8.4c-0.3-0.7-0.4-1.7-0.4-2.9c2.8,3.5,8.2,10.4,9.2,12.3
C59.5,565.7,61.2,569,60.6,570.1z M59.3,558.5c1.9,3.3,2.8,5.8,2.4,7c-0.1,0.3-0.3,0.5-0.5,0.6c-0.4-0.9-0.8-1.8-1.3-2.8
c-1.2-2.4-8.3-11.3-10.4-13.9c0.1-1.3,0.3-2.6,0.5-3.9C52.3,548.6,58,556.2,59.3,558.5z M71,500.9c0.3,8.4-0.4,17.1-1.9,23.4
c-0.9,4.1-2.4,9.1-3.6,13c-0.9,3.2-1.6,5.6-1.9,6.8l-0.1,0.5c-0.8,4-1.6,6.2-2.4,6.4c-0.3,0.1-0.7-0.2-1.1-0.7
c-3.1-3.4-2.3-8.4-1.3-11.9c0.9-3.5,3.7-14-2.3-17.2c-0.9-0.5-1.7-0.4-2.2-0.1c-2,0.9-2.9,4.6-3.7,8.2c-0.5,2.3-0.9,5.6-1.2,8.5
c-0.1,1-0.2,2-0.3,2.9c-0.1,0.7-0.2,1.6-0.4,2.6c-0.6,4.1-1.5,9.2-0.4,11.8c0.7,1.7,3.4,5.3,6,8.7c1.9,2.5,3.7,4.9,4.2,5.9
c0.2,0.4,0.4,0.8,0.6,1.1c1.2,2.3,1.3,2.7,0.2,3.3c-1,0.6-2.7-1.5-4.5-3.7c-0.8-1-1.8-2.1-2.7-3.2c-0.6-0.6-1.3-1.3-1.9-1.9
c-3-3.1-6.2-6.2-7.2-10c-0.7-2.4-1.4-6.6-2.1-10.7c-0.7-4.1-1.4-8.4-2.1-10.8c-0.9-3.2-1.9-10.3,1.1-21.2
c1.5-5.5,3.9-10.8,6.5-16.3c1.6-3.5,3.2-7.1,4.6-10.7c3.9,2.1,9.9,4.2,18.1,4.6c0.9,0,1.8,0.1,2.7,0.1l0.2-0.5
C71.3,493,70.9,496.7,71,500.9z M90.6,445.9c-7,13.7-13.7,26.6-16.9,36c-0.9,2.7-1.5,4.9-1.8,6.8c-0.6,0-1.3,0-1.9,0
c6.1-19.9,14.8-34.2,21.8-45.1C91.4,444.4,91,445.2,90.6,445.9z M113,394.3c-1.7,8.7-6.7,20.1-12.7,32.3c-0.1,0.1-0.1,0.2-0.2,0.4
c-0.1,0.2-0.2,0.4-0.3,0.6c-1.7,3.4-4.3,7.4-7.2,11.9c-7.4,11.3-17.4,26.8-24.2,49.1c-0.8-0.1-1.6-0.1-2.4-0.2
c3.1-9.2,6.2-20.6,8.9-30.7c3-11.3,5.9-21.9,8.2-27.2c2.7-5.8,5.3-10.8,7.6-15.5c6.4-12.6,12-23.5,16.4-41.1
c1.9,3.2,4.4,6.1,7.7,7.7C114.5,385.4,114,389.6,113,394.3z M115,380c-8.9-5.1-11.8-21.8-11.8-22c-0.1-0.3-0.3-0.6-0.7-0.6
s-0.7,0.2-0.8,0.5c-1.3,3.7-6.8,7.2-9.2,8.6c-0.2-9.2-2.6-16.5-3.1-18c0.4-1.4,0.5-4.6,0.6-9.5c0.2-7.2,0.5-18,2.1-30.4
c1.6-11.8,5.4-25.8,8.2-36c0.5-2,1-3.8,1.4-5.3c0.5-2,1-3.8,1.3-5.6c7.7-1.8,14.1-7.3,20.9-13.2c7.8-6.7,15.8-13.7,26.6-15.6
c-0.4,1.8-0.7,3.7-0.8,5.8c-1.6,8.3-10.9,34.1-17.8,49.4c-3.5,7.7-8.7,17.7-13.4,26.5c-5.2,9.9-9.7,18.5-11.1,22.4
c-1.8,5.3-2.6,15.7,1,24.5c1.4,3.5,3.6,6.7,6.6,9.3C115.1,373.5,115.2,376.6,115,380z M113.4,327.6c0.1,3.8,0.4,10.4,1.9,15.6
c-3,3.7-6.2,6.4-7.8,7.7c-0.4-5.3,0.3-10.3,1.4-13.5C109.6,335.4,111.3,331.9,113.4,327.6z M114.9,358.2c-0.3,2.5-0.1,5.4,0,8.8
c0,0.5,0.1,1,0.1,1.5c-2.1-2.1-3.7-4.6-4.9-7.2c2.2-2.3,4-4.4,5.5-6.2C115.2,356.3,114.9,357.4,114.9,358.2z M117.1,350.2
c-1.5,2.6-4.1,5.8-7.7,9.5c-0.8-2.3-1.4-4.7-1.7-7.1c1.1-0.8,4.6-3.6,8.1-7.8c0.5,1.4,1.1,2.7,1.9,3.7
C117.5,349.1,117.3,349.7,117.1,350.2z M135.6,302.2c-2.3,7.3-6.7,18.1-10.9,28.5c-2.3,5.8-4.6,11.4-6.3,16.1
c-0.6-1-1.1-2.3-1.5-3.6v-0.1c-2-6.9-1.9-17-1.9-18.4c1.5-2.9,3.2-6.1,4.9-9.4c4.6-8.8,9.9-18.8,13.4-26.5
c5.7-12.6,13.1-32.3,16.4-43.7c0,0.2,0,0.3,0,0.5c0.3,4.5,1,9.1,2.2,13.4c-1.2,3.6-5.4,12.4-5.4,12.5
C146.4,271.6,140.5,286.4,135.6,302.2z M157.8,313.3c-0.6-2.6-1.1-5.3-1.6-7.9c-3.5-18.1-7.8-31.5-8.4-33.6
c0.7-1.3,3.2-6.7,4.7-10.4c0.7,2.1,1.4,4.2,2.3,6.1c0.1,0.2,0.2,0.4,0.3,0.6c-2.2,3.2-2.4,8.1-0.6,12.9c0.6,1.6,1.4,3.1,2.4,4.5
c-1.7,2.7-1.9,5.8-0.6,8.9c0.6,1.3,1.4,2.5,2.4,3.7c-1.2,2.2-1.3,5.9-0.2,9.7c0.2,0.8,0.5,1.5,0.8,2.2
C158.9,310.5,158.3,311.6,157.8,313.3z M159.2,319c-0.5-3.8,0.4-6.1,1-7.3c1.6,2.8,3.7,4.9,6.3,6c-0.2,1.5-0.1,3.1,0.3,4.9
c-4.1-1.4-6.1-1.5-7.1-1.2C159.5,320.5,159.4,319.8,159.2,319z M160.4,324.9c-0.2-0.7-0.3-1.4-0.5-2c0.9-0.2,2.9-0.1,7.5,1.6
c1.4,0.5,3.1,1.2,5.1,2.1c13.7,6.1,20.9,12.8,20.9,19.3c0.1,6.7-1.6,8.9-2.4,9.6c-2.3-1.8-5.4-2.7-8.6-3.6
c-1.9-0.6-3.9-1.1-5.8-1.9c-7-2.8-11.8-7.9-12.7-8.8C163,336.4,161.7,330.8,160.4,324.9z M164.5,343.8c2.2,2.1,6.2,5.4,11.6,7.6
c2,0.8,4,1.4,6,2c6.4,1.9,11.5,3.4,11.5,11.8c-0.1,3.8-1.1,6.2-3,7c-5.9,2.6-20.2-9-25.5-14c0.2-1.3,0.4-2.5,0.4-3.7
C165.6,351.8,165.2,348.1,164.5,343.8z M150.2,402.2c3.1-9.1,5.8-16.7,8.1-22.8c3.1-8.5,5.3-14.5,6.4-19.5
c3.6,3.3,16.2,14.2,24,14.2c0.9,0,1.7-0.1,2.5-0.5c0.6-0.3,1.1-0.6,1.6-1.1v0.1c0.4,5.5,1.4,18.3,0.9,30.2
c-0.6,14.4-3.1,22.7-7.3,24c-5.2,1.6-10.3-2.6-16.1-7.6c-6.1-5.2-13-11-21.9-11.8C149,405.7,149.6,403.9,150.2,402.2z M146.3,413.6
c3.8,0.1,9,3.2,14.6,7.6c-3.5,13.4-25.2,65.2-31,78.9C129.5,463.5,138.2,437,146.3,413.6z M161.6,881.1c0.4-1.3,0.7-2.9,0.8-4.7
c0.5-5.9-1.1-13.4-2.7-21.3c-0.4-2.1-0.9-4.3-1.3-6.5c-0.9-5-2-10.3-3.1-15.9c-4.5-22.1-10-49.6-12.3-83.2
c-0.9-13,0.4-23.8,2.1-32.3c2.5,16.2,12.3,78.3,20.4,124.3l0.5,40.1C164.1,881.6,162.5,881.3,161.6,881.1z M170,881.4
c-0.8,0.1-1.6,0.2-2.5,0.2l-0.4-30.7c2.1,11.9,4.1,22.3,5.6,29.9C171.9,881,170.9,881.2,170,881.4z M149.1,702.1
c0.8-2.5,1.3-4.3,1.4-5.4c0.3-2.2,0-6.5-0.3-11.9c2,4,4.7,6.7,7.4,9.2c2.9,2.8,6,5.7,8,10.6c3.3,8.1,3.7,25.5,4.3,49.5
c0.2,8.3,0.4,17.7,0.8,27.9c0.8,21.3,2.9,47.4,4.4,66.5c1.2,15.5,2.3,28.8,1.9,30.3c-0.4,0.5-1.4,1-2.7,1.5
c-8.5-41-27.5-162.5-28.2-167.2C147.1,708.7,148.2,705,149.1,702.1z M161.2,647.3c-0.9,0.6-1.8,0.7-2.8,0.4
c-4.1-1.2-7.9-9.1-8.4-10.2c-0.1-5.3-0.6-11.7-1.6-20.1c-0.6-5-2.5-13.7-4.8-24.6c-5.1-23.8-12.8-59.5-13.7-88.9
c0.4-1,2.2-5.1,4.6-11c2,31.8,7,64.6,13.8,90.5c2.6,9.8,8.5,30,15.3,37.9C164.5,627.7,165.8,644.3,161.2,647.3z M178.1,610
c-1.1,3.7-4.7,13-9,13c-4.3,0-11.6-10.5-19.3-40c-7-26.7-12.1-60.7-13.9-93.3c4.7-11.3,11.4-27.5,16.9-41.5
c3.8,18.7,14.9,50.7,26.6,84.6c2.9,8.4,5.8,16.8,8.7,25.1c-4.7,10.6-8.8,32.3-10,51.6C178.1,609.7,178.1,609.8,178.1,610z
M206.3,616c0.1,1.4,0.3,2.8,0.4,4.1c0.4,4.5,0.7,8.9,0.8,13.7c-0.7,1.6-7.1,15.1-14.4,17.7c-1.8,0.6-3.5,0.5-5.1-0.3
c-6.6-3.4-9.6-18.6-8.3-41.6c1.1-18.2,4.8-38.6,9.1-49.7C195.9,580.6,202.3,600.4,206.3,616z M206.1,593.4
c-0.4,5.7-0.4,10.7-0.2,15.2c-6.1-21.6-15.7-49.4-25.1-76.4c-12.2-35.3-23.8-68.6-27-86.7c3.8-9.8,7-18.4,8.3-23.1
c1.7,1.4,3.4,2.9,5.1,4.5c-2.5,10.9-2.2,22.7-0.2,34.7c0.8,4.9,1.9,9.8,3.2,14.7c5.3,20.2,14.3,40.6,22.2,58.6
c8.1,18.4,15,34.2,15.8,44.7C207.2,584.3,206.4,588.8,206.1,593.4z M197.5,542.7c-1.2-2.7-2.4-5.5-3.7-8.4
c-6.1-13.8-12.7-29-17.9-44.5c13.1-9.4,17-28.5,17.9-34.5c1.4,1.8,2.7,3.6,4,5.4c2,2.6,4,5.3,6,7.9c0.4,1.5,3.7,15.2,5.4,32.8
c0.2,1.7,0.3,3.5,0.5,5.4C208.4,531.3,200,540.5,197.5,542.7z M210.2,516.9c0.3,13.9-0.9,29.6-5.7,42.9c-1.8-4.9-4-10.1-6.4-15.7
C200.1,542.5,207.3,535.6,210.2,516.9z M175.4,488.3c-1.3-4-2.5-7.9-3.6-11.9c11.6-9.1,15.8-22.9,16.9-27.6
c1.3,1.5,2.6,3.1,3.8,4.8C192.2,456.4,189,478.1,175.4,488.3z M171.4,474.8c-1.1-4.5-2.1-8.9-2.8-13.3c0.4-1.6,4.5-15.4,11.5-22
c2.2,2.3,4.2,4.4,6,6.3c0.5,0.5,1,1.1,1.5,1.6C187,449.8,183.5,465,171.4,474.8z M168,458c-1.4-10.4-1.5-20.6,0.5-30
c3.6,3.4,7.2,7,10.4,10.3C173.4,443.6,169.7,453.1,168,458z M219.9,525c-1.5,16.5-5.1,29.6-8.2,41.3c-0.9,3.2-1.7,6.2-2.4,9.2
c-0.7-4-2.1-8.5-3.9-13.4c6.7-17.3,7.1-38.8,5.9-55.4c0,0,0,0,0-0.1c-0.1-1.9-0.3-3.7-0.5-5.4c-1.3-13.4-3.5-24.5-4.7-29.8
c4.3,5.2,8.7,9.8,13.4,12.7C219.9,486.4,222,501.1,219.9,525z M242.2,575.5c-0.7-3-1.5-6.1-2.4-9.2c-3.2-11.6-6.8-24.8-8.2-41.3
c-2.1-23.8,0-38.5,0.4-40.9c4.7-2.9,9.2-7.5,13.4-12.7c-1.2,5.3-3.4,16.4-4.7,29.8c-0.2,1.7-0.3,3.5-0.5,5.4c0,0,0,0,0,0.1
c-1.2,16.6-0.8,38.1,5.9,55.4C244.3,567.1,243,571.6,242.2,575.5z M282.9,461.4c-0.7,4.4-1.7,8.8-2.8,13.3
c-12.1-9.8-15.5-24.9-16-27.4c0.5-0.5,1-1.1,1.5-1.6c1.6-1.8,3.6-4,5.8-6.3C278.4,446,282.4,459.8,282.9,461.4z M272.5,438.3
c3.2-3.4,6.8-7,10.4-10.3c2,9.5,2,19.6,0.5,30C281.8,453.1,278.1,443.6,272.5,438.3z M279.6,476.4c-1.1,4-2.3,7.9-3.6,11.9
c-13.6-10.2-16.8-31.9-17.1-34.7c1.3-1.7,2.6-3.3,3.8-4.8C263.8,453.4,267.9,467.3,279.6,476.4z M275.5,489.8
c-5.2,15.4-11.8,30.6-17.9,44.5c-1.3,2.9-2.5,5.7-3.7,8.4c-2.5-2.2-10.9-11.3-12.2-36c0.1-1.8,0.3-3.6,0.5-5.4
c1.7-17.5,5-31.2,5.4-32.8c2-2.6,4-5.3,6-7.9c1.3-1.7,2.7-3.5,4-5.3C258.5,461.3,262.4,480.4,275.5,489.8z M253.4,544.1
c-2.5,5.6-4.7,10.9-6.5,15.7c-4.8-13.3-6-29-5.6-42.9C244.2,535.6,251.3,542.5,253.4,544.1z M243.1,579.5
c0.9-10.4,7.9-26.3,15.9-44.6c7.9-18,16.8-38.4,22.2-58.6c1.3-4.9,2.4-9.8,3.2-14.7c2-12.1,2.3-23.8-0.2-34.7
c1.7-1.6,3.4-3.1,5.1-4.5c1.3,4.8,4.5,13.3,8.3,23.1c-3.2,18.1-14.8,51.4-27,86.7c-9.4,27-19,54.8-25.1,76.4
c0.2-4.5,0.2-9.4-0.2-15.2C245,588.8,244.2,584.3,243.1,579.5z M263.4,651.3c-1.6,0.8-3.3,0.9-5.1,0.3
c-7.3-2.6-13.6-16.1-14.4-17.7c0.1-4.9,0.5-9.3,0.9-13.8c0.1-1.3,0.2-2.7,0.3-4c4-15.6,10.5-35.4,17.5-56.1
c4.3,11.1,8.1,31.4,9.1,49.7C273,632.7,270,647.9,263.4,651.3z M282.3,623c-4.3,0-7.9-9.3-9-13c0-0.2,0-0.3,0-0.5
c-1.1-19.3-5.3-41-9.9-51.8c2.8-8.3,5.8-16.8,8.7-25.1c11.7-33.8,22.8-65.9,26.6-84.6c5.5,14.1,12.2,30.2,16.9,41.5
c-1.8,32.7-6.9,66.7-13.9,93.3C293.9,612.5,286.6,623,282.3,623z M283.9,881.6c-0.8,0-1.7-0.1-2.5-0.3c-1-0.2-1.9-0.4-2.7-0.6
c1.6-7.6,3.5-18,5.6-29.9L283.9,881.6z M308.4,749.5c-2.3,33.6-7.8,61.1-12.3,83.2c-1.1,5.6-2.2,10.8-3.1,15.8
c-0.4,2.2-0.9,4.4-1.3,6.5c-1.6,7.9-3.1,15.4-2.7,21.3c0.1,1.8,0.5,3.4,0.8,4.8c-0.9,0.2-2.5,0.4-4.4,0.5l0.5-40.1
c8-46,17.8-108.1,20.4-124.3C308,725.7,309.3,736.5,308.4,749.5z M302.4,702.1c0.9,2.9,2,6.5,3.1,11
c-0.8,4.7-19.8,126.2-28.3,167.2c-1.4-0.5-2.4-1-2.7-1.5c-0.4-1.5,0.7-14.9,1.9-30.3c1.5-19.1,3.7-45.2,4.4-66.5
c0.4-10.2,0.6-19.6,0.8-27.9c0.6-24,1-41.4,4.3-49.5c2-4.9,5-7.8,8-10.6c2.7-2.5,5.4-5.2,7.4-9.2c-0.3,5.5-0.6,9.7-0.3,11.9
C301.1,697.8,301.7,699.6,302.4,702.1z M307.6,592.9c-2.4,10.9-4.2,19.5-4.8,24.6c-0.9,8.3-1.3,14.7-1.5,20
c-0.5,1.1-4.3,9-8.4,10.2c-1,0.3-1.9,0.1-2.8-0.4c-4.6-3-3.3-19.6-2.4-26c6.8-7.9,12.7-28.1,15.3-37.9
c6.8-25.9,11.8-58.7,13.8-90.5c2.5,5.9,4.2,10,4.6,11C320.5,533.4,312.8,569,307.6,592.9z M321.6,500.1
c-5.8-13.6-27.5-65.6-31-78.9c5.5-4.4,10.7-7.5,14.6-7.6C313.2,437,322,463.5,321.6,500.1z M304.4,411.4c0.1,0.2,0.2,0.5,0.2,0.7
c-4.2,0.3-9.6,3.6-15.4,8.2c-8.8,7-18.2,17.1-25,24.4c-4.1,4.4-8.1,9.8-12,15c-6.8,9.1-13.7,18.6-21.4,23.1c-0.4,0.2-3,1.1-5.2,1.1
s-4.8-1-5.2-1.1c-7.7-4.6-14.7-14-21.4-23.1c-3.9-5.2-7.9-10.7-12-15c-6.8-7.2-16.2-17.3-24.9-24.4c-5.7-4.6-11.1-7.9-15.4-8.2
c0.1-0.2,0.2-0.5,0.2-0.7c0.3-0.8,0.6-1.6,0.8-2.4c8.6,0.6,15.4,6.3,21.4,11.4c5.2,4.4,9.8,8.3,14.7,8.3c0.9,0,1.9-0.1,2.8-0.4
c7.6-2.4,10.1-21.2,7.5-55.8c-0.1-0.9-0.1-1.6-0.2-2.1c0.6-1.4,0.9-3.2,0.9-5.4c0-4.1-1.1-6.7-2.9-8.6c1.1-1.1,2.9-3.8,2.9-10.7
c0-3.8-2-7.5-5.9-11c0.7-0.2,1.4-0.6,2.2-1.2c2.2-1.8,3.4-5.1,3.7-9.8c0.2-2.4-0.7-4.6-2.6-6.6c1.1-1.3,3.3-4.9,2.6-11.4
c-0.9-7.5-5-9.4-7.2-9.8c1.4-2.7,1.8-5.6,1.1-8.5c1.8-2.5,2.9-5.2,3.4-7.4c9.2-3.8,17.3-10,21.1-15.9c6.7-10.3,7.1-19,7.1-32.5
c0-11.5-2.3-25.8-13.2-28.5c-2.4-1.7-4.6-3.6-6.9-5.5c-7.6-6.5-16.1-13.8-35.5-18.5c1.5-0.3,3-0.8,4.6-1.4c10.7,0,19.7,2,27.7,4.2
c2.8,0.8,5.5,1.6,8.1,2.5c2,0.6,3.9,1.2,5.8,1.8c5.1,1.5,9.8,2.6,14.4,2.6c4.6,0,9.3-1.1,14.4-2.6c1.9-0.5,3.8-1.1,5.8-1.8
c2.6-0.8,5.3-1.7,8.1-2.5c8-2.3,17-4.2,27.7-4.2c1.6,0.6,3.1,1.1,4.6,1.4c-19.4,4.7-28,12-35.5,18.5c-2.3,2-4.5,3.9-6.9,5.5
c-10.9,2.7-13.2,17-13.2,28.5c0,13.4,0.3,22.1,7.1,32.5c3.9,6,12,12.2,21.1,15.9c0.5,2.2,1.6,4.9,3.4,7.4c-0.7,2.8-0.3,5.8,1.1,8.5
c-2.2,0.5-6.3,2.3-7.2,9.8c-0.8,6.5,1.5,10.1,2.6,11.4c-1.9,2-2.7,4.2-2.6,6.6c0.3,4.7,1.6,8,3.7,9.8c0.8,0.6,1.5,1,2.2,1.2
c-3.9,3.6-5.9,7.3-5.9,11c0,6.9,1.7,9.6,2.9,10.7c-1.8,1.9-2.9,4.5-2.9,8.6c0,2.2,0.3,4,0.9,5.4c0,0.4-0.1,1.1-0.2,2.1
c-2.7,34.6-0.2,53.4,7.5,55.8c1,0.3,1.9,0.4,2.8,0.4c4.9,0,9.5-3.9,14.7-8.3c6-5.1,12.8-10.9,21.4-11.4
C303.9,409.8,304.1,410.6,304.4,411.4z M286,354.5c0.1,1.2,0.2,2.4,0.4,3.7c-5.4,5-19.6,16.6-25.5,14c-1.9-0.8-2.9-3.2-2.9-7.1
c0-8.4,5.1-9.9,11.5-11.8c1.9-0.6,3.9-1.1,6-2c5.4-2.2,9.4-5.5,11.6-7.6C286.2,348.1,285.8,351.7,286,354.5z M293.2,379.5
c2.2,6.1,5,13.7,8.1,23c0.6,1.7,1.2,3.4,1.8,5.2c-9,0.5-15.8,6.4-21.9,11.6c-5.8,5-10.9,9.3-16.1,7.6c-4.2-1.3-6.8-9.6-7.3-24
c-0.5-11.9,0.5-24.7,0.9-30.2v-0.1c0.5,0.4,1,0.8,1.6,1.1c0.8,0.3,1.6,0.5,2.5,0.5c7.8,0,20.3-10.9,24-14.2
C287.9,365,290.1,371,293.2,379.5z M290.9,324.8c-1.3,5.9-2.6,11.4-3.5,16.3c-0.8,0.9-5.6,6-12.6,8.8c-1.9,0.8-3.9,1.4-5.8,1.9
c-3.2,0.9-6.3,1.8-8.6,3.6c-0.7-0.6-2.5-2.9-2.5-9.7c0-6.5,7.2-13.2,20.9-19.3c2-0.9,3.6-1.6,5.1-2.1c4.6-1.7,6.6-1.8,7.5-1.6
C291.2,323.4,291.1,324.1,290.9,324.8z M292.3,318.9c-0.2,0.8-0.3,1.5-0.5,2.3c-1.1-0.1-3.1-0.1-7.1,1.3c0.3-1.8,0.4-3.4,0.3-4.9
c2.6-1.1,4.7-3.2,6.3-6C291.9,312.8,292.8,315.2,292.3,318.9z M295,305.4c-0.4,2.7-1,5.3-1.5,7.9c-0.4-1.7-1.1-2.7-1.5-3.3
c0.3-0.7,0.6-1.4,0.8-2.2c1.1-3.8,1.1-7.5-0.2-9.7c1-1.1,1.8-2.3,2.4-3.7c1.4-3.1,1.2-6.2-0.6-8.9c1-1.4,1.8-2.9,2.4-4.5
c1.8-4.8,1.6-9.7-0.6-12.9c0.1-0.2,0.2-0.4,0.3-0.6c0.9-1.9,1.6-4,2.3-6.1c1.5,3.7,4,9.1,4.6,10.4
C302.7,273.9,298.5,287.3,295,305.4z M326.8,330.7c-4.2-10.5-8.5-21.3-10.8-28.6c-4.9-15.8-10.9-30.5-10.9-30.6
c-0.1-0.1-4.2-8.9-5.5-12.5c1.2-4.3,1.9-8.9,2.2-13.4c0-0.2,0-0.3,0-0.5c3.3,11.3,10.7,31.1,16.4,43.7c3.5,7.8,8.8,17.7,13.4,26.5
c1.7,3.3,3.4,6.4,4.9,9.4c0,1.4,0.1,11.5-1.9,18.4v0.1c-0.4,1.4-0.9,2.6-1.5,3.6C331.3,342.1,329.1,336.5,326.8,330.7z M338,327.6
c2.2,4.3,3.8,7.8,4.5,9.8c1.1,3.2,1.8,8.2,1.4,13.5c-1.6-1.3-4.8-4-7.8-7.7C337.6,338,337.9,331.5,338,327.6z M334.3,350.4
c-0.2-0.6-0.4-1.2-0.6-1.8c0.7-1,1.4-2.3,1.9-3.7c3.5,4.1,7,7,8.1,7.8c-0.3,2.4-0.8,4.8-1.7,7.1C338.5,356,335.9,352.9,334.3,350.4
z M341.4,361.3c-1.2,2.7-2.8,5.2-4.9,7.2c0-0.5,0.1-1,0.1-1.5c0.2-3.4,0.3-6.3,0-8.8c-0.1-0.8-0.3-1.8-0.7-3.1
C337.4,356.9,339.2,359,341.4,361.3z M336.4,370.7c3-2.5,5.1-5.8,6.6-9.3c1.2-2.9,1.9-6,2.3-9.1c0.7-6.1,0-11.9-1.2-15.4
c-1.3-3.9-5.8-12.5-11.1-22.4c-4.6-8.8-9.8-18.7-13.4-26.5c-7-15.3-16.3-41.1-17.8-49.4c-0.1-2.1-0.4-4-0.8-5.8
c10.7,1.9,18.8,8.9,26.6,15.6c6.8,5.8,13.2,11.4,20.9,13.2c0.4,1.8,0.8,3.7,1.3,5.6c0.4,1.6,0.9,3.4,1.4,5.3
c2.8,10.2,6.6,24.2,8.2,36c1.7,12.4,2,23.2,2.1,30.4c0.1,4.9,0.2,8.1,0.6,9.5c-0.5,1.5-2.9,8.8-3.1,18c-2.4-1.4-7.9-4.9-9.2-8.6
c-0.1-0.3-0.4-0.5-0.8-0.5c-0.3,0-0.6,0.3-0.7,0.6c0,0.2-2.9,16.9-11.9,21.9C336.2,376.5,336.3,373.4,336.4,370.7z M358.8,439.5
c-2.9-4.5-5.5-8.4-7.2-11.9c-0.1-0.2-0.2-0.4-0.3-0.6c0-0.1-0.1-0.2-0.1-0.3c-6-12.2-11-23.7-12.8-32.4c-1-4.7-1.5-8.9-1.8-12.7
c3.3-1.6,5.8-4.5,7.7-7.7c4.4,17.5,10,28.5,16.4,41.1c2.4,4.7,4.9,9.7,7.6,15.5c2.3,5.3,5.2,16,8.2,27.2
c2.7,10.1,5.8,21.5,8.9,30.7c-0.8,0.1-1.6,0.2-2.4,0.2C376.2,466.2,366.1,450.8,358.8,439.5z M359.7,443.6
c7,10.8,15.7,25.2,21.8,45.1c-0.6,0-1.2,0-1.9,0c-0.3-1.9-0.8-4.1-1.8-6.8c-3.2-9.4-9.9-22.3-16.9-36
C360.5,445.2,360.1,444.4,359.7,443.6z M390.2,566.1c-0.2-0.1-0.4-0.3-0.5-0.6c-0.4-1.2,0.5-3.7,2.4-7c1.3-2.2,7-9.9,9.3-12.9
c0.2,1.3,0.4,2.6,0.5,3.9c-2.1,2.6-9.2,11.5-10.4,13.9C391,564.3,390.6,565.2,390.2,566.1z M391,570.1c0,0,0,0.1-0.1,0.1
c0,0-0.1-0.1-0.1-0.1c-0.5-1.1,1.1-4.4,2-6.2c1-1.9,6.4-8.8,9.2-12.3c0,1.2-0.1,2.2-0.4,2.9c-0.6,1.5-3.3,5.2-5.7,8.4
c-2,2.7-3.8,5-4.4,6.2C391.4,569.5,391.2,569.8,391,570.1z M412.9,534c-0.7,2.4-1.4,6.7-2.1,10.8c-0.7,4.1-1.4,8.3-2.1,10.7
c-1,3.8-4.2,6.9-7.2,10c-0.6,0.7-1.3,1.3-1.9,1.9c-1,1-1.9,2.2-2.7,3.2c-1.8,2.2-3.5,4.3-4.5,3.7c-1.1-0.6-1-1,0.2-3.3
c0.2-0.3,0.4-0.7,0.6-1.1c0.5-1,2.3-3.4,4.2-5.9c2.6-3.4,5.3-7,6-8.7c1-2.6,0.2-7.7-0.4-11.8c-0.2-1-0.3-1.9-0.4-2.6
c-0.1-0.9-0.2-1.9-0.3-2.9c-0.3-2.9-0.7-6.2-1.2-8.5c-0.8-3.7-1.7-7.3-3.7-8.2c-0.5-0.2-1.3-0.4-2.2,0.1
c-5.9,3.2-3.2,13.8-2.3,17.2c0.9,3.4,1.8,8.4-1.3,11.9c-0.5,0.5-0.9,0.8-1.1,0.7c-0.8-0.2-1.6-2.4-2.4-6.4l-0.1-0.5
c-0.2-1.2-1-3.7-1.9-6.8c-1.2-4-2.6-9-3.6-13c-1.5-6.2-2.1-15-1.9-23.4c0.1-4.3-0.2-8.1-0.8-11.3l0.3,0.5c0.9,0,1.8,0,2.7-0.1
c8.2-0.5,14.1-2.5,18-4.5c1.4,3.7,3.1,7.4,4.7,10.9c2.5,5.5,5,10.8,6.5,16.3C414.8,523.7,413.8,530.7,412.9,534z"/>
</g>
</svg>
</template>