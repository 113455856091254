<template>
<svg version="1.1"  id="logo-besttraining" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 312.7 127" style="enable-background:new 0 0 312.7 127;" xml:space="preserve">
<path class="st0" d="M304.4,85.1H10.3c-4.1,0-7.4-3.3-7.4-7.4V48.9h308.9v28.8C311.8,81.8,308.5,85.1,304.4,85.1z"/>
<path class="st1" d="M46.8,24.4c0-0.1,0-0.2,0-0.3c0.5-0.5,0.9-1.4,1.6-1.5c1.1-0.3,2.3-0.2,3.5-0.3c0.3,0.2,0.7,0.3,1,0.5
c0.3,0.4,0.5,0.9,0.8,1.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.1,0.3-0.2,0.5c-0.3,0.5-0.5,1-0.8,1.5
c-0.2,0.2-0.5,0.3-0.7,0.5c-0.4,0.1-0.8,0.2-1.2,0.3c-0.4,0.3-0.8,0.6-1.2,0.9c0,0.1-0.1,0.2,0,0.2c-0.4,0.4-0.7,0.7-1.1,1.1
c-0.3,0.1-0.7,0.2-1,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.2-0.3,0.3-0.5,0.5c-0.3,0.3-0.5,0.7-0.8,1c-0.1,0-0.2,0-0.2,0.1
c-0.1,0.1-0.2,0.1-0.3,0.2l0,0c-0.3,0.2-0.7,0.5-1,0.7c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.3,0.3-0.5,0.4c-0.6,0-1.2,0-1.8,0.1
c-1-0.3-2.1-0.7-3.1-1c-0.6-1-1-0.4-1.4,0.2c-0.9,1.2-1.5,2.8-3.6,2.2c0,1,0.1,1.8,0.1,2.6c0.3,0.4,0.6,0.9,0.9,1.3
c0.3,0.4,0.6,0.8,1,1.1c0.2,0.6,0.4,1.1,0.6,1.7c0.1,0.1,0.1,0.2,0.2,0.3l0,0c0.1,0.4,0.2,0.8,0.3,1.2c0.2,0.5,0.5,1,0.7,1.5
c0.7,1,1.3,2,2,3c0.2,0.5,0.4,1,0.6,1.5c0.2,0.4,0.3,0.8,0.5,1.3c0.3,0.9,0.4,1.8,0.9,2.6c0.8,1.2,1.2,2.4,1,3.9
c-0.1,1.7-0.3,3.3-0.2,5c0,1.5,0.3,3,1.6,4.1c0.9,0.7,1.7,1.4,2.4,2.3c0.4,0.4,0.3,1.1,0.5,1.7c-0.7,0.2-1.4,0.6-1.9,0.4
c-1.7-0.6-3.2-1.4-4.8-2.2c-0.8-0.6-0.7-1.5-0.5-2.2c0.5-1.9,0-3.6-0.7-5.3c-0.3-0.8-0.5-1.8-0.3-2.6c0.4-1.9-1.8-5.1-4.3-4.4
c-0.5-0.4-1-0.9-1.5-1.3c-0.5-0.7-1-1.3-1.5-2c-0.3-0.2-0.6-0.4-1-0.6c0.1,0.3,0.1,0.5,0.2,0.7c-0.5,1.6-1.3,3.1-1.6,4.8
c-0.2,1.5-1.1,1.8-2.3,1.9c-0.4,0.2-1,0.3-1.2,0.7C24.2,59,23,60,21.4,60.6c-0.5,0.2-0.9,0.7-1.4,1c-2.2,0.8-3.3,2.7-4.1,4.7
c-0.2,0.4,0,1,0,1.4c0.1,0.8,0.4,1.6,0.6,2.4c0.2,1.1-0.1,2-1.2,2.4c-1.7-0.1-2.6-0.7-2.7-2.3c-0.1-2.2-0.3-4.5,1.2-6.5
c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.7-1.3,1.5-2.6,2.2-4c0.9-0.7,1.8-1.5,2.7-2.2
c0.4-0.3,1-0.7,1.1-1.1c0.5-1.9,0.8-3.8,1.1-5.8c0.4-1.6,0.8-3.1,1.2-4.7c0.2-0.8,0.2-1.7,0.3-2.5c0.1-1.5,0.5-2.9,1-4.3
c0.3-0.8-0.4-1.9-0.6-2.9c-0.1-0.5-0.2-1-0.3-1.5c-0.9-3.8-0.7-7.3,2.2-10.2c1.2-1.2,2.4-2.3,1.5-4.2c0.1-0.9,0.2-1.7,0.2-2.6
c0.8-1,1.6-2,3-2.1c1.2,0.4,2.3,0.7,3.5,1.1c0.8,0.8,1.2,1.8,1,2.9c-0.1,0.8-0.1,1.6-0.2,2.5c-0.1,0.2-0.1,0.5-0.2,0.7
c-0.3,0.7-0.2,1.3,0.5,1.5c0.7,0.2,1-0.2,1.1-0.9c0.1-0.4,0.5-0.8,0.7-1.2c1.8-1.3,5-0.5,5.8,1.5c0,0.2,0,0.3-0.1,0.5
c-0.5,0.7-1,1.4-1.5,2.2c-1.4,2.1-1.4,2.1,0.8,3.5c0.5-0.1,1-0.3,1.5-0.4C45.1,28.3,46.9,26.1,46.8,24.4z"/>
<path class="st1" d="M108.8,51c-0.3-0.3-0.6-0.7-0.9-1c-0.1,0-0.1-0.1-0.1-0.1c-1.3-1.4-2.9-2.3-4.8-2.8c-0.2,0-0.5-0.1-0.7-0.1
c-0.6,0-1.2-0.1-1.7-0.1c0-0.1,0-0.3,0-0.4c0.3-0.2,0.7-0.3,1-0.5c0.2-0.1,0.3-0.3,0.5-0.4c0.3-0.5,0.5-1,0.8-1.5
c-0.3-0.1-0.5-0.2-0.8-0.3c-0.3,0-0.7,0-1,0.1c-0.8,0.1-1.7,0.3-2.5,0.3c-0.7,0.1-1.5,0.1-2.3,0.1c-0.4,0-0.8,0.1-1.3,0.1
c-0.3-0.1-0.5-0.2-0.8-0.3c-0.6-0.3-1.2-0.5-1.7-0.8c-1.1-0.7-2.2-1.3-3.2-2c-0.4-0.3-0.8-0.6-1.3-0.9c-0.2,0-0.3,0-0.5,0
c0-0.1,0-0.1,0-0.2c-0.2-0.1-0.3-0.2-0.5-0.3c-0.7-0.4-1.5-0.8-2.2-1.3c-0.2-0.2-0.4-0.3-0.5-0.5c-0.3-1.1-0.5-2.1-0.8-3.2
c-0.1-0.2-0.3-0.3-0.4-0.5c-0.3-0.4-0.5-0.8-0.8-1.2c0-0.1,0-0.2,0-0.2c-0.7-0.9-1.4-1.9-2.2-2.7c-0.4-0.4-1.2-0.5-1.8-0.8
c-0.1,0.3-0.2,0.7-0.3,1c0.2,0.8,0.4,1.7,0.6,2.5c0,0.2-0.1,0.5-0.1,0.7c-0.1,0.4-0.2,0.8-0.2,1.2c-0.1,0.2-0.1,0.3-0.2,0.5
c-0.1,0.5-0.2,1-0.2,1.5c-0.3,0.3-0.7,0.7-1,1c-1.8,1.1-3.6,2.3-5.4,3.4c-1,0.6-1.9,1.4-2.8,2c-0.2,0.2-0.4,0.5-0.6,0.7
c-0.3,0.2-0.5,0.4-0.8,0.6c-0.7,0.6-1.1,1.3-0.6,2.2c0.1,0.2,0.2,0.3,0.3,0.5c0.2,0.3,0.5,0.7,0.7,1c0.6,0.6,1.2,1.2,1.8,1.7
c0.2,0.3,0.3,0.6,0.5,0.9c1.6,1.1,2.3,2.6,1.7,4.4c-0.1,0.4-1,0.8-1.5,0.8c-2.2,0.1-3.6,1.2-4.7,2.9c-0.5,0.7-0.8,1-1.7,0.3
c-1.3-0.9-2.8-1.3-4.4-0.9c-0.5,0.2-1.1,0.1-1.7,0.1c-1.2,0.2-2,1.2-2.2,2.7c-0.2,1.5,0.4,3,1.5,3.5c1,0,2.3,0.3,3.1-0.1
c2-1,3.6-0.9,5,0.8c0.1,0.1,0.2,0.2,0.3,0.3c1,0.3,2,0.6,3,0.8c0.8,0.2,1.6,0.2,2.4,0.4c2.3,0.4,4.5,0.9,6.7,1.4c0.8,0,1.5,0,2.3,0
c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0,0.8,0,1.2,0c0.2,0,0.3,0,0.5,0c0.2,0.1,0.3,0.1,0.5,0.2c1.9,0.8,3.7,1.4,5.8,0.6
c0.3-0.1,0.5-0.5,0.8-0.8c-0.2-0.2-0.5-0.4-0.7-0.6c-1.4-1.1-3-1.2-4.6-1.5c-3.1-0.6-6.2-1.3-9.4-2c-0.4-0.1-0.7-0.4-1.2-0.7
c0.4-0.3,0.6-0.5,0.9-0.6c2.7-1.5,5.4-2.8,8.6-2.4c0.8,0.1,1.8,0,2.7-0.8c-0.6-0.1-1.1,0-1.3-0.2c-0.3-0.2-0.6-0.8-0.5-1.1
c0.5-1.1,1.3-2.1,1.8-3.2c0.3-0.8,0.4-1.6,0.6-2.5c0.1-0.7,0.2-1.5,0.3-2.3c-0.1-1.2-0.2-2.3-0.3-3.5c-0.1-1.1-0.2-2.2-0.4-3.5
c0.4,0.1,0.9,0.2,1.3,0.3c0.4,0.2,0.8,0.6,1.3,0.7c2,0.6,4,1.2,6,1.7c0.2,0.7,0.5,1.5,0.7,2.2c0.1,0.3-0.1,0.7-0.2,0.8
c-0.6,0.4-1.3,0.8-1.9,1.2c-1,2.4-2.1,4.7-1.3,7.4c0.5,2.3,1.9,4,3.6,5.4c2.2,1.7,4.9,1.5,7.5,1.8c1.2,0.1,2.2-0.5,3.2-1
c2.8-1.3,4.7-3.4,5.2-6.4C110.4,55.6,110.2,53.2,108.8,51z M78.1,48.8c-1.7-1-4.1-0.8-6.1-2.5c3.7-1.1,4.8-4.8,7.5-6.7
c0.4-0.3,0.9-0.6,1.3-0.9c0.1,0.1,0.2,0.2,0.3,0.2C80.1,42.2,79.2,45.4,78.1,48.8z"/>
<path class="st1" d="M149.4,1.7c0,0.3,0,0.6,0,1c0.1,0.3,0.3,0.7,0.2,1c-1.1,2-2.2,3.9-3.4,5.8c-0.4,0.8-0.9,1.5-1.4,2.2
c-0.6,0.9-1.3,1.8-2,2.7c-0.4,0.4-0.9,0.8-1.1,1.2c-0.8,1.8-2.5,2.6-3.9,3.7c-0.3,0.6-0.6,1.3-0.9,1.9c-0.9-0.9-0.8-1.4,0.4-2.5
c0.1-1.6-0.1-3.2-1.1-4.6c-1-1.5-2.9-1.5-3.7,0c-0.7,1.3-1.1,2.8-1.6,4.2c-0.1,0.1,0.1,0.5,0.2,0.5c1.3,0.6,1.1,1.6,0.5,3
c-1.1-2.1-2.6-3.5-4.5-4.4c-2.4-3.6-4.8-7.2-7.2-10.8c-0.8-1.3-1.6-2.6-0.8-4.2c0-0.2,0-0.5,0-0.7c-1.3-1.5-2.7-1.6-4-0.2
c0.1,0.3,0.2,0.7,0.3,1c0.2,0.4,0.3,0.8,0.5,1.2c0.3,0.4,0.5,0.8,0.8,1.3c0.1,0.2,0.2,0.3,0.2,0.5c0.2,0.3,0.3,0.7,0.5,1
c0.1,0.4,0.3,0.8,0.4,1.2c0.4,2.6,2.2,4.4,3.6,6.4c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.8,0.9,1.6,1,2.4c0.2,1.7,1,3.1,2.1,4.3
c0.1,0,0.2,0,0.3,0c0.3,0.4,0.6,0.8,1,1.2c0.3,1.4,0.5,2.8,0.8,4.2c0.1,0.2,0.1,0.5,0.2,0.7c1.4,2.9,2.6,5.9,2.5,9.2
c0,1-0.5,1.9-0.7,2.9c-0.3,1.3-0.5,2.6-0.7,3.8c-0.1,0.6-0.2,1.1-0.3,1.7c-0.3,1.2-0.5,2.5-0.8,3.7c0,0.1,0,0.1,0,0.2
c0.2,2.3,0.1,4.8,0.8,6.9c0.5,1.4,2.2,2.4,3.4,3.5c0.3,0.3,0.9,0.5,1.3,0.7c-0.1,0.1-0.2,0.3-0.3,0.3c-2.5,0.6-3,2.2-1.5,4.3
c0.3,0.5,0.4,1.2,0.3,1.7c-0.1,1.3,0.2,2.4,1,3.4c0.3,0.3,0.7,0.6,1,0.9c0.7,0.6,1.5,0.5,1.7-0.4c0.2-0.7,0.5-0.9,1.2-1
c1.6-0.4,2-1.1,2.2-2.8c0.1-0.9,0.3-1.8,0.7-2.6c0.7-1.4,0.6-2.3-0.4-3.7c-0.2-0.2,0-0.9,0.3-1.2c1.2-1.6,2.7-3.1,3-5.3
c0.2-1.1,0.1-2.3,0.2-3.4c0-0.4,0-0.8,0-1.2c-0.3-0.8-0.5-1.5-0.8-2.3c-0.1-0.2-0.2-0.5-0.3-0.7c-0.4-1.3-0.9-2.7-1.2-4
c-0.2-1-0.3-2.1-0.5-3.2c-0.7-1.6-0.2-3.2,0.2-4.7c0-0.2,0-0.3,0-0.5c0.1-0.3,0.2-0.7,0.3-1c0-0.3,0-0.7,0-1c0.6-1.7,1.2-3.3,1.8-5
c0.1-0.5,0.2-1,0.3-1.5c0.1-0.5,0.3-1,0.4-1.5c0.1-0.2,0.2-0.3,0.3-0.5c0.3-0.3,0.5-0.7,0.8-1c0.4-0.5,0.9-1.1,1.3-1.6
c0-0.1,0.1-0.1,0.1-0.1c0.2-0.4,0.4-0.7,0.6-1.1c0.3-1.3,0.7-2.6,1-3.9c0.5-0.7,1.1-1.5,1.6-2.2c0.1-0.1,0.1-0.2,0.2-0.3
c0.6-0.8,1.1-1.7,1.7-2.5c0.1-0.2,0.2-0.3,0.2-0.5c0.3-1.3,0.5-2.5,0.8-3.8c0.1-0.4,0.5-0.8,0.7-1.2c0.5-0.8,1-1.7,1.5-2.5
c0.1-0.3,0.2-0.7,0.3-1C152.2,0.4,150.4,0.4,149.4,1.7z M136.4,54.9c-0.6,0.9-1.3,1.7-2,2.7c0,0-0.2-0.1-0.3-0.3
c-0.6-0.6-1.1-1.3-1.8-1.9c-0.7-0.6-1.1-1-0.6-2c0.8-1.5,1.3-3.1,2-4.7c0.2,0.1,0.4,0.1,0.4,0.1c0.9,1.7,1.9,3.4,2.7,5.1
C136.9,54.1,136.6,54.6,136.4,54.9z"/>
<path class="st1" d="M215,29.4c-0.2-0.2-0.4-0.4-0.6-0.5c-1.2,0-2.3,0-3.5,0c-0.6-0.2-1.2-0.5-1.8-0.7c-0.3-0.3-0.7-0.5-0.9-0.8
c-0.2-0.4-0.2-0.9-0.3-1.4c-0.2-0.5-0.3-1.1-0.5-1.6c-0.1-0.1-0.2-0.2-0.3-0.4c-0.6-0.4-1.2-0.9-1.7-1.3c-1.9-0.2-3.6,0.2-4.3,2.3
c-0.1,0.2-0.1,0.5-0.2,0.7c0,0.2,0,0.3,0,0.5c-0.1,0.6-0.2,1.2-0.3,1.7c-0.1,0.5-0.1,1-0.2,1.5c-0.6,0.1-1.2,0.1-1.8,0.2
c-0.1,0.4-0.2,0.9-0.2,1.3c-0.1-0.3-0.2-0.5-0.3-0.8c-1.2,0.2-2.3,0.3-3.5,0.5l-2.3,0c0,0,0,0,0,0c-1,0.1-2.7,0.3-3.8,0.5
c-1.9,0.1-3.7,0.3-5.6,0.4c-1,0.2-2.1,0.4-3.1,0.6l0,0l-5.4,0c-1.2,0.3-2.3,0.6-3.5,0.9l0,0c-0.5-0.3-1-0.5-1.5-0.8
c-0.5-0.1-1.3-0.1-1.4-0.5c-0.5-0.9-0.7-2-1.1-3c-0.9-1.4-2.1-2.3-4-2c-1,0.9-2,1.9-3,2.9c-0.3,1-0.5,2-0.8,2.9
c-0.2,0.8-0.8,1.1-1.7,0.9c-0.6,0.2-1.1,0.3-1.6,0.5c-0.2,0.2-0.5,0.4-0.9,0.8c0.5,0.3,0.8,0.5,1.2,0.7l2.8-0.2
c0.4,0.8,0.8,1.5,1.2,2.3c0.3,0.6,0.5,1.2,0.8,1.9c0.1,0.1,0.2,0.1,0.2,0.2l1.2,1.3c0.6,0.1,1.1,0.3,1.8,0.4
c0.8-0.3,1.6-0.6,2.5-0.9c0.2-0.2,0.4-0.4,0.7-0.6c0.4-0.9,0.7-1.8,1.1-2.8c0.5,0.6,1.1,1.2,1.6,1.7c0,0,0.1,0.1,0.1,0.1
c0.5,0.5,0.9,0.9,1.4,1.4c0.3,0.3,0.6,0.6,0.9,0.9l0.8,0.5c0.1,0.1,0.2,0.2,0.3,0.3c1,1.1,1.9,2.3,2.9,3.4c0.1,0,0.2,0,0.2,0.1
c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2,0,0.2,0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.3,0.2c1.1,0.3,1.4,1,1.3,2.1
c-0.1,1.1-0.1,2.1-0.1,3.2c0,0.1,0,0.2,0,0.3c0.1,0.2,0.3,0.5,0.4,0.7c0.5,1.2,1,2.3,1.4,3.4c-2.4-0.1-4.6-0.3-6.8-0.3
c-1.4,0-2.4,1.3-2.1,2.7c0.4,1.7,1.5,3,2.7,4.1c1.3,1.2,2.9,2.1,4.3,3.1c0.9,0.6,0.8,1.6-0.1,2.2c-0.7,0.5-1.5,0.9-2.2,1.4
c-0.3,0.2-0.4,0.5-0.6,0.8c0.3,0.2,0.7,0.4,1,0.4c1.3,0,2.6-0.1,3.8-0.2c1-0.1,2-0.2,3.1-0.3c0.8-2.7-1.8-3.9-2.9-5.9
c0.9,0,1.7,0,2.4,0c0.3,0,0.7,0,1,0c1.6,0.1,3.2,0.1,4.8,0.2c0.2,0.2,0.3,0.3,0.5,0.5c0,0,0,0,0,0c0.2,0.3,0.4,0.6,0.5,1
c0.1,0.7-0.1,4.4-0.1,5.2c0.9-0.1,1.5-0.1,2.2-0.2c-0.1-0.3,0-3.5,0-3.8c-0.1-0.1-0.1-0.1-0.1-0.2c-0.6-1.7,0-2.9,1.3-4
c1-0.8,1.2-1.9,1-3.2c-0.3-1.8-1.2-2.9-2.9-3.7c-0.4-0.2-0.8-0.5-1.2-0.9c-0.2-0.7-0.4-1.3-0.7-2c0.2-1,0.3-2.1,0.5-3.1
c0-0.3,0-0.5-0.1-0.8c0.5-1.2,1.1-2.6,1.6-3.8l0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.2c0.2-0.4,0.3-0.8,0.5-1.2c0.8-1.7,1.6-3.4,3.2-4.5
c0.1-0.1,0.3-0.2,0.4-0.2c0.9-1.1,1.7-2.3,2.6-3.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.2,0.2-0.4,0.4-0.6
c0.2-0.3,0.3-0.6,0.5-0.9c1,0,0.7,1,0.9,1.5l0.8,2c0,0,0.1,0.1,0.1,0.2l1.3,1.3c0.3,0.1,0.5,0.2,0.8,0.3c1.2,0,2.3-0.1,3.2-1.1
c0.4-1.2,0.9-2.5,1.3-3.7c0-0.6-0.4-1.4,0.7-1.5c0.7-0.9,1.7-1,2.8-1.1c0.5,0,1-0.1,1.5-0.1c0.3-0.1,0.7-0.1,1-0.2
c0.1-0.2,0.2-0.5,0.3-0.7C215,29.6,215,29.5,215,29.4z M198.5,31.9c-0.4,0.6-0.8,1.3-1.3,1.9c-1.3,1.4-2.6,2.9-4,4.2
c-1.4,1.4-3,2.6-4.5,3.9c-0.3-0.2-0.7-0.4-0.8-0.7c-0.6-1.1-1-2.2-1.5-3.3c-0.8-1.5-2.7-2.3-4.3-1.8c-1.3,0.4-1.7,1.6-1.8,2.8
c-0.1,1.1,0,2.3,0,3.5c-2-0.7-3.6-1.2-6-2c-1.6-1.5-3-2.8-4.7-4.3c-0.9-2.4,0.6-2.1,1.4-2.6c2.5-0.2,5-0.5,7.5-0.7
c1.5-0.1,3-0.2,4.6-0.3c2.6-0.2,5.2-0.5,7.8-0.7c1.7-0.1,3.4-0.2,5.1-0.3c0.9,0,1.7,0,2.6,0c0-0.3,0-0.5,0-0.8
C199.1,31.2,199.1,31.5,198.5,31.9z"/>
<path class="st1" d="M247.5,66.1c-0.2-0.3,0.2-0.8,0.1-1.2c-0.1-0.8-0.1-2.2-0.4-2.3c-1.8-0.6-1.9-2.2-2.2-3.5
c-0.8-3-1.5-5.9-4.3-7.7c-0.4-0.3-0.5-0.9-0.7-1.4l0-0.1c-0.1-0.1-0.1-0.3-0.2-0.4c-0.3-1-0.8-2,0.5-2.8c0.2-0.1,0.3-0.7,0.1-0.8
c-1.3-2-1.1-4.3-1.7-6.5c-0.3-0.9-0.5-1.8-0.8-2.8c-0.2-0.3-0.3-0.7-0.5-1c-1.5-2.3-1.4-2.7,0.5-5.3c0.9-1.2,1.7-2.4,2.6-3.6
c0.1-0.4,0.3-0.8,0.5-1.5c0.3,0.5,0.4,0.7,0.5,1c0.7,0.6,1.4,1.1,2.2,1.7c0,1.2,0,2.5,0,3.7c-0.1,0.3-0.2,0.5-0.2,0.8
c-0.2,2.9-0.3,5.8-0.5,8.7c-0.4,0.9-0.9,1.9-1.2,2.8c-0.1,0.4,0.1,0.9,0.2,1.4c0.6-0.1,1.4-0.1,1.8-0.5c0.3-0.4,0.4-1.2,0.3-1.8
c-0.1-0.6-0.5-1.3-0.8-1.9c0.3-0.9,0.6-1.8,0.9-2.8c0.2-1.5,0.3-3,0.5-4.5c0-0.1-0.1-0.2-0.1-0.3c0-1.4,0-2.8,0.1-4.2
c0.1-0.3,0.1-0.5,0.2-0.8c0-0.2,0-0.5,0-0.7c0.2-0.1,0.4-0.2,0.5-0.3c0-0.1,0-0.1,0.1-0.2c-0.2-0.2-0.4-0.3-0.7-0.5
c-0.1,0-0.1-0.1-0.1-0.1c-0.1-0.4-0.2-0.7-0.3-1.1c0.1,0,0.2,0,0.2,0c0-1,0-2,0.1-3c0-0.4,0.1-0.7,0.1-1.1c0-1.2,0.1-2.4,0.1-3.6
c-0.3-0.5-0.7-1-1-1.5c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.5-0.2-1-0.3-1.5c-0.3-0.3-0.7-0.5-1-0.8c-0.7-0.2-1.3-0.3-2-0.5
c-0.4,0.1-0.8,0.2-1.2,0.3c-0.7,0.9-1.5,0.1-2.3,0.2c0,0.2,0,0.3,0,0.5c0.2,0.2,0.3,0.3,0.5,0.5c0.1,0.1,0.1,0.2,0.2,0.3
c0.1,0.8-0.2,1.1-0.9,1c-0.5-0.2-1-0.3-1.5-0.5c-1.4-0.3-2.8-1-4.2-0.4c-0.1,0.1-0.2,0.2-0.2,0.2c0,0.2-0.1,0.5-0.1,0.7
c0,0.2,0.1,0.5,0.1,0.7c0.1,0.2,0.1,0.4,0.2,0.5c0.2,0.4,0.4,0.8,0.5,1.2c0.4,0.9,0.8,1.8,1.3,2.7c0.2,0.6,0.1,0.8-0.8,1.1
c-1.1,1.7-1.1,3.8-1.8,5.7c-0.1,0.2-0.1,0.3-0.2,0.5c0,0.5-0.1,1-0.1,1.5c-0.1,1.7-0.1,3.3-0.2,5c-0.3,0.7-0.6,1.3-0.9,2
c-0.2,0.4-0.3,0.8-0.5,1.2c-0.1,0.3-0.2,0.7-0.3,1c-0.2,0.5-0.3,1-0.5,1.5c-0.1,1.6-1.1,3-0.5,4.7c0.1,0.3,0.2,0.8,0.4,0.8
c1.6,0.6,1.3,2.2,1.7,3.4c0.4,1.2,0.9,2.3,1.2,3.7c0.2,1.2,0,2.3,0,3.5c0,0.5-0.5,1.1-0.4,1.6c0.2,1.4,0.8,2.8,0.9,4.3
c0.2,1.6,0.3,3.2,0.1,4.8c-0.3,2.5,0.1,3.2,2.6,3.5c2.3-0.1,2.5-0.3,1.3-2.4c-0.8-1.4-1.4-2.8-1.3-4.5c0.2-2.3,0.4-4.6,0.5-6.9
c0.1-2.4,0-4.8,0-7.2c-0.1-0.3-0.4-0.7-0.4-1c0-0.5,0.2-0.9,0.3-1.4c0.6,0.2,1.3,0.2,1.6,0.5c0.4,0.4,0.4,1.1,0.6,1.7
c2.1,3.3,4.2,6.6,6.3,9.9c0.8,1.3,1.6,2.7,2,4.2c0.4,1.7,0.4,3.5,0.6,5.3c0.1,1.3,1,1.9,2.2,1.4C247.8,69.2,248.4,67.8,247.5,66.1z
M242.6,22c0.1-0.3,0.9-0.4,1.4-0.6c-0.1,0.9-0.1,1.7-0.2,2.6c-0.1,0.5-0.2,1-0.3,1.4C242.4,24.5,242.3,23.3,242.6,22z M241,20
c0.3,0.1,0.7,0.4,0.7,0.4c-0.2,0.5-0.5,1-0.7,1.5c-0.2-0.1-0.5-0.2-0.7-0.3C240.5,21,240.8,20.5,241,20z"/>
<path class="st1" d="M299.1,68c-1.7-1.4-3.3-2.9-5-4.4c-0.6-1-1.1-2-1.8-2.9c-0.6-0.8-1.4-1.5-2.3-2c-0.9-0.5-1.5-0.9-1.8-2.1
c-0.3-1.7-1-3.4-1.6-5.1c-0.8-1.7-1.5-3.3-2.3-5l1-1.3c0-0.8,0-1.6,0.1-2.5c-0.1-0.4-0.2-0.8-0.3-1.2c0-0.1,0-0.2,0-0.3
c-0.2-0.7-0.3-1.3-0.5-2c-0.1-0.5-0.2-1-0.3-1.5c-0.2-0.5-0.5-1.1-0.7-1.6l-0.3-2.1c0-0.3,0-0.7,0-1c0-0.5,0-1,0-1.5
c0.3-1.9,0.7-3.8,1-5.7c0-0.2-0.1-0.5-0.1-0.7c0.1-0.5,0.3-0.9,0.2-1.4c0-0.9,0.1-1.9-0.4-2.5c-0.6-1-1.7-1.6-2.6-2.5
c-1.2-1.2-2.5-2.4-3.5-3.7c-0.4-0.6-0.7-1.2-1.5-1.3c-0.7-0.1-0.8-0.5-0.7-1.1c1.5-1.1,2.9-2.2,4.4-3.3c-1.7,0-3.4,0.1-5.1,0.1
l-3.7,5.1c0.6,0.2,0.5,0.5,0.5,0.8c0.1,0.9,0.2,1.9,0.3,2.8l2.4-2.6c0.3,0.3,0.5,0.7,0.8,1c0.7,0.5,1.3,1,2,1.5
c0.3,0.3,0.6,0.5,0.9,0.8c-0.4,0.1-0.8,0.3-1.2,0.4c-1.3-0.1-2,0.6-2.3,1.8c-0.4,1.8-0.6,3.6-0.2,5.4c0.2,0.3,0.4,0.6,0.7,1
c-1.1,0.3-2,0.5-2.9,0.7c-1.2,0.8-2.5,1.5-3.6,2.5c-1.1,0.9-1.9,2.1-2.9,3.3c-0.7-0.7-1.4-1.3-2-1.9c-0.4,0-0.7,0-1.1,0.1l0.1,1.7
c0,0,0,0,0,0c0.1,0.8,0.2,1.6,0.3,2.4c0.5,0.1,0.7,0.2,0.6,0.4c0.3,0.3,0.7,0.7,1,1c0.2,0.1,0.3,0.2,0.5,0.3c0.3,0.2,0.7,0.5,1,0.7
c1.1-0.7,2.3-1.4,3.4-2c0.3-0.2,0.7-0.3,1.3-0.6c0.2,1.5,0.3,2.7,0.5,3.9c0.3,0.9,0.5,1.8,0.8,2.7c0.3,1.2,0.8,2.3-0.4,3.4
c-0.2,0.2-0.3,0.5-0.3,0.8c0.1,0.9,0.3,1.9-0.9,2.4c-0.1,0.1,0,0.6-0.1,0.9c-0.1,0.4-0.3,0.9-0.5,1.3c-0.6,1.9-1.3,3.8-1.6,5.7
c-0.2,1.4-1.2,2.8-0.4,4.3c0.8,1.4,1.7,2.7,2.5,4c0.2,0.4,0.3,0.9,0.2,1.3c-0.7,1.7-1.5,3.4-2.2,5.1c-0.1,0.4,0.2,1.1,0.6,1.4
c1.1,0.9,3.4-0.4,3.5-2c0.1-1.4,0.7-2.2,1.8-2.8c1.1-1.6,1-2.4-0.3-3.3c-1.5-1-2.3-3.4-1.8-5.2c0.6-0.2,1.3-0.2,1.8-0.5
c1.1-0.9,2.1-1.9,3.1-2.8c0.5-0.4,1-0.8,1.4-1.1c0.5-0.2,1.3-0.6,1.6-0.4c1.1,0.7,2,1.6,3,2.5c1,1.2,2,2.3,3,3.5
c0.8,0.5,1.7,0.9,2.4,1.4c0.7,0.5,1.2,1.3,1.9,1.7c1.4,0.9,2.3,2,3,3.5c1,1.2,2,2.4,3.1,3.5c0.5,0.5,1.2,0.7,1.8,1.1
c0.8-0.5,1.7-0.8,1.8-2C300.5,69.2,299.9,68.6,299.1,68z M279.6,27.2c-0.1,0-0.3-0.1-0.4-0.1c0-0.4-0.1-0.9,0.2-1.2
c0.9-1,0.9-2.2,1.1-3.4c0-0.3,0.5-0.4,0.7-0.7c0.1,0.5,0.3,1.1,0.3,1.6c0,0.5-0.3,1-0.5,1.4h0C280.5,25.7,280,26.4,279.6,27.2z"/>
<path class="st1" d="M228.3,118.2c0.5-0.8,0.3-1.5,0.3-2.3c-0.1-6.1-0.2-12.3-0.4-18.4c0-0.9,0.2-1.2,1.2-1.2
c1.9,0.1,3.8,0.1,5.7,0.1c0.8,0,1.1,0.3,1.1,1.2c0,8.7,0,17.4,0,26.1c0,0.9-0.3,1-1.1,1c-3.9,0-7.8,0-11.7,0c-0.7,0-1-0.3-1.2-0.8
c-2.5-6.6-5-13.2-7.5-19.8c-0.1-0.2-0.1-0.5-0.4-0.6c-0.4,0.3-0.2,0.7-0.2,1.1c0.1,6.4,0.2,12.7,0.4,19.1c0,0.8-0.3,1.1-1.1,1.1
c-1.9,0-3.9-0.1-5.8-0.1c-0.7,0-0.9-0.2-0.9-0.9c0-8.8,0-17.7-0.1-26.5c0-0.7,0.3-0.9,0.9-0.9c3.8,0,7.6,0,11.5,0
c0.7,0,1.1,0.4,1.4,1.1c2.4,6.5,4.9,12.9,7.4,19.4C227.9,117.3,228.2,117.7,228.3,118.2z"/>
<path class="st1" d="M272.9,118.4c0-1.3,0.1-2.5,0-3.7c-0.4-5.7-0.3-11.5-0.6-17.2c0-0.8,0.2-1.1,1-1.1c2,0,4,0,6,0
c0.8,0,0.9,0.4,0.9,1.1c0,8.7,0,17.4,0,26.1c0,0.9-0.3,1.2-1.2,1.2c-3.7,0-7.4-0.1-11.1,0c-0.9,0-1.4-0.3-1.7-1.2
c-2.4-6.4-4.9-12.8-7.3-19.3c-0.1-0.3-0.2-0.7-0.6-0.9c-0.4,0.2-0.2,0.6-0.2,0.8c0.3,6.5,0.4,12.9,0.5,19.4c0,1.1-0.4,1.2-1.3,1.2
c-1.8,0-3.6-0.1-5.4-0.1c-1,0-1.2-0.3-1.2-1.2c0-8,0-16.1,0-24.1c0-3.1,0-3.1,3.2-3.1c2.9,0,5.9,0,8.8,0c0.9,0,1.4,0.3,1.7,1.2
c2.4,6.4,4.8,12.8,7.2,19.2C272,117.3,272.2,118,272.9,118.4z"/>
<path class="st1" d="M24.2,110.2c0.7,0.5,1.3,0.6,1.9,0.7c1.6,0.4,2.6,1.5,2.9,3c0.6,2.3,0.6,4.7-0.2,6.9c-0.8,2.4-2.7,3.8-5.3,3.8
c-6.1,0-12.3,0-18.4,0.1c-0.6,0-0.8-0.2-0.8-0.8c0-1.2,0-2.3,0-3.5c-0.1-7.6-0.1-15.2-0.3-22.8c0-1.1,0.3-1.4,1.4-1.3
c4.7,0.1,9.5,0,14.2,0c1.2,0,2.3,0.1,3.5,0.2c3.6,0.4,5.4,2.1,5.8,5.6c0.1,1.1,0.1,2.3,0,3.4C28.6,108.2,27.3,109.9,24.2,110.2z
M15.7,118.7C15.7,118.7,15.7,118.7,15.7,118.7c1.1,0,2.2,0,3.2-0.1c1.7-0.1,2.5-1,2.5-2.9c0-1.7-0.7-2.4-2.5-2.5
c-2-0.1-4.1,0-6.1-0.1c-0.7,0-0.8,0.3-0.8,0.9c0,1.3,0.1,2.5,0,3.8c0,0.8,0.3,0.9,1,0.9C13.8,118.7,14.8,118.7,15.7,118.7z
M15.8,107.8C15.8,107.7,15.8,107.7,15.8,107.8c1,0,2,0,3-0.1c1.5-0.1,2.1-0.7,2.1-2.1c0.1-2.3-0.2-2.8-2-2.9
c-2.1-0.1-4.2,0-6.3-0.1c-0.6,0-0.7,0.3-0.7,0.8c0,1.2,0,2.3,0,3.5c0,0.7,0.3,0.8,0.9,0.8C13.8,107.8,14.8,107.8,15.8,107.8z"/>
<path class="st1" d="M157.3,114c0.5,0.4,1.1,0.3,1.6,0.5c2,0.6,3.3,2,3.4,4.1c0.1,1.8,0,3.6,0,5.3c0,0.6-0.2,0.8-0.8,0.8
c-2.2,0-4.3,0-6.5,0c-0.5,0-0.7-0.2-0.7-0.7c0-1.2,0-2.3,0-3.5c-0.1-1.7-1.1-2.8-2.9-2.9c-1.8-0.1-3.6-0.1-5.4-0.1
c-0.8,0-1,0.4-1,1.1c0,1.7,0,3.5,0,5.2c0,0.7-0.2,0.9-0.9,0.9c-2,0-4.1-0.1-6.1,0c-0.8,0-0.8-0.4-0.8-1c0-6.7,0-13.5,0-20.2
c0-2,0.1-4,0-5.9c0-0.9,0.2-1.2,1.2-1.2c4.7,0,9.5,0,14.2,0c1.5,0,3,0.2,4.4,0.5c2.9,0.6,4.7,2.3,5.2,5.2c0.4,2.2,0.3,4.5,0.1,6.8
c-0.2,2.5-1.7,4-4.1,4.8C158.1,113.6,157.7,113.6,157.3,114z M149.1,110.6c1.1,0,2.2,0.1,3.4,0c1.4-0.1,2-0.7,2.1-2.1c0-1,0-2,0-3
c-0.1-1.5-0.8-2.3-2.4-2.4c-2-0.1-4,0-6-0.1c-0.6,0-0.9,0.3-0.9,0.9c0,1,0,2,0,3c0.1,1.2-0.5,2.7,0.3,3.5
C146.4,111.2,147.9,110.6,149.1,110.6z"/>
<path class="st1" d="M295.9,124.8c-1.8-0.1-4.3,0.1-6.7-0.4c-3.4-0.7-5.4-2.8-5.8-6c-0.7-5-0.7-10.1,0-15.2c0.5-3.3,2.6-5.5,6-6.4
c4-1,8-0.7,12-0.6c1.2,0,2.3,0.2,3.4,0.5c3.4,1,5.7,4.6,5.1,8c-0.1,0.4-0.3,0.5-0.7,0.5c-2.2,0-4.4,0-6.6,0c-0.6,0-0.8-0.3-0.9-0.8
c-0.3-1.2-1.2-1.7-2.3-1.9c-1.8-0.3-3.6-0.2-5.4,0.2c-1.6,0.3-2.6,1.4-2.7,2.9c-0.4,3.3-0.4,6.6,0,9.9c0.2,1.8,1.5,3,3.5,3.2
c1.4,0.2,2.8,0.2,4.2,0c2.1-0.3,3.3-2,3-4c-0.1-0.6-0.4-0.6-0.9-0.6c-1.2,0-2.3,0-3.5,0c-0.8,0-1.2-0.2-1.2-1.1
c0.1-1.1,0.1-2.2,0-3.3c0-0.7,0.2-1.1,1-1.1c3.9,0,7.8,0.1,11.7,0.1c0.5,0,0.8,0.2,0.8,0.7c-0.1,3.1,0.2,6.2-0.4,9.3
c-0.7,3.9-3.1,5.8-7.3,6C300.4,124.9,298.5,124.8,295.9,124.8z"/>
<path class="st1" d="M68.4,124.8c-2.3,0-4.1,0-6-0.2c-3.1-0.3-5.2-2-5.8-5.1c-0.2-1-0.2-2-0.4-3c-0.1-0.6,0.2-0.9,0.8-0.9
c2,0,4.1,0,6.1,0c0.7,0,0.7,0.4,0.8,0.9c0.4,1.7,0.9,2.1,2.7,2.3c1.6,0.1,3.2,0.2,4.8-0.1c1.6-0.2,2.1-0.8,2.1-2.3
c0-1.8-0.3-2.3-2.1-2.5c-2.8-0.3-5.5-0.5-8.3-0.7c-1.1-0.1-2.2-0.4-3.3-0.8c-1.8-0.7-2.8-2.1-3.3-3.9c-0.5-2.1-0.6-4.3-0.2-6.5
c0.6-3.4,2.5-5.2,6.4-5.7c4.1-0.5,8.2-0.5,12.3,0.2c4.8,0.8,5.6,4.5,5.6,8.3c0,0.6-0.5,0.5-0.8,0.5c-2,0-3.9,0-5.9,0
c-0.7,0-1-0.3-1.1-1c-0.1-0.9-0.6-1.6-1.6-1.7c-1.9-0.3-3.8-0.2-5.7,0.1c-1.1,0.2-1.5,0.9-1.5,1.9c0,1,0.1,2,1.3,2.3
c1.1,0.3,2.2,0.4,3.3,0.4c2.6,0.1,5.2,0.3,7.8,0.9c3.1,0.8,4.4,2.1,4.8,5.2c0.2,1.8,0.2,3.6,0,5.4c-0.4,3-2.8,5.1-6.3,5.6
C72.7,125,70.3,124.8,68.4,124.8z"/>
<path class="st1" d="M178.8,96.3c1.5,0,3.1,0,4.6,0c0.9,0,1.4,0.3,1.7,1.2c2.6,8.4,5.2,16.8,7.8,25.2c0.6,2,0.6,2-1.5,2
c-1.6,0-3.2-0.1-4.9,0c-0.8,0-1.1-0.2-1.3-1c-0.2-0.9-0.5-1.7-0.7-2.6c-0.1-0.7-0.5-0.9-1.2-0.9c-3.1,0-6.1,0-9.2,0
c-0.6,0-0.9,0.3-1,0.8c-0.2,0.9-0.5,1.9-0.7,2.8c-0.1,0.6-0.4,0.9-1.1,0.9c-2.2,0-4.4,0-6.6,0c-0.7,0-0.7-0.3-0.5-0.8
c2.2-7.1,4.3-14.1,6.5-21.2c0.5-1.7,1.1-3.5,1.6-5.2c0.2-0.9,0.7-1.1,1.5-1.1C175.5,96.3,177.2,96.3,178.8,96.3
C178.8,96.3,178.8,96.3,178.8,96.3z M178.8,102.5c-1.1,3.9-2.2,7.5-3.3,11.2c-0.2,0.8,0.3,0.8,0.8,0.8c1.7,0,3.5,0,5.2,0
c0.9,0,0.9-0.3,0.7-1c-0.8-2.5-1.5-5.1-2.3-7.6C179.6,104.8,179.4,103.7,178.8,102.5z"/>
<path class="st1" d="M31.9,110.5c0-4.4,0-8.7,0-13.1c0-0.9,0.3-1.1,1.1-1.1c6.4,0,12.7,0,19.1,0c0.9,0,1.3,0.3,1.2,1.2
c-0.1,1.3-0.1,2.6,0,3.9c0.1,0.9-0.3,1.3-1.3,1.3c-3.6,0-7.1,0-10.7,0c-1.2,0-1.7,0.3-1.5,1.5c0.1,0.8,0.1,1.7,0,2.5
c-0.1,0.8,0.2,1.1,1.1,1.1c3.2,0,6.5,0,9.7,0c1.8,0,1.8,0,1.8,1.8c0,0.8,0,1.5,0,2.3c0.1,0.9-0.3,1.3-1.3,1.3c-3.4,0-6.7,0-10.1,0
c-0.9,0-1.4,0.2-1.3,1.2c0.1,1.1,0.1,2.2,0,3.3c0,0.8,0.2,1,1,1c3.8,0,7.6,0.1,11.3,0c1,0,1.4,0.2,1.4,1.3c-0.1,1.2-0.2,2.3,0,3.5
c0.1,1.1-0.4,1.2-1.3,1.2c-1.4,0-2.8,0-4.2,0c-4.9,0-9.8,0-14.7,0c-1.1,0-1.5-0.2-1.5-1.4C31.9,119.1,31.9,114.8,31.9,110.5z"/>
<path class="st1" d="M94.2,96.3c3.5,0,7,0,10.5,0c1,0,1.3,0.3,1.3,1.3c-0.1,1.5,0,3,0,4.5c0,0.8-0.3,1.1-1.1,1c-1.9,0-3.8,0-5.7,0
c-0.9,0-1.1,0.3-1.1,1.1c-0.1,6.4-0.1,12.8-0.2,19.1c0,1.5,0,1.5-1.6,1.5c-1.6,0-3.2-0.1-4.7-0.1c-1,0-1.3-0.3-1.3-1.2
c0-4,0-8-0.1-12c0-2.5,0-4.9,0-7.4c0-0.8-0.2-1.1-1.1-1.1c-1.7,0.1-3.4,0-5.1,0c-1,0-1.4-0.2-1.3-1.2c0.1-1.4,0.1-2.9,0-4.4
c0-0.9,0.3-1.2,1.2-1.2C87.2,96.4,90.7,96.3,94.2,96.3z"/>
<path class="st1" d="M123.8,96.4c3.4,0,6.9,0,10.3,0c1,0,1.5,0.3,1.4,1.4c-0.1,1.4-0.1,2.9,0,4.4c0,0.8-0.2,1-1,1c-2,0-3.9,0-5.9,0
c-0.8,0-1.2,0.2-1.1,1.1c0,6.5,0,13,0.1,19.5c0,0.8-0.2,1.2-1.1,1.1c-1.9-0.1-3.8-0.1-5.7-0.1c-0.7,0-0.9-0.2-0.9-0.9
c0-6.5-0.2-13-0.2-19.5c0-1-0.3-1.3-1.3-1.3c-1.7,0.1-3.3,0-5,0c-1,0-1.4-0.2-1.3-1.2c0.1-1.4,0.1-2.9,0-4.4c0-0.9,0.3-1.2,1.2-1.2
C116.9,96.4,120.3,96.4,123.8,96.4C123.8,96.4,123.8,96.4,123.8,96.4z"/>
<path class="st1" d="M203.3,110.6c0,4.4,0,8.7,0,13.1c0,0.8-0.2,1.1-1.1,1.1c-1.9-0.1-3.9-0.1-5.8-0.1c-0.7,0-1-0.1-1-0.9
c0-8.8,0-17.7,0-26.5c0-0.7,0.2-0.9,0.9-0.9c2,0,4,0,6,0c0.9,0,1.1,0.3,1.1,1.2C203.3,101.9,203.3,106.2,203.3,110.6z"/>
<path class="st1" d="M239.5,110.5c0-4.4,0-8.7,0-13.1c0-0.8,0.3-1.1,1.1-1.1c2,0,4,0,6,0c0.7,0,0.9,0.3,0.9,1c0,8.8,0,17.6,0,26.4
c0,0.8-0.3,1.1-1.1,1.1c-1.9,0-3.9-0.1-5.8-0.1c-0.7,0-0.9-0.3-0.9-0.9C239.6,119.3,239.6,114.9,239.5,110.5
C239.6,110.5,239.6,110.5,239.5,110.5z"/>
<path class="st1" d="M303.9,86H10.4c-4.3,0-7.9-3.5-7.9-7.9V9.7c0-4.3,3.5-7.9,7.9-7.9h293.6c4.3,0,7.9,3.5,7.9,7.9v68.5
C311.8,82.5,308.3,86,303.9,86z M10.4,2.8c-3.8,0-6.9,3.1-6.9,6.9v68.5c0,3.8,3.1,6.9,6.9,6.9h293.6c3.8,0,6.9-3.1,6.9-6.9V9.7
c0-3.8-3.1-6.9-6.9-6.9H10.4z"/>
</svg>
</template>

