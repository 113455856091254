<template>
<svg version="1.1" id="logoGoldsgym" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
y="0px" viewBox="0 0 661 163" style="enable-background:new 0 0 661 163;" xml:space="preserve">
<path class="st0" d="M76.2,144.1c0.1-1.8,0.2-1.5-0.8-2.4l-4.9-4.7c-2.6-2.7-5.4-3.5-7.5-5.2h34.6c-1.1,0.9-5.3,2.8-6.1,3.5
l-4.8,4.7c-2.4,2.4-2.4,1.9-2.3,3.9l6.6,4.4l0.1,0.1H69.7c0.1-0.2,0.1-0.1,0.2-0.2c0.1-0.1,0,0,0.2-0.1L76.2,144.1L76.2,144.1z
M46.6,139.9c-1.9-4-1.5-3.5-3.5-4.3c-2.7-1-6.3-3-8.2-4.7c-2.1-1.9-8.5-7.9-6.8-13.3c2.4-7.9,15.9-2.7,20.7,2.2
c1.4,1.5,3.8,3.8,4.6,5.7l3.8-0.2c-0.2,0.5-0.8,1.1-1.2,1.7c-0.4,0.5-0.8,1-1.2,1.5c-0.7,0.9-1.8,2.2-2.3,3.1
c-0.4-0.4-0.7-1.4-1-2.1c-6.4-15.2-17.9-10-15.3-2.9c0.5,1.5,1.6,2.6,2.7,3.3c1,0.6,3,1.7,4.2,1.9c0.2-0.5,0.4-0.6,0.7-1l-2.7-6.9
c0.7,0.4,1.3,0.9,2,1.5l12.7,9.7c0.2,0.1,0.3,0.2,0.5,0.3l0.4,0.5l-6.8-0.1C49.5,136,47.3,139.5,46.6,139.9L46.6,139.9z
M142.4,106.2c2.4-0.6,4.3,1.1,4.8,2.9c0.6,2.5-1.1,4.5-3,4.9C139.1,115.2,137,107.6,142.4,106.2L142.4,106.2z M121.5,122.2
c-1.1-0.6-6.1-3.4-6.9-3.5c-0.5,0.7-0.9,0.9-1.6,1.5c-0.3,0.3-0.5,0.4-0.8,0.7c-0.3,0.3-0.5,0.5-0.8,0.8l-10,8.9
c1.4,0,4.8-0.5,6.6-0.7c0.8-0.1,0.8,0.3,1.4,0.9c0.5,0.5,0.6,0.8,1.1,1.3c0.7,0.7,3,3.6,3.5,3.8c-0.1,2-1.4,5-1.7,7.1l7.4-6.6
c2.5-2,7.6-7,10.3-9.2c2-1.6,5.5-5.1,7.5-6.7l4.7-4.3c-1.9,0.2-4.1,0.6-6,1c-1.4,0.3-1.3-0.3-2-1.1l-2.4-2.7c-3.2-3.3-1.7-1.3-1-9.2
l-5.2,4.6c-0.4,0.4-0.6,0.5-1.1,1l-4.2,3.8c-1.2,1-1.3,0.7-0.7,2.5C120.1,117.8,121.3,120.7,121.5,122.2L121.5,122.2z M78.5,35.2
c-24.6,1.6-45,22.8-43.3,49.5c1.6,24.5,22.8,44.9,49.4,43.2c24.5-1.6,45-22.8,43.2-49.3C126.3,53.9,105.1,33.5,78.5,35.2L78.5,35.2z
M136.4,75.5c0.8-1.2-0.6-4.5-0.8-6.1c-0.6-3.4-1.9-9.9,0.5-12.7c1.1-1.3,3.2-2.5,5.3-1.4c2.7,1.4,4.6,5.6,5.5,9.4
c0.5,2.1,0.7,4.6,0.6,6.9c-0.1,1.9,0,1.4,0.9,2.7c0.4,0.5,1.3,1.6,1.5,2.2l-6.9,1.6c0.3-1.5,0.7-1.8,1.1-4.2
c0.4-2.9,0.2-6.7-0.4-9.6c-0.3-1.3-0.6-3.2-1.8-3.5c-2.7,0.4,3.8,14.5-0.6,19.8c-1.1,1.3-3.8,2-5.6,0.7c-2.7-1.8-4.4-6.3-5.1-10.6
c-0.5-2.7-0.3-4.8-0.5-7.4c-0.1-2.3-1.8-3.8-2.8-4.9c0.4-0.3,7-1.7,7.9-1.9c-0.6,1.5-1.2,2.6-1.6,4.4c-0.9,3.7-0.5,7.2,0.4,10.9
C134.5,73.4,135,75.8,136.4,75.5L136.4,75.5z M28.4,47.5C28.4,47.5,28.4,47.6,28.4,47.5c0.1,0.1,0.1,0.2,0.1,0.2l1.9,5.6
c0.3,0.8,0.1,0.8,0.9,1l4.3,1.4c-0.5,0.6-1.2,1.4-1.7,1.9c-1,0.9-1.1,0.7-1.1,2.4c0,5.8-1.9,11.8-5.2,16.1C21.6,84,14,81.2,14,70.2
c0-3.4,0.7-6.4,1.8-9.2c1.3-3.6,2.5-4.5,4.1-6.9l-1.4-3.5l7.5,2.5c-2.2,1.9-3.9,3.4-5.6,6c-6.2,9.5,1.4,14.1,5.6,11.3
c2.6-1.7,3.2-4.4,3.3-8.5c-1.7-0.3-0.8-0.7-2.1,0.4l-4.8,4.1L28.4,47.5L28.4,47.5z M140.2,42.4l0.4,0.4c0.1,0.2,0.1,0.2,0.3,0.4
l4.2,6c-0.4,0.3-8.7,1.8-9.9,1.9L140.2,42.4L140.2,42.4z M52,22.7c3.9-0.4,7.3,0.6,7.9,4.2c0.6,3.5-1.7,6.9-3.1,8.9
c-2.6,3.5-8.7,8.3-15.3,9c-3.9,0.4-7.5-0.9-8-4.2c-0.5-3.2,1.8-7.2,3.1-9C40.3,26.6,45.3,23.4,52,22.7L52,22.7z M101.6,32.2
c2.1,0.1,4.3,0.3,6.5,0.5c0.7,0.1,0.8,0,1.2-0.5l2.3-2.9c2.1-2.5,2.7-2.5,2.2-4.3c-0.5-1.8-0.9-3.6-1.3-5.3c0.4,0.2,3.6,2.8,4.2,3.3
c1.4,1.2,2.8,2.3,4.2,3.4c1.4,1.2,2.8,2.2,4.2,3.4c3.9,3.3,9.6,6.9,10.1,12.6c0.5,6.7-7.1,10.8-15.3,4.6L101.6,32.2L101.6,32.2z
M72.8,18c-1.1-0.9-5.3-3.5-5.6-4h21.2c-0.4,0.4-1.2,0.8-1.7,1.1c-0.6,0.4-1.2,0.7-1.8,1.1c-0.6,0.4-3.2,1.7-3.5,2.2v8.5
c1.9,0,3.7,0,5.6,0c1.1,0,4.3,0.3,5.1-0.3c0.5-0.4,0.9-0.8,1.5-1.2l3-2.5c0.4-0.4,1-0.9,1.5-1.2c0.1,2,0,6.7,0,9
c-10.2,0-21.3,0.1-31.3,0c0.6-0.7,6.1-4.2,6.2-4.5L72.8,18L72.8,18z M77.7,7.4C66.5,8.1,57,10.8,48.3,15.2c-2.1,1.1-4,2.1-5.9,3.3
c-3,1.9-5.4,3.7-8.1,5.8c-3,2.4-6.9,6.3-9.4,9.2c-0.7,0.9-1.4,1.6-2.1,2.5c-1.3,1.6-2.7,3.5-3.8,5.4c-8.2,13-12.5,27.7-11.6,44
c0.9,16.2,7,31.5,16.9,43.3c0.7,0.9,1.4,1.7,2.1,2.5l2.2,2.4c3.3,3.5,8.8,7.9,12.8,10.4c13,8.1,27.6,12.5,43.9,11.6
c39.6-2,72.4-35.7,70.2-78c-1.2-22.9-12.5-40.3-23.7-50.5c-2.9-2.7-4.1-3.7-7.6-6.2c-4.6-3.3-9.4-5.9-15-8.2
C100.2,8.9,88.8,6.8,77.7,7.4z"/>
<path class="st0" d="M75.4,60.6c1.8-2.2,1.5-1.1,3-2.1c0.5-0.4,0.3-1.4,0.5-2.1c0.3-1.3,0.2-0.7-0.5-1.5c-0.4-0.4-0.3-1.2-0.2-1.6
c0.4-1-0.5-4.7,3.3-4.8c4-0.1,3.3,3.5,3.4,4.2c0.1,0.5,0.7,0.8,0.1,1.8c-0.6,1.1-0.6,0.5-0.4,2c0.1,0.6-0.1,1.6,0.5,2
c0.6,0.3,1,0.2,1.7,0.7c0.5,0.4,0.7,0.7,1.2,1.2c0.7,0.3,4.4-0.9,5,3.4c0.1,1.1,0.2,0.7,0.7,1.5c1.3,2.1,0.7,4.9,1.4,6.9
c0.2,0.7,0.1,1.1,0.2,1.8c0.6,3.6-1.8,5.1-0.8,6.8c0.7,1.2,0.6,1.8,1.8,2.3c0.6-0.6,1.4-1.9,1.9-2.6c0.2-0.3,0.9-1.1,1.2-0.2
c0.1,0.4-0.5,0.9-0.6,1.4c0.5-0.2,0.1-0.3,0.8-0.4c0.3,0.3,0.3,0.2,0.5,0.5l-0.7,1.3l0.6-0.4c0.4,0.2,0.4,0.2,0.7,0.5
c-0.2,0.7-0.6,0.8-0.8,1.5c0.7-0.3,0.5-0.7,1.3,0c-0.1,0.5-0.4,0.9-0.6,1.2c0.4,0.4,0.8,0.5,0.6,0.9c-0.1,0.2-0.3,0.2-0.6,0.1
c-0.1,0-0.3-0.2-0.5-0.3c-0.4,0.3-0.5,0.7-0.8,1c-0.5-0.1-0.8-0.4-0.9-0.8c0-0.1,0-0.1-0.1-0.1c-0.2,0.3-0.4,0.7-0.7,0.9
c-0.3,0-0.5-0.1-0.8-0.2v-0.7c-0.3,0.3-0.5,0.7-0.8,1l-0.8-0.3v-0.7c-0.7,0.7-0.5,1.4-1.6,0.7c0.1-1,1.7-2.5,2.2-3.8l-0.7-0.5
c-0.4,0.1-0.8,0.3-1.4,0.3c-0.5,0-0.8-0.2-1.3-0.2c-1.3-0.1-1.5-0.4-1.4-1.8l-2.2-0.6c0.6,3.6,1.2,8.1-0.9,11.4
c-0.3,0.5-0.6,0.8-0.9,1.2c-1.8,2.1-1,2.5-0.2,4.7c1.8,5-1.9,8.9-2,10.2c0.2,0.6,0.1,0.2,0.4,0.8c0.4,1.1,0.4,0.9,1.1,1.7
c0.3,0.4,0.4,0.7,0.9,1c0.6,0.3,0.8,0.2,1,1h-5.8c0.1-0.7,0.2-0.6,0.1-1.4c-0.2-1.4,0.3-2.7,0.2-4.1c-0.1-1.4-0.4-3.8-0.6-5.1
c-0.2,0.9-0.4,4.1-0.4,5.3c-0.1,1.2,0.1,1.7,0.2,2.7c0,0.6-0.1,1-0.1,1.5c0,0.6,0.1,0.7,0.2,1.2h-5.7c0-0.8,0.8-0.7,1.3-1.3
c0.6-0.8,2.1-2.5,1.7-3.6c-0.9-2.7-3.8-5.4-1.7-10.5c0.7-1.8,1.1-2.3-0.3-3.8c-3.2-3.6-3.1-8.3-2.1-12.8c-0.7,0-1.6,0.3-2.2,0.5
c0,0.8,0,1.2-0.6,1.6c-0.4,0.3-1.2,0.2-1.6,0.3c-2.1,0.5-1.2-0.6-2.9,0.4c0.3,0.8,1.3,2,1.9,2.8c0.9,1.4-0.2,2-1.2,0.2v0.1v0.3
c-0.1,0.4-0.4,0.6-0.9,0.6c-0.3-0.3-0.5-0.7-0.7-1.1c-0.2,0.2,0,0.5-0.1,0.9l-0.7,0.2c-0.3-0.3-0.6-0.8-1-1.1
c0.2,0.8,0.1,0.8-0.6,1.1c-0.4-0.3-0.4-0.4-0.8-0.9c-0.6,0.1-0.8,0.6-1.1,0.1c-0.2-0.4,0.3-0.5,0.6-0.9c-0.2-0.4-0.6-0.9-0.5-1.2
c0.4-0.8,0.6-0.4,1.1-0.2c-0.1-0.6-1.2-1.7-0.1-1.8c0.4,0,0.2,0,0.6,0.3c-0.2-0.5-0.7-0.9-0.6-1.5c0.5-0.6,0.7-0.5,1.1-0.1
c0-0.5-0.5-0.8-0.6-1.3c0.7-1.2,1.3,0,1.8,0.8c0.3,0.5,1.2,1.6,1.4,2.1c0.5-0.2,1.1-0.4,1.3-0.9c0.5-1.3,1.4-2,1-2.9
c-0.7-1.7-1.4-3-1.1-5.1c0.1-0.7,0-1.4,0.1-2c0.2-0.7,0.3-0.7,0.3-1.7c0.1-5.5,1.5-5.5,1.6-6.8C70.8,61.3,72.4,60.5,75.4,60.6
L75.4,60.6z M78.2,42.6c-10.1,0.9-19.6,5.7-26,13.1c-7,8.3-10.6,17.8-9.6,29.3c1.8,20.4,19.8,37.3,42.4,35.4
c20.3-1.7,37.4-19.7,35.5-42.4c-0.5-5.8-2.2-10.5-4.5-15c-1.6-3.1-3.9-6-6.1-8.5c-1.9-2.1-5.7-5.1-8.1-6.5c-2.1-1.2-4.1-2.2-6.6-3.2
C90.1,43,84.1,42.1,78.2,42.6z"/>
<path class="st1" d="M77.7,7.4c11-0.6,22.5,1.5,31.6,5.3c5.6,2.3,10.4,4.9,15,8.2c3.6,2.6,4.7,3.6,7.6,6.2
c11.2,10.2,22.5,27.6,23.7,50.5c2.2,42.2-30.6,75.9-70.2,78c-16.3,0.8-31-3.5-43.9-11.6c-4-2.5-9.5-6.9-12.8-10.4l-2.2-2.4
c-0.7-0.8-1.4-1.6-2.1-2.5c-9.9-11.8-16-27.1-16.9-43.3c-0.9-16.3,3.5-31,11.6-44c1.2-1.9,2.6-3.8,3.8-5.4c0.7-0.9,1.3-1.6,2.1-2.5
c2.4-3,6.3-6.8,9.4-9.2c2.7-2.1,5-3.9,8.1-5.8c1.9-1.2,3.8-2.3,5.9-3.3C57,10.8,66.5,8.1,77.7,7.4L77.7,7.4z M163,77.4
c-0.1-1.8-0.2-3.4-0.3-4.6c-0.5-4.1-1.2-8.5-2.3-12.4c-1.4-5-3.2-9.6-5.3-14.3c-2.2-4.9-7.1-12.3-10.5-16.4
c-0.4-0.5-0.7-0.8-1.1-1.3c-0.8-1-1.6-1.7-2.3-2.6c-0.5-0.5-0.7-0.8-1.2-1.3l-3.8-3.7c-2.1-2-4.5-3.7-6.8-5.4
c-2.4-1.7-4.8-3.4-7.4-4.8c-1.1-0.6-2.1-1.2-3.2-1.7c-0.5-0.3-1.1-0.5-1.6-0.8l-5.1-2.3c-0.7-0.3-1.1-0.4-1.8-0.7l-5.5-1.9
c-6.8-2-14-3.3-21.2-3.3C75.6,0,69.7,0.4,62,2.3C54.5,4.2,47.5,7,40.8,10.8c-1,0.6-2,1.2-3,1.9c-1.8,1-3.5,2.3-5.1,3.6l-5.4,4.5
c-0.3,0.2-0.4,0.4-0.7,0.7l-9.5,10.3c-0.7,1-1.5,1.9-2.1,2.9c-2.5,3.8-5.6,8.4-7.3,12.7L6,50.7c-3.7,8.5-6,19.5-6,28.9
c0,7.8,0.4,13.9,2.3,21.4c0.7,2.7,1.4,5,2.3,7.5c0.5,1.3,0.8,2.3,1.3,3.6l4,8.4c1.9,3.8,6,9.8,8.9,13.1l1.7,2
c0.3,0.3,0.3,0.4,0.5,0.7l2.4,2.6c1.5,1.5,3.4,3.4,5.1,4.7l2.7,2.2c8.4,6.9,18.9,11.9,29.4,14.7c9.1,2.4,13.9,2.6,22.3,2.6h2.8
c1.6,0,3-0.1,4.5-0.3c6.7-0.7,13.4-2.3,19.6-4.6c5.8-2.1,12.5-5.4,17.5-9c2.6-1.9,5.9-4.1,8.2-6.3c0.5-0.5,0.8-0.7,1.3-1.2
c0.9-0.9,1.7-1.5,2.5-2.4c0.8-0.8,1.5-1.6,2.3-2.4c0.5-0.5,0.7-0.8,1.2-1.3c1.2-1.2,2.2-2.6,3.3-3.9c0.8-0.9,1.4-1.8,2.1-2.8
c1.4-1.9,2.6-3.9,3.8-5.9c6-10,9.4-21,10.8-32.6c0.1-1.2,0.2-2.8,0.3-4.6v-8.4C163.1,77.4,163,77.4,163,77.4z"/>
<path class="st1" d="M78.2,42.6c5.9-0.5,11.9,0.4,17,2.3c2.5,1,4.5,2,6.6,3.2c2.5,1.4,6.3,4.4,8.1,6.5c2.3,2.5,4.5,5.4,6.1,8.5
c2.3,4.5,4,9.2,4.5,15c1.9,22.6-15.1,40.6-35.5,42.4c-22.6,1.9-40.6-15.1-42.4-35.4c-1-11.5,2.6-21,9.6-29.3
C58.5,48.3,68.1,43.5,78.2,42.6L78.2,42.6z M78.5,35.2c-24.6,1.6-45,22.8-43.3,49.5c1.6,24.5,22.8,44.9,49.4,43.2
c24.5-1.6,45-22.8,43.2-49.3C126.3,53.9,105.1,33.5,78.5,35.2z"/>
<path class="st1" d="M81.8,95c0.3,0.3,0.2,0,0.4,1.1c0.2,1,0.4,2.7-0.3,3.5C81.3,97.5,81.6,97,81.8,95L81.8,95z M81.9,89.7l0.3,0.7
c0.3,1.1-0.1,1.2-0.2,2.5c-0.1-0.2,0,0.2-0.1-0.3C81.3,90.4,81.7,90.8,81.9,89.7L81.9,89.7z M91.3,80.9c-0.6-0.1-1.2-0.3-1.8-0.4
c-0.9-0.2-0.9-0.1-1.3-0.9c-0.4-0.9-1.2-1.8-1.3-2.9c-0.1-0.8,0.6-2.7,0.9-3.3l1.8-2.3c0.4,0.2,0.6,0.4,1.2,0.6
c2.2,0.8,0.1,1.4,0.4,3.9c0.1,1.3,0.9,1.8,1,2.7C92.5,80.2,91.7,79.7,91.3,80.9L91.3,80.9z M72.5,80.9c-0.1-0.1-0.8-1-0.8-1.1
c-0.8-1.4,0.7-2.6,0.8-4.5c0.1-3.1-2.3-2.4,1.6-4.2l0.5,0.6c0.6,0.9,1.4,1.6,1.5,1.8c0.3,0.5,0.9,2.7,0.9,3.3
c-0.1,0.9-0.9,2.1-1.3,2.9s-0.4,0.6-1.3,0.9C73.6,80.7,73.1,80.8,72.5,80.9L72.5,80.9z M75.4,60.6c-3-0.1-4.6,0.7-4.9,3.5
c-0.1,1.3-1.6,1.3-1.6,6.8c0,0.9-0.2,1-0.3,1.7c-0.1,0.6,0,1.3-0.1,2c-0.3,2.1,0.4,3.3,1.1,5.1c0.4,0.9-0.5,1.6-1,2.9
c-0.2,0.4-0.8,0.7-1.3,0.9c-0.2-0.5-1.1-1.6-1.4-2.1c-0.5-0.7-1-2-1.8-0.8c0.2,0.5,0.6,0.8,0.6,1.3c-0.5-0.3-0.6-0.5-1.1,0.1
c-0.1,0.6,0.4,0.9,0.6,1.5c-0.5-0.2-0.2-0.3-0.6-0.3c-1.1,0.1,0,1.3,0.1,1.8c-0.5-0.2-0.7-0.6-1.1,0.2c-0.1,0.4,0.4,0.9,0.5,1.2
c-0.3,0.3-0.8,0.5-0.6,0.9c0.3,0.6,0.5,0,1.1-0.1c0.4,0.5,0.4,0.6,0.8,0.9c0.7-0.2,0.8-0.3,0.6-1.1c0.4,0.2,0.7,0.8,1,1.1l0.7-0.2
c0.1-0.4-0.1-0.6,0.1-0.9c0.2,0.3,0.5,0.8,0.7,1.1c0.5,0,0.8-0.2,0.9-0.6v-0.3v-0.1c1,1.8,2.1,1.1,1.2-0.2c-0.5-0.8-1.5-2-1.9-2.8
c1.7-1,0.8,0.1,2.9-0.4c0.4-0.1,1.1,0,1.6-0.3c0.6-0.4,0.6-0.7,0.6-1.6c0.6-0.2,1.5-0.5,2.2-0.5c-0.9,4.5-1.1,9.3,2.1,12.8
c1.4,1.6,1,2.1,0.3,3.8c-2.1,5.2,0.8,7.8,1.7,10.5c0.3,1-1.1,2.8-1.7,3.6c-0.5,0.6-1.3,0.5-1.3,1.3h5.7c0-0.5-0.2-0.6-0.2-1.2
c0-0.5,0.1-1,0.1-1.5c0-1-0.3-1.6-0.2-2.7c0.1-1.2,0.2-4.4,0.4-5.3c0.3,1.3,0.5,3.7,0.6,5.1c0.1,1.5-0.4,2.7-0.2,4.1
c0.1,0.8,0,0.6-0.1,1.4H88c-0.2-0.8-0.4-0.7-1-1c-0.5-0.3-0.6-0.6-0.9-1c-0.7-0.9-0.7-0.6-1.1-1.7c-0.2-0.6-0.2-0.2-0.4-0.8
c0.1-1.4,3.8-5.2,2-10.2c-0.8-2.2-1.6-2.6,0.2-4.7c0.4-0.4,0.6-0.8,0.9-1.2c2.2-3.3,1.5-7.8,0.9-11.4l2.2,0.6
c-0.1,1.4,0.2,1.7,1.4,1.8c0.6,0,0.9,0.2,1.3,0.2c0.6,0,1-0.2,1.4-0.3l0.7,0.5c-0.5,1.2-2.1,2.7-2.2,3.8c1.1,0.7,0.9,0,1.6-0.7v0.7
l0.8,0.3c0.3-0.3,0.5-0.7,0.8-1v0.7c0.3,0.1,0.5,0.2,0.8,0.2c0.3-0.3,0.5-0.6,0.7-0.9c0,0,0,0,0.1,0.1c0.1,0.5,0.4,0.7,0.9,0.8
c0.3-0.3,0.4-0.7,0.8-1c0.2,0.1,0.4,0.3,0.5,0.3c0.2,0.1,0.4,0.1,0.6-0.1c0.2-0.4-0.3-0.5-0.6-0.9c0.2-0.3,0.5-0.8,0.6-1.2
c-0.8-0.7-0.6-0.3-1.3,0c0.2-0.6,0.6-0.8,0.8-1.5c-0.2-0.3-0.3-0.3-0.7-0.5l-0.6,0.4l0.7-1.3c-0.3-0.3-0.2-0.3-0.5-0.5
c-0.7,0-0.3,0.1-0.8,0.4c0.2-0.5,0.8-1,0.6-1.4c-0.3-0.9-1-0.1-1.2,0.2c-0.5,0.7-1.3,2-1.9,2.6c-1.3-0.5-1.2-1.1-1.8-2.3
c-1-1.7,1.4-3.2,0.8-6.8c-0.1-0.8,0-1.1-0.2-1.8c-0.7-2-0.1-4.9-1.4-6.9c-0.5-0.8-0.6-0.4-0.7-1.5c-0.6-4.3-4.3-3.1-5-3.4
c-0.5-0.5-0.7-0.8-1.2-1.2c-0.7-0.6-1.1-0.4-1.7-0.7s-0.4-1.3-0.5-2c-0.2-1.6-0.2-0.9,0.4-2s0-1.3-0.1-1.8c-0.2-0.7,0.5-4.4-3.4-4.2
c-3.8,0.1-2.9,3.8-3.3,4.8c-0.2,0.5-0.2,1.2,0.2,1.6c0.7,0.8,0.7,0.3,0.5,1.5c-0.1,0.8,0.1,1.8-0.5,2.1
C76.9,59.5,77.2,58.4,75.4,60.6z"/>
<path class="st1" d="M114.9,124.9l11.3,5.3l-6.2,0.6L114.9,124.9L114.9,124.9z M129.8,126.9l1.2-5.6c0.1-0.8-0.3-0.8-0.8-1.3
l-1.6-1.8c-0.2-0.2-0.4-0.4-0.6-0.6l-2.2-2.5C125.9,115.8,129.5,126.4,129.8,126.9L129.8,126.9z M121.5,122.2
c-1.1-0.6-6.1-3.4-6.9-3.5c-0.5,0.7-0.9,0.9-1.6,1.5c-0.3,0.3-0.5,0.4-0.8,0.7c-0.3,0.3-0.5,0.5-0.8,0.8l-10,8.9
c1.4,0,4.8-0.5,6.6-0.7c0.8-0.1,0.8,0.3,1.4,0.9c0.5,0.5,0.6,0.8,1.1,1.3c0.7,0.7,3,3.6,3.5,3.8c-0.1,2-1.4,5-1.7,7.1l7.4-6.6
c2.5-2,7.6-7,10.3-9.2c2-1.6,5.5-5.1,7.5-6.7l4.7-4.3c-1.9,0.2-4.1,0.6-6,1c-1.4,0.3-1.3-0.3-2-1.1l-2.4-2.7c-3.2-3.3-1.7-1.3-1-9.2
l-5.2,4.6c-0.4,0.4-0.6,0.5-1.1,1l-4.2,3.8c-1.2,1-1.3,0.7-0.7,2.5C120.1,117.8,121.3,120.7,121.5,122.2z"/>
<path class="st1" d="M114.9,38.3c1-1.2,2-2.3,2.9-3.5c0.5-0.6,0.9-1.2,1.5-1.8c0.5-0.6,0.9-1.2,1.4-1.7l4.9,4
c1.9,1.6,1.6,4.1,0.2,6.1c-1.2,1.7-4,2.6-6.2,0.9L114.9,38.3L114.9,38.3z M101.6,32.2l18.1,15c8.2,6.2,15.9,2.2,15.3-4.6
c-0.5-5.7-6.1-9.3-10.1-12.6c-1.4-1.2-2.8-2.3-4.2-3.4c-1.4-1.2-2.8-2.3-4.2-3.4c-0.6-0.5-3.8-3.2-4.2-3.3c0.5,1.8,0.9,3.6,1.3,5.3
c0.5,1.7-0.1,1.7-2.2,4.3l-2.3,2.9c-0.4,0.5-0.4,0.5-1.2,0.5C105.9,32.5,103.7,32.3,101.6,32.2z"/>
<path class="st1" d="M136.4,75.5c-1.4,0.2-1.9-2.1-2.3-3.7c-0.9-3.6-1.3-7.1-0.4-10.9c0.4-1.8,1-2.9,1.6-4.4c-1,0.2-7.5,1.6-7.9,1.9
c0.9,1.1,2.7,2.6,2.8,4.9c0.1,2.7,0,4.8,0.5,7.4c0.8,4.3,2.5,8.8,5.1,10.6c1.9,1.3,4.5,0.6,5.6-0.7c4.4-5.3-2.1-19.4,0.6-19.8
c1.1,0.2,1.5,2.2,1.8,3.5c0.6,2.9,0.8,6.7,0.4,9.6c-0.4,2.4-0.7,2.7-1.1,4.2l6.9-1.6c-0.2-0.6-1.1-1.7-1.5-2.2
c-0.9-1.3-1-0.8-0.9-2.7c0.1-2.3-0.1-4.8-0.6-6.9c-0.9-3.8-2.8-8.1-5.5-9.4c-2.1-1.1-4.2,0.1-5.3,1.4c-2.4,2.8-1.1,9.3-0.5,12.7
C135.9,71,137.2,74.3,136.4,75.5z"/>
<path class="st1" d="M46.6,139.9c0.6-0.4,2.9-3.9,3.2-4l6.8,0.1l-0.4-0.5c-0.2-0.2-0.3-0.2-0.5-0.3L43,125.5c-0.7-0.5-1.3-1.1-2-1.5
l2.7,6.9c-0.3,0.5-0.5,0.6-0.7,1c-1.2-0.2-3.2-1.3-4.2-1.9c-1.1-0.7-2.1-1.8-2.7-3.3c-2.6-7.1,8.8-12.3,15.3,2.9
c0.3,0.7,0.6,1.7,1,2.1c0.5-0.9,1.6-2.2,2.3-3.1c0.4-0.5,0.8-1,1.2-1.5s1-1.2,1.2-1.7l-3.8,0.2c-0.8-2-3.2-4.3-4.6-5.7
C44,115,30.5,109.8,28,117.7c-1.6,5.4,4.7,11.4,6.8,13.3c1.9,1.7,5.5,3.6,8.2,4.7C45.1,136.4,44.8,135.9,46.6,139.9z"/>
<path class="st1" d="M22.3,66.3l4.8-4.1c1.3-1.1,0.3-0.7,2.1-0.4c-0.1,4.2-0.7,6.8-3.3,8.5c-4.2,2.8-11.8-1.8-5.6-11.3
c1.7-2.6,3.4-4.1,5.6-6l-7.5-2.5l1.4,3.5c-1.6,2.4-2.8,3.3-4.1,6.9c-1.1,2.9-1.8,5.8-1.8,9.2c0,11,7.6,13.8,13.6,5.9
c3.3-4.3,5.2-10.3,5.2-16.1c0-1.7,0.1-1.5,1.1-2.4c0.6-0.5,1.2-1.3,1.7-1.9l-4.3-1.4c-0.8-0.2-0.6-0.2-0.9-1l-1.9-5.6
c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1L22.3,66.3z"/>
<path class="st1" d="M45.8,28.2c3.2-0.5,5.8,1.8,6.1,4.5c0.4,3.1-1.8,5.8-4.4,6.2C40.3,40.2,38.5,29.3,45.8,28.2L45.8,28.2z
M52,22.7c-6.7,0.7-11.7,3.8-15.5,8.9c-1.3,1.8-3.6,5.7-3.1,9s4.2,4.6,8,4.2c6.6-0.7,12.7-5.5,15.3-9c1.5-2,3.7-5.4,3.1-8.9
C59.3,23.3,55.9,22.4,52,22.7z"/>
<path class="st1" d="M78.3,135.3c0.5-0.5,1.4-1.1,2-1.6s1.4-1.1,2-1.5c0.5,0.2,2.9,2.1,3.5,2.6c-1,1.3-2.6,2.6-3.6,4
C81.7,138.5,78.6,135.7,78.3,135.3L78.3,135.3z M76.2,144.1l-6.1,4c-0.1,0.1,0,0-0.2,0.1s-0.1,0.1-0.2,0.2h21.4l-0.1-0.1l-6.6-4.4
c-0.1-2-0.1-1.5,2.3-3.9l4.8-4.7c0.8-0.7,5-2.6,6.1-3.5H63c2,1.7,4.8,2.4,7.5,5.2l4.9,4.7C76.3,142.6,76.3,142.3,76.2,144.1z"/>
<path class="st1" d="M72.8,18v8.1c-0.1,0.3-5.7,3.8-6.2,4.5c10,0.1,21.2,0,31.3,0c0-2.2,0.1-7,0-9c-0.5,0.3-1.1,0.8-1.5,1.2l-3,2.5
c-0.5,0.4-0.9,0.8-1.5,1.2c-0.8,0.6-3.9,0.3-5.1,0.3c-1.9,0-3.7,0-5.6,0v-8.5c0.3-0.5,2.9-1.8,3.5-2.2c0.6-0.4,1.2-0.7,1.8-1.1
c0.5-0.3,1.3-0.7,1.7-1.1H67C67.5,14.5,71.7,17.1,72.8,18z"/>
<path class="st0" d="M45.8,28.2c-7.3,1.1-5.5,12,1.7,10.7c2.7-0.5,4.9-3.1,4.4-6.2C51.6,30,48.9,27.7,45.8,28.2z"/>
<path class="st0" d="M114.9,38.3l4.8,4c2.2,1.7,5,0.8,6.2-0.9c1.4-2,1.7-4.5-0.2-6.1l-4.9-4c-0.5,0.5-0.9,1.1-1.4,1.7
c-0.5,0.6-1,1.2-1.5,1.8C116.9,36,115.9,37.1,114.9,38.3z"/>
<path class="st1" d="M135.1,51.1c1.2-0.1,9.5-1.6,9.9-1.9l-4.2-6c-0.1-0.2-0.2-0.3-0.3-0.4l-0.4-0.4L135.1,51.1z"/>
<path class="st0" d="M91.3,80.9c0.4-1.2,1.2-0.7,1-2.6c-0.1-0.9-0.9-1.5-1-2.7c-0.3-2.5,1.8-3.2-0.4-3.9c-0.6-0.2-0.8-0.4-1.2-0.6
l-1.8,2.3c-0.3,0.6-1,2.5-0.9,3.3c0.1,1.1,0.9,2,1.3,2.9c0.4,0.8,0.3,0.7,1.3,0.9C90.1,80.6,90.7,80.8,91.3,80.9z"/>
<path class="st0" d="M72.5,80.9c0.7-0.1,1.1-0.2,1.8-0.4c0.9-0.2,1-0.1,1.3-0.9c0.4-0.8,1.2-2,1.3-2.9c0.1-0.6-0.6-2.8-0.9-3.3
c-0.1-0.2-0.9-0.9-1.5-1.8L74,71c-3.9,1.8-1.4,1.1-1.6,4.2c-0.1,2-1.5,3.1-0.8,4.5C71.7,79.8,72.4,80.8,72.5,80.9z"/>
<path class="st1" d="M140.5,109.4c0.8,0.7,2.3,2.7,3.1,3.4c0.3-0.3,0.5-0.4,0.7-0.7c-0.2-0.3-0.4-0.5-0.6-0.8
c-0.4-0.4-0.4-0.4-0.7-0.8c1.1-1.2,1.8-0.5,2.4,0.6l0.8-0.5C146,114.3,139.6,113.7,140.5,109.4L140.5,109.4z M142,109.1l0.5,0.7
C144.2,109,143.1,107.6,142,109.1L142,109.1z M140.6,109.1c1.2-0.5,2.1-2.4,3.3-1.5c0.4,0.3,0.5,0.7,0.4,1.3c1.1,0,1.5,0.7,1.8,1.4
c0.2-0.2,0.2-0.4,0-1C145.3,106.4,141.4,106.5,140.6,109.1L140.6,109.1z M142.4,106.2c-5.4,1.4-3.3,9,1.8,7.8c1.9-0.4,3.6-2.4,3-4.9
C146.8,107.3,144.8,105.6,142.4,106.2z"/>
<path class="st0" d="M78.3,135.3c0.3,0.4,3.4,3.2,3.9,3.5c0.9-1.4,2.5-2.6,3.6-4c-0.6-0.5-2.9-2.4-3.5-2.6c-0.7,0.4-1.4,1-2,1.5
C79.7,134.1,78.8,134.8,78.3,135.3z"/>
<path class="st0" d="M129.8,126.9l1.2-5.6c0.1-0.8-0.3-0.8-0.8-1.3l-1.6-1.8c-0.2-0.2-0.4-0.4-0.6-0.6l-2.2-2.5
C125.9,115.8,129.5,126.4,129.8,126.9z"/>
<polygon class="st0" points="120,130.8 126.1,130.2 114.9,124.9 "/>
<path class="st0" d="M143.7,111.2c0.3,0.3,0.4,0.5,0.6,0.8c-0.2,0.3-0.4,0.4-0.7,0.7c-0.8-0.6-2.4-2.7-3.1-3.4
c-0.9,4.3,5.5,4.9,5.8,1.1l-0.8,0.5c-0.6-1-1.4-1.8-2.4-0.6C143.3,110.9,143.3,110.8,143.7,111.2z"/>
<path class="st0" d="M140.6,109.1c1.2-0.5,2.1-2.4,3.3-1.5c0.4,0.3,0.5,0.7,0.4,1.3c1.1,0,1.5,0.7,1.8,1.4c0.2-0.2,0.2-0.4,0-1
C145.3,106.4,141.4,106.5,140.6,109.1z"/>
<path class="st0" d="M81.8,95c-0.2,2-0.5,2.5,0.1,4.6c0.6-0.8,0.5-2.5,0.3-3.5C82,95,82.1,95.3,81.8,95z"/>
<path class="st0" d="M81.9,89.7c-0.2,1.1-0.6,0.7,0,2.9c0.1,0.6,0,0.1,0.1,0.3c0.1-1.3,0.5-1.4,0.2-2.5L81.9,89.7z"/>
<path class="st0" d="M142,109.1l0.5,0.7C144.2,109,143.1,107.6,142,109.1z"/>
<polygon class="st1" points="467.7,54 467.7,79.2 379.9,79.2 397.7,66.7 397.7,44.1 381.7,33 441.3,33 421.1,44.7 421.1,68.8 
449.7,68.8 "/>
<polygon class="st1" points="587.5,22.6 575.7,48.3 563.8,22.6 "/>
<path class="st1" d="M270,34.9l8.2-7.1v22.5C266.7,43,256.1,38.6,244.1,39c-11.7,0.4-17.1,7.6-17.2,15.4c-0.1,7.5,6.8,14.6,17,14.6
c4.7-0.1,9.7-1.1,13.9-2.6v-3.5l-18.2-11h55.7l-15.7,11.7v14.6l-10.8-5.1c-9.1,3.2-20.1,5.4-30.2,4.9c-19.2-1-39.4-8.6-39-23.9
c0.5-15.9,27-25,45.6-24.5C253.6,30,262.3,31.8,270,34.9L270,34.9z"/>
<path class="st1" d="M270,99.1l8.2-7.1v22.5c-11.5-7.3-22.1-11.7-34-11.3c-11.7,0.4-17.1,7.6-17.2,15.4c-0.1,7.5,6.8,14.6,16.9,14.6
c4.8-0.1,9.8-1.1,14-2.6v-3.5l-18.2-10.9h55.7l-15.7,11.7v14.6l-10.8-5.1c-9.2,3.2-20.1,5.4-30.2,4.9c-19.3-1-39.4-8.6-39-23.9
c0.5-15.9,27-25,45.5-24.5C253.7,94.2,262.4,96,270,99.1L270,99.1z"/>
<path class="st1" d="M589.5,79.7V56.5c12.1,9.9,25.3,13.6,43.3,12.6c3.2-0.2,9.6-0.2,9-3.4c-0.6-3.6-8.9-1.7-15.3-2
c-14.4-0.8-38.2-0.7-39-17.8c-0.5-12,18.9-16.1,31.7-16.1c7.7-0.1,16,1.7,22.8,3.6l10.9-4.5v20c-10.5-7.2-22-9.4-33.7-10.4
c-4.7-0.4-14.2-0.3-13.8,2.9c0.5,4,12.1,3,19,3.6c13.4,1,36.2,1.5,36.6,17.1c0.2,12-16.2,16-32.2,16c-8.7,0-14.8-1.3-23.3-3.3
C599.5,73.5,595.1,76.3,589.5,79.7L589.5,79.7z"/>
<path class="st1" d="M533.8,33h-62.7l15.7,11.5v23.1l-17.1,11.7h64c21.6,0,32.5-11.6,32.6-23.2C566.5,44.5,555.7,33,533.8,33z
M527.2,68.6h-17V43.4h17C542.6,43.4,542.6,68.6,527.2,68.6z"/>
<path class="st1" d="M338.3,30.5c-17.5,0-42.5,10.9-42.5,24.1c0,13.3,19,24.2,42.5,24.2c23.4,0,42.5-10.9,42.5-24.2
C380.8,41.4,355.8,30.5,338.3,30.5z M338.3,69.6c-8.2-0.1-14.9-6.8-14.8-15c0-8.2,6.7-14.9,14.8-14.9c8.2,0,14.9,6.8,14.9,14.9
C353.2,62.9,346.5,69.6,338.3,69.6z"/>
<path class="st1" d="M389.8,143.4h112c0.2-0.2-16.7-10.6-16.6-10.6v-23l15.4-12.7h-44l-8.7,20.2l-7.4-20.2h-50.4l16.4,11.7v23.1
L389.8,143.4z M470,109.9v22l-13.9,10.5L470,109.9z M428.7,109.9l14,32.5l-14-10.5V109.9z"/>
<path class="st1" d="M372.1,106.6l17-9.5h-96.9l16.1,9.7l20.7,19.8v4.3l-18.7,12.4h60.8L351.8,131v-4.6L372.1,106.6z M345.6,116.3
l-10.7-9.8l11.1-8.8l10,7.4L345.6,116.3z"/>
<path class="st1" d="M514.7,127.4c-4.5,0-8.1,3.6-8.2,8.1c0,4.5,3.7,8.2,8.2,8.2c4.6-0.1,8.1-3.8,8.1-8.2
C522.7,131.1,519.2,127.5,514.7,127.4z M514.8,142.7c-3.9,0-7.2-3.1-7.2-7.1c0-3.9,3.1-7.2,7.1-7.2c3.9-0.1,7.2,3.1,7.2,7.1
C521.9,139.4,518.8,142.7,514.8,142.7z"/>
<path class="st1" d="M517.3,135.4c0.8-0.7,1.2-1.1,1.2-2.2c-0.1-2-1.5-2.3-2.8-2.3h-3.9v8.8h1.1v-3.8h2.8c1.9,0,1.4,1.6,1.6,2.7
l0.1,1.1h1.4c-0.5-0.7-0.3-0.9-0.4-2C518.3,136.1,518.3,135.9,517.3,135.4z M515.8,135H513v-3.1h2.8v-0.1c0.7,0,1.8,0.3,1.6,1.8
C517.2,134.7,516.6,135,515.8,135z"/>
</svg>
</template>
