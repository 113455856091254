<template>

<div class="content-block">

<h2 class="main-title">{{ msg }}</h2>

<div class="dx-card has-paddings">

  <a class="button primary" href="#">Primary</a>
  <a class="button secondary" href="#">Secondary</a>
  <a class="button success" href="#">Success</a>
  <a class="button alert" href="#">Alert</a>
  <a class="button warning" href="#">Warning</a>

</div>

<div style="height: 30px;"></div>

<div class="grid-x grid-margin-x grid-margin-y large-up-3 medium-up-2 small-up-1">

  <div class="cell">
    <div class="dx-card has-paddings">
      <p style="margin: 0 0 15px 0;">small basic:</p>
      <p>
        <a href="#" class="small primary button">Primary Button</a><br>
        <a href="#" class="small secondary button">Secondary Button</a><br>
        <a href="#" class="small success button">Success Button</a><br>
        <a href="#" class="small alert button">Alert Button</a><br>
        <a href="#" class="small warning button">Warning Button</a><br>
      </p>
  </div>
  </div>

  <div class="cell">
    <div class="dx-card has-paddings">
      <p style="margin: 0 0 15px 0;">medium basic:</p>
      <p>
        <a href="#" class="medium primary button">Primary Button</a><br>
        <a href="#" class="medium secondary button">Secondary Button</a><br>
        <a href="#" class="medium success button">Success Button</a><br>
        <a href="#" class="medium alert button">Alert Button</a><br>
        <a href="#" class="medium warning button">Warning Button</a><br>
      </p>
    </div>
  </div>

  <div class="cell">
    <div class="dx-card has-paddings">
      <p style="margin: 0 0 15px 0;">large basic:</p>
      <p>
        <a href="#" class="large primary button">Primary Button</a><br>
        <a href="#" class="large secondary button">Secondary Button</a><br>
        <a href="#" class="large success button">Success Button</a><br>
        <a href="#" class="large alert button">Alert Button</a><br>
        <a href="#" class="large warning button">Warning Button</a><br>
      </p>
    </div>
  </div>

  <div class="cell">
    <div class="dx-card has-paddings">
      <p style="margin: 0 0 15px 0;">small hollow:</p>
      <p>
        <a href="#" class="small hollow primary button">Primary Button</a><br>
        <a href="#" class="small hollow secondary button">Secondary Button</a><br>
        <a href="#" class="small hollow success button">Success Button</a><br>
        <a href="#" class="small hollow alert button">Alert Button</a><br>
        <a href="#" class="small hollow warning button">Warning Button</a><br>
      </p>
    </div>
  </div>

  <div class="cell">
    <div class="dx-card has-paddings">
      <p style="margin: 0 0 15px 0;">medium hollow:</p>
      <p>
        <a href="#" class="medium hollow primary button">Primary Button</a><br>
        <a href="#" class="medium hollow secondary button">Secondary Button</a><br>
        <a href="#" class="medium hollow success button">Success Button</a><br>
        <a href="#" class="medium hollow alert button">Alert Button</a><br>
        <a href="#" class="medium hollow warning button">Warning Button</a><br>
      </p>
    </div>
  </div>

  <div class="cell">
    <div class="dx-card has-paddings">
      <p style="margin: 0 0 15px 0;">large hollow:</p>
      <p>
        <a href="#" class="large hollow primary button">Primary Button</a><br>
        <a href="#" class="large hollow secondary button">Secondary Button</a><br>
        <a href="#" class="large hollow success button">Success Button</a><br>
        <a href="#" class="large hollow alert button">Alert Button</a><br>
        <a href="#" class="large hollow warning button">Warning Button</a><br>
      </p>
    </div>
  </div>

</div><!-- grid-x -->

<div style="height: 30px;"></div>

<h2 class="main-title">{{ msg }} expanded</h2>

<div class="grid-x grid-margin-x grid-margin-y large-up-4 medium-up-3 small-up-2">

  <div class="cell">
    <div class="dx-card has-paddings">
      <p style="margin: 0 0 15px 0;">medium expanded basic:</p>
      <p>
        <a href="#" class="medium expanded primary button">Primary Button</a><br>
        <a href="#" class="medium expanded secondary button">Secondary Button</a><br>
        <a href="#" class="medium expanded success button">Success Button</a><br>
        <a href="#" class="medium expanded alert button">Alert Button</a><br>
        <a href="#" class="medium expanded warning button">Warning Button</a><br>
      </p>
    </div>
  </div>

  <div class="cell">
    <div class="dx-card has-paddings">
      <p style="margin: 0 0 15px 0;">medium expanded hollow:</p>
      <p>
        <a href="#" class="medium hollow expanded primary button">Primary Button</a><br>
        <a href="#" class="medium hollow expanded secondary button">Secondary Button</a><br>
        <a href="#" class="medium hollow expanded success button">Success Button</a><br>
        <a href="#" class="medium hollow expanded alert button">Alert Button</a><br>
        <a href="#" class="medium hollow expanded warning button">Warning Button</a><br>
      </p>
    </div>
  </div>

  <div class="cell">
    <div class="dx-card has-paddings">
      <p style="margin: 0 0 15px 0;">large expanded basic:</p>
      <p>
        <a href="#" class="large expanded primary button">Primary Button</a><br>
        <a href="#" class="large expanded secondary button">Secondary Button</a><br>
        <a href="#" class="large expanded success button">Success Button</a><br>
        <a href="#" class="large expanded alert button">Alert Button</a><br>
        <a href="#" class="large expanded warning button">Warning Button</a><br>
      </p>
    </div>
  </div>

  <div class="cell">
    <div class="dx-card has-paddings">
      <p style="margin: 0 0 15px 0;">large expanded hollow:</p>
      <p>
        <a href="#" class="large hollow expanded primary button">Primary Button</a><br>
        <a href="#" class="large hollow expanded secondary button">Secondary Button</a><br>
        <a href="#" class="large hollow expanded success button">Success Button</a><br>
        <a href="#" class="large hollow expanded alert button">Alert Button</a><br>
        <a href="#" class="large hollow expanded warning button">Warning Button</a><br>
      </p>
    </div>
  </div>


</div><!-- grid-x -->

</div><!-- content-block -->

<div style="height: 50px;"></div>

<div class="content-block">
<p style="margin: 15px 5px; font-size: 11px;"><a href="https://get.foundation/sites/docs/button.html" target="_blank" style="color: grey;"><i class="fa-solid fa-arrow-up-right-from-square"></i> https://get.foundation/sites/docs/button.html</a></p>
</div><!-- content-block -->





</template>

<script>
 

export default {
  name: 'button-example',
  mounted() {

  },
  data() {
    return {
      msg: 'Buttons',
    };
  },
  methods: {

  },
  unmounted() {

  },
};
</script>

<style lang="scss">
</style>
