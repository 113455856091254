<template>
  <h2 class="content-block">
    <i class="dx-icon nav-icon fa-light fa-light fa-users"></i> 
    {{ title }}
  </h2>
    <div class="content-block">
      <div class="_dx-card _responsive-paddings _has-paddings has-accordion-title">

        <DxLoadPanel
            v-model:visible="isLoadPanelVisible"
        />

        <DxAccordion
          :data-source="employees"
          :collapsible="true"
          :multiple="true"
          :animation-duration="450"
          item-template="accordionitem"
          item-title-template="accordiontitle"
        >

          <template #accordiontitle="{ data }">
              <!-- {{ data.FirstName + " " + data.LastName }} -->
            <div class="grid-x -grid-margin-x align-middle dx-accordion-title">
              <div class="shrink cell">
                <div class="user-image" 
                  style="width: 40px; height: 40px; border-radius: 50%; background-repeat: no-repeat; background-size: cover; margin: 0 10px;" 
                  v-bind:style="{ 'background-image': 'url(' + data.Avatar + ')' }" >
                </div>
              </div>
              <div class="auto cell">
                <p>{{ data.FirstName }} {{ data.LastName }}</p>
              </div>
              <div class="shrink cell">
                <!-- <DxButton
                :on-click="showdataInfo"
                class="button-info"
                text="Details"
                /> -->
              </div>
            </div>
          </template>

          <template #accordionitem="{ data }">
              <!-- {{ data.Position + " from " + data.State }} -->
            <div class="itemrow">
              <div class="legend">
                Name
              </div>
              <div class="text">
                {{ data.Title }} {{ data.FirstName }} {{ data.LastName }}
              </div>
            </div>

            <div class="itemrow">
              <div class="legend">
                E-Mail
              </div>
              <div class="text">
                {{ data.Email }}
              </div>
            </div>

            <div class="itemrow">
              <div class="legend">
                Telefon
              </div>
              <div class="text">
                {{ data.Phone }}
              </div>
            </div>

            <div class="itemrow">
              <div class="legend">
                Strasse
              </div>
              <div class="text">
                {{ data.Street }}
              </div>
            </div>

            <div class="itemrow">
              <div class="legend">
                Ort
              </div>
              <div class="text">
                {{ data.Zip }} {{ data.City }}
              </div>
            </div>

            <div class="itemrow">
              <div class="legend">
                Geburtsdatum
              </div>
              <div class="text">
                {{getFormattedDate(data.BirthDate)}}
              </div>
            </div>

            <div class="itemrow">
              <div class="legend">
                Notizen
              </div>
              <div class="text">
                {{ data.Notes }}
              </div>
            </div>

            <div class="itemrow">
              <div class="legend">
                Erstellt
              </div>
              <div class="text">
                {{getFormattedDate(data.CreationDate)}}
              </div>
            </div>

            <div class="itemrow">
              <div class="legend">
                Geändert
              </div>
              <div class="text">
                {{getFormattedDate(data.ModificationDate)}}
              </div>
            </div>

            <div class="itemrow">
              <div class="legend">
                Rolle
              </div>
              <div class="text">
                {{ data.Role }}
              </div>
            </div>

            <div class="itemrow">
              <div class="legend">
                ID
              </div>
              <div class="text">
                {{ data.id }}
              </div>
            </div>



          </template>
        </DxAccordion> 
      </div>
    </div>
</template>

<script>
  import { DxAccordion } from 'devextreme-vue/accordion';
  import { DxLoadPanel } from 'devextreme-vue/load-panel';

  import moment from 'moment';

  import auth from "../auth";

  import { 
    apihost, 
  } from "../api";
  //console.log(apihost);

  let employees = {};
  let isLoadPanelVisible = true;

  export default {

  mounted() {

  auth.getUser().then((e) => {
    if(e.data){
      // console.log(e.data);
      // console.log(e.data.id);
      // console.log(e.data.Language);
      // console.log(e.data.Role);
      // console.log(e.data.Translation);

      //TRANSLATIONS
      this.translations = e.data.Translation;

      //DATA
      setTimeout(() => {
      
        fetch(apihost+'/de/vue/member/list/')
          .then(response => response.text())
          .then(result => {
          const data = JSON.parse(result);
          //console.log(data)
          this.employees = data;
          this.isLoadPanelVisible = false;
        })
        .catch(() => { throw 'Network error' });
        
        }, 200);
      
      }
      });
    
    },

    components: {
      DxAccordion,
      DxLoadPanel,
    },
    data() {
      return {
        apihost,

        title: 'Mitglieder Accordion',
        isLoadPanelVisible,
        employees,
      };
    },

    methods: {

    getFormattedDate(date) {
      if(date){
        return moment(date).format("DD.MM.YYYY")
      }
    }
    
  },

}
</script>

<style>

</style>