export const genders = [
	'Herr',
	'Frau',
	'Divers',
];

export const roles = [
	'Customer',
	'Moderator',
	'Administrator',
];

export const itemstatus = [
	'active',
	'inactive',
	'blocked',
];

export const countries = [
	'CH',
	'DE',
	'AT',
	'ES',
	'UK',
	'PL',
	'US',
];

export const languages = [
	'de',
	'en',
];

export const statuses = [
 'Completed',
 'In Progress',
 'Deferred',
];

export const priorities = [
  'High',
  'Urgent',
  'Normal',
];

export const abotypes = [
 'Time',
 'Coupon',
];

export const exercisecategory = [
	'Cardio',
	'Fitboxen',
];
