<template>

<svg class="tgym-logo" viewBox="0 0 638 209" fill="none" xmlns="http://www.w3.org/2000/svg">
<path class="st0" d="M108.2,55.5H5.8c-3.3,0-5.4,2.2-5.4,4.4c0,3.3,2.2,5.4,5.4,5.4h45.7v136.2c0,3.3,2.2,5.4,5.4,5.4
s5.4-2.2,5.4-5.4V65.4h45.7c3.3,0,5.4-2.2,5.4-5.4C113.6,57.7,110.3,55.5,108.2,55.5z"/>
<path class="st0" d="M235.6,63.2c16.3,0,31.6,5.4,42.5,15.2c2.2,2.2,5.4,1.1,7.6-1.1c2.2-2.2,1.1-5.4-1.1-7.6
c-14.2-10.9-30.5-17.4-49-17.4c-43.6,0-79.5,34.9-79.5,77.3c0,43.6,35.9,77.3,79.5,77.3c18.5,0,35.9-6.5,49-16.3
c1.1-1.1,2.2-2.2,2.2-4.4v-1.1v-52.3c0-3.3-2.2-5.4-4.4-5.4h-1.1h-46.8c-3.3,0-5.4,2.2-5.4,5.4c0,3.3,2.2,5.4,5.4,5.4h41.4V183
c-10.9,8.7-25.1,13.1-40.3,13.1c-38.1,0-68.6-29.4-68.6-66.4C167,93.7,197.5,63.2,235.6,63.2z"/>
<path class="st0" d="M450.2,57.7l-47.9,65.4l-47.9-65.4c-1.1-2.2-5.4-3.3-7.6-1.1c-2.2,1.1-2.2,5.4-1.1,7.6l51.2,69.7v67.5
c0,3.3,2.2,5.4,5.4,5.4c3.3,0,5.4-2.2,5.4-5.4v-68.6l51.2-69.7c2.2-2.2,1.1-5.4-1.1-7.6C455.6,55.5,452.4,55.5,450.2,57.7z"/>
<path class="st0" d="M636.4,58.8c0-1.1-1.1-1.1-1.1-2.2c-2.2-1.1-5.4-1.1-7.6,1.1l-54.5,75.2l-54.5-75.2c-2.2-2.2-5.4-3.3-7.6-1.1
c-1.1,1.1-2.2,2.2-2.2,4.4v141.6c0,3.3,2.2,5.4,5.4,5.4c3.3,0,5.4-2.2,5.4-5.4V77.3l49,67.5c1.1,2.2,2.2,2.2,4.4,2.2
c1.1,0,3.3-1.1,4.4-2.2l49-67.5v125.3c0,3.3,2.2,5.4,5.4,5.4c3.3,0,5.4-2.2,5.4-5.4V61C637.5,59.9,636.4,58.8,636.4,58.8z"/>
<path class="st0" d="M57,21.8c3.3,0,5.4-1.1,7.6-3.3c2.2-2.2,3.3-4.4,3.3-7.6c0-3.3-1.1-5.4-3.3-7.6C62.4,1.1,60.2,0,57,0
c-3.3,0-5.4,1.1-7.6,3.3c-2.2,2.2-3.3,4.4-3.3,7.6c0,3.3,1.1,5.4,3.3,7.6C51.5,20.7,53.7,21.8,57,21.8z"/>
</svg>

</template>

