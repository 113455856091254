<template>

  <!-- mainhide -->
  <div class="hide" style="font-size: 10px; padding: 10px;">

    <!-- subhide -->
    <div class="-hide" style="color: orange;">
      <p>this.templateData.data.id: {{ this.templateData.data.id }}</p>
      <p>this.templateData.data.Picthumb: {{ this.templateData.data.Picthumb }}</p>
      <p>this.templateData.data.GetDevices: {{ this.templateData.data.GetDevices }}</p>
      <p>this.templateData.data.OptionDevice: {{ this.templateData.data.OptionDevice }}</p>

    </div>
    <!-- /subhide -->

    <!-- subhide -->
    <div class="-hide" style="color: green;">
      <p>this.Picthumb: {{ this.Picthumb }}</p>
    </div>
    <!-- /subhide -->

  </div>
  <!-- /mainhide -->

  <form 
    style="padding: 0;"
    :id="this.templateData.data.id"
    @submit="handleSubmit"
  >

  <div class="-hide">

    <DxForm 
      :id="this.templateData.data.id"
      :form-data="this.templateData.data"
      :read-only="false"
      @initialized="saveFormInstance"
      :show-colon-after-label="true"
      :show-validation-summary="true"
      validation-group="currentUserData"
    >
    <DxSimpleItem 
      data-field="id"
      help-text=""
      css-class="hide"
    >
    </DxSimpleItem>

    <DxSimpleItem 
      data-field="mastercontent"
      help-text=""
      template="mastercontent"
    >
    <DxLabel 
      :text="'mastercontent'"
      :visible="false"
      />
    </DxSimpleItem>

      <template #mastercontent>

        <div class="master-content">
        
        <!-- SUBMIT BUTTON  -->
        <div class="grid-x grid-margin-x align-middle title-area-content">
          <div class="auto cell">
            <h5>Abo bearbeiten</h5>
          </div>
          <div class="shrink cell">
            <button :id="'submitform'+this.templateData.data.id" class="cx-button"><i class="fa-light fa-ticket"></i> {{currentUser.Translation.vueappAboFormSubmit}}</button>
          </div>
        </div>
        <!-- /SUBMIT BUTTON  -->

          <div class="grid-x grid-margin-x medium-up-2 xlarge-up-2 xxlarge-up-3 master-grid">

            <!-- cell Betreff-->
            <div class="cell cell-margin-y large-3">

              <div class="dx-card no-paddings">

              <label class="ck-label hide">Betreff</label>

              <div class="dx-card-label grid-x align-middle">
                <div class="auto cell">
                  <p>{{ currentUser.Translation.vueappTaskSubject }}</p>
                </div>
              </div>


              <ul :id="'tabsLang'+this.templateData.data.id" class="tabs hide" data-tabs>
                <li class="tabs-title" :class="currentUser.Language == 'de'  ? 'is-active' : ''"><a :href="'#panelSubject_de'+this.templateData.data.id" aria-selected="true"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/de.svg'"><span class="hide">de</span></a></li>
                <li class="tabs-title" :class="currentUser.Language == 'en'  ? 'is-active' : ''"><a :href="'#panelSubject_en'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/gb.svg'"><span class="hide">en</span></a></li>
                <li class="tabs-title" :class="currentUser.Language == 'fr'  ? 'is-active' : ''"><a :href="'#panelSubject_fr'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/fr.svg'"><span class="hide">fr</span></a></li>
                <li class="tabs-title" :class="currentUser.Language == 'es'  ? 'is-active' : ''"><a :href="'#panelSubject_es'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/es.svg'"><span class="hide">es</span></a></li>
                <li class="tabs-title" :class="currentUser.Language == 'it'  ? 'is-active' : ''"><a :href="'#panelSubject_it'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/it.svg'"><span class="hide">it</span></a></li>
              </ul>


              <div class="tabs-content" :data-tabs-content="'tabsLang'+this.templateData.data.id">

                <div class="tabs-panel" :class="currentUser.Language == 'de'  ? 'is-active' : ''" :id="'panelSubject_de'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_de'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_de"
                    :input-attr="{ 'aria-label': 'Subject_de' }"
                  />
                </div>
                <div class="tabs-panel" :class="currentUser.Language == 'en'  ? 'is-active' : ''" :id="'panelSubject_en'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_en'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_en"
                    :input-attr="{ 'aria-label': 'Subject_en' }"
                  />
                </div>
                <div class="tabs-panel" :class="currentUser.Language == 'fr'  ? 'is-active' : ''" :id="'panelSubject_fr'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_fr'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_fr"
                    :input-attr="{ 'aria-label': 'Subject_fr' }"
                  />
                </div>
                <div class="tabs-panel" :class="currentUser.Language == 'es'  ? 'is-active' : ''" :id="'panelSubject_es'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_es'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_es"
                    :input-attr="{ 'aria-label': 'Subject_es' }"
                  />
                </div>
                <div class="tabs-panel" :class="currentUser.Language == 'it'  ? 'is-active' : ''" :id="'panelSubject_it'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_it'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_it"
                    :input-attr="{ 'aria-label': 'Subject_it' }"
                  />
                </div>

                </div><!-- /tabs-content-->

                <!-- price-->
                <label class="ck-label hide">Preis</label>

                <div class="dx-card-label grid-x align-middle -hide">
                  <div class="auto cell">
                    <p>Preis</p>
                  </div>
                  <div class="shrink cell hide">
                    Accordion
                  </div>
                </div>

                <DxNumberBox
                  :id="'Price'+this.templateData.data.id"
                  :value="this.templateData.data.Price"
                  :input-attr="{ 'aria-label': 'Price' }"
                />
                <!-- /price-->

                <!-- Daysvalid-->
                <label class="ck-label hide">Code</label>

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>Laufzeit (Tage)</p>
                  </div>
                </div>
                <DxNumberBox
                  :id="'Daysvalid'+this.templateData.data.id"
                  :value="this.templateData.data.Daysvalid"
                  :input-attr="{ 'aria-label': 'Daysvalid' }"
                />
                <!-- /Daysvalid-->

                <!-- code-->
                <label class="ck-label hide">Code</label>

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>Code</p>
                  </div>
                </div>
                <DxTextBox
                  :id="'Code'+this.templateData.data.id"
                  :value="this.templateData.data.Code"
                  :input-attr="{ 'aria-label': 'Code' }"
                />
                <!-- /code-->

                <!-- description-->
                <label class="ck-label hide">Beschreibung</label>

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>{{ currentUser.Translation.vueappTaskDescription }}</p>
                  </div>
                </div>
                <DxTextBox
                  :id="'Description'+this.templateData.data.id"
                  :value="this.templateData.data.Description"
                  :input-attr="{ 'aria-label': 'Description' }"
                />
                <!-- /description-->

              </div><!-- dx-card-->

            </div>
            <!-- /cell Betreff-->

            <!-- cell Image-->
            <div class="cell cell-margin-y large-3">
              <div class="dx-card no-paddings">

                <label class="ck-label hide">Bild</label>

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>Bild</p>
                  </div>
                </div>

                <ul :id="'tabsImage'+this.templateData.data.id" class="tabs hide" data-tabs>
                  <li class="tabs-title -is-active"><a :href="'#panelImage_1'+this.templateData.data.id" aria-selected="true"><i class="tabnav-icon no-margin fa-light fa-image"></i><span class="hide">Image</span></a></li>
                  <li class="tabs-title is-active"><a :href="'#panelImage_2'+this.templateData.data.id"><i class="tabnav-icon no-margin fa-light fa-cloud-arrow-up"></i><span class="hide">Upload</span></a></li>
                </ul>

                <div class="tabs-content" :data-tabs-content="'tabsImage'+this.templateData.data.id">
                  <div class="tabs-panel -is-active" :id="'panelImage_1'+this.templateData.data.id">
                    <div class="padding-wrap">
                      <img :src="this.Picthumb" :alt="this.templateData.data.Subject" />
                    </div><!-- /padding-wrap -->
                  </div><!-- /tabs-panel -->

                  <div class="tabs-panel is-active" :id="'panelImage_2'+this.templateData.data.id">

                    <div class="padding-wrap">

                      <!-- upload Picture -->
                      <div v-if="this.Picthumb">
                        <img :src="this.Picthumb" :alt="this.templateData.data.Subject" />
                      </div>
                      <div v-else style="font-size: 12px;">
                        <span style="color: #ec5f67;">
                          <i class="fa-light fa-circle-exclamation"></i>
                          Bild jpg, png (5 MB)
                        </span>
                      </div>

                      <DxFileUploader
                        id="upfile"
                        name="upfile"
                        class="uploadbutton"
                        :upload-url="apihost+'/uploadabo.php?id=' + this.templateData.data.id"
                        :accept="accept"
                        :multiple="false"
                        :upload-mode="uploadMode"
                        :allowed-file-extensions="['.jpg', '.jpeg', '.png']"
                        :min-file-size="1024"
                        :max-file-size="10240*1024"
                        :on-files-uploaded="onFilesUploaded"
                        label-text=""
                        :select-button-text="currentUser.Translation.vueappAboUploadPicButton"
                        @value-changed="e => files = e.value"
                      />

                      <!-- /upload Picture -->

                    </div><!-- /padding-wrap -->

                  </div>                  
                  <!-- /tabs-panel -->
                </div>                  
                  <!-- /tabs-content -->

              </div><!-- dx-card-->

            </div>
            <!-- /cell Image-->


            <!-- cell Devices-->
            <div class="cell cell-margin-y -hide">
              <div class="dx-card no-paddings">

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>GT7-Geräte</p>
                  </div>
                </div>

                <div id="checkboxContainer">

                  <div class="padding-wrap">

                    <div v-for="device in this.templateData.data.GetDevices" :key="device.id">

                      <div class="checkbox-container">

                        <input 
                          :name="'checkboxesdevice'+this.templateData.data.id"
                          :id="device.id+this.templateData.data.id"
                          type="checkbox" 
                          :value="device.id"
                          :checked="this.templateData.data.OptionDevice.includes(device.id.toString())"
                          v-model="device.checked"
                        >
                        <label :for="device.id+this.templateData.data.id"> &nbsp;{{device.devicename}}</label>
                        <span class="checkmark"></span>
                      </div>
                      
                    </div>

                  </div><!-- padding-wrap-->
                </div><!-- checkboxContainer -->

              </div><!-- dx-card-->

              <div class="dx-card no-paddings">
                  <div class="dx-card-label grid-x align-middle">
                    <p>GV6 Geräte</p>
                  </div>
                  <div class="padding-wrap">
                    <div v-for="device in [{id: 1, name: 'Sursee-Vending'}, {id: 2, name: 'Sursee-Solarium'}, {id: 3, name: 'Luzern-Vending'}]" :key="device.id"> 
                      <div class="checkbox-container">
                       <input
                         type="checkbox"
                         :value="device.id"
                         :checked="false"
                         >
                       <label>{{device.name}}</label>
                       <span class="checkmark"></span>
                     </div>
                    </div>
                  </div>
              </div>

            </div>
            <!-- /cell Devices-->

            <!-- cell Itemkey hide-->
            <div class="cell cell-margin-y hide">
              <div class="dx-card no-paddings">
              
              <label class="ck-label">Itemkey</label>
              <div class="ck-input-container">
                <input type="text" class="dx-texteditor-input ck-input" autocomplete="off" spellcheck="false" name="Itemkey" :id="'Itemkey'+this.templateData.data.id" :value="this.templateData.data.Itemkey" >
              </div>

              </div><!-- dx-card-->

            </div>
            <!-- /cell Itemkey hide-->

          </div><!-- grid-x grid-margin-x medium-up-2 xlarge-up-2 xxlarge-up-3 master-grid -->

        </div><!-- master-form -->

      </template><!-- mastercontent -->
    
      <DxButtonItem
        horizontal-alignment="left"
        :button-options="buttonOptions"
        css-class="hide"
      />

    </DxForm>

  </div><!-- hide -->

  </form>

</template>
<script>

import { DxFileUploader } from 'devextreme-vue/file-uploader';

import DxForm, {
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  //DxGroupItem,
  //DxRequiredRule,
  //DxCompareRule,
  //DxRangeRule,
  //DxStringLengthRule,
  //DxPatternRule,
  //DxEmailRule,
  //DxAsyncRule,
} from 'devextreme-vue/form';

import notify from 'devextreme/ui/notify';
import DxTextBox from 'devextreme-vue/text-box';
import { DxNumberBox } from 'devextreme-vue/number-box';

import $ from 'jquery';
import Foundation from 'foundation-sites';

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 3,
  lg: 4
}

let currentUser;

let Picthumb;

// let optionselectedDevice;
// let optionDevicelist;


auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
  } // e.data
}); //auth

function handleErrors(response) {
   if (!response.ok) {
       throw Error(response.statusText);
   }
   return response;
 }

export default {
 name: 'exerciseitem-template',

 props: {
  templateData: Object,
 },

 components: {
  DxFileUploader,
  DxForm,
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  DxTextBox,
  DxNumberBox,
  //DxGroupItem,
  //DxRadioGroup,
  //DxFileUploader,
 },

 mounted() {

  this.Picthumb = this.templateData.data.Picthumb;

  //set checkboxes device
  // this.optionselectedDevice = [];
  // this.optionDevicelist = [];

  //foundation
  this.tabsLang = new Foundation.Tabs($('#tabsLang'+this.templateData.data.id), {
    matchHeight: false
  })
  this.tabsImage = new Foundation.Tabs($('#tabsImage'+this.templateData.data.id), {
    matchHeight: false
  })



},

 data() {
   return {
    apihost,

    currentUser,
    colCountByScreen,

    Picthumb,


    //save Button
    buttonOptions: {
      text: currentUser.Translation.vueappAboFormSubmit,
      type: 'success',
      useSubmitBehavior: true,
    },

    //uploader
    formRefName: 'tree-view',
    multiple: false,
    accept: '*',
    uploadMode: 'instantly',
    fileTypesSource: [
      { name: 'All types', value: '*' },
      { name: 'Images', value: 'image/*' },
      { name: 'Images', value: 'image/*' },
    ],
    files: [],
    //end uploader    

   };

 },
 methods: {

  printTimestamp: function () {
    return Date.now();
  },

  onFilesUploaded() {

    const id = this.formInstance.getEditor('id').option('value'); 

    let objString = '?id=' + id;
    //console.log(objString);

    fetch(apihost+'/de/vue/abo/getabo/'+id+'/view'+objString)
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
      const data = JSON.parse(result);
      //console.log(data);

      //update Frontend
      this.Picthumb = data.Picthumb;

    })
    .catch(() => { throw 'Network error' });
  
    notify({
      message: currentUser.Translation.vueappAboFormSubmitSuccess,
      position: {
        my: 'center top',
        at: 'center top',
      },
    }, 'success', 3000);
    

    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);


  },

  saveFormInstance(e) {
    this.formInstance = e.component;
  },

  handleSubmit(e) {
    e.preventDefault();

    const id = this.formInstance.getEditor('id').option('value'); 
    // const Status = this.formInstance.getEditor('Status').option('value');  
    // const Priority = this.formInstance.getEditor('Priority').option('value');  

    const Price = document.getElementById("Price"+id).getElementsByTagName("input")[0].value;
    const Daysvalid = document.getElementById("Daysvalid"+id).getElementsByTagName("input")[0].value;
    const Code = document.getElementById("Code"+id).getElementsByTagName("input")[0].value;


    //checkboxesdevice
    const checkboxesdevice = document.getElementsByName("checkboxesdevice"+id);
    //console.log(checkboxesdevice)

    const selectedCheckboxesdevice = Array.prototype.slice.call(checkboxesdevice).filter(ch => ch.checked==true);
    let optionselecteddevice = [];
    for (let i1 = 0; i1 < selectedCheckboxesdevice.length; i1++) {
      optionselecteddevice.push(selectedCheckboxesdevice[i1].value);
    }
    const OptionDevice = encodeURIComponent(JSON.stringify(optionselecteddevice));
    //console.log(OptionDevice)

    const Subject_de = document.getElementById("Subject_de"+id).getElementsByTagName("input")[0].value;
    const Subject_en = document.getElementById("Subject_en"+id).getElementsByTagName("input")[0].value;
    const Subject_fr = document.getElementById("Subject_fr"+id).getElementsByTagName("input")[0].value;
    const Subject_es = document.getElementById("Subject_es"+id).getElementsByTagName("input")[0].value;
    const Subject_it = document.getElementById("Subject_it"+id).getElementsByTagName("input")[0].value;

    const Description = document.getElementById("Description"+id).getElementsByTagName("input")[0].value;

    let objString = '?id=' + id;

    if(Price){
      objString = objString + '&Price=' + Price;
    } else {
      objString = objString + '&Price=';
    }
    if(Daysvalid){
      objString = objString + '&Daysvalid=' + Daysvalid;
    } else {
      objString = objString + '&Daysvalid=';
    }
    if(Code){
      objString = objString + '&Code=' + Code;
    } else {
      objString = objString + '&Code=';
    }
    if(OptionDevice){
      objString = objString + '&OptionDevice=' + OptionDevice;
    } else {
      objString = objString + '&OptionDevice=';
    }
    if(Subject_de){
      objString = objString + '&Subject_de=' + Subject_de;
    } else {
      objString = objString + '&Subject_de=';
    }
    if(Subject_en){
      objString = objString + '&Subject_en=' + Subject_en;
    } else {
      objString = objString + '&Subject_en=';
    }
    if(Subject_fr){
      objString = objString + '&Subject_fr=' + Subject_fr;
    } else {
      objString = objString + '&Subject_fr=';
    }
    if(Subject_es){
      objString = objString + '&Subject_es=' + Subject_es;
    } else {
      objString = objString + '&Subject_es=';
    }
    if(Subject_it){
      objString = objString + '&Subject_it=' + Subject_it;
    } else {
      objString = objString + '&Subject_it=';
    }
    if(Description){
      objString = objString + '&Description=' + Description;
    } else {
      objString = objString + '&Description=';
    }

    //console.log(objString);

    fetch(apihost+'/de/vue/abo/getabo/'+id+'/save'+objString)
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
      const data = JSON.parse(result);
      //console.log(data);
      this.fetchUser = data;
    })
    .catch(() => { throw 'Network error' });
  
    notify({
      message: currentUser.Translation.vueappAboFormSubmitSuccess,
      position: {
        my: 'center top',
        at: 'center top',
      },
    }, 'success', 3000);

  },    

  },

};

</script>

<style>

</style>
