//DEVEXTREME
import 'devextreme/dist/css/dx.common.css';
//import './themes/generated/theme.base.css';

//ZURB FOUNDATION
import './themes/foundation-sites/assets/css/app.css';
import "motion-ui";

import { createApp }  from "vue";
import router from "./router";
import themes from "devextreme/ui/themes";

import App from "./App";
import appInfo from "./app-info";


themes.initialized(() => {
    const app = createApp(App);
    app.use(router);
    app.config.globalProperties.$appInfo = appInfo;
    app.mount('#app');
});
