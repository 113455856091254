<template>

  <div class="–health-block" style="padding: 16px 16px 0 16px; min-height: 300px;">

    <div class="hide" style="border: 1px solid orange; white-space: normal; font-size: 12px;">
      <p>{{ customerData }}</p>
      <p>{{ healthData }}</p>
      <p>{{ summary1 }}</p>
      <p>{{ summary2 }}</p>
      <p>{{ summary3 }}</p>
    </div>

    <p>
      <i class="dx-icon -nav-icon tabnav-icon fa-light fa-heart-pulse"></i>
      {{ currentUser.Translation.vueappNavMemberHealthcheck }}
    </p>

    <div style="height: 16px;">
      &nbsp;
    </div>

    <!-- BLOCKGRID -->
      <div class="grid-x grid-margin-x medium-up-2 large-up-3 xxlarge-up-4 health-result">

        <!-- CELL 1 -->
        <div class="cell dx-card" style="margin-bottom: 15px;">

          <!-- QUESTION -->
          <div class="question">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 1">
                <p class="health-type">{{ health.type }}</p>
                <p class="health-question-index">Frage {{ index }}</p>
                <p class="health-question-text">{{ health.question }}</p>
              </template>
            </template><!-- /v-for -->
          </div><!-- question -->
          <!-- /QUESTION -->

          <!-- LIST -->
          <div class="answer">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 1">
                <ul class="menu vertical" v-for="(answer, index) in health.answer" :key="answer.id">
                  <li class="grid-x align-middle answer-list-item" :class="{ hide: answer.checked.value === '' || answer.checked.value === 0 }">
                    <div class="shrink cell">
                      <div class="icon">
                        <img v-if="answer.img" :src="answer.img" :alt="answer.key" />
                      </div>
                    </div>
                    <div class="auto cell">
                      <div class="text">
                        <p>{{ answer.text }}</p>
                        <p class="hide"><small>{{ answer.checked }} {{index}}</small></p>
                      </div>
                    </div>
                    <div class="shrink cell">
                      <p class="check-label">{{ answer.checked.text }}</p>
                    </div>
                    <div class="shrink cell">
                      <div v-if="answer.checked.value === 1" class="check">
                        <p class="color-light"><i class="fa-light fa-shield-check"></i></p>
                      </div>
                      <div v-if="answer.checked.value === 2" class="check">
                        <p class="color-medium"><i class="fa-light fa-shield-check"></i></p>
                      </div>
                      <div v-if="answer.checked.value === 3" class="check">
                        <p class="color-strong"><i class="fa-light fa-shield-check"></i></p>
                      </div>
                      <div class="hide check">
                        <p>{{ answer.checked.value }} | {{ answer.checked.text }}</p>
                      </div>
                    </div>
                  </li>
                </ul>

                <ul v-if="summary1 === 0" class="menu vertical">
                  <li class="grid-x align-middle answer-list-item">
                    <div class="shrink cell">
                      <div class="icon-shim"></div>
                    </div>
                    <div class="auto cell">
                      <div class="text">
                        <p>keine Probleme</p>
                      </div>
                    </div>
                    <div class="shrink cell">
                      <p class="check-label">keine</p>
                    </div>
                    <div class="shrink cell">
                      <div class="check">
                      <p class="color-success"><i class="fa-light fa-shield-check"></i></p>
                      </div>
                    </div>
                  </li>
                </ul>

              </template>
            </template><!-- /v-for -->


          </div><!-- answer -->
          <!-- /LIST -->
            
          <!-- REMARK -->
          <div class="remark">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 1">
                <p><small>Bemerkungen:</small></p>
                <p>{{ health.remark }}</p>
              </template>
            </template><!-- /v-for -->
          </div><!-- remark -->
          <!-- /REMARK -->

        </div><!-- cell dx-card -->
        <!-- /CELL 1 -->
        
        <!-- CELL 2 -->
        <div class="cell dx-card" style="margin-bottom: 15px;">

          <!-- QUESTION -->
          <div class="question">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 2">
                <p class="health-type">{{ health.type }}</p>
                <p class="health-question-index">Frage {{ index }}</p>
                <p class="health-question-text">{{ health.question }}</p>
              </template>
            </template><!-- /v-for -->
          </div><!-- question -->
          <!-- /QUESTION -->

          <!-- LIST -->
          <div class="answer">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 2">
                <ul class="menu vertical" v-for="(answer, index) in health.answer" :key="answer.id">
                  <li class="grid-x align-middle answer-list-item" :class="{ hide: answer.checked.value === '' || answer.checked.value === 0 }">
                    <div class="shrink cell">
                      <div class="icon">
                        <img v-if="answer.img" :src="answer.img" :alt="answer.key" />
                      </div>
                    </div>
                    <div class="auto cell">
                      <div class="text">
                        <p>{{ answer.text }}</p>
                        <p class="hide"><small>{{ answer.checked }} {{index}}</small></p>
                      </div>
                    </div>
                    <div class="shrink cell">
                      <p class="check-label">{{ answer.checked.text }}</p>
                    </div>
                    <div class="shrink cell">
                      <div v-if="answer.checked.value === 1" class="check">
                        <p class="color-light"><i class="fa-light fa-shield-check"></i></p>
                      </div>
                      <div v-if="answer.checked.value === 2" class="check">
                        <p class="color-medium"><i class="fa-light fa-shield-check"></i></p>
                      </div>
                      <div v-if="answer.checked.value === 3" class="check">
                        <p class="color-strong"><i class="fa-light fa-shield-check"></i></p>
                      </div>
                      <div class="hide check">
                        <p>{{ answer.checked.value }} | {{ answer.checked.text }}</p>
                      </div>
                    </div>
                  </li>
                </ul>

                <ul v-if="summary2 === 0" class="menu vertical">
                  <li class="grid-x align-middle answer-list-item">
                    <div class="shrink cell">
                      <div class="icon-shim"></div>
                    </div>
                    <div class="auto cell">
                      <div class="text">
                        <p>keine Probleme</p>
                      </div>
                    </div>
                    <div class="shrink cell">
                      <p class="check-label">keine</p>
                    </div>
                    <div class="shrink cell">
                      <div class="check">
                      <p class="color-success"><i class="fa-light fa-shield-check"></i></p>
                      </div>
                    </div>
                  </li>
                </ul>

              </template>
            </template><!-- /v-for -->
          </div><!-- answer -->
          <!-- /LIST -->
            
          <!-- REMARK -->
          <div class="remark">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 2">
                <p><small>Bemerkungen:</small></p>
                <p>{{ health.remark }}</p>
              </template>
            </template><!-- /v-for -->
          </div><!-- remark -->
          <!-- /REMARK -->

          </div><!-- cell dx-card -->
          <!-- /CELL 2 -->

        <!-- CELL 3 -->
        <div class="cell dx-card" style="margin-bottom: 15px;">

          <!-- QUESTION -->
          <div class="question">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 3">
                <p class="health-type">{{ health.type }}</p>
                <p class="health-question-index">Frage {{ index }}</p>
                <p class="health-question-text">{{ health.question }}</p>
              </template>
            </template><!-- /v-for -->
          </div><!-- question -->
          <!-- /QUESTION -->

          <!-- LIST -->
          <div class="answer">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 3">
                <ul class="menu vertical" v-for="(answer, index) in health.answer" :key="answer.id">
                  <li class="grid-x align-middle answer-list-item" :class="{ hide: answer.checked.value === '' || answer.checked.value === 0 }">
                    <div class="shrink cell">
                      <div class="icon">
                        <img v-if="answer.img" :src="answer.img" :alt="answer.key" />
                      </div>
                    </div>
                    <div class="auto cell">
                      <div class="text">
                        <p>{{ answer.text }}</p>
                        <p class="hide"><small>{{ answer.checked }} {{index}}</small></p>
                      </div>
                    </div>
                    <div class="shrink cell">
                      <p class="check-label">{{ answer.checked.text }}</p>
                    </div>
                    <div class="shrink cell">
                      <div v-if="answer.checked.value === 1" class="check">
                        <p class="color-light"><i class="fa-light fa-shield-check"></i></p>
                      </div>
                      <div v-if="answer.checked.value === 2" class="check">
                        <p class="color-medium"><i class="fa-light fa-shield-check"></i></p>
                      </div>
                      <div v-if="answer.checked.value === 3" class="check">
                        <p class="color-strong"><i class="fa-light fa-shield-check"></i></p>
                      </div>
                      <div class="hide check">
                        <p>{{ answer.checked.value }} | {{ answer.checked.text }}</p>
                      </div>
                    </div>
                  </li>
                </ul>

                <ul v-if="summary3 === 0" class="menu vertical">
                  <li class="grid-x align-middle answer-list-item">
                    <div class="shrink cell">
                      <div class="icon-shim"></div>
                    </div>
                    <div class="auto cell">
                      <div class="text">
                        <p>keine Probleme</p>
                      </div>
                    </div>
                    <div class="shrink cell">
                      <p class="check-label">keine</p>
                    </div>
                    <div class="shrink cell">
                      <div class="check">
                      <p class="color-success"><i class="fa-light fa-shield-check"></i></p>
                      </div>
                    </div>
                  </li>
                </ul>

                
              </template>
            </template><!-- /v-for -->
          </div><!-- answer -->
          <!-- /LIST -->
            
          <!-- REMARK -->
          <div class="remark">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 3">
                <p><small>Bemerkungen:</small></p>
                <p>{{ health.remark }}</p>
              </template>
            </template><!-- /v-for -->
          </div><!-- remark -->
          <!-- /REMARK -->

          </div><!-- cell dx-card -->
          <!-- /CELL 3 -->

        <!-- CELL 4 -->
        <div class="cell dx-card" style="margin-bottom: 15px;">
          <!-- QUESTION -->
          <div class="question">

          <template v-for="(health, index) in healthData" :key="health.id">
            <template v-if="index == 4">
              <p class="health-type">{{ health.type }}</p>
              <p class="health-question-index">Frage {{ index }}</p>
              <p class="health-question-text">{{ health.question }}</p>
            </template>
          </template><!-- /v-for -->

          </div><!-- question -->
          <!-- /QUESTION -->

          <!-- LEGEND -->
          <div class="grid-x legend align-middle">
          <div class="shrink cell">
          </div>
          <div class="auto cell">
          </div>
          </div>
          <!-- /LEGEND -->

          <!-- LIST -->
          <div class="answer">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 4">
                <ul class="menu vertical" v-for="(answer, index) in health.answer" :key="answer.id">
                  <li class="grid-x align-middle answer-list-item" :class="{ hide: answer.checked.value ==='' }">
                    <div class="shrink cell">
                      <div class="icon-shim">
                      </div>
                    </div>
                    <div class="auto cell">
                      <div class="text">
                        <p>{{ answer.text }}</p>
                        <p class="hide"><small>{{ answer.checked }} {{ index }}</small></p>
                      </div>                  
                    </div>
                    <div class="shrink cell">
                      <div class="check">
                        <p class="color-success"><i class="fa-light fa-shield-check"></i></p>
                      </div>
                    </div>
                  </li>
                </ul>
              </template>
            </template>
          </div><!-- answer -->
          <!-- /LIST -->

          <!-- REMARK -->
          <div class="remark">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 4">
                <p><small>Bemerkungen:</small></p>
                <p>{{ health.remark }}</p>
              </template>
            </template><!-- /v-for -->
          </div><!-- remark -->
          <!-- /REMARK -->

        </div>
        <!-- /CELL 4 -->
        
        <!-- CELL 5 -->
        <div class="cell dx-card" style="margin-bottom: 15px;">
          <!-- QUESTION -->
          <div class="question">

          <template v-for="(health, index) in healthData" :key="health.id">
            <template v-if="index == 5">
              <p class="health-type">{{ health.type }}</p>
              <p class="health-question-index">Frage {{ index }}</p>
              <p class="health-question-text">{{ health.question }}</p>
            </template>
          </template><!-- /v-for -->

          </div><!-- question -->
          <!-- /QUESTION -->

          <!-- LEGEND -->
          <div class="grid-x legend align-middle">
          <div class="shrink cell">
          </div>
          <div class="auto cell">
          </div>
          </div>
          <!-- /LEGEND -->

          <!-- LIST -->
          <div class="answer">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 5">
                <ul class="menu vertical" v-for="(answer, index) in health.answer" :key="answer.id">
                  <li class="grid-x align-middle answer-list-item" :class="{ hide: answer.checked.value ==='' }">
                    <div class="shrink cell">
                      <div class="icon-shim">
                      </div>
                    </div>
                    <div class="auto cell">
                      <div class="text">
                        <p>{{ answer.text }}</p>
                        <p class="hide"><small>{{ answer.checked }} {{ index }}</small></p>
                      </div>                  
                    </div>
                    <div class="shrink cell">
                      <div class="check">
                        <p class="color-success"><i class="fa-light fa-shield-check"></i></p>
                      </div>
                    </div>
                  </li>
                </ul>
              </template>
            </template>
          </div><!-- answer -->
          <!-- /LIST -->

          <!-- REMARK -->
          <div class="remark">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 5">
                <p><small>Bemerkungen:</small></p>
                <p>{{ health.remark }}</p>
              </template>
            </template><!-- /v-for -->
          </div><!-- remark -->
          <!-- /REMARK -->

        </div>
        <!-- /CELL 5 -->
         
        <!-- CELL 6 -->
        <div class="cell dx-card" style="margin-bottom: 15px;">
          <!-- QUESTION -->
          <div class="question">

          <template v-for="(health, index) in healthData" :key="health.id">
            <template v-if="index == 6">
              <p class="health-type">{{ health.type }}</p>
              <p class="health-question-index">Frage {{ index }}</p>
              <p class="health-question-text">{{ health.question }}</p>
            </template>
          </template><!-- /v-for -->

          </div><!-- question -->
          <!-- /QUESTION -->

          <!-- LEGEND -->
          <div class="grid-x legend align-middle">
          <div class="shrink cell">
          </div>
          <div class="auto cell">
          </div>
          </div>
          <!-- /LEGEND -->

          <!-- LIST -->
          <div class="answer">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 6">
                <ul class="menu vertical" v-for="(answer, index) in health.answer" :key="answer.id">
                  <li class="grid-x align-middle answer-list-item" :class="{ hide: answer.checked.value ==='' }">
                    <div class="shrink cell">
                      <div class="icon-shim">
                      </div>
                    </div>
                    <div class="auto cell">
                      <div class="text">
                        <p>{{ answer.text }}</p>
                        <p class="hide"><small>{{ answer.checked }} {{ index }}</small></p>
                      </div>                  
                    </div>
                    <div class="shrink cell">
                      <div class="check">
                        <p class="color-success"><i class="fa-light fa-shield-check"></i></p>
                      </div>
                    </div>
                  </li>
                </ul>
              </template>
            </template>
          </div><!-- answer -->
          <!-- /LIST -->

          <!-- REMARK -->
          <div class="remark">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 6">
                <p><small>Bemerkungen:</small></p>
                <p>{{ health.remark }}</p>
              </template>
            </template><!-- /v-for -->
          </div><!-- remark -->
          <!-- /REMARK -->

        </div>
        <!-- /CELL 6 -->

        <!-- CELL 7 -->
        <div class="cell dx-card" style="margin-bottom: 15px;">
          <!-- QUESTION -->
          <div class="question">

          <template v-for="(health, index) in healthData" :key="health.id">
            <template v-if="index == 7">
              <p class="health-type">{{ health.type }}</p>
              <p class="health-question-index">Frage {{ index }}</p>
              <p class="health-question-text">{{ health.question }}</p>
            </template>
          </template><!-- /v-for -->

          </div><!-- question -->
          <!-- /QUESTION -->

          <!-- LEGEND -->
          <div class="grid-x legend align-middle">
          <div class="shrink cell">
          </div>
          <div class="auto cell">
          </div>
          </div>
          <!-- /LEGEND -->

          <!-- LIST -->
          <div class="answer">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 7">
                <ul class="menu vertical" v-for="(answer, index) in health.answer" :key="answer.id">
                  <li class="grid-x align-middle answer-list-item" :class="{ hide: answer.checked.value ==='' }">
                    <div class="shrink cell">
                      <div class="icon-shim">
                      </div>
                    </div>
                    <div class="auto cell">
                      <div class="text">
                        <p>{{ answer.text }}</p>
                        <p class="hide"><small>{{ answer.checked }} {{ index }}</small></p>
                      </div>                  
                    </div>
                    <div class="shrink cell">
                      <div class="check">
                        <p class="color-success"><i class="fa-light fa-shield-check"></i></p>
                      </div>
                    </div>
                  </li>
                </ul>
              </template>
            </template>
          </div><!-- answer -->
          <!-- /LIST -->

          <!-- REMARK -->
          <div class="remark">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 7">
                <p><small>Bemerkungen:</small></p>
                <p>{{ health.remark }}</p>
              </template>
            </template><!-- /v-for -->
          </div><!-- remark -->
          <!-- /REMARK -->

        </div>
        <!-- /CELL 7 -->

        <!-- CELL 8 -->
        <div class="cell dx-card" style="margin-bottom: 15px;">
          <!-- QUESTION -->
          <div class="question">

          <template v-for="(health, index) in healthData" :key="health.id">
            <template v-if="index == 8">
              <p class="health-type">{{ health.type }}</p>
              <p class="health-question-index">Frage {{ index }}</p>
              <p class="health-question-text">{{ health.question }}</p>
            </template>
          </template><!-- /v-for -->

          </div><!-- question -->
          <!-- /QUESTION -->

          <!-- LEGEND -->
          <div class="grid-x legend align-middle">
          <div class="shrink cell">
          </div>
          <div class="auto cell">
          </div>
          </div>
          <!-- /LEGEND -->

          <!-- LIST -->
          <div class="answer">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 8">
                <ul class="menu vertical" v-for="(answer, index) in health.answer" :key="answer.id">
                  <li class="grid-x align-middle answer-list-item" :class="{ hide: answer.checked.value ==='' }">
                    <div class="shrink cell">
                      <div class="icon-shim">
                      </div>
                    </div>
                    <div class="auto cell">
                      <div class="text">
                        <p>{{ answer.text }}</p>
                        <p class="hide"><small>{{ answer.checked }} {{ index }}</small></p>
                      </div>                  
                    </div>
                    <div class="shrink cell">
                      <div class="check">
                        <p class="color-success"><i class="fa-light fa-shield-check"></i></p>
                      </div>
                    </div>
                  </li>
                </ul>
              </template>
            </template>
          </div><!-- answer -->
          <!-- /LIST -->

          <!-- REMARK -->
          <div class="remark">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 8">
                <p><small>Bemerkungen:</small></p>
                <p>{{ health.remark }}</p>
              </template>
            </template><!-- /v-for -->
          </div><!-- remark -->
          <!-- /REMARK -->

        </div>
        <!-- /CELL 8 -->

        <!-- CELL 9 -->
        <div class="cell dx-card" style="margin-bottom: 15px;">
          <!-- QUESTION -->
          <div class="question">

          <template v-for="(health, index) in healthData" :key="health.id">
            <template v-if="index == 9">
              <p class="health-type">{{ health.type }}</p>
              <p class="health-question-index">Frage {{ index }}</p>
              <p class="health-question-text">{{ health.question }}</p>
            </template>
          </template><!-- /v-for -->

          </div><!-- question -->
          <!-- /QUESTION -->

          <!-- LEGEND -->
          <div class="grid-x legend align-middle">
          <div class="shrink cell">
          </div>
          <div class="auto cell">
          </div>
          </div>
          <!-- /LEGEND -->

          <!-- LIST -->
          <div class="answer">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 9">
                <ul class="menu vertical" v-for="(answer, index) in health.answer" :key="answer.id">
                  <li class="grid-x align-middle answer-list-item" :class="{ hide: answer.checked.value ==='' }">
                    <div class="shrink cell">
                      <div class="icon-shim">
                      </div>
                    </div>
                    <div class="auto cell">
                      <div class="text">
                        <p>{{ answer.text }}</p>
                        <p class="hide"><small>{{ answer.checked }} {{ index }}</small></p>
                      </div>                  
                    </div>
                    <div class="shrink cell">
                      <div class="check">
                        <p class="color-success"><i class="fa-light fa-shield-check"></i></p>
                      </div>
                    </div>
                  </li>
                </ul>
              </template>
            </template>
          </div><!-- answer -->
          <!-- /LIST -->

          <!-- REMARK -->
          <div class="remark">
            <template v-for="(health, index) in healthData" :key="health.id">
              <template v-if="index == 9">
                <p><small>Bemerkungen:</small></p>
                <p>{{ health.remark }}</p>
              </template>
            </template><!-- /v-for -->
          </div><!-- remark -->
          <!-- /REMARK -->

        </div>
        <!-- /CELL 9 -->

      </div><!-- grid-x -->
    <!-- /BLOCKGRID -->

    <div style="height: 16px;">
      &nbsp;
    </div>
    
  </div><!-- –health-block -->
</template>

<script>
 
let currentUser;
let customerid;
let healthData;

let summary1;
let summary2;
let summary3;

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

//this.customerid = this.customerData.id;

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;
  } // e.data
}); //auth


export default {
  name: 'customer-healthcheck',

  props: {
    templateData: Object,
    customerData: Object,

  },

  mounted() {

    //console.log(this.customerData.id);

    //DATA
    setTimeout(() => {
      fetch(apihost+'/de/vue/health/gethealth/'+this.customerData.id+'/view')
      //fetch(apihost+'/de/vue/health/gethealth/1199/result')
      .then(response => response.text())
      .then(result => {
      const data = JSON.parse(result);
      //console.log(data);
      this.healthData = data;

      //CHECK SUMMARY

      //question 1
      const arraySummary1 = data[1].answer;
      let i1 = 0;
      let res1 = 0;
      while (i1 < arraySummary1.length) {
        if(arraySummary1[i1].text){
          res1 = res1 + arraySummary1[i1].checked.value;
        }
        i1++;
      }
      this.summary1 = res1;

      //question 2
      const arraySummary2 = data[2].answer;
      let i2 = 0;
      let res2 = 0;
      while (i2 < arraySummary2.length) {
        if(arraySummary2[i2].text){
          res2 = res2 + arraySummary2[i2].checked.value;
        }
        i2++;
      }
      this.summary2 = res2;

      //question 3
      const arraySummary3 = data[3].answer;
      let i3 = 0;
      let res3 = 0;
      while (i3 < arraySummary3.length) {
        if(arraySummary3[i3].text){
          res3 = res3 + arraySummary3[i3].checked.value;
        }
        i3++;
      }
      this.summary3 = res3;


      }) //fetch
      .catch(() => { throw 'Network error' });
    
    }, 0);

  },
  data() {
    return {
      msg: 'Healthcheck (part)',
      currentUser,
      apihost,
      customerid,
      healthData,
      summary1,
      summary2,
      summary3,
    };
  },
  methods: {

  },
  unmounted() {

  },
};
</script>

<style>

</style>
