<template>
    <svg version="1.1" id="logoProfitfitness" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 2349 1088.7" style="enable-background:new 0 0 2349 1088.7;" xml:space="preserve">
    <polygon class="st0" points="790,334.6 1030.8,334.6 1039.7,434.1 882.8,434.1 860.6,544.4 973.4,544.4 953.4,644.1 840.4,644.1 
    808.5,801.9 692.7,816.2 "/>
    <polygon class="st0" points="1120.6,341.6 1236.4,327.5 1140.6,801.9 1024.8,816.2 "/>
    <polygon class="st0" points="1391,446.2 1273.3,446.2 1325,334.6 1635.4,334.6 1612.9,446.2 1504,446.2 1432,801.9 1316.2,816.2 "/>
    <path class="st0" d="M187.5,434.7l85.2-422l35.1-8.3l-13.5,67c18.4-24.8,30.3-35.8,53.6-49.1C372.6,8.2,400.3,0,424.1,0
    c83.1,0,136,71.4,118.1,160.2c-18.7,92.6-98.6,163.5-186,163.5c-43.7,0-74.8-19.9-100-63.9l-33.7,166.6L187.5,434.7z M275,160.8
    c-8.4,42.2-2.3,72.2,19.4,96.5c17.4,19.2,43.1,30,68.5,30c65,0,128.7-56.8,143-127.7c13.9-68.9-24.8-123.2-89.3-123.2
    C350,36.3,289.5,89.4,275,160.8z"/>
    <path class="st0" d="M780.2,40.2H771c-31,0-65.1,25-89.6,65.1c-13.2,20.4-21,43.4-28.7,81.7l-25,123.8l-35.2,9l62.1-307.1l35-8.3
    l-13.5,67c20.2-28,32.4-40.2,53.3-51c19.1-10.3,42.4-17.2,58.9-17.2c2.9,0,7.7,0.7,13.7,1.2L780.2,40.2z"/>
    <path class="st0" d="M1092.1,158.4c-18.8,92.5-99.7,165.3-183.4,165.3c-80.7,0-134.1-75.3-116.2-164.1C810.1,72.1,892.1,0,973.9,0
    C1059.1,0,1110.1,68.8,1092.1,158.4z M828.6,160.8c-13.9,69,26.7,126.5,89.8,126.5c60.1,0,122.8-58,136.7-126.5
    c14-69.5-24.8-124.5-87.8-124.5C903.5,36.3,842.7,91.3,828.6,160.8z"/>
    <path class="st0" d="M187.5,434.7l85.2-422l35.1-8.3l-13.5,67c18.4-24.8,30.3-35.8,53.6-49.1C372.6,8.2,400.3,0,424.1,0
    c83.1,0,136,71.4,118.1,160.2c-18.7,92.6-98.6,163.5-186,163.5c-43.7,0-74.8-19.9-100-63.9l-33.7,166.6L187.5,434.7z M275,160.8
    c-8.4,42.2-2.3,72.2,19.4,96.5c17.4,19.2,43.1,30,68.5,30c65,0,128.7-56.8,143-127.7c13.9-68.9-24.8-123.2-89.3-123.2
    C350,36.3,289.5,89.4,275,160.8z"/>
    <path class="st0" d="M780.2,40.2H771c-31,0-65.1,25-89.6,65.1c-13.2,20.4-21,43.4-28.7,81.7l-25,123.8l-35.2,9l62.1-307.1l35-8.3
    l-13.5,67c20.2-28,32.4-40.2,53.3-51c19.1-10.3,42.4-17.2,58.9-17.2c2.9,0,7.7,0.7,13.7,1.2L780.2,40.2z"/>
    <path class="st0" d="M1092.1,158.4c-18.8,92.5-99.7,165.3-183.4,165.3c-80.7,0-134.1-75.3-116.2-164.1C810.1,72.1,892.1,0,973.9,0
    C1059.1,0,1110.1,68.8,1092.1,158.4z M828.6,160.8c-13.9,69,26.7,126.5,89.8,126.5c60.1,0,122.8-58,136.7-126.5
    c14-69.5-24.8-124.5-87.8-124.5C903.5,36.3,842.7,91.3,828.6,160.8z"/>
    <polygon class="st0" points="668.3,952.6 680.4,950.8 687.8,914.2 714.1,914.2 716.4,902.8 690.1,902.8 695,878.4 729.1,878.4 
    729,867 685.6,867 "/>
    <path class="st0" d="M738.9,871l5.5,9.8l9.2-9.8l-5.2-9.9L738.9,871z M724.2,952.6l11.5-1.8l12.8-63.5l-11.5,1.8L724.2,952.6z"/>
    <path class="st0" d="M791.4,898.9l2.2-11.1h-16.9l3.9-19.3l-11.7,3.1l-3.3,16.3h-5.1l-6.5,11.1h9.3l-6.4,31.9
    c-3,14.9,1.9,22.2,15.1,22.2c2.9,0,5.1-0.3,8.6-0.9l4.5-11.4c-3.9,0.8-5.6,1-8.5,1c-7.5,0-10.1-3.4-8.5-11.1l6.4-31.8H791.4z"/>
    <path class="st0" d="M792,952.6l11.5-1.8l7-34.8c1.2-6.1,3.1-9.8,6.6-13.1c3.9-3.6,8.5-5.5,13.7-5.5c4.3,0,7.4,1.4,9.1,4.1
    c1.6,2.4,1.6,4.1,0.2,10.9l-8.1,40.2l11.5-1.8l7.8-38.4c1.9-9.4,1.6-14.1-1.3-18.7c-3-4.7-8.2-7.2-15-7.2c-8.2,0-13.7,2.5-20.4,9.5
    l1.8-8.8l-11.5,1.8L792,952.6z"/>
    <path class="st0" d="M874,921c0.1-1,0.2-1.8,0.3-2.3c2.3-11.3,13.6-21.2,24.5-21.2c4.2,0,7.8,1.5,10.1,4.2L874,921z M915.7,931.1
    c-10.3,7.2-17.9,10.4-25.1,10.4c-7.6,0-12.4-3.3-15-10.4L925,904c-1.8-5.6-3.5-8.4-7.1-11.4c-4.6-3.9-10.4-6-16.9-6
    c-17.6,0-34.9,14.9-38.6,33.4c-1.9,9.4-0.3,17.4,5.1,24c5.1,6.5,11.8,9.4,20.9,9.4c8.8,0,17.6-3.2,27.2-9.7L915.7,931.1z"/>
    <path class="st0" d="M922.9,942.5c4.2,7.9,9.4,10.9,18.6,10.9c12.6,0,22.9-8.1,25.3-19.8c1.9-9.5-2.7-16.5-12.6-19.1l-4.1-1.1
    c-1.5-0.4-2.8-0.9-4.2-1.5c-2.5-1.3-3.5-3.7-2.9-6.7c0.9-4.6,5.2-7.8,10.4-7.8c4.3,0,7.7,1.9,10.5,5.8l8.7-8.4
    c-4-5.7-9.7-8.4-17.4-8.4c-11.2,0-21.6,8.3-23.8,19.1c-1.9,9.5,2.4,15.6,13.6,18.6c6.4,1.8,6.1,1.7,7.8,2.8c2.1,1.4,3,3.8,2.4,6.7
    c-1,4.7-5.8,8-11.9,8c-4.7,0-8.1-1.9-10.9-6.2L922.9,942.5z"/>
    <path class="st0" d="M972.2,942.5c4.2,7.9,9.4,10.9,18.6,10.9c12.6,0,22.9-8.1,25.3-19.8c1.9-9.5-2.7-16.5-12.6-19.1l-4.1-1.1
    c-1.5-0.4-2.8-0.9-4.2-1.5c-2.5-1.3-3.5-3.7-2.9-6.7c0.9-4.6,5.2-7.8,10.4-7.8c4.3,0,7.7,1.9,10.5,5.8l8.7-8.4
    c-4-5.7-9.7-8.4-17.4-8.4c-11.2,0-21.6,8.3-23.8,19.1c-1.9,9.5,2.4,15.6,13.6,18.6c6.4,1.8,6.1,1.7,7.8,2.8c2.1,1.4,3,3.8,2.4,6.7
    c-1,4.7-5.8,8-11.9,8c-4.7,0-8.1-1.9-10.9-6.2L972.2,942.5z"/>
    <polygon class="st0" points="1021.8,925 1047.9,925 1049.9,915 1023.8,915 "/>
    <path class="st0" d="M1145,887.3l-11.5,1.8l-6.4,31.6c-1.7,8.3-3.2,11.6-6.9,15.1c-3.7,3.6-8.9,5.7-13.6,5.7c-3.6,0-6.6-1.3-8.5-3.6
    c-1.8-2.3-2.1-5.1-1-10.7l8.1-40l-11.5,1.8l-7.7,38.2c-2,9.7-1.6,15.2,1.2,19.3c2.9,4.1,8.9,6.7,15.1,6.7c7.2,0,13.1-2.8,20.2-9.5
    l-1.8,8.8l11.5-1.8L1145,887.3z"/>
    <path class="st0" d="M1148.7,952.6l11.5-1.8l7-34.8c1.2-6.1,3.1-9.8,6.6-13.1c3.9-3.6,8.5-5.5,13.7-5.5c4.3,0,7.4,1.4,9.1,4.1
    c1.6,2.4,1.6,4.1,0.2,10.9l-8.1,40.2l11.5-1.8l7.8-38.4c1.9-9.4,1.6-14.1-1.3-18.7c-3-4.7-8.2-7.2-15-7.2c-8.2,0-13.7,2.5-20.4,9.5
    l1.8-8.8l-11.5,1.8L1148.7,952.6z"/>
    <path class="st0" d="M1255.7,897.5c11.4,0,18.5,10,16,22.2c-2.5,12.2-13.5,21.9-25,21.9c-11.6,0-18.6-9.5-16.1-21.9
    C1233.2,906.9,1243.8,897.5,1255.7,897.5z M1264.3,952.6l11.5-1.8l17.6-87.2l-11.4,1.7l-6,29.6c-4.5-5.3-11.5-8.4-19.1-8.4
    c-17.2,0-34.1,14.9-37.8,33.4c-3.8,18.7,6.9,33.4,24.2,33.4c8.6,0,15.7-3.4,23.1-11.1L1264.3,952.6z"/>
    <path class="st0" d="M1365.3,915.3h26.1l-4,20c-8.4,5-14.7,6.9-21.9,6.9c-8.6,0-17.2-3.9-21.4-9.9c-4-5.5-5.8-15.1-4.2-22.9
    c1.6-8.1,7.1-17.3,13.8-23c7.2-6.2,16.6-9.7,25.8-9.7c8.7,0,14.6,2.5,20.7,8.9l9.3-9c-8-8.1-16.5-11.7-27.8-11.7
    c-13.2,0-25.9,5.1-36,14.1c-8.6,7.9-15.8,20.1-17.9,30.4c-5,24.7,10.6,44.6,34.7,44.6c11.6,0,22.9-3.7,35.2-11.8l7.7-38.2h-33.9
    L1365.3,915.3z"/>
    <path class="st0" d="M1425.6,921c0.1-1,0.2-1.8,0.3-2.3c2.3-11.3,13.6-21.2,24.5-21.2c4.2,0,7.8,1.5,10.1,4.2L1425.6,921z
    M1467.3,931.1c-10.3,7.2-17.9,10.4-25.1,10.4c-7.6,0-12.4-3.3-15-10.4l49.5-27.2c-1.8-5.6-3.5-8.4-7.1-11.4c-4.6-3.9-10.4-6-16.9-6
    c-17.6,0-34.9,14.9-38.6,33.4c-1.9,9.4-0.3,17.4,5.1,24c5.1,6.5,11.8,9.4,20.9,9.4c8.8,0,17.6-3.2,27.2-9.7L1467.3,931.1z"/>
    <path class="st0" d="M1474.5,942.5c4.2,7.9,9.4,10.9,18.6,10.9c12.6,0,22.9-8.1,25.3-19.8c1.9-9.5-2.7-16.5-12.6-19.1l-4.1-1.1
    c-1.5-0.4-2.8-0.9-4.2-1.5c-2.5-1.3-3.5-3.7-2.9-6.7c0.9-4.6,5.2-7.8,10.4-7.8c4.3,0,7.7,1.9,10.5,5.8l8.7-8.4
    c-4-5.7-9.7-8.4-17.4-8.4c-11.2,0-21.6,8.3-23.8,19.1c-1.9,9.5,2.4,15.6,13.6,18.6c6.4,1.8,6.1,1.7,7.8,2.8c2.1,1.4,3,3.8,2.4,6.7
    c-1,4.7-5.8,8-11.9,8c-4.7,0-8.1-1.9-10.9-6.2L1474.5,942.5z"/>
    <path class="st0" d="M1591,887.3l-11.5,1.8l-6.4,31.6c-1.7,8.3-3.2,11.6-6.9,15.1c-3.7,3.6-8.9,5.7-13.6,5.7c-3.6,0-6.6-1.3-8.5-3.6
    c-1.8-2.3-2.1-5.1-1-10.7l8.1-40l-11.5,1.8l-7.7,38.2c-2,9.7-1.6,15.2,1.2,19.3c2.9,4.1,8.9,6.7,15.1,6.7c7.2,0,13.1-2.8,20.2-9.5
    l-1.8,8.8l11.5-1.8L1591,887.3z"/>
    <path class="st0" d="M1594.8,952.6l11.5-1.8l7-34.8c1.2-6.1,3.1-9.8,6.6-13.1c3.9-3.6,8.5-5.5,13.7-5.5c4.3,0,7.4,1.4,9.1,4.1
    c1.6,2.4,1.6,4.1,0.2,10.9l-8.1,40.2l11.5-1.8l7.8-38.4c1.9-9.4,1.6-14.1-1.3-18.7c-3-4.7-8.2-7.2-15-7.2c-8.2,0-13.7,2.5-20.4,9.5
    l1.8-8.8l-11.5,1.8L1594.8,952.6z"/>
    <path class="st0" d="M1701.7,897.5c11.4,0,18.5,10,16,22.2c-2.5,12.2-13.5,21.9-25,21.9c-11.6,0-18.6-9.5-16.1-21.9
    C1679.3,906.9,1689.9,897.5,1701.7,897.5z M1710.4,952.6l11.5-1.8l17.6-87.2l-11.4,1.7l-6,29.6c-4.5-5.3-11.5-8.4-19.1-8.4
    c-17.2,0-34.1,14.9-37.8,33.4c-3.8,18.7,6.9,33.4,24.2,33.4c8.6,0,15.7-3.4,23.1-11.1L1710.4,952.6z"/>
    <path class="st0" d="M1767.6,863.7l-11.4,1.7l-17.6,87.3l11.5-1.8l7.8-38.4c1.8-9,9.4-15,19-15c3.7,0,7.1,1.4,9.2,3.7
    c2.1,2.5,2.4,4.4,1,11.3l-8.1,40.2l11.5-1.8l7.8-38.4c1.8-9,1.4-14.1-1.6-18.8c-3.2-4.7-8.2-7.1-15.1-7.1c-7,0-12.2,2-19.8,7.6
    L1767.6,863.7z"/>
    <path class="st0" d="M1820.8,921c0.1-1,0.2-1.8,0.3-2.3c2.3-11.3,13.6-21.2,24.5-21.2c4.2,0,7.8,1.5,10.1,4.2L1820.8,921z
    M1862.5,931.1c-10.3,7.2-17.9,10.4-25.1,10.4c-7.6,0-12.4-3.3-15-10.4l49.5-27.2c-1.8-5.6-3.5-8.4-7.1-11.4c-4.6-3.9-10.4-6-16.9-6
    c-17.6,0-34.9,14.9-38.6,33.4c-1.9,9.4-0.3,17.4,5.1,24c5.1,6.5,11.8,9.4,20.9,9.4c8.8,0,17.6-3.2,27.2-9.7L1862.5,931.1z"/>
    <path class="st0" d="M1887.5,871l5.5,9.8l9.2-9.8l-5.2-9.9L1887.5,871z M1872.9,952.6l11.5-1.8l12.8-63.5l-11.5,1.8L1872.9,952.6z"
    />
    <path class="st0" d="M1940,898.9l2.2-11.1h-16.9l3.9-19.3l-11.7,3.1l-3.3,16.3h-5.1l-6.5,11.1h9.3l-6.4,31.9
    c-3,14.9,1.9,22.2,15.1,22.2c2.9,0,5.1-0.3,8.6-0.9l4.5-11.4c-3.9,0.8-5.6,1-8.5,1c-7.5,0-10.1-3.4-8.5-11.1l6.4-31.8H1940z"/>
    <path class="st0" d="M1937.5,942.5c4.2,7.9,9.4,10.9,18.6,10.9c12.6,0,22.9-8.1,25.3-19.8c1.9-9.5-2.7-16.5-12.6-19.1l-4.1-1.1
    c-1.5-0.4-2.8-0.9-4.2-1.5c-2.5-1.3-3.5-3.7-2.9-6.7c0.9-4.6,5.2-7.8,10.4-7.8c4.3,0,7.7,1.9,10.5,5.8l8.7-8.4
    c-4-5.7-9.7-8.4-17.4-8.4c-11.2,0-21.6,8.3-23.8,19.1c-1.9,9.5,2.4,15.6,13.6,18.6c6.4,1.8,6.1,1.7,7.8,2.8c2.1,1.4,3,3.8,2.4,6.7
    c-1,4.7-5.8,8-11.9,8c-4.7,0-8.1-1.9-10.9-6.2L1937.5,942.5z"/>
    <path class="st0" d="M2036.8,934.8c-6.1,4.8-10.5,6.7-16,6.7c-12,0-19.4-9.9-16.8-22.5c2.4-12.1,13.7-21.7,25.4-21.7
    c5.4,0,8.9,1.5,13.7,6.2l9.2-8.4c-5.7-6.1-13.3-9.4-21.4-9.4c-7.7,0-17.1,3.8-24.1,9.7c-7.3,6.2-12.6,15.1-14.5,24.4
    c-3.8,18.7,7.5,33.5,25.2,33.5c9.2,0,17.1-3.3,25.1-10.4L2036.8,934.8z"/>
    <path class="st0" d="M2065.5,921c0.1-1,0.2-1.8,0.3-2.3c2.3-11.3,13.6-21.2,24.5-21.2c4.2,0,7.8,1.5,10.1,4.2L2065.5,921z
    M2107.2,931.1c-10.3,7.2-17.9,10.4-25.1,10.4c-7.6,0-12.4-3.3-15-10.4l49.5-27.2c-1.8-5.6-3.5-8.4-7.1-11.4c-4.6-3.9-10.4-6-16.9-6
    c-17.6,0-34.9,14.9-38.6,33.4c-1.9,9.4-0.3,17.4,5.1,24c5.1,6.5,11.8,9.4,20.9,9.4c8.8,0,17.6-3.2,27.2-9.7L2107.2,931.1z"/>
    <path class="st0" d="M2117.6,952.6l11.5-1.8l7-34.8c1.2-6.1,3.1-9.8,6.6-13.1c3.9-3.6,8.5-5.5,13.7-5.5c4.3,0,7.4,1.4,9.1,4.1
    c1.6,2.4,1.6,4.1,0.2,10.9l-8.1,40.2l11.5-1.8l7.8-38.4c1.9-9.4,1.6-14.1-1.3-18.7c-3-4.7-8.2-7.2-15-7.2c-8.2,0-13.7,2.5-20.4,9.5
    l1.8-8.8l-11.5,1.8L2117.6,952.6z"/>
    <path class="st0" d="M2224.8,898.9l2.2-11.1h-16.9l3.9-19.3l-11.7,3.1l-3.3,16.3h-5.1l-6.5,11.1h9.3l-6.4,31.9
    c-3,14.9,1.9,22.2,15.1,22.2c2.9,0,5.1-0.3,8.6-0.9l4.5-11.4c-3.9,0.8-5.6,1-8.5,1c-7.5,0-10.1-3.4-8.5-11.1l6.4-31.8H2224.8z"/>
    <path class="st0" d="M2238.2,921c0.1-1,0.2-1.8,0.3-2.3c2.3-11.3,13.6-21.2,24.5-21.2c4.2,0,7.8,1.5,10.1,4.2L2238.2,921z
    M2279.9,931.1c-10.3,7.2-17.9,10.4-25.1,10.4c-7.6,0-12.4-3.3-15-10.4l49.5-27.2c-1.8-5.6-3.5-8.4-7.1-11.4c-4.6-3.9-10.4-6-16.9-6
    c-17.6,0-34.9,14.9-38.6,33.4c-1.9,9.4-0.3,17.4,5.1,24c5.1,6.5,11.8,9.4,20.9,9.4c8.8,0,17.6-3.2,27.2-9.7L2279.9,931.1z"/>
    <path class="st0" d="M2340,886.5c-2-0.4-3.6-0.5-4.5-0.5c-9.8,0-16.6,4.1-23.5,14.2l2.6-13l-11.5,1.8l-12.8,63.5l11.5-1.8l4.6-22.6
    c2.4-11.7,4.5-17.5,8.4-22.2c4.2-5.1,9.3-7.8,15-7.8c0.6,0,1.7,0,2.6,0.1L2340,886.5z"/>
    <path class="st1" d="M2349,545.8C2349,261.3,1838,27.9,1187.8,4.7c0,0,95.5,41.7,137.1,84.1c41.7,42.4,42.8,92.2,42.8,92.2
    c494.7,57.8,815.2,225.3,815.2,400c0,89-83.1,182.1-229.8,260.9H2144C2273.6,756.7,2349,655,2349,545.8z"/>
    <path class="st1" d="M987.7,1038.2c-429.8,0-779.8-89.7-987.7-208.7c223.1,155.5,623.7,259.2,1081.2,259.2
    c291.4,0,559.8-42.1,773.9-112.9h-278.4C1406.8,1014.6,1207.7,1038.2,987.7,1038.2z"/>
    </svg>
</template>

