<template>
<svg version="1.1" id="Logo-gym365" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 492.6 203.4" style="enable-background:new 0 0 492.6 203.4;" xml:space="preserve">
<path class="st0" d="M29.8,39h62.4v12H39.9L29.8,39z M92.2,54.1H42.6l10.1,12h39.6L92.2,54.1z M92.2,81.3v-12H55.3l10.1,12H92.2z
M452.6,51l10.1-12h-68.6v12H452.6z M394.1,81.3h33l10.1-12h-43.1V81.3z M439.8,66.1l10.1-12h-55.8v12H439.8z"/>
<path class="st1" d="M143.2,129.3h47.9v12h-57.9L143.2,129.3z M343.2,129.3h-47.9v12h58L343.2,129.3z M78.1,96.5h82.1v-12H68
L78.1,96.5z M80.5,99.3l10.1,12h69.7v-12H80.5z M414.3,96.5l10.1-12H326v12H414.3z M412,99.3h-86v12h75.9L412,99.3z M157.2,172.6
l10.1,12h23.8v-12H157.2z M329,172.6h-33.8v12H319L329,172.6z M341.1,158.3H145.2l10.1,12H331L341.1,158.3z M353.2,143.8H133
l10.1,12h200L353.2,143.8z M182.7,111.2v-4.9h-10.8V84.5h-7.3v26.7H182.7z M193.8,111.2V84.5h-7.3v26.7H193.8z M206.6,111.2V92.7
h0.4l5.9,18.5h5.6l6-18.5h0.3v18.5h7V84.5h-10.2l-5.6,17.3h-0.3l-5.6-17.3h-10.3v26.7H206.6z M245,111.2V84.5h-7.3v26.7H245z
M263.6,111.2V89.4h7.7v-4.9h-22.6v4.9h7.7v21.8H263.6z M294.5,111.2v-4.7h-12.3v-6.4h10.5v-4.7h-10.5v-6.1h12.3v-4.7H275v26.7h19.5
V111.2z M309.6,111.2c2.5,0,4.5-0.1,6.1-0.4c1.6-0.2,2.8-0.7,3.7-1.3c0.9-0.6,1.6-1.4,2-2.4s0.6-2.3,0.7-3.8s0.1-3.4,0.1-5.5
s0-3.9-0.1-5.5c-0.1-1.5-0.3-2.8-0.7-3.8c-0.4-1-1-1.8-2-2.4c-0.9-0.6-2.1-1-3.7-1.3c-1.6-0.2-3.6-0.4-6.1-0.4h-10.1v26.7h10.1
V111.2z M306.8,89.2h2.7c1,0,1.9,0,2.6,0.1s1.2,0.2,1.6,0.5s0.7,0.7,0.9,1.3c0.2,0.6,0.3,1.5,0.4,2.5c0,1.1,0.1,2.5,0.1,4.2
s0,3.1-0.1,4.2c0,1.1-0.2,1.9-0.4,2.5c-0.2,0.6-0.5,1-0.9,1.3s-0.9,0.4-1.6,0.5s-1.5,0.1-2.6,0.1h-2.7V89.2z M124.7,81.8
c2.1-0.2,4.3-0.4,6.4-0.6c2.1-0.3,4.1-0.5,5.8-0.9V47.5h-19.2v11.1h5.7v11.8c-0.4,0.1-0.8,0.1-1.3,0.1s-1,0.1-1.5,0.1s-0.9,0-1.4,0
c-1.4,0-2.6-0.1-3.6-0.3c-1-0.2-1.8-0.7-2.4-1.3c-0.6-0.6-1.1-1.6-1.4-2.9s-0.6-3.1-0.7-5.2c-0.1-2.2-0.2-4.9-0.2-8.2
c0-3.5,0.1-6.3,0.3-8.5c0.2-2.2,0.5-4,0.9-5.2c0.4-1.3,1.1-2.2,2.1-2.7c0.9-0.6,2.2-0.9,3.7-1s3.5-0.2,5.9-0.2c0.8,0,1.9,0,3.3,0.1
c1.3,0.1,2.8,0.2,4.5,0.3c1.6,0.1,3.3,0.3,5.1,0.5V25c-1.7-0.3-3.6-0.6-5.7-0.9c-2.1-0.3-4.1-0.5-6.2-0.7s-4-0.3-5.6-0.3
c-3.8,0-7,0.3-9.7,0.8c-2.6,0.6-4.8,1.5-6.6,2.8c-1.8,1.3-3.1,3-4.1,5.3c-1,2.2-1.7,5-2,8.4c-0.4,3.4-0.6,7.4-0.6,12.2
c0,4.7,0.2,8.7,0.5,12.1c0.4,3.4,1,6.2,2,8.5c1,2.2,2.3,4,4.1,5.3c1.7,1.3,3.9,2.2,6.5,2.8c2.6,0.6,5.8,0.8,9.5,0.8
C120.5,82.1,122.5,82,124.7,81.8z M171.2,81.4V62l16-38.2h-15.1l-7.9,23.5h-0.7l-8.1-23.5H140L156,62v19.4H171.2z M205,81.4V43.6
h0.8l8.4,37.8h11.4l8.4-37.8h0.7v37.8h14.6V23.7h-21.4l-7.4,33.9h-0.7l-7.4-33.9h-21.6v57.6L205,81.4L205,81.4z M288.7,81.1
c2.7-0.6,4.8-1.6,6.4-2.9s2.7-3,3.4-5c0.7-2,1-4.4,1-7.1c0-3.1-0.3-5.5-1-7.4s-1.7-3.3-3.1-4.3s-3.1-1.7-5.2-2.2v-0.3
c3-0.7,5.2-2.1,6.7-4.3c1.4-2.1,2.2-5.2,2.2-9.4c0-2.7-0.4-5.1-1.1-7c-0.8-1.9-1.9-3.5-3.6-4.7c-1.6-1.2-3.7-2.1-6.2-2.6
s-5.6-0.8-9.3-0.8c-1.6,0-3.4,0.1-5.4,0.2s-4,0.3-5.9,0.6s-3.7,0.5-5.3,0.9v11.1c1.4-0.2,2.9-0.4,4.4-0.5c1.6-0.1,3.2-0.2,4.8-0.3
c1.6-0.1,3.2-0.1,4.6-0.1c2.3,0,4,0.1,5.1,0.3s1.9,0.7,2.2,1.5c0.4,0.8,0.5,2.1,0.5,3.9c0,1.6-0.1,2.8-0.4,3.7
c-0.3,0.8-0.8,1.4-1.5,1.7c-0.8,0.3-1.9,0.5-3.5,0.5H270v11.6h8.5c1.6,0,2.9,0.2,3.7,0.5c0.9,0.3,1.4,0.9,1.8,1.8
c0.3,0.9,0.5,2.3,0.5,4.1s-0.2,3.1-0.6,3.9s-1.2,1.3-2.3,1.6c-1.1,0.2-2.7,0.3-4.8,0.3c-1.2,0-2.6,0-4.2-0.1s-3.3-0.2-5.1-0.3
c-1.8-0.1-3.6-0.3-5.4-0.5v10.9c1.6,0.3,3.4,0.6,5.4,0.9s4,0.5,6,0.6s3.8,0.2,5.4,0.2C282.7,82.1,286,81.7,288.7,81.1z M336.7,81
c2.6-0.7,4.6-1.9,6.1-3.4c1.5-1.6,2.5-3.6,3.1-6.2s0.9-5.7,0.9-9.5c0-3.6-0.3-6.6-1-8.8c-0.7-2.3-1.6-4-2.9-5.2s-2.9-2-4.8-2.5
c-2-0.4-4.2-0.7-6.7-0.7c-2.1,0-3.9,0.2-5.3,0.6s-2.5,0.9-3.2,1.5c-0.8,0.6-1.3,1.2-1.7,1.8h-0.6c0-3,0.1-5.4,0.3-7.2
c0.2-1.8,0.7-3.2,1.3-4.1c0.7-0.9,1.7-1.6,3-1.9c1.3-0.3,3.1-0.5,5.3-0.5c1,0,2.2,0,3.6,0.1s2.9,0.2,4.6,0.3s3.4,0.3,5.3,0.5v-11
c-1.8-0.4-3.7-0.7-5.6-1c-2-0.3-3.8-0.5-5.7-0.6c-1.8-0.2-3.5-0.3-4.8-0.3c-3.9,0-7.2,0.3-9.8,1c-2.6,0.7-4.8,1.8-6.5,3.3
s-3,3.6-4,6.2c-0.4,1.3-0.8,2.9-1.2,4.8c-0.3,1.9-0.6,4-0.7,6.3s-0.2,4.9-0.2,7.8c0,3.2,0.1,6,0.2,8.5s0.3,4.7,0.6,6.5
c0.3,1.8,0.6,3.4,1,4.5c1,2.8,2.3,4.9,4,6.3s3.8,2.4,6.3,2.8c2.5,0.5,5.6,0.7,9.2,0.7C330.9,82.1,334.1,81.7,336.7,81z M323.5,70.2
c-0.8-0.2-1.3-0.6-1.8-1.1c-0.4-0.5-0.7-1.3-0.8-2.3c-0.1-1-0.2-2.3-0.2-4c0-1.3,0-2.3,0.1-3.1s0.3-1.5,0.7-2c0.4-0.5,1-0.9,1.7-1
c0.8-0.2,1.8-0.3,3.1-0.3c1.3,0,2.3,0.1,3.1,0.3c0.8,0.2,1.4,0.5,1.8,1s0.7,1.2,0.9,2.1c0.1,0.9,0.2,2.2,0.2,3.7
c0,1.5-0.1,2.7-0.2,3.6s-0.4,1.6-0.9,2.1c-0.4,0.5-1.1,0.8-1.8,1c-0.8,0.2-1.8,0.3-3.1,0.3C325.2,70.5,324.3,70.4,323.5,70.2z
M388.1,49.2c-1.2-1.5-2.8-2.6-4.8-3.4c-2-0.8-4.6-1.1-7.6-1.1c-0.9,0-1.9,0-2.9,0s-1.9,0.1-2.8,0.2c-0.9,0.1-1.7,0.2-2.5,0.3v-9.5
h95.3l-10.1-12h-98.5v33.4c1.4-0.2,3-0.4,4.5-0.5c1.6-0.1,3.1-0.2,4.7-0.3s3-0.1,4.3-0.1c1.8,0,3.3,0.1,4.3,0.3
c1.1,0.2,1.9,0.5,2.5,0.9s1,1.1,1.2,2.1c0.2,0.9,0.3,2.2,0.3,3.7c0,1.6-0.1,2.8-0.3,3.7c-0.2,0.9-0.6,1.6-1.1,2.1
c-0.6,0.4-1.4,0.8-2.5,0.9c-1.1,0.2-2.5,0.3-4.3,0.3c-1.2,0-2.6,0-4.2-0.1s-3.2-0.2-4.9-0.3c-1.7-0.1-3.4-0.3-5.1-0.5v11
c1.7,0.3,3.6,0.6,5.5,0.9c2,0.3,3.9,0.4,5.9,0.6c2,0.1,3.8,0.2,5.4,0.2c4.2,0,7.6-0.4,10.3-1.1c2.7-0.8,4.8-1.9,6.3-3.5
s2.6-3.6,3.2-6.1s0.9-5.5,0.9-9.1c0-2.8-0.2-5.4-0.7-7.5C390.1,52.5,389.2,50.7,388.1,49.2z M30,35.8h62.2v-12H40.1L30,35.8z"/>
</svg>
</template>

