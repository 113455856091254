<template>

  <h2 class="content-block">
    <i class="dx-icon nav-icon fa-light fa-dumbbell"></i> 
    {{ title }}
  </h2>

  <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block">

    <div class="dx-card no-paddings dx-card-has-minheight">

      <DxDataGrid
        id="gridContainer"
        :data-source="customDataSource"

        :focused-row-enabled="false"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        
        :allow-column-reordering="true"
        :show-borders="false"

        @editing-start="logEvent('EditingStart')"
        @init-new-row="logEvent('InitNewRow')"
        @row-inserting="logEvent('RowInserting')"
        @row-inserted="logEvent('RowInserted')"
        @row-updating="logEvent('RowUpdating')"
        @row-updated="logEvent('RowUpdated')"
        @row-removing="logEvent('RowRemoving')"
        @row-removed="logEvent('RowRemoved')"
        @saving="logEvent('Saving')"
        @saved="logEvent('Saved')"
        @edit-canceling="logEvent('EditCanceling')"
        @edit-canceled="logEvent('EditCanceled')"
      >

      <DxPaging :page-size="50" />
      <DxPager :show-page-size-selector="true" :show-info="true" />
      <DxFilterRow :visible="true" />

      <DxEditing
        :allow-updating="true"
        :allow-deleting="true"
        :allow-adding="true"
        mode="row"
      />

      <DxColumn
          alignment='left' 
          data-field="Subject"
          :caption="currentUser.Translation.vueappTaskSubject"
          :placeholder="currentUser.Translation.vueappTaskSubject"
          cell-template="subjectTemplate"
          :hiding-priority="99"
        />
        <template #subjectTemplate="{ data: content }">
          <div class="grid-x align-middle ck-itemrow">
            <div class="cell">
              <p class="ck-overflow-ellipsis strong">
                {{ content.data.Subject }}
              </p>
              <p class="ck-overflow-ellipsis small">
                {{ content.data.Subject_en }}
              </p>
            </div>
          </div>
        </template>

      <DxMasterDetail
          :enabled="true"
          template="masterDetailTemplate"
        />
        <template #masterDetailTemplate="{ data: content }">
          <DetailTemplate
            :template-data="content"
          />
        </template>

    </DxDataGrid>

  </div><!-- dx-card -->
  
  </div><!-- content-block -->

  <div v-else class="content-block">
    Oh no 😢
  </div><!-- content-block -->

  <div class="content-block hide">
    <div id="events">

      <div class="grid-x grid-margin-x"> 
        <div class="auto cell"> 
          <div class="caption">
            Fired events:
          </div>
          <ul class="menu vertical">
          <li
            v-for="(event, index) in events"
            :key="index"
          >{{ event }}</li>
          </ul>
        </div>
        <div class="shrink cell"> 
          <DxButton
            id="clear"
            text="Clear"
            @click="clearEvents()"
          />
        </div>
      </div><!-- grid-x -->

    </div><!-- events -->
  </div><!-- content-block -->

</template>

<script>

import {
  DxDataGrid, 
  DxFilterRow, 
  DxColumn, 
  DxEditing, 
  DxPager, 
  DxPaging, 
  DxButton,
  DxMasterDetail,
  //DxLookup, 
} from 'devextreme-vue/data-grid';
//import { employees } from '../datamembers.js';

import CustomStore from 'devextreme/data/custom_store';

import DetailTemplate from '../parts/exercise-plan-detail-master.vue';

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 3,
  lg: 4
}

let currentUser;

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;
  } // e.data
}); //auth

function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

const customDataSource = new CustomStore({
  key: 'id',
  load: () => {
    return fetch(apihost+'/de/vue/exerciseplan/list/')
    .then(handleErrors)
    .then(response => response.text())
    .then(data => {
    //console.log(`response text`, text)
    return JSON.parse(data)
    })
    .catch(() => { throw 'Network error' });
  },
  insert: (values) => {
    //console.log(values);
    const key = '0';
    const objString = '?' + new URLSearchParams(values).toString();
    return fetch(apihost+'/de/vue/exerciseplan/getexerciseplan/'+key+'/insert'+objString, {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  },
  remove: (key) => {
    //console.log(key);
    return fetch(apihost+'/de/vue/exerciseplan/getexerciseplan/'+key+'/remove', {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  },
  update: (key, values) => {
    // console.log(key);
    // console.log(values);
    const objString = '?' + new URLSearchParams(values).toString();
    return fetch(apihost+'/de/vue/exerciseplan/getexerciseplan/'+key+'/save'+objString, {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  }

});

export default {
  components: {
    DxDataGrid,
    DxMasterDetail,
    DxFilterRow,
    DxColumn,
    DxEditing,
    DxPager,
    DxPaging,
    DxButton,

    DetailTemplate,

    //DxRangeRule,
    //DxEmailRule,
    //DxStringLengthRule,
    //DxAutocomplete,
    //DxAsyncRule,

    //DxLookup,
  },
  data() {
    return {
      apihost,

      title: currentUser.Translation.vueappNavMemberExerciseplan,
      events: [],
      formInstance: null,
      currentUser,
      customDataSource,
      colCountByScreen,

      calendarOptions: { 
        displayFormat: "dd.MM.yyyy"
      },
      buttonOptions: {
        text: currentUser.Translation.vueappExerciseplanFormSubmit,
        type: 'success',
        useSubmitBehavior: true,
      },
      dateBoxOptions: {
        invalidDateMessage:
          'The date must have the following format: MM/dd/yyyy',
      },
      checkBoxOptions: {
        text: 'I agree to the Terms and Conditions',
        value: false,
      },
      phoneEditorOptions: {
        mask: '+1 (X00) 000-0000',
        maskRules: {
          X: /[02-9]/,
        },
        maskInvalidMessage: 'The phone must have a correct USA phone format',
      },

      statusPattern: /^[^0-9]+$/,
      priorityPattern: /^[^0-9]+$/,
      exerciseplanPattern: /^[^0-9]+$/,

      // phonePattern: /^[02-9]\d{9}$/,
      // maxDate: new Date().setFullYear(new Date().getFullYear() - 21),
      
      validationRules: {

        status: [
          { type: 'required', message: currentUser.Translation.vueappTaskStatusMessage },
        ],
        priority: [
          { type: 'required', message: currentUser.Translation.vueappTaskPriorityMessage },
        ],
      },
      statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
      priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },

    };
  },
  methods: {

    validateForm(e) {
      e.component.validate();
    },
    
    logEvent(eventName) {
      //console.log(this.events);
      this.events.unshift(eventName);
    },
    clearEvents() {
      this.events = [];
    },

    printTimestamp: function () {
        return Date.now();
    },
  },
};

</script>