<template>
    <svg class="x30-logo" viewBox="0 0 97 39.554" fill="none" xmlns="http://www.w3.org/2000/svg">

        <path class="st0" d="M264.4,10.317a3.451,3.451,0,0,1,1.367,2.62,16.993,16.993,0,0,1,0,4.23,3.793,3.793,0,0,1-3.993,3.554c-5.347.046-10.694.007-16.041.021-.411,0-.459-.158-.455-.5.018-1.2.035-2.4-.005-3.6-.018-.519.094-.678.653-.675,4.317.03,8.633.019,12.948.019.239,0,.48,0,.719-.016a1.391,1.391,0,0,0,1.473-1.631c-.012-1.006-.464-1.507-1.5-1.517q-4.425-.045-8.847-.014c-.579.005-.722-.161-.7-.715.044-1.1.048-2.208,0-3.307-.025-.574.1-.767.722-.756,2.709.042,5.42.021,8.129.018A6.316,6.316,0,0,0,259.73,8c.97-.129,1.351-.595,1.337-1.606-.014-.992-.413-1.466-1.424-1.5-1.579-.058-3.162-.05-4.745-.053-3-.007-6-.016-8.993.007-.51,0-.644-.143-.63-.641.035-1.222.021-2.445.007-3.669,0-.345.053-.512.462-.508,5.25.03,10.5-.014,15.751.085,2.948.055,4.377,1.675,4.363,4.632a19.5,19.5,0,0,1-.1,3.229A2.985,2.985,0,0,1,264.4,10.317Z" transform="translate(-201.844 -0.024)"/>
        <path class="st0" d="M406.522,5.282c0-2.946-1.424-4.541-4.375-4.623C398.412.556,394.672.6,390.934.59c-.31,0-.62.034-.93.06a3.994,3.994,0,0,0-4.032,4.377c-.018,3.931-.012,7.86-.007,11.79,0,2.828,1.634,4.455,4.465,4.464q2.911.008,5.823,0c1.988,0,3.978.016,5.967,0a3.916,3.916,0,0,0,4.125-3.077,6.615,6.615,0,0,0,.175-1.567C406.515,12.854,406.522,9.067,406.522,5.282Zm-4.814,9.9a1.237,1.237,0,0,1-1.4,1.319q-4.063.045-8.127,0c-.968-.012-1.429-.506-1.454-1.5-.034-1.365-.007-2.734-.007-4.1h-.007c0-1.271-.018-2.543.007-3.812.025-1.19.407-1.638,1.578-1.67,2.682-.073,5.368-.094,8.051.018.922.039,1.321.409,1.358,1.337Q401.882,10.981,401.708,15.186Z" transform="translate(-317.625 -0.485)"/>
        <path class="st0" d="M528.56,11.639a1.258,1.258,0,0,0-.287-.223.836.836,0,0,0,.526-.246.708.708,0,0,0,.175-.494.777.777,0,0,0-.115-.42.613.613,0,0,0-.31-.255,1.938,1.938,0,0,0-.62-.071H526.8v2.659h.536v-1.11h.11a.853.853,0,0,1,.271.03.4.4,0,0,1,.161.112,3.589,3.589,0,0,1,.283.391l.388.579h.643l-.324-.519A3.294,3.294,0,0,0,528.56,11.639Zm-.827-.584h-.4V10.38h.42c.218,0,.349,0,.391.009a.328.328,0,0,1,.2.1.318.318,0,0,1,.071.216.342.342,0,0,1-.055.2.3.3,0,0,1-.151.113A2.082,2.082,0,0,1,527.733,11.054Z" transform="translate(-525.208 -8.593)"/>
        <path class="st0" d="M520.469,2.38a2.658,2.658,0,1,0,2.659,2.659A2.663,2.663,0,0,0,520.469,2.38Zm0,4.889A2.229,2.229,0,1,1,522.7,5.039,2.232,2.232,0,0,1,520.469,7.269Z" transform="translate(-517.81 -2.38)"/>
        <path class="st1" d="M.883,0H7.39A.892.892,0,0,1,8.017.26L19.292,11.537a.885.885,0,0,0,1.261-.009L31.532.267A.881.881,0,0,1,32.166,0h6.5a.886.886,0,0,1,.885.885V6.942a.886.886,0,0,1-.251.618L27.992,19.15A.887.887,0,0,0,28,20.4L39.292,31.688a.884.884,0,0,1,.259.627v6.355a.886.886,0,0,1-.885.885H32.456a.886.886,0,0,1-.618-.251L20.26,28.007a.884.884,0,0,0-1.255.019L8.171,39.282a.882.882,0,0,1-.637.271H.885A.886.886,0,0,1,0,38.667V32.16a.892.892,0,0,1,.259-.627L11.542,20.25a.886.886,0,0,0,0-1.252L.258,7.714A.884.884,0,0,1,0,7.088V.885A.883.883,0,0,1,.883,0Z" transform="translate(0 0)"/>

    </svg>
</template>

