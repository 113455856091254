<template>

    <div class="content-block">
      <h2 class="main-title">{{ msg }}</h2>
      <div class="dx-card has-paddings">
      <p><a @click="openReveal()">Click me for a modal</a></p>
      </div><!-- dx-card -->

      <div style="height: 10px;"></div>
      <p style="margin: 15px 5px; font-size: 11px;"><a href="https://get.foundation/sites/docs/reveal.html" target="_blank" style="color: grey;"><i class="fa-solid fa-arrow-up-right-from-square"></i> https://get.foundation/sites/docs/reveal.html</a></p>
 
    </div><!-- content-block -->

    <div id="reveal-dialog" class="reveal" data-reveal>
      <div class="content">
        <h2 class="no-margin-top">Awesome. I Have It.</h2>
        <p class="lead">Your couch. It is mine.</p>
        <p>I'm a cool paragraph that lives inside of an even cooler modal. Wins!</p>
        <button class="close-button" data-close aria-label="Close modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div><!-- content -->
    </div><!-- reveal-dialog -->

</template>

<script>
 
import $ from 'jquery';
import Foundation from 'foundation-sites';

export default {
  name: 'reveal-example',
  mounted() {
    this.reveal = new Foundation.Reveal($('#reveal-dialog'), {
      // These options can be declarative using the data attributes
      animationIn: 'scale-in-up',
    });
  },
  data() {
    return {
      msg: 'Reveal',
    };
  },
  methods: {
    // Added the below openReveal method for two reasons:
    // 1) There was a bug preventing the reveal from working once
    // you navigated away and back to the reveal component.
    // 2) Most dialogs will need to be opened using code.
    openReveal() {
      this.reveal.open();
    },
  },
  unmounted() {
    this.reveal.destroy();
  },
};
</script>

<style lang="scss">
</style>
