<template>

  <div v-if="email" class="user-panel">
    <div class="user-info">
      <div class="image-container">
        <div class="user-image" v-bind:style="{ 'background-image': 'url(' + avatar + '?v=' + timestamp + ')' }">
        </div>
      </div>
      <div class="user-name">{{email}}</div>
    </div>

    <dx-context-menu
      v-if="menuMode === 'context'"
      target=".user-button"
      :items="menuItems"
      :width="210"
      show-event="dxclick"
      css-class="user-menu"
    >
      <dx-position my="top center" at="bottom center" />
    </dx-context-menu>

    <dx-list
      v-if="menuMode === 'list'"
      class="dx-toolbar-menu-action"
      :items="menuItems"
    />
  </div>
</template>

<script>
import DxContextMenu, { DxPosition } from "devextreme-vue/context-menu";
import DxList from "devextreme-vue/list";

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

export default {
  props: {
    menuMode: String,
    menuItems: Array,
    //email: String,
  },
  components: {
    DxContextMenu,
    DxPosition,
    DxList
  },

  mounted() {

    this.timestamp = this.printTimestamp();

    auth.getUser().then((e) => {
      
      if(e.data){
        // console.log(e.data);
        // console.log(e.data.Email);
        // console.log(e.data.Avatar);
        //this.email = e.data.Email;
        //this.avatar = e.data.Avatar;

        setTimeout(() => {
          fetch(apihost+'/de/vue/member/getmember/'+e.data.id+'/view')
            .then(response => response.text())
            .then(result => {
            const data = JSON.parse(result);
            //console.log(data)
            this.email = data.Email;
            this.avatar = data.Avatar;
          })
          .catch(() => { throw 'Network error' });
        }, 200);


      } // e.data

    }); //auth

  },

  data() {
    return {
      apihost,
      
      avatar: '',
      email: '',
    };
  },

  methods: {

    printTimestamp: function () {
      return Date.now();
    },
    
  },

};
</script>

<style>

</style>
