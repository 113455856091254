<template>

  <!-- mainhide -->
  <div class="hide" style="font-size: 10px; padding: 10px; overflow: hidden;">

    <!-- subhide -->
    <div class="-hide" style="color: orange;">
      <p>exerciseDone: {{ this.templateData }}</p>
    </div>
    <!-- /subhide -->

    &nbsp;

    <!-- subhide -->
    <div class="-hide" style="color: green;">

    </div>
    <!-- /subhide -->

  </div>
  <!-- /mainhide -->

  <div class="user-base-wrap">
    <!-- LEGEND -->
    <div class="grid-x align-middle" style="height: 24px; padding: 0 10px; border-bottom: 1px solid #e0e0e0;">
      <div class="shrink cell" style="width: 140px;">
        <p class="ck-overflow-ellipsis small" style="font-size: 10px; color: grey;"><i class="fa-light fa-calendar-days"></i> Datum</p>
      </div>
      <div class="auto cell">
        <p class="ck-overflow-ellipsis small" style="font-size: 10px; color: grey;"><i class="fa-light fa-dumbbell"></i> Übung</p>
      </div>
      <div class="shrink cell" style="width: 50px;">
        <p class="ck-overflow-ellipsis small" style="font-size: 10px; color: grey;">Wdhl.</p>
      </div>
      <div class="shrink cell" style="width: 50px;">
        <p class="ck-overflow-ellipsis small" style="font-size: 10px; color: grey;"><i class="fa-light fa-weight-hanging"></i> kg</p>
      </div>
      
    </div>
  
    <!-- LIST -->
    <div v-for="exercise in this.templateData" 
      :key="exercise.id" 
      class="grid-x align-middle" 
      style="height: 24px; padding: 0 10px; border-bottom: 1px solid #e0e0e0;"
    >
      <div class="shrink cell" style="width: 140px;">
        <p style="font-size: 10px;" class="ck-overflow-ellipsis small">{{ formatDateTs(exercise.date) }}</p>
      </div>
      <div class="auto cell">
        <p style="font-size: 10px;" class="ck-overflow-ellipsis small">{{ exercise.exercisetext }}</p>
      </div>
      <div class="shrink cell" style="width: 50px;">
        <p v-if="exercise.type == 'time'" style="font-size: 10px;" class="ck-overflow-ellipsis small"><i class="fa-light fa-stopwatch"></i> {{ exercise.time }}</p>
        <p v-if="exercise.type == 'repeat'" style="font-size: 10px;" class="ck-overflow-ellipsis small"><i class="fa-light fa-repeat"></i> {{ exercise.rep }}</p>
      </div>
      <div class="shrink cell" style="width: 50px;">
        <!-- <p v-if="exercise.weight" class="ck-overflow-ellipsis small"><i class="fa-light fa-weight-hanging"></i> {{ exercise.weight }}</p> -->
        <p v-if="exercise.weight" style="font-size: 10px;" class="ck-overflow-ellipsis small">{{ exercise.weight }}</p>
        <p v-if="!exercise.weight" style="font-size: 10px;" class="ck-overflow-ellipsis small">0</p>
      </div>

    </div><!-- v-for -->

  </div><!-- user-base-wrap -->
 
</template>

<script>

export default {
  name: 'training-data',

  props: {
    templateData: Object,
  },

  mounted() {


  },

  data() {
    return {


    };
  },

  methods: {

    formatDateTs(ts) {
      //console.log(ts);
      const timestamp = parseInt(ts); // timestamp
      const date = new Date(timestamp);
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

      // Get Hours part from the timestamp
      var hours = '0' + date.getHours();
      hours = hours.slice(-2);
      //console.log(hours);

      // Get Minutes part from the timestamp
      var minutes = '0' + date.getMinutes();
      minutes = minutes.slice(-2);
      //console.log(minutes);

      // Get Seconds part from the timestamp
      var seconds = '0' + date.getSeconds();
      seconds = seconds.slice(-2);
      //console.log(seconds);
      
      //var time = '00:00:00';
      var time = hours+':'+minutes+':'+seconds;
      //console.log(time);

      const formattedDate = new Intl.DateTimeFormat('de-DE', options).format(date) + ' | ' + time;
      //console.log(formattedDate);

      return formattedDate;
    },

  },

};


</script>
