<template>
<svg version="1.1" id="WomanBack" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 450 950" style="enable-background:new 0 0 450 950;" xml:space="preserve">
<g id="Hand_WB">
<path class="st0" d="M376.5,485.2c0,0,2.5,15.8,2.5,22.7s1.6,17.6,3.1,23c1.6,5.4,4.1,16.4,4.7,18.4s1.3,5.1,4.1,2.1
c2.8-3,3.8-7.2,2.4-13s-3-11.3-1.7-14s1.3-2.7,1.3-2.7s5.4,3.4,6.6,9.6c1.3,6.2,0,10.3-1.7,14s-4.8,7.9-5.8,10.4s-1.3,3.6-0.8,4.8
c0,0-2.5,3-3.1,5.4s0.6,3.2,0.6,3.2s-2.3,3.2-1,4.4c1.3,1.1,1.8,1.4,4-1c2.1-2.4,7.6-5.9,9.6-7.2s7.1-4.1,8.3-6.9
c1.3-2.8,2.1-10.4,2.1-10.4s2.8-10.4,3.2-15s1.1-15-0.4-21.7s-5.2-13.3-7.9-19.2c-2.7-5.9-6.2-14-6.2-14s-2,0.4-6.4-2
s-7.6-6.1-7.6-6.1s-0.1,3-3,8.3C380.6,483.8,376.5,485.2,376.5,485.2z"/>
<path class="st0" d="M71.2,485.2c0,0-2.5,15.8-2.5,22.7
s-1.6,17.6-3.1,23c-1.6,5.4-4.1,16.4-4.7,18.4c-0.6,2-1.3,5.1-4.1,2.1s-3.8-7.2-2.4-13s3-11.3,1.7-14s-1.3-2.7-1.3-2.7
s-5.4,3.4-6.6,9.6c-1.3,6.2,0,10.3,1.7,14s4.8,7.9,5.8,10.4s1.3,3.6,0.8,4.8c0,0,2.5,3,3.1,5.4s-0.6,3.2-0.6,3.2s2.3,3.2,1,4.4
c-1.3,1.1-1.8,1.4-4-1c-2.1-2.4-7.6-5.9-9.6-7.2s-7.1-4.1-8.3-6.9C36.8,555.6,36,548,36,548s-2.8-10.4-3.2-15
c-0.4-4.5-1.1-15,0.4-21.7c1.5-6.7,5.2-13.3,7.9-19.2c2.7-5.9,6.2-14,6.2-14s2,0.4,6.4-2s7.6-6.1,7.6-6.1s0.1,3,3,8.3
C67.2,483.6,71.2,485.2,71.2,485.2z"/>
</g>
<g id="Fuss_WB">
<path class="st0" d="M163.2,896.7c-3.2-0.5-5.2-2-6.4-3.2c0,0.1,0,0.2,0,0.3c0.1,2.5,0.4,4.5,0.7,6.3c0.4,2.4,0.8,4.7,0.6,7.9
c-0.2,2.8-1.3,14.8-2.4,17.8c-1.1,3.1-2.2,6.1-2.3,8.6s0.4,6.1,3.2,9c2.9,3,7.5,4.5,13.6,4.5c10.8,0,14.4-2.6,16.6-6.4
c2.8-4.9,2.2-12.1,1.7-18.4c-0.2-2.6-0.5-13.9-0.5-16c0.1-2.1,0.7-4.4,1.5-7.2c1.3-4.5,2.7-9.5,1.9-14.8c-0.1-0.6-0.2-1.2-0.3-1.7
c-10,9-18.5,13.5-25.5,13.5C164.8,896.9,164,896.9,163.2,896.7z"/>
<path class="st0" d="M284.8,896.7c-0.8,0.1-1.6,0.2-2.4,0.2c-7,0-15.5-4.5-25.5-13.5c-0.1,0.5-0.2,1.1-0.3,1.7
c-0.8,5.2,0.7,10.3,1.9,14.8c0.8,2.8,1.4,5.1,1.5,7.2c0.1,2.1,0,13.4-0.3,16c-0.5,6.3-1.1,13.5,1.7,18.4c2.2,3.9,5.9,6.4,16.6,6.4
c6.2,0,10.7-1.5,13.6-4.5c2.8-2.9,3.3-6.5,3.2-9c-0.1-2.5-1.2-5.5-2.3-8.6c-1.1-3.1-2.4-15-2.6-17.8c-0.2-3.2,0.1-5.5,0.6-7.9
c0.3-1.9,0.6-3.8,0.7-6.3c0-0.1,0-0.2,0-0.3C290,894.7,288,896.2,284.8,896.7z"/>
</g>
<g id="Waden_WB">
<path class="st0" d="M163.7,895.2c0.9,0.1,1.9,0.2,3.1,0.1c2.8-37.6-3.4-64.1-9-79.7c-4.2-11.7-8.8-19.2-11.3-22.7
c2.3,14.9,5,28.2,7.4,39.9c1.1,5.6,2.2,10.8,3.1,15.8c0.4,2.2,0.9,4.4,1.3,6.5c1.6,7.9,3.1,15.4,2.7,21.3c-0.3,3.6-1.2,6.1-2.1,8.5
c-0.7,2-1.5,3.9-1.7,6.2C157.5,892,159.5,894.6,163.7,895.2z"/>
<path class="st0" d="M177,892c3.9-2.1,8.5-5.4,14-10.4c-0.5-2.2-1.1-3.5-1.7-4.7c-0.7-1.6-1.5-3.3-2.1-6.8
c-1.6-8.9-1.1-42.1-1-48.6c0.2-10.4,1.4-21,3-31.2c-1.4-1.8-2.6-3.7-3.9-5.5C182.4,795.8,166,860.2,177,892z"/>
<path class="st0" d="M166.8,719.8c0-1.1,0.1-2.2,0.2-3.3c1.1-24.7-2.5-40.6-3.9-45.6c-1.5,2.8-3.1,5.7-4.7,8.6
c-3.1,5.5-6.2,11.2-9.4,17.6c-0.2,1.1-0.7,2.9-1.4,5.3c-2.6,8.6-7.6,24.7-6,47.4c0.8,12.2,2.1,23.6,3.6,34.2
c4.3-7.4,20-35.9,21.7-63.9C166.8,719.9,166.8,719.9,166.8,719.8z"/>
<path class="st0" d="M182.3,668.2c-2.4,4.3-9.4,18.2-12.5,37.2c-0.6,3.6-1.1,7.5-1.3,11.7c0,0.5-0.1,1.1-0.1,1.6
c-0.9,19.6,3.2,44.5,21.2,69.4c0.8-5.2,1.7-10.3,2.6-15.2c2.3-12.8,4.4-24.8,4.5-34.3c0.1-11.4-1-20.7-2-28.9
c-1-8.1-1.8-15.2-1-21.4C189.6,684.5,185.6,676.5,182.3,668.2z"/>
<path class="st0" d="M281.6,895.4c1.2,0.1,2.2,0,3.1-0.1c4.2-0.7,6.1-3.3,6.6-4c-0.3-2.3-1-4.2-1.7-6.2c-0.9-2.4-1.9-4.9-2.1-8.5
c-0.5-5.9,1.1-13.4,2.7-21.3c0.4-2.1,0.9-4.3,1.3-6.5c0.9-5,2-10.3,3.1-15.8c2.4-11.7,5-25,7.4-39.9c-2.5,3.6-7.1,11.1-11.3,22.8
C285,831.4,278.9,857.8,281.6,895.4z"/>
<path class="st0" d="M285.3,670.8c-1.4,5.1-5,21-3.9,45.6c0.1,1.1,0.1,2.2,0.2,3.3c0,0.1,0,0.1,0,0.2c1.7,28,17.4,56.5,21.7,63.9
c1.5-10.6,2.7-22,3.6-34.2c1.5-22.7-3.4-38.8-6-47.4c-0.7-2.4-1.3-4.1-1.4-5.3c-3.1-6.3-6.3-12-9.4-17.6
C288.4,676.5,286.8,673.7,285.3,670.8z"/>
<path class="st0" d="M263,784.7c-1.2,1.9-2.5,3.7-3.9,5.5c1.6,10.2,2.9,20.9,3,31.2c0.1,6.6,0.6,39.7-1,48.6
c-0.6,3.5-1.4,5.1-2.1,6.8c-0.6,1.3-1.1,2.6-1.7,4.7c5.4,5,10.1,8.3,14,10.4C282.3,860.2,266,795.8,263,784.7z"/>
<path class="st0" d="M280,718.7c0-0.5-0.1-1.1-0.1-1.6c-0.2-4.2-0.7-8.1-1.3-11.7c-3.1-18.9-10.1-32.9-12.5-37.2
c-3.3,8.3-7.3,16.2-11.4,20.1c0.8,6.3,0,13.3-1,21.4c-1,8.2-2.1,17.5-2,28.9c0.1,9.5,2.2,21.6,4.5,34.3c0.9,4.9,1.8,10,2.6,15.2
C276.8,763.2,280.9,738.3,280,718.7z"/>
</g>
<g id="Beinbeuger_WB">
<path class="st0" d="M157.3,582.7c-1.3-10.2-2.7-21.1-3.3-32c-3.5,7.1-12.8,27.9-11,46.1c2,9.1,3.5,16.3,4,20.7
c2.2,18.2,1.7,26.9,1.2,36.1c-0.1,1.9-0.2,3.9-0.3,6c3.5-7.2,11.9-26.7,12-49.7C159.9,603,158.7,593.6,157.3,582.7z"/>
<path class="st0" d="M175.7,627.5c2-25.8,3.8-51.2,3.6-77.3c0-5.7-0.4-12-0.8-18.6c-0.8-14-1.6-28.5,0.6-37.3
c-1.6-0.3-3.2-0.6-4.7-1.1c-11.1,5.8-17.2,17.9-18.7,36.9c-0.4,5.7-0.4,11.6-0.2,17.7c0,0.1,0,0.1,0,0.2
c0.5,11.6,2.1,23.6,3.5,34.6c1.4,10.9,2.6,20.4,2.6,27.5c-0.1,28-12.4,51-13.6,53.2c-0.1,6.5,0.3,13.9,0.7,20.4
c0.2,3.9,0.4,7.2,0.5,9.7c2.7-5.2,5.3-10,7.9-14.7C166.3,662.3,174.1,648.1,175.7,627.5z"/>
<path class="st0" d="M189,593.2c0.4-4.8,0.7-9.3,1-13.7c0.4-7.2,0.7-14.9,0.9-23.1c0.7-23.7,1.5-48.2,6.8-64
c-3.8,1.6-8.1,2.4-12.5,2.4c-1.6,0-3.1-0.1-4.7-0.3c-2.2,8.6-1.4,23.1-0.6,37c0.4,6.7,0.7,13,0.8,18.7c0.2,26.2-1.6,51.6-3.6,77.5
c-0.4,4.9-1.1,9.5-2.2,13.8c0.8,2.7,9.8,35,19,44.9c0.8-4.6,2.4-8.3,4.2-12.5c0-0.1,0.1-0.2,0.1-0.3
C184.5,650.7,186.9,620.2,189,593.2z"/>
<path class="st0" d="M271.3,627.6c-2-25.8-3.8-51.2-3.6-77.5c0-5.7,0.4-12,0.8-18.7c0.8-14,1.6-28.4-0.6-37
c-1.6,0.2-3.2,0.3-4.7,0.3c-4.4,0-8.7-0.8-12.5-2.4c5.3,15.8,6.1,40.3,6.8,64c0.3,8.2,0.5,15.9,0.9,23.1c0.3,4.4,0.6,8.9,1,13.7
c2.1,27,4.5,57.5-9.2,80.5c0,0.1,0.1,0.2,0.1,0.3c1.8,4.2,3.3,7.9,4.2,12.5c9.2-9.9,18.3-42.2,19-44.9
C272.4,637.2,271.7,632.6,271.3,627.6z"/>
<path class="st0" d="M289.5,582.4c1.4-11,2.9-22.9,3.5-34.6c0-0.1,0-0.1,0-0.2c0.3-6,0.3-12-0.2-17.7c-1.5-19.1-7.6-31.1-18.7-36.9
c-1.6,0.4-3.1,0.8-4.7,1.1c2.2,8.9,1.4,23.3,0.6,37.3c-0.4,6.7-0.7,12.9-0.8,18.6c-0.2,26.2,1.6,51.5,3.6,77.3
c1.6,20.6,9.5,34.8,18.5,51.2c2.6,4.7,5.3,9.5,7.9,14.7c0-2.5,0.3-5.9,0.5-9.7c0.4-6.5,0.9-14,0.7-20.4
c-1.2-2.2-13.5-25.2-13.6-53.2C286.9,602.8,288.1,593.4,289.5,582.4z"/>
<path class="st0" d="M294.4,550.8c-0.6,10.8-2,21.8-3.3,32c-1.4,10.9-2.6,20.3-2.6,27.3c0.1,23,8.5,42.5,12,49.7
c-0.1-2.1-0.2-4.1-0.3-6c-0.5-9.2-0.9-18,1.2-36.1c0.5-4.4,2-11.6,4-20.7C307.3,578.7,297.9,557.9,294.4,550.8z"/>
</g>
<g id="Innerer_Oberschenkelmuskel_WB">
<path class="st0" d="M258,593.2c-0.4-4.8-0.7-9.3-1-13.7c-0.4-7.2-0.7-14.9-0.9-23.1c-0.8-24.3-1.6-49.4-7.2-64.9
c-0.9-0.5-1.8-0.9-2.7-1.4c-4.2,11.7-0.9,36.7,2.3,60.8c1.2,9,2.4,18.3,3.2,26.8c0.1,1.5,0.3,3,0.4,4.4c1.4,14,2.7,27.2,2.3,38.7
c0,0.1,0,0.1,0,0.2c0,0.2,0,0.3-0.1,0.5c-0.5,13.1-3.2,23.8-10.5,31.1c1.4,8.6,3.6,14.4,5.6,19.3
C262.5,649.3,260.1,619.5,258,593.2z"/>
<path class="st0" d="M252.8,614.8c-0.1-9.8-1.2-20.7-2.4-32.3c-0.1-1.5-0.3-3-0.4-4.4c-0.8-8.5-2.1-17.8-3.2-26.8
c-1-7.4-1.9-14.7-2.7-21.6c-4.7-10.2-11.6-33.9-7.2-46.5c-2.1-2.1-3.8-4.3-5.3-6.5c-0.6,3.1-1.1,7.3-1.1,7.4l0,0l0,0
c0,0.1-2.8,15.3-0.5,41.2c1.5,16.6,5.1,29.7,8.2,41.3c0.4,1.4,0.7,2.7,1.1,4c0.3,1.3,1.3,4.3,2.7,8.6
C245.2,588.8,250,603.7,252.8,614.8z"/>
<path class="st0" d="M216.6,476.5c-1.5,2.2-3.2,4.4-5.3,6.5c4.4,12.5-2.4,36.3-7.2,46.5c-0.8,6.9-1.7,14.2-2.7,21.6
c-1.2,9-2.4,18.3-3.2,26.8c-0.1,1.5-0.3,3-0.4,4.4c-1.1,11.5-2.2,22.4-2.4,32.3c2.9-11.1,7.7-26,10.8-35.5c1.4-4.3,2.4-7.3,2.7-8.6
c0.4-1.3,0.7-2.6,1.1-4c3.2-11.6,6.8-24.8,8.2-41.3c2.3-25.9-0.4-41-0.5-41.2l0,0l0,0C217.8,483.9,217.3,479.7,216.6,476.5z"/>
<path class="st0" d="M194.3,621.4c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c-0.4-11.5,0.9-24.7,2.3-38.9c0.1-1.5,0.3-3,0.4-4.4
c0.8-8.5,2.1-17.9,3.2-26.9c3.2-24.2,6.5-49.3,2.3-61c-0.9,0.5-1.8,1-2.7,1.4c-5.7,15.6-6.5,40.8-7.2,65.2
c-0.3,8.2-0.5,16-0.9,23.2c-0.3,4.4-0.6,8.9-1,13.8c-2.1,26.4-4.4,56.3,8.4,78.9c2.1-5,4.2-10.8,5.6-19.4
C197.6,645.4,194.8,634.6,194.3,621.4z"/>
<path class="st0" d="M243.5,524.2c-1.4-14.8-1.7-27.3,1.1-34.9c-2.5-1.6-4.6-3.3-6.5-5.1C234.9,494.8,239.3,513.1,243.5,524.2z"/>
<path class="st0" d="M204.8,524.1c4.2-11.1,8.7-29.4,5.4-40c-1.9,1.8-4.1,3.5-6.5,5.1C206.5,496.8,206.3,509.4,204.8,524.1z"/>
<path class="st0" d="M205.3,584.2c0,0-5.7,17.6-7.4,24.2c-1.7,6.6-3,10.7-3,10.7s-0.1,10,2.1,17.8c2.2,7.8,5.3,12.8,8.1,14.7
c0,0,2.1-9.7,1.6-18.4c-0.5-8.6-2-20.3-2-28.9c0.1-8.6,1.4-20.7,1.4-20.7L205.3,584.2z"/>
<path class="st0" d="M243,584.2c0,0,5.7,17.6,7.4,24.2c1.7,6.6,3,10.7,3,10.7s0.1,10-2.1,17.8c-2.2,7.8-5.3,12.8-8.1,14.7
c0,0-2.1-9.7-1.6-18.4c0.5-8.6,2-20.3,2-28.9c-0.1-8.6-1.4-20.7-1.4-20.7L243,584.2z"/>
</g>
<g id="Gesaess_WB">
<g>
<path class="st0" d="M174.5,395.3c9.5-9.6,18.4-15,21-16.5c-1.2-1.8-2.3-3.4-3.5-5c0,0,0,0,0-0.1v-0.1c-3.3-4.5-6.8-8.2-10.1-10.6
c-0.1,0-0.1-0.1-0.2-0.1c-2-1.4-4-2.3-5.9-2.7c-8.7-1.6-15.6,10.9-16.1,11.7c-0.8,2.3-1.7,4.8-2.8,7.7c-1.3,3.6-2.8,7.8-4.5,12.5
c0,1.7,0.6,19.8,5.1,25.9C162.7,408.4,169,400.9,174.5,395.3z"/>
<path class="st0" d="M272.5,360.2c-2,0.4-3.9,1.3-5.9,2.7c-0.1,0.1-0.1,0.1-0.2,0.1c-3.4,2.4-6.8,6.1-10.1,10.6v0.1c0,0,0,0,0,0.1
c-1.2,1.6-2.4,3.3-3.5,5c2.5,1.5,11.5,7,21,16.5c5.5,5.5,11.8,13.1,17.1,22.6c4.6-6,5.1-24.2,5.1-25.9c-1.7-4.7-3.2-8.9-4.5-12.5
c-1-2.8-1.9-5.3-2.8-7.7C288.1,371.2,281.2,358.6,272.5,360.2z"/>
</g>
<path class="st0" d="M225,438.7c0,0.1-0.1,0.1-0.1,0.1l0,0c-0.1,0-0.1,0.1-0.2,0.1l0,0l0,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
l0,0l0,0c-0.1,0-0.1-0.1-0.2-0.1l0,0c-0.1,0-0.1-0.1-0.1-0.1l0,0c0-0.1-0.1-0.1-0.1-0.2l0,0c-0.2-0.5-12.4-35.6-27.1-58.7
c-2.3,1.3-11.2,6.7-20.8,16.3c-10.2,10.3-23.1,27.6-27.2,51.7c-3.6,21,6.6,32.5,13.1,37.6c10.5,8.3,25.2,10.7,36,5.9
c23.2-10.4,25.9-36.2,25.9-36.5l0,0v-0.1c0,0,0,0,0-0.1l0,0c0.1-0.3,0.3-0.5,0.6-0.5l0,0c0,0,0,0,0.1,0h0.1l0,0h0.1l0,0h0.1l0,0
c0.1,0.1,0.2,0.2,0.3,0.3l0,0l0,0v0.1l0,0v0.1l0,0c0,0.1,0.7,6.5,4.2,14.3c3.3,7.1,9.7,16.7,21.8,22.1c10.7,4.8,25.5,2.3,36-5.9
c6.4-5,16.7-16.6,13.1-37.6c-4.1-24.2-17.2-41.5-27.3-51.7c-9.5-9.6-18.4-15-20.7-16.3c-14.7,23.2-26.9,58.2-27.1,58.7l0,0
C225.1,438.6,225,438.7,225,438.7L225,438.7z"/>
</g>
<g id="Unterer_Ruecken_WB">
<path class="st0" d="M214.5,329.9c0,0-1.4,16.8-6.5,24.8s-8.2,14-14.8,18.1c0,0,7.2,10.4,14.5,24.3c7.3,13.8,16.9,40.4,16.9,40.4
s11.2-27.8,13.7-32.9s15.1-28.5,17.9-31.8c0,0-9.2-5.1-15.7-20.6s-5.6-23-5.6-23s-1.8-3.1-9.7-2.8S214.5,329.9,214.5,329.9z"/>
</g>
<g id="Seitlicher_Bauch_WB">
<path class="st0" d="M175.9,358.7c1.8,0.3,3.6,1.1,5.5,2.3c1-18.1-20.3-49.1-27.2-58.5c0.2,1,0.4,2,0.6,3
c1.3,6.6,2.7,13.1,4.2,19.4c2.8,12.6,5.3,23.5,5,29.7c-0.2,3.4-1,7.1-2.5,11.7C164.6,362.2,169.7,357.5,175.9,358.7z"/>
<path class="st0" d="M267,360.9c1.9-1.2,3.7-1.9,5.5-2.3c6.1-1.2,11.3,3.5,14.4,7.5c-1.5-4.6-2.4-8.3-2.5-11.7
c-0.3-6.2,2.2-17.1,5-29.6c1.4-6.3,2.9-12.8,4.2-19.4c0.2-1,0.4-2,0.6-3C287.3,311.8,266,342.8,267,360.9z"/>
</g>
<g id="Aeusseres_Gesaess_WB">
<path class="st0" d="M150.9,431.6c1.7-4.5,3.6-8.8,5.7-12.7c-4.1-4.6-5.3-16.9-5.7-23.3c-0.7,2.1-1.5,4.3-2.3,6.7c-1,3-2,6-3.1,9.1
c-6.2,17.9-13,37.6-15.8,62.3C133.7,461.8,142.9,437.4,150.9,431.6z"/>
<path class="st0" d="M304.9,483.3c-0.7-0.1-1.5,0.3-2.4,1.1c-5,4.7-10.7,21.6-10.4,31.3c1.1,4.2,1.8,8.9,2.2,14.1
c0.4,5.7,0.4,11.7,0.2,17.7c1.2,2.2,11.5,22,12.5,41.4c4.1-18.9,9.4-44,11.7-67.5c-0.3-1.2-2.3-10.1-5-19
C308.5,485,305.6,483.4,304.9,483.3z"/>
<path class="st0" d="M297.5,431.6c8,5.8,17.1,30.3,21.2,42.2c-2.8-24.7-9.6-44.4-15.8-62.3c-1.1-3.1-2.1-6.1-3.1-9.1
c-0.8-2.3-1.5-4.5-2.3-6.7c-0.4,6.4-1.6,18.6-5.7,23.3C293.9,422.8,295.8,427,297.5,431.6z"/>
<path class="st0" d="M154.1,529.8c0.4-5.2,1.1-9.9,2.2-14.1c0.2-9.6-5.4-26.6-10.4-31.3c-0.9-0.8-1.7-1.2-2.4-1.1
c-0.8,0.1-3.6,1.7-8.9,19.1c-2.7,8.9-4.8,17.8-5,19c2.4,23.5,7.7,48.6,11.7,67.5c1-19.4,11.3-39.2,12.5-41.4
C153.6,541.5,153.6,535.5,154.1,529.8z"/>
</g>
<g id="Unterarm_WB">
<path class="st0" d="M346.8,355.4c3.9,12.5,31.5,41.6,38.8,49.2c-1.2-6.2-2.5-12.3-4.1-18.2c-2.3-2.6-27.6-31.1-36.8-52.2
C345.4,342.3,346.1,349.7,346.8,355.4z"/>
<path class="st0" d="M63.4,468.8c0,0.3,0,0.5,0,0.7l0,0c-0.3,8.4,5.1,12.5,7.6,14c-1.7-13.8,5.1-78.8,6.5-92.5
c-0.8,0.9-1.7,1.8-2.5,2.7c-0.7,5.8-3.5,14.5-6.9,23.1C67.9,420.4,64.2,457.4,63.4,468.8z"/>
<path class="st0" d="M92.8,405.4c-7.2,19.8-5.9,37.1-5.1,43.1c0.5-0.9,0.9-1.8,1.4-2.6c9.7-18.9,19.8-38.5,22.6-51.7
c1.2-5.4,1.7-10.2,1.9-14.5C110.7,380.3,100.7,383.9,92.8,405.4z"/>
<path class="st0" d="M103.6,334.1c-9.2,21-34.5,49.6-36.8,52.2c-1.5,5.9-2.9,12.1-4.1,18.2c7.3-7.6,34.9-36.7,38.8-49.2
C102.2,349.7,102.9,342.3,103.6,334.1z"/>
<path class="st0" d="M61.9,469.3c0-0.2,0-0.3,0-0.5c0.7-9.2,3.2-35.2,4.3-46.6c-4.8,11.7-9.8,22.1-10.5,23.4
c-0.9,5-1.9,9.3-2.9,12.2c-0.8,2.3-0.1,5-0.8,7.2c-1.5,4.7-2.8,8.8-4.2,12.1C50.1,477.2,54.8,476.2,61.9,469.3z"/>
<path class="st0" d="M355.5,405.5c-7.9-21.6-17.8-25.1-20.7-25.7c0.2,4.3,0.7,9.1,1.9,14.5c2.8,13.2,12.9,32.7,22.6,51.7
c0.5,0.9,0.9,1.8,1.4,2.6C361.5,442.6,362.8,425.3,355.5,405.5z"/>
<path class="st0" d="M386.6,468.7c0,0.2,0,0.3,0,0.5c7.1,6.9,11.8,8.6,14.1,8.5c-1.4-3.3-2.7-7.4-4.2-12.1c-0.7-2.1-1-5.4-1.8-7.8
c-1-3-1-7.2-2-12.2c-0.6-1.3-5.6-11.7-10.4-23.5C383.4,433.5,385.9,459.4,386.6,468.7z"/>
<path class="st0" d="M377.5,483.5c2.6-1.5,7.9-5.6,7.6-14l0,0c0-0.2,0-0.5,0-0.7c-0.8-11.4-4.5-48.4-4.8-51.9
c-3.3-8.6-6.2-17.3-6.9-23.1c-0.8-0.9-1.6-1.8-2.5-2.7C372.4,404.7,379.1,469.8,377.5,483.5z"/>
<path class="st0" d="M334,379c0,0,0.8-13.9,12.4-17.5l1.1-2.3c0,0,11.9,19.4,23.5,30.7l-1,2.1c0,0,6.5,57.2,7,74.4
c0.5,17.1-0.6,18.1-0.6,18.1s-9-23.2-15.6-34.4c0,0,2.8-11.1,0.8-24.3c-2.1-13.2-5.1-21.6-9.6-29.9
C347.4,387.5,341.4,379.4,334,379z"/>
<path class="st0" d="M374.1,393.3c0,0,3.8,23.3,18.3,48.8l-5.8-36.1L374.1,393.3z"/>
<path class="st0" d="M114.2,379c0,0-0.8-13.9-12.4-17.5l-1.1-2.3c0,0-11.9,19.4-23.5,30.7l1.1,2.1c0,0-6.5,57.2-7,74.4
c-0.5,17.1,0.6,18.1,0.6,18.1s9-23.2,15.6-34.4c0,0-2.8-11.1-0.8-24.3c2.1-13.2,5.1-21.6,9.6-29.9
C100.8,387.5,106.8,379.4,114.2,379z"/>
<path class="st0" d="M74.1,393.3c0,0-3.8,23.3-18.3,48.8l5.8-36.1L74.1,393.3z"/>
</g>
<g id="Trizeps_WB">
<path class="st0" d="M345.3,355.6L345.3,355.6c-0.9-6.9-1.7-16.3-2.6-26.6c-0.2-0.5-0.3-1.1-0.5-1.6c-0.1-0.3,0-0.6,0.3-0.8
c-2.4-30.1-4.4-66.8-4.6-70.9c-10.3-7.4-18.7-17.4-26.7-27.4c1.6,5.9,4.5,20,9.9,54.2c0.6,1.9,17.2,54.2,24.3,74.1
C345.4,356.3,345.4,356,345.3,355.6z"/>
<path class="st0" d="M143.5,255.3c-1.2-13.3-2.7-26.6-3.8-29.9c-3.7,8.4-14.4,78.4-19.4,112.7c1-2.4,2-4.9,3-7.4
c4.2-10.4,8.5-21.3,10.8-28.6c4.6-14.9,10.1-28.9,10.8-30.5c-0.3-4.8-0.8-10.5-1.3-16.2C143.5,255.4,143.5,255.3,143.5,255.3z"/>
<path class="st0" d="M108.8,256.8c-2.3,1.5-4.7,2.9-7.1,4.2c-0.4,2-0.9,4.1-1.4,6.3c-0.4,1.6-0.9,3.4-1.4,5.3
c-2.8,10.2-6.6,24.2-8.2,36c-1.7,12.4-2,23.2-2.1,30.4c-0.1,5.3-0.2,8.5-0.7,9.8c-0.8,1.9-2.5,3.8-4.6,6.3
c-4.4,5.2-11.1,12.9-15,26.7c-0.1,0.4-0.2,0.8-0.3,1.2c7.2-8.4,30.5-36.2,36.3-54.3C106.4,300.2,108.4,264.6,108.8,256.8z"/>
<path class="st0" d="M344.3,328.7c5.8,18.1,29.1,45.9,36.3,54.3c-0.1-0.4-0.2-0.8-0.3-1.2c-3.9-13.7-10.6-21.5-15.1-26.7
c-2.1-2.5-3.8-4.5-4.6-6.3c-0.5-1.3-0.6-4.5-0.7-9.8c-0.2-7.2-0.5-18-2.1-30.4c-1.6-11.8-5.4-25.8-8.2-36c-0.5-2-1-3.7-1.4-5.3
c-0.6-2.2-1.1-4.3-1.4-6.3c-2.5-1.2-4.9-2.7-7.1-4.2C340,264.6,342,300.2,344.3,328.7z"/>
<path class="st0" d="M304.9,255.3c0,0.1,0,0.1,0,0.2c-0.5,5.7-0.9,11.3-1.3,16.2c0.7,1.6,6.2,15.6,10.8,30.5
c2.2,7.3,6.6,18.1,10.8,28.6c1,2.5,2,5,3,7.4c-5-34.3-15.7-104.3-19.4-112.7C307.6,228.6,306.1,242,304.9,255.3z"/>
<path class="st0" d="M110.4,255.7c-0.2,4.1-2.2,40.8-4.6,70.9c0.2,0.2,0.4,0.5,0.3,0.8c-0.1,0.5-0.3,1.1-0.5,1.6
c-0.8,10.3-1.7,19.7-2.5,26.6v0.1c0,0.3-0.1,0.7-0.1,1c7.1-20,23.7-72.2,24.3-74.1c5.5-34.2,8.4-48.3,10-54.2
C129.1,238.3,120.7,248.3,110.4,255.7z"/>
</g>
<g id="Latissimus_WB">
<path class="st0" d="M182.8,362.1c3.1,2.3,6.4,5.6,9.8,10.1c9.1-7.2,14.2-17.2,16.8-24.4c3.2-8.8,3.8-16,3.8-17.4
c0-1-1.4-3.6-3.1-6.8c-4-7.4-10.8-19.8-14.4-37.7c-1.4-7-1.9-14.1-2-21.1c-2,0.9-4.1,1.6-6.3,2.2c-0.1,0-0.2,0.1-0.3,0.1
c-2.6,0.6-5.4,0.9-8.3,0.9c-5.6,0-11.7-1.1-18.1-3.4c-7.3-2.6-13.1-6-15.6-7.5c0.7,7.7,1.1,14,1.2,14.8c0.3,1.1,3.8,11.9,7.2,27.2
C155.8,302,184.9,340.7,182.8,362.1z"/>
<path class="st0" d="M287.7,264.5c-6.4,2.3-12.4,3.4-18.1,3.4c-2.9,0-5.6-0.3-8.3-0.9c-0.1,0-0.2,0-0.3-0.1
c-2.2-0.5-4.3-1.2-6.3-2.2c-0.1,7-0.6,14-2,21.1c-3.7,17.9-10.4,30.3-14.4,37.7c-1.7,3.2-3.1,5.7-3.1,6.8c0,1.5,0.6,8.7,3.8,17.4
c2.6,7.2,7.6,17.2,16.8,24.4c3.4-4.5,6.7-7.8,9.8-10.1c-2.1-21.4,27.1-60.1,29.4-63.1c3.3-15.4,6.8-26.2,7.2-27.2
c0.1-0.8,0.5-7.1,1.2-14.8C300.7,258.6,294.9,262,287.7,264.5z"/>
</g>
<g id="Teres_WB">
<path class="st0" d="M276,257.7c15.2-12.2,27-34,28.8-37.3c-9.8-11.9-19.6-22.3-32.1-24.9c-3.9,3.6-7.4,7.8-9.8,12.4
c-5.7,10.9-7.4,21.6-7.9,32.7l-0.6,22.8l7.2,2.8C261.6,266.3,272.8,262.2,276,257.7z"/>
<path class="st0" d="M172.4,257.7c-15.2-12.2-27-34-28.8-37.3c9.8-11.9,19.6-22.3,32.1-24.9c3.9,3.6,7.4,7.8,9.8,12.4
c5.7,10.9,7.4,21.6,7.9,32.7l1.1,22.3c0,0-6.1,3.4-7.6,3.4C185.3,266.3,174.4,261,172.4,257.7z"/>
<path class="st0" d="M293,241.7c-9.7,12.9-19.2,21-28.2,24.2c8.1,1,16.2-0.8,22.3-3c8.5-3,14.9-7.1,16.3-8c0.2-2.2,0.4-4.4,0.6-6.7
c1.8-18.2,2.9-22.9,3.7-24.2c-0.6-0.8-1.3-1.6-1.9-2.4C304.3,224.5,299.6,233,293,241.7z"/>
<path class="st0" d="M183.6,265.9c-9-3.1-18.4-11.2-28.2-24.2c-6.6-8.8-11.3-17.3-12.9-20.1c-0.6,0.8-1.3,1.6-1.9,2.4
c0.8,1.3,1.9,6,3.7,24.2c0.2,2.3,0.4,4.5,0.6,6.7c1.4,0.9,7.8,5.1,16.3,8C167.4,265.1,175.4,266.9,183.6,265.9z"/>
</g>
<g id="Schulter_WB">
<path class="st0" d="M109.3,254.8c11-7.8,19.8-18.7,28.4-29.4c11.4-14.1,22.1-27.5,36.6-31c-9.6-8.5-20.8-13.6-22.2-14.2
c-9.6,0.4-24.2,1.7-30.7,9.9c-7.8,9.7-16,39.1-17.1,50.1c-0.2,2-0.4,3.9-0.6,5.7c-0.5,4.8-0.9,9-1.6,13.4
C104.5,258,106.9,256.5,109.3,254.8C109.2,254.9,109.2,254.9,109.3,254.8z"/>
<path class="st0" d="M306.3,220L306.3,220c1.5,1.8,3,3.6,4.4,5.5c8.6,10.7,17.4,21.6,28.4,29.4l0,0c2.3,1.6,4.7,3.1,7.2,4.4
c-0.8-4.4-1.2-8.6-1.6-13.4c-0.2-1.8-0.4-3.7-0.6-5.7c-1.2-11-9.4-40.4-17.1-50.1c-6.6-8.2-21.1-9.5-30.7-9.9
c-1.4,0.6-12.6,5.7-22.2,14.2C286.7,197.5,296.5,208,306.3,220z"/>
</g>
<g id="Nacken_WB">
<path class="st0" d="M158.8,179c0,0,7.5,0.9,19.4-7.5c9.5-6.8,15.2-12.4,18.8-20c3.6-7.7,7.8-26.7,7.8-26.7s2.2-13.1,2.6-16.7
c0.4-3.6,1-11.9,6.9-11.6s4.7,13.8,4.6,16.7c-0.1,2.9-0.1,23.7-0.8,31.1s-2,19-3.2,25.9c0,0-1.5,13.9-1.5,15.8s-0.4,2.9-0.4,2.9
s-16.2,2-28.3-1.1S162.1,181.8,158.8,179z"/>
<path class="st0" d="M289.5,178.7c0,0-7.5,0.9-19.4-7.5c-9.5-6.8-15.2-12.4-18.8-20c-3.6-7.7-7.8-26.7-7.8-26.7s-2.2-13.1-2.6-16.7
c-0.4-3.6-1-11.9-6.9-11.6s-4.7,13.8-4.6,16.7c0.1,2.9,0.1,23.7,0.8,31.1s2,19,3.2,25.9c0,0,1.5,13.9,1.5,15.8s0.4,2.9,0.4,2.9
s16.2,2,28.3-1.1C275.9,184.3,286.2,181.5,289.5,178.7z"/>
</g>
<g id="Trapezmuskel_WB">
<path class="st0" d="M288.6,179.8c1-0.4,2.4,0.1,3.9,0.2c-6.8,3.4-23.9,12.9-31.4,27.3c-5.9,11.2-7.6,22.7-8.1,34l0,0
c0,0,0,0,0,0.1c-0.2,4.6-0.2,9.1-0.2,13.7c0,10,0,20.2-2.1,30.4c-3.2,15.6-8.8,27-12.8,34.5c-2-6.8-8.6-33.1-6-66.4
c1.6-20.2,3.6-48.9,3.3-64.4C235.3,189.2,258.1,193.4,288.6,179.8z"/>
<path class="st0" d="M159.2,179.8c-1-0.4-2.4,0.1-3.9,0.2c6.8,3.4,23.9,12.9,31.4,27.3c5.9,11.2,7.6,22.7,8.1,34l0,0c0,0,0,0,0,0.1
c0.2,4.6,0.2,9.1,0.2,13.7c0,10,0,20.2,2.1,30.4c3.2,15.6,8.8,27,12.8,34.5c2-6.8,8.6-33.1,6-66.4c-1.6-20.2-3.6-48.9-3.3-64.4
C212.5,189.2,189.7,193.4,159.2,179.8z"/>
</g>
<path id="Kontur" class="st1" d="M413,504.6c-2.9-6.6-11.8-24.7-13.9-33.3v-0.1c-0.5-2-1-4.1-1.7-6.2c-0.6-3.2-1-5.1-2.1-8.4
c-1.9-5.6-2.4-15.2-4.3-27.1c-2.5-15.3-5.3-32.7-9.7-48c-4-14.1-10.8-22-15.4-27.2c-2.1-2.4-3.7-4.3-4.3-5.9c-0.4-1-0.5-4.7-0.6-9.3
c-0.2-7.2-0.5-18.1-2.2-30.5c-1.6-11.9-5.4-25.9-8.2-36.2c-0.5-2-1-3.7-1.4-5.3c-2.1-7.8-2.6-13.8-3.4-21.4
c-0.2-1.8-0.4-3.7-0.6-5.7c-1.2-11.2-9.5-41-17.5-50.9c-7.8-9.7-25.4-10.3-34.9-10.6c-1.6-0.1-3.1-0.1-4.2-0.2
c-7.3-0.5-12.9-3.3-23.8-11.6c-11.1-8.5-15.3-19.4-16-24c-0.5-3.5-0.1-15.6,0-17.6c1.5-1.5,2.8-3,4-4.5c2.3,8.4,4.4,12,7.6,17.9
l0.2,0.4c9.5,17.4,29.6,24.2,29.8,24.3l2.7,0.9l-1.9-2.1c-4.1-4.7-6.2-10.2-7.1-13.7c1.3,1.7,3.2,4.1,5.7,6.7
c6.8,7.3,17.3,13.2,17.7,13.4l3.2,1.8l-2.2-2.9c-0.1-0.1-9.2-12.1-15.4-26.8c-6.1-14.3-10.1-46.6-9.4-60.1
c0.6-12.5,0.2-30.9-5.2-47.4c-5.3-16.3-14.1-26.4-26.1-30.2c-12.2-3.9-20.3-0.8-25,2.5c-4,2.8-6.6,6.9-7.6,8.6
c-9.9,0.8-18.4,4.5-25.2,10.8c-7.3,6.9-15.3,18.6-12,48.5c-1.6,0-4.4,0.4-6.2,2.5c-1.3,1.5-1.8,3.6-1.4,6.3
c1.3,8.8,3.8,14.6,7.2,16.7c1.2,0.7,2.6,1.3,4.3,1.8c1.5,7.5,4.3,16.2,12.7,24.7c0.1,2,0.5,14.1,0,17.6c-0.7,4.6-4.9,15.6-16,24
c-10.9,8.4-16.5,11.1-23.8,11.6c-1.1,0.1-2.5,0.1-4.2,0.2c-9.5,0.3-27.2,0.9-34.9,10.6c-7.9,9.9-16.3,39.7-17.5,50.9
c-0.2,2-0.4,3.9-0.6,5.7c-0.7,7.6-1.3,13.6-3.4,21.4c-0.4,1.6-0.9,3.4-1.4,5.3c-2.8,10.2-6.6,24.3-8.2,36.2
c-1.7,12.5-2,23.3-2.2,30.5c-0.1,4.6-0.2,8.2-0.6,9.3c-0.7,1.6-2.3,3.5-4.3,5.9c-4.5,5.3-11.4,13.2-15.4,27.2
c-4.4,15.3-7.2,32.7-9.7,48c-1.9,11.9-3.1,22.4-4.5,28.2c-0.7,2.9-1.1,4.7-1.8,6.8c-0.8,2.5-1.4,4.6-1.9,6.5l-0.1,0.1
c-0.1,0.4-0.2,0.8-0.3,1.2c-0.5,1.6-0.9,3-1.6,4.4l-0.5,1.6c-3.5,9.3-9.4,21.6-11.6,26.7c-2.8,6.4-4.2,15.1-3.5,22.3
c0.3,3.7,1.1,7.5,1.8,10.9c0.5,2.3,0.9,4.5,1.1,6.1c0.1,1.2,0.5,2.5,1,3.8c0.2,2.5,0.4,5.9,1.1,8.2c1.4,4.8,7.8,9.2,13.2,12.6
c2.6,1.6,4.3,3.2,5.6,4.6c1.3,1.3,2.5,2.4,3.7,2.4c0.4,0,0.9-0.1,1.3-0.5c2-1.4,0.7-4.2,0-5.7c0.2-0.3,0.4-0.5,0.5-0.8
c1.4-2.2-1.8-6.2-3.1-7.7c0.3-0.4,0.4-0.9,0.4-1.6c-0.1-2.7-2-4.9-3.4-6.9c-2.2-3-3.6-6.8-5-10.3c-0.5-1.3-1-2.7-1.3-4
c-0.2-2.3,0.2-4.7,0.9-6.9c0.9-3.4,2.7-6,5.1-8.4c0.5,0.9,1.3,2.8,1.3,5.1c0,2.1-0.6,5-1.3,8.4c-0.4,2.1-0.9,4.3-1.3,6.6
c-1,6,2.2,9.7,5.1,11c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.2c1.5-0.8,2.4-4.2,3.6-9.7c0.4-2,0.9-4,1.4-5.6l0.1-0.3
c1.6-5.2,4.3-13.9,5.2-30.6c0.9-15.8,2.7-22.9,3.4-25.3l0.1-0.2c3.2-9.2,9.8-22.1,16.9-35.8c9.8-19,19.8-38.6,22.7-52
c1.3-5.9,1.8-11.1,2-15.6c0,0,0,0,0-0.1c0.2-4.6,0-8.6-0.2-11.9s-0.3-6.2,0-8.6c0.3-2.3,2-7.3,4.4-13.6c1.6-4.1,3.4-8.8,5.4-13.5
c4.2-10.5,8.5-21.3,10.8-28.7c3.8-12.3,8.3-24,10-28.6c1.4,4.6,4.9,16.5,7.8,31.7c1.3,6.6,2.8,13.1,4.2,19.5
c2.8,12.5,5.2,23.3,5,29.2c-0.3,5.8-2.8,12.8-7.1,24.4c-1.3,3.6-2.9,7.8-4.5,12.5c-1.1,3.2-2.4,6.7-3.6,10.5c-1,3-2,6-3.1,9.1
c-6.8,19.6-14.3,41.4-16.6,69.5c-0.5,6.4-0.8,13.1-0.7,20.2c0.1,6.6,0.5,13.6,1.3,20.7c0,0.1,0,0.1,0,0.2
c2.6,25.3,8.4,52.3,12.6,71.6c2.4,10.9,4.2,19.5,4.8,24.4c2.1,18,1.7,26.7,1.2,35.9c-0.2,2.9-0.3,6-0.4,9.4
c-0.1,6.6,0.3,14.1,0.7,20.7c0.4,5.7,0.7,10.7,0.4,12.9c-0.1,1-0.7,2.7-1.4,5.1c-2.7,8.7-7.7,24.9-6.1,47.9
c0.9,13,2.2,25.1,3.9,36.3c0,0.1,0,0.1,0,0.2c0.2,1.3,0.4,2.6,0.6,3.8v0.1c2.4,16.1,5.3,30.4,7.8,42.9c1.1,5.6,2.2,10.8,3.1,15.8
c0.4,2.2,0.9,4.4,1.3,6.5c1.6,7.8,3.1,15.2,2.7,20.9c-0.3,3.3-1.2,5.7-2,8.1c-1,2.8-2,5.4-1.9,9.3c0.1,2.6,0.4,4.6,0.7,6.5
c0.4,2.3,0.8,4.6,0.5,7.6c-0.2,2.6-1.2,14.4-2.3,17.4c-1.2,3.3-2.3,6.3-2.4,9.1c-0.1,4,1.1,7.6,3.6,10.1c3.2,3.3,8.1,5,14.7,5
c11.5,0,15.5-2.9,18-7.2c3-5.3,2.4-12.8,1.9-19.3c-0.2-2.6-0.5-13.8-0.5-15.9c0.1-1.9,0.7-4.2,1.4-6.8c1.2-4.4,2.8-9.8,1.9-15.4
c-0.7-4.7-1.6-6.7-2.4-8.6c-0.7-1.6-1.4-3.2-2-6.4c-1.2-6.8-1.3-30.3-1-48.3c0.3-16.3,3.3-33.3,6-48.3c2.3-12.8,4.4-25,4.5-34.6
c0.1-11.5-1-20.8-2-29.1c-1-8.2-1.8-15.3-1-21.4c0.7-5.1,2.4-9.1,4.3-13.6c2.4-5.6,5.1-11.9,6.7-22.1c0.2-1.2,0.4-2.5,0.5-3.8
c1.4-11.5,0.7-19.7,0-28.4c-0.6-7.7-1.3-15.7-0.6-26.5c0.5-7.4,2.3-14.7,4.5-22.9c0.3-1.3,0.7-2.6,1.1-3.9
c3.2-11.7,6.8-24.9,8.3-41.6c1.9-21.9,0.3-36.1-0.3-40.3c1.2,0.4,2.9,0.8,4.6,0.8s3.4-0.5,4.6-0.8c-0.6,4.2-2.2,18.5-0.3,40.3
c1.5,16.7,5.1,29.9,8.3,41.6c0.4,1.3,0.7,2.6,1.1,3.9c2.2,8.2,4,15.5,4.5,22.9c0.7,10.8,0,18.8-0.6,26.5c-0.7,8.7-1.4,17,0,28.4
c0.2,1.3,0.3,2.6,0.5,3.8c1.5,9.8,4.1,16,6.4,21.5c0.1,0.2,0.2,0.4,0.3,0.6c1.9,4.6,3.6,8.5,4.3,13.6c0.8,6.2,0,13.2-1,21.4
c-1,8.3-2.1,17.6-2,29.1c0.1,9.6,2.2,21.8,4.5,34.6c2.7,15,5.7,32.1,6,48.3c0.3,18.1,0.2,41.5-1,48.3c-0.6,3.3-1.3,4.8-2,6.4
c-0.8,1.9-1.7,3.9-2.4,8.6c-0.8,5.6,0.7,11,1.9,15.4c0.7,2.7,1.4,5,1.4,6.8c0.1,2.1,0,13.3-0.3,15.9c-0.5,6.6-1.2,14,1.9,19.3
c2.5,4.3,6.4,7.2,18,7.2c6.6,0,11.5-1.7,14.7-5c2.5-2.6,3.8-6.2,3.6-10.1c-0.1-2.7-1.2-5.8-2.4-9.1c-1.1-3-2.3-14.8-2.5-17.4
c-0.2-3,0.1-5.2,0.5-7.6c0.3-1.9,0.7-3.9,0.7-6.5c0.1-3.9-0.9-6.5-1.9-9.3c-0.9-2.3-1.8-4.7-2-8.1c-0.4-5.7,1.1-13.1,2.7-20.9
c0.4-2.1,0.9-4.3,1.3-6.5c0.9-5,2-10.3,3.1-15.8c2.5-12.5,5.4-26.8,7.8-43v-0.1c0.2-1.3,0.4-2.5,0.6-3.8c0-0.1,0-0.1,0-0.2
c1.6-11.2,3-23.3,3.9-36.3c1.6-23-3.4-39.2-6.1-47.9c-0.7-2.4-1.3-4.1-1.4-5.1c-0.3-2.2,0-7.2,0.4-12.9c0.4-6.6,0.9-14.2,0.7-20.7
c-0.1-3.4-0.2-6.5-0.4-9.4c-0.5-9.2-0.9-17.8,1.2-35.9c0.6-5,2.5-13.6,4.8-24.4c4.2-19.3,10-46.3,12.6-71.6c0-0.1,0-0.1,0-0.2
c0.7-7.1,1.2-14.1,1.3-20.7c0.1-7.1-0.2-13.8-0.7-20.2c-2.3-28.1-9.8-49.9-16.6-69.5c-1.1-3.1-2.1-6.1-3.1-9.1
c-3.1-9.3-5.9-16.9-8.2-23c-4.3-11.6-6.8-18.6-7.1-24.4c-0.3-6,2.1-16.8,5-29.2c1.4-6.3,2.9-12.9,4.2-19.5
c2.9-15.2,6.4-27.1,7.8-31.7c1.8,4.5,6.2,16.2,10,28.6c2.3,7.4,6.6,18.2,10.8,28.7c1.9,4.8,3.8,9.4,5.4,13.5
c2.4,6.2,4.2,11.3,4.4,13.6c0.3,2.4,0.1,5.3,0,8.6c-0.2,3.3-0.4,7.3-0.2,12c0.2,4.5,0.7,9.7,2,15.6c2.9,13.4,13,33,22.7,52
c6.7,13.2,13.1,25.6,16.5,34.8l0,0l0,0c0.1,0.2,0.1,0.4,0.2,0.6c0.6,1.9,2.6,8.9,3.5,25.6s3.6,25.4,5.2,30.6l0.1,0.3
c0.5,1.5,0.9,3.6,1.4,5.6c1.3,5.5,2.1,9,3.6,9.7c0.2,0.1,0.5,0.2,0.7,0.2s0.4,0,0.6-0.1c3-1.3,6.1-5,5.1-11
c-0.4-2.3-0.8-4.5-1.3-6.6c-0.7-3.4-1.3-6.3-1.3-8.4c0.1-2.3,0.9-4.2,1.3-5.1c2.4,2.4,4.2,5,5.1,8.4c0.7,2.2,1.1,4.6,0.9,6.9
c-0.3,1.3-0.8,2.7-1.3,4c-1.4,3.5-2.8,7.3-5,10.3c-1.4,2-3.3,4.2-3.4,6.9c0,0.7,0.2,1.2,0.4,1.6c-1.3,1.4-4.5,5.5-3.1,7.7
c0.2,0.2,0.3,0.5,0.5,0.8c-0.8,1.5-2,4.3,0,5.7c0.5,0.3,0.9,0.5,1.3,0.5c1.2,0,2.3-1.1,3.7-2.4c1.4-1.3,3.1-3,5.6-4.6
c5.4-3.3,11.8-7.7,13.2-12.6c0.7-2.3,0.9-5.7,1.1-8.2c0.5-1.3,0.9-2.6,1-3.8c0.2-1.7,0.6-3.9,1.1-6.1c0.7-3.4,1.5-7.2,1.8-10.9
C417.2,519.7,415.8,510.9,413,504.6z M394.7,458.4c0.8,2.3,1.1,5,1.8,7.2c0.2,0.6,0.4,1.2,0.6,1.8l-0.1-0.1c0.6,3,1.6,6.6,2.9,10.4
c-2.5-0.3-6.9-2.2-13.3-8.4c0-0.2,0-0.3,0-0.5c-0.7-9.2-3.2-35.2-4.3-46.6c4.8,11.7,9.1,21.8,9.7,23.1
C392.9,450.1,393.7,454.4,394.7,458.4z M370.9,391c0.8,0.9,1.7,1.8,2.5,2.7c0.7,5.8,3.5,14.5,6.9,23.1c0.3,3.5,4,40.4,4.8,51.9
c0,0.3,0,0.5,0,0.7c0.2,8.5-5.2,12.7-7.7,14.1C379.1,469.7,372.3,404.7,370.9,391z M389.9,429.8c0.6,3.5,1.1,6.9,1.7,10
c-2.5-5.4-6.5-14.3-10-23.3c0-0.1-0.1-0.2-0.1-0.3c-2.9-7.4-5.4-14.9-6.4-20.3c5.6,6.1,10,10.6,11,11.6
C387.5,415.1,388.8,422.7,389.9,429.8z M381.5,386.6c1.5,5.9,2.9,12.1,4.1,18.2c-7.3-7.6-34.9-36.8-38.8-49.2
c-0.7-5.6-1.4-13-2.1-21.2C353.9,355.4,379.2,384,381.5,386.6z M366.3,386c-0.2,1.4-1.3,6.7-5.6,7.6c-4-11.3-8.7-21.4-12-28.6
l-0.6-1.3c-0.2-0.8-0.3-1.7-0.5-2.7C351.5,368.1,358.9,377.5,366.3,386z M342.3,327.6c0.1,0.5,0.3,1.1,0.5,1.6
c0.8,10.3,1.7,19.7,2.5,26.6v0.1c0,0.3,0.1,0.7,0.1,1c-7-20-23.6-72.3-24.2-74.2c-5.5-34.2-8.4-48.3-9.9-54.2
c8,10,16.4,20,26.7,27.4c0.2,4.1,2.2,40.8,4.6,70.9C342.4,327,342.2,327.3,342.3,327.6z M348.1,267.5c0.4,1.6,0.9,3.4,1.4,5.3
c2.9,10.2,6.7,24.2,8.3,36c1.7,12.4,2,23.2,2.1,30.4c0.1,5.3,0.2,8.5,0.7,9.8c0.8,1.9,2.5,3.8,4.6,6.3c4.4,5.2,11.1,12.9,15.1,26.7
c0.1,0.4,0.2,0.8,0.3,1.2c-7.2-8.4-30.5-36.2-36.3-54.3c-2.3-28.5-4.3-64.1-4.7-71.9c2.3,1.5,4.7,2.9,7.1,4.2
C347.1,263.2,347.6,265.3,348.1,267.5z M296.2,180.3c9.7,0.3,24.3,1.6,30.8,9.8c7.8,9.7,16,39.1,17.1,50.1c0.2,2,0.4,3.9,0.6,5.7
c0.5,4.8,0.9,9,1.6,13.4c-2.5-1.3-4.9-2.8-7.2-4.4c-11-7.8-19.8-18.7-28.4-29.4c-0.4-0.5-0.7-0.9-1.1-1.4c0,0,0-0.1-0.1-0.1
c-1.1-1.4-2.2-2.7-3.3-4c-9.8-12-19.6-22.5-32.2-25.5C283.6,186,294.8,180.9,296.2,180.3z M304.8,220.6
c-1.8,3.4-13.6,25.2-28.8,37.3l-1.5,1.1c-4.2,3.1-8.6,5.5-13.1,6.5c-2.2-0.5-4.5-1.3-6.6-2.3c0-2.7,0-5.3,0-8c0-4.3,0-8.6,0.2-12.9
v-1.5c0.5-11.1,2.2-21.9,7.9-32.7c2.4-4.7,6-8.8,9.8-12.4C285.2,198.3,295,208.6,304.8,220.6z M289.1,179.9c1,0.1,2.1,0.1,3.4,0.2
c-6.8,3.4-23.9,12.9-31.4,27.3c-5.9,11.2-7.6,22.7-8.1,34c0,0,0,0,0,0.1c-0.2,4.6-0.2,9.1-0.2,13.7c0,10,0,20.2-2.1,30.4
c-3.2,15.6-8.8,27-12.8,34.5c-2-6.8-8.6-33.1-6-66.4c1.6-20.1,3.6-48.7,3.3-64.3c0.9,0.2,3,0.5,6.3,0.7c0.9,0.1,2,0.1,3.2,0.1
c4.2,0,10.1-0.3,17.8-1.7C278.9,186,287.1,181.2,289.1,179.9z M264,168c10.2,7.8,15.9,10.8,22.6,11.7c-3.5,1.9-11.3,5.5-24.4,7.4
c-17.8,2.7-26.5,0.8-27,0.7c-0.3-9.5-1.5-18.5-2.7-26.5c-0.6-4.4-1.2-8.5-1.5-12.2c-1.1-11.7-1.2-35.4-1.2-41.6l0.4,0.2
c0-0.4,0-0.7,0-0.9c0,0,0-3.3,0.8-5.9c0.8-2.7,2.2-3.5,3.6-3.3c1.4,0.2,2.7,0.4,3.9,2.6c1.2,2,1.5,3.8,1.5,6.5l-0.2,0.1
c0.9,18.8,7.1,35.1,7.6,36.3C248.2,148,252.6,159.3,264,168z M187.8,99.6c-0.1-0.3-0.3-0.5-0.6-0.6c-1.7-0.4-3.2-1-4.3-1.7
c-3-1.8-5.3-7.4-6.5-15.6c-0.3-2.2,0-3.9,1-5.1c1.5-1.7,4.2-1.9,5.3-1.9c0.4,0,0.6,0,0.6,0c0.2,0,0.5-0.1,0.6-0.2
c0.2-0.2,0.2-0.4,0.2-0.6c-3.4-29.9,4.3-41.4,11.5-48.1c6.8-6.4,15.3-9.9,25.4-10.5l0.5-1.3l0,0c0.1,0,2.1-4.3,6.9-7.6
c6.4-4.4,14.3-5.2,23.7-2.2c11.5,3.6,20,13.4,25.1,29.2c4.2,12.7,6,29.3,5.1,46.9c-0.6,13.5,3.1,45.8,9.5,60.8
c4.4,10.4,10.3,19.6,13.4,24.1c-3.7-2.3-9.8-6.5-14.2-11.2c-4.8-5.1-7.7-9.4-7.7-9.4l-1.8-2.6l0.4,3.2c0.1,0.3,1.1,8.3,6.4,15.6
c-5.7-2.5-19.1-9.5-26.3-22.6l-0.2-0.4c-3.4-6.2-5.5-10-8.1-19.6l-0.3,0.2l-0.2-0.7c-1.6,2.3-3.5,4.4-5.6,6.5
c-0.1,0.1-0.2,0.4-0.2,0.6c0,0.7-0.3,7.5-0.3,12.8c-2.1-6.5-5.3-18.4-5.8-31.4l0.3-0.2c0-0.2-0.2-3.2-1.1-5.3
c-1.3-2.9-3.5-4.9-6.3-4.8c-2.6,0.1-4.2,2.2-4.9,5.1c-0.6,2.5-0.6,5.8-0.6,5.8l0.1,0.1c0,4.8,0.1,30.3,1.2,42.4
c0.3,3.7,0.9,7.8,1.5,12.2c1.2,8.4,2.5,17.9,2.7,27.8c0.3,15.4-1.8,44-3.3,64.2c-2.9,36.8,5.5,65.2,6.5,68.4
c-0.2,0.3-0.3,0.6-0.5,0.9c-2.1,3.8-3.3,6.1-3.3,7.5c0,5.3,3.8,29.4,21.2,43c-4.9,6.7-10.1,15.6-15.5,26.7
c-7.5,15.3-13.2,30.5-15.1,35.8c-1.9-5.3-7.6-20.5-15.1-35.8c-5.4-11.1-10.6-20-15.5-26.7c17.4-13.6,21.2-37.7,21.2-43
c0-1.4-1.2-3.7-3.3-7.5c-0.2-0.3-0.3-0.6-0.5-0.9c1-3.3,9.4-31.6,6.5-68.4c-1.6-20.2-3.6-48.8-3.3-64.2c0.2-10,1.5-19.4,2.7-27.8
c0.6-4.4,1.2-8.6,1.5-12.2c1.1-12.3,1.2-37.8,1.2-42.5c0,0,0-3.3-0.6-5.8c-0.7-2.9-2.3-5-4.9-5.1c-2.8-0.1-5,1.9-6.3,4.8
c-0.9,2.1-1.1,5.2-1.1,5.3c-0.6,13.1-3.8,25.1-5.8,31.6c0-5.3-0.2-12-0.3-12.8c0-0.2-0.1-0.4-0.2-0.6
C193.6,117.4,190,110.3,187.8,99.6z M272.9,627.5c-2-25.8-3.8-51.2-3.6-77.3c0-5.7,0.4-12,0.8-18.6c0.8-14,1.6-28.5-0.6-37.3
c1.6-0.3,3.2-0.6,4.7-1.1c11,5.8,17.1,17.9,18.6,36.9c0.4,5.7,0.4,11.6,0.2,17.7c0,0.1,0,0.1,0,0.2c-0.5,11.6-2.1,23.6-3.5,34.6
c-1.4,10.9-2.6,20.4-2.6,27.5c0.1,28,12.4,51,13.6,53.2c0.1,6.5-0.3,13.9-0.7,20.4c-0.2,3.9-0.4,7.2-0.5,9.7
c-2.7-5.2-5.3-10-7.9-14.7C282.3,662.3,274.5,648.1,272.9,627.5z M284.3,668.9c-0.6,2.1-4.1,14-4.6,34.1
c-3.7-20.1-11.5-34.2-12.9-36.6c3.4-8.8,6.1-17.6,7.4-21.9C276.6,653.3,280.2,661.1,284.3,668.9z M251,490.9
c-12.2-5.4-18.6-14.9-21.8-21.9c-3.5-7.7-4.2-14.2-4.2-14.2v-0.1v-0.1l-0.1-0.1V439c0-0.1,0.1-0.1,0.1-0.2
c0.2-0.5,12.4-35.3,27.2-58.3c2.3,1.3,11.2,6.7,20.7,16.2c10.1,9.8,23.1,27,27.3,51c3.6,20.8-6.6,32.3-13.1,37.3
C276.6,493.2,261.8,495.6,251,490.9z M243.5,524.1c-4.2-11.1-8.7-29.4-5.4-40c1.9,1.8,4.1,3.5,6.5,5.1
C241.8,496.8,242.1,509.4,243.5,524.1z M203.7,489.2c2.5-1.6,4.6-3.3,6.5-5.1c3.3,10.6-1.1,28.9-5.4,40
C206.2,509.3,206.5,496.8,203.7,489.2z M197.7,491.2c-10.7,4.8-25.6,2.3-36.1-5.9c-6.4-5-16.7-16.5-13.1-37.3
c4.1-23.9,17.1-41.1,27.3-51.3c9.5-9.5,18.4-14.9,20.8-16.2c13.3,20.8,24.6,51.1,26.8,57.2v19.1l0,0
C222.4,462.8,217.4,482.4,197.7,491.2z M178.4,531.6c0.4,6.7,0.7,12.9,0.8,18.6c0.2,26.2-1.6,51.5-3.6,77.3
c-1.6,20.6-9.5,34.8-18.5,51.2c-2.6,4.7-5.3,9.5-7.9,14.7c0-2.5-0.3-5.9-0.5-9.7c-0.4-6.5-0.9-14-0.7-20.4
c1.2-2.2,13.5-25.2,13.6-53.2c0-7.1-1.2-16.6-2.6-27.5c-1.4-11-3-22.9-3.5-34.6c0-0.1,0-0.1,0-0.2c-0.3-6-0.3-12,0.2-17.7
c1.5-19.1,7.6-31.1,18.7-36.9c1.6,0.4,3.1,0.8,4.7,1.1C176.8,503.1,177.6,517.6,178.4,531.6z M174.1,644.6
c1.3,4.3,3.9,13.2,7.4,21.9c-1.3,2.3-9.2,16.4-12.9,36.5c-0.5-20.1-3.9-32-4.6-34C168.1,661.1,171.7,653.3,174.1,644.6z
M183.8,167.9c11.4-8.7,15.8-20.1,16.6-24.9c0.5-1.2,6.7-17.5,7.6-36.3c0.2-2.7,0.5-4.5,1.7-6.6s2.5-2.4,3.9-2.6s2.8,0.7,3.6,3.3
c0.8,2.7,0.8,5.9,0.8,5.9c0,0.2,0,0.5,0,0.9c0,6.4-0.2,29.9-1.2,41.5c-0.3,3.6-0.9,7.8-1.5,12.2c-1.2,8.1-2.4,17.3-2.7,26.9
c-2.3,0.4-11,1.5-26.4-0.8c-14-2.1-22.1-6.1-25.1-7.8C167.9,178.7,173.6,175.7,183.8,167.9z M158.9,179.8L158.9,179.8
c0.2,0.3,8.3,6.2,27,9c7.6,1.1,13.5,1.4,17.7,1.4c1.3,0,2.3,0,3.3,0.2c2.7-0.1,4.6-0.4,5.7-0.6c-0.2,15.6,1.8,43.8,3.3,63.9
c2.6,33.3-4.1,59.6-6,66.4c-4-7.5-9.6-18.9-12.8-34.5c-2.1-10.2-2.1-20.4-2.1-30.4c0-4.5,0-9.1-0.2-13.7c0,0,0,0,0-0.1
c0,0,0,0,0-0.1c-0.5-11.3-2.2-22.8-8.1-34c-7.5-14.4-24.6-23.9-31.4-27.3C156.7,179.9,157.9,179.9,158.9,179.8z M193.7,255.2
c0,2.6,0,5.3,0,8c-2.2,1.1-4.4,1.8-6.6,2.3c-4.5-1-8.9-3.3-13.1-6.5l-1.6-1.1c-15.2-12.2-27-34-28.8-37.3
c9.8-11.9,19.6-22.3,32.1-24.9c3.9,3.6,7.4,7.8,9.8,12.4c5.7,10.9,7.4,21.6,7.9,32.7l0.1,1.5C193.7,246.5,193.7,250.8,193.7,255.2z
M103.6,245.8c0.2-1.7,0.4-3.6,0.6-5.6c1.2-11,9.4-40.4,17.1-50.1c6.6-8.2,21.1-9.5,30.7-9.9c1.4,0.6,12.6,5.7,22.2,14.2
c-12.6,3.1-22.4,13.6-32.2,25.5c-1.5,1.8-3,3.6-4.4,5.5c-8.6,10.7-17.4,21.6-28.4,29.4c-2.3,1.6-4.7,3.1-7.2,4.4
C102.8,254.8,103.2,250.6,103.6,245.8z M105.7,326.7c2.4-30.1,4.4-66.8,4.6-70.9c10.3-7.4,18.7-17.4,26.7-27.4
c-1.5,6.1-4.3,20.1-9.8,54.3c-0.6,1.9-17.2,54.2-24.3,74.1c0-0.3,0.1-0.7,0.1-1v-0.1c0.8-6.8,1.7-16.2,2.5-26.6
c0.2-0.5,0.3-1.1,0.5-1.6C106.1,327.2,106,326.9,105.7,326.7z M68.1,381.9c3.9-13.7,10.6-21.5,15-26.7c2.1-2.5,3.8-4.5,4.6-6.3
c0.5-1.3,0.6-4.5,0.7-9.8c0.2-7.2,0.5-18,2.1-30.4c1.6-11.8,5.4-25.8,8.2-36c0.5-2,1-3.8,1.4-5.3c0.6-2.2,1.1-4.3,1.4-6.3
c2.5-1.2,4.9-2.7,7.1-4.2c-0.4,7.8-2.4,43.4-4.7,71.9c-5.8,18-29.1,45.9-36.3,54.3C67.9,382.7,68,382.3,68.1,381.9z M66.8,386.5
c2.3-2.6,27.6-31.1,36.8-52.2c-0.7,8.2-1.4,15.6-2.1,21.2C97.6,368,70,397.1,62.7,404.7C64,398.5,65.3,392.4,66.8,386.5z M100.8,361
c-0.2,1.1-0.3,2-0.5,2.7l-0.6,1.3c-3.4,7.2-8,17.3-12,28.6c-4.3-0.9-5.4-6.2-5.6-7.6C89.5,377.5,96.9,368.1,100.8,361z M71,483.5
c-2.6-1.5-7.9-5.6-7.6-14c0-0.2,0-0.5,0-0.7c0.8-11.4,4.5-48.4,4.8-51.9c3.3-8.6,6.2-17.3,6.9-23.1c0.8-0.9,1.6-1.8,2.5-2.7
C76.1,404.7,69.3,469.8,71,483.5z M58.4,429.8c1.2-7.1,2.4-14.7,3.8-22.3c1-1,5.4-5.5,11-11.6c-1,5.5-3.5,12.9-6.4,20.3
c-0.1,0.1-0.1,0.2-0.1,0.3c-3.5,9-7.5,17.9-10,23.3C57.3,436.6,57.8,433.3,58.4,429.8z M52.1,464.9c0.7-2.1,1.1-3.4,1.8-6.8
c0.7-3.1,0.9-7.6,1.9-12.6c0.6-1.3,5.6-11.7,10.4-23.5c-1.1,11.4-3.7,37.4-4.3,46.6c0,0.2,0,0.3,0,0.5c-6.8,6.6-11.3,7.9-13.7,7.9
c0.5-1.4,0.9-2.8,1.2-4.1C50.3,470.6,51.2,467.9,52.1,464.9z M59.2,573.9c-0.5,0.4-1.1-0.1-2.8-1.8c-1.4-1.3-3.2-3.2-5.9-4.8
c-4.7-2.9-11.2-7.3-12.5-11.6c-0.3-0.9-0.5-2-0.6-3.1c1.1,2,2.2,3.6,2.9,4.4c0.7,0.8,2.6,2.2,5,4c2.7,2,6,4.5,8.2,6.6
c2.4,2.3,3.7,3.1,4.8,3.1c0.1,0,0.2,0,0.3,0s0.1,0,0.2,0C59.6,572.4,59.8,573.5,59.2,573.9z M68.1,507.8
c-0.9,16.5-3.5,24.8-5.1,30.2l-0.1,0.3c-0.5,1.6-1,3.7-1.4,5.7c-0.7,3-1.8,7.9-2.7,8.6c-0.8-0.4-5.1-2.7-4.1-9.2
c0.4-2.2,0.8-4.4,1.3-6.5c0.7-3.5,1.4-6.5,1.3-8.7c-0.1-3.1-1.3-5.6-1.8-6.3c2.6-2.9,4.9-6,6.9-9.2l-0.6-0.4
c-2.2,3.2-4.6,6.3-7.2,9.1c-1.4,1.4-3,2.7-4.1,4.3c-2.3,3.4-3.4,7.3-3.6,11.4c-0.1,2.1,0.7,4,1.4,5.9c1.3,3.5,2.6,7,4.6,10.2
c1.2,1.7,2.6,3.5,3.3,5.4c0.7,2-0.1,2.5-1.8,1.7c-3.3-1.7-5.6-5.2-7.9-8c-1.7-2.3-3.4-4.6-4.3-7.3c-1.2-4.8-3.3-9.3-5.6-13.6
l-0.4,0.2c1.4,4.7,3.3,9.2,4.4,13.9c1,2.9,2.8,5.4,4.6,7.8c2.6,3.1,4.8,6.5,8.5,8.5c0.9,0.5,1.9,0.7,2.7,0.5l-0.1,0.1
c1.9,2.1,3.6,5,3.1,5.8c-0.3,0.5-0.7,1-0.9,1c-0.5,0.1-1.7-0.8-3.7-2.7c-2.3-2.1-5.5-4.5-8.4-6.7c-2.1-1.6-4.1-3.1-4.8-3.8
c-1.4-1.5-5.1-7.8-5.6-11.8c-0.2-1.8-0.7-4-1.1-6.3c-0.7-3.3-1.5-7.1-1.8-10.7c-0.6-6.9,0.7-15.4,3.4-21.5c1.6-3.6,5-10.7,8.1-18.4
l0.2-0.3c0.1-0.3,0.2-0.6,0.4-1.1c1-2.4,1.9-4.9,2.7-7.2c0.2,0,0.4,0,0.6,0c2.7,0,6.9-1.4,13.5-7.3c0.5,8.5,6.5,12.6,9,13.8
C69.9,489,68.7,496,68.1,507.8z M72.4,481.2l-0.1-0.1c-0.9-18.3,6.4-87.1,7-92c0.5-0.6,1-1.1,1.5-1.7c0.6,2.4,2.2,6.6,6.4,7.6
c-0.9,2.7-1.8,5.4-2.6,8.2c-6.5,22.1-6.8,62.5-6.8,65.4C75.7,473.2,73.8,477.5,72.4,481.2z M79.5,465.1c0.7-10,3.9-24,6.3-33.6
c-0.8,10.6,0.5,18.1,0.7,19.6C84,455.9,81.6,460.6,79.5,465.1z M111.6,394.4c-2.8,13.2-12.8,32.8-22.5,51.7
c-0.5,0.9-0.9,1.8-1.4,2.6c-0.8-6-2.1-23.3,5.1-43.1c7.9-21.6,17.8-25.1,20.7-25.7C113.3,384.2,112.8,389,111.6,394.4z M117.6,344.5
c-2.2,6.4-4,11.5-4.3,13.9c-0.3,2.5-0.1,5.4,0,8.8c0.2,3.2,0.3,6.9,0.2,11.2c-2.9,0.4-13.7,3.6-22.2,26.7
c-1.9,5.1-3.2,10.1-4.1,14.8c-0.1,0.2-4.7,16.3-7.5,31.4c0.7-14.4,2.3-34.5,6.2-47.8c0.9-3,1.9-6,2.9-8.9c4-11.4,8.7-21.7,12.1-29
l0.6-1.4c3.2-6.9,17.5-51.5,23.9-71.5C121.3,318.8,117.8,342.9,117.6,344.5z M133.9,302.4c-2.2,7.2-6.5,18-10.7,28.5
c-1,2.5-2,5-3,7.4c5-34.3,15.7-104.3,19.4-112.7c1.2,3.2,2.6,16.6,3.8,29.9c0,0.1,0,0.1,0,0.2c0.5,5.7,0.9,11.3,1.3,16.2
C144,273.5,138.5,287.5,133.9,302.4z M144.5,248.4c-1.8-18.2-2.9-22.9-3.7-24.2c0.5-0.8,1.1-1.6,1.8-2.4c1.5,2.9,6.3,11.3,12.9,20.1
c9.7,12.9,19.2,21,28.2,24.2c-8.2,1-16.2-0.8-22.3-3c-8.5-3-14.9-7.1-16.3-8C144.9,252.9,144.7,250.7,144.5,248.4z M146.3,271.8
c-0.1-0.8-0.5-7.1-1.2-14.8c2.5,1.6,8.3,5,15.6,7.5c6.4,2.3,12.4,3.4,18.1,3.4c2.9,0,5.6-0.3,8.3-0.9c0.1,0,0.2,0,0.3-0.1
c2.2-0.5,4.3-1.2,6.3-2.2c0.1,7,0.6,14,2,21.1c3.7,17.9,10.4,30.3,14.4,37.7c1.7,3.2,3.1,5.7,3.1,6.8c0,1.5-0.6,8.7-3.8,17.4
c-2.6,7.2-7.6,17.2-16.8,24.4c-3.4-4.5-6.7-7.8-9.8-10.1c2.1-21.3-27-60-29.3-63C150.2,283.6,146.7,272.8,146.3,271.8z M164,354.7
c0.3-6.2-2.2-17.1-5-29.7c-1.4-6.3-2.9-12.8-4.2-19.4c-0.2-1-0.4-2-0.6-3c6.8,9.4,28.2,40.4,27.2,58.5c-1.9-1.2-3.7-1.9-5.5-2.3
c-6.1-1.2-11.3,3.5-14.4,7.5C162.9,361.7,163.8,358,164,354.7z M156.8,379.5c1-2.8,1.9-5.3,2.8-7.6c0.5-0.8,7.4-13.3,16.1-11.6
c2,0.4,3.9,1.3,5.9,2.7c0.1,0,0.1,0.1,0.2,0.1c3.4,2.4,6.8,6.1,10.1,10.5v0.1c0,0,0,0,0,0.1c1.2,1.6,2.4,3.3,3.5,5
c-2.5,1.5-11.5,6.9-21,16.4c-5.5,5.5-11.8,13-17.1,22.4c-4.6-6-5.1-24-5.1-25.7C153.9,387.3,155.4,383.1,156.8,379.5z M145.5,411.6
c1.1-3.1,2.1-6.1,3.1-9.1c0.8-2.3,1.5-4.5,2.3-6.7c0.4,6.4,1.6,18.6,5.7,23.3c-2.1,3.9-4.1,8.1-5.7,12.7c-8,5.8-17.1,30.3-21.2,42.2
C132.5,449.2,139.3,429.5,145.5,411.6z M128.3,500.9c-0.1-7,0.2-13.7,0.7-20c0.6-2,11.4-35.9,20.9-46.1c-1.3,4-2.4,8.2-3.1,12.7
c-3.7,21.6,6.9,33.6,13.6,38.8c3.6,2.8,7.6,4.9,11.9,6.4c-6.7,4-11.6,10.3-14.6,19c-1.1-9.6-5.8-23.5-10.7-28.1
c-1.2-1.1-2.4-1.7-3.5-1.6c-1.8,0.2-4.8,2.5-10.2,20.2c-1.7,5.5-3.1,11-4,14.8C128.7,511.4,128.4,506,128.3,500.9z M129.7,521.6
c0.2-1.2,2.2-10.1,4.9-19c5.3-17.4,8.1-19,8.9-19.1c0.7-0.1,1.5,0.3,2.4,1.1c5,4.7,10.7,21.6,10.4,31.3c-1.1,4.2-1.8,8.9-2.2,14.1
c-0.4,5.7-0.4,11.7-0.2,17.7c-1.2,2.2-11.5,22-12.5,41.4C137.3,570.2,132,545.1,129.7,521.6z M147,617.7c-0.5-4.5-2-11.7-4-20.8
c-1.8-18.2,7.5-39,11-46.1c0.6,10.8,2,21.8,3.3,32c1.4,10.9,2.6,20.3,2.6,27.3c-0.1,23-8.5,42.5-12,49.7c0.1-2.1,0.2-4.1,0.3-6
C148.7,644.6,149.1,635.8,147,617.7z M141.5,749.6c-1.5-22.7,3.4-38.7,6.1-47.3c0.7-2.4,1.3-4.1,1.4-5.3c3.1-6.3,6.3-12,9.4-17.6
c1.6-2.9,3.2-5.8,4.7-8.6c1.4,5.1,5,21,3.9,45.6c-0.1,1.1-0.1,2.2-0.2,3.3c0,0.1,0,0.1,0,0.2c-1.7,28-17.4,56.5-21.7,63.9
C143.6,773.2,142.4,761.8,141.5,749.6z M145.5,786.3c1.7-2.8,16.7-27.8,21.5-55.1c0.3,4.6,0.9,9.2,1.8,13.8
c2.6,13.3,7.8,26.1,15.5,38.1c-0.7,2.6-5.5,20.4-9,41.8c-4.9,30.4-4.8,53.3,0.3,67.9c-2.8,1.4-5.2,2.1-7.3,2.5
c2.7-37.8-3.4-64.4-9.1-80.1c-5.8-16-12.1-24.1-13.2-25.4C145.8,788.6,145.7,787.5,145.5,786.3z M158.7,885.1
c0.9-2.4,1.9-4.9,2.1-8.5c0.5-5.9-1.1-13.4-2.7-21.3c-0.4-2.1-0.9-4.3-1.3-6.5c-0.9-5-2-10.3-3.1-15.8c-2.3-11.9-4.9-25.1-7.3-40
c2.5,3.5,7.1,11,11.3,22.7c5.7,15.6,11.8,42.1,9,79.7c-1.2,0.1-2.2,0-3.1-0.1c-4.2-0.7-6.1-3.3-6.6-4
C157.3,889,158,887.1,158.7,885.1z M191.6,885.1c0.8,5.2-0.7,10.3-1.9,14.8c-0.8,2.8-1.4,5.1-1.5,7.2c-0.1,2.1,0.2,13.4,0.5,16
c0.5,6.3,1.1,13.5-1.7,18.4c-2.2,3.9-5.9,6.4-16.6,6.4c-6.2,0-10.7-1.5-13.6-4.5c-2.8-2.9-3.3-6.5-3.2-9s1.2-5.5,2.3-8.6
s2.2-15,2.4-17.8c0.2-3.2-0.1-5.5-0.6-7.9c-0.3-1.9-0.7-3.8-0.7-6.3c0-0.1,0-0.2,0-0.3c1.2,1.2,3.3,2.7,6.4,3.2
c0.8,0.1,1.6,0.2,2.4,0.2c7,0,15.5-4.5,25.5-13.5C191.4,883.9,191.5,884.5,191.6,885.1z M186.2,821.4c-0.1,6.6-0.6,39.7,1,48.6
c0.6,3.5,1.4,5.1,2.1,6.8c0.5,1.4,1.1,2.7,1.6,4.8c-5.4,5-10.1,8.3-14,10.4c-10.9-31.8,5.4-96.2,8.4-107.3c1.2,1.9,2.5,3.7,3.9,5.5
C187.6,800.4,186.3,811.1,186.2,821.4z M194.7,709.7c1,8.2,2.1,17.5,2,28.9c-0.1,9.5-2.2,21.6-4.5,34.3c-0.9,4.9-1.8,10-2.6,15.2
c-18-24.9-22.1-49.9-21.2-69.4c0-0.5,0.1-1.1,0.1-1.6c0.2-4.2,0.7-8.1,1.3-11.7c3.1-18.9,10.1-32.9,12.5-37.2
c3.3,8.3,7.3,16.2,11.4,20.1C192.9,694.6,193.7,701.6,194.7,709.7z M198.1,673.9c-1.8,4.2-3.3,7.9-4.2,12.5
c-9.2-9.9-18.3-42.2-19-44.9c1-4.3,1.8-8.9,2.2-13.8c2-25.8,3.8-51.2,3.6-77.5c0-5.7-0.4-12-0.8-18.7c-0.8-14-1.6-28.4,0.6-37
c1.6,0.2,3.2,0.3,4.7,0.3c4.4,0,8.7-0.8,12.5-2.4c-5.3,15.8-6.1,40.3-6.8,64c-0.3,8.2-0.5,15.9-0.9,23.1c-0.3,4.4-0.6,8.9-1,13.7
c-2.1,27-4.5,57.5,9.2,80.5C198.2,673.7,198.1,673.8,198.1,673.9z M199.1,671.9c-13-22.6-10.7-52.4-8.6-78.7c0.4-4.8,0.7-9.3,1-13.7
c0.4-7.2,0.7-14.9,0.9-23.1c0.8-24.3,1.6-49.4,7.2-64.9c0.9-0.5,1.8-0.9,2.7-1.4c4.2,11.7,0.9,36.7-2.3,60.8
c-1.2,9-2.4,18.3-3.2,26.8c-0.1,1.5-0.3,3-0.4,4.4c-1.4,14-2.7,27.2-2.3,38.7c0,0.1,0,0.1,0,0.2c0,0.2,0,0.3,0.1,0.5
c0.5,13.1,3.2,23.8,10.5,31.1C203.3,661.2,201.2,667,199.1,671.9z M205.1,648.3c-0.1,0.8-0.2,1.5-0.3,2.2c-6.5-7-8.9-17.3-9.3-29.7
c1.8-8.7,6.1-22.5,9.6-33.5c-0.2,2.1-0.4,4.2-0.6,6.2c-0.7,10.9,0,19,0.6,26.7C205.8,628.8,206.5,637,205.1,648.3z M218.3,525
c-1.4,16.6-5,29.8-8.2,41.4c-0.4,1.3-0.7,2.7-1.1,4c-0.3,1.3-1.3,4.3-2.7,8.6c-3.1,9.6-7.9,24.4-10.8,35.5
c0.1-9.8,1.2-20.7,2.4-32.3c0.1-1.5,0.3-3,0.4-4.4c0.8-8.5,2.1-17.8,3.2-26.8c1-7.4,1.9-14.7,2.7-21.6c4.7-10.2,11.6-33.9,7.2-46.5
c2.1-2.1,3.8-4.3,5.3-6.5c0.6,3.1,1.1,7.3,1.1,7.4C217.8,483.9,220.6,499.1,218.3,525z M224.2,484.2c-1.9,0-4-0.7-4.9-1
c-0.2-1.4-0.8-5.9-1.4-8.6c1.1-1.8,2-3.5,2.7-5.1c1.9-4.1,2.9-7.7,3.6-10.4c0.6,2.7,1.7,6.3,3.6,10.4c0.7,1.6,1.6,3.3,2.7,5.1
c-0.7,2.7-1.3,7.1-1.4,8.6C228.2,483.5,226.1,484.2,224.2,484.2z M242,579.1c-1.4-4.2-2.4-7.3-2.7-8.6c-0.3-1.4-0.7-2.7-1.1-4.1
c-3.2-11.6-6.8-24.8-8.2-41.3c-2.3-25.9,0.4-41,0.5-41.2c0-0.1,0.5-4.3,1.1-7.4c1.5,2.2,3.2,4.4,5.3,6.5
c-4.4,12.5,2.4,36.3,7.2,46.5c0.8,6.9,1.7,14.2,2.7,21.6c1.2,9,2.4,18.3,3.2,26.8c0.1,1.5,0.3,3,0.4,4.4c1.1,11.5,2.2,22.4,2.4,32.3
C249.9,603.5,245.1,588.6,242,579.1z M243.2,648.4c-1.3-11.3-0.7-19.5,0-28.1c0.6-7.7,1.3-15.8,0.6-26.7c-0.1-2.1-0.4-4.1-0.7-6.2
c3.5,11,7.8,24.8,9.6,33.5c-0.4,12.4-2.8,22.7-9.3,29.7C243.4,649.9,243.3,649.1,243.2,648.4z M243.8,652.5c7.3-7.2,10-18,10.5-31.1
c0.1-0.1,0.1-0.3,0.1-0.5c0-0.1,0-0.1,0-0.2c0.4-11.5-0.9-24.6-2.3-38.7c-0.1-1.5-0.3-3-0.4-4.4c-0.8-8.5-2.1-17.8-3.2-26.8
c-3.2-24.1-6.5-49.1-2.3-60.8c0.9,0.5,1.8,1,2.7,1.4c5.7,15.5,6.5,40.6,7.2,64.9c0.3,8.2,0.5,15.9,0.9,23.1c0.3,4.4,0.6,8.9,1,13.7
c2.1,26.3,4.4,56.1-8.4,78.6C247.3,666.8,245.2,661.1,243.8,652.5z M250.3,673.8c0-0.1-0.1-0.2-0.1-0.3
c13.7-22.9,11.3-53.5,9.2-80.4c-0.4-4.8-0.7-9.3-1-13.7c-0.4-7.2-0.7-14.9-0.9-23.1c-0.7-23.7-1.5-48.2-6.8-64
c3.8,1.6,8.1,2.4,12.5,2.4c1.6,0,3.1-0.1,4.7-0.3c2.2,8.6,1.4,23.1,0.6,37c-0.4,6.7-0.7,13-0.8,18.7c-0.2,26.2,1.6,51.6,3.6,77.5
c0.4,4.9,1.1,9.5,2.2,13.8c-0.8,2.7-9.8,35-19,44.9C253.7,681.7,252.1,678,250.3,673.8z M256.2,772.9c-2.3-12.8-4.4-24.8-4.5-34.3
c-0.1-11.4,1-20.7,2-28.9c1-8.1,1.8-15.2,1-21.4c4.1-3.8,8-11.8,11.4-20.1c2.4,4.3,9.4,18.2,12.5,37.2c0.6,3.6,1.1,7.5,1.3,11.7
c0,0.5,0.1,1.1,0.1,1.6c0.9,19.6-3.2,44.5-21.2,69.4C258,782.9,257.1,777.8,256.2,772.9z M259.2,877c0.7-1.6,1.5-3.3,2.1-6.8
c1.5-9,1-42.1,0.9-48.7c-0.2-10.4-1.4-21-3-31.2c1.4-1.8,2.6-3.7,3.9-5.5c3,11.1,19.3,75.5,8.4,107.3c-3.9-2.1-8.5-5.4-14-10.4
C258,879.5,258.6,878.2,259.2,877z M291.4,893.8c-0.1,2.5-0.4,4.4-0.7,6.3c-0.4,2.4-0.8,4.7-0.6,7.9c0.2,2.8,1.5,14.8,2.6,17.8
c1.1,3.1,2.2,6.1,2.3,8.6s-0.4,6.1-3.2,9c-2.9,3-7.5,4.5-13.6,4.5c-10.8,0-14.4-2.6-16.6-6.4c-2.8-4.9-2.2-12.1-1.7-18.4
c0.2-2.6,0.3-13.9,0.3-16c-0.1-2.1-0.7-4.4-1.5-7.2c-1.3-4.5-2.7-9.5-1.9-14.8c0.1-0.6,0.2-1.2,0.3-1.7c10,9,18.5,13.5,25.5,13.5
c0.8,0,1.6-0.1,2.4-0.2c3.2-0.5,5.2-2,6.4-3.2C291.4,893.6,291.4,893.7,291.4,893.8z M294.6,832.9c-1.1,5.6-2.2,10.8-3.1,15.8
c-0.4,2.2-0.9,4.4-1.3,6.5c-1.6,7.9-3.1,15.4-2.7,21.3c0.3,3.6,1.2,6.1,2.1,8.5c0.7,2,1.5,3.9,1.7,6.2c-0.5,0.7-2.4,3.3-6.6,4
c-0.9,0.1-1.9,0.2-3.1,0.1c-2.8-37.5,3.4-64,9-79.6c4.2-11.7,8.9-19.3,11.3-22.8C299.6,807.9,296.9,821.1,294.6,832.9z M302.5,789.7
c-1.1,1.3-7.4,9.4-13.2,25.4c-5.7,15.7-11.9,42.3-9.1,80c-2.1-0.3-4.5-1.1-7.3-2.5c5.1-14.6,5.2-37.5,0.3-67.9
c-3.4-21.3-8.3-39.2-9-41.8c7.7-12,12.9-24.8,15.5-38.1c0.9-4.5,1.5-9.1,1.8-13.8c4.8,27.4,19.8,52.5,21.5,55.2
C302.8,787.4,302.7,788.6,302.5,789.7z M300.8,702.3c2.7,8.6,7.6,24.6,6.1,47.3c-0.8,12.2-2.1,23.6-3.6,34.2
c-4.3-7.4-20-35.9-21.7-63.9c0-0.1,0-0.1,0-0.2c0-1.1-0.1-2.2-0.2-3.3c-1.1-24.7,2.5-40.6,3.9-45.6c1.5,2.8,3.1,5.7,4.7,8.6
c3.1,5.5,6.2,11.2,9.4,17.6C299.6,698.1,300.1,699.9,300.8,702.3z M305.4,596.8c-2,9.2-3.5,16.4-4,20.8c-2.2,18.2-1.7,26.9-1.2,36.1
c0.1,1.9,0.2,3.9,0.3,6c-3.5-7.2-11.9-26.7-12-49.7c0-7,1.2-16.4,2.6-27.3c1.3-10.2,2.7-21.1,3.3-32
C297.9,557.8,307.2,578.6,305.4,596.8z M307.1,589.2c-1.1-19.5-11.4-39.2-12.6-41.4c0.3-6.1,0.3-12-0.2-17.7
c-0.4-5.2-1.1-9.9-2.2-14.1c-0.2-9.6,5.4-26.6,10.4-31.3c0.9-0.8,1.7-1.2,2.4-1.1c0.8,0.1,3.6,1.7,8.9,19.1c2.7,8.9,4.8,17.8,5,19
C316.4,545.2,311.1,570.3,307.1,589.2z M320.2,500.9c-0.1,5.2-0.4,10.5-0.8,16c-1.1-3.7-2.5-9.2-4.2-14.7
c-5.3-17.7-8.4-20-10.2-20.2c-1.1-0.1-2.3,0.4-3.5,1.6c-4.9,4.6-9.6,18.5-10.7,28.1c-3-8.8-7.9-15.1-14.6-19
c4.2-1.4,8.3-3.6,11.9-6.4c6.7-5.2,17.4-17.2,13.6-38.8c-0.8-4.4-1.8-8.7-3.1-12.7c9.5,10.2,20.2,44.1,20.9,46.1
C320,487.2,320.3,493.9,320.2,500.9z M299.8,402.5c1,3,2,6,3.1,9.1c6.2,17.9,13,37.6,15.8,62.3c-4.1-11.9-13.2-36.3-21.2-42.2
c-1.7-4.5-3.6-8.8-5.7-12.7c4.1-4.6,5.3-16.9,5.7-23.3C298.2,397.9,299,400.2,299.8,402.5z M291.6,379.5c1.3,3.6,2.8,7.7,4.5,12.4
c0,1.7-0.6,19.7-5.1,25.7c-5.3-9.4-11.6-16.9-17.1-22.4c-9.5-9.5-18.4-14.9-21-16.4c1.2-1.8,2.3-3.4,3.5-5c0,0,0,0,0-0.1v-0.1
c3.3-4.5,6.8-8.1,10.1-10.5c0.1,0,0.1-0.1,0.2-0.1c2-1.4,4-2.3,5.9-2.7c8.7-1.6,15.6,10.8,16.1,11.6
C289.7,374.3,290.6,376.8,291.6,379.5z M293.6,305.6c-1.3,6.6-2.7,13.1-4.2,19.4c-2.8,12.6-5.3,23.5-5,29.6c0.2,3.4,1,7.1,2.5,11.7
c-3.1-4-8.3-8.7-14.4-7.5c-1.8,0.3-3.6,1.1-5.5,2.3c-1-18.1,20.3-49.1,27.2-58.5C294,303.6,293.8,304.6,293.6,305.6z M302.1,271.8
c-0.3,1.1-3.8,11.9-7.2,27.2c-2.3,3-31.5,41.7-29.4,63.1c-3.1,2.3-6.4,5.6-9.8,10.1c-9.1-7.2-14.2-17.2-16.8-24.4
c-3.2-8.8-3.8-16-3.8-17.4c0-1,1.4-3.6,3.1-6.8c4-7.4,10.8-19.8,14.4-37.7c1.4-7,1.9-14.1,2-21.1c2,0.9,4.1,1.6,6.3,2.2
c0.1,0,0.2,0.1,0.3,0.1c2.6,0.6,5.4,0.9,8.3,0.9c5.6,0,11.7-1.1,18.1-3.4c7.3-2.6,13.1-6,15.6-7.5
C302.6,264.7,302.1,271,302.1,271.8z M287.1,263.1c-6.1,2.2-14.2,4-22.3,3c9-3.1,18.4-11.2,28.2-24.2c6.6-8.8,11.3-17.3,12.9-20.1
c0.6,0.8,1.3,1.6,1.9,2.4c-0.9,1.3-2,6-3.8,24.2c-0.2,2.3-0.4,4.5-0.6,6.7C302,256,295.6,260.2,287.1,263.1z M325.2,330.9
c-4.2-10.4-8.5-21.3-10.8-28.6c-4.6-14.9-10.1-28.9-10.8-30.5c0.3-4.8,0.8-10.5,1.3-16.2c0-0.1,0-0.1,0-0.2
c1.2-13.3,2.7-26.6,3.8-29.9c3.7,8.4,14.4,78.4,19.4,112.7C327.2,335.9,326.2,333.4,325.2,330.9z M335,358.4
c-0.3-2.4-2.1-7.5-4.5-13.8c-0.2-1.6-3.7-25.7-7.8-51.8c6.4,20,20.7,64.7,23.9,71.5l0.6,1.4c3.4,7.3,8.1,17.5,12.1,29
c1,2.9,2,5.9,2.9,8.9c3.9,13.3,5.5,33.4,6.2,47.8c-2.8-15.1-7.4-31.2-7.5-31.4c-0.9-4.7-2.2-9.7-4.1-14.8
c-8.5-23.2-19.3-26.3-22.2-26.7c0.1-4.4,0.3-8.1,0.4-11.3C335.2,363.8,335.3,360.9,335,358.4z M360.6,448.7
c-0.4-0.9-0.8-1.7-1.3-2.6c-9.7-18.9-19.8-38.5-22.6-51.7c-1.2-5.4-1.7-10.2-1.9-14.5c2.9,0.6,12.8,4.1,20.7,25.7
C362.7,425.4,361.4,442.7,360.6,448.7z M361.9,451c0.3-1.5,1.5-9,0.7-19.6c2.4,9.6,5.6,23.6,6.3,33.6
C366.7,460.6,364.4,455.9,361.9,451z M376.1,481.4c-1.4-3.7-3.3-8.1-5.5-12.8c0-2.9-0.3-43.3-6.8-65.4c-0.8-2.8-1.7-5.5-2.6-8.2
c4.2-1,5.8-5.2,6.4-7.6c0.5,0.6,1,1.2,1.5,1.7C369.6,394,377.1,463.6,376.1,481.4z M409.8,555.4c-1.3,4.3-7.7,8.7-12.5,11.6
c-2.7,1.7-4.6,3.5-5.9,4.8c-1.7,1.7-2.3,2.1-2.8,1.8c-0.6-0.4-0.4-1.5,0.5-3.3c0.1,0,0.1,0,0.2,0s0.2,0,0.3,0c1.1,0,2.4-0.8,4.8-3.1
c2.2-2.1,5.5-4.6,8.2-6.6c2.4-1.8,4.3-3.2,5-4c0.7-0.7,1.8-2.4,2.9-4.4C410.3,553.4,410.1,554.5,409.8,555.4z M414.8,526.7
c-0.3,3.6-1.1,7.4-1.8,10.7c-0.5,2.3-0.9,4.5-1.1,6.3c-0.5,3.9-4.2,10.3-5.6,11.8c-0.6,0.7-2.6,2.2-4.8,3.8
c-2.9,2.1-6.1,4.6-8.4,6.7c-2,1.8-3.2,2.8-3.7,2.7c-0.2,0-0.6-0.5-0.9-1c-0.5-0.8,1.2-3.7,3.1-5.8l-0.1-0.1c0.8,0.2,1.8,0,2.7-0.5
c3.7-2,5.9-5.4,8.5-8.5c1.8-2.4,3.6-4.9,4.6-7.8c1.1-4.7,2.9-9.2,4.4-13.9l-0.4-0.2c-2.4,4.3-4.5,8.8-5.6,13.6
c-0.9,2.7-2.6,5-4.3,7.3c-2.3,2.8-4.6,6.3-7.9,8c-1.7,0.9-2.5,0.4-1.8-1.7c0.7-1.9,2-3.7,3.3-5.4c2.1-3.1,3.3-6.7,4.6-10.2
c0.6-1.9,1.5-3.8,1.4-5.9c-0.2-4-1.4-8-3.6-11.4c-1.1-1.7-2.7-2.9-4.1-4.3c-2.7-2.8-5.1-5.9-7.2-9.1l-0.6,0.4
c2.1,3.3,4.3,6.4,6.9,9.2c-0.4,0.8-1.7,3.2-1.8,6.3c-0.1,2.3,0.6,5.3,1.3,8.7c0.4,2.1,0.9,4.2,1.3,6.5c1,6.4-3.3,8.8-4.1,9.2
c-0.9-0.6-2-5.6-2.7-8.6c-0.5-2-0.9-4.1-1.4-5.7l-0.1-0.3c-1.7-5.4-4.2-13.7-5.1-30.2c-0.6-11.4-1.7-18.4-2.6-22.3l0.1,0.2
c2.1-1,8.8-5.1,9.3-14c6.6,6,10.9,7.9,13.6,7.9c0.1,0,0.2,0,0.3,0c3.7,10.3,9,21.4,11,26.1C414.1,511.4,415.5,519.8,414.8,526.7z"/>
</svg>
</template>