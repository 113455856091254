<template>

  <!-- mainhide -->
  <div class="hide" style="font-size: 10px; padding: 10px; overflow: hidden;">
    <!-- subhide -->
    <div class="-hide" style="color: orange;">

      <p>id: {{ this.templateData.data.id }}</p>
      <p>Memberlist: {{ this.templateData.data.Memberlist }}</p>
      <p>Employeelist: {{ employeeList }}</p>
      <p>AboList: {{ this.templateData.data.Abolist }}</p>
      <p>Customerid: {{ this.templateData.data.Customerid }}</p>
      <p>CustomerSubject: {{ this.templateData.data.CustomerSubject }}</p>
      <p>CustomerPicthumb: {{ this.templateData.data.CustomerPicthumb }}</p>
      <p>Employeeid: {{ this.templateData.data.Employeeid }}</p>
      <p>EmployeeSubject: {{ this.templateData.data.EmployeeSubject }}</p>
      <p>EmployeePicthumb: {{ this.templateData.data.EmployeePicthumb }}</p>
      <p>Aboid: {{ this.templateData.data.Aboid }}</p>
      <p>LocationId: {{ this.templateData.data.LocationId }}</p>
      <p>AboSubject: {{ this.templateData.data.AboSubject }}</p>
      <p>AboPicthumb: {{ this.templateData.data.AboPicthumb }}</p>

      &nbsp;

      <p>Contractid: {{ this.templateData.data.id }}</p>
      <p>Invoiceid: {{ this.templateData.data.Invoiceid }}</p>
      <p>InvoiceDate: {{ this.templateData.data.InvoiceDate }}</p>
      <p>arrRates: {{ this.templateData.data.RateList }}</p>

      &nbsp;

      <p>DateStart: {{ this.templateData.data.DateStart }}</p>
      <p>DateStop: {{ this.templateData.data.DateStop }}</p>
      <p>DateStart1: {{ this.templateData.data.DateStart1 }}</p>
      <p>DateStop1: {{ this.templateData.data.DateStop1 }}</p>
      <p>DateStart2: {{ this.templateData.data.DateStart2 }}</p>
      <p>DateStop2: {{ this.templateData.data.DateStop2 }}</p>
      <p>DateStart3: {{ this.templateData.data.DateStart3 }}</p>
      <p>DateStop3: {{ this.templateData.data.DateStop3 }}</p>
      <p>DateStart4: {{ this.templateData.data.DateStart4 }}</p>
      <p>DateStop4: {{ this.templateData.data.DateStop4 }}</p>
      <p>DateStart5: {{ this.templateData.data.DateStart5 }}</p>
      <p>DateStop5: {{ this.templateData.data.DateStop5 }}</p>
      <p>DateInvoice: {{ this.templateData.data.DateInvoice }}</p>

      &nbsp;

      <p>ExtendDay1: {{ this.templateData.data.ExtendDay1 }}</p>
      <p>ExtendDay2: {{ this.templateData.data.ExtendDay2 }}</p>
      <p>ExtendDay3: {{ this.templateData.data.ExtendDay3 }}</p>
      <p>ExtendDay4: {{ this.templateData.data.ExtendDay4 }}</p>
      <p>ExtendDay5: {{ this.templateData.data.ExtendDay5 }}</p>

      &nbsp;

      <p>ValidDates: {{ this.templateData.data.ValidDates }}</p>
      <p>CountValidDates: {{ this.templateData.data.ValidDates.length }}</p>
      <p>StartDate: {{ this.templateData.data.ValidDates.slice(0, 1) }}</p>
      <p>StopDate: {{ this.templateData.data.ValidDates.slice(-1) }}</p>

      &nbsp;

      <p>ContractPdf: {{ this.templateData.data.ContractPdf }}</p>
      <p>SignaturePng: {{ this.templateData.data.SignaturePng }}</p>
      <p>FitnessguidePdf: {{ this.templateData.data.FitnessguidePdf }}</p>
      <p>QualicertPdf: {{ this.templateData.data.QualicertPdf }}</p>
      
      &nbsp;

      <p>Price: {{ this.templateData.data.Price }}</p>
      <p>Discount: {{ this.templateData.data.Discount }}</p>
      <p>Rates: {{ this.templateData.data.Rates }}</p>
      <p>Interval: {{ this.templateData.data.Interval }}</p>

    </div>
    <!-- /subhide -->

    &nbsp;

    <!-- subhide -->
    <div class="-hide" style="color: green;">

    <p>id: {{ id }}</p>
    <p>memberList: {{ memberList }}</p>
    <p>employeeList: {{ employeeList }}</p>
    <p>aboList: {{ aboList }}</p>
    <p>customerId: {{ customerId }}</p>
    <p>customerSubject: {{ customerSubject }}</p>
    <p>customerPicthumb: {{ customerPicthumb }}</p>
    <p>employeeId: {{ employeeId }}</p>
    <p>employeeSubject: {{ employeeSubject }}</p>
    <p>employeePicthumb: {{ employeePicthumb }}</p>
    <p>aboId: {{ aboId }}</p>
    <p>locationId: {{ locationId }}</p>
    <p>aboSubject: {{ aboSubject }}</p>
    <p>aboPicthumb: {{ aboPicthumb }}</p>

    &nbsp;

    <p>contractId: {{ id }}</p>
    <p>invoiceId: {{ invoiceId }}</p>
    <p>invoicedate: {{ invoicedate }}</p>
    <!-- <p>RateList: {{ this.templateData.data.RateList }}</p> -->
    <p>arrRates: {{ arrRates }}</p>

    &nbsp;

    <p>dateStart: {{ dateStart }}</p>
    <p>dateStop: {{ dateStop }}</p>
    <p>dateStart1: {{ dateStart1 }}</p>
    <p>dateStop1: {{ dateStop1 }}</p>
    <p>dateStart2: {{ dateStart2 }}</p>
    <p>dateStop2: {{ dateStop2 }}</p>
    <p>dateStart3: {{ dateStart3 }}</p>
    <p>dateStop3 {{ dateStop3 }}</p>
    <p>dateStart4: {{ dateStart4 }}</p>
    <p>dateStop4: {{ dateStop4 }}</p>
    <p>dateStart5: {{ dateStart5 }}</p>
    <p>dateStop5: {{ dateStop5 }}</p>
    <p>dateInvoice: {{ dateInvoice }}</p>

    &nbsp;

    <p>dayExt1: {{ dayExt1 }}</p>
    <p>dayExt2: {{ dayExt2 }}</p>
    <p>dayExt3: {{ dayExt3 }}</p>
    <p>dayExt4: {{ dayExt4 }}</p>
    <p>dayExt5: {{ dayExt5 }}</p>

    &nbsp;

    <p>validDates: {{ validDates }}</p>
    <p>CountValidDates:</p>
    <p>StartDate:</p>
    <p>StopDate:</p>

    &nbsp;


    &nbsp;

    <p>contractpdf: {{ contractpdf }}</p>
    <p>signaturepng: {{ signaturepng }}</p>
    <p>fitnessguidepdf: {{ fitnessguidepdf }}</p>
    <p>qualicertpdf: {{ qualicertpdf }}</p>

    &nbsp;

    <p>Price: {{ price }}</p>
    <p>Discount: {{ discount }}</p>
    <p>Rates: {{ rates }}</p>
    <p>Interval: {{ interval }}</p>

    </div>
    <!-- /subhide -->

  </div>
  <!-- /mainhide -->


  <form 
    :id="id"
    @submit="handleSubmit"
  >
  <div class="-dx-form">

    <DxForm 
      :id="id"
      :form-data="this.templateData.data"
      :read-only="false"
      @initialized="saveFormInstance"
      :show-colon-after-label="true"
      :show-validation-summary="true"
      validation-group="currentUserData"
    >
    <DxSimpleItem 
      data-field="id"
      help-text=""
      css-class="hide"
    >
    </DxSimpleItem>

    <DxSimpleItem 
      data-field="mastercontent"
      help-text=""
      template="mastercontent"
    >
    <DxLabel 
      :text="'mastercontent'"
      :visible="false"
      />
    </DxSimpleItem>

      <template #mastercontent>

        <div class="master-content -hide" style="padding: 0;">

          <!-- hide if context sap-memberactive -->
          <div :class="{ hide: this.templateData.data.Hascustomerid }">
          <!-- customerIdID (hidden) -->
          <input type="text" :value="customerId" :id="'memberInput'+id" class="hide" />
          </div>
          <!-- /hide if context sap-memberactive -->

          <ul :id="'accordionSignature'+id" class="accordion accordion-customer" style="border-top: none;" data-accordion>

            <li class="accordion-item is-active" :class="{ hide: !signaturepng }" data-accordion-item>
              <a href="#" class="accordion-title -has-border-top" style="padding: 10px 10px;">
                <div class="grid-x align-middle" style="height: 100%;">
                  <div class="cell">
                    <span v-if="!signaturepng" class="alert-color"><i class="fa-light fa-triangle-exclamation"></i> </span>
                    <span v-if="signaturepng" class="success-color"><i class="fa-light fa-circle-check"></i> </span>
                    Unterschrift Vertrag
                  </div>
                </div>
              </a>
              <div class="accordion-content -is-active" data-tab-content style="padding: 0 10px;">


                <div v-if="!signaturepng">
                  <div class="ck-alert" style="padding: 10px;">
                    <p><i class="fa-light fa-triangle-exclamation"></i> Unterschrift definieren</p>
                  </div>
                </div>

                <div v-if="signaturepng" class="-content-wrap">

                <!-- Unterschrift Kunde (signature.png) -->
                <div class="signature-image" style="margin: 10px 0;">
                  <img :src="signaturepng+'?v=' + timestamp" style="border: 1px solid #e0e0e0;"/>
                </div><!-- /signature-image -->



                <div class="signature-controls-save hide">
                  <!-- controls save -->
                  <ul class="menu horizontal">
                    <li>
                      <button type="button" class="cx-button tiny" @click.prevent="removeSavedStrokes()"><i class="fa-light fa-trash"></i> Unterschrift löschen</button>
                    </li>
                  </ul>
                </div><!-- /signature-controls-save -->
                  

              </div><!-- /content-wrap -->

              </div> <!-- /accordion-content-->
            </li>



            <li class="accordion-item is-active" :class="{ hide: signaturepng }" data-accordion-item>
              <a href="#" class="accordion-title -has-border-top" style="padding: 10px 10px;">
                <div class="grid-x align-middle" style="height: 100%;">
                  <div class="cell">

                    <div v-if="!signaturepng">
                        <span v-if="!signaturepng" class="alert-color"><i class="fa-light fa-triangle-exclamation"></i> </span>
                        <span v-if="signaturepng" class="success-color"><i class="fa-light fa-circle-check"></i> </span>
                        Unterschreiben Vertrag
                    </div>
                    
                  </div>
                </div>
              </a>
              <div class="accordion-content -is-active" data-tab-content style="padding: 0 10px;">
                <!-- <p>Unterschreiben Kunde (signature-canvas)</p>  -->

                <div class="content-wrap" style="padding: 10px 0;">

                  <div class="signature-controls-edit" style="margin-bottom: 5px;">
                  <!-- controls edit -->

                    <ul class="menu horizontal">
                      <li class="hide">
                        <button type="button" @click.prevent="disabled = !disabled">
                        <span v-if="!disabled"><i class="fa-light fa-unlock"></i> Unlock</span>
                        <span v-else><i class="fa-light fa-lock"></i> Lock</span>
                        </button>
                      </li>
                      <li>
                        <button type="button" @click.prevent="$refs.VueCanvasDrawing.undo()"><i class="fa-light fa-arrow-rotate-left"></i> Undo</button>
                      </li>
                      <li>
                        <button type="button" @click.prevent="$refs.VueCanvasDrawing.redo()"><i class="fa-light fa-arrow-rotate-right"></i> Redo</button>
                      </li>
                      <li class="hide">
                        <button type="button" @click.prevent="$refs.VueCanvasDrawing.redraw()"><i class="fa-light fa-arrows-rotate"></i> Redraw</button>
                      </li>
                      <li>
                        <button type="button" @click.prevent="$refs.VueCanvasDrawing.reset()"><i class="fa-light fa-eraser"></i> Reset</button>
                      </li>
                    </ul>
                  </div><!-- /signature-controls-edit -->

                  <div class="signature-canvas">
                  
                    <vue-drawing-canvas
                      ref="VueCanvasDrawing"
                      :canvasId="'VueDrawingCanvas'+id"
                      v-model:image="image"
                      :width="300"
                      :height="200"
                      :stroke-type="strokeType"
                      :line-cap="lineCap"
                      :line-join="lineJoin"
                      :fill-shape="fillShape"
                      :eraser="eraser"
                      :lineWidth="line"
                      :color="color"
                      :background-color="backgroundColor"
                      :background-image="backgroundImage"
                      :watermark="watermark"
                      :initial-image="initialImage"
                      saveAs="png"
                      :styles="{
                      border: 'solid 1px #dedede',
                      }"
                      :lock="disabled"
                      @mousemove="getCoordinate($event)"
                      :additional-images="additionalImages"
                    />

                  </div><!-- /signature-canvas -->

                  <div class="signature-image hide">
                    <img :src="image" />
                  </div><!-- /signature-image -->

                  <div class="signature-controls-save">
                    <!-- controls save -->
                    <ul class="menu horizontal">
                      <li>
                        <button type="button" class="cx-button tiny" @click.prevent="getStrokes()"><i class="fa-light fa-floppy-disk"></i> Unterschrift sichern</button>
                      </li>
                    </ul>
                  </div><!-- /signature-controls-save -->


                </div><!-- /content-wrap -->


              </div> <!-- /accordion-content-->
            </li>


            <li :id="'accItemCalendar'+id" class="accordion-item -is-active hide" data-accordion-item>
              <a href="#" class="accordion-title">
                <div class="grid-x align-middle" style="height: 100%;">
                  <div class="cell">
                    Gültige Abodaten
                  </div>
                </div>
              </a>
              <div class="accordion-content is-active" data-tab-content>

                <div class="ck-calendar-wrap show-disabled">
                  <DxCalendar
                    :id="'DxCalendar'+id"
                    :value="initialValue"
                    :show-week-numbers="false"
                    :first-day-of-week="1"
                    :selection-mode="selectionMode"
                    :min="minDateValue"
                    :max="maxDateValue"
                    :disabled-dates="disabledDates"
                  />
                </div>

              </div><!-- accordion-content-->
            </li>


          </ul>


        </div><!-- master-form -->

      </template><!-- mastercontent -->
    
      <DxButtonItem
        horizontal-alignment="left"
        :button-options="buttonOptions"
        css-class="hide"
      />

    </DxForm>

  </div><!-- -dx-form -->

  </form>

</template>

<script>

import DxForm, {
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
} from 'devextreme-vue/form';

import notify from 'devextreme/ui/notify';
import DxCalendar from 'devextreme-vue/calendar';

import $ from 'jquery';
import Foundation from 'foundation-sites';

import { ref } from 'vue';

import VueDrawingCanvas from "vue-drawing-canvas";

import auth from "../auth";

import { 
  apihost, 
  pdfhost,
} from "../api";

const initialValue = new Date();
const minDateValue = ref(null);
const maxDateValue = ref(null);
const selectionMode = ref('multiple');

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 3,
  lg: 4
}

let currentUser;
let timestamp;

let disabledDates = [];

let memberList = [];
let employeeList = [];
let aboList = [];

let id;
let customerId;
let employeeId;
let aboId;
let locationId;

let customerSubject;
let customerPicthumb;

let employeeSubject;
let employeePicthumb;

let aboSubject;
let aboPicthumb;

let arrRates;
let invoiceId;

let dateStart;
let dateStop;

let dateStart1;
let dateStop1;
let dateStart2;
let dateStop2;
let dateStart3;
let dateStop3;
let dateStart4;
let dateStop4;
let dateStart5;
let dateStop5;

let deskBreak1;
let deskBreak2;
let deskBreak3;
let deskBreak4;
let deskBreak5;

let dayExt1;
let dayExt2;
let dayExt3;
let dayExt4;
let dayExt5;

let textExt1;
let textExt2;
let textExt3;
let textExt4;
let textExt5;

let dateInvoice;

let validDates;
let arrValiddates;

let price;
let discount;
let rates;
let interval;

let contractpdf;
let signaturepng;
let fitnessguidepdf;
let qualicertpdf;

let invoicedate;

auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
  } // e.data
}); //auth


export default {
 name: 'contractitem-template',

 props: {
  templateData: Object,
 },

 components: {
  DxForm,
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  DxCalendar,

  VueDrawingCanvas,

 },

 mounted() {

  this.memberList = this.templateData.data.Memberlist;
  this.employeeList = this.templateData.data.Employeelist;
  this.aboList = this.templateData.data.Abolist;

  this.id = this.templateData.data.id;
  this.customerId = this.templateData.data.Customerid;
  this.employeeId = this.templateData.data.Employeeid;
  this.aboId = this.templateData.data.Aboid;
  this.locationId = this.templateData.data.LocationId;

  this.customerSubject = this.templateData.data.CustomerSubject;
  this.customerPicthumb = this.templateData.data.CustomerPicthumb;

  this.employeeSubject = this.templateData.data.EmployeeSubject;
  this.employeePicthumb = this.templateData.data.EmployeePicthumb;

  this.aboSubject = this.templateData.data.AboSubject;
  this.aboPicthumb = this.templateData.data.AboPicthumb;

  this.arrRates = this.templateData.data.RateList;
  this.invoiceId = this.templateData.data.Invoiceid;

  this.dateStart = this.templateData.data.DateStart;
  this.dateStop = this.templateData.data.DateStop;
  this.dateStart1 = this.templateData.data.DateStart1;
  this.dateStop1 = this.templateData.data.DateStop1;
  this.dateStart2 = this.templateData.data.DateStart2;
  this.dateStop2 = this.templateData.data.DateStop2;
  this.dateStart3 = this.templateData.data.DateStart3;
  this.dateStop3 = this.templateData.data.DateStop3;
  this.dateStart4 = this.templateData.data.DateStart4;
  this.dateStop4 = this.templateData.data.DateStop4;
  this.dateStart5 = this.templateData.data.DateStart5;
  this.dateStop5 = this.templateData.data.DateStop5;

  this.deskBreak1 = this.templateData.data.DeskBreak1;
  this.deskBreak2 = this.templateData.data.DeskBreak2;
  this.deskBreak3 = this.templateData.data.DeskBreak3;
  this.deskBreak4 = this.templateData.data.DeskBreak4;
  this.deskBreak5 = this.templateData.data.DeskBreak5;

  this.dayExt1 = this.templateData.data.ExtendDay1;
  this.dayExt2 = this.templateData.data.ExtendDay2;
  this.dayExt3 = this.templateData.data.ExtendDay3;
  this.dayExt4 = this.templateData.data.ExtendDay4;
  this.dayExt5 = this.templateData.data.ExtendDay5;

  this.textExt1 = this.templateData.data.ExtendText1;
  this.textExt2 = this.templateData.data.ExtendText2;
  this.textExt3 = this.templateData.data.ExtendText3;
  this.textExt4 = this.templateData.data.ExtendText4;
  this.textExt5 = this.templateData.data.ExtendText5;

  this.dateInvoice = this.templateData.data.DateInvoice;
  this.validDates = this.templateData.data.ValidDates;

  this.price = this.templateData.data.Price;
  this.discount = this.templateData.data.Discount;
  this.rates = this.templateData.data.Rates;
  this.interval = this.templateData.data.Interval;

  this.contractpdf = this.templateData.data.ContractPdf;
  this.signaturepng = this.templateData.data.SignaturePng;
  this.fitnessguidepdf = this.templateData.data.FitnessguidePdf;
  this.qualicertpdf = this.templateData.data.QualicertPdf;

  this.invoicedate = this.templateData.data.InvoiceDate;

  arrValiddates = [];
  if(this.validDates){
    let datesValid = this.validDates;
    //console.log(validdates);
    for(var i in datesValid){
      //console.log(validdates[i]);
      let date = new Date(datesValid[i]);
      //console.log(date);
      arrValiddates.push(date);
    }

  }
  //console.log(arrValiddates);
  this.disabledDates = arrValiddates;

  //foundation
  this.accordionSignature = new Foundation.Accordion($('#accordionSignature'+id), {
    // These options can be declarative using the data attributes
    slideSpeed: 500,
    multiExpand: false,
    allowAllClosed: true,
  });

  this.timestamp = this.printTimestamp();
  
},

 data() {
   return {

    apihost,
    pdfhost,

    timestamp,

    currentUser,
    colCountByScreen,

    memberList,
    employeeList,
    aboList,

    id,
    customerId,
    employeeId,
    aboId,
    locationId,

    customerSubject,
    customerPicthumb,

    employeeSubject,
    employeePicthumb,

    aboSubject,
    aboPicthumb,

    arrRates,
    invoiceId,

    dateStart,
    dateStop,
    dateStart1,
    dateStop1,
    dateStart2,
    dateStop2,
    dateStart3,
    dateStop3,
    dateStart4,
    dateStop4,
    dateStart5,
    dateStop5,

    deskBreak1,
    deskBreak2,
    deskBreak3,
    deskBreak4,
    deskBreak5,

    dayExt1,
    dayExt2,
    dayExt3,
    dayExt4,
    dayExt5,

    textExt1,
    textExt2,
    textExt3,
    textExt4,
    textExt5,

    dateInvoice,

    minDateValue,
    maxDateValue,
    initialValue,
    disabledDates,
    selectionMode,

    validDates,

    price,
    discount,
    rates,
    interval,

    contractpdf,
    signaturepng,
    fitnessguidepdf,
    qualicertpdf,

    invoicedate,

    //save Button
    buttonOptions: {
      text: currentUser.Translation.vueappContractFormSubmit,
      type: 'success',
      useSubmitBehavior: true,
    },

    //CANVAS
    initialImage: [
      {
        type: "dash",
        from: {
          x: 262,
          y: 154,
        },
        coordinates: [],
        color: "#000000",
        width: 5,
        fill: false,
      },
    ],

    x: 0,
    y: 0,
    image: "",
    eraser: false,
    disabled: false,
    fillShape: false,
    line: 3,
    color: "#000000",
    strokeType: "dash",
    lineCap: "round",
    lineJoin: "round",
    backgroundColor: "#FFFFFF",
    backgroundImage: null,
    watermark: null,
    additionalImages: [],

    //END CANVAS
    
   }; //return

 },
 methods: {

  formatDate(dateString) {
    const date = new Date(dateString);
    // Then specify how you want your dates to be formatted
    //return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
    return new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(date);
  },

  formatPrice(value) {
    //https://stackoverflow.com/questions/43208012/how-do-i-format-currencies-in-a-vue-component
    let val = (value/1).toFixed(2).replace('.', '.')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
  },

  async setImage(event) {
    console.log(event);
      let URL = window.URL;
      this.backgroundImage = URL.createObjectURL(event.target.files[0]);
      await this.$refs.VueCanvasDrawing.redraw();
    },
    async setWatermarkImage(event) {
      let URL = window.URL;
      this.watermark = {
        type: "Image",
        source: URL.createObjectURL(event.target.files[0]),
        x: 0,
        y: 0,
        imageStyle: {
          width: 600,
          height: 400,
        },
      };
      await this.$refs.VueCanvasDrawing.redraw();
    },
    getCoordinate(event) {
      //console.log(event);
      let coordinates = this.$refs.VueCanvasDrawing.getCoordinates(event);
      this.x = coordinates.x;
      this.y = coordinates.y;
    },

    getStrokes() {

      window.localStorage.setItem(
        "vue-drawing-canvas",
        JSON.stringify(this.$refs.VueCanvasDrawing.getAllStrokes())
      );
      //console.log("Strokes saved, reload your browser to see the canvas with previously saved image")

      const action = 'contractsave';
      const baseUrl  = apihost+'/de/vue/signature/'+this.customerId+'/'+this.id+'/'+action;
      const dataURL = this.image;

      $.ajax({
      type: "POST",
      url: baseUrl,
      data: { 
         imgBase64: dataURL
      }
      }).done(function() {
        // If you want the file to be visible in the browser 
        // - please modify the callback in javascript. All you
        // need is to return the url to the file, you just saved 
        // and than put the image in your browser.
      });      

      this.timestamp = Date.now();

      setTimeout(() => {
        this.signaturepng = apihost+'/contractsignature/'+this.id+'.png?v='+Date.now();
      }, "3000");

      notify({
          message: "Unterschrift wurde gesichert",
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);

    },
    
    removeSavedStrokes() {

      window.localStorage.removeItem("vue-drawing-canvas");
      //console.log("Strokes cleared from local storage"); 

      const action = 'contractdelete';

      const baseUrl  = apihost+'/de/vue/signature/'+this.customerId+'/'+this.id+'/'+action;
      //console.log(baseUrl);

      $.ajax({
      type: "POST",
      url: baseUrl,
      data: { 
         imgBase64: ''
      }
      }).done(function() {
        //console.log("Strokes saved, reload your browser to see the canvas with previously saved image");
        // If you want the file to be visible in the browser 
        // - please modify the callback in javascript. All you
        // need is to return the url to the file, you just saved 
        // and than put the image in your browser.
      });      

      notify({
        message: "Unterschrift wurde gelöscht",
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'success', 3000);
      
      this.signaturepng = '';

    },


    printTimestamp: function () {
      return Date.now();
    },

    saveFormInstance(e) {
      this.formInstance = e.component;
    },

  },

};

</script>

<style>

</style>
