<template>
  <div class="content-block">

<h2 class="main-title">{{ msg }}</h2>

<div class="-dx-card -has-paddings">

  <ul id="tabs" class="tabs" data-tabs>
      <li class="tabs-title is-active"><a href="#panel1d" aria-selected="true">Tab 1</a></li>
      <li class="tabs-title"><a href="#panel2d">Tab 2</a></li>
      <li class="tabs-title"><a href="#panel3d">Tab 3</a></li>
    </ul>

    <div class="tabs-content" data-tabs-content="tabs">
      <div class="tabs-panel is-active" id="panel1d">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua.
        </p>
      </div>
      <div class="tabs-panel" id="panel2d">
        <p>
          Vivamus hendrerit arcu sed erat molestie vehicula. Sed auctor neque eu tellus rhoncus ut
          eleifend nibh porttitor. Ut in nulla enim. Phasellus molestie magna non est bibendum non
          venenatis nisl tempor. Suspendisse dictum feugiat nisl ut dapibus.
        </p>
      </div>
      <div class="tabs-panel" id="panel3d">
        <p>
          Curabitur sit amet dolor vitae justo vulputate semper in quis ipsum. Proin dignissim,
          eros vitae aliquet pellentesque, tortor odio molestie felis, in tempor lectus metus nec
          lacus.
        </p>
      </div>
    </div>

  </div><!-- dx-card -->

<div style="height: 10px;"></div>
<p style="margin: 15px 5px; font-size: 11px;"><a href="https://get.foundation/sites/docs/tabs.html" target="_blank" style="color: grey;"><i class="fa-solid fa-arrow-up-right-from-square"></i> https://get.foundation/sites/docs/tabs.html</a></p>

</div><!-- content-block -->

</template>

<script>
 
import $ from 'jquery';
import Foundation from 'foundation-sites';

export default {
  name: 'dropdownmenu-example',
  mounted() {
   this.tabs = new Foundation.Tabs($('#tabs'), {
    // These options can be declarative using the data attributes
    matchHeight: false
    })
  },
  data() {
    return {
      msg: 'Tabs',
    };
  },
  methods: {

  },
  unmounted() {

  },
};
</script>

<style lang="scss">
</style>
