<template>
  <div id="list-demo">
    <div class="widget-container">
      <DxList
        :data-source="plannedTasks"
        key-expr="id"
        :search-enabled="true"
        :search-expr="['text']"

      >
        <DxItemDragging
          data="plannedTasks"
          :allow-reordering="true"
          :on-drag-start="onDragStart"
          :on-add="onAdd"
          :on-remove="onRemove"
          :on-reorder="onReorder"
          group="tasks"
        />
      </DxList>
      <DxList
        :data-source="doingTasks"
        key-expr="id"
        :search-enabled="true"
        :search-expr="['text']"
      >
        <DxItemDragging
          data="doingTasks"
          :allow-reordering="true"
          :on-drag-start="onDragStart"
          :on-add="onAdd"
          :on-remove="onRemove"
          :on-reorder="onReorder"
          group="tasks"
        />
      </DxList>
    </div>
  </div>


</template>
<script>

import DxList, { DxItemDragging } from 'devextreme-vue/list';

import { doingTasks, plannedTasks } from '../data.js';

export default {
  components: {
    DxList,
    DxItemDragging,
  },
  data() {
    return {
      doingTasks,
      plannedTasks,
    };
  },
  methods: {
    onDragStart(e) {
      let items = e.component.getDataSource().items();
      e.itemData = items[e.fromIndex];
      // e.itemData = this[e.fromData][e.fromIndex];
    },
    onAdd(e) {
      // const data = [...this[e.toData]];
      // data.splice(e.toIndex, 0, e.itemData);
      // this[e.toData] = data;
      const data = [...this[e.toData]];
      data.splice(e.toIndex, 0, e.itemData);
      this[e.toData] = data;
      e.component.getDataSource().reload();
      
    },
    onRemove(e) {
      // const data = [...this[e.fromData]];
      // data.splice(e.fromIndex, 1);
      // this[e.fromData] = data;
      const data = [...this[e.fromData]];
      const itemIndex = data.findIndex((i) => i.id === e.itemData.id);
      data.splice(itemIndex, 1);
      this[e.fromData] = data;
      e.component.getDataSource().reload();


    },
    onReorder(e) {
      this.onRemove(e);
      this.onAdd(e);
    },


  //   onDragStart(e) {
  //   let items = e.component.getDataSource().items();
  //   e.itemData = items[e.fromIndex];
  // },

  // onAdd(e) {
  //   const tasks = this.state[e.toData];
  //   tasks.splice(e.toIndex, 0, e.itemData);
  //   this.setState({
  //     [e.toData]: tasks
  //   });
  //   e.component.getDataSource().reload();
  // },

  // onRemove(e) {
  //   const tasks = this.state[e.fromData];
  //   const itemIndex = tasks.findIndex((i) => i.id === e.itemData.id);
  //   tasks.splice(itemIndex, 1);
  //   this.setState({
  //     [e.fromData]: tasks
  //   });
  //   e.component.getDataSource().reload();
  // },

  // onReorder(e) {
  //   this.onAdd(e);
  //   this.onRemove(e);
  // },

  },
};
</script>
<style>
.widget-container {
  display: flex;
}

.widget-container > * {
  height: 400px;
  width: 50%;
  padding: 10px;
}

.dx-scrollview-content {
  min-height: 380px;
}

</style>
