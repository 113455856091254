<template>

  <h2 class="app-title">
    <!-- <i class="nav-icon fa-light fa-right-to-bracket"></i>  -->
    {{ title }}
  </h2>

  <div class="-content-block hide">
    <div class="dx-card _responsive-paddings has-paddings">

      <div class="-hide" style="font-size: 10px;">
        <p>id: {{ currentUser.id }}</p>
        <p>Rfid: {{ currentUser.Rfid }}</p>
        <p>Email: {{ currentUser.Email }}</p>
        <p>Avatar: {{ currentUser.Avatar }}</p>
        <p>Language: {{ currentUser.Language }}</p>
        <p>Role: {{ currentUser.Role }}</p>

        &nbsp;

        <p>FirstName: {{ customerFirstName }}</p>
        <p>LastName: {{ customerLastName }}</p>
      </div>

    </div>
  </div>


  <div v-if="currentUser.Role == 'Customer'" class="-content-block">


    <div class="grid-x">
      <div class="auto cell" style="width: 100%;">

        <div class="dx-card -has-paddings">
          <div class="" style="padding: 10px;">
            <div class="grid-x">
              <div class="shrink cell" style="margin-right: 10px;">

                <div class="user-thumb" v-if="currentUser.Avatar"
                  v-bind:style="{ 'background-image': 'url(' + currentUser.Avatar + ')' }" >
                </div>

              </div>
              <div class="auto cell">
                <p>{{ customerFirstName }} {{ customerLastName }}</p>
                <p style="font-size: 11px;">{{ customerRfid }}</p>
              </div>
            </div><!-- grid-x -->
          </div>
        </div><!-- dx-card -->


        <div class="dx-card -has-paddings">

          <div class="text-center" style="padding: 10px;">

            <QRCodeVue3 
              v-if="render"
              :width="200"
              :height="200"
              v-model:value="qrValue"
              :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
              :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
              :dotsOptions="{
                type: 'square',
                color: '#000000',
                gradient: {
                  type: 'linear',
                  rotation: 0,
                  colorStops: [
                    { offset: 0, color: '#000000' },
                    { offset: 1, color: '#000000' },
                  ],
                },
              }"
              :backgroundOptions="{ color: '#ffffff' }"
              :cornersSquareOptions="{ type: 'square', color: '#000000' }"
              :cornersDotOptions="{ type: undefined, color: '#000000' }"
              fileExt="png"
              :download="false"
              myclass="my-qur"
              imgclass="img-qr"
              downloadButton="qr-download-button cx-button tiny"
              :downloadOptions="{ name: 'vqr', extension: 'png' }"
            />

          </div>


        </div><!-- shrink -->
      </div>

    </div>


  </div><!-- content-block -->

  <div v-else class="-content-block">
    Oh no 😢
  </div><!-- content-block -->


</template>

<script>
import auth from "../auth";
import { 
  apihost, 
} from "../api";

import QRCodeVue3 from "qrcode-vue3";
import * as OTPAuth from 'otpauth';
import { ref, nextTick } from 'vue';

const PERIOD = 10;

let currentUser;
let customerId;
let customerRfid;

let customerFirstName;
let customerLastName;
let customerEmail;
let customerEmail64;

let totp = new OTPAuth.TOTP({
  period: PERIOD,
  digits: 8,
  secret: customerEmail64,
});

let qrValue = ref(1111);
let render = ref(false);

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

function updateQR(){
  const currentTime = Math.floor(Date.now() / 1000);
  const code = totp.generate();
  const codeObject = {
    id: customerEmail64,
    totp: code,
    t: currentTime 
  };
  
  qrValue.value = JSON.stringify(codeObject);
}

async function update() {
  const currentTime = Math.floor(Date.now() / 1000);
  const counter = PERIOD - (currentTime % PERIOD + 1);

  if(counter == 1) {
    updateQR();
    render.value = false;
    await nextTick();
    render.value = true;
  }
}

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;
  } // e.data
}); //auth

export default {

  name: "VueBarcode",
  components: {
    QRCodeVue3,
  },

  async mounted() {

    await this.fetchCustomer();
    await update();
    setInterval(update, 1000);
  },


  data() {
    return {
      title: 'Check-in',

      apihost,
      currentUser,
      customerId,
      customerRfid,
      customerFirstName,
      customerLastName,

      qrValue,
      render,

    };
  },
  methods: {

    async fetchCustomer() {

      fetch(apihost+'/'+currentUser.Language+'/app/member/getmember/'+currentUser.id+'/view')
        .then(handleErrors)
        .then(response => response.text())
        .then(result => {
          const data = JSON.parse(result);
          //console.log(data);
          this.customerId = data.Id;
          this.customerRfid = data.Rfid;
          customerRfid = data.Rfid;
          this.customerFirstName = data.FirstName;
          this.customerLastName = data.LastName;
          this.customerEmail = data.Email;
          customerEmail = data.Email;
          customerEmail64 = btoa(customerEmail);
        })
        .catch(() => { throw 'Network error' });

    },


  },
  unmounted() {

  },
};
</script>

<style lang="scss">

</style>
