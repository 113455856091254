<template>

  <!-- <h2 class="content-block">
    <i class="dx-icon nav-icon fa-light fa-users"></i> 
    {{ title }}
  </h2> -->

    <div class="content-block -hide">
  
    <div class="dx-card no-paddings">
  
    <div id="form-demo">
      <div class="widget-container">
        <form
          action="your-action"
          @submit="handleSubmit"
        >
          <!-- <DxForm
            :form-data="currentUser"
            :read-only="false"
            @initialized="saveFormInstance"
            :show-colon-after-label="true"
            :show-validation-summary="true"
            validation-group="currentUserData"
            :colCountByScreen="colCountByScreen"
          > -->
          <DxForm
            :form-data="fetchUser"
            :read-only="false"
            @initialized="saveFormInstance"
            :show-colon-after-label="true"
            :show-validation-summary="true"
            validation-group="currentUserData"
            :colCountByScreen="colCountByScreen"
  
          >
  
            <!-- <DxSimpleItem data-field="Title">
            </DxSimpleItem>
  
            <DxSimpleItem data-field="FirstName">
            </DxSimpleItem>
  
            <DxSimpleItem data-field="LastName">
            </DxSimpleItem>
  
            <DxSimpleItem data-field="Email">
            </DxSimpleItem>
  
            <DxSimpleItem data-field="Phone">
            </DxSimpleItem>
  
            <DxSimpleItem data-field="Street">
            </DxSimpleItem>
  
            <DxSimpleItem data-field="Zip">
            </DxSimpleItem>
  
            <DxSimpleItem data-field="City">
            </DxSimpleItem>
  
            <DxSimpleItem data-field="BirthDate">
            </DxSimpleItem>
  
            <DxSimpleItem data-field="Role">
            </DxSimpleItem>
  
            <DxSimpleItem data-field="Notes">
            </DxSimpleItem> -->
  
  
            <DxGroupItem caption="Login Data">
  
              <DxSimpleItem 
                data-field="Email"
                help-text=""
              >
                <DxLabel text="Email"/>
                <DxRequiredRule message="Email is required"/>
                <DxEmailRule message="Email is invalid"/>
                <DxAsyncRule
                  :validation-callback="asyncValidation"
                  message="Email is already registered"
                />
              </DxSimpleItem>
  
              <DxSimpleItem
                data-field="Password"
                help-text=""
                :editor-options="passwordOptions"
                >
                <DxLabel text="Password"/>
                <DxRequiredRule message="Password is required"/>
              </DxSimpleItem>
  
              <DxSimpleItem
                name="ConfirmPassword"
                help-text=""
                editor-type="dxTextBox"
                :editor-options="confirmPasswordOptions"
              >
              <DxLabel text="Confirm Password"/>
                <DxRequiredRule message="Confirm Password is required"/>
                <DxCompareRule
                  :comparison-target="passwordComparison"
                  message="Password and Confirm Password do not match"
                />
              </DxSimpleItem>
  
            </DxGroupItem>
  
            <DxGroupItem caption="Personal Data">
  
              <DxSimpleItem 
                data-field="Title"
                help-text=""
              >
                <DxLabel text="Title"/>
                <DxRequiredRule message="Title is required"/>
                <DxPatternRule
                  :pattern="titlePattern"
                  message="Do not use digits in the Title"
                />
              </DxSimpleItem>
  
              <DxSimpleItem 
                data-field="FirstName"
                help-text=""
              >
                <DxLabel text="First Name"/>
                <DxRequiredRule message="First name is required"/>
                <DxPatternRule
                  :pattern="firstnamePattern"
                  message="Do not use digits in the First name"
                />
              </DxSimpleItem>
  
              <DxSimpleItem 
                data-field="LastName"
                help-text=""
              >
                <DxLabel text="Last Name"/>
                <DxRequiredRule message="Last name is required"/>
                <DxPatternRule
                  :pattern="lastnamePattern"
                  message="Do not use digits in the Last name"
                />
              </DxSimpleItem>
  
              <DxSimpleItem
                data-field="Phone"
                help-text=""
              >
                <DxLabel text="Phone"/>
              </DxSimpleItem>
              
              <DxSimpleItem
                data-field="Street"
                help-text=""
              >
                <DxLabel text="Street"/>
              </DxSimpleItem>
  
              <DxSimpleItem 
                data-field="Zip"
                help-text=""
              >
                <DxLabel text="Zip"/>
              </DxSimpleItem>
  
              <DxSimpleItem 
                data-field="City"
                help-text=""
              >
                <DxLabel text="City"/>
              </DxSimpleItem>
  
  
              <DxSimpleItem 
                data-field="Notes"
                help-text=""
              >
                <DxLabel text="Notes"/>
              </DxSimpleItem>
              
  
              <DxSimpleItem
                data-field="BirthDate"
                help-text=""
                editor-type="dxDateBox"
              >
                <DxLabel text="Birth Date"/>
              </DxSimpleItem>
  
            </DxGroupItem>
  
            <DxButtonItem
              horizontal-alignment="left"
              :button-options="buttonOptions"
            />
  
          </DxForm>
        </form>
      </div>
    </div>
  
  </div><!-- dx-card -->
  
  </div><!-- content-block -->
    
  </template>
  <script>
  import DxForm, {
    DxGroupItem,
    DxSimpleItem,
    DxButtonItem,
    DxLabel,
    DxRequiredRule,
    DxCompareRule,
    //DxRangeRule,
    //DxStringLengthRule,
    DxPatternRule,
    DxEmailRule,
    DxAsyncRule,
  } from 'devextreme-vue/form';
  //import DxAutocomplete from 'devextreme-vue/autocomplete';
  
  import notify from 'devextreme/ui/notify';
  import Validator from 'devextreme/ui/validator';
  
  import auth from "../auth";

  import { 
    apihost, 
  } from "../api";
  //console.log(apihost);

  const colCountByScreen = {
    xs: 1,
    sm: 1,
    md: 2,
    lg: 2
  }

  let currentUser;
  let fetchUser;

  auth.getUser().then((e) => {
    if(e.data){
      //console.log(e.data);
      currentUser = e.data;
    } // e.data
  }); //auth

  
  const sendRequest = function(value) {
    const invalidEmail = 'test@dx-email.com';
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(value !== invalidEmail);
      }, 1000);
    });
  };
  
  
  function handleErrors(response) {
      if (!response.ok) {
          throw Error(response.statusText);
      }
      return response;
    }
  
  export default {
  
    mounted() {
  
      //auth.getUser().then((e) => {
        //if(e.data){
          // console.log(e.data);
          // console.log(e.data.id);
          // console.log(e.data.Language);
  
          //DATA
          setTimeout(() => {
            // fetch(apihost+'/de/vue/member/getmember/'+e.data.id+'/view')
          fetch(apihost+'/de/vue/member/getmember/0/view')
          .then(response => response.text())
          .then(result => {
          const data = JSON.parse(result);
          //console.log(data);
          this.fetchUser = data;
          })
          .catch(() => { throw 'Network error' });
          
          }, 200);
  
        //} // e.data
        
      //}); //auth
  
    },
  
    components: {
      DxGroupItem,
      DxSimpleItem,
      DxButtonItem,
      DxLabel,
      DxRequiredRule,
      DxCompareRule,
      DxPatternRule,
      //DxRangeRule,
      DxEmailRule,
      //DxStringLengthRule,
      DxForm,
      //DxAutocomplete,
      DxAsyncRule,
      //notify,
    },
    data() {
      return {
        apihost, 
        
        title: 'Register Form',
        colCountByScreen,
        formInstance: null,
        currentUser,
        fetchUser,
  
        buttonOptions: {
        text: currentUser.Translation.vueappCustomerProfileSubmit,
          type: 'success',
          useSubmitBehavior: true,
        },
        passwordOptions: {
          mode: 'password',
          onValueChanged: () => {
            const editor = this.formInstance.getEditor('ConfirmPassword');
            if (editor.option('value')) {
              const instance = Validator.getInstance(editor.element());
              instance.validate();
            }
          },
          buttons: [
            {
              name: 'password',
              location: 'after',
              options: {
                icon: apihost+'/images/icons/eye.png',
                type: 'default',
                onClick: () => this.changePasswordMode('Password'),
              },
            },
          ],
        },
        confirmPasswordOptions: {
          mode: 'password',
          buttons: [
            {
              name: 'password',
              location: 'after',
              options: {
                icon: apihost+'/images/icons/eye.png',
                type: 'default',
                onClick: () => this.changePasswordMode('ConfirmPassword'),
              },
            },
          ],
        },
        dateBoxOptions: {
          invalidDateMessage:
            'The date must have the following format: MM/dd/yyyy',
        },
        checkBoxOptions: {
          text: 'I agree to the Terms and Conditions',
          value: false,
        },
        phoneEditorOptions: {
          mask: '+1 (X00) 000-0000',
          maskRules: {
            X: /[02-9]/,
          },
          maskInvalidMessage: 'The phone must have a correct USA phone format',
        },
        // cityEditorOptions: {
        //   //dataSource: service.getCities(),
        //   dataSource: cities,
        //   minSearchLength: 2,
        // },
        // countryEditorOptions: {
        //   //dataSource: service.getCountries(),
        //   dataSource: countries,
        // },
        //maxDate: new Date().setFullYear(new Date().getFullYear() - 21),
        titlePattern: /^[^0-9]+$/,
        firstnamePattern: /^[^0-9]+$/,
        lastnamePattern: /^[^0-9]+$/,
        namePattern: /^[^0-9]+$/,
        cityPattern: /^[^0-9]+$/,
        //phonePattern: /^[02-9]\d{9}$/,
      };
    },
    methods: {
      saveFormInstance(e) {
        this.formInstance = e.component;
        //console.log(e.component);
      },
      changePasswordMode(name) {
        const editor = this.formInstance.getEditor(name);
        editor.option(
          'mode',
          editor.option('mode') === 'text' ? 'password' : 'text',
        );
      },
      passwordComparison() {
        return this.fetchUser.Password;
      },
      checkComparison() {
        return true;
      },
      asyncValidation(params) {
        return sendRequest(params.value);
      },
      handleSubmit(e) {
  
        e.preventDefault();
  
        //auth.getUser().then((e) => {
          //if(e.data){
  
            const objString = '?' + new URLSearchParams(this.fetchUser).toString();
            //console.log(objString);
            
            // fetch(apihost+'/de/vue/member/getmember/'+e.data.id+'/save'+objString)
            fetch(apihost+'/de/vue/member/getmember/0/insert'+objString)
            .then(handleErrors)
            .then(response => response.text())
            .then(result => {
            const data = JSON.parse(result);
            //console.log(data);
            this.fetchUser = data;
            })
            .catch(() => { throw 'Network error' });
  
          //} // e.data
          
        //}); //auth
  
        notify({
          message: 'You have submitted the form',
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);
  
      },
    },
  };
  </script>
  <style scoped>
  form {
    margin: 15px;
  }
  </style>
  