<template>
  <div class="footer-item-alert grid-x align-middle -hide" :class="{ hide: count < 1 }">
    <div class="cell">
      <p>{{ count }}</p>
    </div>
  </div>
</template>


<script>
 
//import $ from 'jquery';
//import Foundation from 'foundation-sites';

export default {
  name: 'tooltip-example',

  props: {
    count: Number,
  },

  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  unmounted() {

  },
};
</script>
