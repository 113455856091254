<template>
  <div class="content-block">
  <div class="dx-card _responsive-paddings has-paddings">

    <div class="hide" style="font-size: 10px;;">
    option1list: {{ option1list }}
    </div>

    <div id="musclegroupContainer" class="musclegroup-container -grid-container" style="padding: 40px 0 20px 0;">

    <div class="show-for-medium grid-x align-middle" style="position: absolute; top: 0; left: 0; width: 100%; height: 30px; text-align: center;">
    <div class="cell">
    <span style="font-size: 11px; border: 1px solid #dedede; background: #f1f1f1; padding: 3px 10px; border-radius: 5px; opacity: 0;" id="musclegroupindicator"></span>
    </div>
    </div>

    <div class="grid-x grid-margin-x">

    <div class="auto cell">

    <div class="grid-x medium-up-2 xmedium-up-4">
    <div class="cell">
      <ManFront />
    <div class="download-svg-link text-center" style="height: 30px; font-size: 12px;">
    <a href="/static/assets/svg/man_front.svg" target="_blank" download>man_front.svg</a>
    </div>
    </div>

    <div class="cell">
      <WomanFront />
    <div class="download-svg-link text-center" style="height: 30px; font-size: 12px;">
    <a href="/static/assets/svg/woman_front.svg" target="_blank" download>woman_front.svg</a>
    </div>
    </div>

    <div class="cell">
      <ManBack />
    <div class="download-svg-link text-center" style="height: 30px; font-size: 12px;">
    <a href="/static/assets/svg/man_back.svg" target="_blank" download>man_back.svg</a>
    </div>
    </div>

    <div class="cell">
      <WomanBack />
    <div class="download-svg-link text-center" style="height: 30px; font-size: 12px;">
    <a href="/static/assets/svg/woman_back.svg" target="_blank" download>woman_back.svg</a>
    </div>
    </div>
    </div>	    

    </div>

    <div class="shrink cell">

    <div id="checkboxContainer">

      <div class="checkbox-container" v-for="(option1) in option1list" :key="option1.id">
        <input name="checkboxes11689" :id="option1.Itemkey" type="checkbox" :value="option1.Itemkey">
        <label :for="option1.Itemkey">{{ option1.Subject }}</label>
        <span class="checkmark"></span>
      </div>

    </div><!-- checkboxContainer -->

    </div><!-- shrink -->
    </div><!-- grid-x -->

    </div><!-- musclegroupContainer -->


  </div>
  </div>
</template>

<script>

import ManFront from '../svg/man_front.vue';
import ManBack from '../svg/man_back.vue';
import WomanFront from '../svg/woman_front.vue';
import WomanBack from '../svg/woman_back.vue';

import {
  //DxDataGrid, 
  //DxFilterRow, 
  //DxColumn, 
  //DxEditing, 
  //DxPager, 
  //DxPaging, 
  //DxButton,
  //DxMasterDetail,
  //DxLookup, 
} from 'devextreme-vue/data-grid';

//import CustomStore from 'devextreme/data/custom_store';
//import notify from 'devextreme/ui/notify';

import {
  //DxForm
  //DxGroupItem,
  //DxSimpleItem,
  //DxButtonItem,
  //DxLabel,
  //DxRequiredRule,
  //DxCompareRule,
  //DxRangeRule,
  //DxStringLengthRule,
  //DxPatternRule,
  //DxEmailRule,
  //DxAsyncRule,
} from 'devextreme-vue/form';

import axios from "axios";

let { data } = { undefined };
//console.log(data);

let option1list = data;
//console.log(option1list);

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

let currentUser;

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;
  } // e.data
}); //auth

export default {

  components: {
    ManFront,
    ManBack,
    WomanFront,
    WomanBack,

  },

  async mounted() {

    data = (await axios.get(apihost+'/de/vue/exerciseoption1/list/')).data;

    //Timestamp
    this.timestamp = this.printTimestamp();

    //SVG HOVER CLICK
    const musclegroup = document.getElementById("musclegroupContainer");
    const svgnodes = musclegroup.getElementsByTagName("g");
    for (let i = 0; i < svgnodes.length; i++) {

    //hover
    if(svgnodes[i].id && svgnodes[i].id != 'Kontur'){
    svgnodes[i].addEventListener('mouseover', () => {
    let text = svgnodes[i].id.replace('_',' ').replace('_','').replace('MF','').replace('MB','').replace('WF','').replace('WB','').replace('Ae','Ä').replace('ae','ä').replace('Ue','Ü').replace('ue','ü');
    document.getElementById("musclegroupindicator").innerHTML = text;
    document.getElementById("musclegroupindicator").style.opacity = "1";

    });
    svgnodes[i].addEventListener('mouseout', () => {
    document.getElementById("musclegroupindicator").innerHTML = "";
    document.getElementById("musclegroupindicator").style.opacity = "0";
    });
    }

    //click
    svgnodes[i].addEventListener('click', () => {
    let text = svgnodes[i].id.replace('_MF','').replace('_MB','').replace('_WF','').replace('_WB','');
    let thischeckbox = document.getElementById(text);

    if(thischeckbox){

    let svgmanfront = document.getElementById(thischeckbox.id+'_MF');
    let svgmanback = document.getElementById(thischeckbox.id+'_MB');
    let svgwomanfront = document.getElementById(thischeckbox.id+'_WF');
    let svgwomanback = document.getElementById(thischeckbox.id+'_WB');

    if(thischeckbox.checked){
    thischeckbox.checked = false;
    if(svgmanfront){
    svgmanfront.classList.remove("checked");
    }
    if(svgmanback){
    svgmanback.classList.remove("checked");
    }
    if(svgwomanfront){
    svgwomanfront.classList.remove("checked");
    }
    if(svgwomanback){
    svgwomanback.classList.remove("checked");
    }
    } else {
    thischeckbox.checked = true;
    if(svgmanfront){
    svgmanfront.classList.add("checked");
    }
    if(svgmanback){
    svgmanback.classList.add("checked");
    }
    if(svgwomanfront){
    svgwomanfront.classList.add("checked");
    }
    if(svgwomanback){
    svgwomanback.classList.add("checked");
    }
    } //thischeckbox.checked

    } //thischeckbox

    });

    } // for svgnodes

    //HIGHLIGHT SVG BY CHECKBOX

    const checkboxes = document.getElementById("checkboxContainer");
    const checknodes = checkboxes.getElementsByTagName("input");
    for (let i = 0; i < checknodes.length; i++) {

    checknodes[i].addEventListener('click', () => {

    let svgmanfront = document.getElementById(checknodes[i].id+'_MF');
    let svgmanback = document.getElementById(checknodes[i].id+'_MB');
    let svgwomanfront = document.getElementById(checknodes[i].id+'_WF');
    let svgwomanback = document.getElementById(checknodes[i].id+'_WB');


    if(checknodes[i].checked){
    if(svgmanfront){
    svgmanfront.classList.add("checked");
    }
    if(svgmanback){
    svgmanback.classList.add("checked");
    }
    if(svgwomanfront){
    svgwomanfront.classList.add("checked");
    }
    if(svgwomanback){
    svgwomanback.classList.add("checked");
    }
    } else {
    if(svgmanfront){
    svgmanfront.classList.remove("checked");
    }
    if(svgmanback){
    svgmanback.classList.remove("checked");
    }
    if(svgwomanfront){
    svgwomanfront.classList.remove("checked");
    }
    if(svgwomanback){
    svgwomanback.classList.remove("checked");
    }
    } //checknodes[i].checked

    });

    } //for checknodes


  },

  data() {
    return {
      apihost,
      
      title: 'Muskelgruppen',
      currentUser,
      option1list,
      //exerciseoption1,

    };
  },

  methods: {

    printTimestamp: function () {
        return Date.now();
    },

  },

};

</script>

<style lang="scss">
</style>
