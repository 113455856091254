<template>
<svg version="1.1" id="fitnessunited-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 1160.6 237.4" style="enable-background:new 0 0 1160.6 237.4;" xml:space="preserve">
<path class="st0" d="M0.3,0c0.3,0,0.6,0,0.8,0c37.6,0,75.1,0,112.7,0c0.3,0,0.6,0,0.8,0c0.1,0.1,0.1,0.1,0.2,0.2c0,0.4,0,0.8,0,1.2
c0,78.2,0,156.4,0,234.6c0,0.4,0,0.8,0,1.4c-1.7-0.1-3.3-0.2-5-0.3c-1.3-0.1-2.6-0.1-3.9-0.3c-4.1-0.6-8.1-1.2-12.2-1.9
c-5.1-0.9-10.1-2.3-14.9-4.1c-3.7-1.4-7.3-2.9-10.9-4.5c-6.2-2.7-12-6.2-17.6-10.1c-2.7-1.9-5.3-3.8-7.7-5.9
c-3.8-3.3-7.6-6.7-11.1-10.3c-3.3-3.5-6.2-7.4-9.1-11.2c-3.7-4.7-6.7-9.9-9.4-15.2c-3.7-7-6.7-14.4-8.7-22
c-1.2-4.4-2.1-8.9-2.9-13.5c-0.6-3.7-0.9-7.5-1.2-11.3c-0.2-2.2-0.2-4.4-0.3-6.5c0-39.7,0-79.4,0-119c0-0.3,0-0.6,0-1
C0.1,0.1,0.2,0.1,0.3,0z"/>
<path class="st0" d="M246,0.2c0,0.3,0,0.6,0,0.8c0,21.6,0,43.1,0,64.7c0,0.3,0,0.6,0,0.8c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0-0.6,0-1,0
c-38,0-76,0-114,0c-0.3,0-0.6,0-0.8,0c-0.1-0.1-0.1-0.1-0.2-0.2c0-9.9,0-19.9,0-29.8c0-11.7,0-23.5,0-35.2c0-0.4,0-0.9,0-1.5
c0.4,0,0.8-0.1,1.2-0.1c38,0,76,0,114,0c0.3,0,0.6,0,0.8,0C245.9,0.1,245.9,0.2,246,0.2z"/>
<path class="st0" d="M129.8,148.4c0-11.9,0-23.9,0-35.8c0-10.1,0-20.2,0-30.3c0.1-0.1,0.1-0.1,0.2-0.2c0.3,0,0.6,0,1,0
c38,0,75.9,0,113.9,0c0.3,0,0.6,0,1,0c0.1,0.1,0.1,0.1,0.2,0.2c0,0.3,0,0.6,0,0.8c0,21.5,0,43,0,64.5c0,0.3,0,0.6,0,0.8
c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0-0.6,0-1,0c-38,0-75.9,0-113.9,0c-0.3,0-0.6,0-1,0C129.9,148.5,129.9,148.4,129.8,148.4z"/>
<path class="st1" d="M786,142.3c0-19.1,0-38.3,0-57.7c4.2,0,8.2,0,12.2,0c0.1,0.1,0.3,0.2,0.4,0.3c0,4.9,0.1,9.7,0.1,14.6
c0,16.6-0.2,33.1,0.1,49.7c0.1,6.9,1.8,13.6,8.1,17.9c3.6,2.4,7.5,4,12,4c5.7,0,11.3,0,16.5-2.8c5.8-3.1,9-8,9.7-14.3
c0.7-5.9,0.8-11.9,0.9-17.9c0.1-17,0.1-34.1,0.1-51.1c0.1-0.1,0.3-0.2,0.4-0.4c3.8,0,7.6,0,11.4,0c0.2,0.1,0.4,0.2,0.5,0.3
c0,0.8,0.1,1.6,0.1,2.4c0,19,0.1,37.9,0,56.9c0,6.5-0.4,13.1-2.9,19.2c-4.2,10.1-12,15.8-22.5,18.1c-1.5,0.3-2.9,0.7-4.4,1
c-4.5,0-8.9,0-13.4,0c-3.1-0.8-6.2-1.4-9.2-2.5c-10.4-3.8-16.8-11.3-18.9-22.1c-1-5-1-10.1-1.5-15.1C786,142.7,786,142.5,786,142.3z
"/>
<path class="st1" d="M632.3,182.5c-3.4-1-6.9-1.6-10.1-3c-3.4-1.5-6.6-3.7-9.7-5.8c-1.7-1.2-3.1-2.8-4.8-4.4
c2.6-2.9,5.1-5.7,7.5-8.4c2.4,2.2,4.4,4.5,6.9,6.3c5.5,3.9,11.5,6.2,18.5,4.9c3-0.6,5.7-1.3,8.3-2.9c2.9-1.7,3.7-4.5,3.7-7.3
c0-2.6-1-5.4-4-6.3c-3.9-1.2-7.9-2.2-11.8-3.2c-5.2-1.4-10.6-2.3-15.6-4.2c-12.9-5.1-12.9-20.3-6.3-27.9c4.6-5.4,10.7-7.6,17.4-8
s13.4-0.1,19.6,2.9c3.9,1.8,7.4,4.1,10.3,7.4c-2.6,2.6-5.1,5.1-7.7,7.7c-3.5-3-7.1-6.1-11.8-7.3c-5.8-1.5-11.4-1.8-16.7,1.6
c-3.9,2.6-5.3,9.8,0.7,13c4.9,2.6,10.4,3.1,15.7,4.3c5.5,1.3,11.2,2.2,15.9,5.7c4.1,3,6.1,7,6.4,12.1c0.8,13.6-9.4,20-19.3,21.9
c-1.2,0.2-2.4,0.6-3.7,0.9C638.7,182.5,635.5,182.5,632.3,182.5z"/>
<path class="st1" d="M698.8,182.5c-3.1-0.8-6.3-1.4-9.3-2.5c-6.1-2.2-11-6-15.1-10.9c2.5-2.7,5-5.5,7.4-8.2c2.2,2,4.1,4.1,6.3,5.9
c4.8,3.8,10.3,5.9,16.6,5.6c3.8-0.2,7.5-0.9,10.8-3c2.7-1.7,3.9-4.6,3.8-7.6c-0.1-2.8-1.2-5.5-4.3-6.4c-3.8-1.1-7.7-2.1-11.7-3
c-6-1.3-12.1-2.3-17.6-5.3c-5.6-3-8.2-7.8-8.4-14.1c-0.3-8.3,3.6-14.1,10.6-17.7c3.4-1.8,7.5-2.6,11.3-2.9
c6.6-0.6,13.3-0.3,19.4,2.8c3.7,1.9,7.1,4.5,10.8,6.9c-3.1,3.1-5.7,5.7-8.2,8.2c-4.7-4.3-9.7-7.5-16.3-8.1c-4.4-0.4-8.4,0-12,2.4
c-5.1,3.4-4.9,10.9,0.8,13.1c4.9,1.8,10.1,3.1,15.2,4.3c5.7,1.3,11.4,2.2,16.2,5.8c3.9,2.9,5.9,6.7,6.2,11.6
c1.1,14-9.8,20.8-19.6,22.3c-1.2,0.2-2.3,0.6-3.4,0.9C705.2,182.5,702,182.5,698.8,182.5z"/>
<path class="st1" d="M425.1,113.1c0-7.1,0.1-14.3,0-21.4c0-1.9,0.3-2.9,2.4-3.1c3.1-0.4,6.1-1.1,9.1-1.7c0.1,0.1,0.3,0.3,0.4,0.4
c0,8.6,0,17.3,0,26.3c5,0,9.7,0,14.5,0c0.1,0.2,0.2,0.4,0.3,0.5c0,2.7,0,5.4,0,8.4c-4.9,0-9.7,0-14.7,0c0,1,0,1.8,0,2.6
c0,13.3,0,26.6,0,39.9c0,6.4,2.8,8.7,9.1,7.5c1.7-0.3,3.4-0.4,5.3-0.7c0,3,0.1,5.6-0.1,8.2c0,0.6-1.2,1.3-2,1.5
c-1.6,0.5-3.4,0.7-5.1,1c-2.9,0-5.7,0-8.6,0c-1.9-0.8-4-1.3-5.8-2.3c-3.3-1.8-4.7-4.8-4.7-8.5c-0.1-16.3-0.1-32.6-0.1-49
c-0.2-0.1-0.3-0.1-0.5-0.2c-4.1,0-8.2,0-12.4,0c-0.1-0.1-0.3-0.2-0.4-0.4c0-2.8,0-5.7,0-8.8c4.3,0,8.5,0,12.8,0
C424.8,113.4,424.9,113.2,425.1,113.1z"/>
<path class="st1" d="M990.2,89.2c3.8-0.9,7.5-1.7,11.7-2.7c0,9.2,0,17.9,0,26.6c0.1,0.1,0.2,0.2,0.3,0.3c4.7,0,9.3,0,14.2,0
c0,3,0,5.9,0,8.8c-0.1,0.1-0.3,0.2-0.4,0.4c-4.6,0-9.2,0-13.8,0.1c-0.1,0.1-0.2,0.2-0.4,0.3c0,13.6,0,27.1,0,40.7
c0,0.9,0.1,1.7,0.1,2.6c0.1,0.5,0.1,1,0.2,1.5c0.5,3.4,2,4.8,5.3,4.7c3-0.1,5.9-0.4,9-0.6c0,2.9,0,6,0,9.2c-2.2,0.5-4.4,1-6.6,1.5
c-3.2,0-6.4,0-9.6,0c-1.5-0.5-3.1-0.8-4.4-1.5c-3.3-1.6-5.2-4.2-5.6-7.9c-0.1-0.9-0.2-1.9-0.3-2.8c0-0.2-0.1-0.5-0.1-0.7
c0-15.5,0-31.1,0-46.9c-4.3,0-8.5,0-12.9,0c0-3,0-5.8,0-8.5c0.1-0.2,0.2-0.4,0.3-0.5c4.1,0,8.2-0.1,12.3-0.1
c0.1-0.1,0.2-0.2,0.3-0.4c0-7.8,0-15.6,0.1-23.4C990,89.6,990.1,89.4,990.2,89.2z"/>
<path class="st1" d="M312.7,180.6c0.2,0.1,0.4,0.1,0.5,0.2c-0.2,0.1-0.4,0.2-0.5,0.3C312.7,180.9,312.7,180.8,312.7,180.6z"/>
<path class="st1" d="M313.3,85c0.1-0.1,0.1-0.3,0-0.4c20.8,0,41.7,0,62.5,0c0.1,0.1,0.3,0.2,0.4,0.3c0,3.5,0,7,0,10.5
c-0.1,0.1-0.2,0.2-0.4,0.4c-15.4,0-30.7,0.1-46.1,0.1c-1.3,0-2.7,0-4.3,0c0,9.8,0,19.5,0,29.2c0.1,0.2,0.1,0.3,0.2,0.5
c15,0,30.1,0,45.3,0c0,3.8,0,7.4,0,11.2c-15.1,0-30.1,0-45.2,0c-0.1,0.1-0.2,0.2-0.3,0.4c0,14.3,0,28.7-0.1,43
c-0.1,0.2-0.2,0.3-0.3,0.5c-4,0-8,0-12,0C313.2,148.8,313.2,116.9,313.3,85z"/>
<path class="st1" d="M464,180.1c0-20.3,0-40.7-0.1-61c0-1.7,0-3.3,0.1-5c0.1-0.2,0.3-0.3,0.4-0.5c3.7,0,7.5,0,11.4,0
c0,2.8,0,5.6,0,8.1c3-2.4,5.9-5.4,9.3-7.2c2.7-1.5,6-2,9.1-2.2c4-0.2,8.1,0,11.9,1c7.8,2,12,7.7,13.2,15.5c0.8,5,1.1,10.2,1.1,15.3
c0.2,12.1,0.1,24.2,0.1,36.6c-3.9,0-7.8,0-11.6,0c-0.1-0.2-0.2-0.4-0.3-0.5c0-13.5,0-27.1,0-40.6c0-0.2,0-0.4-0.1-0.6
c-0.8-3.4-1.1-6.9-2.6-10c-1.9-4.2-5.6-6.3-10.3-6.2c-2.8,0.1-5.7,0-8.3,0.8c-5.5,1.8-8.8,5.9-10.4,11.4c-0.6,1.9-0.7,4-1.1,6
c-0.1,0-0.2,0.1-0.2,0.1c0,0.1,0.1,0.2,0.1,0.4c0,12.9,0,25.8,0,38.7c-0.1,0.2-0.3,0.3-0.4,0.5c-3.7,0-7.3,0-11,0
C464.3,180.5,464.1,180.3,464,180.1z"/>
<path class="st1" d="M890.2,180.6c-3.7,0-7.5,0-11.3,0c0-22.3,0-44.6,0-67c3.8,0,7.5,0,11.4,0c0,2.9,0,5.7,0,9.1
c0.9-0.9,1.5-1.4,1.9-2.1c4.5-5.8,10.5-8.5,17.8-8.4c3.6,0.1,7.3,0.2,10.8,1c7,1.6,11.3,6.6,12.9,13.4c1.1,4.8,1.4,9.8,1.5,14.7
c0.2,12.3,0.1,24.5,0.1,36.8c0,0.8,0,1.6,0,2.5c-3.9,0-7.7,0-11.5,0c-0.1-0.2-0.3-0.3-0.4-0.5c0-13.6,0-27.1,0-40.7
c0.1,0,0.2-0.1,0.2-0.1c0-0.1-0.1-0.2-0.2-0.3c-0.6-2.9-1.1-5.8-1.9-8.6c-1.4-4.9-6.4-7.6-10.8-7.6c-2.4,0-4.8-0.1-7.1,0.4
c-7,1.5-10.5,6.6-12.3,13.1c-0.4,1.5-0.4,3.1-0.6,4.7c0,0.2,0,0.4,0,0.6c0,12.9,0,25.8,0,38.7C890.4,180.3,890.3,180.5,890.2,180.6z
"/>
<path class="st1" d="M389.8,113.6c3.7,0,7.5,0,11.3,0c0,22.2,0,44.6,0,67c-3.8,0-7.6,0-11.3,0c-0.1-0.2-0.2-0.4-0.3-0.5
c0-22,0-44,0-65.9C389.5,114,389.6,113.8,389.8,113.6z"/>
<path class="st1" d="M965.8,180.6c-3.7,0-7.5,0-11.3,0c0-22.2,0-44.6,0-67c3.8,0,7.6,0,11.3,0c0.1,0.2,0.3,0.3,0.4,0.5
c0,22,0,44,0,66C966,180.3,965.9,180.5,965.8,180.6z"/>
<path class="st1" d="M389.4,99.3c0-4.8,0-9.6,0-14.6c3.9,0,7.6,0,11.3,0c0.2,0.1,0.3,0.2,0.4,0.4c0,4.8,0,9.7,0,14.7
c-3.8,0-7.6,0-11.4,0C389.7,99.6,389.5,99.4,389.4,99.3z"/>
<path class="st1" d="M966.1,85c0,4.8,0,9.7,0,14.6c-3.9,0-7.7,0-11.8,0c0-4.9,0-9.8,0-14.7c0.1-0.1,0.3-0.2,0.4-0.4
c3.6,0,7.3,0,10.9,0C965.9,84.8,966,84.9,966.1,85z"/>
<path class="st1" d="M1002.1,167.7c-0.1-0.5-0.1-1-0.2-1.5C1002,166.7,1002,167.2,1002.1,167.7z"/>
<path class="st1" d="M798.7,85c-0.1-0.1-0.3-0.2-0.4-0.3C798.4,84.8,798.6,84.9,798.7,85z"/>
<path class="st1" d="M966.1,85c-0.1-0.1-0.3-0.2-0.4-0.3C965.9,84.8,966,84.9,966.1,85z"/>
<path class="st1" d="M437,87.3c-0.1-0.1-0.3-0.3-0.4-0.4C436.8,87.1,436.9,87.2,437,87.3z"/>
<path class="st1" d="M990.2,89.2c-0.1,0.2-0.2,0.4-0.3,0.5C990,89.6,990.1,89.4,990.2,89.2z"/>
<path class="st1" d="M375.9,95.8c0.1-0.1,0.2-0.2,0.4-0.4C376.1,95.6,376,95.7,375.9,95.8z"/>
<path class="st1" d="M389.4,99.3c0.1,0.1,0.2,0.3,0.4,0.4C389.7,99.6,389.5,99.4,389.4,99.3z"/>
<path class="st1" d="M376.2,85c-0.1-0.1-0.3-0.2-0.4-0.3C376,84.8,376.1,84.9,376.2,85z"/>
<path class="st1" d="M425.1,113.1c-0.2,0.1-0.3,0.2-0.5,0.3C424.8,113.4,424.9,113.2,425.1,113.1z"/>
<path class="st1" d="M989.8,113.1c-0.1,0.1-0.2,0.2-0.3,0.4C989.6,113.4,989.7,113.3,989.8,113.1z"/>
<path class="st1" d="M1002.2,113.4c-0.1-0.1-0.2-0.2-0.3-0.3C1002,113.2,1002.1,113.3,1002.2,113.4z"/>
<path class="st1" d="M389.8,113.6c-0.1,0.2-0.2,0.4-0.3,0.5C389.5,114,389.6,113.8,389.8,113.6z"/>
<path class="st1" d="M451.8,114.2c-0.1-0.2-0.2-0.4-0.3-0.5C451.6,113.8,451.7,114,451.8,114.2z"/>
<path class="st1" d="M464.4,113.6c-0.1,0.2-0.3,0.3-0.4,0.5C464.1,114,464.3,113.8,464.4,113.6z"/>
<path class="st1" d="M954.8,84.6c-0.1,0.1-0.3,0.2-0.4,0.4C954.5,84.9,954.7,84.7,954.8,84.6z"/>
<path class="st1" d="M966.2,114.1c-0.1-0.2-0.3-0.3-0.4-0.5C965.9,113.8,966,114,966.2,114.1z"/>
<path class="st1" d="M977.3,113.6c-0.1,0.2-0.2,0.4-0.3,0.5C977,114,977.1,113.8,977.3,113.6z"/>
<path class="st1" d="M858.6,84.9c-0.2-0.1-0.4-0.2-0.5-0.3C858.3,84.7,858.4,84.8,858.6,84.9z"/>
<path class="st1" d="M401.2,85c-0.1-0.1-0.3-0.2-0.4-0.4C400.9,84.7,401,84.9,401.2,85z"/>
<path class="st1" d="M411.8,122.2c0.1,0.1,0.3,0.2,0.4,0.4C412.1,122.5,412,122.4,411.8,122.2z"/>
<path class="st1" d="M424.6,122.6c0.2,0.1,0.3,0.1,0.5,0.2C425,122.7,424.8,122.7,424.6,122.6z"/>
<path class="st1" d="M1001.8,122.9c0.1-0.1,0.2-0.2,0.4-0.3C1002,122.7,1001.9,122.8,1001.8,122.9z"/>
<path class="st1" d="M1016,122.6c0.1-0.1,0.3-0.2,0.4-0.4C1016.3,122.4,1016.1,122.5,1016,122.6z"/>
<path class="st1" d="M325.7,125.6c-0.1-0.2-0.1-0.3-0.2-0.5C325.6,125.2,325.6,125.4,325.7,125.6z"/>
<path class="st1" d="M325.6,137.1c0.1-0.1,0.2-0.2,0.3-0.4C325.7,136.9,325.6,137,325.6,137.1z"/>
<path class="st1" d="M508.5,139c0,0.2,0,0.4,0.1,0.6C508.5,139.3,508.5,139.2,508.5,139z"/>
<path class="st1" d="M923.3,139c0.1,0.1,0.1,0.2,0.2,0.3c0,0-0.1,0.1-0.2,0.1C923.3,139.3,923.3,139.2,923.3,139z"/>
<path class="st1" d="M475.8,141.4c-0.1-0.1-0.1-0.2-0.1-0.4c0,0,0.1-0.1,0.2-0.1C475.8,141.1,475.8,141.2,475.8,141.4z"/>
<path class="st1" d="M890.5,141.4c0-0.2,0-0.4,0-0.6C890.5,141.1,890.5,141.3,890.5,141.4z"/>
<path class="st1" d="M786,142.3c0,0.2-0.1,0.4-0.1,0.5C786,142.7,786,142.5,786,142.3z"/>
<path class="st1" d="M597.8,144.3c-0.1-0.2-0.2-0.4-0.4-0.5C597.5,143.9,597.7,144.1,597.8,144.3z"/>
<path class="st1" d="M597.4,149.9c0.1-0.1,0.2-0.2,0.4-0.4C597.6,149.6,597.5,149.8,597.4,149.9z"/>
<path class="st1" d="M1084.9,149.9c0.1-0.1,0.3-0.3,0.4-0.4C1085.2,149.7,1085,149.8,1084.9,149.9z"/>
<path class="st1" d="M846.7,84.6c-0.1,0.1-0.3,0.2-0.4,0.4C846.4,84.9,846.5,84.7,846.7,84.6z"/>
<path class="st1" d="M989.8,169.6c0,0.2,0.1,0.5,0.1,0.7C989.9,170,989.9,169.8,989.8,169.6z"/>
<path class="st1" d="M325.2,180.6c0.1-0.2,0.2-0.3,0.3-0.5C325.4,180.3,325.3,180.5,325.2,180.6z"/>
<path class="st1" d="M389.4,180.1c0.1,0.2,0.2,0.4,0.3,0.5C389.6,180.5,389.5,180.3,389.4,180.1z"/>
<path class="st1" d="M464,180.1c0.1,0.2,0.3,0.3,0.4,0.5C464.3,180.5,464.1,180.3,464,180.1z"/>
<path class="st1" d="M475.4,180.6c0.1-0.2,0.3-0.3,0.4-0.5C475.7,180.3,475.5,180.5,475.4,180.6z"/>
<path class="st1" d="M508.6,180.1c0.1,0.2,0.2,0.4,0.3,0.5C508.8,180.5,508.7,180.3,508.6,180.1z"/>
<path class="st1" d="M890.2,180.6c0.1-0.2,0.2-0.4,0.3-0.5C890.4,180.3,890.3,180.5,890.2,180.6z"/>
<path class="st1" d="M923.3,180.1c0.1,0.2,0.3,0.3,0.4,0.5C923.5,180.5,923.4,180.3,923.3,180.1z"/>
<path class="st1" d="M965.8,180.6c0.1-0.2,0.3-0.3,0.4-0.5C966,180.3,965.9,180.5,965.8,180.6z"/>
<path class="st1" d="M1160.5,84.9c-0.2-0.1-0.4-0.2-0.5-0.3C1160.1,84.7,1160.3,84.8,1160.5,84.9z"/>
<path class="st1" d="M1160.6,180.6c0-0.6,0-1.1,0-1.6c0-30.8,0-61.6,0-92.4c0-0.6-0.1-1.1-0.1-1.7c-0.2-0.1-0.4-0.2-0.5-0.3
c-3.7,0-7.4,0-11.4,0c0,12.5,0,24.8,0,36.9c-2.8-2.2-5.3-4.5-8.2-6.3c-4.9-3.1-10.4-3-16-2.9c-8.3,0.1-14.8,4.1-19.7,10.4
c-5,6.4-6.9,14.1-7.3,22.1c-0.3,6.3,0.5,12.5,2.6,18.5c2.8,7.7,7.3,13.9,15.3,17c2.4,0.9,4.9,1.6,7.4,2.4c2.7,0,5.4,0,8.1,0
c0.1-0.2,0.3-0.4,0.4-0.4c4.3-0.6,8.3-2,11.7-4.8c1.8-1.5,3.5-3.1,5.5-5c0,2.9,0,5.6,0,8.3C1152.7,180.6,1156.6,180.6,1160.6,180.6z
M1148.8,122.4L1148.8,122.4L1148.8,122.4L1148.8,122.4z M1130,172.1c-10.3-0.4-16.4-4.8-18.8-14.8c-0.8-3.4-1.2-6.9-1.8-10.4
c0.2-4.8,1.1-9.3,2.9-13.6c3.8-9.2,12.7-13.5,22.4-10.8c8,2.2,13.2,8.6,14.4,17c0.8,5.8,0.8,11.5-0.5,17.3
C1146.6,165.5,1138.8,172.4,1130,172.1z"/>
<polygon class="st1" points="1148.7,122.6 1148.7,122.4 1148.8,122.4 "/>
<path class="st1" d="M561,150c12.1,0,24.2-0.1,36.3-0.1c0.1-0.1,0.2-0.2,0.4-0.4c0-1.8,0-3.5,0-5.3c-0.1-0.2-0.2-0.4-0.4-0.5
c-0.2-2.2-0.1-4.4-0.6-6.6c-1.5-7.4-4.4-14-10.5-19c-5.6-4.6-12-6-19.1-6c-7.3-0.1-13.7,1.9-19.5,6.4c-5.6,4.3-8.7,10-10.6,16.5
c-2.2,7.4-2.1,15-0.5,22.5c2.5,12.1,9.4,20.2,21.2,23.9c1.6,0.5,3.4,0.7,5.1,1.1c2.9,0,5.7,0,8.6,0c1.6-0.3,3.3-0.6,4.9-1
c8.8-2.3,15.9-6.9,21-14.7c-2.9-2.1-5.8-4.2-8.7-6.2c-7.6,11.4-19.4,13.4-28.3,10c-8.3-3.1-13.2-13.2-12.5-20.6
C552.3,150,556.7,150,561,150z M564.7,121.8c14.7-1.5,19.7,8.3,20.7,18.5c-12.5,0-24.8,0-37.2,0C548.8,131,555.6,122.7,564.7,121.8z
"/>
<path class="st1" d="M1075.4,162.1c-2.3,3.7-5.6,6.2-9.4,7.9c-8.4,3.8-19.4,2.6-25.4-5.3c-3.3-4.3-5.1-9.2-4.9-14.8c1.2,0,2,0,2.9,0
c15.5,0,30.9,0,46.4-0.1c0.1-0.1,0.3-0.3,0.4-0.4c0.3-5.3,0.1-10.6-1.4-15.7c-2.4-8.3-6.9-15-14.9-19c-5.2-2.6-10.8-2.6-16.4-2.6
c-8.9,0.1-15.8,4.1-21.4,10.5c-5.7,6.6-7.9,14.8-8,23.4c-0.1,4.6,0.7,9.4,1.9,13.9c3,11.1,9.9,18.7,21.4,21.5
c1.5,0.4,2.9,0.7,4.4,1.1c3,0,6.1,0,9.1,0c1-0.3,1.9-0.8,3-0.9c9.6-1.6,16.8-6.8,22.4-14.8c-2.6-1.9-5.1-3.7-7.6-5.5
C1076.6,160.5,1076,161.2,1075.4,162.1z M1052.6,121.8c11.5-1,19.5,4.8,20.7,18.6c-12.5,0-24.8,0-37.1,0
C1036.4,131.1,1044.1,122.5,1052.6,121.8z"/>
</svg>
</template>

