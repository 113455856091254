<template>

select: {{ currentUser.Location }}

  <DxSelectBox
    :items="currentUser.Location"
    :input-attr="{ 'aria-label': 'Simple Product' }"
    display-expr="locationname"
    value-expr="locationid"
  />

<!-- <DxSelectBox
  :search-enabled="true"
  :data-source="products"
  :input-attr="{ 'aria-label': 'Product' }"
  :search-mode="searchModeOption"
  :search-expr="searchExprOption"
  :search-timeout="searchTimeoutOption"
  :min-search-length="minSearchLengthOption"
  :show-data-before-search="showDataBeforeSearchOption"
  display-expr="locationname"
  value-expr="locationid"
/> -->

<vue3-barcode :value="'7610055020750'" :format="'ean13'" :height="50" />

  <QRCodeVue3
    :width="200"
    :height="200"
    value="D567687"
    :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
    :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
    :dotsOptions="{
      type: 'square',
      color: '#000000',
      gradient: {
        type: 'linear',
        rotation: 0,
        colorStops: [
          { offset: 0, color: '#000000' },
          { offset: 1, color: '#000000' },
        ],
      },
    }"
    :backgroundOptions="{ color: '#ffffff' }"
    :cornersSquareOptions="{ type: 'square', color: '#000000' }"
    :cornersDotOptions="{ type: undefined, color: '#000000' }"
    fileExt="png"
    :download="false"
    myclass="my-qur"
    imgclass="img-qr"
    downloadButton="qr-download-button cx-button tiny"
    :downloadOptions="{ name: 'vqr', extension: 'png' }"
  />


  <QRCodeVue3
    :width="200"
    :height="200"
    value="D567687"
    :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
    :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
    :dotsOptions="{
      type: 'dots',
      color: '#26249a',
      gradient: {
        type: 'linear',
        rotation: 0,
        colorStops: [
          { offset: 0, color: '#26249a' },
          { offset: 1, color: '#26249a' },
        ],
      },
    }"
    :backgroundOptions="{ color: '#ffffff' }"
    :cornersSquareOptions="{ type: 'dot', color: '#000000' }"
    :cornersDotOptions="{ type: undefined, color: '#000000' }"
    fileExt="png"
    :download="true"
    myclass="my-qur"
    imgclass="img-qr"
    downloadButton="qr-download-button cx-button tiny"
    :downloadOptions="{ name: 'vqr', extension: 'png' }"
  />

</template>

<script>
import auth from "../auth";

import Vue3Barcode from 'vue3-barcode'
import QRCodeVue3 from "qrcode-vue3";
import DxSelectBox from 'devextreme-vue/select-box';

let currentUser;

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;
    //console.log(currentUser);
  } // e.data
}); //auth


export default {
  name: "VueBarcode",
  components: {
    Vue3Barcode,
    QRCodeVue3,
    DxSelectBox,

  },
  data() {
    return {
      currentUser,
    };
  },
  mounted() {
  },
  methods: {

  },

};

</script>


<style lang="scss">

.qr-download-button {
  margin-top: 15px;
}
</style>
