<template>

  <div class="hide" style="font-size: 10px; margin-bottom: 16px;">
    <p>id: {{ this.templateData.data.id }}</p>
    <p>Data: {{this.templateData.data}}</p>
  </div>

  <form 
    style="padding: 0;"
    :id="this.templateData.data.id"
    @submit="handleSubmit"
  >

    <DxForm 
      :id="this.templateData.data.id"
      :form-data="this.templateData.data"
      :read-only="false"
      @initialized="saveFormInstance"
      :show-colon-after-label="true"
      :show-validation-summary="true"
      validation-group="currentUserData"
    >
      <DxSimpleItem 
        data-field="id"
        help-text=""
        css-class="hide"
      >
      </DxSimpleItem>

      <DxGroupItem 
        caption=""
        :colCountByScreen="colCountByScreen"
      >

      <DxSimpleItem
        data-field="Subject"
        help-text=""
      >
        <DxLabel :text="currentUser.Translation.vueappTaskSubject+' [de]'"/>
      </DxSimpleItem>

      <DxSimpleItem
        data-field="Subject_en"
        help-text=""
      >
        <DxLabel :text="currentUser.Translation.vueappTaskSubject+' [en]'"/>
      </DxSimpleItem>

      <DxSimpleItem 
        data-field="Status"
        help-text=""
        :caption="currentUser.Translation.vueappTaskStatus"
        :placeholder="currentUser.Translation.vueappTaskStatus"
        :editor-options="statusEditorOptions"
        :validation-rules="validationRules.status"
        editor-type="dxSelectBox"
      >
        <DxLabel :text="currentUser.Translation.vueappTaskStatus"/>
        <DxRequiredRule :message="currentUser.Translation.vueappTaskStatusMessage"/>
        <DxPatternRule
          :pattern="measurementPattern"
          :message="currentUser.Translation.vueappTaskStatusPattern"
        />
      </DxSimpleItem>

      <DxSimpleItem 
        data-field="Priority"
        help-text=""
        :caption="currentUser.Translation.vueappTaskPriority"
        :placeholder="currentUser.Translation.vueappTaskPriority"
        :editor-options="priorityEditorOptions"
        :validation-rules="validationRules.priority"
        editor-type="dxSelectBox"
      >
        <DxLabel :text="currentUser.Translation.vueappTaskPriority"/>
        <DxRequiredRule :message="currentUser.Translation.vueappTaskPriorityMessage"/>
        <DxPatternRule
          :pattern="priorityPattern"
          :message="currentUser.Translation.vueappTaskPriorityPattern"
        />
      </DxSimpleItem>

      </DxGroupItem>

      <DxSimpleItem 
        data-field="Description"
        help-text=""
      >
        <DxLabel :text="currentUser.Translation.vueappTaskDescription"/>
      </DxSimpleItem>

      <DxButtonItem
        horizontal-alignment="left"
        :button-options="buttonOptions"
      />

    </DxForm>

  </form>

</template>
<script>

import DxForm, {
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  DxGroupItem,
  DxPatternRule,
  DxRequiredRule,
  //DxCompareRule,
  //DxRangeRule,
  //DxStringLengthRule,
  //DxEmailRule,
  //DxAsyncRule,
} from 'devextreme-vue/form';


import notify from 'devextreme/ui/notify';

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 3,
  lg: 4
}

let currentUser;

auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
  } // e.data
}); //auth

function handleErrors(response) {
   if (!response.ok) {
       throw Error(response.statusText);
   }
   return response;
 }

export default {
 name: 'measurementitem-template',

 props: {
  templateData: Object,
 },

 components: {
  DxForm,
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  DxGroupItem,
  DxRequiredRule,
  DxPatternRule,


  //DxFileUploader,
  //DxRadioGroup,
 },

 mounted() {


 },

 data() {
   return {
    apihost,

    title: currentUser.Translation.vueappNavMemberMeasurements,
    events: [],
    formInstance: null,
    currentUser,
    colCountByScreen,

    calendarOptions: { 
      displayFormat: "dd.MM.yyyy"
    },
    buttonOptions: {
      text: currentUser.Translation.vueappMeasurementFormSubmit,
      type: 'success',
      useSubmitBehavior: true,
    },
    dateBoxOptions: {
      invalidDateMessage:
        'The date must have the following format: MM/dd/yyyy',
    },
    checkBoxOptions: {
      text: 'I agree to the Terms and Conditions',
      value: false,
    },
    phoneEditorOptions: {
      mask: '+1 (X00) 000-0000',
      maskRules: {
        X: /[02-9]/,
      },
      maskInvalidMessage: 'The phone must have a correct USA phone format',
    },

    statusPattern: /^[^0-9]+$/,
    priorityPattern: /^[^0-9]+$/,
    measurementPattern: /^[^0-9]+$/,

    // phonePattern: /^[02-9]\d{9}$/,
    // maxDate: new Date().setFullYear(new Date().getFullYear() - 21),
    
    validationRules: {

      status: [
        { type: 'required', message: currentUser.Translation.vueappTaskStatusMessage },
      ],
      priority: [
        { type: 'required', message: currentUser.Translation.vueappTaskPriorityMessage },
      ],
    },
    statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
    priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },
    
   };

 },
 methods: {

  saveFormInstance(e) {
    this.formInstance = e.component;
  },

  handleSubmit(e) {
    e.preventDefault();
    const id = this.formInstance.getEditor('id').option('value');  
    const Subject = this.formInstance.getEditor('Subject').option('value');  
    const Subject_en = this.formInstance.getEditor('Subject_en').option('value');  
    const Description = this.formInstance.getEditor('Description').option('value');  
    const Status = this.formInstance.getEditor('Status').option('value');  
    const Priority = this.formInstance.getEditor('Priority').option('value');  

    let objString = '?id=' + id;

    if(Subject){
      objString = objString + '&Subject=' + Subject;
    } else {
      objString = objString + '&Subject=';
    }
    if(Subject_en){
      objString = objString + '&Subject_en=' + Subject_en;
    } else {
      objString = objString + '&Subject_en=';
    }
    if(Description){
      objString = objString + '&Description=' + Description;
    } else {
      objString = objString + '&Description=';
    }
    if(Status){
      objString = objString + '&Status=' + Status;
    } else {
      objString = objString + '&Status=';
    }
    if(Priority){
      objString = objString + '&Priority=' + Priority;
    } else {
      objString = objString + '&Priority=';
    }


    //console.log(objString);

    fetch(apihost+'/de/vue/measurement/getmeasurement/'+id+'/save'+objString)
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
      const data = JSON.parse(result);
      //console.log(data);
      this.fetchUser = data;
    })
    .catch(() => { throw 'Network error' });
  
    notify({
      message: currentUser.Translation.vueappMeasurementFormSubmitSuccess,
      position: {
        my: 'center top',
        at: 'center top',
      },
    }, 'success', 3000);

  },    

  },

};

</script>

<style>

</style>
