<template>

    <div class="dx-scheduler-appointment-title">
        <i v-if="templateModel.appointmentData.typeid == 1" class="fa-light fa-whistle"></i> 
        <i v-if="templateModel.appointmentData.typeid == 2" class="fa-light fa-badge-check"></i> 
        <i v-if="templateModel.appointmentData.typeid == 3" class="fa-light fa-grip-dots"></i> 
        {{ templateModel.appointmentData.text }}
    </div>
    <div class="dx-scheduler-appointment-content-details">
        <div class="dx-scheduler-appointment-content-date">
            <span class="hide">
            <!-- 10:00 - 11:00  -->
            {{ formatTime(templateModel.appointmentData.startDate) }}
            -
            {{ formatTime(templateModel.appointmentData.endDate) }}
            </span>
            <span v-if="templateModel.appointmentData.locationtext">
                {{ templateModel.appointmentData.locationtext }}
            </span>
            
        </div>
    </div>

</template>

<script>

export default {

    name: 'appointment-template',

    props: {
        templateModel: Object,
    },

    methods: {

        formatTime(dateString) {

            const date = new Date(dateString);

            // Get Hours part from dateString
            let hours = '0' + date.getHours();
            hours = hours.slice(-2);

            // Get Minutes part from dateString
            let minutes = '0' + date.getMinutes();
            minutes = minutes.slice(-2);
            
            let formattedDate = hours+':'+minutes;

            return formattedDate;

        },

    },


}

</script>

<style>

</style>
