<template>
  <header class="header-component">
    <dx-toolbar class="header-toolbar">
      <dx-item
        :visible="menuToggleEnabled"
        location="before"
        css-class="menu-button"
      >
        <template #default>
          <dx-button
            icon="menu"
            styling-mode="text"
            @click="toggleMenuFunc"
          />
        </template>
      </dx-item>

      <dx-item
        v-if="title"
        location="before"
        css-class="header-title dx-toolbar-label"
      >
        <div class="hide">{{ title }}</div>
      </dx-item>

      <dx-item
        location="after"
        locate-in-menu="auto"
        menu-item-template="menuUserItem"
      >
      <template #default>
          <div>
            <dx-button
              class="user-button authorization"
              :width="210"
              height="100%"
              styling-mode="text"
            >
              <user-panel :email="email" :menu-items="userMenuItems" menu-mode="context" />
            </dx-button>
          </div>
        </template>
      </dx-item>
      
      <template #menuUserItem>
        <user-panel
          :email="email"
          :menu-items="userMenuItems"
          menu-mode="list"
        />

      </template>
    </dx-toolbar>
  </header>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import auth from "../auth";
import { useRouter, useRoute } from 'vue-router';
import { ref } from 'vue';

import UserPanel from "./user-panel";

export default {
  props: {
    menuToggleEnabled: Boolean,
    title: String,
    toggleMenuFunc: Function,
    logOutFunc: Function
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const email = ref("");
    auth.getUser().then((e) => email.value = e.data.email);
    
    const userMenuItems = [
    {
        text: "Check-in",
        icon: "fa-light fa-right-from-bracket",
        onClick: onDashboardClick
      },
      {
        text: "Meine Daten",
        icon: "fa-light fa-user",
        onClick: onProfileClick
      },
      {
        text: "Training",
        icon: "fa-light fa-dumbbell",
        onClick: onTrainingplanClick
      },
      {
        text: "Verträge",
        icon: "fa-light fa-file-contract",
        onClick: onContractClick
      },
      {
        text: "Shop",
        icon: "fa-light fa-cart-shopping",
        onClick: onShopClick
      },
      {
        text: "Logout",
        icon: "fa-light fa-right-from-bracket",
        onClick: onLogoutClick
      },

      // {
      //   text: "Check-in",
      //   icon: "fa-light fa-right-to-bracket",
      //   onClick: onCheckinClick
      // },

      // {
      //   text: "Messwerte",
      //   icon: "fa-light fa-weight-scale",
      //   onClick: onMesswerteClick
      // },
      // {
      //   text: "Mehr",
      //   icon: "fa-light fa-grip-dots",
      //   onClick: onMoreClick
      // },
      
    ];
      
    function onDashboardClick() {
      router.push({
        path: "/dashboard",
        query: { redirect: route.path }
      });
    }

    function onProfileClick() {
      router.push({
        path: "/myprofile",
        query: { redirect: route.path }
      });
    }

    function onTrainingplanClick() {
      router.push({
        path: "/trainingplan",
        query: { redirect: route.path }
      });
    }

    function onContractClick() {
      router.push({
        path: "/contract",
        query: { redirect: route.path }
      });
    }

    function onShopClick() {
      router.push({
        path: "/shop",
        query: { redirect: route.path }
      });
    }

    function onLogoutClick() {
      auth.logOut();
      router.push({
        path: "/login-form",
        query: { redirect: route.path }
      });
    }

    // function onCheckinClick() {
    //   router.push({
    //     path: "/checkin",
    //     query: { redirect: route.path }
    //   });
    // }

    // function onMesswerteClick() {
    //   router.push({
    //     path: "/measurement",
    //     query: { redirect: route.path }
    //   });
    // }

    // function onMoreClick() {
    //   router.push({
    //     path: "/more",
    //     query: { redirect: route.path }
    //   });
    // }

    return {
      email,
      userMenuItems
    };
  },
  components: {
    DxButton,
    DxToolbar,
    DxItem,
    UserPanel
  }
};
</script>

<style lang="scss">

</style>
