<template>
<svg version="1.1" id="neogate-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 218.9 36.7" style="enable-background:new 0 0 218.9 36.7;" xml:space="preserve">
<path class="st0" d="M180.1,21.3c0,1.5,0,2.9,0,4.4c0,0,0,0.1,0,0.1c0,1,0,2,0,3.1c0,0.1,0,0.2,0,0.2c-0.1,0-0.2,0-0.2,0
c-2,0-4,0-6.1,0c0-0.2,0-0.4,0-0.5c0-5.6,0-11.3,0-16.9c0-0.2,0-0.3-0.3-0.3c-0.9,0-1.9,0-2.8,0c-0.2-0.4,0.1-0.9-0.2-1.3
c0-0.2,0.1-0.3,0.1-0.4c0-1.1,0-2.1,0-3.2c0.2,0,0.4,0.2,0.5-0.1c0.7,0,1.5,0,2.2,0c0.3,0,0.4-0.1,0.4-0.4c0-1.9,0-3.9,0-5.8
c0,0,0-0.1,0-0.1c2.1,0,4.2,0,6.3,0c0,0.4,0,0.7,0,1.1c0,1.6,0,3.3,0,4.9c0,0.3,0.1,0.3,0.3,0.3c2.1,0,4.2,0,6.3,0
c0.5,0,0.5,0,0.5,0.5c0,0.9,0,1.8,0,2.6c0,0.5,0,1.1,0,1.6c0,0.2,0,0.2-0.2,0.2c-2.2,0-4.4,0-6.6,0c-0.1,0-0.2,0-0.3,0.1
c0,0,0,0.1,0,0.1c0,1.1,0,2.2,0,3.3c0,1.5,0,3,0,4.5c0,0,0,0.1,0,0.1C180.1,19.9,180.1,20.6,180.1,21.3
C180.1,21.2,180.1,21.3,180.1,21.3z"/>
<path class="st0" d="M87.8,29c-0.8,0-1.5,0-2.3,0c0-0.7,0-1.4,0-2.1c0,0,0,0,0,0c0-0.9,0-1.8,0-2.6c0-0.1,0-0.2,0-0.3
c0-0.1,0-0.2,0-0.2c0-0.1,0.1-0.1,0.1-0.2c0.5,0,1,0,1.5,0c1-0.1,1.9-0.3,2.7-0.9c0.5-0.3,0.7-0.8,0.9-1.4c0.2-0.6,0.3-1.2,0.2-1.9
c0-0.1,0.1-0.1,0.1-0.2c0-1.4,0-2.9-0.1-4.3c0-0.5-0.2-1.1-0.4-1.6c-0.4-0.8-1.1-1.2-2-1.4c-0.9-0.2-1.8-0.3-2.7-0.2
c-0.2,0-0.3-0.1-0.3-0.3c0-0.6,0-1.2,0-1.7c0,0,0,0,0.1,0c0,0-0.1,0-0.1,0c0-0.3-0.1-0.5,0-0.8c0.1-0.1-0.1-0.3-0.1-0.4
c0-0.6,0-1.2,0-1.8c0-0.2,0.1-0.2,0.2-0.2c1,0,2,0,2.9,0.1c1.1,0.1,2.2,0.2,3.3,0.5c1,0.3,1.9,0.7,2.7,1.3c0.4,0.3,0.7,0.6,0.9,0.9
c0.8,1,1.2,2.1,1.4,3.4c0.1,0.7,0.2,1.4,0.2,2.1c0,1.6,0.1,3.3,0.1,4.9c0,0.4,0,0.8,0,1.2c-0.1,1.5-0.4,3.1-1.1,4.4
c-0.2,0.5-0.5,0.9-0.8,1.3c-0.2,0.2-0.4,0.5-0.7,0.6c-0.1,0-0.1,0.2-0.2,0.3c-0.4,0.2-0.8,0.5-1.2,0.6c-0.3,0.1-0.6,0.3-0.9,0.3
c-0.3,0.1-0.6,0.2-1,0.2c-0.8,0.1-1.5,0.2-2.3,0.3c-0.3,0-0.5,0-0.8,0C88.1,29,88,29,87.8,29z"/>
<path class="st0" d="M79.7,29c-0.1,0-0.2,0.1-0.2,0.1c-1,0-2,0-2.9,0c-0.7,0-1.4-0.1-2.1-0.3c-0.3-0.1-0.5-0.1-0.8-0.1
c-1-0.3-2.1-0.6-3-1.3c-0.4-0.3-0.8-0.7-1-1.1c-0.3-0.8-0.9-1.4-1-2.3c0-0.1-0.1-0.2-0.1-0.4c-0.1-0.7-0.3-1.3-0.4-2
c-0.3-2-0.1-3.9-0.1-5.9c0-1.1,0.1-2.1,0.3-3.2c0.4-2.1,1.4-3.9,3.5-4.9c0.8-0.4,1.6-0.6,2.4-0.8c0.5-0.1,1.1-0.2,1.6-0.3
c0.2,0,0.4,0,0.6,0c1.1,0,2.1,0,3.3,0c0,1.6,0,3.3,0,5c0,0,0,0-0.1,0c0,0,0,0,0.1,0c0,0.1,0,0.1,0,0.2c-0.5,0-1,0-1.5,0.1
c-0.5,0.1-1,0.1-1.6,0.2c-0.4,0.1-0.8,0.2-1.1,0.4c-0.4,0.3-0.7,0.8-0.9,1.2c-0.5,1.1-0.4,2.3-0.4,3.4c0,0.9,0,1.9,0,2.8
c0,0.6,0.1,1.2,0.3,1.8c0.3,0.8,0.9,1.4,1.7,1.6c0.4,0.1,0.9,0.2,1.3,0.3c0.7,0.2,1.4,0,2.1,0.1C79.7,25.4,79.7,27.2,79.7,29
C79.7,29,79.7,29,79.7,29z"/>
<path class="st0" d="M82.5,20.3c-0.7,0-1.4,0.1-2.2,0c-1.1-0.1-1.5-0.6-1.6-1.6c-0.1-0.8,0-1.7,0-2.5c0-0.6,0.5-1.2,1.1-1.3
c0.2,0,0.4-0.1,0.7-0.1c1.5,0,2.9,0,4.4,0c0.3,0,0.6,0.1,0.9,0.2c0.5,0.2,0.7,0.7,0.7,1.2c0,0.9,0,1.7,0,2.6c0,0.8-0.7,1.3-1.4,1.3
C84.2,20.3,83.4,20.3,82.5,20.3C82.5,20.3,82.5,20.3,82.5,20.3z"/>
<path class="st0" d="M18.7,19.1c-0.7,0.1-1.2-0.2-1.7-0.7c-0.5-0.5-0.7-1.1-0.8-1.8c0-0.8,0.3-1.4,0.9-1.9c0.3-0.2,0.6-0.5,0.9-0.5
c0.9,0,1.8,0,2.5,0.7c0.4,0.5,0.6,1,0.6,1.6C21.2,18.1,20,19.2,18.7,19.1z"/>
<path class="st0" d="M132.1,14.9c0-0.9-0.2-1.8-0.4-2.7c-0.3-1.1-0.8-2-1.5-2.7c-0.6-0.6-1.3-1.1-2.1-1.3c-0.6-0.2-1.3-0.3-1.9-0.5
c-0.9-0.2-1.7-0.3-2.6-0.3c-4,0-8.1,0-12.1,0c-0.1,0.1-0.1,0.1-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.6,0-1.3,0.1-1.9,0.1
c-0.1,0-0.2,0.1-0.4,0.1c-1.2,0.3-2.4,0.5-3.4,1.2c-0.7,0.5-1.2,1.1-1.6,1.9c-0.2,0.4-0.3,0.8-0.4,1.1c-0.1,0.4-0.3,0.7-0.3,1.1
c-0.1,0.5-0.2,1-0.2,1.4c0,1.9,0,3.7,0,5.6c0,0.8,0,1.6,0.2,2.3c0.2,0.8,0.3,1.6,1,2.2c0,0,0.1,0.1,0.1,0.2c0.1,0.4,0.4,0.6,0.7,0.8
c0.2,0.2,0.5,0.4,0.6,0.6c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.6,0.3c0.5,0.2,1.1,0.4,1.6,0.5c0.5,0.2,1-0.1,1.5,0.2
c0,0,0.1,0,0.1,0c0.5,0,1.1,0.1,1.6,0.1c0.1,0,0.2,0,0.2,0c0.4,0,0.8,0,1.2,0c0.3,0,0.6,0,0.9,0c4.3,0,8.6,0,12.9,0
c0,0.3,0,0.5,0,0.7c0,0.7,0,1.3-0.1,1.9c-0.1,0.5-0.3,1-0.8,1.3c-0.3,0.2-0.8,0.3-1.2,0.3c-1.9,0-3.7,0-5.6,0c-0.1,0-0.2,0-0.3,0
c-0.4,0-0.8,0-1.2,0c0,0-0.1,0-0.1,0c-0.6,0-1.2,0-1.8,0c-0.1,0-0.1,0.1-0.2,0.1c0,1.5,0,3,0,4.5c0,0.3,0.1,0.3,0.3,0.3
c1.9,0,3.9,0,5.8,0c0.1,0,0.2,0,0.3,0c1.6-0.1,3.2,0.1,4.8-0.1c0.4,0,0.9-0.1,1.3-0.2c0.4-0.1,0.8-0.2,1.2-0.4
c1.5-0.6,2.5-1.6,3-3.2c0.2-0.6,0.3-1.3,0.4-2c0.1-0.8,0.1-1.6,0.1-2.4c0-3.9,0-7.9,0-11.8C132.2,16,132.2,15.5,132.1,14.9z
M126.2,18.6C126.2,18.6,126.2,18.6,126.2,18.6c0,1.3,0,2.5,0,3.7c-0.1,0-0.2,0-0.2,0c-4.9,0-9.8,0-14.7,0c-0.1,0-0.3,0-0.4-0.1
c-0.5-0.1-1-0.2-1.4-0.5c-0.4-0.3-0.7-0.7-0.7-1.2c-0.1-0.6-0.1-1.2-0.1-1.8c0-1,0-1.9,0-2.9c0-0.8,0.1-1.6,0.6-2.3c0,0,0-0.1,0-0.1
c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.5-0.2,1-0.3,1.5-0.4c3.4-0.2,6.8,0,10.2-0.1c0.9,0,1.8,0.1,2.7,0.3
c0.8,0.1,1.5,1.1,1.7,2C126.3,16,126.2,17.3,126.2,18.6z"/>
<path class="st0" d="M63.1,12.7c0-0.2,0-0.4,0-0.5c-0.1-0.4-0.1-0.8-0.4-1.1c-0.2-0.2-0.2-0.6-0.3-0.9c-0.3-0.6-0.8-1-1.3-1.4
c-0.7-0.6-1.6-0.9-2.5-1.1c-1-0.2-2.1-0.4-3.1-0.4c-4.2,0-8.5,0-12.7,0c-0.2,0-0.3-0.1-0.5,0c-0.3,0-0.5,0.1-0.8,0.1
c-0.8,0-1.6,0.2-2.4,0.3c-0.8,0.2-1.6,0.4-2.3,0.8c-1.2,0.7-2,1.7-2.5,3c-0.5,1.3-0.6,2.6-0.6,4c0,2.5-0.1,4.9,0.1,7.4
c0.1,0.5,0.2,1.1,0.3,1.6c0.2,0.6,0.3,1.2,0.6,1.8C35,27,35.5,27.5,36,28c0.5,0.4,1,0.7,1.5,0.9c0.4,0.2,0.9,0.3,1.3,0.4
c0.4,0.1,0.9,0.3,1.3,0.4c1.1,0.1,2.2,0.2,3.2,0.2c2.4,0,4.9,0,7.3,0c0.1,0,0.2,0,0.3,0c0-0.3,0-0.7,0-1c0-0.1,0-0.3,0-0.4
c0-1.2,0-2.4,0-3.5c0,0-0.1,0-0.1,0c-2.5,0-5,0-7.5,0c-0.5,0-1,0-1.5-0.1c-0.5-0.1-0.9-0.2-1.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.6
c-0.2-0.3-0.3-0.6-0.3-1c0-0.7,0-1.3,0-2c0,0,0,0,0.1,0c3.9,0,7.7,0,11.6,0c3.8,0,7.5,0,11.3,0c0,0,0.1-0.1,0.1-0.1
c0-0.1,0.1-0.3,0.1-0.4c0-0.3,0-0.6,0-0.9C63.1,17.1,63.1,14.9,63.1,12.7z M56.9,16.8c-5.6,0-11.3,0-16.9,0c0-0.7,0-1.4,0-2.1
c0-0.3,0.1-0.7,0.2-1c0.2-0.9,0.9-1.4,1.7-1.5c1.4-0.2,2.8-0.2,4.2-0.2c2.7,0,5.4,0,8.1,0c0.5,0,1,0,1.5,0.1
c0.8,0.2,1.2,0.6,1.2,1.4C56.9,14.6,56.9,15.7,56.9,16.8z"/>
<path class="st0" d="M167.1,16.1c0-0.5,0-0.9-0.1-1.4c-0.1-0.7-0.1-1.4-0.3-2.1c-0.2-0.7-0.4-1.4-0.9-2c-0.1-0.2-0.3-0.4-0.4-0.6
c-0.3-0.4-0.8-0.7-1.1-1.1c-0.3-0.3-0.8-0.4-1.2-0.6c-0.8-0.3-1.5-0.5-2.4-0.7c-0.3-0.1-0.6-0.1-0.9-0.1c-0.4,0-0.8-0.1-1.2-0.1
c-0.1,0-0.2,0-0.3-0.1c0,0,0,0-0.1,0.1c-0.1,0-0.3,0-0.4,0c-2.3,0-4.6,0-7,0c0,0,0,0-0.1,0.1c0,1.2,0,2.4,0,3.6c0,0.1,0,0.1,0,0.2
c0,0.2,0,0.4,0.1,0.6c0,0,0.1,0,0.1,0c1.9,0,3.7,0,5.6,0c0.9,0,1.8,0,2.6,0.4c0.7,0.3,1.2,0.7,1.4,1.5c0.2,0.7,0.2,1.5,0.2,2.2
c0,0,0,0-0.1,0c-0.9,0-1.9,0-2.8,0c-5.1,0-10.3,0-15.4,0.1c-1.1,0-2.2,0.2-3.3,0.7c-0.5,0.2-1,0.4-1.3,0.8c-0.9,0.8-1.4,1.8-1.5,3
c-0.1,1.3-0.1,2.7-0.1,4c0,0.2,0,0.5,0.1,0.7c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.2,0.1,0.3c0.2,1,0.6,1.8,1.3,2.5
c0.3,0.3,0.7,0.6,1.1,0.8c0.4,0.2,0.8,0.4,1.2,0.5c1,0.3,2.1,0.5,3.2,0.5c3.5,0,7,0,10.5,0c1.7,0,3.3,0,5-0.1c1,0,2-0.1,2.9-0.4
c0.4-0.1,0.9-0.1,1.1-0.5c0,0,0.1,0,0.1,0c0.3-0.1,0.7-0.2,0.9-0.3c0.6-0.3,1.1-0.8,1.6-1.3c0.5-0.5,0.7-1.1,0.9-1.7
c0.2-0.7,0.4-1.5,0.5-2.2c0.1-0.6,0.1-1.3,0.1-2c0-1.6,0-3.3,0-4.9C167.1,16.4,167.1,16.3,167.1,16.1z M160.9,19.8
c0,0.8,0,1.7,0,2.5c0,0.9-0.2,1.7-0.9,2.3c-0.4,0.3-0.8,0.5-1.2,0.6c-0.5,0.1-1.1,0.2-1.6,0.2c-3.9,0-7.8,0-11.8,0
c-0.6,0-1.1,0-1.7-0.2c-0.7-0.4-1.1-0.9-1.1-1.7c0-0.8,0-1.5,0-2.3c0.1-1.2,0.9-1.6,1.8-1.7c1.1,0,2.2,0,3.2,0c0.1,0,0.2,0,0.3,0
c0.2-0.2,0.5-0.2,0.7,0c1.9,0,3.8,0,5.7,0c0.1-0.1,0.3-0.1,0.5,0c0.8,0,1.6,0,2.3,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0.1,0.1,0.1
c0.5,0,1,0,1.5,0c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0,0.2,0,0.4,0c0.1-0.2,0.2-0.2,0.4,0c0.3,0,0.6,0,0.9,0c0,0,0.1-0.1,0.1-0.1
c0,0,0.1,0.1,0.1,0.1c0.1,0,0.2,0,0.3,0.1C160.9,19.7,160.9,19.7,160.9,19.8C160.9,19.7,160.9,19.8,160.9,19.8z"/>
<path class="st0" d="M218.6,12.8c-0.2-0.9-0.4-1.7-0.9-2.5c-0.6-0.8-1.2-1.6-2.1-2.1c-0.4-0.2-0.8-0.5-1.3-0.7
c-0.7-0.3-1.5-0.4-2.2-0.4c-4.1,0-8.2,0-12.3,0c-1.5,0-2.9,0-4.4,0.3c-1.2,0.2-2.3,0.5-3.3,1.1c-0.6,0.4-1.2,0.9-1.6,1.6
c-0.4,0.7-0.7,1.4-0.9,2.2c-0.1,0.7-0.3,1.4-0.3,2.1c0,2.1,0,4.2,0,6.2c0,0.2-0.1,0.4,0,0.6c0,0.5,0.1,1.1,0.1,1.6
c0.1,0.5,0.2,1.1,0.3,1.6c0.2,0.6,0.4,1.2,0.7,1.8c0.2,0.4,0.5,0.9,0.8,1.3c0.5,0.6,1.1,0.9,1.8,1.2c0.2,0.1,0.4,0.1,0.6,0.2
c0.4,0.2,0.8,0.3,1.2,0.5c0.3,0.1,0.7,0.2,1,0.2c0.8,0.1,1.6,0.2,2.3,0.3c2.6,0,5.2,0,7.8,0c0.2,0,0.4,0,0.7-0.1c0,0,0,0,0,0
c0-0.9,0-1.8,0-2.7c-0.1-0.1-0.1-0.2,0-0.3c0-0.1,0-0.3,0-0.4c0,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2
c0,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.1-0.1c0-0.3,0-0.6,0-1c-0.3,0-0.6,0-0.9,0c-2.6,0-5.1,0-7.7,0c-0.5,0-1.1-0.2-1.5-0.5
c-0.3-0.2-0.6-0.5-0.7-1c-0.2-0.9-0.2-1.7-0.2-2.6c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.5,0,0.7,0c5.2,0,10.4,0,15.6,0
c0.4,0,0.8,0,1.1-0.1c0.4-0.1,0.9-0.3,1.3-0.4c0.3-0.1,0.7-0.2,0.8-0.5c0,0,0.1,0,0.1,0c0.1-0.1,0.2-0.1,0.3-0.2
c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.2,0.3-0.3C218.3,17.4,219,15.3,218.6,12.8z M195.5,16.8c0-0.5,0-1,0-1.5c0-0.6,0-1.2,0.3-1.8
c0.3-0.7,0.8-1.1,1.4-1.3c0.8-0.2,1.7-0.2,2.6-0.2c2.1,0,4.3,0,6.4,0c1.1,0,2.1,0,3.2,0c0.6,0,1.3,0.1,1.9,0.2
c0.5,0.1,1.1,0.4,1.1,1.3c0,1.1,0,2.2,0,3.3C206.9,16.8,201.2,16.8,195.5,16.8z"/>
<path class="st0" d="M29.1,20c0-1.7-0.1-3.4-0.1-5.2c0-0.9-0.2-1.7-0.5-2.6c-0.4-1.5-1.3-2.6-2.5-3.5c-1-0.6-2.1-1-3.2-1.2
c-0.7-0.1-1.4-0.3-2.2-0.3c-0.5,0-0.9-0.1-1.4-0.1c-1.9,0-3.8,0-5.7,0c-1.8,0-3.5,0.1-5.3,0.1c-1.1,0-2.1,0.2-3.1,0.5
C3.8,8.1,2.8,8.7,1.9,9.6c-0.4,0.5-0.7,1-1.1,1.6c-0.4,0.7-0.5,1.5-0.7,2.3c-0.1,0.7-0.2,1.3-0.2,2c0,2.7,0,5.4,0,8.2
c0,2.1,0,4.2,0,6.3c2.1,0,4.1,0,6.2,0c0-0.1,0-0.2,0-0.3c0-4.4,0-8.7,0-13.1c0-0.4-0.1-0.9,0.1-1.3c0.2-0.3,0.2-0.6,0.3-0.9
c0.2-0.3,0.4-0.6,0.6-0.8c0.2-0.3,0.5-0.5,0.9-0.6c0.9-0.3,1.9-0.4,2.8-0.4c2.9,0,5.8,0,8.7,0.1c0.6,0,1.2,0.1,1.8,0.4
c0.5,0.2,0.9,0.7,1.2,1.3c0.4,0.8,0.4,1.6,0.4,2.4c0,3.5,0,7.1,0,10.6c0,0.9,0,1.7,0,2.6c2.1,0,4.2,0,6.3,0c0-0.1,0-0.2,0-0.3
C29.1,26.5,29.1,23.2,29.1,20z M24.6,16.8c-0.1-0.3-0.1-0.7-0.2-1.1c0-0.3-0.1-0.5-0.3-0.8c-0.2-0.2-0.1-0.5-0.2-0.7
c-0.5-1.1-1.4-1.8-2.5-2.3c-0.6-0.3-1.3-0.4-2-0.5c-0.2,0-0.2-0.1-0.2-0.2c0-0.3,0-0.5,0.1-0.8c3.6,0,6.4,3.1,6.2,6.3
C25.2,16.8,24.9,16.8,24.6,16.8z M27.8,16.8c-0.4,0-0.7,0-1.1,0c-0.3,0-0.3-0.2-0.3-0.4c-0.1-1.3-0.4-2.4-1.1-3.5
c-0.3-0.5-0.7-0.9-1.1-1.3c-1-1-2.2-1.6-3.6-1.9c-0.4-0.1-0.9-0.1-1.3-0.2c0-0.4,0-0.9,0-1.3c0-0.2,0.2-0.2,0.3-0.2
c0.1,0,0.1,0,0.2,0c0.5,0.1,1,0.1,1.6,0.2c0.1,0,0.1,0,0.2,0.1c0.1,0,0.2,0,0.2,0c0.6,0,1,0.3,1.5,0.6c1.3,0.7,2.4,1.6,3.2,2.8
c0.6,0.9,1.1,1.8,1.3,2.8c0.2,0.7,0.2,1.3,0.3,2C28,16.6,27.9,16.8,27.8,16.8z"/>
</svg>
</template>

