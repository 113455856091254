<template>
  <div class="content-block">

<h2 class="main-title">{{ msg }}</h2>

<div class="-dx-card -has-paddings">

  <ul id="accordion" class="accordion" data-accordion>
      <li class="accordion-item is-active" data-accordion-item>
        <a href="#" class="accordion-title">Accordion 1</a>
        <div class="accordion-content" data-tab-content>
          If you init Foundation in the component, this will work fine.
        </div>
      </li>
      <li class="accordion-item" data-accordion-item>
        <a href="#" class="accordion-title">Accordion 2</a>
        <div class="accordion-content" data-tab-content>
          I need to be clicked, in order to show up.
        </div>
      </li>
    </ul>

  </div><!-- dx-card -->

<div style="height: 10px;"></div>
<p style="margin: 15px 5px; font-size: 11px;"><a href="https://get.foundation/sites/docs/accordion.html" target="_blank" style="color: grey;"><i class="fa-solid fa-arrow-up-right-from-square"></i> https://get.foundation/sites/docs/accordion.html</a></p>

</div><!-- content-block -->

</template>

<script>
 
import $ from 'jquery';
import Foundation from 'foundation-sites';

export default {
  name: 'dropdownmenu-example',
  mounted() {
    this.accordion = new Foundation.Accordion($('#accordion'), {
    // These options can be declarative using the data attributes
    slideSpeed: 500,
    multiExpand: true,
    allowAllClosed: true,
  })
  },
  data() {
    return {
      msg: 'Accordion',
    };
  },
  methods: {

  },
  unmounted() {

  },
};
</script>

<style lang="scss">
</style>
