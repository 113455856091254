<template>
  <div class="content-block">

    <h2 class="main-title">{{ msg }}</h2>

    <div class="dx-card has-paddings">

      <p>
        The
        <span
          data-tooltip
          aria-haspopup="true"
          class="has-tip"
          tabindex="1"
          title="Fancy word for a beetle."
          >scarabaeus</span
        >
        hung quite clear of any branches, and, if allowed to fall, would have fallen at our feet.
        Legrand immediately took the scythe, and cleared with it a circular space, three or four
        yards in diameter, just beneath the insect, and, having accomplished this, ordered Jupiter
        to let go the string and come down from the tree.
      </p>

    </div><!-- dx-card -->

    <div style="height: 10px;"></div>
    <p style="margin: 15px 5px; font-size: 11px;"><a href="https://get.foundation/sites/docs/tooltip.html" target="_blank" style="color: grey;"><i class="fa-solid fa-arrow-up-right-from-square"></i> https://get.foundation/sites/docs/tooltip.html</a></p>

  </div><!-- content-block -->

</template>

<script>
 
import $ from 'jquery';
import Foundation from 'foundation-sites';

export default {
  name: 'tooltip-example',
  mounted() {
    this.tooltip = new Foundation.Tooltip($('.has-tip'), {
    // These options can be declarative using the data attributes
    showOn: 'all'
  })
  },
  data() {
    return {
      msg: 'Tooltip',
    };
  },
  methods: {

  },
  unmounted() {

  },
};
</script>

<style lang="scss">
</style>
