<template>

  <!-- mainhide -->
  <div class="hide" style="font-size: 10px; padding: 10px;">
    <p>navStatus: {{ navStatus }}</p>
    <p>navCurrentpage: {{ navCurrentpage }}</p>
    <p>navPrevpage: {{ navPrevpage }}</p>
    <p>navPrevTemplate: {{ navPrevTemplate }}</p>
    <p>navNextpage: {{ navNextpage }}</p>
    <p>navNextTemplate: {{ navNextTemplate }}</p>

  </div>

  <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block">
    <div class="grid-x grid-margin-x">
      <div class="large-auto cell">
        <h2 v-if="navStatus == 'open'"><i class="dx-icon nav-icon fa-light fa-file-contract"></i> fällige {{ title }}</h2>
        <h2 v-if="navStatus == 'active'"><i class="dx-icon nav-icon fa-light fa-file-contract"></i> aktive {{ title }}</h2>
        <h2 v-if="navStatus == 'inactive'"><i class="dx-icon nav-icon fa-light fa-file-contract"></i> inaktive {{ title }}</h2>
        <h2 v-if="navStatus == 'signature'"><i class="dx-icon nav-icon fa-light fa-file-contract"></i> ohne Unterschrift</h2>
        <h2 v-if="navStatus == 'invoice'"><i class="dx-icon nav-icon fa-light fa-file-contract"></i> ohne Rechnung</h2>
      </div>

      <div class="shrink cell">
        <div class="grid-x">

          <div class="shrink cell" v-if="navStatus == 'open'">
            <a :href="$router.resolve({name: 'sap-contractlist'}).href+'?page=0&status=open'" class="cx-button tiny disabled" style="margin-right: 10px; opacity: 0.3;"><i class="fa-light fa-file-contract"></i> fällig</a>
            <a :href="$router.resolve({name: 'sap-contractlist-active'}).href+'?page=0&status=active'" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-contract"></i> aktiv</a>
            <a :href="$router.resolve({name: 'sap-contractlist-inactive'}).href+'?page=0&status=inactive'" class="cx-button tiny" style="margin-right: 10px;"><i class="fa-light fa-file-contract"></i> inaktiv</a>
          </div>

          <div class="shrink cell" v-if="navStatus == 'active'">
            <a :href="$router.resolve({name: 'sap-contractlist'}).href+'?page=0&status=open'" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-contract"></i> fällig</a>
            <a :href="$router.resolve({name: 'sap-contractlist-active'}).href+'?page=0&status=active'" class="cx-button tiny disabled" style="margin-right: 10px; opacity: 0.3;"><i class="fa-light fa-file-contract"></i> aktiv</a>
            <a :href="$router.resolve({name: 'sap-contractlist-inactive'}).href+'?page=0&status=inactive'" class="cx-button tiny" style="margin-right: 10px;"><i class="fa-light fa-file-contract"></i> inaktiv</a>
          </div>

          <div class="shrink cell" v-if="navStatus == 'inactive'">
            <a :href="$router.resolve({name: 'sap-contractlist'}).href+'?page=0&status=open'" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-contract"></i> fällig</a>
            <a :href="$router.resolve({name: 'sap-contractlist-active'}).href+'?page=0&status=active'" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-contract"></i> aktiv</a>
            <a :href="$router.resolve({name: 'sap-contractlist-inactive'}).href+'?page=0&status=inactive'" class="cx-button tiny disabled" style="margin-right: 10px; opacity: 0.3;"><i class="fa-light fa-file-contract"></i> inaktiv</a>
          </div>

          <div class="shrink cell" v-if="navStatus == 'signature'">
            <a :href="$router.resolve({name: 'sap-contractlist'}).href+'?page=0&status=open'" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-contract"></i> fällig</a>
            <a :href="$router.resolve({name: 'sap-contractlist-active'}).href+'?page=0&status=active'" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-contract"></i> aktiv</a>
            <a :href="$router.resolve({name: 'sap-contractlist-inactive'}).href+'?page=0&status=inactive'" class="cx-button tiny disabled" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-contract"></i> inaktiv</a>
          </div>

          <div class="shrink cell" v-if="navStatus == 'invoice'">
            <a :href="$router.resolve({name: 'sap-contractlist'}).href+'?page=0&status=open'" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-contract"></i> fällig</a>
            <a :href="$router.resolve({name: 'sap-contractlist-active'}).href+'?page=0&status=active'" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-contract"></i> aktiv</a>
            <a :href="$router.resolve({name: 'sap-contractlist-inactive'}).href+'?page=0&status=inactive'" class="cx-button tiny disabled" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-contract"></i> inaktiv</a>
          </div>

          <div class="shrink cell">
            <!-- prev -->
            <template v-if="this.navPrevpage > 0">
              <a :href="$router.resolve({name: this.navPrevTemplate}).href+'?page='+this.navPrevpage+'&status='+navStatus" class="cx-button tiny" style="margin-right: 10px;">
                <i class="fa-solid fa-backward"></i>
              </a>
            </template>
            <template v-else>
              <a v-if="navStatus == 'open'" :href="$router.resolve({name: 'sap-contractlist'}).href" class="cx-button tiny" style="margin-right: 10px;">
                <i class="fa-solid fa-backward"></i>
              </a>
              <a v-if="navStatus == 'active'" :href="$router.resolve({name: 'sap-contractlist-active'}).href" class="cx-button tiny" style="margin-right: 10px;">
                <i class="fa-solid fa-backward"></i>
              </a>
              <a v-if="navStatus == 'inactive'" :href="$router.resolve({name: 'sap-contractlist-inactive'}).href" class="cx-button tiny" style="margin-right: 10px;">
                <i class="fa-solid fa-backward"></i>
              </a>
              <a v-if="navStatus == 'signature'" :href="$router.resolve({name: 'sap-contractlist-signature'}).href" class="cx-button tiny" style="margin-right: 10px;">
                <i class="fa-solid fa-backward"></i>
              </a>
              <a v-if="navStatus == 'invoice'" :href="$router.resolve({name: 'sap-contractlist-invoice'}).href" class="cx-button tiny" style="margin-right: 10px;">
                <i class="fa-solid fa-backward"></i>
              </a>
            </template>
            <!-- /prev -->

            <!-- next -->
            <span style="margin-right: 10px; font-size: 12px;">Seite {{ this.navNextpage }}</span>
            <a :href="$router.resolve({name: this.navNextTemplate}).href+'?page='+this.navNextpage+'&status='+navStatus" class="cx-button tiny" style="margin-right: 10px;"><i class="fa-solid fa-forward"></i></a>
            <!-- /next -->

          </div><!-- shrink cell -->
          
        </div><!-- grid-x sub-->

        <div class="grid-x grid-margin-x -hide" style="margin-top: 10px;">

          <div class="shrink cell" v-if="navStatus == 'active'">
            <a :href="$router.resolve({name: 'sap-contractlist-signature'}).href+'?page=0&status=signature'" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-contract"></i> ohne Unterschrift</a>
            <a :href="$router.resolve({name: 'sap-contractlist-invoice'}).href+'?page=0&status=invoice'" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-contract"></i> ohne Rechnung</a>
          </div>

          <div class="shrink cell" v-if="navStatus == 'open'">
            <a :href="$router.resolve({name: 'sap-contractlist-signature'}).href+'?page=0&status=signature'" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-contract"></i> ohne Unterschrift</a>
            <a :href="$router.resolve({name: 'sap-contractlist-invoice'}).href+'?page=0&status=invoice'" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-contract"></i> ohne Rechnung</a>
          </div>

          <div class="shrink cell" v-if="navStatus == 'inactive'">
            <a :href="$router.resolve({name: 'sap-contractlist-signature'}).href+'?page=0&status=signature'" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-contract"></i> ohne Unterschrift</a>
            <a :href="$router.resolve({name: 'sap-contractlist-invoice'}).href+'?page=0&status=invoice'" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-contract"></i> ohne Rechnung</a>
          </div>

          <div class="shrink cell" v-if="navStatus == 'signature'">
            <a :href="$router.resolve({name: 'sap-contractlist-signature'}).href+'?page=0&status=signature'" class="cx-button tiny" style="margin-right: 10px; opacity: 0.3;"><i class="fa-light fa-file-contract"></i> ohne Unterschrift</a>
            <a :href="$router.resolve({name: 'sap-contractlist-invoice'}).href+'?page=0&status=invoice'" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-contract"></i> ohne Rechnung</a>
          </div>

          <div class="shrink cell" v-if="navStatus == 'invoice'">
            <a :href="$router.resolve({name: 'sap-contractlist-signature'}).href+'?page=0&status=signature'" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-contract"></i> ohne Unterschrift</a>
            <a :href="$router.resolve({name: 'sap-contractlist-invoice'}).href+'?page=0&status=invoice'" class="cx-button tiny" style="margin-right: 10px; opacity: 0.3;"><i class="fa-light fa-file-contract"></i> ohne Rechnung</a>
          </div>
          
        </div><!-- grid-x sub -->
      </div><!-- shrink cell -->
      
    </div><!-- grid-x -->

    <div style="height: 10px;"></div>

    <div class="dx-card -no-paddings" style="position: relative;">

      <!-- allow-adding="false" then this -->
      <!-- <div class="" style="position: absolute; top: 15px; right: 15px; z-index: 1;">
        <button @click="createContract" class="cx-button tiny"><i class="fa-light fa-file-contract"></i> Vertrag erstellen</button>
      </div> -->

      <DxDataGrid
        ref="myDataGrid"
        id="gridContainer"
        :data-source="customDataSource"

        :focused-row-enabled="false"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        
        :allow-column-reordering="true"
        :show-borders="false"

        @content-ready="getTotalCount"

        @editing-start="logEvent('EditingStart')"
        @init-new-row="logEvent('InitNewRow')"
        @row-inserting="logEvent('RowInserting')"
        @row-inserted="logEvent('RowInserted')"
        @row-updating="logEvent('RowUpdating')"
        @row-updated="logEvent('RowUpdated')"
        @row-removing="logEvent('RowRemoving')"
        @row-removed="logEvent('RowRemoved')"
        @saving="logEvent('Saving')"
        @saved="logEvent('Saved')"
        @edit-canceling="logEvent('EditCanceling')"
        @edit-canceled="logEvent('EditCanceled')"
      >

      <DxPaging :page-size="500" />
      <DxPager :show-page-size-selector="false" :show-info="true" />
      <DxFilterRow :visible="true" />

      <DxEditing
        :allow-updating="false"
        :allow-deleting="true"
        :allow-adding="false"
        mode="row"
      />

      <DxColumn
          alignment='left' 
          data-field="Search"
          :caption="totalCount + ' ' + title"
          :placeholder="'Suchen'"
          cell-template="subjectTemplate"
          :hiding-priority="99"
        />
        <DxColumn
          alignment='left' 
          data-field="LocationName"
          :caption="''"
          :placeholder="'Standort'"
          :editor-options="locationEditorOptions"
          editor-type="dxSelectBox"
          :width="200" 
          cell-template="locationTemplate"
          :hiding-priority="99"
        />


        <template #subjectTemplate="{ data: content }">

          <div class="grid-x align-middle">

            <div class="shrink cell">

              <!-- avatar -->
              <div class="user-thumb" style="margin: 2px 10px 2px 0;"
                v-bind:style="{ 'background-image': 'url(' + content.data.CustomerPicthumb + '?v=' + timestamp + ')' }" >
              </div>
              <!-- /avatar -->

            </div><!-- /shrink cell main -->

            <div class="auto cell">

              <div class="grid-x align-middle">

                <div class="shrink cell">
                  <!-- row 1 -->
                  <div class="fixedwidth-listitem">
                    <p class="ck-overflow-ellipsis strong small">
                      <span v-if="content.data.CustomerSubject">
                        {{ content.data.CustomerSubject }}
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Kunde
                      </span>
                    </p>

                    <p class="ck-overflow-ellipsis small">
                      VG-{{ content.data.id }}
                    </p>

                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->

                <div class="shrink cell">
                  <!-- row 2 -->
                  <div class="fixedwidth-listitem">
                    <p class="ck-overflow-ellipsis strong small" :id="'titleAboSubject'+content.data.id">
                      <span v-if="content.data.AboSubject">
                        {{ content.data.AboSubject }}
                      </span>
                      <span v-else>
                        <span class="alert-color">
                          <i class="fa-light fa-triangle-exclamation"></i> Abo
                        </span>
                      </span>
                    </p>

                    <p style="font-size: 8px;" v-if="content.data.Subject != 'null'" class="ck-overflow-ellipsis small">
                      {{ content.data.Subject }}
                    </p>
                    
                    <p class="ck-overflow-ellipsis small" :id="'titleValidDates'+content.data.id">
                      <span v-if="content.data.ValidDates && content.data.DateStart && content.data.DateStop">
                        <span v-if="content.data.ValidDates.slice(0, 1)">{{ formatDate(content.data.ValidDates.slice(0, 1)) }}</span> 
                        <span v-if="content.data.ValidDates.slice(-1)"> – {{ formatDate(content.data.ValidDates.slice(-1)) }}</span>
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Zeitraum
                      </span>
                    </p>

                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->

                <div class="shrink cell">
                  <!-- row 3 -->
                  <div class="fixedwidth-listitem">
                    <p class="ck-overflow-ellipsis strong small" :id="'titlePrice'+content.data.id">
                      <span v-if="content.data.Price">
                        CHF {{ formatPrice(content.data.Price - content.data.Discount) }}
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Preis
                      </span>
                    </p>

                    <p class="ck-overflow-ellipsis small" :id="'titleRates'+content.data.id">
                      <span v-if="content.data.Rates">
                      {{ content.data.Rates }} Rate<span v-if="content.data.Rates > 1">n</span>
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Raten
                      </span>
                    </p>

                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->

                <div class="shrink cell">
                  <!-- row 4 -->
                  <div class="fixedwidth-listitem">
                    <p class="ck-overflow-ellipsis strong small">
                      <span v-if="content.data.GetInvoiceid">
                        RE-{{ content.data.GetInvoiceid }}
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Rechnung
                      </span>
                    </p>

                    <p class="ck-overflow-ellipsis small">
                      <span v-if="content.data.SignaturePng" class="success-color">
                        <i class="fa-light fa-circle-check"></i> Unterschrift
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Unterschrift
                      </span>
                    </p>

                    <p v-if="diffDays(content.data.DateStop) < 30" class="ck-overflow-ellipsis small strong alert-color" >
                      <i class="fa-light fa-triangle-exclamation"></i> {{ diffDays(content.data.DateStop) }} Tage
                    </p>
                    <p v-if="diffDays(content.data.DateStop) < 60 && diffDays(content.data.DateStop) >= 30" class="ck-overflow-ellipsis small strong warning-color" >
                      <i class="fa-light fa-triangle-exclamation"></i> {{ diffDays(content.data.DateStop) }} Tage
                    </p>
                    <p v-if="diffDays(content.data.DateStop) >= 60" class="ck-overflow-ellipsis small strong success-color" >
                      <i class="fa-light fa-circle-check"></i> {{ diffDays(content.data.DateStop) }} Tage
                    </p>

                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->

                <div class="shrink cell align-self-top hide">
                  <!-- row 5 -->
                  <div class="fixedwidth-listitem">
                    <!-- PaidList -->
                    <div v-for="(item, index) in content.data.PaidList" :key="index">
                      <p class="ck-overflow-ellipsis small success-color">
                        <span v-if="item.Date">
                          <i class="fa-light fa-circle-check"></i>
                          {{ formatDate(item.Date) }}
                          |
                        </span>
                        <span v-if="item.Price">
                          CHF {{ formatPrice(item.Price) }}
                        </span>
                      </p>
                    </div><!-- /v-for PaidList -->
                    <!-- DueList -->
                    <div v-for="(item, index) in content.data.DueList" :key="index">
                      <p class="ck-overflow-ellipsis small alert-color">
                        
                        <span v-if="item.Date">
                          <i class="fa-light fa-triangle-exclamation"></i>
                          {{ formatDate(item.Date) }}
                          |
                        </span>
                        <span v-if="item.Price">
                          CHF {{ formatPrice(item.Price) }}
                        </span>
                      </p>
                    </div><!-- /v-for DueList -->

                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->


              </div><!-- /grid-x sub-->


            </div><!-- /auto cell main -->
          </div><!-- /grid-x main -->

        </template>

        <template #locationTemplate="{ data: content }">

          <div class="fixedwidth-listitem">

            <p class="ck-overflow-ellipsis small strong">
              {{ content.data.LocationName }}
            </p>

            <div class="reminder-area" :class="{ hide: diffDays(content.data.DateStop) >= 60 }">

              <p>
                <a 
                  :href="'#/renew-abo/'+content.data.Customerid+'-'+content.data.id" 
                  target="_blank" 
                  style="font-size: 12px;" 
                  class="-hide -cx-button -tiny">
                  <i class="fa-light fa-share"></i> 
                  Reminder ansehen
                </a>
              </p>
              
              <p v-if="content.data.RenewDate">
                <span style="font-size: 12px;">
                  verlängert: {{formatDate( content.data.RenewDate) }}
                </span>
              </p>
              <p v-else>
                <a 
                  @click="sendReminder(content.data.Customerid,content.data.id,content.data.CustomerEmail)" 
                  style="font-size: 12px;">
                  <i class="fa-light fa-envelope"></i>
                  <span :id="'reminderdate'+content.data.id"> Reminder senden</span>
                </a>
              </p>

            </div><!-- /reminder-area -->

          </div><!-- /fixedwidth-listitem -->

        </template>


        <DxMasterDetail
          :enabled="true"
          template="masterDetailTemplate"
        />
        <template #masterDetailTemplate="{ data: content }">
          <DetailTemplate
            :template-data="content"
          />
        </template>
  
      </DxDataGrid>
  
    </div><!-- dx-card -->
    
    </div><!-- content-block -->
  
    <div v-else class="content-block">
      Oh no 😢
    </div><!-- content-block -->
  
    <div class="content-block hide">
      <div id="events">
  
        <div class="grid-x grid-margin-x"> 
          <div class="auto cell"> 
            <div class="caption">
              Fired events:
            </div>
            <ul class="menu vertical">
            <li
              v-for="(event, index) in events"
              :key="index"
            >{{ event }}</li>
            </ul>
          </div>
          <div class="shrink cell"> 
            <DxButton
              id="clear"
              text="Clear"
              @click="clearEvents()"
            />
          </div>
        </div><!-- grid-x -->
  
      </div><!-- events -->
    </div><!-- content-block -->
  
  </template>
  

<script>

import {
  DxDataGrid, 
  DxFilterRow, 
  DxColumn, 
  DxEditing, 
  DxPager, 
  DxPaging, 
  DxButton,
  DxMasterDetail,
  //DxSelection,
  // DxScrolling,

  //DxLookup, 
} from 'devextreme-vue/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import DetailTemplate from '../parts/contract-item-detail-master.vue';
import notify from 'devextreme/ui/notify';

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 2,
  lg: 3
}

let currentUser;
let totalCount;

let navCurrentpage;
let navPrevpage;
let navPrevTemplate;
let navNextpage;
let navNextTemplate;
let navStatus;

let paramEmployeeId;
let paramLocationId;
let paramAboId;
let paramPrice;
let paramDiscount;
let paramRates;
let paramInterval;
let paramDateStart;
let paramDateStop;
let paramSignature;

auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
    // console.log(currentUser.id);
    // console.log(currentUser.Constant.languages);
    // console.log(currentUser.Email);
    // console.log(currentUser.Language);
  } // e.data
}); //auth

function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

const customDataSource = new CustomStore({
  key: 'id',
  load: () => {
    const queryString = window.location.href;
    let pagestring = queryString.indexOf("page=");
    let currentpage = parseInt(queryString.substring(pagestring+5));

    if(!currentpage){
      currentpage = 1;
    }

    const statusstring = queryString.indexOf("status=");
    let currentstatus = queryString.substring(statusstring+7);
    if(!currentstatus){
      currentstatus = 'active';
    }
    //console.log(currentstatus);

    const objString = '?page=' + currentpage + '&status=' + currentstatus;
    //console.log(objString);

    return fetch(apihost+'/'+currentUser.Language+'/vue/contract/list/'+objString)
    .then(handleErrors)
    .then(response => response.text())
    .then(data => {
    //console.log(`response text`, text)
    return JSON.parse(data)
    })
    .catch(() => { throw 'Network error' });
  },
  insert: (values) => {
    //console.log(values);
    const key = '0';
    const objString = '?' + new URLSearchParams(values).toString();
    return fetch(apihost+'/de/vue/contract/getcontract/'+key+'/insert'+objString, {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  },
  remove: (key) => {
    //console.log(key);
    return fetch(apihost+'/de/vue/contract/getcontract/'+key+'/remove', {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  },
  update: (key, values) => {
    // console.log(key);
    // console.log(values);
    const objString = '?' + new URLSearchParams(values).toString();
    return fetch(apihost+'/de/vue/contract/getcontract/'+key+'/save'+objString, {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  }

});


export default {

  mounted() {

    //redirect to Member App
    if(currentUser.Role == 'Customer'){
      const currentUrl = window.location.href;
      const redirecturl = currentUrl.replace("/#/sap-contractlist-odd", "/#/dashboard");
      window.location.href = redirecturl;
    }

    this.timestamp = this.printTimestamp();

    const queryString = window.location.href;

    //STATUS
    const statusstring = queryString.indexOf("status=");
    let currentstatus = queryString.substring(statusstring+7);
    //console.log(currentstatus);

    if(!currentstatus){
      currentstatus = 'active';
    }
    this.navStatus = currentstatus;

    //PAGE
    let pagestring = queryString.indexOf("page=");
    let currentpage = parseInt(queryString.substring(pagestring+5));
    if(!currentpage){
      currentpage = 1;
    }
    let prevpage = currentpage-1;
    let nextpage = currentpage+1;
    this.navCurrentpage = currentpage;
    this.navPrevpage = prevpage;
    this.navNextpage = nextpage;

    //TEMPLATE
    //check if the number is even
    if(prevpage % 2 == 0) {
      this.navPrevTemplate = 'sap-contractlist-even';
    }
    // if the number is odd
    else {
      this.navPrevTemplate = 'sap-contractlist-odd';
    }

    //check if the number is even
    if(nextpage % 2 == 0) {
      this.navNextTemplate = 'sap-contractlist-even';
    }
    // if the number is odd
    else {
      this.navNextTemplate = 'sap-contractlist-odd';
    }

  },

  components: {
    DxDataGrid,
    DxMasterDetail,
    DxFilterRow,
    DxColumn,
    DxEditing,
    DxPager,
    DxPaging,
    DxButton,
    DetailTemplate,
    // DxForm,
    // DxGroupItem,
    // DxSimpleItem,
    // DxButtonItem,
    // DxLabel,
    // DxFileUploader,
    // DxDropDownBox,
    // DxSelection,
    // DxScrolling,
    //DxTextBox,
    //DxRequiredRule,
    //DxCompareRule,
    //DxPatternRule,
    //DxRangeRule,
    //DxEmailRule,
    //DxStringLengthRule,
    //DxAutocomplete,
    //DxAsyncRule,
    //DxLookup,


  },
  data() {
    return {
      apihost,
      totalCount,

      navCurrentpage,
      navPrevpage,
      navPrevTemplate,
      navNextpage,
      navNextTemplate,
      navStatus,
      paramEmployeeId,
      paramLocationId,
      paramAboId,
      paramPrice,
      paramDiscount,
      paramRates,
      paramInterval,
      paramDateStart,
      paramDateStop,
      paramSignature,

      title: currentUser.Translation.vueappNavMemberContracts,
      events: [],
      formInstance: null,
      // statuses,
      // priorities,
      // contracttypes,
      // contractcategory,
      currentUser,
      customDataSource,
      colCountByScreen,

      // timestamp,

      // //Option 1
      // option1DataSource,
      // option1Columns,
      // option1BoxValue,
      // //changeOption1,
      // //readyOption1,

      // //Option 2
      // option2DataSource,
      // option2Columns,
      // option2BoxValue,
      // //changeOption2,
      // //readyOption2,

      calendarOptions: { 
        displayFormat: "dd.MM.yyyy"
      },
      buttonOptions: {
        text: currentUser.Translation.vueappContractFormSubmit,
        type: 'success',
        useSubmitBehavior: true,
      },
      dateBoxOptions: {
        invalidDateExerciseoption2:
          'The date must have the following format: MM/dd/yyyy',
      },
      checkBoxOptions: {
        text: 'I agree to the Terms and Conditions',
        value: false,
      },
      phoneEditorOptions: {
        mask: '+1 (X00) 000-0000',
        maskRules: {
          X: /[02-9]/,
        },
        maskInvalidExerciseoption2: 'The phone must have a correct USA phone format',
      },

      statusPattern: /^[^0-9]+$/,
      priorityPattern: /^[^0-9]+$/,
      contractPattern: /^[^0-9]+$/,

      // phonePattern: /^[02-9]\d{9}$/,
      // maxDate: new Date().setFullYear(new Date().getFullYear() - 21),
      
      validationRules: {

        // status: [
        //   { type: 'required', contract: currentUser.Translation.vueappTaskStatusExerciseoption2 },
        // ],
        // priority: [
        //   { type: 'required', contract: currentUser.Translation.vueappTaskPriorityExerciseoption2 },
        // ],
      },
      //statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
      //priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },
      // statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
      // priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },
      locationEditorOptions: { items: currentUser.Constant.locations, searchEnabled: true },
    };
  },
  methods: {
    
    diffDays(dateStop) {

      const now = new Date();
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const stopDate = new Date(dateStop);
      const diffDays = Math.round(Math.abs((now - stopDate) / oneDay));

      return diffDays;

      },

      getTotalCount(e) {
      this.totalCount = e.component.totalCount();
      },

      formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      //return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
      return new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(date);
      },

      formatPrice(value) {
      //https://stackoverflow.com/questions/43208012/how-do-i-format-currencies-in-a-vue-component
      let val = (value/1).toFixed(2).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
      },

      validateForm(e) {
      e.component.validate();
      },

      logEvent(eventName) {
      //console.log(this.events);
      this.events.unshift(eventName);
      },
      clearEvents() {
      this.events = [];
      },

      printTimestamp: function () {
        return Date.now();
      },

      createContract() {
      this.$refs['myDataGrid'].instance.addRow();
      const savebutton = document.getElementsByClassName("dx-link-save")[0];
      if(savebutton){
        savebutton.click();
      }

      notify({
        message: currentUser.Translation.vueappContractFormSubmitSuccess,
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'success', 3000);

      },


      dupContract(Employeeid,LocationId,Aboid,Price,Discount,Rates,Interval,DateStart,DateStop,SignaturePng) {

      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const startDate = new Date(DateStart);
      const stopDate = new Date(DateStop);
      const diffDays = Math.round(Math.abs((startDate - stopDate) / oneDay));
      const inOneDay = new Date(new Date(stopDate).setDate(stopDate.getDate() + 1));
      const inDiffDays = new Date(new Date(stopDate).setDate(stopDate.getDate() + diffDays));
      const Signature = SignaturePng.split("/").splice(-1)[0];

      // "DateStart": "2024-01-01T00:00:00+01:00",
      // "DateStop": "2025-01-01T00:00:00+01:00",

      // console.log(DateStart);
      // console.log(DateStop);
      // console.log(diffDays);
      // console.log(inOneDay.toISOString());
      // console.log(inDiffDays.toISOString());
      // console.log(Signature);

      paramEmployeeId = Employeeid;
      paramLocationId = LocationId;
      paramAboId = Aboid;
      paramPrice = Price;
      paramDiscount = Discount;
      paramRates = Rates;
      paramInterval = Interval;
      paramDateStart = inOneDay.toISOString();
      paramDateStop = inDiffDays.toISOString();
      paramSignature = Signature;


      this.$refs['myDataGrid'].instance.addRow();
      const savebutton = document.getElementsByClassName("dx-link-save")[0];
      if(savebutton){
      savebutton.click();
      }

      notify({
      message: currentUser.Translation.vueappContractFormSubmitSuccess,
      position: {
        my: 'center top',
        at: 'center top',
      },
      }, 'success', 3000);

      },


      sendReminder(CustomerId, ContractId, CustomerEmail){

      // console.log('sendReminder');
      // console.log(CustomerId);
      // console.log(ContractId);
      // console.log(CustomerEmail);

      const reminderdateHtml = document.getElementById("reminderdate"+ContractId);
      //console.log(reminderdateHtml);

      if(reminderdateHtml){
        reminderdateHtml.innerHTML = ' Reminder gesandt';
      }

      fetch(apihost+'/de/mail/reminder/'+CustomerId+'/'+ContractId)
        .then(handleErrors)
        .then(response => response.text())
        .then(result => {
          const data = JSON.parse(result);
          console.log(data);

          //Update Frontend
          //this.arrDatesent = data.arrDatesent;

          //this.fetchUser = data;
        })
        .catch(() => { throw 'Network error' });


      notify({
      message: 'Reminder wurde an '+ CustomerEmail +' versandt',
      position: {
        my: 'center top',
        at: 'center top',
      },
      }, 'success', 3000);

    },

  },

};

</script>