<template>

  <h2 class="content-block">
    <i class="dx-icon nav-icon fa-light fa-user"></i> 
    {{ title }}
  </h2>
  
  <div class="content-block dx-card _responsive-paddings has-paddings">

    <div class="grid-x -grid-margin-x">
      <div class="shrink cell">
        <div class="form-avatar">
          <div v-if="fetchUser">
            <img :src="fetchUser.Avatar + '?v=' + timestamp" />
          </div>
        </div>
      </div>
      <div class="auto cell">

        <DxFileUploader
          name="upfile"
          :upload-url="apihost+'/uploadavatar.php?id=' + currentUser.id"
          :accept="accept"
          :multiple="false"
          :upload-mode="uploadMode"
          :allowed-file-extensions="['.jpg', '.jpeg', '.png']"
          :min-file-size="1024"
          :max-file-size="10240*1024"
          :on-files-uploaded="onFilesUploaded"
          label-text=""
          :select-button-text="currentUser.Translation.vueappCustomerAvatarButton"
          @value-changed="e => files = e.value"
        />

        <div
          :style="{display: files.length > 0 ? 'block' : 'none'}"
          class="content"
        >
          <div class="hide">
            <div
              v-for="(file, index) in files"
              :key="index"
              class="selected-item"
            >
              <span>Name: {{ file.name }}<br></span>
              <span>Size {{ file.size }}<br></span>
              <span>Type {{ file.type }}<br></span>
              <span>Last Modified Date: {{ file.lastModifiedDate }}</span>
            </div><!-- v-for -->
          </div><!-- hide -->

        </div><!-- if files.length -->



        <div class="options hide">
          <div class="caption">Options</div>
          <div class="option">
            <span>File types</span>
            <DxSelectBox
              :data-source="fileTypesSource"
              value-expr="value"
              :input-attr="{ 'aria-label': 'File Type' }"
              display-expr="name"
              value="*"
              @value-changed="e => accept = e.value"
            />
          </div>
          <div class="option">
            <span>Upload mode</span>
            <DxSelectBox
              :items="['instantly', 'useButtons']"
              value="instantly"
              :input-attr="{ 'aria-label': 'Mode' }"
              @value-changed="e => uploadMode = e.value"
            />
          </div>
          <div class="option">
            <DxCheckBox
              text="Allow multiple files selection"
              @value-changed="e => multiple = e.value"
            />
          </div>


        </div><!-- options -->


      </div>


    </div>

  </div>
  
  
  
  
  <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block -hide">
  
    <div class="dx-card no-paddings">
  
    <div id="form-demo">
      <div class="upload-widget-container">
        <form
          action="your-action"
          @submit="handleSubmit"
        >
        <DxForm
            :form-data="fetchUser"
            :read-only="false"
            @initialized="saveFormInstance"
            :show-colon-after-label="true"
            :show-validation-summary="true"
            validation-group="currentUserData"
          >

            <DxGroupItem 
            caption=""
            :colCountByScreen="colCountByScreen"
            >
  
              <DxSimpleItem 
                data-field="Title"
                help-text=""
                :caption="currentUser.Translation.vueappCustomerPrefix"
                :placeholder="currentUser.Translation.vueappCustomerPrefix"
                :editor-options="genderEditorOptions"
                :validation-rules="validationRules.gender"
                editor-type="dxSelectBox"
              >
                <DxLabel :text="currentUser.Translation.vueappCustomerPrefix"/>
                <DxRequiredRule :message="currentUser.Translation.vueappCustomerPrefixMessage"/>
                <DxPatternRule
                  :pattern="titlePattern"
                  :message="currentUser.Translation.vueappCustomerPrefixPattern"
                />
              </DxSimpleItem>
  
              <DxSimpleItem 
                data-field="FirstName"
                help-text=""
              >
                <DxLabel :text="currentUser.Translation.vueappCustomerFirstName"/>
                <DxRequiredRule :message="currentUser.Translation.vueappCustomerFirstNameMessage"/>
                <DxPatternRule
                  :pattern="firstnamePattern"
                  :message="currentUser.Translation.vueappCustomerFirstNamePattern"
                />
              </DxSimpleItem>
  
              <DxSimpleItem 
                data-field="LastName"
                help-text=""
              >
                <DxLabel :text="currentUser.Translation.vueappCustomerLastName"/>
                <DxRequiredRule :message="currentUser.Translation.vueappCustomerLastNameMessage"/>
                <DxPatternRule
                  :pattern="lastnamePattern"
                  :message="currentUser.Translation.vueappCustomerLastNamePattern"
                />
              </DxSimpleItem>

              <DxSimpleItem 
                data-field="Email"
                help-text=""
              >
                <DxLabel :text="currentUser.Translation.vueappCustomerEmail"/>
                <DxRequiredRule :message="currentUser.Translation.vueappCustomerEmailMessage"/>
                <DxEmailRule :message="currentUser.Translation.vueappCustomerEmailRule"/>
                <DxAsyncRule
                  :validation-callback="asyncValidation"
                  :message="currentUser.Translation.vueappCustomerEmailPattern"
                />
              </DxSimpleItem>

              <DxSimpleItem
                data-field="Street"
                help-text=""
              >
                <DxLabel :text="currentUser.Translation.vueappCustomerStreet"/>
              </DxSimpleItem>
  
              <DxSimpleItem 
                data-field="Zip"
                help-text=""
              >
                <DxLabel :text="currentUser.Translation.vueappCustomerZip"/>
              </DxSimpleItem>
  
              <DxSimpleItem 
                data-field="City"
                help-text=""
              >
                <DxLabel :text="currentUser.Translation.vueappCustomerCity"/>
              </DxSimpleItem>
  
              <DxSimpleItem
                data-field="Phone"
                help-text=""
              >
                <DxLabel :text="currentUser.Translation.vueappCustomerPhone"/>
              </DxSimpleItem>

              <DxSimpleItem
                data-field="BirthDate"
                help-text=""
                editor-type="dxDateBox"
                :editor-options="calendarOptions"
                displayFormat="dd.MM.yyyy"  
              >
                <DxLabel :text="currentUser.Translation.vueappCustomerBirthDate"/>
              </DxSimpleItem>
  
              <DxSimpleItem 
                data-field="Country"
                help-text=""  
                :editor-options="countryEditorOptions"
                :validation-rules="validationRules.country"
                editor-type="dxSelectBox"
            >
              <DxLabel :text="currentUser.Translation.vueappCustomerCountry"/>

            </DxSimpleItem>

            <DxSimpleItem 
                data-field="Language"
                help-text=""  
                :editor-options="languageEditorOptions"
                :validation-rules="validationRules.language"
                editor-type="dxSelectBox"
            >
              <DxLabel :text="currentUser.Translation.vueappCustomerLanguage"/>
            </DxSimpleItem>

            <DxSimpleItem
              data-field="Ahvnummer"
              help-text=""
            >
              <DxLabel :text="'AHV-Nummer'"/>
            </DxSimpleItem>

            <DxSimpleItem
              data-field="Krankenversicherung"
              help-text=""
            >
              <DxLabel :text="'Krankenversicherung'"/>
            </DxSimpleItem>

            <DxSimpleItem
              data-field="Zusatzversicherung"
              help-text=""
            >
              <DxLabel :text="'Zusatzversicherung'"/>
            </DxSimpleItem>


            </DxGroupItem>

            <DxSimpleItem 
              data-field="Notes"
              help-text=""
            >
              <DxLabel :text="currentUser.Translation.vueappCustomerNotes"/>
            </DxSimpleItem>

            <DxButtonItem
              horizontal-alignment="left"
              :button-options="buttonOptions"
            />
  
          </DxForm>
        </form>
      </div>
    </div>
  
  </div><!-- dx-card -->
  
  </div><!-- content-block -->
  
  <div v-else class="content-block">
    Oh no 😢
  </div><!-- content-block -->
  
  </template>
  <script>

  import DxForm, {
    DxGroupItem,
    DxSimpleItem,
    DxButtonItem,
    DxLabel,
    DxRequiredRule,
    //DxCompareRule,
    //DxRangeRule,
    //DxStringLengthRule,
    DxPatternRule,
    DxEmailRule,
    DxAsyncRule,
  } from 'devextreme-vue/form';
  //import DxAutocomplete from 'devextreme-vue/autocomplete';
  //import DxDateBox from 'devextreme-vue/date-box';

  import notify from 'devextreme/ui/notify';
  import Validator from 'devextreme/ui/validator';
  import { DxFileUploader } from 'devextreme-vue/file-uploader';
  //import { DxTextBox } from 'devextreme-vue/text-box';
  //import { DxButton } from 'devextreme-vue/button';
  import { DxCheckBox } from 'devextreme-vue/check-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';

  import auth from "../auth";

  import { 
    apihost, 
  } from "../api";
  //console.log(apihost);

  const colCountByScreen = {
    xs: 1,
    sm: 1,
    md: 3,
    lg: 4
  }

  //let invalidEmail = 'test@dx-email.com';
  let invalidEmail = '';
  let currentUser;
  let fetchUser;

  //Async Check Email
  const sendRequest = function(value) {

    setTimeout(() => {
      fetch(apihost+'/de/vue/member/membercheckmail/' + currentUser.id + '/' + value)
      .then(response => response.text())
      .then(result => {
      const data = JSON.parse(result);
      //console.log(data);
      invalidEmail = data;
      //this.fetchUser = data;
      })
      .catch(() => { throw 'Network error' });
    
    }, 0);

    //console.log(invalidEmail);

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(value !== invalidEmail);
      }, 1000);
    });

  };
  
  auth.getUser().then((e) => {
    if(e.data){
      //console.log(e.data);
      //!Important for Role id etc. => update data with fetch on mounted!
      currentUser = e.data;
    } // e.data
  }); //auth
  
  function handleErrors(response) {
      if (!response.ok) {
          throw Error(response.statusText);
      }
      return response;
    }
  
  export default {
  
    mounted() {

    //redirect to Member App
    if(currentUser.Role == 'Customer'){
      const currentUrl = window.location.href;
      const redirecturl = currentUrl.replace("/#/profile", "/#/myprofile");
      window.location.href = redirecturl;
    }


      //REFRESH https://stackoverflow.com/questions/44094629/i-have-page-refreshing-on-vuejs-single-page-app
      //this.$router.push('/about');
  
      auth.getUser().then((e) => {
        if(e.data){
          // console.log(e.data);
          // console.log(e.data.id);
          // console.log(e.data.Language);
  
          //DATA
          setTimeout(() => {
            fetch(apihost+'/de/vue/member/getmember/'+e.data.id+'/view')
            .then(response => response.text())
            .then(result => {
            const data = JSON.parse(result);
            //console.log(data.Avatar);
            this.fetchUser = data;
            })
            .catch(() => { throw 'Network error' });
          
          }, 0);
  
        } // e.data
        
      }); //auth

      this.timestamp = this.printTimestamp();
  
    },
  
    components: {
      DxGroupItem,
      DxSimpleItem,
      DxButtonItem,
      DxLabel,
      DxRequiredRule,
      //DxCompareRule,
      DxPatternRule,
      //DxRangeRule,
      DxEmailRule,
      //DxStringLengthRule,
      DxForm,
      //DxAutocomplete,
      DxAsyncRule,
      //notify,
      //DxDateBox,
      DxFileUploader,
      //DxTextBox,
      //DxButton,
      DxSelectBox,
      DxCheckBox,

    },
    data() {
      return {
        apihost,
        
        title: currentUser.Translation.vueappNavMemberProfile,
        colCountByScreen,
        formInstance: null,
        currentUser,
        fetchUser,

        //uploader
        formRefName: 'tree-view',
        multiple: false,
        accept: '*',
        uploadMode: 'instantly',
        //uploadUrl: apihost+'/uploadavatar.php?id=' + currentUser.id,
        fileTypesSource: [
          { name: 'All types', value: '*' },
          { name: 'Images', value: 'image/*' },
          { name: 'Videos', value: 'video/*' },
        ],
        files: [],
        //end uploader        

        calendarOptions: { 
          displayFormat: "dd.MM.yyyy"
        },
        buttonOptions: {
        text: currentUser.Translation.vueappCustomerProfileSubmit,
          type: 'success',
          useSubmitBehavior: true,
        },
        passwordOptions: {
          mode: 'password',
          onValueChanged: () => {
            const editor = this.formInstance.getEditor('ConfirmPassword');
            if (editor.option('value')) {
              const instance = Validator.getInstance(editor.element());
              instance.validate();
            }
          },
          buttons: [
            {
              name: 'password',
              location: 'after',
              options: {
                icon: apihost+'/images/icons/eye.png',
                type: 'default',
                onClick: () => this.changePasswordMode('Password'),
              },
            },
          ],
        },
        confirmPasswordOptions: {
          mode: 'password',
          buttons: [
            {
              name: 'password',
              location: 'after',
              options: {
                icon: apihost+'/images/icons/eye.png',
                type: 'default',
                onClick: () => this.changePasswordMode('ConfirmPassword'),
              },
            },
          ],
        },
        dateBoxOptions: {
          invalidDateMessage:
            'The date must have the following format: MM/dd/yyyy',
        },
        checkBoxOptions: {
          text: 'I agree to the Terms and Conditions',
          value: false,
        },
        phoneEditorOptions: {
          mask: '+1 (X00) 000-0000',
          maskRules: {
            X: /[02-9]/,
          },
          maskInvalidMessage: 'The phone must have a correct USA phone format',
        },
        titlePattern: /^[^0-9]+$/,
        firstnamePattern: /^[^0-9]+$/,
        lastnamePattern: /^[^0-9]+$/,
        namePattern: /^[^0-9]+$/,
        cityPattern: /^[^0-9]+$/,
        //phonePattern: /^[02-9]\d{9}$/,
        //maxDate: new Date().setFullYear(new Date().getFullYear() - 21),

        validationRules: {
          position: [
            { type: 'required', message: currentUser.Translation.vueappCustomerPrefixMessage },
          ],
          firstname: [
            { type: 'required', message: currentUser.Translation.vueappCustomerFirstNameMessage },
          ],
          lastname: [
            { type: 'required', message: currentUser.Translation.vueappCustomerLastNameMessage },
          ],
          email: [
            { type: 'required', message: currentUser.Translation.vueappCustomerEmailMessage },
          ],
          role: [
            { type: 'required', message: currentUser.Translation.vueappCustomerRoleMessage },
          ],
          country: [
            { type: 'required', message: currentUser.Translation.vueappCustomerCountryMessage },
          ],
          language: [
            { type: 'required', message: currentUser.Translation.vueappCustomerLanguageMessage },
          ],
        },
        genderEditorOptions: { items: currentUser.Constant.genders, searchEnabled: true },
        roleEditorOptions: { items: currentUser.Constant.roles, searchEnabled: true },
        countryEditorOptions: { items: currentUser.Constant.countries, searchEnabled: true },
        languageEditorOptions: { items: currentUser.Constant.languages, searchEnabled: true },
      };
    },
    methods: {

      printTimestamp: function () {
        return Date.now();
      },

      onFilesUploaded() {
        //console.log(e);
        //console.log('finished');
        //this.$router.push('/');

        setTimeout(() => {
          window.location.reload();
        }, 2000);

      },

      saveFormInstance(e) {
        this.formInstance = e.component;
        //console.log(e.component);
      },
      changePasswordMode(name) {
        const editor = this.formInstance.getEditor(name);
        editor.option(
          'mode',
          editor.option('mode') === 'text' ? 'password' : 'text',
        );
      },
      passwordComparison() {
        return this.fetchUser.Password;
      },
      checkComparison() {
        return true;
      },
      asyncValidation(params) {
        return sendRequest(params.value);
      },
      handleSubmit(e) {
  
        e.preventDefault();

        if(invalidEmail){

         //console.log(invalidEmail);

        } else {
  
        auth.getUser().then((e) => {
          if(e.data){
  
            const objString = '?' + new URLSearchParams(this.fetchUser).toString();
            //console.log(objString);
            
            fetch(apihost+'/de/vue/member/getmember/'+e.data.id+'/save'+objString)
            .then(handleErrors)
            .then(response => response.text())
            .then(result => {
            const data = JSON.parse(result);
            //console.log(data);
            this.fetchUser = data;
            })
            .catch(() => { throw 'Network error' });
  
          } // e.data
          
        }); //auth
  
        notify({
          message: currentUser.Translation.vueappCustomerProfileSubmitSuccess,
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);

        } //if invalidEmail
  
      },

    },
  };
</script>

<style scoped>
  form {
    margin: 15px;
  }
</style>

<style>

</style>
  