<template>
<svg version="1.1" id="ManBack" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 450 950" style="enable-background:new 0 0 450 950;" xml:space="preserve">
<g id="Hand_MB">
<path class="st0" d="M361.4,424.4c0,0,0.1,25.7-4.4,36.8s-8.6,16.9-10.3,20.2c-1.7,3.2-5.8,7.3-5.5,22.2c0.3,14.8-1.6,23.4,4,24.1
c5.5,0.7,7.8-6.2,8.3-9.6c0.6-3.4,0.4-15,5.8-15.8c0,0,3.5,2.7,6.1,9.5c2.5,6.8,4.5,13.4,4.5,13.4s-2.5,8.5-3,13.4
c0,0-8.9,8.6-9.6,12s-0.6,7.1,2.3,11c0,0,1.3,5.2,3.1,7.6l3.5-3.7c0,0-0.6,7.2,5.2,9.7c0,0,8.3-22.6,9-25.1s1.6-6.9,2.1-14
c0.6-7.1,3.5-46.7,3.5-64.7c0-17.9,0.6-45.3,0.6-45.3s-1.3-11.9-2.7-18.9s-2.8-9.2-2.8-9.2s1,19.9,1.1,30.6s0.8,31.2-0.6,35.9
c-1.4,4.7-7.2,7.6-9.6,1s-3.5-17.4-5.2-23.4C365.2,436.1,361.4,424.4,361.4,424.4z"/>
<path class="st0" d="M87.9,424.4c0,0-0.1,25.7,4.4,36.8s8.6,16.9,10.3,20.2c1.7,3.2,5.8,7.3,5.5,22.2c-0.3,14.8,1.6,23.4-4,24.1
c-5.5,0.7-7.8-6.2-8.3-9.6c-0.6-3.4-0.4-15-5.8-15.8c0,0-3.5,2.7-6.1,9.5c-2.5,6.8-4.5,13.4-4.5,13.4s2.5,8.5,3,13.4
c0,0,8.9,8.6,9.6,12s0.6,7.1-2.3,11c0,0-1.3,5.2-3.1,7.6l-3.5-3.7c0,0,0.6,7.2-5.2,9.7c0,0-8.3-22.6-9-25.1s-1.6-6.9-2.1-14
c-0.6-7.1-3.5-46.7-3.5-64.7c0-17.9-0.6-45.3-0.6-45.3s1.3-11.9,2.7-18.9c1.4-7.1,2.8-9.2,2.8-9.2s-1,19.9-1.1,30.6
c-0.1,10.7-0.8,31.2,0.6,35.9c1.4,4.7,7.2,7.6,9.6,1s3.5-17.4,5.2-23.4C84,436.1,87.9,424.4,87.9,424.4z"/>
</g>
<g id="Fuss_MB">
<path class="st0" d="M86.8,915.3c0,0-0.2-5.4,3.6-6.8c3.8-1.4,6.9-0.7,6.9-0.7s9.7-8.3,12.6-10.4c2.8-2.1,13.9-9.2,13.9-9.2
s-1.2-4.8,2.3-8.8s6.9-5.3,10.7-5.6c2.1-0.2,5.4,0.3,5.4,0.3s4.1,5,10.6,4.6c6.4-0.3,7.9-3.3,7.9-3.3s0.7,7.9,3.3,16.7
c3.3,10.9,8.9,27.9,7.8,39.3c-1.1,11.4-3.6,14.5-12.4,16.2s-20.1,1.7-25.6-1.2s-9.4-8.6-15.9-10.6s-16.7-4.6-21.1-7.8
c-4.5-3.1-3.6-8.3-3.6-8.3S86.5,918.7,86.8,915.3z"/>
<path class="st0" d="M362.4,915.3c0,0,0.2-5.4-3.6-6.8s-6.9-0.7-6.9-0.7s-9.7-8.3-12.6-10.4c-2.8-2.1-13.9-9.2-13.9-9.2
s1.2-4.8-2.3-8.8s-6.9-5.3-10.7-5.6c-2.1-0.2-5.4,0.3-5.4,0.3s-4.1,5-10.6,4.6s-7.9-3.3-7.9-3.3s-0.7,7.9-3.3,16.7
c-3.3,10.9-8.9,27.9-7.8,39.3c1.1,11.4,3.6,14.5,12.4,16.2s20.1,1.7,25.6-1.2c5.4-2.8,9.4-8.6,15.9-10.6c6.4-2,16.7-4.6,21.1-7.8
c4.5-3.1,3.6-8.3,3.6-8.3S362.7,918.7,362.4,915.3z"/>
</g>
<g id="Waden_MB">
<g>
<g>
<g>
<path class="st0" d="M161.7,719.9c-4.6,13.7-7.1,28.2-8,43.3c-0.6,10.6-0.9,21.3-0.9,32c-5.5,7.2-17.7,4.9-19.3-5.4
c0-0.1,0-0.2-0.1-0.2c-3.9-24.5-6.7-49-3.5-73.6c0-0.1,0.1-0.3,0.1-0.4c2.6-7.7,7.4-21.1,10.7-30.2c0-0.1,0.1-0.1,0.1-0.2
c1.6-3.6,3.2-7.3,4.7-11C156.5,687.1,162.2,703,161.7,719.9z"/>
<path class="st0" d="M193,749.8c0,5.3-1.8,10.7-3.6,15.9c-0.9,2.7-1.8,5.5-2.5,8.1l-1.7,3.9c-4.4,9.9-8.8,20-12.5,30.2
c-5.2,7.1-15.6,5.3-18.7-2l0,0c0-13.2,0.2-26.3,0.6-39.5c0.6-16,3-31.4,8-45.9c0.1-0.2,0.1-0.3,0.2-0.5v-0.2
c3.7-10.7,8.7-20.9,15.3-30.4c1,2.5,1.8,4.9,2.4,7.2c0.5,10.8,4,20.4,7,30.4C190,734.9,192.2,742.4,193,749.8z"/>
</g>
</g>
<g>
<g>
<path class="st0" d="M287.4,719.9c4.6,13.7,7.1,28.2,8,43.3c0.6,10.6,0.9,21.3,0.9,32c5.5,7.2,17.7,4.9,19.3-5.4
c0-0.1,0-0.2,0.1-0.2c3.9-24.5,6.7-49,3.5-73.6c0-0.1-0.1-0.3-0.1-0.4c-2.6-7.7-7.4-21.1-10.7-30.2c0-0.1-0.1-0.1-0.1-0.2
c-1.6-3.6-3.2-7.3-4.7-11C292.5,687.1,286.9,703,287.4,719.9z"/>
<path class="st0" d="M256,749.8c0,5.3,1.8,10.7,3.6,15.9c0.9,2.7,1.8,5.5,2.5,8.1l1.7,3.9c4.4,9.9,8.8,20,12.5,30.2
c5.2,7.1,15.6,5.3,18.7-2l0,0c0-13.2-0.2-26.3-0.6-39.5c-0.6-16-3-31.4-8-45.9c-0.1-0.2-0.1-0.3-0.2-0.5v-0.2
c-3.7-10.7-8.7-20.9-15.3-30.4c-1,2.5-1.8,4.9-2.4,7.2c-0.5,10.8-4,20.4-7,30.4C259.1,734.9,256.9,742.4,256,749.8z"/>
</g>
</g>
</g>
</g>
<g id="Beinbeuger_MB">
<path class="st0" d="M175.4,554.8c-1.3,16.7-3.9,33.4-7.2,49.9c-5.6,27.8-16.9,54-28.3,80c-0.1,0.1-0.1,0.3-0.2,0.4
c-1.2,2.7-2.4,5.4-3.6,8.2c-0.4,0.9-0.8,1.8-1.2,2.7c-0.1-0.4-0.1-0.7-0.1-1c-0.4-5.3,0-8.1,0.3-10.6c0.3-1.9,0.5-3.7,0.3-6.3
c-0.1-1-0.5-4.2-1.1-9.1c-1.3-10.1-3.5-27.7-5.4-47.3l0,0c4.7-33.2,8.4-66.3,21.4-97.6c0.3,2.3,1.3,4.1,3.2,4.9
c3.9,1.5,7.6,0.9,11.6-0.4c3.4-1.1,6.8-2.2,10.2-3.4C177.4,534.7,176.1,545.8,175.4,554.8z"/>
<path class="st0" d="M195.9,581.3c-13.2,34.7,4,73.8-4,109.7c-2.4,5-5.2,10-8.1,14.6c-1.8-7-3.3-12.6-3.3-12.6v0.1
c0-0.2-0.1-0.4-0.1-0.5c-7.2-22.2-20.3-40.8-18-62.6l0,0l0,0c3.3-10.4,6.2-20.9,8.2-31.8c3-15.8,5.2-31.8,6.3-47.9
c0.5-8,1.3-17.3-0.5-25.5c5.8-2,11.5-4,17.4-5.9c1.8-0.6,3.6-1.2,5.4-1.8C191.9,538.3,192.6,559.8,195.9,581.3z"/>
<path class="st0" d="M204.4,624.2c-0.3,3.3-0.6,6.6-0.8,9.7c-1.3,14.8-2.4,28.9-3.7,35.9c-0.8,4.5-3,10.6-5.9,17.1
c5.5-33.9-9-70.5,2.4-103.2C198.5,597.2,201.6,610.7,204.4,624.2z"/>
<path class="st0" d="M273.7,554.8c1.3,16.7,3.9,33.4,7.2,49.9c5.6,27.8,16.9,54,28.3,80c0.1,0.1,0.1,0.3,0.2,0.4
c1.2,2.7,2.4,5.4,3.6,8.2c0.4,0.9,0.8,1.8,1.2,2.7c0.1-0.4,0.1-0.7,0.1-1c0.4-5.3,0-8.1-0.3-10.6c-0.3-1.9-0.5-3.7-0.3-6.3
c0.1-1,0.5-4.2,1.1-9.1c1.3-10.1,3.5-27.7,5.4-47.3l0,0c-4.7-33.2-8.4-66.3-21.4-97.6c-0.3,2.3-1.3,4.1-3.2,4.9
c-3.9,1.5-7.6,0.9-11.6-0.4c-3.4-1.1-6.8-2.2-10.2-3.4C271.6,534.7,273,545.8,273.7,554.8z"/>
<path class="st0" d="M253.1,581.3c13.2,34.7-4,73.8,4,109.7c2.4,5,5.2,10,8.1,14.6c1.8-7,3.3-12.6,3.3-12.6v0.1
c0-0.2,0.1-0.4,0.1-0.5c7.2-22.2,20.3-40.8,18-62.6l0,0l0,0c-3.3-10.4-6.2-20.9-8.2-31.8c-3-15.8-5.2-31.8-6.3-47.9
c-0.5-8-1.3-17.3,0.5-25.5c-5.8-2-11.5-4-17.4-5.9c-1.8-0.6-3.6-1.2-5.4-1.8C257.2,538.3,256.5,559.8,253.1,581.3z"/>
<path class="st0" d="M244.7,624.2c0.3,3.3,0.6,6.6,0.8,9.7c1.3,14.8,2.4,28.9,3.7,35.9c0.8,4.5,3,10.6,5.9,17.1
c-5.5-33.9,9-70.5-2.4-103.2C250.6,597.2,247.4,610.7,244.7,624.2z"/>
</g>
<g id="Innerer_Oberschenkelmuskel_MB">
<path class="st0" d="M221.7,522.8c-0.6,4.8-2.5,13-4.8,22.5c-3.3,14-7.3,31.3-9.3,46.8c-1.1,8.4-2,18.3-2.9,28
c-7.2-34.6-16.2-69.5-4-103.7c7.7-3,14.6-7.1,19.3-14.4c0.3-0.5,0.6-1,0.9-1.6C221.6,504.2,222.9,513.6,221.7,522.8z"/>
<path class="st0" d="M227.4,522.8c0.6,4.8,2.5,13,4.8,22.5c3.3,14,7.3,31.3,9.3,46.8c1.1,8.4,2,18.3,2.9,28
c7.2-34.6,16.2-69.5,4-103.7c-7.7-3-14.6-7.1-19.3-14.4c-0.3-0.5-0.6-1-0.9-1.6C227.5,504.2,226.2,513.6,227.4,522.8z"/>
</g>
<g id="Gesaess_MB">
<path class="st0" d="M221,496.8c-2.7,12.2-16.9,17.5-27.4,20.9h-0.1c-10.2,3.2-20.2,7-30.4,10.2c-19.7,6.2-6.8-24.6-4-32.9
c2.9-8.6,5.5-18.6,3.8-27.8c-0.8-4.4-2-8.1-4.1-12c-4.5-8.6,10.5-13.5,15.4-16.1c11.5-6.2,25.9-15.8,37.5-4.8
c2.5,4,4.8,8.1,6.8,12.4l0,0c1.3,4.5,2,9.2,2.7,13.4C223.1,472.1,223.7,484.8,221,496.8z"/>
<g>
<path class="st0" d="M210.7,432.8c-5.4-4.8-13.1-6.2-24-1.6c-6.8,2.9-13.3,7-19.9,10.6c-1.7,0.9-11,4.2-11.1,7.8l0,0
c-4.6,2.9-8.7,6.4-12.2,10.6c0.1-1.3,0.1-2.6,0-3.7c-1-16.1,4.6-37.6,7-45.8c14.4-10.6,29.7-16.3,43.3,0
C199.9,417.8,205.7,425.1,210.7,432.8z"/>
</g>
<path class="st0" d="M228.1,496.8c2.7,12.2,16.9,17.5,27.4,20.9h0.1c10.2,3.2,20.2,7,30.4,10.2c19.7,6.2,6.8-24.6,4-32.9
c-2.9-8.6-5.5-18.6-3.8-27.8c0.8-4.4,2-8.1,4.1-12c4.5-8.6-10.5-13.5-15.4-16.1c-11.5-6.2-25.9-15.8-37.5-4.8
c-2.5,4-4.8,8.1-6.8,12.4l0,0c-1.3,4.5-2,9.2-2.7,13.4C225.9,472.1,225.4,484.8,228.1,496.8z"/>
<g>
<path class="st0" d="M238.4,432.8c5.4-4.8,13.1-6.2,24-1.6c6.8,2.9,13.3,7,19.9,10.6c1.7,0.9,11,4.2,11.1,7.8l0,0
c4.6,2.9,8.7,6.4,12.2,10.6c-0.1-1.3-0.1-2.6,0-3.7c1-16.1-4.6-37.6-7-45.8c-14.4-10.6-29.7-16.3-43.3,0
C249.2,417.8,243.4,425.1,238.4,432.8z"/>
</g>
</g>
<g id="Unterer_Ruecken_MB">
<path class="st0" d="M177,393c0,0,3.7-1.1,8.8-5.2c0,0,6.5,1.8,9.5,1.1c3-0.7,6.1-4.2,7.6-8.6c1.6-4.4,12-40.2,13.3-53.7
c0,0,2.7-14.3,7.5-14.8s7.5,3.4,8.7,12.8s9.9,44.6,12.6,52.3c2.7,7.8,4.5,11.9,8,12.4c3.5,0.4,6.6-1.1,7.4-1.4s2.4-0.3,2.4-0.3
s4.9,3.7,6.8,4.5c1.8,0.8,1.8,0.8,1.8,0.8s13.8,6.4,16.9,7.8c3.1,1.4,10.1,8.2,10.1,8.2s-5.5-3-7.5-4.1s-10.2-6.3-19.4-5.5
c0,0-4.9-0.3-11,4.9c-6.1,5.2-18.5,21.6-22.2,27.4s-9.3,15.1-10.7,26.8l-6.1,0.3c0,0-1.4-14.8-9.9-25.6
c-8.5-10.7-15.1-23.7-23.7-29.5c0,0-5.5-4.2-10.3-4.4c0,0-5.2-0.1-9.3,1.1c-4.1,1.3-17,8.6-17,8.6s1.9-3.6,6-6.1
S174.1,394.5,177,393"/>
</g>
<g id="Seitlicher_Bauch_MB">
<path class="st0" d="M157.7,311.7c0,0,0,29,0.2,35.2s0.2,15.6,0.2,15.6s-5.8,10-8.1,16.4c-2.3,6.4-4,10.5-2.4,15.6
c1.5,5.1,3.2,13.7,3.2,13.7s6.2-5.6,10-7.3c3.8-1.7,10.4-5.1,13.2-6.2s9.1-4,10.5-5.5c1.4-1.4,1.4-1.4,1.4-1.4l5.7,0.8
c0,0-3.2-4-3.8-7.3c-0.6-3.4-3.4-15.4-5.8-20.1s-8.7-18.4-12.2-25.6C166.1,328.4,157.7,311.7,157.7,311.7z"/>
<path class="st0" d="M291.4,311.7c0,0,0,29-0.2,35.2c-0.2,6.2-0.2,15.6-0.2,15.6s5.8,10,8.1,16.4s4,10.5,2.4,15.6
c-1.5,5.1-3.2,13.7-3.2,13.7s-6.2-5.6-10-7.3c-3.8-1.7-10.4-5.1-13.2-6.2s-9.1-4-10.5-5.5c-1.4-1.4-1.4-1.4-1.4-1.4l-5.7,0.8
c0,0,3.2-4,3.8-7.3c0.6-3.4,3.4-15.4,5.8-20.1c2.4-4.7,8.7-18.4,12.2-25.6S291.4,311.7,291.4,311.7z"/>
</g>
<g id="Aeusseres_Gesaess_MB">
<path class="st0" d="M142,462.1c0,0,9.9-10.7,15.2-12.9c0,0-1.2,2.3,1.3,7.3s4.8,12.1,4.3,17.5s-1,14.5-5.3,24.1s-6.1,23-6.1,23
s-6.4,12.4-12.1,35.7s-11.4,67.4-11.4,67.4s-3.3-34.2-3.3-52.7s2.3-42.9,6.4-60.3c4.1-17.4,7.3-28.6,9.4-37.5
C142.6,464.8,142,462.1,142,462.1z"/>
<path class="st0" d="M307.1,462.1c0,0-9.9-10.7-15.2-12.9c0,0,1.2,2.3-1.3,7.3s-4.8,12.1-4.3,17.5s1,14.5,5.3,24.1s6.1,23,6.1,23
s6.4,12.4,12.1,35.7c5.6,23.3,11.4,67.4,11.4,67.4s3.3-34.2,3.3-52.7s-2.3-42.9-6.4-60.3c-4.1-17.3-7.3-28.6-9.4-37.5
C306.5,464.8,307.1,462.1,307.1,462.1z"/>
</g>
<g id="Unterarm_MB">
<path class="st0" d="M115.2,352.1C115.2,352.1,115.3,352.1,115.2,352.1c-0.4,0.1-0.8,0.2-1.3,0.3c-0.2,0.2-0.4,0.5-0.5,0.7
c-22.3,28.5-30.6,71.2-21.3,106.2l0,0c0.2,0.9,0.5,1.7,0.7,2.5c0.5,0.4,1,0.7,1.6,0.9c-0.5-4.3-0.3-9.4,0.6-16.7
c0.9-7.5,5.5-19.7,10.4-32.6c5.6-14.8,11.4-30,11.7-38.9C117.7,360.9,115.9,354,115.2,352.1z"/>
<path class="st0" d="M91.1,336.3c-3.4,17.4-12,32.1-19.5,48.1c-5.5,11.8-8.1,24.4-9.1,37.2c-0.3-2.8-0.7-5.6-1-8.3
c-0.9-7.7-1.8-15-2.3-22.9c-1.2-20.3,4.7-30.8,5.5-32.1c0-0.1,0-0.1,0.1-0.1c7.3-9.7,18.8-16.8,21.9-28.8c0.6,1.4,1.2,2.7,1.8,4
C89.2,334.9,90.1,335.9,91.1,336.3z"/>
<path class="st0" d="M98.1,376.7c-6.3,14.5-10.1,30.6-10.8,46.5c-2.3,8.4-6,18.5-7.5,26.8c-0.7,3.9-1.2,7.8-2,11.7
c-1.3,6.3-8.8,10.2-9.7,0.8c-2-21.4-0.5-46.3,1-67.5l0,0c1.6-5.1,3.7-10.1,6.1-15.1c5.2-10.6,10.5-20.6,14-31.5
C89.4,358.5,92.4,368.1,98.1,376.7z"/>
<path class="st0" d="M333.8,352.1L333.8,352.1c0.4,0.1,0.8,0.2,1.3,0.3c0.2,0.2,0.4,0.5,0.5,0.7c22.3,28.5,30.6,71.2,21.3,106.2
l0,0c-0.2,0.9-0.5,1.7-0.7,2.5c-0.5,0.4-1,0.7-1.6,0.9c0.5-4.3,0.3-9.4-0.6-16.7c-0.9-7.5-5.5-19.7-10.4-32.6
c-5.6-14.8-11.4-30-11.7-38.9C331.4,360.9,333.2,354,333.8,352.1z"/>
<path class="st0" d="M358,336.3c3.4,17.4,12,32.1,19.5,48.1c5.5,11.8,8.1,24.4,9.1,37.2c0.3-2.8,0.7-5.6,1-8.3
c0.9-7.7,1.8-15,2.3-22.9c1.2-20.3-4.7-30.8-5.5-32.1c0-0.1,0-0.1-0.1-0.1c-7.3-9.7-18.8-16.8-21.9-28.8c-0.6,1.4-1.2,2.7-1.8,4
C359.9,334.9,359,335.9,358,336.3z"/>
<path class="st0" d="M351,376.7c6.3,14.5,10.1,30.6,10.8,46.5c2.3,8.4,6,18.5,7.5,26.8c0.7,3.9,1.2,7.8,2,11.7
c1.3,6.3,8.8,10.2,9.7,0.8c2-21.4,0.5-46.3-1-67.5l0,0c-1.6-5.1-3.7-10.1-6.1-15.1c-5.2-10.6-10.5-20.6-14-31.5
C359.6,358.5,356.7,368.1,351,376.7z"/>
</g>
<g id="Trizeps_MB">
<path class="st0" d="M141.8,265.7L141.8,265.7c-1.7,5-8.2,19.5-13.3,27.7c-3.9,6.3-6.8,17.2-8.2,23.8c-3.3,6.8-7.9,12.2-16.5,15.1
c-1,0.3-2,0.6-3.1,0.9c7.4-12.9,11.4-26.9,16.2-41.1c0.6-1.9,6.8-18.1-0.7-17.3c-7.3,0.8-11.4,13.1-13.8,18.6
c-5.8,12.9-9.4,26.3-10.7,40.3c-2-1.2-3.3-3.5-4.7-6.6c-3-6.8-6.1-16.2-8.2-24c0.4-7,1.7-25.2,3.5-34.3c2.3-11.3,6.7-22.1,6.7-22.2
l0.1-0.4L89,246c10.1-10.2,20.6-17.5,35.2-22.6c2.9-1,5.7-2.1,8.3-3.6C134,227.2,138.2,249.1,141.8,265.7z"/>
<path class="st0" d="M307.3,265.7L307.3,265.7c1.7,5,8.2,19.5,13.3,27.7c3.9,6.3,6.8,17.2,8.2,23.8c3.3,6.8,7.9,12.2,16.5,15.1
c1,0.3,2,0.6,3.1,0.9c-7.4-12.9-11.4-26.9-16.2-41.1c-0.6-1.9-6.8-18.1,0.7-17.3c7.3,0.8,11.4,13.1,13.8,18.6
c5.8,12.9,9.4,26.3,10.7,40.3c2-1.2,3.3-3.5,4.7-6.6c3-6.8,6.1-16.2,8.2-24c-0.4-7-1.7-25.2-3.5-34.3c-2.3-11.3-6.7-22.1-6.7-22.2
l-0.1-0.4l0.1-0.1c-10.1-10.2-20.6-17.5-35.2-22.6c-2.9-1-5.7-2.1-8.3-3.6C315.1,227.2,310.8,249.1,307.3,265.7z"/>
</g>
<g id="Latissimus_MB">
<path class="st0" d="M217.6,308.8c-1,15.6-4.3,31.1-8.1,46.2c-1.6,6.4-3.6,12.7-5.4,19.1c-1.2,4.5-2.6,9.8-6.5,12.8
c-5.7,4.4-8.4-3.9-9.1-8.3c-2.2-12.7-9.2-25.9-14.8-37.5c-5.5-11.5-11.1-23.2-17-34.5c0,0,0,0,0-0.1c-1-2.9-2.3-6.2-3.6-9.6
c-1.7-4.4-3.4-9-4.8-13.1c-1.8-5.4-5.7-24.1-9-40.4c7.7,8.8,28.9,4.8,37.9,1.9c1.6,5.6,4.2,11.1,7.4,16.7c9,15.5,19.8,31,31,45
C216.4,307.8,217,308.4,217.6,308.8z"/>
<path class="st0" d="M231.5,308.8c1,15.6,4.3,31.1,8.1,46.2c1.6,6.4,3.6,12.7,5.4,19.1c1.2,4.5,2.6,9.8,6.5,12.8
c5.7,4.4,8.4-3.9,9.1-8.3c2.2-12.7,9.2-25.9,14.8-37.5c5.5-11.5,11.1-23.2,17-34.5c0,0,0,0,0-0.1c1-2.9,2.3-6.2,3.6-9.6
c1.7-4.4,3.4-9,4.8-13.1c1.8-5.4,5.7-24.1,9-40.4c-7.7,8.8-28.9,4.8-37.9,1.9c-1.6,5.6-4.2,11.1-7.4,16.7c-9,15.5-19.8,31-31,45
C232.7,307.8,232.1,308.4,231.5,308.8z"/>
</g>
<g id="Teres_MB">
<path class="st0" d="M170.6,230.4c-3.4,5.3-6.3,11-8.8,16.8l0,0c-9.7,1-20.1,0-23.2-7.4v-0.1c-1.9-9.5-3.4-17.6-4.1-21
c3-1.9,5.5-4.2,7.3-7.3C149.4,220.5,159.4,226.5,170.6,230.4z"/>
<path class="st0" d="M176.9,216.8c-0.5,2.2-1,4.3-1.3,6.3c-0.1,0-0.2,0.1-0.2,0.2c-1.4,2-2.8,4-4.1,6c-11.2-3.8-21.3-9.8-28.9-19
c0.4-0.8,0.7-1.6,1-2.4c1.5-4.9,2-9.6,1.6-14c0,0,0.1,0,0.1-0.1c6.5-6.8,14-12.1,22.5-15.9c2,0.3,4.1,0.6,6.1,1.1
C189.3,183,179.5,206.4,176.9,216.8z"/>
<path class="st0" d="M278.4,230.4c3.4,5.3,6.3,11,8.8,16.8l0,0c9.7,1,20.1,0,23.2-7.4v-0.1c1.9-9.5,3.4-17.6,4.1-21
c-3-1.9-5.5-4.2-7.3-7.3C299.7,220.5,289.6,226.5,278.4,230.4z"/>
<path class="st0" d="M272.2,216.8c0.5,2.2,1,4.3,1.3,6.3c0.1,0,0.2,0.1,0.2,0.2c1.4,2,2.8,4,4.1,6c11.2-3.8,21.3-9.8,28.9-19
c-0.4-0.8-0.7-1.6-1-2.4c-1.5-4.9-2-9.6-1.6-14c0,0-0.1,0-0.1-0.1c-6.5-6.8-14-12.1-22.5-15.9c-2,0.3-4.1,0.6-6.1,1.1
C259.7,183,269.6,206.4,272.2,216.8z"/>
</g>
<g id="Schulter_MB">
<path class="st0" d="M130.9,219.5c-9.1,4.7-19.1,6.6-27.9,12.2c-5.4,3.5-10.1,8-14.4,12.8c-0.9-2.8-3-9.6-4.2-17.8
c-1.7-10.6,5.7-37.1,10.1-48.3c2.7-6.8,8.2-9.4,13.1-11.6c1.6-0.7,3.2-1.5,4.5-2.3c8.7,1.3,15.9,5.5,23,12.3
C147.4,188.7,145.5,211.9,130.9,219.5z"/>
<path class="st0" d="M318.2,219.5c9.1,4.7,19.1,6.6,27.9,12.2c5.4,3.5,10.1,8,14.4,12.8c0.9-2.8,3-9.6,4.2-17.8
c1.7-10.6-5.7-37.1-10.1-48.3c-2.7-6.8-8.2-9.4-13.1-11.6c-1.6-0.7-3.2-1.5-4.5-2.3c-8.7,1.3-15.9,5.5-23,12.3
C301.6,188.7,303.6,211.9,318.2,219.5z"/>
</g>
<g id="Nacken_MB">
<path class="st0" d="M226.4,107.8c0.2,11.1,1,19.4,7.7,28.8c6.9,9.5,11.6,15.2,22.8,19.7c10.5,4.3,56,14.4,66.3-0.7
c-1-0.6-2-1.5-3.2-2.6c-2.5-2.3-6.4-5.8-14.7-10.6c-4.8-2.8-12-6.1-19.7-9.6c-12.1-5.5-24.7-11.3-28.2-15.4
c-8.2-6.6-13.3-16.5-17.9-25.8c-1-2.1-1.9-4.1-2.9-6.2c-1.1-2.2-2.9-4.5-5.3-5.5c-2.2-0.8-4.5,1.1-4.7,3.2
c-0.3,1.9-0.5,3.8-0.5,5.7C225.9,95.1,226.2,101.5,226.4,107.8z"/>
<path class="st0" d="M223,107.8c-0.2,11.1-1,19.4-7.7,28.8c-6.9,9.5-11.6,15.2-22.8,19.7c-10.5,4.3-56,14.4-66.3-0.7
c1-0.6,2-1.5,3.2-2.6c2.5-2.3,6.4-5.8,14.7-10.6c4.8-2.8,12-6.1,19.7-9.6c12.1-5.5,24.7-11.3,28.2-15.4
c8.2-6.6,13.3-16.5,17.9-25.8c1-2.1,1.9-4.1,2.9-6.2c1.1-2.2,2.9-4.5,5.3-5.5c2.2-0.8,4.5,1.1,4.7,3.2c0.3,1.9,0.5,3.8,0.5,5.7
C223.5,95.1,223.1,101.5,223,107.8z"/>
</g>
<g id="Trapezmuskel_MB">
<path class="st0" d="M221.9,280.9c-0.1,7.2-0.6,14.4-0.5,21.6c0.1,5.7-7.4,0.1-9.3-2.6c-2.6-3.7-5.4-7.3-8-11.1
c-8.1-11.8-16.8-23.4-23.2-36.3c-8.8-17.8-2.6-34.4,1.4-52.3c1.6-7.4,3.2-15.8-3.7-20.6c-3.7-2.6-9.5-3-13.8-3.6
c-2-0.3-4-0.4-6.1-0.5c-6-0.4-20.4-1.1-18.8-11.7c12.3,2.1,28.2-0.3,35.3-1.4c8-1.2,15.7-3.7,22.6-7.5c11.7,6.5,20,16.4,23.4,29.8
c4.2,16.4,0.9,34.4,0.3,51C221,250.7,222.1,265.9,221.9,280.9z"/>
<path class="st0" d="M227.2,280.9c0.1,7.2,0.6,14.4,0.5,21.6c-0.1,5.7,7.4,0.1,9.3-2.6c2.6-3.7,5.4-7.3,8-11.1
c8.1-11.8,16.8-23.4,23.2-36.3c8.8-17.8,2.6-34.4-1.4-52.3c-1.6-7.4-3.2-15.8,3.7-20.6c3.7-2.6,9.5-3,13.8-3.6c2-0.3,4-0.4,6.1-0.5
c6-0.4,20.4-1.1,18.8-11.7c-12.3,2.1-28.2-0.3-35.3-1.4c-8-1.2-15.7-3.7-22.6-7.5c-11.7,6.5-20,16.4-23.4,29.8
c-4.2,16.4-0.9,34.4-0.3,51C228.1,250.7,226.9,265.9,227.2,280.9z"/>
</g>
<g id="Kontur">
<g>
<g>
<g>
<g>
<g>
<path class="st1" d="M195.2,105.3c0-0.1-3.7-7.3-4-8.3c-1-3.4-1.1-8-1-10.8c-0.6,0.3-1.3,0.4-2.1,0.3
c-2.8-0.3-5.4-4.7-8.5-10.3c-0.9-1.6-1.7-3.1-2.3-4c-2.7-3.9-2.1-12.2-0.1-18.5c0.6-1.8,1.6-2.9,2.9-3.1
c1.9-0.3,3.9,1.1,5.4,2.4c-0.3-7.8-0.2-29.8,8.8-40.6C203.7,1,224.8,0,225.7,0l0.1,2c-0.2,0-21.1,0.9-30,11.6
c-10.3,12.4-8.3,41.7-8.2,42l0.2,3.3l-2.1-2.6c-1.4-1.7-3.9-4-5.4-3.8c-0.6,0.1-1,0.7-1.3,1.7c-2.1,6.6-2.2,13.8-0.1,16.7
c0.7,1,1.5,2.5,2.5,4.2c1.9,3.4,5,9.1,7,9.3c1.3,0.1,2-1.1,2-1.1l2.2-4.3l-0.3,4.8c0,0.1-0.5,7.8,0.9,12.6
c0.2,0.6,2.6,5.3,3.1,6.2L195.2,105.3z"/>
</g>
<g>
<path class="st1" d="M143.4,267.1c-0.3-1-0.5-2-0.7-3h-0.6c0,2-7.8,19.8-13.7,29.3c-5.8,9.2-9.1,28.4-9.3,29.4
c0,0.1,0.3,11.5-0.7,17.8c-1,6-5.1,10.6-5.2,10.6l-0.4,0.5l0.2,0.6c0,0.1,2.6,6.5,2,22.3c-0.4,8.5-6.1,23.7-11.6,38.3
c-4.9,13.1-9.6,25.4-10.6,33.1c-2.3,18.6-0.2,24.6,7.8,34.5c5.1,6.3,5.5,13.5,6.1,23.5l0.2,2.7c0.1,8.7-0.6,18.7-1.9,19.9
c-0.4,0.4-0.9,0.5-1.4,0.4c-0.9-0.1-2.7-0.9-5.1-4.8c-1.7-2.6-2.1-6.2-2.6-9.7c-0.6-4.5-1.2-8.7-4.1-10.6
c-0.7-0.5-1.5-0.5-2.3-0.2c-4.9,1.8-10.2,20.3-11.2,23.8l1.9-0.1c2.3-7.8,7-20.7,10-21.8c0.2-0.1,0.4-0.1,0.5,0
c2.2,1.4,2.7,5.2,3.2,9.1c0.5,3.7,1,7.6,2.9,10.6c2.2,3.5,4.4,5.4,6.6,5.7c0.2,0,0.4,0,0.5,0c0.9,0,1.8-0.3,2.5-1
c2.5-2.3,2.6-17,2.5-21.5l-0.2-2.7c-0.6-10.3-1-17.8-6.5-24.6c-7.7-9.5-9.6-15-7.4-33c0.9-7.5,5.5-19.7,10.4-32.6
c5.6-14.8,11.4-30,11.7-38.9c0.6-13.7-1.2-20.6-1.9-22.5c1.2-1.4,4.4-5.7,5.3-11.2c1-6.5,0.7-17.7,0.7-17.9
c0-0.2,3.4-19.6,9-28.4C134.8,287.1,141.2,273.5,143.4,267.1z"/>
</g>
<g>
<path class="st1" d="M77.2,576.3l-0.3-0.9c-0.4-1-9.2-24.2-10.2-28.9c-1.1-5.2-5.4-58.1-4.4-86.3c0.7-18.5-1.1-32.8-2.8-46.6
c-0.9-7.8-1.8-15.1-2.3-23.1c-1.3-20.8,4.6-31.4,5.8-33.2V341c0-15.9,11.9-33.2,13.6-35.6c0.2-3,1.5-26.2,3.7-37.1
c2.1-10.3,5.9-20.2,6.7-22.2c-0.6-1.8-3.2-9.6-4.7-19.1c-1.7-11,5.7-37.9,10.2-49.3c3-7.5,9.2-10.4,14.2-12.7
c2.7-1.2,5-2.3,6.3-3.8c3.4-3.9,6.7-5,9.7-5.9c0.8-0.2,1.5-0.5,2.2-0.8c1.1-0.5,2.1-1.4,3.7-2.7c2.6-2.3,6.5-5.9,14.8-10.7
c4.8-2.8,12-6.1,19.6-9.6c11.4-5.2,24.2-11.1,27.2-14.8c2.9-3.5,8.7-26.5,8.7-26.5c-0.1,0.9-3.3,23.1-7.1,27.8
c-3.3,4.1-15.8,9.8-28,15.4c-7.5,3.5-14.7,6.7-19.4,9.5c-8.2,4.7-12,8.2-14.5,10.5c-1.6,1.5-2.8,2.5-4.3,3.1
c-0.8,0.3-1.6,0.6-2.4,0.8c-2.8,0.9-5.8,1.8-8.7,5.3c-1.6,1.9-4.2,3.1-7,4.4c-4.9,2.3-10.4,4.8-13.1,11.6
c-4.4,11-11.8,37.4-10.1,48.1c1.7,10.6,4.7,19,4.7,19.1l0.1,0.4l-0.1,0.4c0,0.1-4.4,10.9-6.7,22.2
c-2.3,11.3-3.7,36.9-3.7,37.1v0.3l-0.2,0.2C78.2,306.6,65,324.9,65,341v16.9l-0.2,0.3c-0.1,0.1-6.9,10.5-5.6,32.2
c0.5,7.9,1.4,15.2,2.3,22.9c1.7,13.9,3.4,28.3,2.8,47c-1,29.2,3.5,81.6,4.4,85.8c0.9,4.1,8,23.1,9.7,27.7
c1.3-0.7,3.5-2.6,3.5-7.1c0-0.2,0-0.5,0-0.8l2-0.1c0,0.3,0,0.6,0,0.8c0,7.8-5.8,9.4-5.8,9.5L77.2,576.3z"/>
</g>
<g>
<path class="st1" d="M88.7,561.9L88.7,561.9c-0.5,2.4-1.6,4.5-2.3,5.6l-16.5-16.9l-1.5,1.4l18.3,18.7l0.7-1
c0.2-0.2,2.9-4.1,3.4-8.7C90.2,561.5,89.5,561.9,88.7,561.9z"/>
</g>
<g>
<path class="st1" d="M89.4,563.6l-0.8-0.6c-0.1-0.1-6.9-5.8-13.4-12.5c-5.5-5.7-10.1-15.3-10.1-18.2h2c0,2,4,11,9.5,16.8
c5,5.3,10.4,9.9,12.4,11.7c1.3-1.9,4-7,0.9-11.8c-1.6-2.4-8.3-9.3-8.4-9.3l-0.3-0.3V539c0-0.1-0.5-7.7-3-13.6l1.9-0.8
c2.3,5.5,3,12.2,3.1,13.9c1.3,1.3,6.9,7,8.4,9.4c4.7,7.2-1.5,14.9-1.6,15L89.4,563.6z"/>
</g>
<g>
<path class="st1" d="M158.5,312.7c-0.6-3.3-2.9-9.4-5.4-15.8c-1.7-4.4-3.4-9-4.8-13.1c-3.1-9.2-12.4-57.9-13.9-65.7
c-0.6,0.4-1.2,0.7-1.9,1c1.8,9.5,10.8,56.1,13.9,65.4c1.4,4.1,3.1,8.7,4.8,13.2c2.4,6.3,4.7,12.3,5.3,15.5
c0.6,3.1,0.7,18.6,0.5,46.3c0,1.4,0,2.4,0,2.7c-0.1,0.5-1.2,2.6-2.3,4.4c-3.5,6.2-9.4,16.6-8.3,25.4
c1.2,9.8,2.7,13.9,3.2,15.1c-1.1,3.5-9.3,30.3-8,49.6c0.4,6.7-2.2,17.2-5.6,30.6c-4.3,17.2-9.7,38.5-11.5,63.2
c-2.7,36.5,5,96.3,7.9,118.9c0.6,4.8,1,8,1.1,9c0.1,2.4-0.1,4-0.3,5.9c-0.3,2.6-0.7,5.5-0.3,11c0.1,1.8,0.6,3.9,1.5,6.5
c0.4-0.9,0.9-1.7,1.3-2.5c-0.4-1.6-0.7-2.9-0.8-4.1c-0.4-5.3,0-8.1,0.3-10.6c0.3-1.9,0.5-3.7,0.3-6.3c-0.1-1-0.5-4.3-1.1-9.1
c-2.9-22.5-10.5-82.2-7.9-118.5c1.8-24.5,7.2-45.7,11.5-62.8c3.4-13.5,6.1-24.1,5.6-31.2c-1.3-20.2,8-49,8.1-49.3l0.1-0.4
l-0.2-0.4c0,0-1.8-3.4-3.2-14.8c-1-8.1,4.7-18.1,8.1-24.1c1.7-3.1,2.5-4.5,2.5-5.4c0-0.3,0-1.3,0-2.7
C159.1,349,159.4,317.5,158.5,312.7z"/>
</g>
<g>
<path class="st1" d="M127.8,898.8c-0.3-0.5-0.5-1-0.8-1.5c-1.9-3.4-5.9-10.5-2.9-15.8c0.1-0.3,0.3-0.6,0.5-0.9
c1.2-1.9,3.3-5.4,5.3-13.1c3.1-11.6,1.6-24.4,0.3-36.7l-0.2-1.6c-1.1-6.8-2.4-13.8-3.6-20.6c-5.8-31.2-11.8-63.4,1.7-93.8
c4.1-12.5,9.9-27.4,16.1-38.9c0,0-10.1,27.2-14.2,39.7c-13.3,29.9-7.6,60.4-1.6,92.7c1.3,6.8,2.6,13.8,3.7,20.7l0.2,1.6
c1.4,12.5,2.8,25.4-0.3,37.4c-2.1,7.9-4.4,11.6-5.6,13.6c-0.2,0.3-0.4,0.6-0.5,0.8C123.5,886.8,127.8,898.8,127.8,898.8z"/>
</g>
<g>
<path class="st1" d="M148,950c-5.1,0-9.4-0.7-11.4-1.5s-5.3-3-8.7-5.4c-3.7-2.5-7.8-5.4-10-6c-6.3-1.9-21.3-6.6-23.7-10.3
c-1.7-2.6-2-4.9-2-6.1c-1.8-0.5-6-2.1-6-5.5c0-3.6,0.9-5,1.7-5.9c1.1-1.2,4.8-2.8,9.6-2l-0.3,2c-4.2-0.7-7.1,0.8-7.7,1.4
c-0.4,0.4-1.2,1.3-1.2,4.5c0,2.6,5.2,3.8,5.2,3.8l1,0.2l-0.2,1c0,0.1-0.4,2.5,1.6,5.6c1.6,2.4,12.5,6.5,22.6,9.5
c2.5,0.7,6.6,3.6,10.6,6.3c3.2,2.2,6.5,4.5,8.3,5.2c4.1,1.6,19.3,3,28.8-3c9.4-5.9,2.2-33.1,2.1-33.3
c-0.4-1.4-0.8-2.9-1.2-4.5c-1.3-5-2.9-10.8-4.4-15.3l-0.1-0.3c0-2.3,1.1-4.4,2.1-6.6c1-1.9,1.9-3.8,1.9-5.5
c-9.8-35.4,4.7-68.4,18.7-100.3l1.7-3.9c0.6-2.6,1.6-5.4,2.5-8c1.8-5.3,3.6-10.7,3.6-15.9c-0.8-7.5-3.1-14.9-5.4-22.9
c-3.3-11-7.2-21.6-7-34c0,0,5.8,22.6,9,33.4c2.4,8,4.6,15.6,5.5,23.3c0,5.7-1.9,11.3-3.7,16.7c-0.9,2.6-1.8,5.4-2.5,8.1
l-1.8,4.1c-13.9,31.6-28.2,64.3-18.6,99.1v0.3c0,2.3-1.1,4.4-2.1,6.6c-1,1.9-1.9,3.7-1.9,5.5c1.5,4.6,3,10.3,4.4,15.3
c0.4,1.6,0.8,3.1,1.2,4.5c0.3,1.2,7.7,28.9-3,35.6C161.5,948.9,154.2,950,148,950z"/>
</g>
<g>
<path class="st1" d="M184.5,708.4l-1.7-1.1c8-12.2,15.3-28.3,17-37.4c1.3-7,2.5-21,3.7-35.9c1.2-13.8,2.5-29.3,4.1-41.8
c2-15.4,6.1-32.8,9.3-46.8c2.2-9.5,4.1-17.6,4.8-22.5c1.5-11.7-1-23.9-1-24l1.8-2.8c0.1,0.5,2.7,14.9,1.2,27
c-0.6,5-2.6,13.2-4.8,22.7c-3.3,13.9-7.3,31.2-9.3,46.6c-1.6,12.4-2.9,28-4.1,41.7c-1.3,15.5-2.4,29-3.7,36.1
C200.1,679.7,192.8,695.8,184.5,708.4z"/>
</g>
<g>
<path class="st1" d="M83.9,509.9l0.6,0.3c0.3-0.5,0.5-1.1,0.8-1.6c0.5-0.8,1.1-1.4,1.6-2.1c0-0.1,0.1-0.3,0.2-0.4
c1-1.1,2.1-2.3,3.1-3.4c0.2-0.2,0.4-0.3,0.6-0.3c1.9-2.4,4.1-4.5,6.5-6.4C97.4,496.1,86.6,500.5,83.9,509.9z"/>
</g>
</g>
<g>
<path class="st1" d="M91.7,912c0.6-0.6,14.2-13.9,21.8-18.2c7.3-4.1,9.7-6.9,9.8-7l1.6,1.3c-0.1,0.1-2.6,3.1-10.3,7.4
c-3.7,2.1-9,6.5-13.4,10.5C96.7,910,91.7,912,91.7,912z"/>
</g>
<g>
<path class="st1" d="M112,163.2c-0.5,0.4-1,0.7-1.5,1.1c9.4,1,17,5.3,24.6,12.5c12.4,11.9,10.4,35.1-4.2,42.7
c-9.1,4.7-19.1,6.6-27.9,12.2c-5.7,3.6-10.6,8.5-15.1,13.5c0.2,0.5,0.4,1,0.6,1.5c10.3-10.5,21-18,35.8-23.3
c8.1-2.9,16.3-6.7,19.1-15.6C150.7,184.4,133.7,165.9,112,163.2z"/>
</g>
<g>
<path class="st1" d="M123.9,308.5c-4,10.7-8.1,19.7-20.2,23.8c-1.8,0.6-3.7,1.1-5.5,1.6c-6.7,1.8-8.9-1.3-11.4-6.9
c-3.6-8-7-19.4-9.2-27.8c0-0.2-0.1-0.3-0.2-0.3c-0.3,0.9-0.4,1.8-0.5,2.7c2.8,10.2,7,22.5,11.5,31.8c3,6.4,8.6,2.1,13.9,0.5
c10.9-3.1,16.1-9.8,20-18.4C122.6,313.1,123.1,310.7,123.9,308.5z"/>
</g>
<g>
<path class="st1" d="M116.1,274.7c-7.3,0.8-11.3,13.1-13.8,18.6c-5.9,13-9.5,26.6-10.8,40.9c-0.1,0.8,1.2,0.8,1.2,0
c1.6-18.4,7.3-37.5,17.4-53.2c1.1-1.8,2.5-3.3,4.2-4.5c7-5.1,2.8,10.9,2.6,11.7c-1.2,3.9-2.5,7.7-3.7,11.5
c-3.7,12.3-8.2,24.2-14.9,35.1c0.1,0,0.3,0,0.4-0.1c0.4-0.2,0.9-0.3,1.3-0.4c7.8-13.2,11.9-27.6,16.8-42.2
C117.4,290.2,123.6,273.9,116.1,274.7z"/>
</g>
<g>
<path class="st1" d="M86,326.6c-1.8,13.2-13.9,20.6-21.8,30.6c0,0.1-0.1,0.3-0.2,0.4c-2.4,3.5-3.8,7.5-4.9,11.5
c0.1-0.1,0.2-0.2,0.3-0.4c4.3-16.5,23.8-23.8,27.4-40.2C86.5,328,86.3,327.3,86,326.6z"/>
</g>
<g>
<path class="st1" d="M91.5,334c-3,18.5-12.1,33.8-19.9,50.5c-7.5,16-9.7,33.6-9.5,51c0,0.8,1.3,0.8,1.2,0
c-0.2-19.7,3.2-37.9,11.9-55.7c7.4-15,14.8-28.8,17.5-45.5C92.9,333.5,91.7,333.2,91.5,334L91.5,334z"/>
</g>
<g>
<path class="st1" d="M106.3,362.2c-6.8-7.3-10.3-16.4-10.2-26.4c0-0.8-1.2-0.8-1.2,0c-0.1,10.4,3.7,19.9,10.8,27.5
C105.9,363,106.1,362.6,106.3,362.2z"/>
</g>
<g>
<path class="st1" d="M90.5,343.2c0-0.2,0-0.3-0.1-0.4c-0.4,0.8-0.8,1.6-1.2,2.4c-0.4,11.5,2.7,22.4,9.1,31.9
c0.2-0.4,0.4-0.9,0.6-1.3C92.6,366.1,89.7,355,90.5,343.2z"/>
</g>
<g>
<path class="st1" d="M114.7,351.6c-0.1-0.5-0.2-1-0.3-1.6c-25.8,31.1-34.5,80.4-20.8,118.2c0.3,0.7,1.5,0.4,1.2-0.3
C81.3,430.8,89.6,382.3,114.7,351.6z"/>
</g>
<g>
<path class="st1" d="M87.8,421.7c-0.1,0.1-0.2,0.2-0.2,0.3c-2.2,8.6-6.2,19.3-7.8,28c-0.7,3.9-1.2,7.8-2,11.7
c-1.3,6.3-8.8,10.2-9.6,0.8c-2-21.4-0.5-46.4,1-67.6c0.1-0.8-1.6,1.4-1.6,2.2c-1.1,15.6-1.6,31.2-1.2,46.9
c0.1,2.8-0.9,25.2,3.3,26.5c4.3,1.4,8-2.9,9.1-6.2c1.8-5.4,2-11.7,3-17.2c1.3-6.7,4.4-15.1,6.5-22.1
C87.9,424,87.8,422.9,87.8,421.7z"/>
</g>
<g>
<path class="st1" d="M223.7,93.3c0.1-4.4,0.9-11.9-3.6-14.5c-4.3-2.6-8.7,6.5-9.8,8.6c-6,12.4-11.9,26.3-24.6,33.1
c-0.7,0.4-0.1,1.4,0.6,1.1c11.2-6.1,17.6-18,23-29.1c1-2,1.9-4.1,2.9-6.1c1.1-2.2,2.9-4.5,5.2-5.4c2.2-0.8,4.4,1,4.6,3.2
c0.3,1.8,0.4,3.7,0.5,5.6c0.2,6.3-0.1,12.6-0.3,18.8c-0.2,11-1,19.2-7.6,28.4c-6.8,9.4-11.5,15-22.5,19.4
c-10.6,4.3-57.2,14.7-66-1.7c-0.3,0.3-0.7,0.5-1,0.7c7.6,13.4,39.3,8.6,50.2,6.9c21.8-3.4,41.5-15.9,47.3-38.4
C225.1,114.4,223.4,103.1,223.7,93.3z"/>
</g>
<g>
<path class="st1" d="M221.6,181c-3.1-12-12.5-21.3-23.1-27.1c-0.4,0.3-0.8,0.5-1.2,0.7c12,6.5,20.5,16.5,24,30.2
c4.2,16.4,0.9,34.4,0.3,51c-0.6,15,0.6,30.1,0.4,45.2c-0.1,7.2-0.6,14.4-0.5,21.6c0.1,5.7-7.4,0.1-9.3-2.7
c-2.6-3.7-5.4-7.3-8-11.1c-8.1-11.8-16.8-23.4-23.2-36.3c-8.8-17.8-2.6-34.4,1.4-52.3c1.6-7.4,3.2-15.8-3.7-20.6
c-3.7-2.6-9.5-3-13.8-3.7c-2-0.3-4-0.4-6.1-0.5c-6.1-0.4-20.8-1.1-18.7-12.1c-0.4-0.1-0.8-0.1-1.2-0.2
c-1.2,6.6,3.7,11.4,10.8,12.9c7.9,1.7,16,1,24,3c15.7,4,5.9,27.4,3.3,37.8c-4.2,16.6-0.8,30.6,7.7,45.2c9,15.5,19.8,31,31,45
c4.7,5.8,7.1-0.2,7-4.3c-0.5-21,0.2-42.2,0.1-63.2C222.6,219.7,226.5,200.3,221.6,181z"/>
</g>
<g>
<path class="st1" d="M136.6,237.5c2.4,16.3,30.4,11.2,40.8,7.7c0.7-0.2,0.4-1.4-0.3-1.2c-9.9,3.3-37.1,8.4-39.3-6.8
C137.7,236.4,136.5,236.7,136.6,237.5L136.6,237.5z"/>
</g>
<g>
<path class="st1" d="M217.8,306c-0.8,16.5-4.2,33-8.3,49c-1.6,6.4-3.6,12.7-5.4,19.1c-1.2,4.5-2.6,9.8-6.5,12.8
c-5.7,4.4-8.4-3.9-9.2-8.3c-2.2-12.7-9.2-25.9-14.8-37.4c-6.6-13.7-13.2-27.8-20.5-41.1c-0.4-0.7-1.4-0.1-1.1,0.6
c4.6,8.6,8.1,17.7,12.5,26.4c5.1,10,10.5,19.6,14.8,30c3.9,9.4,6.8,18.3,8.6,28.3c0.6,3.1,1.7,4.1,4.7,4.4
c10.7,1.3,13-17.3,14.8-23.7c5.7-19.5,10.4-39.6,11.4-60C219,305.2,217.8,305.2,217.8,306L217.8,306z"/>
</g>
<g>
<path class="st1" d="M176.2,223.1c-0.3-0.1-0.6-0.1-0.8,0.2c-5.5,7.5-10,15.6-13.7,24.2c-0.1,0.3,0,0.5,0.1,0.7
c0.3,0,0.6,0,0.9,0l0,0c3.6-8.4,8-16.2,13.3-23.6C176.1,224,176.1,223.6,176.2,223.1z"/>
</g>
<g>
<path class="st1" d="M171.8,229.5c-11.9-3.9-22.5-10.3-30.3-20.3c-0.5-0.6-1.3,0.3-0.9,0.9c7.8,10.1,18.6,16.5,30.6,20.5
C171.4,230.2,171.6,229.8,171.8,229.5z"/>
</g>
<g>
<path class="st1" d="M167.2,176.7c-8.7,3.9-16.4,9.3-23,16.2c-0.5,0.6,0.3,1.4,0.9,0.9c6.6-6.9,14.3-12.3,23-16.1
c0.4-0.2,0.4-0.6,0.1-0.9C167.8,176.8,167.5,176.7,167.2,176.7z"/>
</g>
<g>
<path class="st1" d="M185.9,387.2c-11.1,8.9-27.6,10.9-36.3,22.1c-0.2,1-0.4,2-0.6,3c7.8-13.3,26.1-14.9,37.7-24.3
C187.4,387.6,186.5,386.8,185.9,387.2z"/>
</g>
<g>
<path class="st1" d="M193.5,388.5c-11.6-0.6-19.9-9.9-28.6-16.7c-3.6-2.9-5.7-7-6.7-11.5c-0.2,0.4-0.4,0.9-0.6,1.3
c1,3.2,2.5,6.4,4.7,9.2c4.1,5.3,11.3,9.9,16.7,13.7c4.6,3.2,8.9,4.8,14.5,5.1C194.3,389.7,194.3,388.5,193.5,388.5z"/>
</g>
<g>
<path class="st1" d="M193.5,517.7c-10.2,3.2-20.2,7-30.4,10.2c-19.7,6.2-6.8-24.6-4.1-32.9c2.9-8.6,5.5-18.6,3.8-27.8
c-0.8-4.4-2.1-8.1-4.1-12c-4.5-8.6,10.5-13.5,15.4-16.1c11.6-6.3,26.4-16.1,38-4.3c6.2,6.3,7.6,16.9,9,25.2
c2,12.1,2.5,24.7-0.2,36.7C218.3,509,204,514.3,193.5,517.7c-0.7,0.2-0.4,1.4,0.3,1.2c10.2-3.3,20.1-7.4,26.2-16.8
c6.4-9.9,4.6-27.4,2.9-38.2c-3.2-21.1-10.6-43.5-36.3-32.7c-6.9,2.9-13.3,7-19.9,10.6c-1.7,0.9-11.5,4.4-11,8.1
c0.9,7.3,5.5,13,6.3,20.7c1,10.5-3.1,21.3-6.5,31.1c-1.5,4.3-9.9,24.3-2,27.3c3.9,1.5,7.6,0.8,11.6-0.4
c9.6-3,19.1-6.6,28.7-9.7C194.6,518.6,194.3,517.5,193.5,517.7z"/>
</g>
<g>
<path class="st1" d="M216.1,440.5c-7.4-15.3-15.5-24.5-25.5-35.5c-12-13.1-28.8-4.1-40.5,4.5c0,0,0,0.1-0.1,0.1
c0,0,0,0.1-0.1,0.1c-0.2,0.6-0.4,1.2-0.6,1.8c14.8-11.2,30.6-17.7,44.6-0.9c9.5,11.4,18.5,23,24.8,36.4
C218.2,445.1,217,442.2,216.1,440.5z"/>
</g>
<g>
<path class="st1" d="M150.5,521c-0.1,0-0.2,0.1-0.3,0.3c-14,32.5-17.6,66.8-22.6,101.4c0.3,0.8,0.6,1.6,0.9,2.4
c5.1-34.8,8.5-69.4,22.4-102.1C150.7,522.3,150.5,521.7,150.5,521z"/>
</g>
<g>
<path class="st1" d="M176.4,524.3c-0.4,0.1-0.7,0.3-1.1,0.4c2.2,9.6,0.8,20.9,0.2,30.2c-1.3,16.7-3.9,33.4-7.2,49.8
c-6.5,32.5-21,62.9-34.2,93.3c0.2,0.5,0.4,1.1,0.6,1.6c14.2-33.1,29.3-65.8,36.1-101.4c3-15.8,5.2-31.8,6.3-47.9
C177.5,542.2,178.3,532.6,176.4,524.3z"/>
</g>
<g>
<path class="st1" d="M157.5,448.5c-6.1,3.5-11.2,8-15.5,13.6c-0.5,0.6,0.6,1.2,1.1,0.6c3.9-5.1,8.6-9.3,14-12.6
C157.1,449.6,157.2,449.1,157.5,448.5z"/>
</g>
<g>
<path class="st1" d="M205.8,625.3c-7.4-36.6-18.1-73.6-4.7-109.8c-0.5,0.2-1,0.5-1.5,0.7c-13.5,37.7-1.2,76.4,6.1,114.4
C205.7,628.8,205.7,627,205.8,625.3z"/>
</g>
<g>
<path class="st1" d="M166.9,832.9c0.3-2.2,0.7-4.3,1.4-6.4c-10.4,22.9-11.4,48.1-2.7,71.8c0.3,0.7,1.4,0.4,1.2-0.3
C158.8,876.5,158.9,853.9,166.9,832.9z"/>
</g>
<g>
<path class="st1" d="M153.7,840.5c0,0,0.6-10.6,0.5-16.2c-0.3-19.3-0.2-38.6,0.4-57.8c1-28.2,8-54.7,23.8-77.4
c-0.2-0.5-0.3-0.9-0.5-1.4c-15.6,22.2-22.7,47.8-24.3,75.6c-1.3,21.6-0.9,43.4-0.5,64.9C153.2,832.4,153.4,836.4,153.7,840.5z"
/>
</g>
<g>
<path class="st1" d="M128.9,713.7c-9.4,66.6,26.1,133,7.9,198.8c-0.2,0.8,3.5-9.1,4.3-13.8c10.3-61.3-19.8-122.9-11-184.7
C130.2,713.3,129,713,128.9,713.7L128.9,713.7z"/>
</g>
<g>
<path class="st1" d="M161.5,723c0.4-1,0.9-2,1.4-3c0.5-17.6-5.5-34.2-17.2-47.5c-0.2,0.4-0.3,0.8-0.5,1.3
C157.1,687.6,162.8,704.8,161.5,723z"/>
</g>
<g>
<path class="st1" d="M180.5,692.6c-7.4-22.6-20.9-41.6-17.8-64c-0.4,1-0.9,1.9-1.3,2.9c-1.2,24.2,15.2,49,19.8,66.5
C181,696.9,180.8,693.7,180.5,692.6z"/>
</g>
<g>
<path class="st1" d="M153.1,794.9c-5.3,7.6-18,5.4-19.6-5.1c-0.1-0.8-1.3-0.4-1.2,0.3c1.7,11.1,14.5,13.5,20.8,6.6
C153.1,796.1,153.1,795.5,153.1,794.9z"/>
</g>
<g>
<path class="st1" d="M153.6,804.7c2.6,8.9,15.3,11,20.2,1.6c0.4-0.7,1.4,0,1,0.7c-5.2,9.9-18.1,8-21.8-0.6
C153.2,805.8,153.5,805.2,153.6,804.7z"/>
</g>
<g>
<path class="st1" d="M141.7,851.7c-2.8,10.5-8.2,19.8-16.2,27.3c-0.6,0.5,0.3,1.4,0.9,0.9c7.2-6.8,12.4-15,15.4-24.3
C141.8,854.3,141.7,853,141.7,851.7z"/>
</g>
<g>
<path class="st1" d="M191.4,693.5c0.7-1.1,1.3-2.3,2-3.4c7-35-8.9-73,3.1-106.8c-0.2-0.7-0.3-1.4-0.5-2.2
C182.4,616.6,200.8,656.9,191.4,693.5z"/>
</g>
</g>
<g>
<g>
<g>
<path class="st1" d="M253.9,105.3c0-0.1,3.7-7.3,4-8.3c1-3.4,1.1-8,1-10.8c0.6,0.3,1.3,0.4,2.1,0.3c2.8-0.3,5.4-4.7,8.5-10.3
c0.9-1.6,1.7-3.1,2.3-4c2.7-3.9,2.1-12.2,0.1-18.5c-0.6-1.8-1.6-2.9-2.9-3.1c-1.9-0.3-3.9,1.1-5.4,2.4
c0.3-7.8,0.2-29.8-8.8-40.6C245.4,1,224.3,0,223.4,0l-0.1,2c0.2,0,21.1,0.9,30,11.6c10.3,12.4,8.3,41.7,8.2,42l-0.2,3.3
l2.1-2.6c1.4-1.7,3.9-4,5.4-3.8c0.6,0.1,1,0.7,1.3,1.7c2.1,6.6,2.2,13.8,0.1,16.7c-0.7,1-1.5,2.5-2.5,4.2
c-1.9,3.4-5,9.1-7,9.3c-1.3,0.1-2-1.1-2-1.1l-2.2-4.3l0.3,4.8c0,0.1,0.5,7.8-0.9,12.6c-0.2,0.6-2.6,5.3-3.1,6.2L253.9,105.3z"
/>
</g>
<g>
<path class="st1" d="M305.6,267.1c0.3-1,0.5-2,0.7-3h0.6c0,2,7.8,19.8,13.7,29.3c5.8,9.2,9.1,28.4,9.3,29.4
c0,0.1-0.3,11.5,0.7,17.8c1,6,5.1,10.6,5.2,10.6l0.4,0.5l-0.2,0.6c0,0.1-2.6,6.5-2,22.3c0.4,8.5,6.1,23.7,11.6,38.3
c4.9,13.1,9.6,25.4,10.6,33.1c2.3,18.6,0.2,24.6-7.8,34.5c-5.1,6.3-5.5,13.5-6.1,23.5l-0.2,2.7c-0.1,8.7,0.6,18.7,1.9,19.9
c0.4,0.4,0.9,0.5,1.4,0.4c0.9-0.1,2.7-0.9,5.1-4.8c1.7-2.6,2.1-6.2,2.6-9.7c0.6-4.5,1.2-8.7,4.1-10.6c0.7-0.5,1.5-0.5,2.3-0.2
c4.9,1.8,10.2,20.3,11.2,23.8l-1.9-0.1c-2.3-7.8-7-20.7-10-21.8c-0.2-0.1-0.4-0.1-0.5,0c-2.2,1.4-2.7,5.2-3.2,9.1
c-0.5,3.7-1,7.6-2.9,10.6c-2.2,3.5-4.4,5.4-6.6,5.7c-0.2,0-0.4,0-0.5,0c-0.9,0-1.8-0.3-2.5-1c-2.5-2.3-2.6-17-2.5-21.5
l0.2-2.7c0.6-10.3,1-17.8,6.5-24.6c7.7-9.5,9.6-15,7.4-33c-0.9-7.5-5.5-19.7-10.4-32.6c-5.6-14.8-11.4-30-11.7-38.9
c-0.6-13.7,1.2-20.6,1.9-22.5c-1.2-1.4-4.4-5.7-5.3-11.2c-1-6.5-0.7-17.7-0.7-17.9c0-0.2-3.4-19.6-9-28.4
C314.3,287.1,307.9,273.5,305.6,267.1z"/>
</g>
<g>
<path class="st1" d="M371.9,576.3l0.3-0.9c0.4-1,9.2-24.2,10.2-28.9c1.1-5.2,5.4-58.1,4.4-86.3c-0.7-18.5,1.1-32.8,2.8-46.6
c0.9-7.8,1.8-15.1,2.3-23.1c1.3-20.8-4.6-31.4-5.8-33.2V341c0-15.9-11.9-33.2-13.6-35.6c-0.2-3-1.5-26.2-3.7-37.1
c-2.1-10.3-5.9-20.2-6.7-22.2c0.6-1.8,3.2-9.6,4.7-19.1c1.7-11-5.7-37.9-10.2-49.3c-3-7.5-9.2-10.4-14.2-12.7
c-2.7-1.2-5-2.3-6.3-3.8c-3.4-3.9-6.7-5-9.7-5.9c-0.8-0.2-1.5-0.5-2.2-0.8c-1.1-0.5-2.1-1.4-3.7-2.7
c-2.6-2.3-6.5-5.9-14.8-10.7c-4.8-2.8-12-6.1-19.6-9.6c-11.4-5.2-24.2-11.1-27.2-14.8C256,113,250.2,90,250.2,90
c0.1,0.9,3.3,23.1,7.1,27.8c3.3,4.1,15.8,9.8,28,15.4c7.5,3.5,14.7,6.7,19.4,9.5c8.2,4.7,12,8.2,14.5,10.5
c1.6,1.5,2.8,2.5,4.3,3.1c0.8,0.3,1.6,0.6,2.4,0.8c2.8,0.9,5.8,1.8,8.7,5.3c1.6,1.9,4.2,3.1,7,4.4c4.9,2.3,10.4,4.8,13.1,11.6
c4.4,11.2,11.8,37.6,10.1,48.3c-1.7,10.6-4.7,19-4.7,19.1l-0.1,0.4l0.1,0.4c0,0.1,4.4,10.9,6.7,22.2
c2.3,11.3,3.7,36.9,3.7,37.1v0.3l0.2,0.2c0.1,0.2,13.4,18.6,13.4,34.6v16.9l0.2,0.3c0.1,0.1,6.9,10.5,5.6,32.2
c-0.5,7.9-1.4,15.2-2.3,22.9c-1.7,13.9-3.4,28.3-2.8,47c1,29.2-3.5,81.6-4.4,85.8c-0.9,4.1-8,23.1-9.7,27.7
c-1.3-0.7-3.5-2.6-3.5-7.1c0-0.2,0-0.5,0-0.8l-2-0.1c0,0.3,0,0.6,0,0.8c0,7.8,5.8,9.4,5.8,9.5L371.9,576.3z"/>
</g>
<g>
<path class="st1" d="M360.4,561.9L360.4,561.9c0.5,2.4,1.6,4.5,2.3,5.6l16.5-16.9l1.5,1.4l-18.3,18.7l-0.7-1
c-0.2-0.2-2.9-4.1-3.4-8.7C358.9,561.5,359.6,561.9,360.4,561.9z"/>
</g>
<g>
<path class="st1" d="M359.7,563.6l0.8-0.6c0.1-0.1,6.9-5.8,13.4-12.5c5.5-5.7,10.1-15.3,10.1-18.2h-2c0,2-4,11-9.5,16.8
c-5,5.3-10.4,9.9-12.4,11.7c-1.3-1.9-4-7-0.9-11.8c1.6-2.4,8.3-9.3,8.4-9.3l0.3-0.3V539c0-0.1,0.5-7.7,3-13.6l-1.9-0.8
c-2.3,5.5-3,12.2-3.1,13.9c-1.3,1.3-6.9,7-8.4,9.4c-4.7,7.2,1.5,14.9,1.6,15L359.7,563.6z"/>
</g>
<g>
<path class="st1" d="M290.6,312.7c0.6-3.3,2.9-9.4,5.4-15.8c1.7-4.4,3.4-9,4.8-13.1c3.1-9.2,12.4-57.9,13.9-65.7
c0.6,0.4,1.2,0.7,1.9,1c-1.8,9.5-10.8,56.1-13.9,65.4c-1.4,4.1-3.1,8.7-4.8,13.2c-2.4,6.3-4.7,12.3-5.3,15.5
c-0.6,3.1-0.7,18.6-0.5,46.3c0,1.4,0,2.4,0,2.7c0.1,0.5,1.2,2.6,2.3,4.4c3.5,6.2,9.4,16.6,8.3,25.4
c-1.2,9.8-2.7,13.9-3.2,15.1c1.1,3.5,9.3,30.3,8,49.6c-0.4,6.7,2.2,17.2,5.6,30.6c4.3,17.2,9.7,38.5,11.5,63.2
c2.7,36.5-5,96.3-7.9,118.9c-0.6,4.8-1,8-1.1,9c-0.1,2.4,0.1,4,0.3,5.9c0.3,2.6,0.7,5.5,0.3,11c-0.1,1.8-0.6,3.9-1.5,6.5
c-0.4-0.9-0.9-1.7-1.3-2.5c0.4-1.6,0.7-2.9,0.8-4.1c0.4-5.3,0-8.1-0.3-10.6c-0.3-1.9-0.5-3.7-0.3-6.3c0.1-1,0.5-4.3,1.1-9.1
c2.9-22.5,10.5-82.2,7.9-118.5c-1.8-24.5-7.2-45.7-11.5-62.8c-3.4-13.5-6.1-24.1-5.6-31.2c1.3-20.2-8-49-8.1-49.3l-0.1-0.4
l0.2-0.4c0,0,1.8-3.4,3.2-14.8c1-8.1-4.7-18.1-8.1-24.1c-1.7-3.1-2.5-4.5-2.5-5.4c0-0.3,0-1.3,0-2.7
C290,349,289.7,317.5,290.6,312.7z"/>
</g>
<g>
<path class="st1" d="M321.3,898.8c0.3-0.5,0.5-1,0.8-1.5c1.9-3.4,5.9-10.5,2.9-15.8c-0.1-0.3-0.3-0.6-0.5-0.9
c-1.2-1.9-3.3-5.4-5.3-13.1c-3.1-11.6-1.6-24.4-0.3-36.7l0.2-1.6c1.1-6.8,2.4-13.8,3.6-20.6c5.8-31.2,11.8-63.4-1.7-93.8
c-4.1-12.5-9.9-27.4-16.1-38.9c0,0,10.1,27.2,14.2,39.7c13.3,29.9,7.6,60.4,1.6,92.7c-1.3,6.8-2.6,13.8-3.7,20.7l-0.2,1.6
c-1.4,12.5-2.8,25.4,0.3,37.4c2.1,7.9,4.4,11.6,5.6,13.6c0.2,0.3,0.4,0.6,0.5,0.8C325.6,886.8,321.3,898.8,321.3,898.8z"/>
</g>
<g>
<path class="st1" d="M301.1,950c5.1,0,9.4-0.7,11.4-1.5s5.3-3,8.7-5.4c3.7-2.5,7.8-5.4,10-6c6.3-1.9,21.3-6.6,23.7-10.3
c1.7-2.6,2-4.9,2-6.1c1.8-0.5,6-2.1,6-5.5c0-3.6-0.9-5-1.7-5.9c-1.1-1.2-4.8-2.8-9.6-2l0.3,2c4.2-0.7,7.1,0.8,7.7,1.4
c0.4,0.4,1.2,1.3,1.2,4.5c0,2.6-5.2,3.8-5.2,3.8l-1,0.2l0.2,1c0,0.1,0.4,2.5-1.6,5.6c-1.6,2.4-12.5,6.5-22.6,9.5
c-2.5,0.7-6.6,3.6-10.6,6.3c-3.2,2.2-6.5,4.5-8.3,5.2c-4.1,1.6-19.3,3-28.8-3c-9.4-5.9-2.2-33.1-2.1-33.3
c0.4-1.4,0.8-2.9,1.2-4.5c1.3-5,2.9-10.8,4.4-15.3l0.1-0.3c0-2.3-1.1-4.4-2.1-6.6c-1-1.9-1.9-3.8-1.9-5.5
c9.8-35.4-4.7-68.4-18.7-100.3l-1.7-3.9c-0.6-2.6-1.6-5.4-2.5-8c-1.8-5.3-3.6-10.7-3.6-15.9c0.8-7.5,3.1-14.9,5.4-22.9
c3.3-11,7.2-21.6,7-34c0,0-5.8,22.6-9,33.4c-2.4,8-4.6,15.6-5.5,23.3c0,5.7,1.9,11.3,3.7,16.7c0.9,2.6,1.8,5.4,2.5,8.1
l1.8,4.1c13.9,31.6,28.2,64.3,18.6,99.1v0.3c0,2.3,1.1,4.4,2.1,6.6c1,1.9,1.9,3.7,1.9,5.5c-1.5,4.6-3,10.3-4.4,15.3
c-0.4,1.6-0.8,3.1-1.2,4.5c-0.3,1.2-7.7,28.9,3,35.6C287.6,948.9,294.9,950,301.1,950z"/>
</g>
<g>
<path class="st1" d="M264.6,708.4l1.7-1.1c-8-12.2-15.3-28.3-17-37.4c-1.3-7-2.5-21-3.7-35.9c-1.2-13.8-2.5-29.3-4.1-41.8
c-2-15.4-6.1-32.8-9.3-46.8c-2.2-9.5-4.1-17.6-4.8-22.5c-1.5-11.7,1-23.9,1-24l-1.8-2.8c-0.1,0.5-2.7,14.9-1.2,27
c0.6,5,2.6,13.2,4.8,22.7c3.3,13.9,7.3,31.2,9.3,46.6c1.6,12.4,2.9,28,4.1,41.7c1.3,15.5,2.4,29,3.7,36.1
C249,679.7,256.3,695.8,264.6,708.4z"/>
</g>
<g>
<path class="st1" d="M365.2,509.9l-0.6,0.3c-0.3-0.5-0.5-1.1-0.8-1.6c-0.5-0.8-1.1-1.4-1.6-2.1c0-0.1-0.1-0.3-0.2-0.4
c-1-1.1-2.1-2.3-3.1-3.4c-0.2-0.2-0.4-0.3-0.6-0.3c-1.9-2.4-4.1-4.5-6.5-6.4C351.7,496.1,362.5,500.5,365.2,509.9z"/>
</g>
</g>
<g>
<path class="st1" d="M357.4,912c-0.6-0.6-14.2-13.9-21.8-18.2c-7.3-4.1-9.7-6.9-9.8-7l-1.6,1.3c0.1,0.1,2.6,3.1,10.3,7.4
c3.7,2.1,9,6.5,13.4,10.5C352.4,910,357.4,912,357.4,912z"/>
</g>
<g>
<path class="st1" d="M337.1,163.2c0.5,0.4,1,0.7,1.5,1.1c-9.4,1-17,5.3-24.6,12.5c-12.4,11.9-10.4,35.1,4.2,42.7
c9.1,4.7,19.1,6.6,27.9,12.2c5.7,3.6,10.6,8.5,15.1,13.5c-0.2,0.5-0.4,1-0.6,1.5c-10.3-10.5-21-18-35.8-23.3
c-8.1-2.9-16.3-6.7-19.1-15.6C298.4,184.4,315.4,165.9,337.1,163.2z"/>
</g>
<g>
<path class="st1" d="M325.2,308.5c4,10.7,8.1,19.7,20.2,23.8c1.8,0.6,3.7,1.1,5.5,1.6c6.7,1.8,8.9-1.3,11.4-6.9
c3.6-8,7-19.4,9.2-27.8c0-0.2,0.1-0.3,0.2-0.3c0.3,0.9,0.4,1.8,0.5,2.7c-2.8,10.2-7,22.5-11.5,31.8c-3,6.4-8.6,2.1-13.9,0.5
c-10.9-3.1-16.1-9.8-20-18.4C326.5,313.1,326,310.7,325.2,308.5z"/>
</g>
<g>
<path class="st1" d="M333,274.7c7.3,0.8,11.3,13.1,13.8,18.6c5.9,13,9.5,26.6,10.8,40.9c0.1,0.8-1.2,0.8-1.2,0
c-1.6-18.4-7.3-37.5-17.4-53.2c-1.1-1.8-2.5-3.3-4.2-4.5c-7-5.1-2.8,10.9-2.6,11.7c1.2,3.9,2.5,7.7,3.7,11.5
c3.7,12.3,8.2,24.2,14.9,35.1c-0.1,0-0.3,0-0.4-0.1c-0.4-0.2-0.9-0.3-1.3-0.4c-7.8-13.2-11.9-27.6-16.8-42.2
C331.6,290.2,325.5,273.9,333,274.7z"/>
</g>
<g>
<path class="st1" d="M363.1,326.6c1.8,13.2,13.9,20.6,21.8,30.6c0,0.1,0.1,0.3,0.2,0.4c2.4,3.5,3.8,7.5,4.9,11.5
c-0.1-0.1-0.2-0.2-0.3-0.4c-4.3-16.5-23.8-23.8-27.4-40.2C362.5,328,362.8,327.3,363.1,326.6z"/>
</g>
<g>
<path class="st1" d="M357.5,334c3,18.5,12.1,33.8,19.9,50.5c7.5,16,9.7,33.6,9.5,51c0,0.8-1.3,0.8-1.2,0
c0.2-19.7-3.2-37.9-11.9-55.7c-7.4-15-14.8-28.8-17.5-45.5C356.2,333.5,357.4,333.2,357.5,334L357.5,334z"/>
</g>
<g>
<path class="st1" d="M342.8,362.2c6.8-7.3,10.3-16.4,10.2-26.4c0-0.8,1.2-0.8,1.2,0c0.1,10.4-3.7,19.9-10.8,27.5
C343.2,363,343,362.6,342.8,362.2z"/>
</g>
<g>
<path class="st1" d="M358.5,343.2c0-0.2,0-0.3,0.1-0.4c0.4,0.8,0.8,1.6,1.2,2.4c0.4,11.5-2.7,22.4-9.1,31.9
c-0.2-0.4-0.4-0.9-0.6-1.3C356.5,366.1,359.4,355,358.5,343.2z"/>
</g>
<g>
<path class="st1" d="M334.4,351.6c0.1-0.5,0.2-1,0.3-1.6c25.8,31.1,34.5,80.4,20.8,118.2c-0.3,0.7-1.5,0.4-1.2-0.3
C367.7,430.8,359.5,382.3,334.4,351.6z"/>
</g>
<g>
<path class="st1" d="M361.3,421.7c0.1,0.1,0.2,0.2,0.2,0.3c2.2,8.6,6.2,19.3,7.8,28c0.7,3.9,1.2,7.8,2,11.7
c1.3,6.3,8.8,10.2,9.6,0.8c2-21.4,0.5-46.4-1-67.6c-0.1-0.8,1.6,1.4,1.6,2.2c1.1,15.6,1.6,31.2,1.2,46.9
c-0.1,2.8,0.9,25.2-3.3,26.5c-4.3,1.4-8-2.9-9.1-6.2c-1.8-5.4-2-11.7-3-17.2c-1.3-6.7-4.4-15.1-6.5-22.1
C361.2,424,361.3,422.9,361.3,421.7z"/>
</g>
<g>
<path class="st1" d="M225.4,93.3c-0.1-4.4-0.9-11.9,3.6-14.5c4.3-2.6,8.7,6.5,9.8,8.6c6,12.4,11.9,26.3,24.6,33.1
c0.7,0.4,0.1,1.4-0.6,1.1c-11.2-6.1-17.6-18-23-29.1c-1-2-1.9-4.1-2.9-6.1c-1.1-2.2-2.9-4.5-5.2-5.4c-2.2-0.8-4.4,1-4.6,3.2
c-0.3,1.8-0.4,3.7-0.5,5.6c-0.2,6.3,0.1,12.6,0.3,18.8c0.2,11,1,19.2,7.6,28.4c6.8,9.4,11.5,15,22.5,19.4
c10.6,4.3,57.2,14.7,66-1.7c0.3,0.3,0.7,0.5,1,0.7c-7.6,13.4-39.3,8.6-50.2,6.9c-21.8-3.4-41.5-15.9-47.3-38.4
C224,114.4,225.7,103.1,225.4,93.3z"/>
</g>
<g>
<path class="st1" d="M227.5,181c3.1-12,12.5-21.3,23.1-27.1c0.4,0.3,0.8,0.5,1.2,0.7c-12,6.5-20.5,16.5-24,30.2
c-4.2,16.4-0.9,34.4-0.3,51c0.6,15-0.6,30.1-0.4,45.2c0.1,7.2,0.6,14.4,0.5,21.6c-0.1,5.7,7.4,0.1,9.3-2.7
c2.6-3.7,5.4-7.3,8-11.1c8.1-11.8,16.8-23.4,23.2-36.3c8.8-17.8,2.6-34.4-1.4-52.3c-1.6-7.4-3.2-15.8,3.7-20.6
c3.7-2.6,9.5-3,13.8-3.7c2-0.3,4-0.4,6.1-0.5c6.1-0.4,20.8-1.1,18.7-12.1c0.4-0.1,0.8-0.1,1.2-0.2c1.2,6.6-3.7,11.4-10.8,12.9
c-7.9,1.7-16,1-24,3c-15.7,4-5.9,27.4-3.3,37.8c4.2,16.6,0.8,30.6-7.7,45.2c-9,15.5-19.8,31-31,45c-4.7,5.8-7.1-0.2-7-4.3
c0.5-21-0.2-42.2-0.1-63.2C226.4,219.7,222.6,200.3,227.5,181z"/>
</g>
<g>
<path class="st1" d="M312.4,237.5c-2.4,16.3-30.4,11.2-40.8,7.7c-0.7-0.2-0.4-1.4,0.3-1.2c9.9,3.3,37.1,8.4,39.3-6.8
C311.4,236.4,312.6,236.7,312.4,237.5L312.4,237.5z"/>
</g>
<g>
<path class="st1" d="M231.3,306c0.8,16.5,4.2,33,8.3,49c1.6,6.4,3.6,12.7,5.4,19.1c1.2,4.5,2.6,9.8,6.5,12.8
c5.7,4.4,8.4-3.9,9.2-8.3c2.2-12.7,9.2-25.9,14.8-37.4c6.6-13.7,13.2-27.8,20.5-41.1c0.4-0.7,1.4-0.1,1.1,0.6
c-4.6,8.6-8.1,17.7-12.5,26.4c-5.1,10-10.5,19.6-14.8,30c-3.9,9.4-6.8,18.3-8.6,28.3c-0.6,3.1-1.7,4.1-4.7,4.4
c-10.7,1.3-13-17.3-14.8-23.7c-5.7-19.5-10.4-39.6-11.4-60C230.1,305.2,231.3,305.2,231.3,306L231.3,306z"/>
</g>
<g>
<path class="st1" d="M272.9,223.1c0.3-0.1,0.6-0.1,0.8,0.2c5.5,7.5,10,15.6,13.7,24.2c0.1,0.3,0,0.5-0.1,0.7
c-0.3,0-0.6,0-0.9,0l0,0c-3.6-8.4-8-16.2-13.3-23.6C273,224,273,223.6,272.9,223.1z"/>
</g>
<g>
<path class="st1" d="M277.3,229.5c11.9-3.9,22.5-10.3,30.3-20.3c0.5-0.6,1.3,0.3,0.9,0.9c-7.8,10.1-18.6,16.5-30.6,20.5
C277.7,230.2,277.5,229.8,277.3,229.5z"/>
</g>
<g>
<path class="st1" d="M281.9,176.7c8.7,3.9,16.4,9.3,23,16.2c0.5,0.6-0.3,1.4-0.9,0.9c-6.6-6.9-14.3-12.3-23-16.1
c-0.4-0.2-0.4-0.6-0.1-0.9C281.3,176.8,281.6,176.7,281.9,176.7z"/>
</g>
<g>
<path class="st1" d="M263.2,387.2c11.1,8.9,27.6,10.9,36.3,22.1c0.2,1,0.4,2,0.6,3c-7.8-13.3-26.1-14.9-37.7-24.3
C261.7,387.6,262.6,386.8,263.2,387.2z"/>
</g>
<g>
<path class="st1" d="M255.5,388.5c11.6-0.6,19.9-9.9,28.6-16.7c3.6-2.9,5.7-7,6.7-11.5c0.2,0.4,0.4,0.9,0.6,1.3
c-1,3.2-2.5,6.4-4.7,9.2c-4.1,5.3-11.3,9.9-16.7,13.7c-4.6,3.2-8.9,4.8-14.5,5.1C254.8,389.7,254.8,388.5,255.5,388.5z"/>
</g>
<g>
<path class="st1" d="M255.6,517.7c10.2,3.2,20.2,7,30.4,10.2c19.7,6.2,6.8-24.6,4.1-32.9c-2.9-8.6-5.5-18.6-3.8-27.8
c0.8-4.4,2.1-8.1,4.1-12c4.5-8.6-10.5-13.5-15.4-16.1c-11.6-6.3-26.4-16.1-38-4.3c-6.2,6.3-7.6,16.9-9,25.2
c-2,12.1-2.5,24.7,0.2,36.7C230.8,509,245.1,514.3,255.6,517.7c0.7,0.2,0.4,1.4-0.3,1.2c-10.2-3.3-20.1-7.4-26.2-16.8
c-6.4-9.9-4.6-27.4-2.9-38.2c3.2-21.1,10.6-43.5,36.3-32.7c6.9,2.9,13.3,7,19.9,10.6c1.7,0.9,11.5,4.4,11.1,8.1
c-0.9,7.3-5.5,13-6.3,20.7c-1,10.5,3.1,21.3,6.5,31.1c1.5,4.3,9.9,24.3,2,27.3c-3.9,1.5-7.6,0.8-11.6-0.4
c-9.6-3-19.1-6.6-28.7-9.7C254.5,518.6,254.8,517.5,255.6,517.7z"/>
</g>
<g>
<path class="st1" d="M233,440.5c7.4-15.3,15.5-24.5,25.5-35.5c12-13.1,28.8-4.1,40.5,4.5c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1
c0.2,0.6,0.4,1.2,0.6,1.8c-14.8-11.2-30.6-17.7-44.6-0.9c-9.5,11.4-18.5,23-24.8,36.4C230.9,445.1,232.1,442.2,233,440.5z"/>
</g>
<g>
<path class="st1" d="M298.6,521c0.1,0,0.2,0.1,0.3,0.3c14,32.5,17.6,66.8,22.6,101.4c-0.3,0.8-0.6,1.6-0.9,2.4
c-5.1-34.8-8.5-69.4-22.4-102.1C298.4,522.3,298.6,521.7,298.6,521z"/>
</g>
<g>
<path class="st1" d="M272.7,524.3c0.4,0.1,0.7,0.3,1.1,0.4c-2.2,9.6-0.8,20.9-0.2,30.2c1.3,16.7,3.9,33.4,7.2,49.8
c6.5,32.5,21,62.9,34.2,93.3c-0.2,0.5-0.4,1.1-0.6,1.6c-14.2-33.1-29.3-65.8-36.1-101.4c-3-15.8-5.2-31.8-6.3-47.9
C271.6,542.2,270.8,532.6,272.7,524.3z"/>
</g>
<g>
<path class="st1" d="M291.6,448.5c6.1,3.5,11.2,8,15.5,13.6c0.5,0.6-0.6,1.2-1.1,0.6c-3.9-5.1-8.6-9.3-14-12.6
C292,449.6,291.8,449.1,291.6,448.5z"/>
</g>
<g>
<path class="st1" d="M243.3,625.3c7.4-36.6,18.1-73.6,4.7-109.8c0.5,0.2,1,0.5,1.5,0.7c13.5,37.7,1.2,76.4-6.1,114.4
C243.4,628.8,243.3,627,243.3,625.3z"/>
</g>
<g>
<path class="st1" d="M282.2,832.9c-0.3-2.2-0.7-4.3-1.4-6.4c10.4,22.9,11.4,48.1,2.7,71.8c-0.3,0.7-1.4,0.4-1.2-0.3
C290.2,876.5,290.2,853.9,282.2,832.9z"/>
</g>
<g>
<path class="st1" d="M295.3,840.5c0,0-0.6-10.6-0.5-16.2c0.3-19.3,0.2-38.6-0.4-57.8c-1-28.2-8-54.7-23.8-77.4
c0.2-0.5,0.3-0.9,0.5-1.4c15.6,22.2,22.7,47.8,24.3,75.6c1.3,21.6,0.9,43.4,0.5,64.9C295.9,832.4,295.7,836.4,295.3,840.5z"/>
</g>
<g>
<path class="st1" d="M320.2,713.7c9.4,66.6-26.1,133-7.9,198.8c0.2,0.8-3.5-9.1-4.3-13.8c-10.3-61.3,19.8-122.9,11-184.7
C318.9,713.3,320.1,713,320.2,713.7L320.2,713.7z"/>
</g>
<g>
<path class="st1" d="M287.5,723c-0.4-1-0.9-2-1.4-3c-0.5-17.6,5.5-34.2,17.2-47.5c0.2,0.4,0.3,0.8,0.5,1.3
C292,687.6,286.2,704.8,287.5,723z"/>
</g>
<g>
<path class="st1" d="M268.6,692.6c7.4-22.6,20.9-41.6,17.8-64c0.4,1,0.9,1.9,1.3,2.9c1.2,24.2-15.2,49-19.8,66.5
C268.1,696.9,268.3,693.7,268.6,692.6z"/>
</g>
<g>
<path class="st1" d="M296,794.9c5.3,7.6,18,5.4,19.6-5.1c0.1-0.8,1.3-0.4,1.2,0.3c-1.7,11.1-14.5,13.5-20.8,6.6
C296,796.1,296,795.5,296,794.9z"/>
</g>
<g>
<path class="st1" d="M295.4,804.7c-2.6,8.9-15.3,11-20.2,1.6c-0.4-0.7-1.4,0-1,0.7c5.2,9.9,18.1,8,21.8-0.6
C295.8,805.8,295.6,805.2,295.4,804.7z"/>
</g>
<g>
<path class="st1" d="M307.4,851.7c2.8,10.5,8.2,19.8,16.2,27.3c0.6,0.5-0.3,1.4-0.9,0.9c-7.2-6.8-12.4-15-15.4-24.3
C307.3,854.3,307.4,853,307.4,851.7z"/>
</g>
<g>
<path class="st1" d="M257.7,693.5c-0.7-1.1-1.3-2.3-2-3.4c-7-35,8.9-73-3.1-106.8c0.2-0.7,0.3-1.4,0.5-2.2
C266.7,616.6,248.3,656.9,257.7,693.5z"/>
</g>
</g>
</g>
</g>
</g>
</svg>
</template>
