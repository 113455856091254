<template>

  <div style="margin-top: 80px;">
    <!-- shop-cart  -->
    {{ msg }}
  </div>
  
</template>

<script>

//import

export default {
  components: {

  },
  setup() {

  },
  mounted() {

  },
  data() {
    return {
      msg: 'Cart',
    };
  },
  methods: {

  },
  unmounted() {

  },

};

</script>
