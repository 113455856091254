<template>

  <div style="padding: 16px 16px 16px 16px;">
    <p><i class="dx-icon -nav-icon tabnav-icon fa-light fa-file-invoice"></i>{{ currentUser.Translation.vueappNavMemberInvoices }}</p>
  </div>

  <div class="dx-card -no-paddings">
  
  <DxDataGrid
    id="gridContainer"
    :data-source="customDataSource"
    :focused-row-enabled="false"
    :column-auto-width="true"
    :column-hiding-enabled="true"
    :allow-column-reordering="true"
    :show-borders="false"
  >
  
  <DxPaging :page-size="500" />
  <DxPager :show-page-size-selector="false" :show-info="true" />
  <DxFilterRow :visible="true" />
  
  <DxEditing
    :allow-updating="false"
    :allow-deleting="false"
    :allow-adding="false"
    mode="row"
  />  
  
  <DxColumn
    alignment='left' 
    data-field="Search"
    :caption="title"
    :placeholder="'Suchen'"
    :hiding-priority="99"
    cell-template="invoiceTemplate"

  />
  <template #invoiceTemplate="{ data: content }">

    <div class="grid-x align-middle">

      <div class="shrink cell">

      <!-- avatar -->
      <div class="user-thumb" style="margin: 2px 10px 2px 0;"
        v-bind:style="{ 'background-image': 'url(' + content.data.AboPicthumb + '?v=' + timestamp + ')' }" >
      </div>
      <!-- /avatar -->

      </div><!-- /shrink cell main -->

      <div class="auto cell">

        <div class="grid-x align-middle">

        <div class="shrink cell">
          <!-- row 1 -->
          <div class="fixedwidth-listitem">
            <p class="ck-overflow-ellipsis strong small">
              <span v-if="content.data.CustomerSubject">
                {{ content.data.CustomerSubject }}
              </span>
              <span v-else class="alert-color">
                <i class="fa-light fa-triangle-exclamation"></i> Kunde
              </span>
            </p>

            <p class="ck-overflow-ellipsis small">
              RE-{{ content.data.id }}
            </p>

          </div><!-- /fixedwidth-listitem -->
        </div><!-- /shrink cell -->

        <div class="shrink cell">
          <!-- row 2 -->
          <div class="fixedwidth-listitem">
            <p class="ck-overflow-ellipsis strong small">
              <span v-if="content.data.DateInvoice">
                {{ formatDate(content.data.DateInvoice) }}
              </span>
              <span v-else class="alert-color">
                <i class="fa-light fa-triangle-exclamation"></i> Rechnungsdatum
              </span>
            </p>

            <p class="ck-overflow-ellipsis small">
              <span>{{ content.data.AboSubject }} </span>
            </p>

          </div><!-- /fixedwidth-listitem -->
        </div><!-- /shrink cell -->

        <div class="shrink cell">
          <!-- row 3 -->
          <div class="fixedwidth-listitem">
            <p class="ck-overflow-ellipsis strong small">
              <span v-if="content.data.Price">
                CHF {{ formatPrice(content.data.Price) }}
              </span>
              <span v-else class="alert-color">
                <i class="fa-light fa-triangle-exclamation"></i> Preis
              </span>
            </p>

            <p class="ck-overflow-ellipsis small">
              <span v-if="content.data.Rates">
              {{ content.data.Rates }} Rate<span v-if="content.data.Rates > 1">n</span>
              </span>
              <span v-else class="alert-color">
                <i class="fa-light fa-triangle-exclamation"></i> Raten
              </span>
            </p>

          </div><!-- /fixedwidth-listitem -->
        </div><!-- /shrink cell -->

        <div class="shrink cell hide">
          <!-- row 4 -->
          <div class="fixedwidth-listitem">

            <p class="ck-overflow-ellipsis strong small hide">
              Status offen/bezahlt
            </p>

            rateDayspaid: {{ content.data.rateDayspaid }} | rateDaysdue: {{ content.data.rateDaysdue }}
            rateDate: {{ content.data.rateDate }} | rateDayspaid: {{ content.data.rateDayspaid }}


            <p v-if="content.data.rateDate" class="ck-overflow-ellipsis small">
              <span v-if="content.data.rateStatus == 'alert'" class="alert-color"><i class="fa-light fa-triangle-exclamation"></i> {{ formatDate(content.data.rateDate) }}</span>
              <span v-if="content.data.rateStatus == 'wait'" class=""><i class="fa-light fa-hourglass-clock"></i> {{ formatDate(content.data.rateDate) }}</span>
            </p>

            <p class="ck-overflow-ellipsis small">
              <span v-if="content.data.rateDayspaid < 1" class="success-color">{{ content.data.rateDayspaid }}</span>
              <span v-else class="alert-color">{{ content.data.rateDayspaid }}</span>
            </p>


            <p class="ck-overflow-ellipsis small">
              <span v-if="content.data.rateDaysdue < 1" class="success-color">{{ content.data.rateDaysdue }} Tage</span>
              <span v-else class="alert-color">{{ content.data.rateDaysdue }} Tage</span>
            </p>

          </div><!-- /fixedwidth-listitem -->
        </div><!-- /shrink cell -->
        

        </div><!-- /grid-x sub-->


      </div><!-- /auto cell main -->


    </div><!-- /grid-x main -->



    <div class="grid-x -grid-margin-x align-middle ck-itemrow hide">

      <div class="shrink cell">
        <!-- avatar -->
        <div class="user-thumb" style="margin-right: 15px;"
          v-bind:style="{ 'background-image': 'url(' + content.data.AboPicthumb + '?v=' + timestamp + ')' }" >
        </div>
        <!-- /avatar -->
      </div><!-- /shrink cell -->

      <div class="auto cell">
        <p class="ck-overflow-ellipsis strong">
          RE-{{ content.data.id}} | {{ content.data.AboSubject }}
        </p>
        <p class="ck-overflow-ellipsis small -hide">          

          <span v-if="content.data.DateInvoice">
            {{ formatDate(content.data.DateInvoice) }}
          </span>
          <span v-else class="alert-color">
            <i class="fa-light fa-triangle-exclamation"></i> Rechnungsdatum
          </span>
          | 
          <span v-if="content.data.Price">
            CHF {{ formatPrice(content.data.Price) }}
          </span>
          <span v-else class="alert-color">
            <i class="fa-light fa-triangle-exclamation"></i> Preis
          </span>
          | 
          <span v-if="content.data.Rates && content.data.Price">
            {{ content.data.Rates }} Rate<span v-if="content.data.Rates > 1">n</span>
          </span>
          <span v-else class="alert-color">
            <i class="fa-light fa-triangle-exclamation"></i> Raten
          </span>

        </p>
      </div>
    </div>
  </template>

  <DxMasterDetail
    :enabled="true"
    template="masterDetailTemplate"
  />
  <template #masterDetailTemplate="{ data: content }">
    <DetailTemplate
      :template-data="content"
    />
  </template>

</DxDataGrid>

</div><!-- dx-card -->
  
</template>


<script>
   
  import {
    DxDataGrid, 
    DxFilterRow, 
    DxColumn, 
    DxEditing, 
    DxPager, 
    DxPaging, 
    DxMasterDetail,
    //DxButton,
    //DxLookup, 
  } from 'devextreme-vue/data-grid';
  
  import CustomStore from 'devextreme/data/custom_store';
  import DetailTemplate from '../parts/invoice-item-detail-master.vue';
  
  import auth from "../auth";

  import { 
    apihost, 
  } from "../api";
  //console.log(apihost);

  let currentUser;
  let customerId;

  auth.getUser().then((e) => {
    if(e.data){
      //console.log(e.data);
      currentUser = e.data;
    } // e.data
  }); //auth
  
  function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
  }
  
  const customDataSource = new CustomStore({
    key: 'id',
    load: () => {
      return fetch(apihost+'/de/vue/invoice/list/?Customerid='+customerId)
      .then(handleErrors)
      .then(response => response.text())
      .then(data => {
      //console.log(`response text`, text)
      return JSON.parse(data)
      })
      .catch(() => { throw 'Network error' });
    },
    insert: (values) => {
      //console.log(values);
      const key = '0';
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/invoice/getinvoice/'+key+'/insert'+objString+'&Customerid='+customerId, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    remove: (key) => {
      //console.log(key);
      return fetch(apihost+'/de/vue/invoice/getinvoice/'+key+'/remove', {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    update: (key, values) => {
      // console.log(key);
      // console.log(values);
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/invoice/getinvoice/'+key+'/save'+objString, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    }

  });

  // console.log(customDataSource)
  
  
  export default {
    name: 'customer-tasklist',
  
    props: {
      customerData: Object,
    },
  
    components: {
      DxDataGrid,
      DxFilterRow,
      DxColumn,
      DxEditing,
      DxPager,
      DxPaging,
      DxMasterDetail,
      //DxButton,
      //DxLookup,

      DetailTemplate,

    },
  
    mounted() {

      this.timestamp = this.printTimestamp();

      // console.log(this.customerData.id);
      // customerId = '1189';
      if(this.customerData){
        customerId = this.customerData.id;
      }
    },
  
    data() {
      return {
        title: currentUser.Translation.vueappNavMemberInvoices,
        msg: 'Tasks (part)',
        // statuses,
        // priorities,
        currentUser,
        customDataSource,
  
        validationRules: {
          status: [
            { type: 'required', message: 'Status is required.' },
          ],
          priority: [
            { type: 'required', message: 'Priority is required.' },
          ],
        },
        statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
        priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },
  
      };
    },
    methods: {

      formatDate(dateString) {
        //https://jerickson.net/how-to-format-dates-in-vue-3/
        const date = new Date(dateString);
        // Then specify how you want your dates to be formatted
        return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
      },

      formatPrice(value) {
        //https://stackoverflow.com/questions/43208012/how-do-i-format-currencies-in-a-vue-component
        let val = (value/1).toFixed(2).replace('.', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
      },

      printTimestamp: function () {
          return Date.now();
      },

    },
    unmounted() {
  
    },
  };
  </script>
  
  <style lang="scss">
  
  </style>
  