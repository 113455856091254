<template>
<svg version="1.1" id="logo-christoppark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 538.4 193.5" style="enable-background:new 0 0 538.4 193.5;" xml:space="preserve">
<path class="st0" d="M217.8,83.6c-0.5,0-1.1,0.2-1.2,0.7c0-0.5-3.2,10.1-3.3,10c0,0-0.2,2.3,1.6,2.3c3.1,0,26.7,0,26.7,0
c0.7,0,0.6,0.9,0.3,1.9l-29.4,93.7c-0.3,1.1,0.9,1.4,2.3,1.2l13.4-3.8c0.8-0.2,1.1-1.3,1.2-1.4L258.2,98c0.5-1.2,1.2-1.5,2.4-1.6
c6.4-0.3,36.3,0,36.3,0c0.8,0,1.6,0,2-0.9l2.5-9.4c0.1-1.1,0.2-1.8-1.1-2.4L218,83.5L217.8,83.6z"/>
<path class="st0" d="M372.9,84.1c-1.1-0.1-1.4-0.3-4.7-0.4h-56.8c-0.9,0-1.6,0.3-2.1,1.4l-2.9,8.8c-0.3,1.2,0.7,2.2,2,2.2h61.9
c2.1,0,7.2,3.5,7,9.4c-0.1,3.4-3.5,8.1-6.3,8.2c-2.8,0.2-13.7,0-13.7,0c-1.4-0.5-1.9-1.2-1.5-3.2c0,0,2.4-8,2.5-10.3
c0-2.3-0.9-2.7-1.8-2.7l-7.3-0.2c-0.7,0-1.8,0.5-2.1,2.1c0,0-12.4,45.5-12.4,46.5s0.7,2.1,2.3,2.3c1.6,0.2,5,0.1,6.3,0.1
s2-0.6,2.5-1.9c0.5-1.3,4.7-19.6,5.3-20.1c0.6-0.5,0.7-1,1.6-1s18.1,0,19.8,0c3.7,0,15.2-6.7,16-19C389.7,88,374.1,84.2,372.9,84.1"
/>
<path class="st0" d="M308.2,130.4c0,2.7-3.2,3.9-4.5,4h-4c-0.5,0-0.7-0.5-0.8-1.1v-6.5c0-0.5,0.6-1,1-1h4.2
C305.7,126.1,308.3,127.3,308.2,130.4 M310,116h-23.3c-1.6,0-2.4,0.8-2.4,3.1s0.3,38.6,0.3,38.6c0,0.9,0.2,1.4,0.9,1.4h12
c0.8,0.1,1.4,0.1,1.4-1.8v-11.1c-0.1-1.1,0.4-2.2,1.4-2h8.3c7.5-0.3,13.2-5.8,13.6-13.8c0.3-7.4-4.3-14.2-12.2-14.2"/>
<path class="st0" d="M271.6,131.4c0,2.9-2.3,5.2-5.2,5.2s-5.2-2.3-5.2-5.2s2.3-5.2,5.2-5.2S271.6,128.5,271.6,131.4 M282.9,131.3
c0-9.3-7.4-16.9-16.6-16.9s-16.6,7.6-16.6,16.9s7.4,16.9,16.6,16.9S282.9,140.6,282.9,131.3"/>
<path class="st0" d="M330.2,128.4c3.1,0,5.6-2.6,5.6-5.8s-2.5-5.8-5.6-5.8s-5.6,2.6-5.6,5.8S327.1,128.4,330.2,128.4"/>
<path class="st0" d="M421.6,83.7c-2,0-3.8,0.5-4.9,1.9s-47.8,58-48.9,59.5c-1.1,1.6-2.1,2.8,0.3,3s7.8,0.5,8.9,0.2
c1.1-0.2,1.6-0.8,2.4-1.8c0.9-1,40.5-46.2,40.5-46.2c0.8-1.1,1.9-0.9,1.9,0.5s-0.3,21.8-0.3,21.8c0,1-1.1,1.9-2.2,1.8H404
c-1.1,0.1-1.8,0-2.7,1.1l-6.6,8.1c-0.5,1,0,1.9,1.7,1.9h23.4c1,0,1.7,0.7,1.7,1.7v9.2c0.1,0.9,1,1.4,2.3,1.6h7.5
c1.3,0,2.3-1.2,2.4-2.3l-0.9-59.4c0-1.1-0.7-2.6-1.7-2.6h-9.7L421.6,83.7z"/>
<path class="st0" d="M442.1,83.7c-1.3,0-2.9,1.2-2.9,3.1s0,58.5,0,58.5c0,1.1,0.6,2.5,2,2.6c0,0,5.5,0,7.3,0s2.7-0.7,2.7-2.8V97.9
c0-0.9-0.1-2,1-2c1.7,0,9.8-0.6,13.2,0c3.4,0.6,7.7,4.4,7.8,9.4c0.1,5.1-1.5,9.2-7.2,9.8c-3.1,0.3-11.5,0.3-11.5,0.3
c-2.2,0-3,3.7-2,6.8l17.6,24.5c0.3,0.5,1.3,1.3,3,1.4h9.4c2.2,0,3.2-1.1,2.6-2.5l-14.2-17.3c-0.4-0.7-0.1-1.8,1.3-2.3
c0,0,13.8-3.1,13.9-18.6c0-11.9-5.1-23.8-22.5-23.5l-21.3-0.2L442.1,83.7z"/>
<path class="st0" d="M501.7,86.7c0-1.5-1.2-2.6-2.6-2.5h-6.9c-1.2,0-2.3,0.7-2.3,2.1l-0.3,59.6c0,1.2,0.5,1.9,1.5,1.9h9.4
c0.8,0,1.6-1,1.7-2.1v-15.8L538,86.6c1-1.7,0-2.8-0.9-2.8h-9.6c-1.5,0-2.9,0.5-3.8,1.8l-20.7,24c-0.9,0.6-1.1-0.1-1.1-1.4
L501.7,86.7L501.7,86.7z"/>
<path class="st0" d="M513.5,121.2c-0.7-0.7-1.6-0.4-2.3,0.5l-5.6,6.2l16.8,19.3c0.9,0.7,1.9,1.2,3.7,1.2l8.9-0.2
c1.3,0,1.8-1.2,1.1-2.3l-22.8-24.6L513.5,121.2z"/>
<path class="st1" d="M137.1,120c6.8-4.2,19.3-10.8,22-6.8c2.4,3.5-12.2,12.6-21.5,19.4c-9.7,7.1-19.7,14.2-20.8,13.4
C114.5,144.3,129.3,125.8,137.1,120 M156.6,106.6c-11.5,1.6-17.6,9.6-19.2,8.4c-1.9-1.5,26.6-31.8,33.4-37.3
c7.2-5.9,12.5-7.1,14.2-5.4c5.2,5.8-17.1,36.2-20.4,36.4c-1.3,0-3.7-1.7-7.9-2.1 M224.2,5.9c-7.4-16.9-44.4,7.7-44.4,7.7
s-56.4,33.2-90.4,97.7c0,0-22.1,10.3-34.9,15.1c-16.7,6.4-41,14.5-43.4,15c-4.7,1-5.2,0-6.4-1c-1.1-1-4.5-1-4.7,1.2
c-0.1,2.2,0.7,4.3,5.2,4.9c4.5,0.6,5.2,0.1,10.1-1.4c2.4-0.8,19.7-7.4,37.2-14.2c13.9-5.4,34.5-15,34.5-15s-17.9,28.6-2.6,39.8
c14.5,10.9,41-3.3,71.7-30.3c1.2-1.1,1.6,0.7,1.1,1.3c-0.4,0.6-11.6,17.2-14.6,20.1c-3,2.9-5.5,7.2-1.2,9.1c5,2.2,9.2-3.5,10.6-5.8
c1.4-2.3,9.4-18.1,11.5-20c3.1-1.9,6.6-3.6,7.2-3.1c1,0.8-1.5,3.8-3.7,6.9c-1.7,2.4-2.9,4.9-3.5,5.7s-5.7,5.5-3.2,8.9s5.6,0,6.7-1.5
c1.1-1.5,13.6-14.4,16.3-14.9c1.6-0.3,0,2.7,1.8,2.8c3.2,0.1,2.9-0.9,3.7-1.8c0,0,4.6-6.7,4.9-7.6c0.3-0.9,0.4-3.9-3.7-3.8
c-4.1,0.1-11.1,6.1-11.6,6.3c-0.6,0.2-1.3,1-1.1-0.2c0.2-1.2,1.8-3.4,1.5-5.1c-0.3-1.7-1.5-3.9-3.6-3.7c-3.5,0.4-5.8,3.3-6.7,3.9
c-0.7,0.6-2.1,0.3-1.8-0.7s1.9-5,1.5-8c0-1.5,4.9-7.7,6-9.1s19.4-26.7,16.2-34.4c-3.1-7.4-8.8-4.3-9.9-4
c-1.1,0.3-24.7,12.6-43.9,39.3c-14.2,19.2-21.8,32.2-27.8,43.2c-1.1,2.1-12.2,6.8-18.9,2c-4.7-2.8-6-6.9-2.1-17.3
c4-10.8,8.4-26.2,57.5-78.2c51.3-53.4,73.1-51.2,74-49.6c1.3,2.3-2.7,8.5-5.7,12c-16.7,19.9-41.4,45.4-84.8,72.7
c-1.7,1-0.1,1.7,0.8,1.2c11.1-4.5,106.4-59.2,94.5-86.3"/>
<path class="st1" d="M206.1,106.6c-0.9,0-1.9,2.2-2.1,2.5c-0.4,0.5-3,0-3.3,0.6c-0.4,1,0.9,1.9,0.9,2.9c0,0.8-1.1,2.9-0.3,3.3
c0.8,0.5,2.3-1,3.3-0.9c0.6,0,2.3,1.4,3,1c0.6-0.3,0.2-2.4,0.5-3s2.4-1.9,2.5-2.7c0-1.1-2.3-0.9-2.5-1.3c-0.2-0.4-0.9-2.2-1.8-2.3"
/>
<path class="st1" d="M203.2,128.3c-0.2-4.4,11.1-12.8,31-15.5c3-0.4-0.1,6.3-2,6.7c-11,2.1-22.4,7.1-21.7,9.3
c0.7,2.4,9.1,7.1,6,13.1c-2.7,5.1-12.1,6.2-19,5.5c-1.5-0.2-3.2-1.4-1.5-2.9c0.9-0.8,15-4.5,15.3-6.8c0.7-4.5-7.7-4.5-7.9-9.4"/>
<path class="st1" d="M204.3,123.5c3.1-4.2-5.2-4.5-6.6-2.3c-1.6,2.5-7.4,13.6-10.8,16.2c-3.4,2.6-8.1,5.4-6.5,8.4s5,3.5,8.2,0
C191.4,142.7,200.6,128.6,204.3,123.5"/>
</svg>
</template>

